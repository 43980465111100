import { useSearchParams } from 'react-router-dom';

const useCustomSearchParams = initialParams => {
  const [searchParams, setSearchParams] = useSearchParams(initialParams);

  const appendSearchParams = newParams => {
    let currentParams = {};
    for (let [key, value] of searchParams) {
      currentParams[key] = value;
    }
    setSearchParams({ ...currentParams, ...newParams });
  };

  return [searchParams, setSearchParams, appendSearchParams];
};

export default useCustomSearchParams;
