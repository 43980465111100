import { MOBILE, PHONE } from 'utils/contact_type';
import { prepareNestedAttributes } from './address';

export const preparePhoneNumbers = ({ values, formData, phoneNumberByType }) => {
  [PHONE, MOBILE].forEach(numberType => {
    if (phoneNumberByType[numberType] || values[numberType]) {
      const attributes = {
        id: phoneNumberByType[numberType]?.at(0)?.id,
        number: values[numberType],
        number_type: numberType,
        primary: values.preferredContact === numberType,
        destroy: !values[numberType],
      };

      prepareNestedAttributes({ entityName: 'phone_numbers', attributes, formData });
    }
  });
};
