import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import TableLoaderPlaceholder from 'components/shared-components/placeholders/table-loader-placeholder';
import { useTranslation } from 'react-i18next';
import Icon from 'components/base-components/Icon';
import SidePanel from 'components/base-components/SidePanel';
import { useDeleteLocalUnitMutation } from 'api/producers/local-unit';
import DeleteModal from 'components/shared-components/delete-modal';
import TvdOrderForm from 'components/tvd/tvd-order-form.component';
import { organizationAddressToString } from 'components/organisations/organisation-address';
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/base-components/Table';
import EmphasisTag from 'components/base-components/EmphasisTag';
import IconButton from 'components/base-components/IconButton';
import EditTVD from './edit-tvd.component';

const TVDListOverviewTableRow = ({
  tvd,
  organization,
  setIsEditSidepanelOpen,
  setSelectedTVD,
  setTvdForOrder,
}) => {
  const [deleteCallbackParams, setDeleteCallbackParams] = useState(null);
  const { t } = useTranslation();

  const [deleteTVD, deleteStatus] = useDeleteLocalUnitMutation();

  const getLabels = labels =>
    labels.map(label => t(`tvd.order.extra_labels.${label.trim()}`)).join(', ') || '';

  const handleEditTVD = tvd => {
    setIsEditSidepanelOpen(true);
    setSelectedTVD(tvd);
  };

  return (
    <>
      <TableRow key={tvd?.id}>
        <TableData>{tvd?.tvdStickerNumber}</TableData>
        <TableData>
          {organizationAddressToString(tvd?.stickerAddress || organization?.mainAddress)}
        </TableData>
        <TableData>{getLabels(tvd?.labels)}</TableData>
        <TableData align="center">
          <EmphasisTag
            className="tvd-list--emphasis-tag"
            type={tvd.tvdMailing ? 'success' : 'disabled'}
            size="tiny"
            text={tvd?.tvdMailing ? t('shared.action.yes') : t('shared.action.no')}
          />
        </TableData>
        <TableData align="center">
          <div>
            <IconButton
              icon={<Icon name="editAlt" size="normal" />}
              size="tiny"
              color="tertiary"
              onClick={() => handleEditTVD(tvd)}
            />
            <IconButton
              icon={<Icon name="sendAlt" size="normal" />}
              size="tiny"
              color="tertiary"
              onClick={() => setTvdForOrder(tvd)}
            />
            <IconButton
              icon={<Icon name="deleteAlt" size="normal" />}
              size="tiny"
              color="tertiary"
              onClick={() => setDeleteCallbackParams({ localUnitId: tvd?.id })}
            />
          </div>
        </TableData>
      </TableRow>
      <DeleteModal
        modelName="tvd"
        callback={deleteTVD}
        status={deleteStatus}
        deleteCallbackParams={deleteCallbackParams}
        setDeleteCallbackParams={setDeleteCallbackParams}
      />
    </>
  );
};

TVDListOverviewTableRow.propTypes = {
  tvd: PropTypes.shape().isRequired,
  organization: PropTypes.shape().isRequired,
  setIsEditSidepanelOpen: PropTypes.func.isRequired,
  setSelectedTVD: PropTypes.func.isRequired,
  setTvdForOrder: PropTypes.func.isRequired,
};

export const TVDListOverviewTableHeader = () => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableHeader>{t('organisation_view.tvd.table.header.tvd_no')}</TableHeader>
        <TableHeader>{t('organisation_view.tvd.table.header.address')}</TableHeader>
        <TableHeader>{t('organisation_view.tvd.table.header.labels')}</TableHeader>
        <TableHeader align="center">
          {t('organisation_view.tvd.table.header.tvd_mailing')}
        </TableHeader>
        <TableHeader align="center">{t('organisation_view.tvd.table.header.action')}</TableHeader>
      </TableRow>
    </TableHead>
  );
};

const TVDListOverviewTable = ({ tvdList, isFetching, organization }) => {
  const [tvdForOrder, setTvdForOrder] = useState(null);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [isEditSidepanelOpen, setIsEditSidepanelOpen] = useState(false);
  const [selectedTVD, setSelectedTVD] = useState(null);
  const { t } = useTranslation();

  return (
    <>
      {!isFetching && tvdList.length === 0 ? (
        <EmptyContentPlaceholder
          iconName="organization"
          text={t('organisation_view.tvd.no_tvd_found')}
        />
      ) : (
        <Table>
          <TVDListOverviewTableHeader />
          <TableBody>
            {isFetching ? (
              <TableLoaderPlaceholder numberOfRows={10} numberOfColumns={5} />
            ) : (
              tvdList?.map(tvd => (
                <TVDListOverviewTableRow
                  key={tvd?.id}
                  tvd={tvd}
                  organization={organization}
                  setIsEditSidepanelOpen={setIsEditSidepanelOpen}
                  setSelectedTVD={setSelectedTVD}
                  setTvdForOrder={setTvdForOrder}
                />
              ))
            )}
          </TableBody>
        </Table>
      )}
      <SidePanel
        isOpened={isEditSidepanelOpen}
        title={t('organisation_view.tvd.sidepanel.title.edit')}
        body={
          isEditSidepanelOpen && (
            <EditTVD
              selectedTVD={selectedTVD}
              organization={organization}
              setIsEditSidepanelOpen={setIsEditSidepanelOpen}
              showAddressForm={showAddressForm}
              setShowAddressForm={setShowAddressForm}
            />
          )
        }
        onClose={() => {
          setIsEditSidepanelOpen(false);
          setShowAddressForm(false);
        }}
        onClick={() => {
          setIsEditSidepanelOpen(false);
          setShowAddressForm(false);
        }}
      />
      <SidePanel
        isOpened={!!tvdForOrder}
        title={t('tvd.order.title')}
        body={tvdForOrder && <TvdOrderForm tvd={tvdForOrder} setTvdForOrder={setTvdForOrder} />}
        onClose={() => setTvdForOrder(null)}
      />
    </>
  );
};

TVDListOverviewTable.propTypes = {
  organization: PropTypes.shape().isRequired,
  tvdList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetching: PropTypes.bool.isRequired,
  setIsEditSidepanelOpen: PropTypes.func.isRequired,
  setSelectedTVD: PropTypes.func.isRequired,
};

export default TVDListOverviewTable;
