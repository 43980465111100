import routes from 'utils/routes';
import {
  CONTROL_BODY_SURVEY,
  CONTROL_BODY_SURVEY_DELETE_AUDIO,
  CONTROL_BODY_SURVEY_DELETE_IMAGE,
  ORGANISATION,
  ORGANISATION_OVERVIEW,
} from 'utils/tag-types';
import { apiSlice } from '../index';

const controlBodySurveyApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getSurvey: build.query({
      query: params => routes.controlBodySurvey.entity(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
    getActiveSurvey: build.query({
      query: params => routes.controlBodySurvey.active(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
    getDraftControl: build.query({
      query: params => routes.controlBodySurvey.draftControl(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
    getControlBodySurvey: build.query({
      query: params => routes.controlBodySurvey.schedules.collection(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
    getControlBodySchedule: build.query({
      query: params => routes.controlBodySchedule.collection(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
    getLastControlBodySurvey: build.query({
      query: params => routes.controlBodySurvey.last(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
    declareControlBodySurvey: build.mutation({
      query: ({ organizationId, payload }) => ({
        url: routes.controlBodySurvey.schedules.declare({ organizationId }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [CONTROL_BODY_SURVEY],
    }),
    submitNegativeReport: build.mutation({
      query: params => ({
        url: routes.controlBodySurvey.submitNegativeReport(params),
        method: 'PUT',
      }),
      invalidatesTags: [CONTROL_BODY_SURVEY, ORGANISATION_OVERVIEW, ORGANISATION],
    }),
    acceptSchedule: build.mutation({
      query: ({ surveyId, scheduleId, payload }) => ({
        url: routes.controlBodySurvey.schedules.entity({ surveyId, scheduleId }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [CONTROL_BODY_SURVEY],
    }),
    getLogos: build.query({
      query: params => routes.controlBodySurvey.logoCollection(params),
    }),
    getLogoAttachments: build.query({
      query: params => routes.controlBodySurvey.logoAttachmentsCollection(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
    getRestrictedIngredientOptions: build.query({
      query: params => routes.controlBodySurvey.restrictedIngredientsOptions(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
    getRestrictedIngredients: build.query({
      query: params => routes.controlBodySurvey.restrictedIngredientsCollection(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
    getAccountingProcesses: build.query({
      query: params => routes.controlBodySurvey.accountingCollection(params),
    }),
    getActiveAccountingProcesses: build.query({
      query: params => routes.controlBodySurvey.activeAccountingCollection(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
    getIngredientReserves: build.query({
      query: params => routes.controlBodySurvey.ingredientReservesCollection(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
    getAudioResponses: build.query({
      query: params => routes.controlBodySurvey.audioResponseCollection(params),
      providesTags: [CONTROL_BODY_SURVEY, CONTROL_BODY_SURVEY_DELETE_AUDIO],
    }),
    getActiveIngredientReserves: build.query({
      query: params => routes.controlBodySurvey.activeIngredientsReservedCollection(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
    getActiveAudioResponse: build.query({
      query: params => routes.controlBodySurvey.activeAudioResponseCollection(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
    getDeclarationStatuses: build.query({
      query: params => routes.controlBodySurvey.statusCollection(params),
    }),
    getActiveGoodsDeclarationEvaluation: build.query({
      query: params => routes.controlBodySurvey.goodsDeclarationEvaluationCollecton(params),
      providesTags: [
        CONTROL_BODY_SURVEY,
        CONTROL_BODY_SURVEY_DELETE_IMAGE,
        CONTROL_BODY_SURVEY_DELETE_AUDIO,
      ],
    }),
    getControlBodySurveyDraft: build.query({
      query: params => routes.controlBodySurvey.surveyEntity(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
    draftDeclaration: build.mutation({
      query: ({ surveyId, payload }) => ({
        url: routes.controlBodySurvey.draft({ surveyId }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [CONTROL_BODY_SURVEY],
    }),
    verifyAmount: build.mutation({
      query: ({ id, payload }) => ({
        url: routes.controlBodySurvey.goodsDeclarationCustomInputEntity({
          id,
        }),
        method: 'PUT',
        body: payload,
      }),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
    getCustomVatPercentageReasons: build.query({
      query: () => routes.controlBodySurvey.customVatPercentageReasonOptions(),
    }),
    finsihDeclaration: build.mutation({
      query: ({ surveyId, payload }) => ({
        url: routes.controlBodySurvey.finish({ surveyId }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [CONTROL_BODY_SURVEY, ORGANISATION_OVERVIEW],
    }),
    checkSurveyAssesment: build.mutation({
      query: ({ surveyId, payload }) => ({
        url: routes.controlBodySurvey.certifierFinish({ surveyId }),
        method: 'PUT',
      }),
    }),
    requestCertificate: build.mutation({
      query: params => ({
        url: routes.controlBodySurvey.certificates.request(params),
        method: 'PUT',
      }),
      invalidatesTags: [CONTROL_BODY_SURVEY],
    }),
    uploadCertificate: build.mutation({
      query: ({ surveyId, payload }) => ({
        url: routes.controlBodySurvey.certificates.upload({ surveyId }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [CONTROL_BODY_SURVEY],
    }),
    deleteCertificate: build.mutation({
      query: ({ surveyId }) => ({
        url: routes.controlBodySurvey.certificates.delete({ surveyId }),
        method: 'PUT',
      }),
      invalidatesTags: [CONTROL_BODY_SURVEY],
    }),
    deleteImage: build.mutation({
      query: ({ attachmentId }) => ({
        url: routes.controlBodySurvey.deleteImage({ attachmentId }),
        method: 'DELETE',
      }),
      invalidatesTags: [CONTROL_BODY_SURVEY_DELETE_IMAGE],
    }),
    deleteAudio: build.mutation({
      query: ({ audioResponseId }) => ({
        url: routes.controlBodySurvey.deleteAudio({ audioResponseId }),
        method: 'PUT',
      }),
      invalidatesTags: [CONTROL_BODY_SURVEY, CONTROL_BODY_SURVEY_DELETE_AUDIO],
    }),
    getSurveyReport: build.query({
      query: params => routes.controlBodySurvey.report(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSurveyQuery,
  useGetActiveSurveyQuery,
  useGetDraftControlQuery,
  useGetSurveyReportQuery,
  useGetControlBodyScheduleQuery,
  useDeclareControlBodySurveyMutation,
  useSubmitNegativeReportMutation,
  useAcceptScheduleMutation,
  useGetLogosQuery,
  useGetAccountingProcessesQuery,
  useGetRestrictedIngredientOptionsQuery,
  useGetDeclarationStatusesQuery,
  useGetActiveGoodsDeclarationEvaluationQuery,
  useDraftDeclarationMutation,
  useGetLogoAttachmentsQuery,
  useGetActiveAccountingProcessesQuery,
  useGetRestrictedIngredientsQuery,
  useGetIngredientReservesQuery,
  useGetAudioResponsesQuery,
  useVerifyAmountMutation,
  useFinsihDeclarationMutation,
  useCheckSurveyAssesmentMutation,
  useGetLastControlBodySurveyQuery,
  useGetControlBodySurveyDraftQuery,
  useRequestCertificateMutation,
  useUploadCertificateMutation,
  useDeleteCertificateMutation,
  useDeleteImageMutation,
  useDeleteAudioMutation,
  useGetCustomVatPercentageReasonsQuery,
} = controlBodySurveyApi;
