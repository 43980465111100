import React from 'react';
import {
  useGetInvoiceItemsQuery,
  useGetInvoiceQuery,
  useUpdateInvoiceMutation,
} from 'api/invoices';
import ManualInvoiceForm from 'components/invoices/Form/index.component.jsx';
import { useParams } from 'react-router-dom';

const EditManualInvoice = () => {
  const { invoiceId } = useParams();
  const { data: invoice } = useGetInvoiceQuery({ invoiceId });

  const { data: invoiceItems } = useGetInvoiceItemsQuery({ invoiceId: invoiceId });
  const [updateManualInvoice, status] = useUpdateInvoiceMutation();

  const onUpdateInvoice = payload => {
    return updateManualInvoice({
      invoiceId: invoice.id,
      payload: payload,
    });
  };

  return (
    <ManualInvoiceForm
      invoice={invoice}
      invoiceItems={invoiceItems}
      onSave={onUpdateInvoice}
      status={status}
    />
  );
};
export default EditManualInvoice;
