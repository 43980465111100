import React from 'react';
import { useGetProductDeclarationsQuery } from 'api/product-declarations';
import { useGetCurrentUserQuery } from 'api/users';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import EmphasisTag from 'components/base-components/EmphasisTag';
import Spinner from 'components/base-components/Spinner/Spinner';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { dateMonthYearFormatter, monthYearShort } from 'utils/date-time-formatter';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { PRODUCT_DECLARATION_STATUS } from 'utils/statuses';
import isControlBodyMember from 'utils/is-control-body-member';
import ProductTable from '../product-table/product-table.component';

const DeclaredProductDeclarations = ({ organizationId, setSelectedTab }) => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data, isFetching } = useGetProductDeclarationsQuery({
    organizationId: organizationId,
    type: PRODUCT_DECLARATION_STATUS.active,
  });

  if (isFetching)
    return (
      <Spinner
        className="product-declarations__spinner"
        bgColor="none"
        color="success"
        size="small"
      />
    );

  const activeItems = data ? data.collection : [];
  const hasItems = activeItems.length > 0;
  const currentItem = activeItems.find(
    item => !!item.validFrom && !!item.validUntil && !!item.confirmedOn,
  );

  return (
    <div className="product-declarations">
      <div className="product-declarations__header">
        <div className="product-declarations__header--subtitle">
          <div className="product-declarations__header-period">
            <span className="product-declarations__header-title">
              {t('shared.current_period')}{' '}
            </span>
            <span className="product-declarations__header-text--bold">
              {(currentItem &&
                `${monthYearShort(currentItem.validFrom)} - ${monthYearShort(
                  currentItem.validUntil,
                )}`) ||
                t('shared.tbd')}
            </span>
            <span>
              {hasItems && (
                <EmphasisTag
                  radius="oval"
                  size="tiny"
                  text={t('product_declarations.status.verified')}
                  type="success"
                />
              )}
            </span>
          </div>
          <div className="product-declarations__header-period">
            <span className="product-declarations__header-title">{t('shared.declared_on')} </span>
            <span className="product-declarations__header-text--bold">
              {(currentItem && dateMonthYearFormatter(currentItem.confirmedOn)) || t('shared.tbd')}
            </span>
          </div>
        </div>
      </div>
      <div className="product-declarations__content">
        {hasItems ? (
          <ProductTable data={activeItems} showActions={false} organizationId={organizationId} />
        ) : (
          <Card className="product-declarations__content-card">
            <div className="product-declarations__content-card--title">
              {t('product_declarations.messages.begin_text')}
            </div>
            <Button
              label={t('product_declarations.begin_declaration')}
              size="small"
              type="success"
              onClick={() => setSelectedTab(1)}
              disabled={isBioSuisseAdmin(currentUser) || isControlBodyMember(currentUser)}
            />
          </Card>
        )}
      </div>
    </div>
  );
};

DeclaredProductDeclarations.propTypes = {
  organizationId: PropTypes.number.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
};

export default DeclaredProductDeclarations;
