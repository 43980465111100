import React from 'react';
import { useGetProductionByYearQuery } from 'api/producers/productions';
import { useGetSuperOfficeDetailsQuery, useGetOrganizationQuery } from 'api/organizations';
import DefaultSpinner from 'components/shared-components/default-spinner';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import GeneralInformation from './general-information.component';
import MarketData from './market-data.component';
import './styles.scss';
import CompanyAttributes from './company-attributes.component';

const SuperOfficeDetails = ({ companyIdentifier }) => {
  const { id: organizationId } = useParams();

  const { data: organisation = {} } = useGetOrganizationQuery(organizationId);

  const customerSince = new Date(organisation.customerSince).getFullYear();
  const currentYear = new Date().getFullYear();

  const { data: organizationOldView = {}, isFetching } =
    useGetSuperOfficeDetailsQuery(organizationId);

  const { data: seasonalData = {} } = useGetProductionByYearQuery(
    {
      organisationId: organizationId,
      year: customerSince > currentYear ? customerSince : currentYear,
    },
    { skip: !organisation?.customerSince },
  );

  if (isFetching) {
    return <DefaultSpinner className="default-spinner--center" />;
  }

  const processings = Object.keys(seasonalData.processings || {}).filter(
    portalInterest => seasonalData.processings[portalInterest],
  );

  const allInterests = (organizationOldView?.interests || []).concat(processings);

  return (
    <div className="super-office-details grid">
      <div className="col-12 col-bleed">
        <GeneralInformation
          organisationDataLakeAttribute={organizationOldView}
          companyIdentifier={companyIdentifier}
        />
      </div>
      <div className="col-12 col-bleed margin-top-20">
        <div className="super-office-details__container">
          <MarketData
            organisationDataLakeAttribute={organizationOldView}
            organisation = {organisation}
            isFetching={isFetching}
            productionId={seasonalData?.id}
          />
          <CompanyAttributes interests={allInterests} isFetching={isFetching} />
        </div>
      </div>
    </div>
  );
};

SuperOfficeDetails.propTypes = {
  companyIdentifier: PropTypes.number.isRequired,
};

export default SuperOfficeDetails;
