import React from 'react';
import { useCreateGoodsDeclarationsComplaintMutation } from 'api/goods-declarations';
import { useTranslation } from 'react-i18next';
import Complaint from 'components/organisations/educations/complaint';
import PropTypes from 'prop-types';

const CreateComplaint = ({ goodsDeclarationId, isDisabled }) => {
  const { t } = useTranslation();

  const [createComplaint] = useCreateGoodsDeclarationsComplaintMutation();

  const onSave = values => {
    const params = {
      queryParams: {
        goodsDeclarationId,
      },
      payload: {
        title: values.title,
        description: values.description,
      },
    };

    return createComplaint(params);
  };

  return <Complaint onSave={onSave} label={t('shared.action.complaint')} isDisabled={isDisabled} />;
};

CreateComplaint.propTypes = {
  goodsDeclarationId: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default CreateComplaint;
