import { StyleSheet, Font } from '@react-pdf/renderer';
import OpenSansNormal from 'assets/fonts/OpenSans-Regular.ttf';
import OpenSansBold from 'assets/fonts/OpenSans-Bold.ttf';
import {
  FONT_SIZE_16,
  FONT_SIZE_12,
  FONT_SIZE_8_5,
  FONT_SIZE_10,
  FONT_WEIGHT_BOLD,
  BASE_LINE_HEIGHT,
  COLOR_BLACK,
  COLOR_GRAY,
} from './variables';

Font.register({
  family: 'Open Sans',
  fonts: [{ src: OpenSansNormal }, { src: OpenSansBold, fontWeight: FONT_WEIGHT_BOLD }],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontFamily: 'Open Sans',
    lineHeight: BASE_LINE_HEIGHT,
    fontSize: FONT_SIZE_10,
    paddingBottom: 20,
  },
  statusLogo: {
    height: 20,
    padding: 2,
    width: 20,
  },
  imageLogo: {
    height: 10,
    marginRight: 10,
    marginLeft: 10,
    marginTop: 2,
    width: 10,
  },
  body: {
    flexGrow: 1,
    marginHorizontal: 72,
  },
  checkListBody: {
    flexGrow: 1,
    marginHorizontal: 20,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 25,
    paddingTop: 10,
  },
  footerText: {
    fontSize: FONT_SIZE_8_5,
    color: COLOR_GRAY,
    fontFamily: 'Open Sans',
  },
  title: {
    fontSize: FONT_SIZE_16,
    fontWeight: FONT_WEIGHT_BOLD,
  },
  heading1: {
    fontSize: FONT_SIZE_12,
    fontWeight: FONT_WEIGHT_BOLD,
  },
  heading2: {
    fontSize: FONT_SIZE_10,
    fontWeight: FONT_WEIGHT_BOLD,
  },
  bold: {
    fontWeight: FONT_WEIGHT_BOLD,
  },
  frontPageDescription: {
    fontSize: FONT_SIZE_12,
    paddingTop: 20,
  },
  section: {
    marginTop: 20,
  },
  subSection: {
    marginBottom: 20,
  },
  listItemContainer: {
    position: 'relative',
    marginTop: 6,
    marginLeft: 10,
    paddingLeft: 15,
  },
  bulletPointContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    paddingTop: 4,
    paddingBottom: 10,
  },
  bulletPoint: {
    backgroundColor: COLOR_BLACK,
    width: 3,
    height: 3,
  },
  signatureForm: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  horizontalLine: {
    width: '100%',
    height: 0.5,
    backgroundColor: COLOR_BLACK,
    marginTop: 40,
  },
  checklistHorizontalLine: {
    width: '100%',
    height: 0.5,
    backgroundColor: '#00393f40',
    marginTop: '10px',
  },

  checklistMarginForty: {
    marginTop: 40,
  },

  checklistMarginFive: {
    marginTop: 5,
  },

  checklistMarginTen: {
    marginTop: 10,
  },

  checklistMarginTwenty: {
    marginTop: 20,
  },

  checklistMarginTwentyBottom: {
    marginBottom: 20,
  },

  checklistMarginTwentyLeft: {
    marginLeft: 20,
  },

  checklistMarginTenLeft: {
    marginLeft: 10,
  },

  checklistMarginTenRight: {
    marginRight: 10,
  },

  checklistMarginFiveLeft: {
    marginLeft: 5,
  },

  checklistMarginTwo: {
    marginTop: 2,
  },

  checkListFlexSpaceBetween: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  contractualStatusWrapper: {
    position: 'relative',
    paddingTop: 10,
    paddingBottom: 10,
  },
  contractualStatusLogo: {
    position: 'absolute',
    right: 0,
    top: 5,
  },

  contractualStatusHR: {
    width: '100%',
    height: 0.5,
    backgroundColor: '#00393f40',
  },
  checkListFlex: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  baseComment: {
    marginTop: 10,
    marginLeft: 10,
  },

  audioComment: {
    marginTop: 10,
    marginLeft: 8,
  },
  yesNoListItemContainer: {
    paddingLeft: 10,
    position: 'relative',
    marginTop: 10,
  },
  yesNoBulletPoint: {
    left: 0,
    top: 5,
    position: 'absolute',
    height: 4,
    width: 4,
    borderRadius: 10,
    backgroundColor: COLOR_BLACK,
  },
});

export default styles;
