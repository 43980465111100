import React from 'react';
import { Document, Page, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import PdfFooter from 'components/pdf/footer/pdf-footer.component';
import PDFHeader from 'components/pdf/header/pdf-header.component';
import styles from 'components/pdf/styles/styles';
import PdfFrontPage from './content/pdf-frontpage-component';
import PdfContent from './content/pdf-content.component';

const BioCuisineContractPDF = ({ organisation }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PDFHeader />
        <View style={styles.body}>
          <PdfFrontPage organisation={organisation} />
          <PdfContent />
        </View>
        <PdfFooter showInFirstPageOnly={true} />
      </Page>
    </Document>
  );
};

BioCuisineContractPDF.propTypes = {
  organisation: PropTypes.shape().isRequired,
};

export default BioCuisineContractPDF;
