import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useGetAccountingProcessesQuery,
  useGetActiveAccountingProcessesQuery,
} from 'api/control-body-survey';
import { useGetActiveAccountingProcessesAdditionalTaskQuery } from 'api/control-body-survey/accounting-processes';
import RadioWithoutCheck from 'components/base-components/RadioWithoutCheck';
import { Field } from 'react-final-form';
import Icon from 'components/base-components/Icon';
import { t } from 'i18next';
import { PERMANENT, TEMPORARY } from 'utils/control-body-survey';
import Textarea from 'components/base-components/Textarea/Textarea';

const AccountingProcess = ({
  isCertifier,
  errorMessage,
  surveyId,
  status,
  comment,
  setAccount,
  account,
}) => {
  const { data: accountingProcesses, isSuccess: isAccountingProcessesFetched } =
    useGetAccountingProcessesQuery();

  const { data: activeAccountingProcesses } = useGetActiveAccountingProcessesQuery(
    { surveyId },
    { skip: !surveyId },
  );

  const { data: activeAccountingProcessAdditionalTask } =
    useGetActiveAccountingProcessesAdditionalTaskQuery({ surveyId }, { skip: !surveyId });

  useEffect(() => {
    if (activeAccountingProcesses) {
      setAccount(activeAccountingProcesses?.type);
    }
  }, [activeAccountingProcesses]);

  return (
    <div>
      {isAccountingProcessesFetched &&
        accountingProcesses.map(item => (
          <RadioWithoutCheck
            className="organisation__form--radio__item"
            isChecked={item.value === account}
            label={item.label}
            name={`item-${item.value}`}
            onChange={() => setAccount(item.value)}
            size="medium"
            disabled={isCertifier || status === PERMANENT}
            status={status}
          />
        ))}
      <Field name="accountingProcessComment" initialValue={comment || ''}>
        {({ input }) => (
          <Textarea
            className="organisation-checklist__quarter-comment"
            label={
              isCertifier
                ? t('control_body.survey.form.auditor_comments')
                : t('control_body.survey.form.comments')
            }
            placeholder={t('control_body.survey.form.comments_placeholder')}
            disabled={isCertifier || status === PERMANENT}
            {...input}
          />
        )}
      </Field>
      {status === TEMPORARY &&
        activeAccountingProcessAdditionalTask &&
        activeAccountingProcesses?.type !== activeAccountingProcessAdditionalTask?.type && (
          <>
            <div className="organisation-checklist__accounts">
              <span className="organisation-checklist__accounts-bold">
                {t('control_body.survey.form.newly_declared')}:
              </span>{' '}
              <span className="organisation-checklist__accounts-normal">
                {t(
                  `control_body.survey.form.accounting.${activeAccountingProcessAdditionalTask?.type}`,
                )}
              </span>
            </div>

            <div className="organisation-checklist__verify">
              {t('control_body.survey.form.bio_suisse_verify_title')}
            </div>
          </>
        )}
      {errorMessage && errorMessage['accountingProcesses'] && (
        <div className="organisation-checklist__accounting-error">
          <>
            <Icon name="invalid" color="danger" size="small" />
            <span className="organisation-checklist__errors-text">
              {errorMessage['accountingProcesses']}
            </span>
          </>
        </div>
      )}
    </div>
  );
};

AccountingProcess.defaultProps = {
  text: null,
  account: null,
  status: null,
  surveyId: null,
  isCertifier: false,
  errorMessage: null,
  comment: null,
  setAccount: () => {},
};

AccountingProcess.propTypes = {
  text: PropTypes.string,
  account: PropTypes.string,
  comment: PropTypes.node,
  surveyId: PropTypes.node,
  status: PropTypes.string,
  isCertifier: PropTypes.bool,
  errorMessage: PropTypes.shape(),
  setAccount: PropTypes.func,
};

export default AccountingProcess;
