import { Text, View } from '@react-pdf/renderer';
import { Break } from 'components/pdf/shared/shared-components';
import tvdStyles from 'components/pdf/styles/tvd-styles';
import React from 'react';

const PPAInfo = () => (
  <View style={[tvdStyles.ppaInfoContainer, tvdStyles.flexRow]}>
    <View style={[tvdStyles.ppaInfo, tvdStyles.flexRowItem, tvdStyles.flexRow]}>
      <View style={[tvdStyles.flexRowItem]}>
        <Text style={tvdStyles.ppaText}>P.P.A</Text>
      </View>
      <View style={[tvdStyles.flexRowItem, tvdStyles.ppaNumber]}>
        <Text>
          CH-3052
          <Break />
          Zollikofen
        </Text>
      </View>
    </View>
    <View style={[tvdStyles.flexRowItem, tvdStyles.textAlignRight]}>
      <Text>Post CH AG</Text>
      <Break />
      <Text style={tvdStyles.postNumber}>000001</Text>
    </View>
  </View>
);

export default PPAInfo;
