import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'i18next';
import Avatar from '../../Avatar';

const Task = props => {
  const {
    className,
    priority,
    name,
    description,
    assignee,
    reporter,
    assigneeLabel,
    reporterLabel,
    assosiatedOrder,
    lastUpdatedTime,
    createdTime,
    lastUpdatedTimeLabel,
    taskCreatedLabel,
    isExpanded,
    isChecked,
    showTaskOptions,
    dueDate,
    isOrganisation,
    organisation,
    showTaskDetails,
  } = props;

  const getFirstandLastName = value => {
    const namesArray = value.split(' ');

    const lastName = namesArray.pop();
    const firstName = namesArray.join(' ');

    return { firstName, lastName };
  };

  const getAssigneeAvatar = (value, showName) => {
    if (value) {
      const { firstName, lastName } = getFirstandLastName(value);

      return <Avatar showName={showName} firstName={firstName} lastName={lastName} size="tiny" />;
    }

    return <Avatar firstName="Un" lastName="Assigned" size="tiny" showName={showName} />;
  };

  const getReporterAvatar = (value, showName) => {
    if (value.toLowerCase() === 'auto generated') {
      return (
        <Avatar showName={showName} firstName="Auto" lastName="Generated" src={null} size="tiny" />
      );
    }

    if (value) {
      const { firstName, lastName } = getFirstandLastName(value);

      return <Avatar showName={showName} firstName={firstName} lastName={lastName} size="tiny" />;
    }

    return <Avatar firstName="Un" lastName="Assigned" size="tiny" showName={showName} />;
  };

  return (
    <div
      className={classNames('task-list__item', className, {
        'task-list__item--expanded': isExpanded,
      })}
    >
      <div className="task-list__item-tag">{priority}</div>

      <div
        className={classNames('task-list__item-title', {
          'task-list__item-title--checked': isChecked,
          'task-list__item-title--expanded': isExpanded,
        })}
      >
        {name}
      </div>

      {assignee && isOrganisation && (
        <div className="task-list__item-avatar--organisation">
          {t('shared.organisation')}:{' '}
          {typeof assignee === 'string' ? getAssigneeAvatar(assignee, false) : assignee}
        </div>
      )}

      <div
        className={classNames('task-list__item-date', {
          'task-list__item-date--with-no-margin': !assignee || showTaskDetails,
        })}
      >
        <span className="task-list__item-date--text">{t('tasks.create_form.due_date')}</span>
        {': '}
        {dueDate}
      </div>
      {assignee && !isOrganisation && !showTaskDetails && (
        <div className="task-list__item-avatar">
          {typeof assignee === 'string' ? getAssigneeAvatar(assignee, false) : assignee}
        </div>
      )}
      <div
        className={classNames('task-list__item-icons', {
          'task-list__item-icons--hide': showTaskDetails,
        })}
      >
        {showTaskOptions}
      </div>

      <div className="task-list__item-details">
        <div className="task-list__item-assosiatedOrder">{assosiatedOrder}</div>
        <div className="task-list__item-description">{description}</div>
        {isOrganisation && (
          <div className="task-list__item-description">
            {t('shared.organisation')}
            {organisation}
          </div>
        )}
        <div className="task-list__item-avatars">
          <div className="task-list__item-avatars-assignee">
            <div className="task-list__item-avatars-assignee-label">{assigneeLabel}</div>
            <div className="task-list__item-avatars-assignee-name">
              {typeof assignee === 'string' || assignee === null
                ? getAssigneeAvatar(assignee, true)
                : assignee}{' '}
            </div>
          </div>

          <div className="task-list__item-avatars-reporter">
            <div className="task-list__item-avatars-reporter-label">{reporterLabel}</div>
            <div className="task-list__item-avatars-reporter-name">
              {typeof reporter === 'string' || reporter === null
                ? getReporterAvatar(reporter, true)
                : reporter}{' '}
            </div>
          </div>
        </div>
        <div className="task-list__item-times">
          <div>
            {lastUpdatedTimeLabel} {lastUpdatedTime}
          </div>
          <div>
            {taskCreatedLabel} {createdTime}
          </div>
        </div>
      </div>
    </div>
  );
};

Task.defaultProps = {
  id: null,
  className: null,
  dueDate: null,
  priority: null,
  assignee: null,
  organisation: null,
  name: '',
  assignDate: '',
  description: '',
  reporter: null,
  assosiatedOrder: null,
  lastUpdatedTime: '',
  createdTime: '',
  locales: 'en',
  isExpanded: false,
  isChecked: false,
  assigneeLabel: t('tasks.assignee_label'),
  reporterLabel: t('shared.reporter'),
  lastUpdatedTimeLabel: t('shared.last_updated'),
  taskCreatedLabel: t('tasks.task_created'),
  showTaskDetails: false,
  showTaskOptions: false,
  isOrganisation: false,
  onCheck: () => {},
  onExpand: () => {},
  onEdit: () => {},
  onDelete: () => {},
};

Task.propTypes = {
  id: PropTypes.number,
  className: PropTypes.string,
  dueDate: PropTypes.node,
  priority: PropTypes.node,
  organisation: PropTypes.node,
  assignee: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  assignDate: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  reporter: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  assosiatedOrder: PropTypes.string,
  lastUpdatedTime: PropTypes.string,
  createdTime: PropTypes.string,
  isExpanded: PropTypes.bool,
  isChecked: PropTypes.bool,
  assigneeLabel: PropTypes.string,
  reporterLabel: PropTypes.string,
  lastUpdatedTimeLabel: PropTypes.string,
  taskCreatedLabel: PropTypes.string,
  showTaskDetails: PropTypes.bool,
  showTaskOptions: PropTypes.bool,
  isOrganisation: PropTypes.bool,
  locales: PropTypes.oneOf(['en', 'de', 'fr', 'it']),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onCheck: PropTypes.func,
  onExpand: PropTypes.func,
};

export default Task;
