import Recognition from 'components/recognition/index.component';
import { Tabs } from 'components/base-components/RTabs';
import React from 'react';
import { t } from 'i18next';
import { useSearchParams } from 'react-router-dom';
import TVDWrapper from 'components/tvd/tvd-wrapper.component';
import { RECOGNITION } from 'utils/data-center-tabs';

const DataCenter = () => {
  const [searchParams, setSearchParams] = useSearchParams({ tab: RECOGNITION, page: 1 });
  const currentTab = searchParams.get('tab');

  const TAB_MAPPER = {
    recognition: 0,
    tvd: 1,
  };

  const onChangeTab = selectedTabKey => {
    const selectedTab = Object.keys(TAB_MAPPER).find(tab => TAB_MAPPER[tab] === selectedTabKey);
    setSearchParams({ tab: selectedTab, page: 1 });
  };

  return (
    <div className="portal__layout">
      <Tabs
        controlled={true}
        selectedTabKey={TAB_MAPPER[currentTab]}
        items={[
          {
            title: t('organisation_view.tabs.recognition'),
            key: 0,
            getContent: () => <Recognition />,
          },
          {
            title: t('organisation_view.tabs.tvd'),
            key: 1,
            getContent: () => <TVDWrapper />,
          },
        ]}
        onChange={onChangeTab}
      />
    </div>
  );
};

export default DataCenter;
