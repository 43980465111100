import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/base-components/Icon';
import { SUBMITTED } from 'utils/verification-status';
import { dateMonthYearFormatter } from 'utils/date-time-formatter';
import { useTranslation } from 'react-i18next';
import VerificationTag from 'components/shared-components/verification-tag';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableData,
} from 'components/base-components/Table';
import { useGetSingleUserEducationQuery, useDeleteUserEducationMutation } from 'api/educations';
import ViewComplaint from 'components/shared-components/view-complaint';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import IconButton from 'components/base-components/IconButton';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader/ContentLoader';
import EditForm from './edit/edit.component';
import CreateComplaint from './create-complaint';

const EducationDeclarationTableRow = ({ educationDeclaration, isAdmin }) => {
  const [deleteEducation, setDeleteEducation] = useState(false);
  const { t } = useTranslation();

  const { isFetching, data: education = {} } = useGetSingleUserEducationQuery(
    {
      educationId: educationDeclaration.educationId,
    },
    { skip: deleteEducation },
  );

  const [deleteCertificate] = useDeleteUserEducationMutation();

  const onDeleteEducation = () => {
    setDeleteEducation(true);
    deleteCertificate({ educationId: education.id });
  };

  const complaint = educationDeclaration.complaint;

  return (
    <Fragment>
      <TableRow className="organization-education__table-row">
        <TableData>
          {isFetching ? (
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} isRounded={true} />
          ) : (
            <div className="supplier-certificates__row-header">
              <span>{t(`educations.degree.${education.degree}`)}</span>
              {!complaint && <VerificationTag status={educationDeclaration.status} />}
              {complaint && <ViewComplaint complaint={complaint} />}
            </div>
          )}
        </TableData>
        <TableData>
          {isFetching ? (
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} isRounded={true} />
          ) : (
            t(`educations.institute.${education.institute}`)
          )}
        </TableData>
        <TableData>
          {education.certificationId ? education.certificationId : t('shared.tbd')}
        </TableData>
        <TableData>{dateMonthYearFormatter(education.certificationDate)}</TableData>
        <TableData>
          {educationDeclaration.declaredAt
            ? dateMonthYearFormatter(educationDeclaration.declaredAt)
            : t('shared.tbd')}
        </TableData>
        <TableData align="center">
          <a href={education.diplomaForEducationUrl} target="_blank" rel="noreferrer">
            <IconButton
              className="organization-education__table-row--download-icon"
              icon={<Icon name="downloadFile" size="small" />}
              color="default"
              disabled={!education.diplomaForEducationUrl}
              isIconOnly={true}
            />
          </a>
        </TableData>
        <TableData>
          <div className="organization-education__table-row-actions">
            {isAdmin && (
              <CreateComplaint
                educationDeclarationId={educationDeclaration.id}
                isDisabled={educationDeclaration.status !== SUBMITTED}
              />
            )}
            {!isFetching && (
              <div className="supplier-certificates__row-actions">
                <EditForm
                  educationCertificate={education}
                  disabled={!educationDeclaration.modifiable}
                />
                <IconButton
                  icon={<Icon name="delete" size="small" />}
                  color="danger"
                  onClick={onDeleteEducation}
                  disabled={!educationDeclaration.modifiable}
                  isIconOnly={true}
                />
              </div>
            )}
          </div>
        </TableData>
      </TableRow>
    </Fragment>
  );
};

EducationDeclarationTableRow.defaultProps = {
  educationDeclaration: null,
  isAdmin: false,
};
EducationDeclarationTableRow.propTypes = {
  educationDeclaration: PropTypes.shape(),
  isAdmin: PropTypes.bool,
};

const EducationDeclarationTable = ({ educationDeclarations, currentUser }) => {
  const { t } = useTranslation();
  const isAdmin = isBioSuisseAdmin(currentUser);

  return (
    <>
      <Table className="organization-education__table">
        <TableHead>
          <TableRow>
            <TableHeader>{t('shared.attributes.degree.label')}</TableHeader>
            <TableHeader>{t('shared.attributes.institution.label')}</TableHeader>
            <TableHeader>{t('shared.attributes.certification_id.label')}</TableHeader>
            <TableHeader>{t('shared.attributes.certification_date.label')}</TableHeader>
            <TableHeader>{t('shared.attributes.declaration_date.label')}</TableHeader>
            <TableHeader align="center">{t('shared.attributes.diploma.label')}</TableHeader>
            <TableHeader align="center">{t('shared.actions')}</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {educationDeclarations.map(educationDeclaration => (
            <EducationDeclarationTableRow
              key={educationDeclaration.id}
              educationDeclaration={educationDeclaration}
              isAdmin={isAdmin}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

EducationDeclarationTable.defaultProps = {
  educationDeclarations: [],
  currentUser: null,
};
EducationDeclarationTable.propTypes = {
  educationDeclarations: PropTypes.arrayOf(PropTypes.shape()),
  currentUser: PropTypes.shape(),
};

export default EducationDeclarationTable;
