import { Form, Field } from 'react-final-form';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import React, { useState, useMemo } from 'react';
import moment from 'moment';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import Card from 'components/base-components/Card';
import Input from 'components/base-components/Input';
import SelectBox from 'components/base-components/SelectBox';
import DatePicker from 'components/base-components/DatePicker';
import Button from 'components/base-components/Button';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import { useGetOrganizationQuery, useGetOrganizationsQuery } from 'api/organizations';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import ORGANISATION_TYPE from 'utils/organisation-type';
import ADDRESS_TYPE from 'utils/address-type';
import { DRAFT, OPEN } from 'utils/invoice-statuses';
import { Link, useParams } from 'react-router-dom';
import useCustomNavigate from 'hooks/useCustomNavigate';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { organizationAddressToString } from '../../organisations/organisation-address';
import { yearMonthDayWithHyphen } from '../../../utils/date-time-formatter';
import LineItems from './line-items';

const ManualInvoiceForm = ({ invoice, invoiceItems, onSave, status }) => {
  const { isSuccess, isLoading, error, requestId } = status;
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const navigationLinks = useNavigationLinks();
  const { id } = useParams();

  const { data: organization } = useGetOrganizationQuery(invoice?.organizationId || id, {
    skip: !invoice?.organizationId && !id,
  });

  const [enableInvoiceDatePicker, setEnableInvoiceDatePicker] = useState(null);
  const [organisationQuery, setOrganisationQuery] = useState(null);
  const [removedItems, setRemovedItems] = useState([]);
  const organizationSearchParams = {
    q: organisationQuery,
    status: ORGANISATIONS_STATUS.active,
    'types[]': [
      ORGANISATION_TYPE.parent_organisation,
      ORGANISATION_TYPE.sub_organisation,
      ORGANISATION_TYPE.single_organisation,
    ],
  };
  const { data: organizationCollection } = useGetOrganizationsQuery(organizationSearchParams, {
    skip: organization,
  });
  const organisationList = organizationCollection?.organizations || [];
  const organisationOptions = organisationList.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const dueDateOptions = [
    {
      label: t('invoice.due_days.30_days'),
      value: 30,
    },
    {
      label: t('invoice.due_days.60_days'),
      value: 60,
    },
  ];
  const onSearchOrganisation = debounce(500, value => setOrganisationQuery(value));
  const isLineItemsAbsent = items => items.length == 0;
  const onSubmit = values => {
    let modifiedRemovedItems = removedItems?.map(item => {
      if (item.id) {
        return {
          ...item,
          _destroy: true,
        };
      }
      return item;
    });
    modifiedRemovedItems = (modifiedRemovedItems || []).filter(item => item.id);

    values?.items?.push(...modifiedRemovedItems);

    if (values.due_date) {
      values.due_date = getDueDate(values.due_date, values.invoice_date);
    }

    return onSave(values)
      .unwrap()
      .then(() => {
        return null;
      })
      .catch(({ data: { errors } }) => {
        const itemsError = [];
        errors?.['items']?.forEach(item => {
          const attributes = {
            name: item.name,
            quantity: item.quantity,
            description: item.description,
            price: item.price,
            vat_rate: item.vatRate,
            id: item.id,
          };
          itemsError.push(attributes);
        });
        return {
          organization: null,
          organization_mailing_address: null,
          invoice_id: errors?.invoiceId,
          invoice_date: errors?.invoiceDate,
          due_date: errors?.dueDate,
          items: itemsError,
        };
      });
  };

  const getDueDateInitialValue = (dueDate, invoiceDate) => {
    return moment(dueDate).diff(invoiceDate, 'days');
  };

  const initialValues = useMemo(() => {
    if (!organization) {
      return;
    }
    const itemsArray = [];
    invoiceItems?.forEach(item => {
      const attributes = {
        name: item.name,
        quantity: item.quantity,
        description: item.description,
        price: item.price,
        vat_rate: item.vatRate,
        id: item.id,
        cost_center: item.costCenter,
        cost_element: item.costElement,
      };
      itemsArray.push(attributes);
    });
    organisationOptions.push({ label: organization.name, value: organization.id });

    return {
      organization: organization?.id,
      organization_mailing_address:
        organizationAddressToString(
          organization?.addresses?.find(address => address.type === ADDRESS_TYPE.invoice),
        ) || t('common.not_applicable'),
      invoice_id: invoice?.invoiceId,
      invoice_date: invoice?.invoiceDate,
      due_date: getDueDateInitialValue(invoice?.dueDate, invoice?.invoiceDate),
      items: itemsArray,
      status: invoice?.status,
    };
  }, [invoice?.id, invoiceItems?.length, organization?.id]);

  const getBillingAddress = e => {
    const selectedOrganisation = organisationList.find(org => org.id === e.value);
    return selectedOrganisation?.addresses.find(address => address.type === ADDRESS_TYPE.invoice);
  };
  const onRedirect = values => {
    if (id) {
      navigate.openOrganisationInvoicePage(id);
    } else {
      navigate.openInvoiceListPage(values.status);
    }
  };
  const getSuccessContent = values => {
    if (!isSuccess) {
      return null;
    }

    if (values?.status === OPEN) {
      return <div>{t('invoice.invoice_creation_message')}</div>;
    }

    if (values?.status === DRAFT) {
      return <div>{t('invoice.invoice_draft_message')}</div>;
    }

    return <div>{t('invoice.invoice_saving_message')}</div>;
  };

  const getDueDate = (dueDate, invoiceDate) => {
    return yearMonthDayWithHyphen(moment(invoiceDate).add(dueDate, 'days'));
  };

  return (
    <div className="invoice__card">
      <Card className="col-4">
        <Form
          onSubmit={onSubmit}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={initialValues}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="invoice__form_label">
                {invoice ? t('invoice.update_invoice_title') : t('invoice.create_invoice_title')}
              </div>
              <div>
                <div className="col-6 col-bleed-x invoice__form-input">
                  <Field name="organization">
                    {({ input, meta }) => (
                      <SelectBox
                        size="tiny"
                        width="large"
                        label={t('invoice.select_organization')}
                        errorMsg={meta.submitError}
                        options={organisationOptions}
                        value={organisationOptions?.find(option => option.value === input.value)}
                        onChange={e => {
                          input.onChange(e.value);
                          form.change(
                            'organization_mailing_address',
                            organizationAddressToString(getBillingAddress(e)) ||
                              t('common.not_applicable'),
                          );
                        }}
                        onInputChange={onSearchOrganisation}
                        placeholderText={t('user_assignment.organisation.placeholder')}
                        isDisabled={organization}
                        required={true}
                        isClearable={true}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-6 col-bleed-x invoice__form-input invoice__form-input--right">
                  <Field name="organization_mailing_address">
                    {({ input, meta }) => (
                      <Input
                        size="tiny"
                        width="large"
                        label={t('invoice.invoice_mailing_address')}
                        error={meta.submitError}
                        disabled={true}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <hr className="invoice__divider" />
              </div>
              <div>
                <div className="invoice__form_label">{t('invoice.invoice_details_title')}</div>
                <div className="invoice__date-wrapper">
                  <div className="col-6 col-bleed-x">
                    <Field name="invoice_date">
                      {({ input, meta }) => (
                        <DatePicker
                          className="invoice__date-picker"
                          focused={enableInvoiceDatePicker}
                          date={input.value}
                          onChange={e => {
                            input.onChange(e);
                          }}
                          touched={!meta.dirtySinceLastSubmit}
                          error={meta.submitError}
                          onFocusChange={() => setEnableInvoiceDatePicker(!enableInvoiceDatePicker)}
                          displayFormat="LL"
                          label={t('invoice.invoice_date')}
                          navNext="arrowForwardAlt"
                          navPrev="arrowBackAlt"
                          size="tiny"
                          enablePastDates={true}
                          disableFutureDates={false}
                          futureYearsCount={0}
                          locale={i18n.language}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="col-6 col-bleed-x invoice__form-input--right">
                    <Field name="due_date">
                      {({ input, meta }) => (
                        <SelectBox
                          size="tiny"
                          width="large"
                          label={t('invoice.due_date')}
                          errorMsg={meta.submitError}
                          touched={!meta.dirtySinceLastSubmit}
                          options={dueDateOptions}
                          value={dueDateOptions?.find(option => option.value === input.value)}
                          onChange={e => {
                            input.onChange(e.value);
                          }}
                          placeholderText={'select date'}
                          isClearable={true}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <hr className="invoice__divider" />
              </div>
              <div>
                <div className="invoice__form_label">{t('invoice.line_items_header')}</div>
                <LineItems setRemovedItems={setRemovedItems} />
              </div>

              <div className="invoice__form-submit">
                <Button
                  label={t('shared.action.save')}
                  disabled={!values?.organization || isLineItemsAbsent(values.items || [])}
                  size="small"
                  onClick={() => {
                    form.change('status', 'draft');
                    handleSubmit();
                  }}
                  type="success"
                />

                <Link to={navigationLinks.invoiceListPage()}>
                  <Button
                    label={t('common.cancel')}
                    size="small"
                    disabled={!values?.organization}
                    type="default"
                  />
                </Link>
              </div>
              <SubmitModal
                isLoading={isLoading}
                isSuccess={isSuccess}
                requestId={requestId}
                successTitle={
                  values.status === OPEN
                    ? t('invoice.invoice_publish_title')
                    : t('invoice.invoice_saving_title')
                }
                successContent={getSuccessContent(values)}
                errorTitle={t('invoice.invoice_publish_failed_message')}
                onSuccess={() => onRedirect(values)}
                errorContent={error?.data?.message}
                showError={true}
              />
            </form>
          )}
        />
      </Card>
    </div>
  );
};

ManualInvoiceForm.defaultProps = {
  invoice: null,
  invoiceItems: null,
};

ManualInvoiceForm.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.number,
    invoiceId: PropTypes.string,
    invoiceDate: PropTypes.instanceOf(Date),
    dueDate: PropTypes.instanceOf(Date),
    status: PropTypes.string,
    organizationId: PropTypes.number,
  }),
  invoiceItems: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.number,
      service: PropTypes.string,
      vatRate: PropTypes.number,
      quantity: PropTypes.number,
      costCenter: PropTypes.string,
      costElement: PropTypes.string,
    }),
  ),
  onSave: PropTypes.func.isRequired,
  status: PropTypes.shape().isRequired,
};

export default ManualInvoiceForm;
