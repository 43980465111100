import { useApproveSupplierCertificateDeclarationsMutation } from 'api/supplier-certificates';
import ApprovalConfirmationModal from 'components/shared-components/ApprovalModal/approval-confirmation-modal.component';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import Button from 'components/base-components/Button';

const ApprovedDeclaration = ({ organizationId, isDeclared }) => {
  const [enableVerificationModal, setEnableVerificationModal] = useState(false);
  const [
    approveCertificateDeclarations,
    { isSuccess: isVerificationSuccessful, requestId: approvedRequestId },
  ] = useApproveSupplierCertificateDeclarationsMutation({
    organizationId,
  });
  const { t } = useTranslation();

  const onApprove = () => {
    setEnableVerificationModal(false);
    return approveCertificateDeclarations({ organizationId: organizationId });
  };

  return (
    <>
      <ApprovalConfirmationModal
        organizationId={organizationId}
        isModalOpen={enableVerificationModal}
        setIsModalOpen={setEnableVerificationModal}
        onConfirm={onApprove}
        title={t('supplier_certificates.modal.verification_permission.title')}
        content={t('supplier_certificates.modal.verification_permission.body')}
        confirmActionText={t('supplier_certificates.verify')}
        cancelActionText={t('shared.action.cancel')}
      />
      <SubmitModal
        isSuccess={isVerificationSuccessful}
        requestId={approvedRequestId}
        successTitle={t('supplier_certificates.modal.verification_successful.title')}
        successContent={t('supplier_certificates.modal.verification_successful.body')}
        showError={false}
        showLoader={false}
      />
      <Button
        label={t('supplier_certificates.verify')}
        size="small"
        onClick={() => setEnableVerificationModal(true)}
        type="success"
        disabled={!isDeclared}
      />
    </>
  );
};

ApprovedDeclaration.propTypes = {
  organizationId: PropTypes.number.isRequired,
  isDeclared: PropTypes.bool.isRequired,
};

export default ApprovedDeclaration;
