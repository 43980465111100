import { apiSlice } from 'api';
import routes from 'utils/routes';
import {
  CONTROL_BODY_SURVEY,
  LOGO_ATTACHMENT_ADDITIONAL_TASK,
  LOGO_ATTACHMENT_PHOTOS,
} from 'utils/tag-types';

const logoAttachmentsApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getActiveLogoAttachmentsAdditionalTask: build.query({
      query: params => routes.logoAttachments.active(params),
      providesTags: [LOGO_ATTACHMENT_ADDITIONAL_TASK],
    }),
    declareLogoAttachmentsAdditionalTask: build.mutation({
      query: ({ surveyId, payload }) => ({
        url: routes.logoAttachments.declare({ surveyId }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, id) => (!error ? [LOGO_ATTACHMENT_ADDITIONAL_TASK] : []),
    }),
    approveLogoAttachmentsAdditionalTask: build.mutation({
      query: ({ surveyId }) => ({
        url: routes.logoAttachments.approve({ surveyId }),
        method: 'PUT',
      }),
      invalidatesTags: (result, error, id) =>
        !error ? [LOGO_ATTACHMENT_ADDITIONAL_TASK, CONTROL_BODY_SURVEY] : [],
    }),
    getLogoAttachmentPhotos: build.query({
      query: params => routes.logoAttachments.getLogoAttachmentPhotos(params),
      providesTags: [LOGO_ATTACHMENT_PHOTOS, CONTROL_BODY_SURVEY],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetActiveLogoAttachmentsAdditionalTaskQuery,
  useDeclareLogoAttachmentsAdditionalTaskMutation,
  useApproveLogoAttachmentsAdditionalTaskMutation,
  useGetLogoAttachmentPhotosQuery,
} = logoAttachmentsApi;
