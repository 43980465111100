import routes from 'utils/routes';
import { EDUCATION_OVERVIEW, EDUCATION_DECLARATION, EDUCATION } from 'utils/tag-types';
import { apiSlice } from '../index';

const educationsApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getEducationDegrees: build.query({
      query: () => routes.educations.degrees(),
    }),
    getEducationInstitutes: build.query({
      query: () => routes.educations.institutes(),
    }),
    getEducationsOverview: build.query({
      query: params => routes.educations.overview(params),
      providesTags: [EDUCATION_OVERVIEW],
    }),
    getUsersEducation: build.query({
      query: userId => routes.educations.collectionOfUser({ userId }),
      providesTags: [EDUCATION],
    }),
    getSingleUserEducation: build.query({
      query: params => routes.educations.entity(params),
      providesTags: (result, error, id) => [{ type: EDUCATION, id: result.id }],
    }),
    getEducationDeclaration: build.query({
      query: params => routes.educations.declarations.entity(params),
      providesTags: (result, error, id) => [{ type: EDUCATION_DECLARATION, id: result.id }],
    }),
    createUserEducation: build.mutation({
      query: ({ queryParams, payload }) => ({
        url: routes.educations.collectionOfUser(queryParams),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, id) => (!error ? [EDUCATION_DECLARATION, EDUCATION] : []),
    }),
    updateUserEducation: build.mutation({
      query: ({ queryParams, payload }) => ({
        url: routes.educations.entity(queryParams),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, id) => [EDUCATION, { type: EDUCATION, id: id }],
    }),
    deleteUserEducation: build.mutation({
      query: params => ({
        url: routes.educations.entity(params),
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: [EDUCATION_DECLARATION, EDUCATION],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEducationDegreesQuery,
  useGetEducationInstitutesQuery,
  useGetEducationsOverviewQuery,
  useGetUsersEducationQuery,
  useGetSingleUserEducationQuery,
  useCreateUserEducationMutation,
  useUpdateUserEducationMutation,
  useDeleteUserEducationMutation,
  useGetEducationDeclarationQuery,
} = educationsApi;
