import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { monthYearShort } from 'utils/date-time-formatter';
import {
  APPROVED,
  COMPLAINED,
  SUBMITTED,
  EXPIRING_SOON,
  EXPIRED,
  ACTIVE,
  ARCHIVED,
} from 'utils/verification-status';

const OverviewTextComponent = ({ status, text, goodsValidFrom, goodsValidUntil }) => {
  const { t } = useTranslation();

  const handleStatus = () => {
    if (status === APPROVED || status === ACTIVE) return t('shared.complete');
    if (status === EXPIRED) return t('shared.expired');
    if (status === null || status === ARCHIVED) return t('shared.not_declared');
    if (status === EXPIRING_SOON) return t('shared.expire_soon');
    if (status === SUBMITTED) return t('shared.submitted');
    if (status === COMPLAINED) return t('shared.complained');
  };

  const showGoodsDate =
    goodsValidFrom &&
    goodsValidUntil &&
    `(${monthYearShort(goodsValidFrom)} - ${monthYearShort(goodsValidUntil)})`;

  return (
    <div className="organisation-overview-text__wrapper">
      <div className="organisation-overview-text__wrapper-status">
        <span>{t('shared.status')}</span>:{' '}
        <span className="organisation-overview-text__wrapper-status--text">
          {handleStatus() || t('shared.not_declared')} {showGoodsDate}
        </span>
      </div>
      <div className="organisation-overview-text__wrapper-description">{text}</div>
    </div>
  );
};

OverviewTextComponent.defaultProps = {
  text: null,
  goodsValidFrom: null,
  goodsValidUntil: null,
};

OverviewTextComponent.propTypes = {
  status: PropTypes.string.isRequired,
  text: PropTypes.node,
  goodsValidFrom: PropTypes.node,
  goodsValidUntil: PropTypes.node,
};

export default OverviewTextComponent;
