import useNavigationLinks from 'hooks/useNavigationLinks';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import isControlBodyMember from 'utils/is-control-body-member';
import React from 'react';
import Icon from '../base-components/Icon';
import { SideNav, SideNavFooterBrand, SideNavLink, SideNavList } from '../base-components/Sidenav';
import './sidenav.styles.scss';

export default function SidenavComponent({ currentUser }) {
  const { t } = useTranslation();
  const isAdmin = isBioSuisseAdmin(currentUser);
  const isCBMember = isControlBodyMember(currentUser);
  const navigationLinks = useNavigationLinks();
  const location = useLocation();
  const basePath = location.pathname === '/' ? 'dashboard' : location.pathname.split('/')[1];

  return (
    <SideNav className="side-nav">
      <SideNavList>
        <SideNavLink
          href={navigationLinks.rootPage()}
          icon={<Icon name="home" />}
          label={t('sidenav.dashboard')}
          activeLink={basePath === 'dashboard'}
        />
        {(isAdmin || isCBMember) && (
          <SideNavLink
            href={navigationLinks.usersPage()}
            icon={<Icon name="person" />}
            label={t('sidenav.user')}
            activeLink={basePath === 'users'}
          />
        )}
        {isCBMember ? (
          <SideNavLink
            href={navigationLinks.organisationsPage()}
            icon={<Icon name="organization" />}
            label={t('sidenav.organisation')}
            activeLink={basePath === 'organisations'}
          />
        ) : (
          <>
            <SideNavLink
              className="side-nav__organisation"
              href={navigationLinks.restaurantsPage()}
              icon={<Icon name="restaurantAlt" size="medium" />}
              label={t('sidenav.restaurants')}
              activeLink={basePath === 'restaurants'}
            />
            <SideNavLink
              className="side-nav__organisation"
              href={navigationLinks.producersPage()}
              icon={<Icon name="producerAlt" size="medium" />}
              label={t('sidenav.producers')}
              activeLink={basePath === 'producers'}
            />
            <SideNavLink
              className="side-nav__organisation"
              href={navigationLinks.controlBodyPage()}
              icon={<Icon name="controlBodyAlt" size="medium" />}
              label={t('sidenav.control_body')}
              activeLink={basePath === 'control-body'}
            />
          </>
        )}
        <SideNavLink
          href={navigationLinks.tasksListPage()}
          icon={<Icon name="tasks" />}
          label={t('sidenav.task_management')}
          activeLink={basePath === 'tasks'}
        />
        {!isCBMember && (
          <SideNavLink
            href={navigationLinks.messagesListPage()}
            icon={<Icon name="chat" />}
            label={t('sidenav.message_management')}
            activeLink={basePath === 'messages'}
          />
        )}
        {isAdmin && (
          <SideNavLink
            href={navigationLinks.invoiceJobListPage()}
            icon={<Icon name="invoice" />}
            label={t('sidenav.invoices')}
            activeLink={basePath === 'invoices'}
          />
        )}
        {isAdmin && (
          <SideNavLink
            href={navigationLinks.dataCenterPage()}
            icon={<Icon size="normal" name="database" />}
            label={t('sidenav.data_center')}
            activeLink={basePath === 'data-center'}
          />
        )}
      </SideNavList>
      <SideNavList type="footer">
        <SideNavFooterBrand />
      </SideNavList>
    </SideNav>
  );
}

SidenavComponent.propTypes = {
  currentUser: PropTypes.shape().isRequired,
};
