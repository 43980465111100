import { apiSlice } from 'api';
import Button from 'components/base-components/Button';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import Modal from 'components/base-components/Modal';
import SelectBox from 'components/base-components/SelectBox';
import Spinner from 'components/base-components/Spinner';
import Tooltip from 'components/base-components/Tooltip';
import { organizationAddressToString } from 'components/organisations/organisation-address';
import { SuccessModal } from 'components/shared-components/modal/submit-modal';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Trans } from 'react-i18next';
import { GENERAL } from 'utils/organisation-create-options';
import './styles.scss';

const ChangeMainAddress = ({ organisation, updateAddress }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [upToDateModalOpen, setUpToDateModalOpen] = useState(false);
  const [showReason, setShowReason] = useState(false);

  const [fetchAddress, { data, isFetching }] =
    apiSlice.endpoints.getOrganizationRegistry.useLazyQuery();

  const reasons = [
    {
      label: t('organisation.change_address.reason_options.returned'),
      value: 'returned',
    },
    {
      label: t('organisation.change_address.reason_options.reported'),
      value: 'reported',
    },
  ];

  const toPortalAddressFormat = address => {
    if (!address) return {};

    return {
      line1: address.addressLine1,
      line2: address.addressLine2,
      city: address.city,
      zipCode: address.zipCode,
      country: address.country,
      canton: address.canton,
    };
  };

  const newAddress = toPortalAddressFormat(data);
  const mainAddress = organisation.mainAddress || {};

  const onFetchAddress = () => {
    fetchAddress({ uid: organisation.uid })
      .unwrap()
      .then(data => {
        const address = toPortalAddressFormat(data);

        if (isAddressChanged(address)) {
          setShowReason(false);
          setModalOpen(true);
        } else {
          setUpToDateModalOpen(true);
        }
      })
      .catch(error => {
        throw error;
      });
  };

  const isAddressChanged = newAddress => {
    if (mainAddress.country?.code !== newAddress.country?.code) return true;

    const otherKeys = ['line1', 'line2', 'city', 'zipCode', 'canton'];
    for (let key of otherKeys) {
      if (mainAddress[key]?.trim() !== newAddress[key]?.trim()) return true;
    }

    return false;
  };

  const getModalContent = () => {
    if (showReason) {
      return (
        <div>
          <div className="address-change__description">
            {t('organisation.change_address.step2.description')}
          </div>
          <div className="address-change__reason">
            <Field name="reason" initialValue={reasons[0].value}>
              {({ input }) => (
                <SelectBox
                  className=""
                  size="tiny"
                  width="full"
                  label={t('organisation.change_address.change_reason_label')}
                  isClearable={false}
                  options={reasons}
                  value={reasons.find(option => option.value === input.value)}
                  onChange={e => input.onChange(e.value)}
                  required={true}
                />
              )}
            </Field>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="address-change__description">
          {t('organisation.change_address.step1.description')}
        </div>
        <div className="address-change__title">
          {t('organisation.change_address.step1.current_address')}:
        </div>
        <div className="address-change__description">
          {organizationAddressToString(mainAddress)}
        </div>
        <div className="address-change__title">
          {t('organisation.change_address.step1.uid_address')}:
        </div>
        <div className="address-change__description">{organizationAddressToString(newAddress)}</div>
        <Trans
          i18nKey="organisation.change_address.step1.confirmation_text"
          components={{ bold: <b /> }}
        />
      </div>
    );
  };

  const onNextStep = values => {
    if (!showReason) {
      setShowReason(true);
      return;
    }

    setModalOpen(false);

    const payload = {
      line1: newAddress.line1,
      line2: newAddress.line2,
      main: true,
      zip_code: newAddress.zipCode,
      city: newAddress.city,
      canton: newAddress.canton,
      country_code: newAddress.country.code,
      address_type: GENERAL,
      reason_to_change: values.reason,
    };

    return updateAddress({ organisationId: organisation.id, formData: payload }).unwrap();
  };

  return (
    <>
      <div className="address-change__container">
        <Button
          label={t('organisation.change_address.fetch_address')}
          size="small"
          onClick={onFetchAddress}
          disabled={isFetching || !organisation.uid}
        />
        {!organisation.uid && (
          <Tooltip
            content={t('organisation.change_address.no_uid_warning')}
            type="inverse"
            position="right"
            gap={0}
          >
            <IconButton
              icon={<Icon name="info" size="small" />}
              color="default"
              size="tiny"
              isIconOnly={true}
            />
          </Tooltip>
        )}
        {isFetching && (
          <Spinner className="address-change__loader" bgColor="none" color="success" size="tiny" />
        )}
      </div>

      <Form
        onSubmit={onNextStep}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal className="modal__warning address-change__modal" isVisible={modalOpen}>
              <Modal.Title>
                <Icon name="warning" showBGColor={true} size="medium" color="warning" />
                <div className="modal__warning--title-name">
                  {showReason
                    ? t('organisation.change_address.step2.title')
                    : t('organisation.change_address.step1.title')}
                </div>
              </Modal.Title>
              <Modal.Content>{getModalContent()}</Modal.Content>
              <Modal.Footer>
                <Button
                  label={t('shared.action.save')}
                  type="success"
                  size="small"
                  onClick={handleSubmit}
                />
                <Button
                  label={t('shared.action.cancel')}
                  size="small"
                  onClick={() => setModalOpen(false)}
                />
              </Modal.Footer>
            </Modal>
          </form>
        )}
      />

      <SuccessModal
        title={t('organisation.change_address.modal.uptodate_title')}
        content={t('organisation.change_address.modal.uptodate_description')}
        footer={
          <Button
            label={t('shared.action.okay')}
            size="small"
            onClick={() => setUpToDateModalOpen(false)}
            type="success"
          />
        }
        isVisible={upToDateModalOpen}
      />
    </>
  );
};

ChangeMainAddress.propTypes = {
  organisation: PropTypes.shape().isRequired,
  updateAddress: PropTypes.func.isRequired,
};

export default ChangeMainAddress;
