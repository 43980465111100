import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import AvatarUpload from 'components/base-components/AvatarUpload';
import Modal from 'components/base-components/Modal';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './styles.scss';

const AvatarModal = props => {
  const { className, updateAvatar, iconName } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { t } = useTranslation();

  const onModalOpen = () => setIsModalVisible(true);
  const onModalClose = () => setIsModalVisible(false);

  const saveAvatar = async image => {
    updateAvatar(image);
  };

  const onAvatarSave = image => {
    const file = new File([image], image.name, { type: image.type });
    saveAvatar(file);
  };

  return (
    <Fragment>
      <IconButton
        className="user__general-icon"
        version="v2"
        isIconOnly={true}
        icon={<Icon name={iconName || 'cameraAlt'} showBGColor={true} />}
        size="small"
        color="tertiary"
        onClick={onModalOpen}
      />
      <Modal
        isVisible={isModalVisible}
        onOutsideClick={onModalClose}
        className={classNames('avatar-upload-modal', className)}
      >
        <Modal.Content>
          <AvatarUpload
            onAvatarUpload={onAvatarSave}
            onClose={onModalClose}
            title={t('avatar_upload.title')}
            fileSizeErrorMsg={t('avatar_upload.file_size_error_msg')}
            fileTypeErrorMsg={t('avatar_upload.file_type_error_msg')}
            uploadTitle={t('avatar_upload.upload_title')}
            uploadSubtitle={t('avatar_upload.upload_subtitle')}
            setButtonLabel={t('avatar_upload.set')}
            cancelButtonLabel={t('avatar_upload.cancel')}
            active={t('avatar_upload.active')}
          />
        </Modal.Content>
      </Modal>
    </Fragment>
  );
};

AvatarModal.defaultProps = {
  className: null,
  iconName: null,
};

AvatarModal.propTypes = {
  className: PropTypes.string,
  fetchInstance: PropTypes.func.isRequired,
  updateAvatar: PropTypes.func.isRequired,
  iconName: PropTypes.string,
};

export default AvatarModal;
