import { useGetUserQuery } from 'api/users';
import classNames from 'classnames';
import Spinner from 'components/base-components/Spinner';
import React from 'react';
import PropTypes from 'prop-types';

const UserName = ({ userID, className }) => {
  const { data: user = {}, error, isFetching } = useGetUserQuery({ id: userID });
  if (isFetching) return <Spinner bgColor="none" color="success" size="tiny" />;
  if (error) return null;

  return <span className={classNames('user-name', className)}>{user.name}</span>;
};

UserName.defaultProps = {
  className: null,
};

UserName.propTypes = {
  userID: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default UserName;
