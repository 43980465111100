import { useDeclareSupplierCertificateDeclarationsMutation } from 'api/supplier-certificates';
import Button from 'components/base-components/Button';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import useCustomNavigate from 'hooks/useCustomNavigate';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DeclareCertificate = ({
  draftCertificateDeclarations,
  organizationId,
  isComplained,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const [
    declareCertificateDeclarations,
    { isSuccess: isDeclarationSuccessful, requestId: declareRequestId },
  ] = useDeclareSupplierCertificateDeclarationsMutation();

  const onDeclareCertificateDeclarations = () => {
    return declareCertificateDeclarations({ organizationId });
  };
  const navigate = useCustomNavigate();

  return (
    <div>
      {!isComplained ? (
        <>
          <SubmitModal
            isSuccess={isDeclarationSuccessful}
            requestId={declareRequestId}
            successTitle={t('supplier_certificates.modal.declaration_successful.title')}
            successContent={t('supplier_certificates.modal.declaration_successful.body')}
            showError={false}
            showLoader={false}
            onSuccess={() => navigate.openOrganisationDetailsPage(organizationId)}
          />
          <Button
            label={t('supplier_certificates.declare_certificates')}
            size="small"
            onClick={onDeclareCertificateDeclarations}
            disabled={
              isDisabled ||
              (draftCertificateDeclarations && draftCertificateDeclarations.length === 0)
            }
            type="success"
          />
        </>
      ) : (
        <div className="supplier-certificates__header-redeclare">
          <div className="supplier-certificates__header-redeclare-warning">
            {t('supplier_certificates.redeclare.warning')}
          </div>
          <Button
            label={t('supplier_certificates.redeclare.action')}
            size="small"
            onClick={onDeclareCertificateDeclarations}
            disabled={draftCertificateDeclarations?.length === 0}
            type="success"
          />
        </div>
      )}
    </div>
  );
};

DeclareCertificate.defaultProps = {
  isComplained: false,
  isDisabled: false,
};

DeclareCertificate.propTypes = {
  organizationId: PropTypes.number.isRequired,
  draftCertificateDeclarations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isComplained: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default DeclareCertificate;
