import React from 'react';
import { t } from 'i18next';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import PropTypes from 'prop-types';
import Card from 'components/base-components/Card';
import OverviewContent from '../overview-content.component';

const ContactOverview = ({ isFetching }) => (
  <Card className="organisation-overview__gastronomy-card">
    {isFetching ? (
      <ContentLoaderPlaceholder numberOfLines={4} showBackground={false} />
    ) : (
      <>
        <span className="organisation-overview__card-title">
          {t('organisation_view.overview.attributes.contact.header')}
        </span>
        <div className="organisation-overview__content-wrapper">
          <OverviewContent
            label={t('organisation_view.overview.attributes.contact.company_phone')}
          />
          <OverviewContent
            label={t('organisation_view.overview.attributes.contact.company_mobile')}
          />
          <OverviewContent
            label={t('organisation_view.overview.attributes.contact.visitor_phone')}
          />
          <OverviewContent
            label={t('organisation_view.overview.attributes.contact.company_website')}
            noMargin={true}
          />
          <OverviewContent
            label={t('organisation_view.overview.attributes.contact.company_email')}
            noMargin={true}
          />
          <OverviewContent
            label={t('organisation_view.overview.attributes.contact.visitor_email')}
            noMargin={true}
          />
        </div>
      </>
    )}
  </Card>
);

ContactOverview.propTypes = {
  isFetching: PropTypes.bool.isRequired,
};

export default ContactOverview;
