import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { HeaderDataProvider } from './contexts/HeaderDataContext';

const Table = ({ className, children, disableShadow, disablePadding }) => (
  <div
    className={classNames('table', className, {
      'table--no-shadow': disableShadow,
      'table--no-padding': disablePadding,
    })}
  >
    <HeaderDataProvider initialHeaderData={[]}>
      <table className="table__table">{children}</table>
    </HeaderDataProvider>
  </div>
);

Table.defaultProps = {
  className: null,
  disableShadow: false,
  disablePadding: false,
};

Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  disableShadow: PropTypes.bool,
  disablePadding: PropTypes.bool,
};

export default Table;
