import { t } from 'i18next';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import SelectBox from 'components/base-components/SelectBox';
import { Trans } from 'react-i18next';
import Input from 'components/base-components/Input';
import { FieldArray } from 'react-final-form-arrays';
import { useParams } from 'react-router-dom';
import { useGetTVDListQuery } from 'api/tvd';

const TVDinProducer = ({ tvdStickerEnabled, validationError }) => {
  const { id } = useParams();

  const { data: localUnitsCollection } = useGetTVDListQuery(
    { 'organization_ids[]': [id] },
    { skip: !id },
  );
  const localUnitsList = localUnitsCollection?.collection;

  const { values, submitErrors } = useFormState();

  const tvdOptions = [
    { label: t('shared.action.yes'), value: true },
    { label: t('shared.action.no'), value: false },
  ];

  return (
    <>
      <div className="organisation__form--font-bold">{t('organisation.form.tvd.header')}</div>
      <div className="organisation__form--margin-top organisation__form--tvd">
        <Field name="tvd_sticker_enabled" initialValue={tvdStickerEnabled}>
          {({ input, meta }) => (
            <SelectBox
              size="tiny"
              width="small"
              label={
                <Trans
                  i18nKey="organisation.form.tvd.label"
                  components={{
                    anchor: (
                      <a
                        href="https://www.bioaktuell.ch/grundlagen/umstellung/allgemein/vermarktung#c18056:~:text=Tierverkehrsvignetten%20f%C3%BCr%20die%20Umstellzeit"
                        target="_blank"
                        rel="noreferrer"
                        className="organisation__form-sub-label--color"
                      >
                        <i area-hidden={true}></i>
                      </a>
                    ),
                  }}
                />
              }
              placeholderText={t('organisation.form.placeholder.tvd')}
              isClearable={false}
              options={tvdOptions}
              value={tvdOptions.find(option => option.value === input.value)}
              selectedValue={input.label}
              touched={!meta.dirtySinceLastSubmit}
              errorMsg={meta.submitError || validationError?.tvd}
              onChange={e => input.onChange(e.value)}
              required={true}
            />
          )}
        </Field>

        {values.tvd_sticker_enabled && (
          <div className="organisation__form--margin-top">
            <div>{t('tvd.enter_all_tvd_number')}</div>
            <FieldArray name="tvd_sticker_numbers">
              {() =>
                Array.from({ length: 3 }).map((_, index) => {
                  return (
                    <Field
                      name={`tvd_sticker_numbers[${index}]`}
                      initialValue={localUnitsList?.[index]?.tvdStickerNumber}
                    >
                      {({ input, meta }) => (
                        <Input
                          {...input}
                          className="organisation__form--margin-top"
                          type="number"
                          size="tiny"
                          label={t('tvd.placeholder.tvd_number')}
                          placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
                          touched={!meta.dirtySinceLastSubmit}
                          error={
                            index === 0 &&
                            (meta.submitError ||
                              submitErrors?.tvdStickerNumbers ||
                              validationError?.tvdStickerNumbers)
                          }
                          required={index === 0}
                        />
                      )}
                    </Field>
                  );
                })
              }
            </FieldArray>
          </div>
        )}
      </div>
    </>
  );
};

TVDinProducer.defaultProps = {
  tvdStickerEnabled: null,
  validationError: {},
};

TVDinProducer.propTypes = {
  tvdStickerEnabled: PropTypes.oneOf([true, false, null]),
  validationError: PropTypes.shape(),
};
export default TVDinProducer;
