import React from 'react';
import { t } from 'i18next';
import {
  NOT_LEGITIMIZED,
  NO_RATING,
  ONE_STAR,
  THREE_STAR,
  TWO_STAR,
} from 'utils/organisation-stars';
import CheckboxFilter from 'components/shared-components/Filters/CheckboxFilter';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import OrganizationFilter from 'components/shared-components/organization-filter.jsx';
import { getSelectedMembers, getSelectedStars } from '../helpers/getSelectedFilters';

const starOptions = [
  { label: t('organisation.stars.one_star'), key: ONE_STAR },
  { label: t('organisation.stars.two_star'), key: TWO_STAR },
  { label: t('organisation.stars.three_star'), key: THREE_STAR },
  { label: t('organisation.stars.not_legitimized'), key: NOT_LEGITIMIZED },
  { label: t('organisation.stars.no_rating'), key: NO_RATING },
];

export const memberOptions = [
  { label: t('organisation.members.one_to_five'), key: { min: 1, max: 5 } },
  { label: t('organisation.members.five_to_ten'), key: { min: 5, max: 10 } },
  { label: t('organisation.members.ten_plus'), key: { min: 10 } },
];

const FilterContainer = ({ isAdmin, form }) => {
  const [searchParams] = useSearchParams();
  const stars = searchParams.getAll('stars');
  const noRating = searchParams.get('noRating');
  const minimumUsers = searchParams.get('minimumUsers');
  const minRangeOfUsers = searchParams.getAll('minRangeOfUsers');

  const selectedStars = getSelectedStars(stars, noRating);
  const selectedMembers = getSelectedMembers(minRangeOfUsers, minimumUsers);

  return (
    <div className="modal__content--filter-container">
      <span onClick={() => form.reset()} className="modal__content--reset-all">
        {t('shared.action.reset_all')}
      </span>
      <div className="modal__content__filter-set">
        {/* //TODO Need to add checkbox for Organization Health in https://welltravel.atlassian.net/browse/BIOS-771 this story */}
        {isAdmin && <OrganizationFilter />}
        <CheckboxFilter
          title={t('organisation.filter.title.stars')}
          fieldName="stars"
          checkboxList={starOptions}
          showBadge={true}
          form={form}
          selectedOptions={selectedStars}
        />
        <CheckboxFilter
          title={t('organisation.filter.title.members')}
          fieldName="members"
          checkboxList={memberOptions}
          showBadge={true}
          form={form}
          selectedOptions={selectedMembers}
        />
      </div>
    </div>
  );
};

FilterContainer.propTypes = {
  form: PropTypes.shape().isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default FilterContainer;
