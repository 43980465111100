import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';
import SelectBox from '../base-components/SelectBox';
import { useGetCantonsQuery } from '../../api/countries';

const CantonSelector = ({ value, isDisabled, parentKey, ...other }) => {
  const initiallySelectedCountryCode = 'CH';
  const { values } = useFormState();
  const { data: cantons = [], isFetching } = useGetCantonsQuery({
    code: values?.[parentKey]?.country?.value?.toUpperCase() || initiallySelectedCountryCode,
  });
  const cantonOptions = cantons.map(canton => ({ label: canton.code, value: canton.code }));
  return (
    <SelectBox
      {...other}
      value={cantonOptions?.find(option => option.value === value)}
      isDisabled={isDisabled || isFetching}
      options={cantonOptions}
    />
  );
};

CantonSelector.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  parentKey: PropTypes.string.isRequired,
};
export default CantonSelector;
