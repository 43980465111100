import { useGetInvoiceQuery, useUpdateInvoiceMutation } from 'api/invoices';
import { useGetCurrentUserQuery } from 'api/users';
import Button from 'components/base-components/Button';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader/ContentLoader';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import { Table, TableBody } from 'components/base-components/Table';
import OrganisationViewHeader from 'components/organisations/view/organisation-view-header.component';
import TableLoaderPlaceholder from 'components/shared-components/placeholders/table-loader-placeholder';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { t } from 'i18next';
import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CANCELLED, DRAFT, OPEN, PENDING } from 'utils/invoice-statuses';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { moveToPreviousPage } from 'utils/navigation-utils';
import InvoiceDeleteModal from '../shared/delete-modal.component';
import DownloadPdf from '../shared/download-pdf.component';
import InvoiceReOpenComponent from '../shared/ReOpenInvoice.component';
import InvoiceDetails from './details.component';
import '../invoice.styles.scss';
import InvoiceItemsTable from './line-items/table.component';

const InvoiceShowPage = () => {
  const { invoiceId } = useParams();
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: invoice = {}, isFetching } = useGetInvoiceQuery({ invoiceId });
  const [updateInvoice] = useUpdateInvoiceMutation();

  const navigate = useNavigate();
  const isAdmin = isBioSuisseAdmin(currentUser);
  const navigationLinks = useNavigationLinks();

  const publishInvoice = () => updateInvoice({ invoiceId, payload: { status: OPEN } });

  return (
    <div className="user__rtabs">
      <OrganisationViewHeader />

      <div className="invoice-wrapper">
        <div className="invoice-header">
          <span>
            <IconButton
              className="organisation__head-popover--go-back"
              icon={<Icon name="goBack" />}
              onClick={() => navigate(moveToPreviousPage)}
            />
            <span className="invoice-header--heading">{invoice.title}</span>
          </span>
          <div className="invoice-header__right-buttons">
            <InvoiceReOpenComponent invoice={invoice} isAdmin={isAdmin} />
            {isAdmin && (invoice.status === DRAFT || invoice.status === OPEN) && (
              <InvoiceDeleteModal invoice={invoice} />
            )}
            <DownloadPdf entityType="invoice" invoiceId={invoice.id} type="button" />
            {isAdmin && invoice.status === DRAFT && invoice.status !== CANCELLED && (
              <Button
                onClick={publishInvoice}
                label={t('invoice.publish_invoice')}
                type="success"
                size="small"
              />
            )}
            {isAdmin &&
              (invoice.status === OPEN || invoice.status === PENDING) &&
              invoice.status !== CANCELLED && (
                <Link type="link" to={navigationLinks.invoiceRecordPayment(invoiceId)}>
                  <Button label={t('invoice.record_payment')} type="success" size="small" />
                </Link>
              )}
          </div>
        </div>
        <div className="grid">
          <div className="col-3 col-bleed">
            {isFetching ? (
              <ContentLoaderPlaceholder numberOfLines={9} />
            ) : (
              <InvoiceDetails invoice={invoice} />
            )}
          </div>
          <div className="col-9 col-bleed">
            <div className="invoice__line-card">
              {isFetching ? (
                <Table>
                  <TableBody>
                    <TableLoaderPlaceholder numberOfRows={8} numberOfColumns={5} />
                  </TableBody>
                </Table>
              ) : (
                <InvoiceItemsTable invoice={invoice} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceShowPage;
