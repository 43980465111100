import { useUpdateUserEducationMutation } from 'api/educations';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import SidePanel from 'components/base-components/SidePanel';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EducationForm from '../form/form.component';

const EditForm = ({ educationCertificate, disabled }) => {
  const [enableSidePannel, setEnableSidePanel] = useState(false);
  const { t } = useTranslation();

  const [updateEducation] = useUpdateUserEducationMutation();

  const onUpdateEducation = formData => {
    return updateEducation({
      payload: formData,
      queryParams: { educationId: educationCertificate.id },
    }).unwrap();
  };

  return (
    <Fragment>
      <SidePanel
        title={t('educations.edit_certification')}
        closeIcon={<Icon name="close" />}
        body={
          <EducationForm
            onSave={onUpdateEducation}
            educationCertificate={educationCertificate}
            onCancel={() => {
              setEnableSidePanel(false);
            }}
          />
        }
        isOpened={enableSidePannel}
        onClick={() => {}}
        onClose={() => {
          setEnableSidePanel(false);
        }}
      />
      <IconButton
        icon={<Icon name="edit" size="small" />}
        color="tertiary"
        onClick={() => {
          setEnableSidePanel(true);
        }}
        disabled={disabled}
        isIconOnly={true}
      />
    </Fragment>
  );
};

EditForm.propTypes = {
  educationCertificate: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default EditForm;
