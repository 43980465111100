import { Image, Text, View } from '@react-pdf/renderer';
import ImageUpload from 'assets/image-upload.png';
import styles from 'components/pdf/styles/checklist-styles';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import truncateFilename from 'utils/truncate-filename';
import { FINAL } from 'utils/verification-status';

const LogoAttachments = ({
  data,
  reportType,
  comments,
  logoAttachmentPhotos,
  followUpLogoAttachmentsPhotos,
  activeLogosFromAuditor,
}) => {
  return (
    <>
      <Text style={styles.checklistMarginForty}>
        <Text style={styles.bold}>1. </Text>
        {t('control_body.survey.form.first_step')}
      </Text>
      <Text style={styles.checklistMarginFive}>{t('control_body.survey.form.first_step_description')}</Text>
      <View style={styles.checklistMarginTen}>
        {data?.length > 0 &&
          data.map(logo => (
            <>
              {reportType === FINAL && logo.comment && (
                <View style={styles.checkListFlexSpaceBetween}>
                  <View style={styles.yesNoListItemContainer}>
                    <View style={styles.yesNoBulletPoint} />
                    <Text>
                      {t(`control_body.survey.form.location.${logo.value}`)} ({t('control_body.survey.form.previous')})
                    </Text>
                  </View>
                  <Text>{t(`control_body.survey.shared.${activeLogosFromAuditor[logo.value]}`)}</Text>
                </View>
              )}
              <View style={styles.checkListFlexSpaceBetween}>
                <View style={styles.yesNoListItemContainer}>
                  {!logo.comment && <View style={styles.yesNoBulletPoint} />}
                  <Text>{t(`control_body.survey.form.location.${logo.value}`)}</Text>
                </View>

                <Text>{t(`control_body.survey.shared.${logo.attached}`)}</Text>
              </View>
              {reportType === FINAL && logo.comment && (
                <View style={styles.baseComment}>
                  <Text>
                    {t('control_body.survey.form.reason_of_change')} {logo.comment}
                  </Text>
                </View>
              )}
              <View style={styles.checklistHorizontalLine} />
            </>
          ))}
      </View>

      {reportType === FINAL && logoAttachmentPhotos.length > 0 && (
        <>
          <View style={styles.checklistMarginTwenty}>
            <View style={styles.checkListFlexSpaceBetween}>
              <Text style={styles.heading1}> {t('control_body.survey.form.uploaded_files')} </Text>
            </View>
          </View>
          <View style={styles.checklistMarginTen}>
            {logoAttachmentPhotos.map(logo => (
              <View style={{ ...styles.checkListFlex, ...styles.checklistMarginTen }}>
                <Image src={ImageUpload} style={styles.imageLogo} />
                <Text>{truncateFilename(logo?.filename) || t('common.not_applicable')}</Text>
              </View>
            ))}
          </View>
        </>
      )}

      {comments.logoAttachments && (
        <View style={styles.baseComment}>
          <Text>
            {t('control_body.survey.form.comment_from_auditor')} {comments.logoAttachments}
          </Text>
        </View>
      )}

      {reportType === FINAL && followUpLogoAttachmentsPhotos.length > 0 && (
        <>
          <View style={styles.checklistMarginTwenty}>
            <View style={styles.checkListFlexSpaceBetween}>
              <Text style={styles.heading1}> {t('control_body.survey.form.uploaded_files_followup')} </Text>
            </View>
          </View>
          <View style={styles.checklistMarginTen}>
            {followUpLogoAttachmentsPhotos.map(logo => (
              <View style={{ ...styles.checkListFlex, ...styles.checklistMarginTen }}>
                <Image src={ImageUpload} style={styles.imageLogo} />
                <Text>{truncateFilename(logo?.filename) || t('common.not_applicable')}</Text>
              </View>
            ))}
          </View>
        </>
      )}
    </>
  );
};

LogoAttachments.propTypes = {
  data: PropTypes.array.isRequired,
  reportType: PropTypes.string.isRequired,
  comments: PropTypes.shape().isRequired,
  logoAttachmentPhotos: PropTypes.array.isRequired,
  followUpLogoAttachmentsPhotos: PropTypes.array.isRequired,
  activeLogosFromAuditor: PropTypes.array.isRequired,
};

export default LogoAttachments;
