import React, { useState } from 'react';
import { useGetTVDListQuery } from 'api/tvd';
import Button from 'components/base-components/Button';
import Icon from 'components/base-components/Icon';
import Pagination from 'components/base-components/Pagination/Pagination';
import Popover from 'components/base-components/Popover';
import { t } from 'i18next';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import WarningModal from 'components/shared-components/modal/warning';
import useDebounce from 'hooks/useDebounce';
import { Field, useForm, useFormState } from 'react-final-form';
import Input from 'components/base-components/Input';
import { useSearchParams } from 'react-router-dom';
import { useOrderTvdVignettesMutation } from 'api/tvd';
import TVDTable from './table.component';
import TVDFilterModal from './tvd-filter-modal';
import './tvd.styles.scss';

const TVD = () => {
  const form = useForm();
  const [isPopoverOpen, setOpenPopover] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const { values } = useFormState();
  const [searchParams, setSearchParams] = useSearchParams({
    q: '',
    page: 1,
  });

  const [exportTvdToPrintingServer, { isLoading, isSuccess, requestId, isError }] =
    useOrderTvdVignettesMutation();

  const currentPage = parseInt(searchParams.get('page'));
  const currentTab = searchParams.get('tab');
  const queryParam = searchParams.get('q');
  const organizationIds = searchParams.getAll('organizationIds');

  const tvdParams = {
    q: queryParam,
    page: currentPage,
    'organization_ids[]': organizationIds,
  };

  const { data: tvdData, isFetching } = useGetTVDListQuery(tvdParams);

  const tvds = tvdData?.collection || [];
  const totalTvdCount = tvdData?.pagination.totalCount;

  const selectCount = tvds => {
    const allTvds = values?.tvds && [].concat(...values?.tvds);
    const selectedItemsLength = allTvds?.filter(list => list?.checked)?.length;

    if (values.selectAllTVD) {
      let countedSelectedTVD = totalTvdCount;

      const deselectedItemsLength = allTvds?.filter(list => !list?.checked)?.length;

      return t('tvd.selected_tvd', {
        count: countedSelectedTVD - deselectedItemsLength,
      });
    }

    return t('tvd.selected_tvd', {
      count: selectedItemsLength,
    });
  };

  const getTotalCount = () => values?.tvds?.flat().filter(tvd => tvd.checked).length;

  const onExportTvd = () => {
    setOpenUploadModal(false);
    return exportTvdToPrintingServer().unwrap();
  };

  const setSearchQueryInParams = useDebounce(() => {
    setSearchParams({ q: searchInput, page: 1, tab: currentTab });
  });

  const onSearch = value => {
    setSearchInput(value);
    setSearchQueryInParams(value);
  };

  return (
    <>
      <WarningModal
        onOutsideClick={() => {
          setOpenUploadModal(false);
        }}
        isVisible={openUploadModal}
        title={t('tvd.export.modal.title.warning')}
        content={
          <div>
            <p>{t('tvd.export.modal.content.warning.acknowledge')}</p>
            <div>
              <div>{t('tvd.export.modal.content.warning.note')}</div>
              <span>{t('tvd.export.modal.content.warning.continue')}</span>
            </div>
          </div>
        }
        confirmActionText={t('shared.action.yes')}
        cancelActionText={t('shared.action.no')}
        onCancel={() => setOpenUploadModal(false)}
        onConfirm={onExportTvd}
        classNames={'tvd__modal'}
      />
      <SubmitModal
        isLoading={isLoading}
        isSuccess={isSuccess}
        requestId={requestId}
        successTitle={t('tvd.export.modal.title.success')}
        successContent={t('tvd.export.modal.content.success')}
        showLoader={true}
        showError={true}
        isError={isError}
        errorTitle={t('tvd.export.modal.content.error')}
      />
      <div className="invoice__header--wrapper">
        <div className="user__list--header">
          <div className="organisation__search-tab">
            <Input
              className="organisation__search"
              placeholder={t('organisation.search_organisation')}
              preIcon={<Icon name="search" />}
              size="tiny"
              value={searchInput}
              onChange={onSearch}
            />
            {/* // TODO Need to show the filter in https://welltravel.atlassian.net/browse/BIOS-1187 this story, since we are not showing the filter right now */}
            <Button
              onClick={() => setIsFilterVisible(true)}
              label={
                <span className="organisation__filter-button tvd__filter-button">
                  <Icon fill="none" name="filter" />
                  <span>{t('shared.action.filter')}</span>
                </span>
              }
              size="small"
            />
          </div>
        </div>
        <div>
          <Button
            className="tvd__create-button"
            type="success"
            size="small"
            label={t('tvd.list.export_and_upload_tvd')}
            onClick={() => setOpenUploadModal(true)}
          />
        </div>
      </div>

      {getTotalCount() > 0 && (
        <div className="recognition__marks--wrapper">
          <Icon name="check" color="tertiary" />
          <div className="recognition__selection-text">{selectCount(values.tvds)}</div>
          <div className="recognition__vertical-line" />
          <Field name="selectAllTVD">
            {({ input, meta }) => (
              <div
                className="recognition__selection-text recognition__select-all"
                onClick={() => input.onChange(true)}
              >
                {t('tvd.select_all', { count: totalTvdCount })}
              </div>
            )}
          </Field>
          <div className="recognition__vertical-line" />
          <div
            className="recognition__selection-text recognition__clear-all"
            onClick={() => form.reset()}
          >
            {t('tvd.clear')}
          </div>
          <Popover
            isVisible={isPopoverOpen}
            size="medium"
            content={
              <div className="task__popover">
                <div className="task__popover-content" onClick={() => {}}>
                  {t('recognition.details.mark_as_sent')}
                </div>
                <div className="task__popover-content" onClick={() => {}}>
                  <span>{t('recognition.details.download_all')}</span>
                </div>
                <div className="task__popover-content" onClick={() => {}}>
                  {t('recognition.details.delete_all')}
                </div>
              </div>
            }
            direction="bottom-right"
            onOutsideClick={() => setOpenPopover(false)}
          >
            <Button
              label={t('recognition.details.bulk_actions')}
              size="small"
              onClick={() => setOpenPopover(!isPopoverOpen)}
            />
          </Popover>
        </div>
      )}
      <TVDTable tvds={tvds} isFetching={isFetching} currentPage={currentPage} />

      <div className="organisation__pagination invoice__pagination">
        <Pagination
          onPaginationClick={newPage => {
            setSearchParams({ page: newPage, tab: currentTab, q: queryParam });
          }}
          totalPages={tvdData && parseInt(tvdData.pagination.totalPages, 10)}
          currentPage={currentPage}
          firstText={t('pagination.first')}
          lastText={t('pagination.last')}
          nextText={t('pagination.next')}
          previousText={t('pagination.prev')}
        />
      </div>
      <TVDFilterModal isFilterVisible={isFilterVisible} setIsFilterVisible={setIsFilterVisible} />
    </>
  );
};

export default TVD;
