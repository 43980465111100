import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TableData = props => {
  const { className, children, align, unavailableText, heading, smallScreenConfig, colspan } =
    props;
  const { order, isHeaderHidden, showInTop, isFullWidth } = smallScreenConfig;

  return (
    <td
      className={classNames('table__field', className, `table__field--align-${align}`, {
        'table__field--unavailable': !children,
        'table__field--top-left': showInTop === 'left',
        'table__field--top-right': showInTop === 'right',
        'table__field--full-width': isFullWidth,
      })}
      style={{ '--order-in-smaller-screen': order }}
      colSpan={colspan}
    >
      <div className="table__field-value">{!children ? unavailableText : children}</div>
      {!isHeaderHidden && heading && <div className="table__field-label">{heading}</div>}
    </td>
  );
};

TableData.defaultProps = {
  colspan: null,
  align: 'left',
  children: null,
  className: null,
  unavailableText: null,
  heading: null,
  textCase: 'capitalize',
  smallScreenConfig: {
    order: 1,
    isHeaderHidden: false,
    showInTop: null,
    isFullWidth: false,
  },
};

TableData.propTypes = {
  colspan: PropTypes.number,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  unavailableText: PropTypes.node,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  textCase: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize']),
  smallScreenConfig: PropTypes.shape({
    order: PropTypes.number,
    isHeaderHidden: PropTypes.bool,
    showInTop: PropTypes.oneOf(['left', 'right', null]),
    isFullWidth: PropTypes.bool,
  }),
};

export default TableData;
