import React from 'react';
import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';
import { t } from 'i18next';

const EnsureTransparencyComponent = () => (
  <Card className="product-cultivation__outside-card product-cultivation__ensure-transparency--card">
    <div className="product-cultivation__ensure-transparency">
      <Icon name="info" size="small" color="tertiary" showLabel={true} />
      <div className="product-cultivation__ensure-transparency--text">
        <span className="product-cultivation__ensure-transparency--title">
          {t('producer.ensure_transparency.title')}
        </span>
        <span>{t('producer.ensure_transparency.description')}</span>
      </div>
    </div>
  </Card>
);

export default EnsureTransparencyComponent;
