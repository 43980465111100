import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useGetOrganizationQuery } from 'api/organizations';
import STARS_STATUS from 'utils/star_status';
import OrganizationName from 'components/organisations/organization-name';
import BioCuisineStatus from 'components/organisations/bio-cuisine-status.component';

const OrganizationBasic = ({ organizationId, fetchedOrganisation }) => {
  const { t } = useTranslation();
  const { data } = useGetOrganizationQuery(organizationId, {
    skip: fetchedOrganisation?.id,
  });

  const organisation = data || fetchedOrganisation;

  return (
    <div className="dashboard__upcoming-control-organisation">
      {organisation && (
        <Fragment>
          <OrganizationName fetchedOrganisation={organisation} />
          {organisation.bioCuisineStars === 0 ? (
            <span className="dashboard--disabled">{t('bio_cuisine_status.not_certified')}</span>
          ) : (
            <BioCuisineStatus
              status={STARS_STATUS[organisation.bioCuisineStars]}
              displayAllStars={true}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};

OrganizationBasic.defaultProps = {
  fetchedOrganisation: null,
};

OrganizationBasic.propTypes = {
  organizationId: PropTypes.number.isRequired,
  fetchedOrganisation: PropTypes.shape(),
};

export default OrganizationBasic;
