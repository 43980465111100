import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import PopoverPortal from './subComponents/PopoverPortal';
import {
  popoverPositionFromPage,
  getDerivedPopoverDirection,
  getNodeDimension,
} from './helpers/index';
import isInsideWindow from './helpers/isInsideWindow';

const Popover = props => {
  const {
    className,
    isVisible,
    children,
    content,
    direction,
    size,
    tipSize,
    showTip,
    onOutsideClick,
  } = props;
  const [popoverDimension, setPopoverDimension] = useState({ height: 0, width: 0 });
  const [derivedPopoverDirection, setDerivedPopoverDirection] = useState(direction); // Best direction to keep the popover inside the window
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  const [parentInsideWindow, setParentInsideWindow] = useState(true);
  const parentElementRef = useRef(null);
  const popoverRef = useRef(null);
  const parentNode = parentElementRef.current;
  const popoverNode = popoverRef.current;

  const onPositionChangeHandler = () => {
    if (popoverNode) {
      setPopoverDimension(getNodeDimension(popoverNode));
    }

    if (parentNode && isVisible) {
      setParentInsideWindow(isInsideWindow(parentNode));
      setDerivedPopoverDirection(
        getDerivedPopoverDirection(parentNode, direction, popoverDimension, tipSize),
      );
      setPopoverPosition(
        popoverPositionFromPage(parentNode, derivedPopoverDirection, popoverDimension, tipSize),
      );
    }
  };

  useEffect(() => {
    if (popoverNode) {
      setPopoverDimension(getNodeDimension(popoverNode));
    }
  }, [popoverNode, size]);

  useEffect(() => {
    if (parentNode && isVisible) {
      setDerivedPopoverDirection(
        getDerivedPopoverDirection(parentNode, direction, popoverDimension, tipSize),
      );
    }
  }, [parentNode, direction, size, isVisible, popoverDimension, tipSize]);

  useEffect(() => {
    if (parentNode && isVisible) {
      setPopoverPosition(
        popoverPositionFromPage(parentNode, derivedPopoverDirection, popoverDimension, tipSize),
      );
    }
  }, [parentNode, derivedPopoverDirection, size, isVisible, popoverDimension, parentInsideWindow]);

  useEffect(() => {
    window.addEventListener('resize', onPositionChangeHandler);

    if (!isVisible) {
      window.removeEventListener('resize', onPositionChangeHandler);
    }

    return () => window.removeEventListener('resize', onPositionChangeHandler);
  }, [isVisible, onPositionChangeHandler]);

  useEffect(() => {
    if (parentNode) {
      setParentInsideWindow(isInsideWindow(parentNode));
    }
  }, [isVisible, parentNode]);

  return (
    <>
      <span className="rcl-popover__parent" ref={parentElementRef}>
        {children}
      </span>
      {
        /* If the parent goes outside the browser window due to overflow
      the popover will be hidden forcefully */
        parentInsideWindow && isVisible ? (
          <PopoverPortal
            className={className}
            parentElementRef={parentElementRef}
            ref={popoverRef}
            content={content}
            direction={derivedPopoverDirection}
            size={size}
            tipSize={tipSize}
            showTip={showTip}
            position={popoverPosition}
            onOutsideClick={onOutsideClick}
          />
        ) : null
      }
    </>
  );
};

Popover.defaultProps = {
  children: null,
  content: null,
  className: null,
  isVisible: false,
  direction: 'bottom-left',
  size: 'medium',
  tipSize: 'small',
  showTip: false,
  onOutsideClick: () => {},
};

Popover.propTypes = {
  children: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isVisible: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  tipSize: PropTypes.oneOf(['tiny', 'small']),
  direction: PropTypes.oneOf([
    'top',
    'top-left',
    'top-right',
    'right',
    'right-top',
    'right-bottom',
    'bottom',
    'bottom-left',
    'bottom-right',
    'left',
    'left-top',
    'left-bottom',
  ]),
  className: PropTypes.string,
  showTip: PropTypes.bool,
  onOutsideClick: PropTypes.func,
};

export default Popover;
