import Card from 'components/base-components/Card';
import TitleComponent from 'components/organisations/form/producer/plant-cultivate/shared/title.component';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SharedProductionComponent = ({ title, icon, children, isMultipleCard }) => {
  return (
    <Card
      className={classNames({
        'operations__production--card': !isMultipleCard,
        'operations__production--multiple margin-top-10': isMultipleCard,
      })}
    >
      <TitleComponent title={title} icon={icon} isOutsideForm={true} />
      <hr className="organisation-checklist__checkbox-horizontal-line" />
      {children}
    </Card>
  );
};

SharedProductionComponent.defaultProps = {
  children: null,
  title: null,
  icon: null,
  isMultipleCard: false,
};

SharedProductionComponent.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  icon: PropTypes.string,
  isMultipleCard: PropTypes.bool,
};

export default SharedProductionComponent;
