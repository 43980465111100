import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import SidePanel from 'components/base-components/SidePanel';
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/base-components/Table';
import { organizationAddressToString } from 'components/organisations/organisation-address';
import OrganizationName from 'components/organisations/organization-name';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import TableLoaderPlaceholder from 'components/shared-components/placeholders/table-loader-placeholder';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useGetOrganizationQuery } from 'api/organizations';
import EmphasisTag from 'components/base-components/EmphasisTag';
import TvdOrderForm from './tvd-order-form.component';

const TVDTableRow = ({ tvd, setTvdForOrder }) => {
  const { data: organization = {} } = useGetOrganizationQuery(tvd.organizationId);
  const address = organizationAddressToString(tvd?.stickerAddress || organization.mainAddress);

  return (
    <>
      <TableRow className="organisation__table-row">
        <TableData>{tvd.tvdStickerNumber || t('common.not_applicable')}</TableData>
        <TableData>{address || t('common.not_applicable')}</TableData>
        <TableData>
          <OrganizationName organizationId={tvd.organizationId} />
        </TableData>
        <TableData align="center">
          <EmphasisTag
            className="tvd-list--emphasis-tag"
            type={tvd.tvdMailing ? 'success' : 'disabled'}
            size="tiny"
            text={tvd.tvdMailing ? t('shared.action.yes') : t('shared.action.no')}
          />
        </TableData>
        <TableData align="right">
          <IconButton
            icon={<Icon size="medium" name="sendAlt" />}
            onClick={() => setTvdForOrder(tvd)}
          />
        </TableData>
      </TableRow>
    </>
  );
};

export const TVDTableHeader = ({ currentPage }) => {
  return (
    <TableHead>
      <TableRow>
        <TableHeader>{t('tvd.list.table.tvd_no')}</TableHeader>
        <TableHeader>{t('tvd.list.table.address')}</TableHeader>
        <TableHeader>{t('tvd.list.table.associated_organisation')}</TableHeader>
        <TableHeader align="center">
          {t('organisation_view.tvd.table.header.tvd_mailing')}
        </TableHeader>
        <TableHeader align="right">{t('shared.action.action')}</TableHeader>
      </TableRow>
    </TableHead>
  );
};

const TVDTable = ({ tvds, isFetching, currentPage }) => {
  const [tvdForOrder, setTvdForOrder] = useState(null);

  return (
    <>
      {!isFetching && tvds.length === 0 ? (
        <EmptyContentPlaceholder iconName="tvd" text={t('tvd.no_tvd')} />
      ) : (
        <>
          <Table className="organisation_table">
            <TVDTableHeader currentPage={currentPage} />
            <TableBody>
              {isFetching ? (
                <TableLoaderPlaceholder numberOfRows={10} numberOfColumns={5} />
              ) : (
                tvds.map((tvd, index) => (
                  <TVDTableRow
                    key={`tvd-list-${tvd.tvdStickerNumber}`}
                    tvd={tvd}
                    index={index}
                    currentPage={currentPage}
                    setTvdForOrder={setTvdForOrder}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </>
      )}

      <SidePanel
        isOpened={!!tvdForOrder}
        title={t('tvd.order.title')}
        body={tvdForOrder && <TvdOrderForm tvd={tvdForOrder} setTvdForOrder={setTvdForOrder} />}
        onClose={() => setTvdForOrder(null)}
      />
    </>
  );
};

TVDTableHeader.propTypes = {
  currentPage: PropTypes.number.isRequired,
};

TVDTableRow.propTypes = {
  tvd: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setTvdForOrder: PropTypes.func.isRequired,
};

TVDTable.defaultProps = {
  isFetching: false,
  totalInvoiceCount: '',
  currentPage: 1,
};

TVDTable.propTypes = {
  tvds: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isFetching: PropTypes.bool,
  currentPage: PropTypes.number,
};

export default TVDTable;
