const GENERAL = 'general';
const LEGAL_FORM = 'legal_form';
const CONTACT_AND_ADDRESS = 'contact_and_address';
const CONTROL_BODY = 'control_body';
const FARMLAND = 'farmland';
const PRODUCTION_DATA = 'production_data';
const TVD = 'tvd';
const MEMBERSHIPS = 'memberships';

export {
  GENERAL,
  LEGAL_FORM,
  CONTACT_AND_ADDRESS,
  CONTROL_BODY,
  FARMLAND,
  PRODUCTION_DATA,
  TVD,
  MEMBERSHIPS,
};
