import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/base-components/Icon';
import bytesToMegabytes from 'utils/bytes-to-megabytes';
import IconButton from 'components/base-components/IconButton';

const UploadLogoAttachmentItem = ({ uploadedFile, handleFileDelete, isAuditor }) => {
  const filename = uploadedFile.file ? uploadedFile.file.name : uploadedFile.filename;
  const fileSize = uploadedFile.file ? uploadedFile.file.size : uploadedFile.sizeInByte;

  return (
    <div
      key={uploadedFile.id}
      className="col-6 col-bleed logo-attachment-task__file-dynamic-margin"
    >
      <div className="logo-attachment-task__file-wrapper">
        <div className="logo-attachment-task__file-info">
          <Icon name="photo" color="tertiary" />
          <span className="logo-attachment-task__file-info--name">{filename}</span>
        </div>

        <div className="organisation-checklist__audio-card--icon">
          <span>{bytesToMegabytes(fileSize)} MB</span>
          {uploadedFile?.url && (
            <a href={uploadedFile?.url} target="_blank" rel="noreferrer">
              <IconButton
                className="topnav__back-button"
                icon={
                  <Icon
                    className="choose-file__contract-delete--icon"
                    name="downloadFile"
                    size="small"
                  />
                }
              />
            </a>
          )}
          <IconButton
            className="topnav__back-button"
            color="danger"
            disabled={!isAuditor}
            icon={
              <Icon
                className="choose-file__contract-delete--icon"
                name="delete"
                size="small"
                color="danger"
              />
            }
            onClick={() => handleFileDelete(uploadedFile)}
          />
        </div>
      </div>
    </div>
  );
};

UploadLogoAttachmentItem.propTypes = {
  uploadedFile: PropTypes.shape().isRequired,
  handleFileDelete: PropTypes.func.isRequired,
  isAuditor: PropTypes.bool.isRequired,
};

export default UploadLogoAttachmentItem;
