import React, { useId } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/base-components/Icon';
import {
  Table,
  TableBody,
  TableData,
  TableHeader,
  TableHead,
  TableRow,
} from 'components/base-components/Table';
import Spinner from 'components/base-components/Spinner';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import { useGetUsersEducationQuery } from 'api/educations';
import { useParams } from 'react-router';
import { dateMonthYearFormatter } from '../../../utils/date-time-formatter';
import '../users.styles.scss';

const EducationListComponent = () => {
  const { id } = useParams();

  const { isFetching, data: userEducationListData, isError } = useGetUsersEducationQuery(id); // TODO: Will remove this after showing to client

  const { t } = useTranslation();
  const pid = useId();

  return (
    <>
      {isFetching || isError ? (
        <Spinner className="user-details__spinner" bgColor="none" color="success" size="small" />
      ) : userEducationListData && userEducationListData.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader>{t('user_details.education.table_head.degree')}</TableHeader>
              <TableHeader>{t('user_details.education.table_head.certification')}</TableHeader>
              <TableHeader>{t('user_details.education.table_head.institute')}</TableHeader>
              <TableHeader align="center">
                {t('user_details.education.table_head.diploma')}
              </TableHeader>
              <TableHeader align="center">{t('user_details.education.table_head.doc')}</TableHeader>
              <TableHeader align="center">{t('user_details.education.table_head.dod')}</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {userEducationListData.map((education, index) => (
              <TableRow key={`${pid}--${index}`} className="user__education-table--row">
                <TableData>
                  {education.degree
                    ? t(`educations.degree.${education.degree}`)
                    : t('common.not_applicable')}
                </TableData>
                <TableData>{education.certificationId || t('common.not_applicable')}</TableData>
                <TableData>
                  {education.institute
                    ? t(`educations.institute.${education.institute}`)
                    : t('common.not_applicable')}
                </TableData>
                <TableData>
                  <Icon className="user-details__report-icon" name="downloadFile" size="normal" />
                </TableData>
                <TableData align="center">
                  {education.certificationDate
                    ? dateMonthYearFormatter(education.certificationDate)
                    : t('common.not_applicable')}
                </TableData>
                <TableData align="center">
                  {education.declarationDate
                    ? dateMonthYearFormatter(education.declarationDate)
                    : t('common.not_applicable')}
                </TableData>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <EmptyPlaceholder
          emptyCardText={t('user_details.education.empty_placeholder')}
          emptyIcon={<Icon name="stickyNote" />}
        />
      )}
    </>
  );
};

export default EducationListComponent;
