import { Text, View } from '@react-pdf/renderer';
import { Break } from 'components/pdf/shared/shared-components';
import styles from 'components/pdf/styles/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {
  addressHeader,
  contact,
  footerFirstText,
  footerFourthText,
  footerSecondText,
  footerThirdText,
  mainAddress,
  urlLink,
} from 'utils/producer-contract-data';

const ProducerContractFooter = ({ showInLastPageOnly }) => (
  <View fixed={true}>
    <View
      style={styles.footer}
      render={({ pageNumber, totalPages }) => {
        if (showInLastPageOnly && pageNumber !== totalPages) return;

        return (
          <>
            <View style={{ width: '100%', marginRight: 5 }}>
              <Text style={{ ...styles.footerText, textAlign: 'right' }}>
                {footerFirstText}
                <Break />
                {footerSecondText}
                <Break />
                {footerThirdText}
                <Break />
                {footerFourthText}
              </Text>
            </View>
            <View style={{ width: '100%', marginLeft: 5 }}>
              <Text style={styles.footerText}>
                {addressHeader} <Break />
                {mainAddress} <Break />
                {contact} <Break />
                {urlLink} <Break />
              </Text>
            </View>
          </>
        );
      }}
    />
  </View>
);

ProducerContractFooter.defaultProps = {
  showInLastPageOnly: false,
};

ProducerContractFooter.propTypes = {
  showInLastPageOnly: PropTypes.bool,
};

export default ProducerContractFooter;
