import React, { useMemo } from 'react';
import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';
import { useParams } from 'react-router';
import {
  useGetOrganizationsQuery,
  useCheckAlreadyRegisteredOrganizationQuery,
} from 'api/organizations';
import { debounce } from 'throttle-debounce';
import ORGANISATION_TYPE from 'utils/organisation-type';
import RadioWithoutCheck from 'components/base-components/RadioWithoutCheck';
import SelectBox from 'components/base-components/SelectBox';
import SubOrganisationField from '../sub-organisation-field';

const LegalForm = ({
  uidSearchQuery,
  organizationRegistryResponse,
  parentOrganizationId,
  validationError,
}) => {
  const [organizationQuery, setOrganizationQuery] = useState(null);
  const { t } = useTranslation();
  const { id } = useParams();

  const { values } = useFormState();

  const searchParams = {
    q: organizationQuery,
    'types[]': [ORGANISATION_TYPE.parent_organisation],
  };
  const { data: organisationCollection, isLoading } = useGetOrganizationsQuery(searchParams);

  const { error: alreadyRegisteredOrganisation } = useCheckAlreadyRegisteredOrganizationQuery(
    { uid: uidSearchQuery, type: ORGANISATION_TYPE.parent_organisation },
    { skip: !organizationRegistryResponse },
  );

  const onSearchOrganisation = debounce(500, value => {
    setOrganizationQuery(value);
  });

  const organisationTypeOptions = [
    {
      name: t('organisation_type.single_organisation'),
      value: ORGANISATION_TYPE.single_organisation,
    },
    {
      name: t('organisation_type.parent_organisation'),
      value: ORGANISATION_TYPE.parent_organisation,
    },
    {
      name: t('organisation_type.sub_organisation'),
      value: ORGANISATION_TYPE.sub_organisation,
    },
  ];

  const fetchedOrganisations = organisationCollection?.organizations || [];
  const eligibleOrgaisations = alreadyRegisteredOrganisation
    ? fetchedOrganisations.filter(organisation => organisation.uid === uidSearchQuery)
    : fetchedOrganisations;

  const parentOrganisationOptions = eligibleOrgaisations.map(organisation => ({
    label: organisation.name,
    value: organisation.id,
  }));

  const initialParentOrganisation = useMemo(
    () => parentOrganisationOptions.find(option => option.value === parentOrganizationId),
    [isLoading],
  );

  return (
    <Fragment>
      <div className="organisation__form--margin-top organisation__form-type">
        {t('organisation_type.title')}
      </div>
      <div className="organisation__form--margin-top organisation__form--radio">
        <Field name="organisationType">
          {({ input }) =>
            organisationTypeOptions.map((item, index) => (
              <RadioWithoutCheck
                className="organisation__form--radio__item"
                isChecked={item.value === input.value}
                label={item.name}
                name={`item-${item.value}`}
                key={`item-${item.value}`}
                onChange={() => input.onChange(item.value)}
                size="medium"
                disabled={id}
              />
            ))
          }
        </Field>
      </div>
      {values.organisationType === ORGANISATION_TYPE.parent_organisation && (
        <SubOrganisationField
          organisationType={values.organisationType}
          errors={validationError?.subOrganizations}
        />
      )}

      {values.organisationType === ORGANISATION_TYPE.sub_organisation && (
        <Field name="parentOrganisation" initialValue={initialParentOrganisation}>
          {({ input }) => {
            return (
              <SelectBox
                onInputChange={onSearchOrganisation}
                isSearchable={true}
                size="tiny"
                width="small"
                className="organisation__form--margin-top organisation__form--parent-select"
                label={t('organisation.form.attributes.parent_organisation')}
                placeholderText={t('organisation.form.placeholder.search_parent')}
                required={true}
                isClearable={false}
                options={parentOrganisationOptions}
                isDisabled={!!initialParentOrganisation}
                {...input}
              />
            );
          }}
        </Field>
      )}
    </Fragment>
  );
};
LegalForm.defaultProps = {
  parentOrganizationId: null,
  validationError: {},
};
LegalForm.propTypes = {
  uidSearchQuery: PropTypes.string.isRequired,
  organizationRegistryResponse: PropTypes.shape().isRequired,
  parentOrganizationId: PropTypes.number,
  validationError: PropTypes.shape(),
};

export default LegalForm;
