import { Image, Text, View } from '@react-pdf/renderer';
import ImageUpload from 'assets/image-upload.png';
import { ListItem } from 'components/pdf/shared/shared-components';
import styles from 'components/pdf/styles/checklist-styles';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { monthYearShort } from 'utils/date-time-formatter';
import truncateFilename from 'utils/truncate-filename';
import { FINAL } from 'utils/verification-status';
import { VAT_PERCENTAGE_REASON } from 'utils/control-body-survey';

const RandomGoods = ({
  goodsDeclarationEvaluation,
  randomGoodsDatas,
  requiredDeliveryNotesCount,
  comments,
  reportType,
}) => {
  const totalAmount = id => {
    const selectedGoods = randomGoodsDatas.find(item => item.id === id);
    if (selectedGoods) {
      // TODO: use selectedGoods.totalAmount currently not available in this branch
      return (
        parseInt(selectedGoods.month1TotalAmount, 10) +
        parseInt(selectedGoods.month2TotalAmount, 10) +
        parseInt(selectedGoods.month3TotalAmount, 10)
      );
    }

    return 0;
  };

  const validityPeriod = id => {
    const selectedGoods = randomGoodsDatas.find(item => item.id === id);

    if (selectedGoods) {
      return `${monthYearShort(selectedGoods.periodFrom)} - ${monthYearShort(
        selectedGoods.periodUntil,
      )}`;
    }

    return null;
  };

  let vatPercentageReason;

  if (goodsDeclarationEvaluation?.VatPercentageReason === VAT_PERCENTAGE_REASON.other) {
    vatPercentageReason = t('shared.other');
  } else if (goodsDeclarationEvaluation?.VatPercentageReason) {
    vatPercentageReason = t(
      `control_body.survey.options.vat_percentage_reasons.${goodsDeclarationEvaluation?.VatPercentageReason}`,
    );
  } else {
    vatPercentageReason = t('common.not_applicable');
  }

  return (
    <div>
      {requiredDeliveryNotesCount() > 0 && (
        <View>
          <Text style={styles.checklistMarginForty}>
            <Text style={styles.bold}>5. </Text> {t('control_body.survey.form.fifth_step')}
          </Text>
          <Text style={styles.checklistMarginTen}>
            {t('control_body.survey.form.vat_percentage')}:{' '}
            {goodsDeclarationEvaluation.vatPercentage}%
          </Text>
          <Text style={styles.checklistMarginTen}>
            {t('shared.reason')}: {vatPercentageReason}
          </Text>
          <Text style={styles.checklistMarginTen}>
            {t('control_body.survey.form.vat_percentage_comment')}:{' '}
            {goodsDeclarationEvaluation.commentToChangeVatPercentage}
          </Text>
          {goodsDeclarationEvaluation?.randomGoodsDeclarations.map(declaration => (
            <View>
              <View style={styles.checkListFlexSpaceBetween}>
                <Text style={styles.checklistMarginTwenty}>
                  {t('negative_report.random_goods.header')}{' '}
                  <Text style={styles.bold}>{validityPeriod(declaration.goodsDeclarationId)}</Text>:
                </Text>
                <Text>{totalAmount(declaration.goodsDeclarationId)} CHF</Text>
              </View>
              <View style={styles.checkListFlexSpaceBetween}>
                <Text style={styles.checklistMarginTen}>
                  {t('negative_report.random_goods.vat')}{' '}
                  <Text style={styles.bold}>{validityPeriod(declaration.goodsDeclarationId)}</Text>
                </Text>
                <Text>
                  {declaration?.customInput
                    ? `${declaration?.customInput} CHF`
                    : t('common.not_applicable')}
                </Text>
              </View>
            </View>
          ))}
          {comments.randomDeclarations && (
            <View style={styles.baseComment}>
              <Text>
                {t('control_body.survey.form.comment_from_auditor')} {comments.randomDeclarations}
              </Text>
            </View>
          )}
          {requiredDeliveryNotesCount() > 0 && (
            <View>
              <Text style={styles.checklistMarginTwenty}>
                {t('control_body.survey.form.delivery_list_items', {
                  count: requiredDeliveryNotesCount(),
                })}
              </Text>
              <Text style={styles.checklistMarginFive}>
                {t('control_body.survey.form.recorded_values')}
              </Text>
              <ListItem>{t('control_body.survey.form.organisation.list_item1')}</ListItem>
              <ListItem>{t('control_body.survey.form.organisation.list_item2')}</ListItem>
              <ListItem>{t('control_body.survey.form.organisation.list_item3')}</ListItem>
              <ListItem>{t('control_body.survey.form.organisation.list_item4')}</ListItem>
              <ListItem>{t('control_body.survey.form.organisation.list_item5')}</ListItem>
              <View style={styles.checklistMarginTwenty}>
                <View style={styles.checkListFlexSpaceBetween}>
                  <Text style={styles.heading1}>
                    {' '}
                    {t('control_body.survey.form.uploaded_files')}{' '}
                  </Text>
                  <Text>
                    {goodsDeclarationEvaluation?.deliveryNotes?.length}/
                    {requiredDeliveryNotesCount()} {t('control_body.survey.form.uploaded')}
                  </Text>
                </View>
              </View>
              <View style={styles.checklistMarginTen}>
                {goodsDeclarationEvaluation &&
                  goodsDeclarationEvaluation?.deliveryNotes?.map(note => (
                    <View style={{ ...styles.checkListFlex, ...styles.checklistMarginTen }}>
                      <Image src={ImageUpload} style={styles.imageLogo} />
                      <Text>{truncateFilename(note?.filename) || t('common.not_applicable')}</Text>
                    </View>
                  ))}
              </View>
              <View>
                <Text style={styles.checklistMarginTwenty}>
                  {t('control_body.survey.form.comment_from_auditor')}{' '}
                  {goodsDeclarationEvaluation?.deliveryNotesComment || t('common.not_applicable')}
                </Text>
              </View>
              <View style={styles.checkListFlexSpaceBetween}>
                <Text style={styles.checklistMarginTen}>
                  {t('control_body.survey.form.auditor_acknowledgement')}
                </Text>
                <Text style={styles.checklistMarginTen}>
                  {goodsDeclarationEvaluation?.certifierAcknowledgement !== null
                    ? goodsDeclarationEvaluation?.certifierAcknowledgement
                      ? t('shared.action.yes')
                      : t('control_body.survey.shared.no')
                    : goodsDeclarationEvaluation?.auditorAcknowledgement
                    ? t('shared.action.yes')
                    : t('control_body.survey.shared.no')}
                </Text>
              </View>
              {reportType === FINAL && goodsDeclarationEvaluation?.certifierComment && (
                <View style={styles.baseComment}>
                  <Text>
                    {t('control_body.survey.form.reason_of_change')}{' '}
                    {goodsDeclarationEvaluation?.certifierComment}
                  </Text>
                </View>
              )}
            </View>
          )}
        </View>
      )}
    </div>
  );
};

RandomGoods.propTypes = {
  goodsDeclarationEvaluation: PropTypes.shape().isRequired,
  randomGoodsDatas: PropTypes.array.isRequired,
  requiredDeliveryNotesCount: PropTypes.func.isRequired,
  comments: PropTypes.shape().isRequired,
  reportType: PropTypes.string.isRequired,
};

export default RandomGoods;
