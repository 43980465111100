import routes from 'utils/routes';
import { apiSlice } from '../index';

const availableLocalesApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getAvailableLocales: build.query({
      query: () => routes.availableLocales(),
    }),
  }),
  overrideExisting: false,
});

export const { useGetAvailableLocalesQuery } = availableLocalesApi;
