import React from 'react';
import Card from 'components/base-components/Card';
import TitleComponent from 'components/organisations/form/producer/plant-cultivate/shared/title.component';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import {
  KITCHEN_HERBS,
  MEDICINAL_HERBS,
  POTTED_HERBS,
  SPICE_AND_TEA_HERBS,
} from 'utils/plant-cultivation';
import { isNonEmptyObject } from 'utils/check-object-length';
import HerbContent from './shared/herb-content.component';

const HerbsData = ({ title, icon, values }) => {
  let herbsContentData = {};
  const herbs = [MEDICINAL_HERBS, POTTED_HERBS, SPICE_AND_TEA_HERBS, KITCHEN_HERBS];

  herbs.forEach(herb => {
    const data = values?.find(value => value?.plantType === herb);

    if (data) {
      herbsContentData[herb] = data;
    }
  });

  return (
    <>
      {isNonEmptyObject(herbsContentData) && (
        <Card className="operations__production--content">
          <TitleComponent title={title} icon={icon} isOutsideForm={true} />
          <hr className="organisation-checklist__checkbox-horizontal-line" />
          <>
            {Object.keys(herbsContentData)?.map(herb => (
              <HerbContent
                key={herb}
                data={herbsContentData[herb]}
                title={t(`plant_cultivation.herbs.${herb}`)}
              />
            ))}
          </>
        </Card>
      )}
    </>
  );
};

HerbsData.defaultProps = {
  values: {},
  isCheckboxData: false,
};

HerbsData.propTypes = {
  title: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  values: PropTypes.shape(),
  isCheckboxData: PropTypes.bool,
};

export default HerbsData;
