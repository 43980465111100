import { useGetSupplierCertificatesOverviewQuery } from 'api/supplier-certificates';
import classNames from 'classnames';
import ChartaValidity from 'components/shared-components/charta-validity/charta-validity.component';
import VerificationTag from 'components/shared-components/verification-tag';
import PropTypes from 'prop-types';
import React from 'react';
import '../styles.scss';

const CurrentDeclarationHeader = ({ organizationId, currentCertificateDeclarations }) => {
  const { data: certificatesOverview = {} } = useGetSupplierCertificatesOverviewQuery({
    organizationId,
  });

  const lastDeclaration =
    currentCertificateDeclarations?.length > 0 && currentCertificateDeclarations[0];

  const validFrom = lastDeclaration.validFrom;
  const validUntil = lastDeclaration.validUntil;

  return (
    <div className="supplier-certificates supplier-certificates__wrapper">
      <div className="supplier-certificates__header">
        <div className="supplier-certificates__header-charta-period">
          <ChartaValidity
            validFrom={validFrom}
            validUntil={validUntil}
            declaredAt={
              (currentCertificateDeclarations &&
                currentCertificateDeclarations?.length > 0 &&
                currentCertificateDeclarations[0].declarationDate) ||
              null
            }
            declaredOnTransKey={'supplier_certificates.time_period.declared_on'}
            tag={
              <span
                className={classNames('supplier-certificates__validity-time--tag', {
                  'supplier-certificates__validity-time--tag-spacing': !validFrom || !validUntil,
                })}
              >
                <VerificationTag status={certificatesOverview.status} />
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
};

CurrentDeclarationHeader.propTypes = {
  currentCertificateDeclarations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  organizationId: PropTypes.number.isRequired,
};

export default CurrentDeclarationHeader;
