import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TableCaption = ({ className, children, align }) => (
  <caption className={classNames('table__caption', className, `table__caption-text--${align}`)}>
    {children}
  </caption>
);

TableCaption.defaultProps = {
  className: null,
  align: 'left',
};

TableCaption.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  align: PropTypes.oneOf(['left', 'center', 'right']),
};

export default TableCaption;
