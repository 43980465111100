import React, { Fragment } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useGetOrganizationQuery, useGetOrganizationsQuery } from 'api/organizations';
import { useSearchParams } from 'react-router-dom';
import OrganisationHeadComponent from 'components/organisations/organisation-head.component';
import '../../../organisations.styles.scss';
import Pagination from '../../../../base-components/Pagination';
import SubOrganisationOverview from '../../../list/tab-content.component';
import ControlAndOverview from '../../shared/control-and-overview.component';

const SubOrganisationList = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams({
    q: '',
    page: 1,
    parent_id: id || '',
  });

  const currentPage = parseInt(searchParams.get('page'));
  const searchQuery = searchParams.get('q');
  const parentId = searchParams.get('parent_id');

  const organizationParams = {
    q: searchQuery,
    page: currentPage,
    parent_id: parentId,
  };

  const { isFetching, data } = useGetOrganizationsQuery(organizationParams);

  const { isSuccess: isParentOrganisationFetched, data: parentOrganisation } =
    useGetOrganizationQuery(id);

  const subOrganization = data ? data.organizations : [];

  return (
    <>
      {isParentOrganisationFetched && (
        <OrganisationHeadComponent organisation={parentOrganisation} showContract={true} />
      )}
      <ControlAndOverview>
        <SubOrganisationOverview
          tab="overview"
          key="overview"
          searchQuery={searchQuery}
          organizations={subOrganization}
          onChangeParams={setSearchParams}
          isLoading={isFetching}
          industry={parentOrganisation?.industry}
        />
      </ControlAndOverview>
      <Fragment>
        <div className="organisation__pagination">
          <Pagination
            onPaginationClick={newPage => {
              setSearchParams({ page: newPage });
            }}
            totalPages={data && parseInt(data.pagination.totalPages, 10)}
            currentPage={currentPage}
            firstText={t('pagination.first')}
            lastText={t('pagination.last')}
            nextText={t('pagination.next')}
            previousText={t('pagination.prev')}
          />
        </div>
      </Fragment>
    </>
  );
};

export default SubOrganisationList;
