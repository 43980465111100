import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'components/base-components/Badge';

const TitleWithBadge = ({ title, showBadge, count }) => {
  return (
    <div className="modal__content--title-container">
      <span className="modal__content__title">{title}</span>
      {showBadge && count > 0 && (
        <Badge classNameForBadge="modal__content--checkbox-badge" label={count} type="default" />
      )}
    </div>
  );
};

TitleWithBadge.defaultProps = {
  showBadge: false,
  count: 0,
};

TitleWithBadge.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
  showBadge: PropTypes.bool,
};

export default TitleWithBadge;
