import Icon from 'components/base-components/Icon';
import PropTypes from 'prop-types';
import React from 'react';

const ProducerTypeWithIcon = ({ name, i18nTranslation }) => {
  return (
    <div className="plant-cultivation__herbs">
      <Icon fill="none" name={name} color="tertiary" size="medium" />
      <div className="plant-cultivation__herbs--title">{i18nTranslation}</div>
    </div>
  );
};

ProducerTypeWithIcon.propTypes = {
  name: PropTypes.string.isRequired,
  i18nTranslation: PropTypes.string.isRequired,
};

export default ProducerTypeWithIcon;
