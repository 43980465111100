import getNodeDimension from './getNodeDimension';
import getNodePosition from './getNodePosition';
import getWindowWidthWithoutScrollbar from './getWindowWidthWithoutScrollbar';

/**
 * Returns True if the node is inside browser window otherwise false.
 *
 * @param {node} parentNode The DOM node of the popover-parent.
 *
 * @return {boolean} True if the node is inside browser window otherwise false.
 */

const isInsideWindow = node => {
  const { height, width } = getNodeDimension(node);
  const { y: top, x: left } = getNodePosition(node);
  const availableWindowWidth = getWindowWidthWithoutScrollbar();
  const availableWindowHeight = window.innerHeight;
  const isAboveTop = top < 0 && Math.abs(top) >= height;
  const isBelowBottom = top >= availableWindowHeight;
  const isLeftOutside = left < 0 && Math.abs(left) >= width;
  const isRightOutside = left >= availableWindowWidth;
  let isHorizontallyInside = true;
  let isVerticallyInside = true;

  if (isAboveTop || isBelowBottom) {
    isVerticallyInside = false;
  }

  if (isLeftOutside || isRightOutside) {
    isHorizontallyInside = false;
  }

  return isHorizontallyInside && isVerticallyInside;
};

export default isInsideWindow;
