import RoutesComponent from './components/routes/routes.component';
import './App.scss';

function App() {
  return (
    <>
      <div className="app__main">
        <RoutesComponent />
      </div>
    </>
  );
}

export default App;
