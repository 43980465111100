import { useGetUserOrganizationOverviewQuery } from 'api/organizations';
import Badge from 'components/base-components/Badge';
import EmphasisTag from 'components/base-components/EmphasisTag';
import Popover from 'components/base-components/Popover';
import BioCuisineStatus from 'components/organisations/bio-cuisine-status.component';
import OrganizationName from 'components/organisations/organization-name';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import STARS_STATUS from 'utils/star_status';

const OrganisationOverviewComponent = ({ assignment }) => {
  const { t } = useTranslation();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const { data: organisationOverviewData, isSuccess: isOrganisationOverviewFetched } =
    useGetUserOrganizationOverviewQuery(assignment.organizationId);

  const handleTaskCount = task => {
    return task.high + task.medium + task.low;
  };

  const taskBadgeTypes = {
    high: 'new',
    medium: 'warning',
    low: 'inactive',
  };

  const handleTaskBadge = priority => {
    if (priority.high > 0) return taskBadgeTypes.high;
    else if (priority.medium > 0) return taskBadgeTypes.medium;
    else if (priority.low > 0) return taskBadgeTypes.low;
    else return null;
  };

  return (
    <div>
      {isOrganisationOverviewFetched && (
        <div className="dashboard__organisation">
          <div className="dashboard__organisation-details">
            <span className="dashboard__organisation-details__name">
              <OrganizationName organizationId={assignment?.organizationId} />
            </span>
            <BioCuisineStatus
              status={STARS_STATUS[organisationOverviewData?.bioCuisineStars]}
              iconSize="medium"
            />
          </div>
          <div className="dashboard__organisation-details__role">
            {t(`role.${assignment?.role}`)}
          </div>
          <div className="dashboard__organisation-details__task-message-count">
            <div className="dashboard__organisation-details__task-count">
              <span>
                {' '}
                {t('dashboard.tasks', {
                  count: handleTaskCount(organisationOverviewData.taskPriorities),
                })}{' '}
              </span>
              <Popover
                className="dashboard__popover--wrapper"
                isVisible={isPopoverVisible}
                onOutsideClick={() => setIsPopoverVisible(false)}
                content={
                  <>
                    <div className="dashboard__popover">
                      <EmphasisTag type="danger" text={t('tasks.priority.high')} />
                      <span>{organisationOverviewData.taskPriorities.high}</span>
                    </div>
                    <div className="dashboard__popover">
                      <EmphasisTag type="warning" text={t('tasks.priority.medium')} />
                      <span>{organisationOverviewData.taskPriorities.medium}</span>
                    </div>
                    <div className="dashboard__popover">
                      <EmphasisTag type="disabled" text={t('tasks.priority.low')} />
                      <span>{organisationOverviewData.taskPriorities.low}</span>
                    </div>
                  </>
                }
                size="small"
                direction="top"
              >
                <div onClick={() => setIsPopoverVisible(!isPopoverVisible)}>
                  {organisationOverviewData.taskPriorities && (
                    <Badge
                      label={null}
                      type={handleTaskBadge(organisationOverviewData.taskPriorities)}
                      size="small"
                    />
                  )}
                </div>
              </Popover>
            </div>
            <span className="dashboard__organisation-details__message-count">
              {t('dashboard.messages', {
                count: organisationOverviewData.messageCount,
              })}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

OrganisationOverviewComponent.defaultProps = {
  assignment: null,
};

OrganisationOverviewComponent.propTypes = {
  assignment: PropTypes.shape(),
};

export default OrganisationOverviewComponent;
