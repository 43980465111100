import React from 'react';
import { SUBMITTED, COMPLAINED, APPROVED, DRAFT, PENDING } from 'utils/verification-status';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EmphasisTag from '../base-components/EmphasisTag';

const VerificationTag = ({ status }) => {
  const { t } = useTranslation();

  if (status === APPROVED) {
    return (
      <EmphasisTag type="success" size="tiny" text={t('supplier_certificates.status.verified')} />
    );
  }

  if (status === COMPLAINED) {
    return (
      <EmphasisTag type="warning" size="tiny" text={t('product_declarations.status.complained')} />
    );
  }

  if (status === SUBMITTED) {
    return (
      <EmphasisTag type="accent" size="tiny" text={t('supplier_certificates.status.submitted')} />
    );
  }

  if (status === DRAFT) {
    return <EmphasisTag type="accent" size="tiny" text={t('supplier_certificates.status.draft')} />;
  }

  if (status === PENDING) {
    return (
      <EmphasisTag type="warning" size="tiny" text={t('shared.verification_statuses.pending')} />
    );
  }

  return null;
};

VerificationTag.propTypes = {
  status: PropTypes.string.isRequired,
};

export default VerificationTag;
