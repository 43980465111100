import { PillTabs } from 'components/base-components/PillTabs';
import { t } from 'i18next';
import { useGetOrganizationQuery } from 'api/organizations';
import React from 'react';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import { useParams, useSearchParams } from 'react-router-dom';
import OperationComponent from './index.component';
import OthersData from './others-data/index.component';

const OperationComponentWrapper = () => {
  const { id } = useParams();
  const TAB_MAPPER = {
    current_season: 0,
    upcoming_season: 1,
    previous_season: 2,
    history: 3,
  };

  const { data: organisation = {} } = useGetOrganizationQuery(id);

  const customerSince = organisation.customerSince ? parseInt(organisation.customerSince) : null;

  const [searchParams, setSearchParams] = useSearchParams({
    season: TAB_MAPPER[0],
    section: 'operations',
  });

  const currentSeason = searchParams.get('season');

  const onChangeTab = selectedTabKey => {
    const selectedTab = Object.keys(TAB_MAPPER).find(tab => TAB_MAPPER[tab] === selectedTabKey);

    setSearchParams({ season: selectedTab, section: 'operations' });
  };

  const currentYear = new Date().getFullYear();

  const isUpcomingYear = customerSince > currentYear;

  return (
    <PillTabs
      useTransitionOnChange={true}
      controlled={true}
      selectedTabKey={TAB_MAPPER[currentSeason]}
      items={[
        {
          getContent: () => (
            <>
              <OperationComponent
                year={currentYear}
                emptyCardText={t('operations.no_current_data')}
                yearText={t('operations.previous_season_date', {
                  year: currentYear,
                })}
              />
              <OthersData year={currentYear} />
            </>
          ),
          key: 0,
          title: t('operations.seasons.current'),
        },
        {
          getContent: () =>
            isUpcomingYear ? (
              <OperationComponent
                year={customerSince}
                emptyCardText={t('operations.no_upcoming_data')}
                yearText={t('operations.upcoming_season_date', {
                  year: customerSince,
                })}
              />
            ) : (
              <EmptyContentPlaceholder
                iconName="plant_little"
                text={t('operations.no_upcoming_data')}
              />
            ),
          key: 1,
          title: t('operations.seasons.upcoming'),
        },
        {
          getContent: () => (
            <>
              <OperationComponent
                year={currentYear - 1}
                emptyCardText={t('operations.no_previous_data')}
                yearText={t('operations.previous_season_date', {
                  year: currentYear - 1,
                })}
              />
              <OthersData year={currentYear - 1} />
            </>
          ),
          key: 2,
          title: t('operations.seasons.previous'),
        },
        {
          getContent: () => (
            <EmptyContentPlaceholder
              iconName="plant_little"
              text={t('operations.no_history_data')}
            />
          ),
          key: 3,
          title: t('operations.seasons.history'),
        },
      ]}
      onChange={onChangeTab}
    />
  );
};

export default OperationComponentWrapper;
