import { useGetInvoiceQuery, useGetInvoiceTransactionsQuery } from 'api/invoices';
import Card from 'components/base-components/Card';
import EmphasisTag from 'components/base-components/EmphasisTag';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/base-components/Table';
import AmountComponent from 'components/shared-components/Amount';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import TableLoaderPlaceholder from 'components/shared-components/placeholders/table-loader-placeholder';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { dateMonthYear } from 'utils/date-time-formatter';
import { moveToPreviousPage } from 'utils/navigation-utils';
import InvoiceStatusTag from 'components/invoices/helpers/invoice-tag.component';
import RecordPaymentForm from './form.component';
import './styles.scss';

const InfoCardItem = ({ title, amount, icon }) => (
  <Card className="record-payment__info-card">
    <div className="record-payment__info-container">
      <div className="record-payment__info--icon">
        <Icon name={icon} color="black" size="huge" />
      </div>

      <div className="record-payment__info--amount">
        <div className="record-payment__info--title">{title}</div>
        <div className="record-payment__info--value">
          <AmountComponent value={amount} />
        </div>
      </div>
    </div>
  </Card>
);

InfoCardItem.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

const RecordPaymentComponent = () => {
  const navigate = useNavigate();
  const { invoiceId } = useParams();
  const { data: invoice = {} } = useGetInvoiceQuery({ invoiceId });
  const { data: invoiceTransactions, isFetching } = useGetInvoiceTransactionsQuery({
    invoiceId,
  });

  return (
    <div className="container">
      <div className="grid">
        <div className="col-12">
          <div className="record-payment__header">
            <IconButton
              className="organisation__head-popover--go-back"
              icon={<Icon name="goBack" />}
              onClick={() => navigate(moveToPreviousPage)}
            />
            <span className="invoice-header--heading">
              {t(`invoice.record_payments.title`, { invoiceId: invoice.invoiceId })}
            </span>
            <InvoiceStatusTag status={invoice.status} />
          </div>
        </div>

        <div className="col-12 col-bleed">
          <div className="record-payment__wrapper">
            <div className="record-payment__info">
              <InfoCardItem
                title={t(`invoice.record_payments.total_amount`)}
                icon="frank"
                amount={invoice.totalAmountInclVat}
              />
              <InfoCardItem
                title={t(`invoice.record_payments.already_received`)}
                icon="bankOutline"
                amount={invoice.totalReceivedAmount}
              />
              <InfoCardItem
                title={t(`invoice.record_payments.written_off`)}
                icon="percent"
                amount={invoice.totalWrittenOffAmount}
              />
              <InfoCardItem
                title={t(`invoice.record_payments.remaining_due`)}
                icon="stopwatch"
                amount={invoice.totalRemainingDue}
              />
            </div>
          </div>
        </div>
      </div>

      <Card>
        <RecordPaymentForm invoice={invoice} />
      </Card>

      <div className="transaction-history">
        <div className="transaction-history__title">
          {t(`invoice.record_payments.transaction_table.title`)}
        </div>
        {!isFetching && invoiceTransactions.length === 0 ? (
          <div className="col-12 col-bleed-x">
            <EmptyContentPlaceholder
              iconName="invoice"
              text={t(`invoice.record_payments.transaction_table.empty_content`)}
            />
          </div>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {[
                  'date',
                  'type',
                  'account_name',
                  'previous_due',
                  'amount_received',
                  'remaining_due',
                ].map(colName => (
                  <TableHeader>
                    {t(`invoice.record_payments.transaction_table.headers.${colName}`)}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <TableLoaderPlaceholder numberOfRows={5} numberOfColumns={6} />
              ) : (
                invoiceTransactions.map(transaction => (
                  <TableRow>
                    <TableData>{dateMonthYear(transaction.createdAt)}</TableData>
                    <TableData>
                      {t(`invoice.record_payments.transaction_type.${transaction.type}`)}
                      {transaction.type === 'written_off' && (
                        <EmphasisTag
                          className="transaction-history__written_off_tag"
                          type="disabled"
                          size="tiny"
                          text={t('invoice.record_payments.written_off')}
                        />
                      )}
                    </TableData>
                    <TableData>
                      {transaction.type === 'reverted'
                        ? null
                        : t(`invoice.record_payments.account_name.${transaction.type}`)}
                    </TableData>
                    <TableData>
                      <AmountComponent value={transaction.previousDueAmount} />
                    </TableData>
                    <TableData>
                      <AmountComponent value={transaction.amount} />
                    </TableData>
                    <TableData>
                      <AmountComponent value={transaction.remainingDue} />
                    </TableData>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default RecordPaymentComponent;
