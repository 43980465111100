import CheckBox from 'components/base-components/CheckBox';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, useFormState } from 'react-final-form';
import EmptyLegalForm from './shared/empty-legal-form.component';

const OperationsManager = ({ selectedNumberOfPartners, operationManagerIds, validationError }) => {
  const formstate = useFormState();

  const emailsToInitialize = (formstate.values.legalFormPartners || []).reduce(
    (emails, partner) => {
      if (operationManagerIds.includes(partner.userId)) {
        emails.push(partner.email);
      }

      return emails;
    },
    [],
  );

  const initialValues = useMemo(() => emailsToInitialize, [JSON.stringify(emailsToInitialize)]);

  if (!selectedNumberOfPartners) {
    return (
      <EmptyLegalForm
        title={t('legal_form.operations_manager.title')}
        description={t('legal_form.operations_manager.description')}
      />
    );
  }

  return (
    <div className="legal-form__operation-manage">
      <div className="legal-form__contract--title">
        <span>{t('legal_form.operations_manager.title')}</span>
        <span>*</span>
      </div>
      <div className="legal-form__contract--description">
        {t('legal_form.operations_manager.description')}
      </div>
      <div className="legal-form__operation-manage--checkbox">
        {(formstate.values.legalFormPartners || []).map((user, index) => (
          <div
            className={classNames({
              'legal-form__operation-manage--checkbox-item': !validationError?.operationManager,
            })}
          >
            <Field
              name={'operationManagers'}
              type="checkbox"
              value={user.email}
              initialValue={initialValues}
            >
              {({ input }) => (
                <CheckBox
                  isChecked={input.checked}
                  size="tiny"
                  label={t('legal_form.input.header', {
                    partner: index + 1,
                  })}
                  onChange={input.onChange}
                  disabled={!user.email}
                />
              )}
            </Field>
          </div>
        ))}
      </div>
      {validationError?.operationManager && (
        <div className="legal-form__required-field">
          {t('legal_form.operations_manager.error_text')}
        </div>
      )}
    </div>
  );
};

OperationsManager.defaultProps = {
  selectedNumberOfPartners: null,
  contractUsers: [],
  validationError: {},
  operationManagerIds: [],
};

OperationsManager.propTypes = {
  selectedNumberOfPartners: PropTypes.number,
  contractUsers: PropTypes.arrayOf(PropTypes.shape()),
  validationError: PropTypes.shape(),
  operationManagerIds: PropTypes.arrayOf(PropTypes.number),
};

export default OperationsManager;
