const ORGANIZATION_INTERESTS = {
  general: [
    'Kleinbauerngruppe',
    'Hofverarbeiter',
    'Direktvermarktung',
    'Knospe-Komponenten-Küche',
    'MO Lizenznehmende',
    'Betrieb mit Social Media Account',
    'Betrieb in Abklärung bei QS',
    'Kunde Ecert Web',
    'Supply Chain Monitor',
    'Lohnverarbeiter',
    'Alpbetrieb',
    'Sammelstelle',
    'Importeur',
    'Knospe-Produkte-Küche',
    'Mitarbeiter Importeur',
    'Lehrbetrieb',
    'Kommunale Erholungs- und Grünflächen',
    'Schnittblumen Produzenten',
    'Import - anonymisierter Betrieb',
    'Bildung & Beratung',
    'Hofverarbeiter Brot',
    'Mischfutterhersteller',
    'Knospe-Küche',
    'Stadtgärtnerei',
    'Private Erholungs- und Grünflächen',
    'Öffentlich zugängliche Grünflächen',
    'Co-Branding',
    'Fachhändler',
    'Supply Chain Monitor Administrator',
    'Grossmetzgerei',
    'Öffentlich Erholungs- und Grünflächen',
    'farmProduction',
    'directMarketing',
    'teachingFarm',
  ],
  animal: [
    'Imkerei (landlos)',
    'Kälbermäster',
    'Imkerei (Produzent)',
    'Schweinemäster',
    'Vermarktung Fleisch',
    '2022 Registrierung für Bio Suisse Milch Pflichtmitgliedschaft',
    'Bio-Weide-Beef',
    'Schweinezüchter (geprüft)',
    'Insektenproduktion',
    'Bestätigung Pflichtmitgliedschaft Milch 2020',
    'Bestätigung Pflichtmitgliedschaft Milch 2018',
    'Fischzucht',
    'Bestätigung Pflichtmitgliedschaft Milch 2019',
    'Ziegenmilchverarbeiter',
    'Richtpreise Bio-Schlachtvieh',
    'Schlachtviehhändler',
    'Schafmilchverarbeiter',
    'Büffelmilchverarbeiter',
    'Eierhandel',
    'Tiertransport',
  ],
  plants: [
    'Kräuterproduzent',
    'Bio-Weinproduzenten',
    'Pilzzucht',
    'Brotgetreide-Verarbeiter',
    'Gärtnerei',
    'Obstproduzent:in',
    'Kernobst Spät-/Lagersorten',
    'Importeur Inlandsgetreide',
    'ZGM Obst befreit',
    'Kernobst Frühsorten',
    'Bioweinproduzenten',
    'Bio-Kräuterproduzenten',
    'Weinkelterei',
  ],
};

export default ORGANIZATION_INTERESTS;
