import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useCreateSupplierCertificateMutation } from 'api/supplier-certificates';
import Icon from '../../base-components/Icon';
import SupplierCertificateForm from '../form/form.component';
import Button from '../../base-components/Button';
import SidePanel from '../../base-components/SidePanel';

const CreateForm = ({ organizationId, type, disabled }) => {
  const [enableSidePannel, setEnableSidePanel] = useState(false);
  const { t } = useTranslation();

  const [createSupplierCertificate] = useCreateSupplierCertificateMutation();

  const onCreateSupplierCertificate = formData => {
    return createSupplierCertificate({ body: formData, queryParams: { organizationId } }).unwrap();
  };

  return (
    <Fragment>
      <SidePanel
        title={t('supplier_certificates.add_certificate')}
        closeIcon={<Icon name="close" />}
        body={
          <SupplierCertificateForm
            onSave={onCreateSupplierCertificate}
            onCancel={() => {
              setEnableSidePanel(false);
            }}
            organizationId={organizationId}
          />
        }
        isOpened={enableSidePannel}
        onClick={() => {}}
        onClose={() => {
          setEnableSidePanel(false);
        }}
      />
      <Button
        label={t('supplier_certificates.add_certificate')}
        size="small"
        onClick={() => setEnableSidePanel(true)}
        disabled={disabled}
        type={type}
      />
    </Fragment>
  );
};

CreateForm.propTypes = {
  organizationId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CreateForm;
