import Card from 'components/base-components/Card';
import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';

const SharedFarmlandCard = ({ content }) => {
  const landInformation = content?.landInfo;

  return (
    <Card className="operations__farmland--content">
      <div className="font-bold">{content.title}</div>
      <hr className="organisation-checklist__checkbox-horizontal-line" />
      <div>
        {landInformation ? (
          landInformation?.map(land => (
            <div className="flex-space-between margin-top-10">
              <div>{land.name}</div>
              <div>{t('operations.unit.hectre', { value: land.value })}</div>
            </div>
          ))
        ) : (
          <div className="flex-space-between">
            <div>{content.name}</div>
            {content.value && <div>{content.value}</div>}
          </div>
        )}
      </div>
    </Card>
  );
};

SharedFarmlandCard.defaultProps = {
  title: null,
  content: null,
};

SharedFarmlandCard.propTypes = {
  title: PropTypes.node,
  content: PropTypes.arrayOf(PropTypes.shape()),
};

export default SharedFarmlandCard;
