import React, { useState } from 'react';
import Card from 'components/base-components/Card';
import SidePanel from 'components/base-components/SidePanel';
import EmphasisTag from 'components/base-components/EmphasisTag';
import IconButton from 'components/base-components/IconButton';
import Icon from 'components/base-components/Icon';
import Button from 'components/base-components/Button';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useDeleteUserPhoneMutation, useGetUserPhonesQuery } from 'api/users';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import DeleteModal from 'components/shared-components/delete-modal';
import ContactLoader from '../../loaders/ContactLoader';
import PhoneForm from './phone-form.component';

const PhoneEditComponent = () => {
  const [isPhoneSidepanelOpen, setIsPhoneSidepanelOpen] = useState(false);
  const [editPhone, setEditPhone] = useState(null);
  const [deleteCallbackParams, setDeleteCallbackParams] = useState(null);
  const { userId } = useParams();
  const [deletePhone, deleteStatus] = useDeleteUserPhoneMutation();
  const { data: userPhoneList, isLoading: isphoneListFetching } = useGetUserPhonesQuery(userId);
  const { t } = useTranslation();

  return (
    <>
      <DeleteModal
        modelName="phone_number"
        callback={deletePhone}
        status={deleteStatus}
        deleteCallbackParams={deleteCallbackParams}
        setDeleteCallbackParams={setDeleteCallbackParams}
      />
      <div className="user__email-header">
        <div className="user__form--title">{t('user_edit.phone_title')}</div>
        <Button
          type="success"
          size="small"
          label={t('user_contact.add_button')}
          onClick={() => {
            setEditPhone(null);
            setIsPhoneSidepanelOpen(true);
          }}
        />
      </div>
      {isphoneListFetching ? (
        <ContactLoader />
      ) : userPhoneList?.length > 0 ? (
        <div className="user__phone--list">
          {userPhoneList.map(phone => (
            <Card>
              <div key={phone.id} className="user__email-content">
                <div>
                  <span> {phone.number} </span>
                  {phone.primary && (
                    <EmphasisTag type="accent" text={t('user_contact.primary')} size="tiny" />
                  )}
                  <EmphasisTag
                    type="disabled"
                    text={t(`user.phone_type.${phone.numberType}`)}
                    size="tiny"
                  />
                </div>
                <div>
                  <div className="user-details__action">
                    <IconButton
                      className="user-details__action--show"
                      icon={<Icon name="delete" size="small" />}
                      size="tiny"
                      color="tertiary"
                      onClick={() => setDeleteCallbackParams({ userId, id: phone.id })}
                    />
                    <IconButton
                      className="user-details__action--edit"
                      icon={<Icon name="edit" size="small" />}
                      color="tertiary"
                      size="tiny"
                      onClick={() => {
                        setEditPhone(phone);
                        setIsPhoneSidepanelOpen(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>
      ) : (
        <EmptyPlaceholder
          emptyCardText={t('user_contact.phone_number.empty_placeholder')}
          emptyIcon={<Icon name="call" />}
        />
      )}

      <SidePanel
        isOpened={isPhoneSidepanelOpen}
        title={editPhone ? t('user_edit.phone_edit_title') : t('user_edit.phone_create_title')}
        body={
          isPhoneSidepanelOpen && (
            <PhoneForm setIsPhoneSidepanelOpen={setIsPhoneSidepanelOpen} editPhone={editPhone} />
          )
        }
        onClose={() => setIsPhoneSidepanelOpen(false)}
        onClick={() => setIsPhoneSidepanelOpen(false)}
      />
    </>
  );
};

export default PhoneEditComponent;
