import { useGetProductQuery } from 'api/product-declarations';
import EmphasisTag from 'components/base-components/EmphasisTag';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import { TableData, TableRow } from 'components/base-components/Table';
import WarningModal from 'components/shared-components/modal/warning';
import TableLoaderPlaceholder from 'components/shared-components/placeholders/table-loader-placeholder';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COMPLAINED, NEW_DECLARATION } from 'utils/declaration-status';

const ProductRow = ({
  productDeclaration,
  setIsSidepanelOpen,
  setDeleteCallbackParams,
  setEditableProduct,
  showActions,
}) => {
  const { t } = useTranslation();
  const [warningModalOpen, setWarningModalOpen] = useState(false);

  const { data: product, isFetching } = useGetProductQuery({
    organizationId: productDeclaration?.organizationId,
    productId: productDeclaration?.productId,
  });

  const isUpdateEnabled = declaration =>
    declaration?.status === NEW_DECLARATION || declaration?.status === COMPLAINED;

  const complaint = productDeclaration.complaint;

  if (isFetching) {
    return <TableLoaderPlaceholder numberOfRows={1} numberOfColumns={4} />;
  }

  return (
    <>
      <WarningModal
        onOutsideClick={() => {
          setWarningModalOpen(false);
        }}
        isVisible={warningModalOpen}
        title={t('product_declarations.complain')}
        content={
          <div className="product-declarations__complain-view">
            <span className="product-declarations__complain-view-header">{complaint?.title}</span>
            <span className="product-declarations__complain-view-body">
              {complaint?.description}
            </span>
          </div>
        }
        confirmActionText={t('shared.action.okay')}
        cancelActionText={t('shared.action.cancel')}
        onCancel={() => setWarningModalOpen(false)}
        onConfirm={() => setWarningModalOpen(false)}
      />
      <TableRow key={productDeclaration.id} className="product-declarations__row">
        <TableData>
          {product.name || t('common.not_applicable')}
          {complaint && (
            <>
              <EmphasisTag
                type="warning"
                size="tiny"
                text={
                  <div className="product-declarations__complain-view--tag-wrapper">
                    {t('product_declarations.status.complained')}
                    <div className="product-declarations__complain-view--vertical-line" />
                    <IconButton
                      className="product-declarations__complain-view--icon-button"
                      icon={<Icon name="show" />}
                      size="tiny"
                      isIconOnly={true}
                      onClick={() => setWarningModalOpen(true)}
                    />
                  </div>
                }
              />
            </>
          )}
        </TableData>
        <TableData>
          {product.category
            ? t(`products.categories.${product.category}`)
            : t('common.not_applicable')}
        </TableData>
        <TableData>{product.supplier || t('common.not_applicable')}</TableData>
        {showActions && (
          <TableData align="right">
            <IconButton
              onClick={() => {
                setEditableProduct(product);
                setIsSidepanelOpen(true);
              }}
              color="tertiary"
              disabled={!isUpdateEnabled(productDeclaration)}
              icon={<Icon name="edit" size="small" />}
            />
            <IconButton
              onClick={() =>
                setDeleteCallbackParams({
                  organizationId: productDeclaration?.organizationId,
                  productId: productDeclaration?.productId,
                })
              }
              disabled={!isUpdateEnabled(productDeclaration)}
              color="danger"
              icon={<Icon name="delete" size="small" />}
            />
          </TableData>
        )}
      </TableRow>
    </>
  );
};

ProductRow.propTypes = {
  setEditableProduct: PropTypes.func.isRequired,
  setIsSidepanelOpen: PropTypes.func.isRequired,
  setDeleteCallbackParams: PropTypes.func.isRequired,
  productDeclaration: PropTypes.shape().isRequired,
  showActions: PropTypes.bool.isRequired,
};

export default ProductRow;
