import { useMarkAsCancelMutation, useMarkAsOpenMutation } from 'api/invoices';
import Button from 'components/base-components/Button';
import Icon from 'components/base-components/Icon';
import Popover from 'components/base-components/Popover';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { toSnakecaseKeys } from 'utils/object-cleanup';

const BulkActionComponent = ({
  selectedInvoices,
  clearSelectedInvoices,
  selectAllInvoices,
  allInvoiceSelected,
  totalInvoiceCount,
  statuses,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const selectedInvoiceCount = allInvoiceSelected
    ? totalInvoiceCount - Object.values(selectedInvoices).filter(checked => !checked).length
    : Object.values(selectedInvoices).filter(checked => checked).length;

  const [markAsOpen] = useMarkAsOpenMutation();
  const [cancelInvoices] = useMarkAsCancelMutation();

  const moveToOpenInvoices = async () => {
    const selectedInvoiceIds = [];
    const excludedInvoiceIds = [];

    for (const id in selectedInvoices) {
      const value = selectedInvoices[id];

      if (value) {
        selectedInvoiceIds.push(parseInt(id));
      } else {
        excludedInvoiceIds.push(parseInt(id));
      }
    }

    const payload = {};

    if (allInvoiceSelected && excludedInvoiceIds.length) {
      payload.excluded_invoices = excludedInvoiceIds;
    } else if (!allInvoiceSelected && selectedInvoiceIds.length) {
      payload.selected_invoices = selectedInvoiceIds;
    } else if (allInvoiceSelected && !excludedInvoiceIds.length) {
      payload.all_invoices_selected = allInvoiceSelected;
    }

    return markAsOpen({ payload })
      .unwrap()
      .then(() => clearSelectedInvoices())
      .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
  };

  const deleteSelectedInvoices = async () => {
    const selectedInvoiceIds = [];
    const excludedInvoiceIds = [];

    for (const id in selectedInvoices) {
      const value = selectedInvoices[id];

      if (value) {
        selectedInvoiceIds.push(parseInt(id));
      } else {
        excludedInvoiceIds.push(parseInt(id));
      }
    }

    const payload = {};

    if (allInvoiceSelected && excludedInvoiceIds.length) {
      payload.excluded_invoices = excludedInvoiceIds;
    } else if (!allInvoiceSelected && selectedInvoiceIds.length) {
      payload.selected_invoices = selectedInvoiceIds;
    } else if (allInvoiceSelected && !excludedInvoiceIds.length) {
      payload.all_invoices_selected = allInvoiceSelected;
    }

    if (statuses.length) {
      payload.statuses = statuses;
    }

    return cancelInvoices({ payload })
      .unwrap()
      .then(() => clearSelectedInvoices())
      .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
  };

  return (
    <div className="invoice-list__bulk-action-wrapper">
      <Icon
        className="invoice-list__bulk-action--check-icon"
        color="tertiary"
        name="check"
        size="tiny"
      />
      <div>{t('invoice.bulk_action.selection_count', { count: selectedInvoiceCount })}</div>
      <div onClick={selectAllInvoices} className="invoice-list__bulk-action--select-all">
        {t('invoice.bulk_action.select_all', { count: totalInvoiceCount })}
      </div>
      <div className="invoice-list__bulk-action--clear-all" onClick={clearSelectedInvoices}>
        {t('invoice.bulk_action.clear_selection')}
      </div>
      <Popover
        className="task__popover-wrapper"
        direction="bottom-right"
        isVisible={showPopover}
        onOutsideClick={() => setShowPopover(false)}
        content={
          <div className="task__popover">
            <div onClick={moveToOpenInvoices} className="task__popover-content">
              <span>{t('invoice.bulk_action.move_to_open')}</span>
            </div>
            <div onClick={deleteSelectedInvoices} className="task__popover-content">
              <span>{t('invoice.bulk_action.cancel_all')}</span>
            </div>
          </div>
        }
      >
        <Button
          className="invoice-list__bulk-action--button"
          label={t('invoice.bulk_action.button')}
          onClick={() => setShowPopover(true)}
          type="default"
          size="small"
        />
      </Popover>
    </div>
  );
};

BulkActionComponent.propTypes = {
  selectedInvoices: PropTypes.arrayOf(PropTypes.number).isRequired,
  excludedInvoices: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectCurrentPageInvoices: PropTypes.bool.isRequired,
  clearSelectedInvoices: PropTypes.func.isRequired,
  moveToOpenInvoices: PropTypes.func.isRequired,
  deleteSelectedInvoices: PropTypes.func.isRequired,
  selectAllInvoices: PropTypes.func.isRequired,
  allInvoiceSelected: PropTypes.bool.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  totalInvoiceCount: PropTypes.string.isRequired,
};

export default BulkActionComponent;
