import {
  useGetActiveControlBodyDeclarationQuery,
  useVerifyControlBodyDeclarationMutation,
} from 'api/control-body-declarations';
import { useGetOrganizationQuery } from 'api/organizations';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import EmphasisTag from 'components/base-components/EmphasisTag';
import SidePanel from 'components/base-components/SidePanel/SidePanel';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  Table,
  TableBody,
  TableData,
  TableHeader,
  TableHead,
  TableRow,
} from 'components/base-components/Table';
import './control-body.styles.scss';
import { dateMonthYearFormatter } from 'utils/date-time-formatter';
import { useGetCurrentUserQuery } from 'api/users';
import ORGANISATION_TYPE from 'utils/organisation-type';
import { useGetActiveSurveyQuery, useGetControlBodyScheduleQuery } from 'api/control-body-survey';
import { useGetOrganizationDeclarationStatusQuery } from 'api/organizations';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import isControlBodyMember from 'utils/is-control-body-member';
import WarningModal from 'components/shared-components/modal/warning';
import IconButton from 'components/base-components/IconButton';
import { Trans } from 'react-i18next';
import { ACCEPTED } from 'utils/control-body-survey';
import Icon from 'components/base-components/Icon';
import classNames from 'classnames';
import { PRODUCER } from 'utils/organisation-industries';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import ApprovalConfirmationModal from 'components/shared-components/ApprovalModal/approval-confirmation-modal.component';
import DefaultSpinner from 'components/shared-components/default-spinner';
import { APPROVED, COMPLAINED, SUBMITTED } from 'utils/verification-status';
import ControlBodyComplaintComponent from './control-body-complaint.component';
import ControlBodyFormComponent from './control-body-form.component';

const CurrentControlBodyComponent = () => {
  const [isSidepanelOpen, setIsSidepanelOpen] = useState(false);
  const [isComplaintSidepanelOpen, setIsComplaintSidepanelOpen] = useState(false);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [isComplainModalOpen, setIsComplainModalOpen] = useState(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const { data: contolBodyData, isFetching } = useGetActiveControlBodyDeclarationQuery(id);
  const [verifyControlBody, verifyStatus] = useVerifyControlBodyDeclarationMutation();
  const { data: status = {} } = useGetOrganizationDeclarationStatusQuery({ organizationId: id });
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: organisation } = useGetOrganizationQuery(id);

  const isAdmin = isBioSuisseAdmin(currentUser);

  const { data: activeSurvey = {} } = useGetActiveSurveyQuery({ organizationId: id });
  const { data: controlBodyScheduleData } = useGetControlBodyScheduleQuery(
    {
      surveyId: activeSurvey.id,
    },
    {
      skip: !activeSurvey?.id,
    },
  );

  const controlBodyStatus = {
    approved: APPROVED,
    submitted: SUBMITTED,
    complained: COMPLAINED,
  };

  const emphasisTagType = {
    submitted: t('control_body.status.submitted'),
    verified: t('control_body.status.verified'),
    complained: t('product_declarations.status.complained'),
  };

  const handleVerify = () => {
    verifyControlBody({
      id,
      payload: {
        organization_id: id,
      },
    }).then(() => setIsVerifyModalOpen(false));
  };

  if (isFetching) {
    return <DefaultSpinner className="default-spinner--center" />;
  }

  const isControlBodyLastToBeApproved =
    status?.starsCountChanged && status?.newDeclarationsLeft === 1;

  const isProducer = organisation?.industry === PRODUCER;

  return (
    <>
      <SubmitModal
        isLoading={verifyStatus.isLoading}
        isSuccess={verifyStatus.isSuccess}
        requestId={verifyStatus.requestId}
        successTitle={t('control_body.modal.verification.success.title')}
        successContent={t('control_body.modal.verification.success.content')}
        showError={false}
        showLoader={false}
      />
      <div
        className={classNames('grid', {
          'control-body__disabled': organisation?.type === ORGANISATION_TYPE.sub_organisation,
        })}
      >
        <div className="col-12 col-bleed">
          <div className="control-body__current-period--wrapper">
            {currentUser && currentUser.role === 'user' && !isProducer && (
              <div className="control-body__action">
                <Button
                  label={t('control_body.change_button')}
                  disabled={
                    contolBodyData?.status === controlBodyStatus.submitted ||
                    organisation?.type === ORGANISATION_TYPE.sub_organisation ||
                    controlBodyScheduleData?.status === ACCEPTED
                  }
                  size="small"
                  type="success"
                  onClick={() => setIsSidepanelOpen(true)}
                />
              </div>
            )}
            {isControlBodyMember(currentUser) && contolBodyData && (
              <div className="control-body__action">
                <Button
                  label={t('control_body.raise_complaint')}
                  size="small"
                  type="disabled"
                  disabled={contolBodyData?.status !== controlBodyStatus.submitted}
                  className="control-body__action-raise"
                  onClick={() => setIsComplaintSidepanelOpen(true)}
                />
                <Button
                  label={t('control_body.verify')}
                  size="small"
                  type="success"
                  disabled={contolBodyData?.status !== controlBodyStatus.submitted}
                  onClick={() => setIsVerifyModalOpen(true)}
                />
              </div>
            )}
          </div>
        </div>
        {contolBodyData ? (
          <div className="col-12 col-bleed">
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader>{t('control_body.table_field.control_body')}</TableHeader>
                  <TableHeader align="center">
                    {t('control_body.table_field.declaration_date')}
                  </TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableData>
                    <span>{contolBodyData.name}</span>
                    <span>
                      {contolBodyData?.status === controlBodyStatus.submitted && (
                        <EmphasisTag
                          radius="oval"
                          size="tiny"
                          text={emphasisTagType.submitted}
                          type="accent"
                        />
                      )}
                      {contolBodyData?.status === controlBodyStatus.complained && (
                        <EmphasisTag
                          radius="oval"
                          size="tiny"
                          text={
                            <div className="product-declarations__complain-view--tag-wrapper">
                              {emphasisTagType.complained}
                              <div className="product-declarations__complain-view--vertical-line" />
                              <IconButton
                                icon={<Icon name="show" />}
                                size="tiny"
                                isIconOnly={true}
                                onClick={() => setIsComplainModalOpen(true)}
                              />
                            </div>
                          }
                          type="warning"
                        />
                      )}
                      {contolBodyData?.status === controlBodyStatus.approved && (
                        <EmphasisTag
                          radius="oval"
                          size="tiny"
                          text={emphasisTagType.verified}
                          type="success"
                        />
                      )}
                    </span>
                  </TableData>
                  <TableData align="center">
                    <span className="product-declarations__complain-view--date">
                      {contolBodyData?.dateOfDeclaration
                        ? dateMonthYearFormatter(contolBodyData?.dateOfDeclaration)
                        : t('common.not_applicable')}
                    </span>
                  </TableData>
                </TableRow>
              </TableBody>
            </Table>
            {!isAdmin && organisation?.type === ORGANISATION_TYPE.sub_organisation && (
              <div className="col-12 control-body__manage">{t('control_body.parent_manage')}</div>
            )}
          </div>
        ) : (
          <div className="col-12 col-bleed-x">
            <Card>
              <div className="col-12 col-bleed">{t('control_body.begin_text')}</div>
              <div className="control-body__begin-button">
                <Button
                  label={t('control_body.declaration_button')}
                  type="success"
                  size="small"
                  onClick={() => setIsSidepanelOpen(true)}
                  disabled={isBioSuisseAdmin(currentUser)}
                />
              </div>
            </Card>
          </div>
        )}
      </div>

      <SidePanel
        isOpened={isSidepanelOpen}
        title={t('control_body.sidepanel_header')}
        body={
          <ControlBodyFormComponent
            setIsSidepanelOpen={setIsSidepanelOpen}
            controlBodyData={contolBodyData}
          />
        }
        onClose={() => setIsSidepanelOpen(false)}
        onClick={() => setIsSidepanelOpen(false)}
      />
      <SidePanel
        isOpened={isComplaintSidepanelOpen}
        title={t('shared.raise_complaint')}
        body={
          isComplaintSidepanelOpen && (
            <ControlBodyComplaintComponent
              setIsComplaintSidepanelOpen={setIsComplaintSidepanelOpen}
            />
          )
        }
        onClose={() => setIsComplaintSidepanelOpen(false)}
        onClick={() => setIsComplaintSidepanelOpen(false)}
      />

      <ApprovalConfirmationModal
        organizationId={id}
        isModalOpen={isVerifyModalOpen}
        setIsModalOpen={setIsVerifyModalOpen}
        onConfirm={handleVerify}
        title={
          isControlBodyLastToBeApproved
            ? t('control_body.last_verify_title')
            : t('control_body.verify_title')
        }
        content={
          isControlBodyLastToBeApproved ? (
            <Trans i18nKey="control_body.last_verify_description" components={{ bold: <b /> }} />
          ) : (
            t('control_body.verify_description')
          )
        }
        confirmActionText={t('shared.action.verify')}
        cancelActionText={t('shared.action.cancel')}
      />

      <WarningModal
        onOutsideClick={() => {
          setIsComplainModalOpen(false);
        }}
        isVisible={isComplainModalOpen}
        title={t('control_body.complain_title')}
        content={
          <div className="control-body__complain-description">
            <div className="control-body__complain-description--header">
              {contolBodyData?.complaint?.title}
            </div>
            <div className="control-body__complain-description--body">
              {contolBodyData?.complaint?.description}
            </div>
          </div>
        }
        confirmActionText={t('shared.action.okay')}
        cancelActionText={t('shared.action.cancel')}
        onCancel={() => setIsComplainModalOpen(false)}
        onConfirm={() => setIsComplainModalOpen(false)}
      />
    </>
  );
};

export default CurrentControlBodyComponent;
