import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactQuill from 'react-quill';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

import './texteditor.styles.scss';

const CustomToolbar = ({ error, hideToolbars }) => (
  <div id="toolbar">
    {!hideToolbars.bold && <button className="ql-bold" type="button" />}
    {!hideToolbars.italic && <button className="ql-italic" type="button" />}
    {!hideToolbars.strike && <button className="ql-strike" type="button" />}
    {!hideToolbars.orderedList && <button className="ql-list" value="ordered" type="button" />}
    {!hideToolbars.bulletList && <button className="ql-list" value="bullet" type="button" />}
    <span className="ql-toolbar-error">
      {error && (
        <Tooltip
          className="texeditor-ql-toolbar-error-tooltip"
          content={error}
          position="bottom-right"
          type="danger"
          size="small"
        >
          <Icon name="invalid" color="danger" />
        </Tooltip>
      )}
    </span>
  </div>
);

CustomToolbar.defaultProps = {
  error: null,
  hideToolbars: {
    bold: false,
    italic: false,
    strike: false,
    orderedList: false,
    bulletList: false,
  },
};

CustomToolbar.propTypes = {
  error: PropTypes.string,
  hideToolbars: PropTypes.shape({
    bold: PropTypes.bool,
    italic: PropTypes.bool,
    strike: PropTypes.bool,
    orderedList: PropTypes.bool,
    bulletList: PropTypes.bool,
  }),
};

const TextEditor = ({ value, onChange, placeholder, label, error, isRequired, hideToolbars }) => (
  <div className="text-editor">
    <div className={classNames('text-editor__label', { 'required-field': isRequired })}>
      {label}
    </div>
    <div
      className={classNames('text-editor__container', { 'text-editor__container--error': error })}
    >
      <CustomToolbar error={error} hideToolbars={hideToolbars} />
      <ReactQuill
        modules={{ toolbar: { container: '#toolbar' } }}
        theme="snow"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  </div>
);

TextEditor.defaultProps = {
  placeholder: null,
  isRequired: null,
  error: null,
  hideToolbars: {},
};

TextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  error: PropTypes.string,
  hideToolbars: PropTypes.shape(),
};

export default TextEditor;
