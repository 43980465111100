import Card from 'components/base-components/Card';
import TitleComponent from 'components/organisations/form/producer/plant-cultivate/shared/title.component';
import { t } from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';

const SharedGreenAreaAndPrivatePark = ({ data, title, icon, name }) => {
  return (
    <Card className="operations__production--card">
      <TitleComponent title={title} icon={icon} isOutsideForm={true} />
      <hr className="organisation-checklist__checkbox-horizontal-line" />
      {data?.map(area => (
        <div className="flex-space-between margin-top-10">
          <div>{t(`plant_cultivation.${name}.checkbox.${area.subType}`)}</div>
          <div>{t('operations.unit.hectre', { value: area.areasInHectare })}</div>
        </div>
      ))}
    </Card>
  );
};

SharedGreenAreaAndPrivatePark.defaultProps = {
  data: [],
  title: null,
  icon: null,
};

SharedGreenAreaAndPrivatePark.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.node,
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default SharedGreenAreaAndPrivatePark;
