import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { dateMonthYearFormatter, monthYearShort } from '../../../utils/date-time-formatter';
import './styles.scss';

const ChartaValidity = ({
  validFrom,
  validUntil,
  declaredAt,
  withValidityOnly,
  currentTimePeriodTransKey,
  declaredOnTransKey,
  tag,
}) => {
  const { t } = useTranslation();

  const onHandleValidity = () => {
    const timePeriod =
      validFrom && validUntil
        ? `${monthYearShort(validFrom)} - ${monthYearShort(validUntil)}`
        : t('charta.time_period.no_date_available');

    return (
      <Trans
        i18nKey={currentTimePeriodTransKey}
        values={{ timePeriod }}
        components={{ bold: <b /> }}
      />
    );
  };

  const onTimePeriodDeclaration = () => {
    const timePeriod = declaredAt
      ? dateMonthYearFormatter(declaredAt)
      : t('charta.time_period.no_date_available');
    return (
      <Trans i18nKey={declaredOnTransKey} values={{ timePeriod }} components={{ bold: <b /> }} />
    );
  };

  return (
    <div className="charta-validity">
      <span className="charta-validity__tag">
        {onHandleValidity()} {tag}{' '}
      </span>
      <span className="charta-validity__period">
        {!withValidityOnly && onTimePeriodDeclaration()}
      </span>
    </div>
  );
};

ChartaValidity.defaultProps = {
  withValidityOnly: false,
  validFrom: null,
  validUntil: null,
  declaredAt: null,
  currentTimePeriodTransKey: 'charta.time_period.current',
  declaredOnTransKey: 'charta.time_period.declared_on',
  tag: null,
};

ChartaValidity.propTypes = {
  validFrom: PropTypes.string,
  validUntil: PropTypes.string,
  declaredAt: PropTypes.string,
  withValidityOnly: PropTypes.bool,
  currentTimePeriodTransKey: PropTypes.string,
  declaredOnTransKey: PropTypes.string,
  tag: PropTypes.node,
};

export default ChartaValidity;
