import React, { useState, useCallback, useRef } from 'react';
import fileSize from 'filesize';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import classNames from 'classnames';
import Button from '../Button';
import Icon from '../Icon';

import { getCroppedImg, toBase64Img } from './imageUtils';

const AvatarUpload = props => {
  const acceptedFileTypes = 'image/png, image/jpg, image/jpeg';
  const {
    onAvatarUpload,
    active,
    maxSize,
    minimumCroppingWidth,
    onClose,
    fileSizeErrorMsg,
    fileTypeErrorMsg,
    title,
    uploadTitle,
    uploadSubtitle,
    setButtonLabel,
    cancelButtonLabel,
    circularCrop,
  } = props;
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ aspect: 1 / 1, unit: 'px', width: 150, x: 100, y: 100 });
  const [uploadedImage, setUploadedImage] = useState({ base64: null, imageFileObj: null });
  const [errorMsg, setErrorMsg] = useState('');

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  const handleRejectedImage = rejectedImage => {
    if (rejectedImage.size > maxSize) {
      setErrorMsg(`${fileSizeErrorMsg} ${fileSize(maxSize)}`);
    } else if (!acceptedFileTypes.includes(rejectedImage.type)) {
      setErrorMsg(fileTypeErrorMsg);
    }
  };

  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      handleRejectedImage(rejectedFiles[0].file);
      return;
    }

    const currentFile = acceptedFiles[0];

    try {
      const base64Img = await toBase64Img(currentFile);
      setUploadedImage({ base64: base64Img, imageFileObj: currentFile });
    } catch (error) {
      throw error.message;
    }
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    isMulti: false,
    maxSize,
    accept: acceptedFileTypes,
  });

  const Label = () => {
    return (
      <div className="avatar-upload__label">
        {errorMsg ? <Icon name="photo" /> : <Icon name="cloudUpload" />}
        {errorMsg ? (
          <span className="avatar-upload__upload-sub-title">{errorMsg}</span>
        ) : (
          <>
            <span className="avatar-upload__upload-title">{uploadTitle}</span>
            <span className="avatar-upload__upload-sub-title">{uploadSubtitle}</span>
          </>
        )}
      </div>
    );
  };

  const setImage = async () => {
    try {
      const croppedImage = await getCroppedImg(imgRef.current, crop, props.fileName);

      onAvatarUpload(croppedImage);
      onClose();
    } catch (error) {
      throw error.message;
    }
  };

  return (
    <div className="avatar-upload">
      <div className="avatar-upload__header">
        <span className="avatar-upload__title">{title}</span>
      </div>
      <div className="avatar-upload__image-section">
        {uploadedImage.base64 ? (
          <ReactCrop
            src={uploadedImage.base64}
            crop={crop}
            minWidth={minimumCroppingWidth}
            circularCrop={circularCrop}
            onChange={newCrop => setCrop(newCrop)}
            onImageLoaded={onLoad}
            keepSelection={true}
          />
        ) : (
          <div
            {...getRootProps()}
            className={classNames('avatar-upload__dropzone', {
              'avatar-upload__dropzone--active': isDragActive,
            })}
          >
            <input {...getInputProps()} />
            {isDragActive ? <p> {active}</p> : <Label />}
          </div>
        )}
      </div>
      <div className="avatar-upload__footer">
        <Button
          className="avatar-upload__set-btn"
          type="success"
          onClick={setImage}
          label={setButtonLabel}
          version="v2"
          size="small"
          disabled={!uploadedImage.base64}
        />
        <Button
          className="avatar-upload__cancel-btn"
          onClick={onClose}
          label={cancelButtonLabel}
          version="v2"
          size="small"
        />
      </div>
    </div>
  );
};

AvatarUpload.defaultProps = {
  active: 'Drop the files here',
  fileName: 'croppedImage.jpeg',
  fileSizeErrorMsg: 'File is too large, please select a file bellow',
  fileTypeErrorMsg: 'File type is not supported, please select png/jpg/jpeg',
  maxSize: 5 * 1024 * 1024, // 5Mb
  minimumCroppingWidth: 70,
  onAvatarUpload: () => ({}),
  onClose: () => ({}),
  title: 'Choose Profile Image',
  uploadTitle: 'Drag n Drop',
  uploadSubtitle: 'or click to select files',
  setButtonLabel: 'Set',
  cancelButtonLabel: 'Cancel',
  circularCrop: true,
};

AvatarUpload.propTypes = {
  active: PropTypes.string,
  fileName: PropTypes.string,
  fileSizeErrorMsg: PropTypes.string,
  fileTypeErrorMsg: PropTypes.string,
  maxSize: PropTypes.number,
  minimumCroppingWidth: PropTypes.number,
  onAvatarUpload: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  uploadTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  uploadSubtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  setButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  cancelButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  circularCrop: PropTypes.bool,
};

export default AvatarUpload;
