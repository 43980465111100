import {
  useApproveContractMutation,
  useUpdateContractMutation,
  useUploadContractMutation,
} from 'api/contract';
import { useGetOrganizationQuery } from 'api/organizations';
import { useGetCurrentUserQuery } from 'api/users';
import ChooseFile from 'components/shared-components/choose-file/ChooseFile';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import Button from 'components/base-components/Button';
import { PRODUCER } from 'utils/organisation-industries';
import { TERMINATED, VERIFIED } from 'utils/signed-contract-statuses';
import Icon from 'components/base-components/Icon';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import ApprovalConfirmationModal from 'components/shared-components/ApprovalModal/approval-confirmation-modal.component';
import DeleteContract from './delete-contract.component';
import TerminateContract from './terminate-contract.component';

const UploadContract = ({ contractData }) => {
  const { id } = useParams();
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [updateContract] = useUpdateContractMutation();
  const [uploadFile, uploadStatus] = useUploadContractMutation();
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: organisationData } = useGetOrganizationQuery(id);

  const [approveContract, verifyStatus = {}] = useApproveContractMutation();

  const uploadDisable = !contractData || contractData?.status === VERIFIED;
  const isAdmin = isBioSuisseAdmin(currentUser);
  const producer = organisationData?.industry === PRODUCER;
  const isDeactivated = organisationData?.status === ORGANISATIONS_STATUS.inactive;
  const isUploadButtonHidden =
    (contractData?.status === TERMINATED || contractData?.status === VERIFIED) &&
    contractData?.terminatedAt !== null;

  const handleVerifyContract = () => {
    return approveContract({
      organisationId: id,
    })
      .unwrap()
      .then(() => setIsVerifyModalOpen(false));
  };

  const handleUploadFile = file => {
    const formData = new FormData();

    formData.append('contract', file);

    if (!producer) {
      if (!contractData) {
        formData.append('organization_id', id);
        return uploadFile({ organisationId: id, payload: formData }).unwrap();
      } else {
        return updateContract({
          contractId: contractData?.id,
          payload: formData,
        }).unwrap();
      }
    }

    return uploadFile({ organisationId: id, payload: formData })
      .unwrap()
      .then(() =>
        approveContract({
          organisationId: id,
        }),
      );
  };
  return (
    <div>
      <div className="organisation-contract__choose-btn">
        {isAdmin && (
          <>
            <div className="organisation-contract__verify-btn">
              {producer ? (
                <>
                  {!isUploadButtonHidden && (
                    <>
                      <ChooseFile
                        showText={false}
                        onChange={file => {
                          handleUploadFile(file);
                        }}
                        labelText={
                          <>
                            <Icon name="uploadFileAlt" size="medium" />
                            <span className="organisation-contract__upload">
                              {t('contract.producer.button.upload')}
                            </span>
                          </>
                        }
                        disabled={isDeactivated || uploadDisable}
                      />
                      {contractData?.status === VERIFIED && (
                        <TerminateContract contractData={contractData} />
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <ChooseFile
                    showText={false}
                    onChange={file => {
                      handleUploadFile(file);
                    }}
                    labelText={
                      <>
                        <Icon name="uploadFile" size="medium" />
                        <span className="organisation-contract__upload">
                          {t('contract.upload_file')}
                        </span>
                      </>
                    }
                    disabled={isDeactivated || contractData?.contract}
                  />
                  <DeleteContract contractData={contractData} />
                  <Button
                    label={t('contract.verify_button')}
                    className="organisation-contract__success"
                    type="success"
                    size="small"
                    onClick={() => setIsVerifyModalOpen(true)}
                    disabled={isDeactivated || contractData?.status === VERIFIED}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
      <SubmitModal
        isLoading={verifyStatus.isLoading}
        isSuccess={verifyStatus.isSuccess}
        requestId={verifyStatus.requestId}
        successTitle={t('control_body.modal.verification.success.title')}
        successContent={t('control_body.modal.verification.success.content')}
        showError={false}
        showLoader={false}
      />
      <SubmitModal
        isLoading={uploadStatus.isLoading}
        isSuccess={uploadStatus.isSuccess}
        requestId={uploadStatus.requestId}
        successContent={t('contract.upload_successful')}
        showError={false}
        showLoader={true}
        loaderContent={t('contract.upload_loading')}
      />
      <ApprovalConfirmationModal
        organizationId={id}
        isModalOpen={isVerifyModalOpen}
        setIsModalOpen={setIsVerifyModalOpen}
        title={t('contract.verify_contract_title')}
        content={t('contract.verify_contract_description')}
        onConfirm={handleVerifyContract}
        confirmActionText={t('shared.action.okay')}
        cancelActionText={t('shared.action.cancel')}
      />
    </div>
  );
};

UploadContract.defaultProps = {
  contractData: null,
};

UploadContract.propTypes = {
  title: PropTypes.string.isRequired,
  contractData: PropTypes.shape(),
};

export default UploadContract;
