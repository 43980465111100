import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../checklist-form.styles.scss';
import { Field } from 'react-final-form';
import Icon from 'components/base-components/Icon';
import Textarea from 'components/base-components/Textarea/Textarea';
import { t } from 'i18next';
import { useGetLogoAttachmentsQuery } from 'api/control-body-survey';
import { LOGOS, PERMANENT, TEMPORARY } from 'utils/control-body-survey';
import { useGetLogoAttachmentPhotosQuery } from 'api/control-body-survey/logo-attachments';
import LogoIngredientsCheckboxItem from '../entities/logo-ingredients-checkbox-item.component';
import UploadAdditionalPhotos from '../entities/upload-additional-photos.component';
import NegativeUploadedLogoAttachments from './negative-uploaded-logo-attachments.component';

const LogoIngredientsCheckbox = ({
  title,
  items,
  activeItems,
  error,
  logoAttachmentComment,
  isCertifier,
  isAuditor,
  logoFromAuditorIds,
  surveyId,
  status,
  form,
  uploadedLogoAttachments,
  setUploadedLogoAttachments,
}) => {
  const [isCommentVisible, setIsCommentVisible] = useState({});
  const [checkedLogos, setCheckedLogos] = useState({});
  const activeLogos = {};
  const activeLogosFromAuditor = {};
  const activeComments = {};

  const { data: draftAuditorData = {} } = useGetLogoAttachmentsQuery(
    { surveyId, 'ids[]': logoFromAuditorIds },
    { skip: !logoFromAuditorIds || !surveyId },
  );

  activeItems?.forEach(({ attachedTo, attached }) => (activeLogos[attachedTo] = attached));
  activeItems?.forEach(({ attachedTo, comment }) => (activeComments[attachedTo] = comment));
  Object.keys(draftAuditorData).length > 0 &&
    draftAuditorData?.forEach(
      ({ attachedTo, attached }) => (activeLogosFromAuditor[attachedTo] = attached),
    );

  const handleCertifierComment = (updatedValue, fixedValue) => {
    if (updatedValue !== activeLogosFromAuditor[fixedValue]) {
      setIsCommentVisible(prev => ({ ...prev, [fixedValue]: true }));
    } else if (updatedValue === activeLogosFromAuditor[fixedValue]) {
      setIsCommentVisible(prev => ({ ...prev, [fixedValue]: false }));
    }
  };

  useEffect(() => {
    Object.keys(activeLogosFromAuditor).forEach(logo => {
      handleCertifierComment(activeLogos[logo], logo);
    });

    const store = {};
    Object.keys(activeLogos).forEach(key => (store[key] = activeLogos[key]));
    Object.keys(activeLogosFromAuditor).forEach(key => (store[key] = activeLogosFromAuditor[key]));

    setCheckedLogos(store);
  }, [Object.keys(activeLogosFromAuditor).length, Object.keys(activeLogos).length]);

  if (!items || !activeItems) return null;

  activeItems.forEach(({ attachedTo, attached }) => (activeLogos[attachedTo] = attached));

  const requiredLogoCount = Object.keys(checkedLogos).filter(
    item => checkedLogos[item] === 'no',
  ).length;

  const errorMsg = error && error['activeLogoAttachments'];

  const isCommentBoxShown = item =>
    Object.keys(isCommentVisible).length > 0 && isCommentVisible[item] && isCertifier;

  return (
    <div className="organisation-checklist__checkbox-wrapper">
      <div className="organisation-checklist__checkbox-title">{title}</div>
      {items.map(item => (
        <LogoIngredientsCheckboxItem
          attachedTo={item.value}
          currentComment={activeComments[item.value]}
          auditorValue={activeLogosFromAuditor[item.value]}
          currentValue={activeLogos[item.value]}
          isCommentBoxShown={isCommentBoxShown}
          isCertifier={isCertifier}
          item={item}
          status={status}
          error={error}
          form={form}
          handleCertifierComment={handleCertifierComment}
          setCheckedLogos={setCheckedLogos}
          isAuditor={isAuditor}
        />
      ))}
      {error && error['logoAttachmentsReasonOfChange'] && (
        <div className="organisation-checklist__errors">
          <Icon name="invalid" color="danger" size="small" />
          <span className="organisation-checklist__errors-text">
            {error['logoAttachmentsReasonOfChange']}
          </span>
        </div>
      )}

      {requiredLogoCount !== 0 && (
        <UploadAdditionalPhotos
          isAuditor={isAuditor}
          surveyId={surveyId}
          uploadedLogoAttachments={uploadedLogoAttachments}
          setUploadedLogoAttachments={setUploadedLogoAttachments}
          requiredLogoCount={requiredLogoCount}
          errors={error?.logoAttachmentAdditionalPhotos}
          fetchPhotosDataQuery={useGetLogoAttachmentPhotosQuery}
          itemType={LOGOS}
        />
      )}

      <Field name="logoComment" initialValue={logoAttachmentComment || ''}>
        {({ input }) => (
          <Textarea
            className="organisation-checklist__quarter-comment"
            label={
              isCertifier
                ? t('control_body.survey.form.auditor_comments')
                : t('control_body.survey.form.comments')
            }
            placeholder={t('control_body.survey.form.comments_placeholder')}
            disabled={isCertifier || status === PERMANENT}
            {...input}
          />
        )}
      </Field>

      {status === TEMPORARY && <NegativeUploadedLogoAttachments surveyId={surveyId} />}

      {errorMsg && (
        <div className="organisation-checklist__errors">
          <Icon name="invalid" color="danger" size="small" />
          <span className="organisation-checklist__errors-text">{errorMsg}</span>{' '}
        </div>
      )}
    </div>
  );
};

LogoIngredientsCheckbox.defaultProps = {
  title: null,
  surveyId: null,
  items: null,
  activeItems: null,
  error: null,
  logoAttachmentComment: null,
  status: null,
  isAuditor: false,
  isCertifier: false,
  logoFromAuditorIds: null,
};

LogoIngredientsCheckbox.propTypes = {
  title: PropTypes.node,
  surveyId: PropTypes.node,
  status: PropTypes.string,
  logoAttachmentComment: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  activeItems: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.shape(),
  isAuditor: PropTypes.bool,
  isCertifier: PropTypes.bool,
  logoFromAuditorIds: PropTypes.array,
  form: PropTypes.shape().isRequired,
  uploadedLogoAttachments: PropTypes.array.isRequired,
  setUploadedLogoAttachments: PropTypes.func.isRequired,
};

export default LogoIngredientsCheckbox;
