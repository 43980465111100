import React from 'react';
import Card from 'components/base-components/Card';
import PropTypes from 'prop-types';
import './empty-placeholder.styles.scss';
import classNames from 'classnames';

const EmptyPlaceholder = ({ emptyCardText, emptyIcon, className }) => (
  <Card
    className={classNames('empty-placeholder', className)}
    size="full"
    emptyCardImageSrc={emptyIcon}
    emptyCardText={emptyCardText}
  />
);

EmptyPlaceholder.defaultProps = {
  emptyIcon: null,
  emptyCardText: null,
  className: null,
};

EmptyPlaceholder.propTypes = {
  emptyIcon: PropTypes.node,
  className: PropTypes.node,
  emptyCardText: PropTypes.string,
};

export default EmptyPlaceholder;
