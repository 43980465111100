import CheckBox from 'components/base-components/CheckBox/CheckBox';
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Input from 'components/base-components/Input';
import { t } from 'i18next';
import Tooltip from 'components/base-components/Tooltip';
import IconButton from 'components/base-components/IconButton';
import Icon from 'components/base-components/Icon';
import classNames from 'classnames';
import Card from 'components/base-components/Card';
import TitleComponent from '../shared/title.component';

const CultivationMethodInputWithCheckbox = ({
  showCoveredCultivation,
  isVisible,
  fieldName,
  initialValue,
  values,
  title,
  iconName,
  isCheckedAlways,
  isViticulture,
  isTableGrape,
  error,
  validationError,
}) => {
  if (!isVisible) return;

  return (
    <Card
      className={classNames({
        'plant-cultivation__card': !isTableGrape,
        'plant-cultivation__method--card': isTableGrape,
      })}
    >
      <TitleComponent title={title} icon={iconName} />
      <div
        className={classNames({
          'plant-cultivation__method--open': !isTableGrape,
          'plant-cultivation__method--open__checked':
            values[`${fieldName}_open_checkbox`] && !isTableGrape,
          'plant-cultivation__method--table-grape': isTableGrape,
        })}
      >
        {!isViticulture && (
          <Field
            name={`${fieldName}_open_checkbox`}
            type="checkbox"
            initialValue={
              isCheckedAlways ||
              initialValue?.surfaceAreaInHectares ||
              initialValue?.openFieldInHectares ||
              initialValue?.openFieldCultivationInHectares ||
              initialValue?.openFieldCultivation ||
              initialValue?.openFieldSurfaceAreaInAres
            }
          >
            {({ input }) => (
              <CheckBox
                isChecked={input.checked}
                size="tiny"
                label={t('plant_cultivation.shared.open_field')}
                onChange={e => input.onChange(e.target.checked)}
              />
            )}
          </Field>
        )}
        <Field
          name={`${fieldName}_open_field_in_hectares`}
          initialValue={
            initialValue?.surfaceAreaInHectares ||
            initialValue?.openFieldInHectares ||
            initialValue?.openFieldCultivationInHectares ||
            initialValue?.openFieldSurfaceInHectares ||
            initialValue?.openFieldSurfaceAreaInAres ||
            ''
          }
        >
          {({ input, meta }) => (
            <Input
              size="tiny"
              label={t('plant_cultivation.shared.surface_area_input_label')}
              placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
              disabled={!values[`${fieldName}_open_checkbox`] && !isViticulture}
              error={meta.submitError || validationError?.[`${fieldName}_open_field_in_hectares`]}
              touched={!meta.dirtySinceLastSubmit}
              {...input}
            />
          )}
        </Field>
      </div>
      {showCoveredCultivation && !isViticulture && (
        <div
          className={classNames({
            'plant-cultivation__method--open': !isTableGrape,
            'plant-cultivation__method--open__checked':
              values[`${fieldName}_covered_checkbox`] && !isTableGrape,
            'plant-cultivation__method--table-grape': isTableGrape,
          })}
        >
          <Field
            name={`${fieldName}_covered_checkbox`}
            type="checkbox"
            initialValue={
              initialValue?.coveredCultivationInHectares ||
              initialValue?.coveredFieldCultivationInHectares ||
              initialValue?.coveredFieldCultivation ||
              initialValue?.coveredFieldSurfaceAreaInAres
            }
          >
            {({ input }) => (
              <CheckBox
                isChecked={input.checked}
                size="tiny"
                label={
                  <div>
                    <span className="plant-cultivation__method--open__tooltip-label">
                      {t('plant_cultivation.shared.covered_cultivation')}
                    </span>
                    <span>
                      <Tooltip
                        content={t('plant_cultivation.shared.covered_cultivation_info')}
                        type="inverse"
                        position="right-top"
                        size="small"
                        showArrow={true}
                      >
                        <IconButton
                          icon={<Icon name="info" size="tiny" />}
                          color="default"
                          size="tiny"
                          isIconOnly={true}
                        />
                      </Tooltip>
                    </span>
                  </div>
                }
                onChange={e => input.onChange(e.target.checked)}
              />
            )}
          </Field>
          <Field
            name={`${fieldName}_covered_cultivation_in_hectares`}
            initialValue={
              initialValue?.coveredCultivationInHectares ||
              initialValue?.coveredFieldCultivationInHectares ||
              initialValue?.coveredFieldSurfaceInHectares ||
              initialValue?.coveredFieldSurfaceAreaInAres
            }
          >
            {({ input, meta }) => (
              <Input
                size="tiny"
                label={t('plant_cultivation.shared.surface_area_input_label')}
                placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
                disabled={!values[`${fieldName}_covered_checkbox`]}
                error={
                  meta.submitError ||
                  validationError?.[`${fieldName}_covered_cultivation_in_hectares`]
                }
                touched={!meta.dirtySinceLastSubmit}
                {...input}
              />
            )}
          </Field>
        </div>
      )}
      {error && (
        <div className="error-text margin-bottom-10 margin-top-10 margin-left-20">{error}</div>
      )}
    </Card>
  );
};

CultivationMethodInputWithCheckbox.defaultProps = {
  showCoveredCultivation: true,
  isVisible: true,
  fieldName: '',
  initialValue: {},
  values: {},
  title: null,
  iconName: null,
  error: null,
  validationError: null,
  isCheckedAlways: false,
  isViticulture: false,
  isTableGrape: false,
};

CultivationMethodInputWithCheckbox.propTypes = {
  showCoveredCultivation: PropTypes.bool,
  isVisible: PropTypes.bool,
  isCheckedAlways: PropTypes.bool,
  isViticulture: PropTypes.bool,
  isTableGrape: PropTypes.bool,
  fieldName: PropTypes.string,
  initialValue: PropTypes.shape(),
  values: PropTypes.shape(),
  validationError: PropTypes.shape(),
  title: PropTypes.string,
  iconName: PropTypes.string,
  error: PropTypes.string,
};

export default CultivationMethodInputWithCheckbox;
