import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Spinner = ({ className, size, color, bgColor }) => {
  return (
    <div
      className={classNames(className, `spinner spinner--${size} `, {
        [`spinner--${color}`]: bgColor === 'default',
        [`spinner--${bgColor}`]: bgColor === 'neutral',
      })}
    >
      <svg className={`spinner__icon spinner__icon--${color}`} viewBox="25 25 50 50">
        <circle style={{ stroke: `${color}` }} cx="50" cy="50" r="20" />
      </svg>
    </div>
  );
};

Spinner.defaultProps = {
  color: 'secondary-accent',
  size: 'small',
  bgColor: 'default',
  className: null,
};

Spinner.propTypes = {
  color: PropTypes.oneOf(['secondary-accent', 'danger', 'success', 'warning']),
  size: PropTypes.oneOf(['xxs', 'xs', 'tiny', 'small', 'large', 'huge']),
  bgColor: PropTypes.oneOf(['none', 'default', 'neutral']),
  className: PropTypes.string,
};

export default Spinner;
