import React from 'react';
import Modal from 'components/base-components/Modal';
import PropTypes from 'prop-types';

const ConfirmationModalWrapper = ({
  title,
  content,
  footer,
  isVisible,
  titleAlign,
  contentAlign,
  footerAlign,
  className,
  timeOut,
  onTimeout,
}) => (
  <Modal className={className} isVisible={isVisible} timeout={timeOut} onTimeout={onTimeout}>
    <Modal.Title align={titleAlign}>{title}</Modal.Title>
    <Modal.Content align={contentAlign}>{content}</Modal.Content>
    <Modal.Footer align={footerAlign}>{footer}</Modal.Footer>
  </Modal>
);

ConfirmationModalWrapper.defaultProps = {
  title: null,
  content: null,
  footer: null,
  isVisible: false,
  titleAlign: null,
  contentAlign: null,
  footerAlign: null,
  className: null,
  timeOut: null,
  onTimeout: () => {},
};

ConfirmationModalWrapper.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  footer: PropTypes.node,
  isVisible: PropTypes.bool,
  titleAlign: PropTypes.node,
  contentAlign: PropTypes.node,
  footerAlign: PropTypes.node,
  className: PropTypes.string,
  timeOut: PropTypes.number,
  onTimeout: PropTypes.func,
};

export default ConfirmationModalWrapper;
