import React, { useEffect, useState } from 'react';
import { dateFormatter, dateTimeFormatterWith24Hour } from 'utils/date-time-formatter';
import PropTypes from 'prop-types';
import OrganizationName from 'components/organisations/organization-name';
import { Message } from 'components/base-components/MessageList';
import { useDeleteMessageMutation, useUpdateConfirmMessageMutation } from 'api/messages';
import useCustomNavigate from 'hooks/useCustomNavigate';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { useTranslation } from 'react-i18next';
import RowLoaderPlaceHolder from 'components/shared-components/placeholders/row-loader-placeholder.component';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import { useGetCurrentUserQuery } from 'api/users';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import UserInfo from 'components/users/user-info.component';
import Input from 'components/base-components/Input';
import Icon from 'components/base-components/Icon';
import Button from 'components/base-components/Button';
import WarningModal from 'components/shared-components/modal/warning';
import ConfirmationModalWrapper from 'components/users/ConfirmationModalWrapper';
import Spinner from 'components/base-components/Spinner/Spinner';
import MESSAGES_STATUS from 'utils/message-status';
import { Link } from 'react-router-dom';

const MessageTabContentComponent = ({
  messages,
  type,
  onChangeParams,
  isFetchingMessages,
  handleSearch,
}) => {
  const { data: currentUser, isSuccess: isCurrentUserFetched } = useGetCurrentUserQuery();

  const [searchInput, setSearchInput] = useState('');
  const [isExpanded, setIsExpanded] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const navigate = useCustomNavigate();
  const navigationLinks = useNavigationLinks();
  const { t } = useTranslation();
  const [updateConfirmMessage, { isLoading, isError }] = useUpdateConfirmMessageMutation();
  const [deleteMessage] = useDeleteMessageMutation();
  const handleStateToggle = (state, id) => {
    if (state.includes(id)) {
      return state.filter(object => object !== id);
    }
    return [...state, id];
  };

  const handleExpandChange = id => {
    setIsExpanded(handleStateToggle(isExpanded, id));
  };

  const onUpdateConfirmMessage = message => {
    updateConfirmMessage({
      id: message.id,
      payload: {
        message_id: message.id,
      },
    })
      .unwrap()
      .then(() => setIsConfirmModalOpen(false))
      .then(() => setIsConfirmationModalOpen(true));
  };

  useEffect(() => {
    onChangeParams({ type, page: 1 }, type, true);
  }, []);

  const handleConfirmMessage = () => {
    onUpdateConfirmMessage(selectedMessage);
  };

  const modalContent = () => {
    if (isLoading) {
      return (
        <div className="user__invite--modal-loading">
          <Spinner size="small" color="success" bgColor="none" />
          <div className="user__invite--modal-loading-message">
            {t('messages.modal.confirmation.loading_description')}
          </div>
        </div>
      );
    }

    if (isError) {
      return (
        <div className="user__invite--modal-failure">
          <Icon className="user__invite--modal-failure-icon" name="invalid" size="xxl" />
          <div className="user__invite--modal-failure-message">
            <div>{t('messages.modal.confirmation.failure_title')}</div>
            <div>{t('messages.modal.confirmation.failure_description')}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="user__invite--modal-success">
        <Icon
          className="user__invite--modal-success-icon"
          name="check"
          size="large"
          showBGColor={true}
        />
        <div className="user__invite--modal-success-message">
          <div>{t('messages.modal.confirmation.success_title')}</div>
          <div>{t('messages.modal.confirmation.success_description')}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="message__create-search">
        <Input
          className="message__search"
          placeholder={t('messages.search_message_placeholer')}
          preIcon={<Icon name="search" />}
          size="tiny"
          value={searchInput}
          onChange={value => {
            handleSearch(value);
            setSearchInput(value);
          }}
        />
        {isCurrentUserFetched && isBioSuisseAdmin(currentUser) && (
          <Link to={navigationLinks.messageCreatePage()}>
            <Button label={t('messages.send_new_message')} type="success" size="small" />
          </Link>
        )}
      </div>
      <div>
        {isFetchingMessages ? (
          <RowLoaderPlaceHolder numberOfLines={8} />
        ) : messages.length > 0 ? (
          messages.map(message => (
            <Message
              isAdmin={isBioSuisseAdmin(currentUser)}
              key={message.id}
              assignDate={dateFormatter(message?.createdAt)}
              assignee={
                message?.recipientOrganizationId ? (
                  <OrganizationName organizationId={message?.recipientOrganizationId} />
                ) : message?.recipientUserIds.length > 1 ? (
                  t('shared.bio_cuisine_admins')
                ) : (
                  <div className="task-list__item-user">
                    <UserInfo userId={message?.recipientUserIds[0]} />
                  </div>
                )
              }
              assigneeLabel={t('task.assignee')}
              createdTime={dateTimeFormatterWith24Hour(message?.createdAt)}
              description={message?.text}
              id={message.id}
              lastUpdatedTime={dateTimeFormatterWith24Hour(message?.updatedAt)}
              lastUpdatedTimeLabel={t('task.last_update')}
              name={message?.title}
              reporter={
                <div className="task-list__item-user">
                  <UserInfo userData={message?.sender} />
                </div>
              }
              reporterLabel={t('task.creator')}
              isExpanded={isExpanded.includes(message.id)}
              onExpand={handleExpandChange}
              showConfirmButton={
                message.type === MESSAGES_STATUS.unconfirmed && message.needConfirmation
              }
              onButtonConfirm={() => {
                setSelectedMessage(message);
                setIsConfirmModalOpen(true);
              }}
              onDelete={() => deleteMessage(message?.id)}
              onEdit={() => navigate.openMessageEditPage(message?.id)}
            />
          ))
        ) : (
          <EmptyContentPlaceholder text={t('messages.no_message_found')} iconName="chat" />
        )}
      </div>
      <WarningModal
        onOutsideClick={() => setIsConfirmModalOpen(false)}
        isVisible={isConfirmModalOpen}
        title={t('messages.modal.confirm.title')}
        content={t('messages.modal.confirm.description')}
        confirmActionText={t('shared.action.yes')}
        cancelActionText={t('shared.action.no')}
        onCancel={() => setIsConfirmModalOpen(false)}
        onConfirm={handleConfirmMessage}
      />
      <ConfirmationModalWrapper
        isVisible={isConfirmationModalOpen}
        contentAlign="center"
        footerAlign="center"
        content={modalContent()}
        className="user__invite--modal"
        timeOut={!isLoading ? 3000 : 0}
        onTimeout={() => {
          setIsConfirmationModalOpen(false);
        }}
      />
    </>
  );
};

MessageTabContentComponent.defaultProps = {
  messages: [],
  isFetched: false,
  isFetchingMessages: false,
  type: false,
  onChangeParams: () => {},
  handleSearch: () => {},
};

MessageTabContentComponent.propTypes = {
  isFetched: PropTypes.bool,
  isFetchingMessages: PropTypes.bool,
  type: PropTypes.bool,
  messages: PropTypes.arrayOf(PropTypes.shape({})),
  onChangeParams: PropTypes.func,
  handleSearch: PropTypes.func,
};

export default MessageTabContentComponent;
