import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';
import IconButton from '../IconButton';

const Alert = props => {
  const {
    children,
    isVisible,
    className,
    hideClose,
    title,
    type,
    onClose,
    isBackgroundVisible,
    isIconVisible,
    iconSize,
  } = props;

  const iconName = {
    neutral: 'info',
    warning: 'warning',
    success: 'checkCircleOutline',
    danger: 'invalidOutline',
    'warning-light': 'warning',
    'warning-medium': 'warning',
    'success-light': 'checkCircleOutline',
    'danger-light': 'invalidOutline',
    'danger-outline': 'invalid',
  };

  return isVisible ? (
    <div
      className={classNames(
        'rcl-alert',
        `rcl-alert__${type}`,
        {
          [`rcl-alert__${type}--no-background`]: !isBackgroundVisible,
        },
        className,
      )}
    >
      {isIconVisible && (
        <Icon className="rcl-alert__info-icon" name={iconName[type]} size={iconSize} />
      )}
      <div
        className={classNames('rcl-alert-container', { 'rcl-alert-container--no-title': !title })}
      >
        <div className="rcl-alert-container__header">
          {title && <div className="rcl-alert-container__header--title">{title}</div>}
          {!hideClose && (
            <IconButton
              className="rcl-alert-container__header--close-button"
              icon={<Icon name="close" size="tiny" />}
              onClick={onClose}
              size="xxs"
            />
          )}
        </div>
        {children && <div className="rcl-alert-container__content">{children}</div>}
      </div>
    </div>
  ) : null;
};

Alert.defaultProps = {
  isVisible: false,
  children: null,
  className: null,
  hideClose: false,
  title: null,
  type: 'neutral',
  iconSize: 'normal',
  isBackgroundVisible: true,
  isIconVisible: true,
  onClose: () => {},
};

Alert.propTypes = {
  isVisible: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  hideClose: PropTypes.bool,
  isBackgroundVisible: PropTypes.bool,
  isIconVisible: PropTypes.bool,
  iconSize: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  type: PropTypes.oneOf([
    'warning',
    'warning-light',
    'warning-medium',
    'danger',
    'danger-light',
    'danger-outline',
    'success',
    'success-light',
    'neutral',
  ]),
  onClose: PropTypes.func,
};

export default Alert;
