import React from 'react';
import { useGetUserInterestsQuery } from 'api/users';
import { useParams } from 'react-router';
import Spinner from 'components/base-components/Spinner';
import Card from 'components/base-components/Card';
import { t } from 'i18next';
import Icon from 'components/base-components/Icon';
import EmptyPlaceholder from 'components/users/empty-placeholder';

const UserInterests = () => {
  const { id } = useParams();
  const { isFetching, data: interests = [] } = useGetUserInterestsQuery(id);

  if (!isFetching && interests.length === 0) {
    return (
      <EmptyPlaceholder
        emptyCardText={t('user_details.interest.empty_placeholder')}
        emptyIcon={<Icon name="organization" />}
      />
    );
  }

  return (
    <Card>
      {isFetching ? (
        <Spinner className="user-details__spinner" bgColor="none" color="success" size="small" />
      ) : (
        <>
          <div className="user__interests">
            {interests.map(interest => (
              <span className="user__interests-item" data-testid="interest">
                {interest.name}
              </span>
            ))}
          </div>
        </>
      )}
    </Card>
  );
};

export default UserInterests;
