import { TableData, TableRow } from 'components/base-components/Table';
import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader/';

const TableLoaderPlaceholder = ({ numberOfRows, numberOfColumns }) => {
  return Array(numberOfRows)
    .fill(0)
    .map((_, rowIndex) => (
      <TableRow key={`key-${rowIndex}`} className="table-loader-placeholder">
        {Array(numberOfColumns)
          .fill(0)
          .map((_, colIndex) => (
            <TableData key={`key-${colIndex}`}>
              <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} isRounded={true} />
            </TableData>
          ))}
      </TableRow>
    ));
};

TableLoaderPlaceholder.defaultProps = {
  numberOfRows: 10,
  numberOfColumns: 5,
};

TableLoaderPlaceholder.propTypes = {
  numberOfRows: PropTypes.number,
  numberOfColumns: PropTypes.number,
};

export default TableLoaderPlaceholder;
