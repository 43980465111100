const HourMinuteCalculator = ({
  time,
  isHourSelected,
  isMinuteSelected,
  upArrow,
  downArrow,
  minTime,
  maxTime,
}) => {
  let hour, minute, getMinHour, getMaxHour, getMinMinute, getMaxMinute;

  hour = parseInt(time.slice(0, 2), 10);
  let index = time.indexOf(':');
  minute = parseInt(time.substring(index + 1), 10);

  if (isHourSelected) {
    getMinHour = parseInt(minTime.slice(0, 2), 10);
    getMaxHour = parseInt(maxTime.slice(0, 2), 10);
  }

  if (isMinuteSelected) {
    getMinMinute = 0;
    getMaxMinute = 59;
  }

  if (isHourSelected && downArrow) {
    if (hour > parseInt(getMinHour, 10)) hour -= 1;
  } else if (isHourSelected && upArrow) {
    if (hour < parseInt(getMaxHour, 10)) hour += 1;
  }

  if (isMinuteSelected && upArrow) {
    if (minute < getMaxMinute) {
      minute += 1;
    }
  } else if (isMinuteSelected && downArrow) {
    if (minute > getMinMinute) {
      minute -= 1;
    }
  }

  return `${hour}:${minute}`;
};

export default HourMinuteCalculator;
