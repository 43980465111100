import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { START_DATE, END_DATE } from 'react-dates/constants';
import { DayPickerRangeController } from 'react-dates';
import classNames from 'classnames';
import SelectBox from '../SelectBox';
import Icon from '../Icon';

const Calendar = props => {
  const [dates, setDates] = useState({
    startDate: props.startDate ? moment(props.startDate) : null,
    endDate: props.endDate ? moment(props.endDate) : null,
  });
  const [focusedInput, setFocusedInput] = useState(START_DATE);
  const {
    className,
    enablePastDates,
    disableFutureDates,
    enableDateRangeSelection,
    onChange,
    locale,
    enableFilterDropdown,
    pastYearsCount,
    futureYearsCount,
  } = props;

  const onDatesChange = ({ startDate, endDate }) => {
    let newEndDate = endDate;

    if (focusedInput === END_DATE && newEndDate == null) {
      newEndDate = startDate;
    }

    if (focusedInput === START_DATE) {
      newEndDate = null;
    }

    setDates({ startDate, endDate: newEndDate });

    if (enableDateRangeSelection) {
      return onChange({ startDate, endDate });
    }

    return onChange(startDate);
  };

  const onFocusChange = focusedInputName => {
    if (enableDateRangeSelection) {
      return setFocusedInput(focusedInputName || START_DATE);
    }

    return setFocusedInput(START_DATE);
  };

  const isOutsideRange = day => {
    const isActiveDate = moment().diff(day);

    if (enablePastDates) {
      return false;
    }

    if (disableFutureDates) {
      return isActiveDate < 0;
    }

    return isActiveDate > 0;
  };

  const getFilteredYears = useMemo(() => {
    const currentYear = moment().year();
    const startYear = currentYear - pastYearsCount;
    const endYear = currentYear + futureYearsCount;

    return Array.from({ length: endYear - startYear + 1 }, (_, index) => {
      const year = startYear + index;

      return { label: year, value: year };
    });
  }, [pastYearsCount, futureYearsCount]);

  const getMonths = useMemo(() => {
    return moment.months().map(monthName => ({ label: monthName, value: monthName }));
  }, []);

  return (
    <div
      className={classNames(
        'rcl-calendar',
        { 'rcl-calendar__has-filters': enableFilterDropdown },
        className,
      )}
    >
      <DayPickerRangeController
        startDate={dates.startDate}
        endDate={dates.endDate}
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        hideKeyboardShortcutsPanel={true}
        navPrev={<Icon name="arrowBackAlt" />}
        navNext={<Icon name="arrowForwardAlt" />}
        isOutsideRange={isOutsideRange}
        minimumNights={0}
        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => {
          if (enableFilterDropdown) {
            return (
              <div className="rcl-calendar__dropdown-navigation">
                <div className="rcl-calendar__dropdown-navigation__selectbox">
                  <SelectBox
                    className="rcl-calendar__dropdown-navigation-select-box"
                    onChange={selectedValue => {
                      onMonthSelect(month, selectedValue.value);
                    }}
                    value={{
                      label: month.locale(locale).format('MMMM'),
                      value: month.locale(locale).format('MMMM'),
                    }}
                    options={getMonths}
                    size="tiny"
                    width="full"
                    isClearable={false}
                  />
                  <SelectBox
                    className="rcl-calendar__dropdown-navigation-select-box"
                    onChange={newSelectedYear => {
                      onYearSelect(month, newSelectedYear.value);
                    }}
                    value={{ label: month.year(), value: month.year() }}
                    options={getFilteredYears}
                    size="tiny"
                    width="full"
                    isClearable={false}
                  />
                </div>
                <div>{month.locale(locale).format('MMMM YYYY')}</div>
              </div>
            );
          }

          return month.locale(locale).format('MMMM YYYY');
        }}
      />
    </div>
  );
};

Calendar.defaultProps = {
  className: '',
  locale: 'en',
  startDate: null,
  endDate: null,
  onChange: () => {},
  enablePastDates: false,
  disableFutureDates: false,
  enableDateRangeSelection: false,
  enableFilterDropdown: false,
  pastYearsCount: 5,
  futureYearsCount: 5,
};

Calendar.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.string,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  enablePastDates: PropTypes.bool,
  disableFutureDates: PropTypes.bool,
  enableDateRangeSelection: PropTypes.bool,
  enableFilterDropdown: PropTypes.bool,
  pastYearsCount: PropTypes.number,
  futureYearsCount: PropTypes.number,
};

export default Calendar;
