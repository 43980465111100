const numberFormatter = number => {
  const numberToFloat = parseFloat(number);

  if (numberToFloat) {
    const numberWithFixedTwoDecimalValue = numberToFloat.toFixed(2);

    const formattedNumber = new Intl.NumberFormat('de-CH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numberWithFixedTwoDecimalValue);

    return formattedNumber;
  }

  return null;
};

export default numberFormatter;
