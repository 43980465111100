import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/base-components/Spinner';
import { GASTRONOMY } from 'utils/organisation-industries';
import { useGetOrganizationHealthQuery } from 'api/organizations';
import HealthStatus from '../health-status';

const OrganisationHealth = ({ organization, isAdmin }) => {
  const isGastronomy = organization?.industry === GASTRONOMY;
  const { isSuccess, data: health = {} } = useGetOrganizationHealthQuery(organization.id, {
    skip: !isAdmin || !isGastronomy,
  });

  return (
    <>
      {isSuccess ? (
        <HealthStatus
          status={health.type}
          task_count={health.pendingTasksCount}
          messages_count={health.unconfirmedMessagesCount}
        />
      ) : (
        <Spinner bgColor="none" color="success" size="tiny" />
      )}
    </>
  );
};

OrganisationHealth.propTypes = {
  organization: PropTypes.shape().isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
};

export default OrganisationHealth;
