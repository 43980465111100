import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import TvdSampleDE from 'assets/tvd_sample_de.png';
import TvdSampleIT from 'assets/tvd_sample_it.png';
import TvdSampleFR from 'assets/tvd_sample_fr.png';
import { ListItem } from 'components/pdf/shared/shared-components';
import tvdStyles from 'components/pdf/styles/tvd-styles';
import { useTranslation } from 'react-i18next';

const UkContent = () => {
  const { t, i18n } = useTranslation();
  const listItemProps = {
    listStyle: 'largeSquare',
    style: tvdStyles.listPadding,
    prefixStyle: { paddingTop: 2 },
    textStyle: { letterSpacing: -0.1 },
  };

  const sampleMapper = {
    de: TvdSampleDE,
    en: TvdSampleDE,
    fr: TvdSampleFR,
    it: TvdSampleIT,
  };

  const sampleImage = sampleMapper[i18n.language];

  return (
    <>
      <View style={{ ...tvdStyles.flex, ...tvdStyles.marginTop10 }}>
        <Image src={sampleImage} style={{ width: '55%' }} />
        <View style={tvdStyles.stickerText}>
          <Text>{t('pdf.tvd.second.sticker_text1')}</Text>
          <Text style={tvdStyles.marginTop20}>{t('pdf.tvd.second.sticker_text2')}</Text>
        </View>
      </View>
      <View>
        <Text style={{ ...tvdStyles.marginTop10, ...tvdStyles.textBold }}>
          {t('pdf.tvd.uk.second_page.content.title1')}
        </Text>
        <Text>{t('pdf.tvd.uk.second_page.content.description1')}</Text>
        <Text style={{ ...tvdStyles.marginTop10, ...tvdStyles.textBold }}>
          {t('pdf.tvd.uk.second_page.content.title2')}
        </Text>
        <Text>{t('pdf.tvd.uk.second_page.content.description2')}</Text>
        <Text style={{ ...tvdStyles.marginTop10, ...tvdStyles.textBold }}>
          {t('pdf.tvd.uk.second_page.content.title3')}
        </Text>
        <ListItem {...listItemProps}>{t('pdf.tvd.uk.second_page.content.list1')}</ListItem>
        <ListItem {...listItemProps}>{t('pdf.tvd.uk.second_page.content.list2')}</ListItem>
        <ListItem {...listItemProps}>{t('pdf.tvd.uk.second_page.content.list3')}</ListItem>
        <ListItem {...listItemProps}>{t('pdf.tvd.uk.second_page.content.list4')}</ListItem>
        <ListItem {...listItemProps}>{t('pdf.tvd.uk.second_page.content.list5')}</ListItem>
      </View>
    </>
  );
};

export default UkContent;
