import { useGetEggProductionsQuery } from 'api/producers/productions';
import { t } from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import SharedProductionComponent from './shared/shared-production.component';

const EggComponent = ({ id }) => {
  const { data: eggProductionCollection, isSuccess } = useGetEggProductionsQuery(
    { productionId: id },
    { skip: !id },
  );

  const eggProductionData = (isSuccess && eggProductionCollection[0]) || {};

  return (
    <SharedProductionComponent
      title={t('egg_production.poultry')}
      icon="poultry"
      isMultipleCard={true}
    >
      <div className="margin-top-10">
        <div className="flex-space-between margin-top-10">
          <div>{t('egg_production.laying_hens')}</div>
          <div>
            {eggProductionData?.productionDetails?.layingHens || t('common.not_applicable')}
          </div>
        </div>
        <div className="flex-space-between margin-top-10">
          <div>{t('egg_production.female_pullets')}</div>
          <div>
            {eggProductionData?.productionDetails?.femalePullets || t('common.not_applicable')}
          </div>
        </div>
        <div className="flex-space-between margin-top-10">
          <div>{t('egg_production.quails')}</div>
          <div>{eggProductionData?.productionDetails?.quails || t('common.not_applicable')}</div>
        </div>
      </div>
    </SharedProductionComponent>
  );
};

EggComponent.defaultProps = {
  id: null,
};

EggComponent.propTypes = {
  id: PropTypes.node,
};

export default EggComponent;
