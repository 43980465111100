import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import PropTypes from 'prop-types';
import { useGetUserQuery } from '../../../../api/users';
import Avatar from '../../../base-components/Avatar';
import Card from '../../../base-components/Card';
import ApproveDeclarations from '../education-declarations/approve-declarations';
import SubmitDeclarations from '../education-declarations/submit-declarations';
import SetAssignee from './set-assignee.component';
const EducationAssignee = ({
  currentUser,
  educationAssignee,
  organizationId,
  educationDeclarationsStatus,
  refetchEducationAssignee,
}) => {
  const { t } = useTranslation();
  const { data: assignedUser = {} } = useGetUserQuery(
    { id: educationAssignee.userId },
    {
      skip: !educationAssignee.id,
    },
  );

  const isCurrentUserAdmin = isBioSuisseAdmin(currentUser);
  const educationAssigneeId = educationAssignee.id;

  if (!educationAssigneeId) {
    return (
      <Card className="organization-education__assignee">
        <div className="organization-education__assignee-eligibility">
          {t('educations.assignee.hint')}
        </div>
        <SetAssignee
          organizationId={organizationId}
          type="success"
          label={t('educations.assignee.action.add')}
          disabled={isCurrentUserAdmin}
        />
      </Card>
    );
  }

  return (
    <Card>
      <div className="organization-education__assignee">
        <div className="organization-education__assignee-user">
          <span className="organization-education__assignee-user-label">
            {t('educations.assignee.title')}:
          </span>
          <Avatar
            showName={true}
            firstName={assignedUser.firstName}
            lastName={assignedUser.lastName}
            src={assignedUser.profilePictureUrl}
            size="tiny"
          />
        </div>
        <div className="organization-education__assignee-action">
          {!isCurrentUserAdmin && (
            <Fragment>
              <SetAssignee
                organizationId={organizationId}
                associatedUser={assignedUser}
                type="default"
                label={t('educations.assignee.action.change')}
              />
              <SubmitDeclarations
                educationAssigneeId={educationAssigneeId}
                organizationId={organizationId}
                isDisabled={
                  !(educationDeclarationsStatus.isDraft || educationDeclarationsStatus.isComplained)
                }
              />
            </Fragment>
          )}
          {isCurrentUserAdmin && (
            <ApproveDeclarations
              educationAssigneeId={educationAssigneeId}
              isDisabled={!educationDeclarationsStatus.isDeclared}
              organizationId={organizationId}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

EducationAssignee.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  educationAssignee: PropTypes.shape().isRequired,
  organizationId: PropTypes.number.isRequired,
  educationDeclarationsStatus: PropTypes.string.isRequired,
  refetchEducationAssignee: PropTypes.func.isRequired,
};

export default EducationAssignee;
