import { removeEmptyFromObject } from 'utils/object-cleanup';

export const meatValues = (values, formData) => {
  const prepareMeatAttributes = (entityName, attributes) => {
    removeEmptyFromObject(attributes);
    if (!(Object.keys(attributes).length === 1 && attributes.hasOwnProperty('sub_type'))) {
      Object.keys(attributes).forEach(attribute =>
        formData.append(
          `production_details[meat_farms][${entityName}][${attribute}]`,
          attributes[attribute] || '',
        ),
      );
    }
  };

  const cowsAttributes = {
    number_of_mother_cows: values?.cows?.numberOfMotherCows,
    number_of_calves: values?.cows?.numberOfCalves,
    number_of_cattle: values?.cows?.numberOfCattle,
    sub_type: 'cow',
  };

  const pigsAttributes = {
    number_of_fattening_pigs: values?.pigs?.numberOfFatteningPigs,
    number_of_breeding_pigs: values?.pigs?.numberOfBreedingPigs,
    sub_type: 'pig',
  };

  const poultryAttributes = {
    number_of_male_pullets: values?.poultry?.numberOfMalePullets,
    number_of_fattening_chicken: values?.poultry?.numberOfFatteningChicken,
    number_of_turkeys: values?.poultry?.numberOfTurkeys,
    number_of_geese: values?.poultry?.numberOfGeese,
  };

  if (values?.poultryCheckbox) {
    const validPoultryData = values.poultryCheckbox.filter(poultry => !!poultry);

    validPoultryData.map(poultry =>
      formData.append('production_details[meat_farms][poultry][other_poultry][]', poultry),
    );

    formData.append('production_details[meat_farms][poultry][sub_type]', 'poultry');
  }

  const eweSheepAttributes = {
    number_of_ewe_sheep: values?.numberOfEweSheep,
    sub_type: 'ewe_sheep',
  };

  const eweGoatsAttributes = {
    number_of_ewe_goat: values?.numberOfEweGoat,
    sub_type: 'ewe_goat',
  };

  prepareMeatAttributes('cow', cowsAttributes);
  prepareMeatAttributes('pig', pigsAttributes);
  prepareMeatAttributes('poultry', poultryAttributes);
  prepareMeatAttributes('ewe_sheep', eweSheepAttributes);
  prepareMeatAttributes('ewe_goat', eweGoatsAttributes);
};
