// Font Size
const FONT_SIZE_36 = 36;
const FONT_SIZE_20 = 20;
const FONT_SIZE_32 = 32;
const FONT_SIZE_16 = 16;
const FONT_SIZE_14 = 14;
const FONT_SIZE_11 = 11;
const FONT_SIZE_12 = 12;
const FONT_SIZE_10 = 10;
const FONT_SIZE_9 = 9;
const FONT_SIZE_8_5 = 8.5;
const FONT_SIZE_8 = 8;
const FONT_SIZE_6 = 6;

// Font Style
const FONT_WEIGHT_BOLD = 'bold';
const BASE_LINE_HEIGHT = 1.2;

// Colors
const COLOR_BLACK = 'black';
const COLOR_GRAY = 'gray';
const COLOR_BLACK_GREEN = '#00393F';

// Page Layout
const BASE_PAGE_MARGIN = 72;

export {
  FONT_SIZE_36,
  FONT_SIZE_20,
  FONT_SIZE_32,
  FONT_SIZE_16,
  FONT_SIZE_14,
  FONT_SIZE_12,
  FONT_SIZE_11,
  FONT_SIZE_8_5,
  FONT_SIZE_8,
  FONT_SIZE_6,
  FONT_SIZE_10,
  FONT_SIZE_9,
  FONT_WEIGHT_BOLD,
  BASE_LINE_HEIGHT,
  COLOR_BLACK,
  COLOR_GRAY,
  COLOR_BLACK_GREEN,
  BASE_PAGE_MARGIN,
};
