const PLANT_CULTIVATION = {
  perennial: 'perennial',
  annual: 'annual',
  plant_cultivation: 'plant_cultivation',
  meat_production: 'meat_production',
  egg: 'egg',
  bee: 'bee',
  fish: 'fish',
  milk: 'milk',
  animal: 'animal',
  garden: 'garden',
  greenArea: 'greenArea',
  privatePark: 'privatePark',
  fruit: 'fruit',
  fruits: 'fruits',
  herb: 'herb',
  herbs: 'herbs',
  ornamental: 'ornamental',
  viticulture: 'viticulture',
  other: 'other',
  mushroom: 'mushroom',
  mushroom_farming: 'mushroom_farming',
  vegetable: 'vegetable',
  potato: 'potato',
  potatoes: 'potatoes',
  arable_crop: 'arable_crop',
  arableCrops: 'arableCrops',
  tableGrape: 'table_grape',
};

export default PLANT_CULTIVATION;

export const PLANT_CULTIVATION_FROM_BACKEND = {
  plant_cultivation: 'plant_cultivation',
  meat_production: 'meat_production',
  egg: 'egg_production',
  milk: 'milk_production',
  fish: 'fish_production',
  bee: 'bee_keeping',
  animal: 'other',
  garden: 'gardening',
  green_area: 'recreational_and_green_areas',
  private_park: 'private_parks_and_municipalities',
};

export const HIGH_TRUNK_TREE = 'high_trunk_tree';
export const ORCHARD = 'orchard';
export const EATING_BERRIES = 'eating_berry';
export const INDUSTRY_BERRIES = 'industry_berry';
export const MEDICINAL_HERBS = 'medicinal_herbs';
export const POTTED_HERBS = 'potted_herbs';
export const KITCHEN_HERBS = 'kitchen_herbs';
export const SPICE_AND_TEA_HERBS = 'spice_and_tea_herbs';
export const TABLE_GRAPE = 'table_grape';
export const OTHER = 'other';
