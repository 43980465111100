import popover from './popoverDefaultData';
import {
  popoverYPositionFromWindow,
  popoverXPositionFromWindow,
} from './popoverPositionFromWindow';
import getWindowWidthWithoutScrollbar from './getWindowWidthWithoutScrollbar';

/**
 * Returns an object with outside indication of left and right of the popover.
 *
 * @param {node} parentNode The DOM node of the popover-parent.
 * @param {string} direction The direction in which user wants to render popover.
 * @param {object} popoverDimension Popover height and width as an object {height, width}.
 *
 * @return {string} An object with outside indication of left and right of the popover.
 */

const popoverOutsideOfWindow = (parentNode, direction, popoverDimension, tipSize) => {
  const { height: popoverHeight, width: popoverWidth } = popoverDimension;
  const totalPopoverHeight = popoverHeight + popover.tip.size[tipSize].height + popover.tip.gap;
  const totalPopoverWidth = popoverWidth + popover.tip.size[tipSize].height + popover.tip.gap;
  const popoverNode = {
    top: popoverYPositionFromWindow(parentNode, direction, popoverDimension, tipSize),
    left: popoverXPositionFromWindow(parentNode, direction, popoverDimension, tipSize),
  };
  const availableWindowWidth = getWindowWidthWithoutScrollbar();
  const availableWindowHeight = window.innerHeight;

  return {
    isHorizontallyOutside:
      popoverNode.left < 0 || popoverNode.left + totalPopoverWidth > availableWindowWidth,
    isVerticallyOutside:
      popoverNode.top < 0 || popoverNode.top + totalPopoverHeight > availableWindowHeight,
  };
};

export default popoverOutsideOfWindow;
