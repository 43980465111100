import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Footer = props => {
  const { align, className, children } = props;

  return (
    <div className={classNames('modal__footer', `modal__footer--${align}`, className)}>
      {children}
    </div>
  );
};

Footer.defaultProps = {
  align: 'center',
  className: null,
  children: null,
};

Footer.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Footer;
