const appendListOfFields = (form, formData, attributesMapper) => {
  const dirtyFields = form.getState().dirtyFields;
  attributesMapper.forEach(attribute => {
    const isDirty = dirtyFields[attribute.fieldName];
    if (isDirty) {
      formData.append(attribute.apiKey, attribute.value);
    }
  });
};

export { appendListOfFields };
