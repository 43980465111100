import EmphasisTag from 'components/base-components/EmphasisTag';
import React from 'react';
import PropTypes from 'prop-types';
import {
  SCHEDULED,
  SUGGESTED,
  ACCEPTED,
  TEMPORARY,
  PERMANENT,
  SAVED,
} from 'utils/control-body-survey';
import { useTranslation } from 'react-i18next';
import { SUBMITTED } from 'utils/verification-status';

const ControlVerificationTag = ({ status, assessmentOutcome }) => {
  const { t } = useTranslation();

  switch (status) {
    case SUGGESTED:
      return (
        <EmphasisTag type="warning" size="tiny" text={t(`control_flow.tag_status.${status}`)} />
      );
    case SUBMITTED:
      return (
        <EmphasisTag type="accent" size="tiny" text={t(`control_flow.tag_status.${status}`)} />
      );
    case TEMPORARY:
      return (
        <EmphasisTag type="danger" size="tiny" text={t(`control_flow.tag_status.${status}`)} />
      );
    case PERMANENT:
      return (
        <EmphasisTag
          type={assessmentOutcome === 'positive' ? 'success' : 'danger'}
          size="tiny"
          text={t(`control_flow.tag_status.${status}`)}
        />
      );
    case SCHEDULED:
      return (
        <EmphasisTag type="warning" size="tiny" text={t(`control_flow.tag_status.${status}`)} />
      );
    case ACCEPTED:
      return (
        <EmphasisTag type="success" size="tiny" text={t(`control_flow.tag_status.${status}`)} />
      );
    case SAVED:
      return (
        <EmphasisTag type="warning" size="tiny" text={t(`control_flow.tag_status.${status}`)} />
      );
    default:
      return t('shared.not_available');
  }
};

ControlVerificationTag.defaultProps = {
  assessmentOutcome: null,
};

ControlVerificationTag.propTypes = {
  status: PropTypes.string.isRequired,
  assessmentOutcome: PropTypes.string,
};

export default ControlVerificationTag;
