import {
  MUSHROOM_FARMING,
  ORNAMENTAL,
  OTHER,
  POTATO,
  VEGETABLE,
  VITICULTURE,
} from 'utils/production-data-type';
import { PLANT_CULTIVATION } from 'utils/production-type';

const plantValidation = values => {
  return {
    plant:
      values?.production?.includes(PLANT_CULTIVATION) &&
      values?.plantTypes &&
      Object.keys(values?.plantTypes)?.every(data => !values?.plantTypes?.[data]),
    arable_crop:
      values?.arableCrops &&
      Object.keys(values?.arableCrops)?.every(data => !values?.arableCrops?.[data]),
    ornamental:
      values?.plantTypes?.includes(ORNAMENTAL) &&
      !values?.ornamental_open_checkbox &&
      !values?.ornamental_covered_checkbox,
    ornamental_covered_cultivation_in_hectares:
      values?.ornamental_covered_checkbox && !values?.ornamental_covered_cultivation_in_hectares,
    ornamental_open_field_in_hectares:
      values?.ornamental_open_checkbox && !values?.ornamental_open_field_in_hectares,
    other:
      values?.plantTypes?.includes(OTHER) &&
      !values?.other_open_checkbox &&
      !values?.other_covered_checkbox,
    other_covered_cultivation_in_hectares:
      values?.other_covered_checkbox && !values?.other_covered_cultivation_in_hectares,
    other_open_field_in_hectares:
      values?.other_open_checkbox && !values?.other_open_field_in_hectares,
    viticulture:
      values?.plantTypes?.includes(VITICULTURE) && !values?.viticulture_open_field_in_hectares,
    potato: values?.plantTypes?.includes(POTATO) && !values?.potatoes_open_checkbox,
    potatoes_open_field_in_hectares:
      values?.plantTypes?.includes(POTATO) && !values?.potatoes_open_field_in_hectares,
    mushroom:
      values?.plantTypes?.includes(MUSHROOM_FARMING) &&
      !values?.mushroom_covered_checkbox &&
      !values.mushroom_open_checkbox,
    mushroom_others:
      values?.plantTypes?.includes(MUSHROOM_FARMING) &&
      !values.mushroom_truffles_quantity_in_tons &&
      !values.mushroom_nobles_quantity_in_tonnes &&
      !values.mushroom_champigon_quantity_in_tons,
    mushroom_open_field_in_ares:
      values?.mushroom_open_checkbox && !values?.mushroom_open_field_in_ares,
    mushroom_covered_field_in_ares:
      values?.mushroom_covered_checkbox && !values?.mushroom_covered_field_in_ares,
    vegetable:
      values?.plantTypes?.includes(VEGETABLE) &&
      !values?.vegetables_covered_checkbox &&
      !values?.vegetables_open_checkbox &&
      !values?.vegetables_covered_without_solid_land_checkbox,
    vegetables_covered_cultivation_without_solid_land_in_ares:
      values?.vegetables_covered_without_solid_land_checkbox &&
      !values?.vegetables_covered_cultivation_without_solid_land_in_ares,
    vegetables_open_field_in_hectares:
      values?.vegetables_open_checkbox && !values?.vegetables_open_field_in_hectares,
    vegetables_covered_solid_land_in_ares:
      values?.vegetables_covered_checkbox && !values?.vegetables_covered_solid_land_in_ares,
    table_grape:
      values?.table_grapes_checkbox &&
      !values?.table_grapes_open_checkbox &&
      !values?.table_grapes_covered_checkbox,
    table_grapes_open_field_in_hectares:
      values?.table_grapes_open_checkbox && !values?.table_grapes_open_field_in_hectares,
    table_grapes_covered_cultivation_in_hectares:
      values?.table_grapes_covered_checkbox &&
      !values?.table_grapes_covered_cultivation_in_hectares,
  };
};

export default plantValidation;
