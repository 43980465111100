import React from 'react';
import { useGetCurrentUserQuery } from 'api/users';
import SharedMessages from './shared-messages.component';

const UserMessages = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  return <SharedMessages currentUserId={currentUser?.id} />;
};

export default UserMessages;
