import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { t } from 'i18next';
import Input from 'components/base-components/Input';
import SelectBox from 'components/base-components/SelectBox';

const UserEditForm = ({ user, validationError, name }) => {
  const salutationOptions = [
    {
      label: t('user_salutation.mister'),
      value: 'mr',
    },
    {
      label: t('user_salutation.miss'),
      value: 'ms',
    },
  ];

  return (
    <>
      <Field name={`${name}.userId`} initialValue={user?.id} type="hidden" component="input" />
      <div className="col-2 col-bleed">
        <Field name={`${name}.salutation`} initialValue={user?.salutation}>
          {({ input, meta }) => (
            <SelectBox
              size="tiny"
              width="large"
              label={t('legal_form.input.salutation')}
              placeholderText={t('user_general.placeholder')}
              isClearable={false}
              options={salutationOptions}
              value={salutationOptions.find(option => option.value === input.value)}
              onChange={e => input.onChange(e.value)}
              errorMsg={meta.submitError}
            />
          )}
        </Field>
      </div>
      <div className="col-5 col-bleed-y legal-form__card--firstname">
        <Field name={`${name}.firstName`} initialValue={user?.firstName}>
          {({ input, meta }) => (
            <Input
              {...input}
              size="tiny"
              label={t('legal_form.input.firstname')}
              required={true}
              placeholder={t('user_invite.firstname.placeholder')}
              touched={!meta.dirtySinceLastSubmit}
              error={meta.submitError || validationError?.assignments}
            />
          )}
        </Field>
      </div>
      <div className="col-5 col-bleed">
        <Field name={`${name}.lastName`} initialValue={user?.lastName}>
          {({ input, meta }) => (
            <Input
              {...input}
              size="tiny"
              label={t('legal_form.input.lastname')}
              required={true}
              placeholder={t('user_invite.lastname.placeholder')}
              touched={!meta.dirtySinceLastSubmit}
              error={meta.submitError || validationError?.assignments}
            />
          )}
        </Field>
      </div>
      <div className="col-6 col-bleed legal-form__email">
        <Field name={`${name}.email`} initialValue={user?.email}>
          {({ input, meta }) => (
            <Input
              {...input}
              size="tiny"
              label={t('legal_form.input.email')}
              placeholder={t('user_contact.email_address.placeholder')}
              required={true}
              touched={!meta.dirtySinceLastSubmit}
              error={meta.submitError || validationError?.assignments}
            />
          )}
        </Field>
      </div>
    </>
  );
};

UserEditForm.defaultProps = {
  validationError: {},
};

UserEditForm.propTypes = {
  user: PropTypes.shape().isRequired,
  validationError: PropTypes.shape(),
  name: PropTypes.string.isRequired,
};

export default UserEditForm;
