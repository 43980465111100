import Card from 'components/base-components/Card';
import TitleComponent from 'components/organisations/form/producer/plant-cultivate/shared/title.component';
import React from 'react';
import PropTypes from 'prop-types';
import { removeEmptyFromObject, toSnakecaseKeys } from 'utils/object-cleanup';
import { t } from 'i18next';
import { isNonEmptyObject } from 'utils/check-object-length';
import sortTitleOrder from '../helpers/sortTitleOrder';

const PlantCultivationContent = ({ title, icon, name, values, isCheckboxData }) => {
  const productionData = {
    openField:
      values?.surfaceAreaInHectares ||
      values?.openFieldInHectares ||
      values?.openFieldInHectares ||
      values?.openFieldCultivationInHectares,
    coveredCultivation:
      values?.coveredSolidLandInHectares ||
      values?.coveredFieldInHectares ||
      values?.coveredCultivationInHectares ||
      values?.coveredFieldCultivationInHectares,
    coveredFieldWithoutSolidLand: values?.coveredCultivationWithoutSolidLandInHectares,
    champignons: values?.champignonsQuantityInTonnes,
    nobleMushroom: values?.noblesQuantityInTonnes,
    truffles: values?.trufflesQuantityInTonnes,
  };

  const plantInHectre = ['potato', 'ornamental', 'viticulture'];
  const plantInTonnes = ['noble_mushroom', 'truffles', 'champignons'];

  const filteredProductionData = toSnakecaseKeys(removeEmptyFromObject(productionData));

  const arableCropsData = isCheckboxData && values && toSnakecaseKeys(values);

  const handleAddUnit = () => {
    const isPlantDataInHectre = plantInHectre.includes(name);
    return Object.keys(filteredProductionData).map(key => {
      const isTonnesData = plantInTonnes.includes(key);

      return (
        <div className="flex-space-between margin-top-10">
          <div>{t(`operations.shared.${key}`)}</div>
          <div>
            {isPlantDataInHectre
              ? t('operations.unit.hectre', {
                  value: filteredProductionData[key],
                })
              : isTonnesData
              ? t('operations.unit.tons', {
                  value: filteredProductionData[key],
                })
              : t('operations.unit.ares', {
                  value: filteredProductionData[key],
                })}
          </div>
        </div>
      );
    });
  };

  if (isCheckboxData && arableCropsData) {
    return (
      <Card className="operations__production--content">
        <TitleComponent title={title} icon={icon} isOutsideForm={true} />
        <hr className="organisation-checklist__checkbox-horizontal-line" />
        {sortTitleOrder('arable_crops', arableCropsData).map(
          value =>
            arableCropsData[value] && (
              <div key={value} className="margin-top-10">
                {t(`operations.arable_crops.${value}`)}
              </div>
            ),
        )}
      </Card>
    );
  }

  return (
    <>
      {isNonEmptyObject(filteredProductionData) && (
        <Card className="operations__production--content">
          <TitleComponent title={title} icon={icon} isOutsideForm={true} />
          <hr className="organisation-checklist__checkbox-horizontal-line" />
          {handleAddUnit()}
        </Card>
      )}
    </>
  );
};

PlantCultivationContent.defaultProps = {
  values: null,
  isCheckboxData: false,
};

PlantCultivationContent.propTypes = {
  title: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.node.isRequired,
  values: PropTypes.shape(),
  isCheckboxData: PropTypes.bool,
};

export default PlantCultivationContent;
