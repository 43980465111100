import { useEffect } from 'react';

/**
 * This hook will invoke the provided callback function, if any user click outside
 * of the nodes provided in the refs array
 *
 * @param {array} refs An array of nodes. If we click outside of any of these node the callback will be invoked.
 * @param {function} callback The function that will be invoked when clicking outside.
 * @param {boolean} addRemoveEventListener If true then the mousedown eventlistener will be removed from window.
 */

const useOutsideClickHandler = (refs, callback, addRemoveEventListener = false) => {
  const handlerFunction = event => {
    const isOutside = refs.every(ref => ref.current && !ref.current.contains(event.target));

    if (isOutside) {
      callback(event);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handlerFunction);

    if (addRemoveEventListener) {
      document.removeEventListener('mousedown', handlerFunction);
    }

    return () => document.removeEventListener('mousedown', handlerFunction);
  }, [refs]);
};

export default useOutsideClickHandler;
