import { creditor } from './invoice-config';

export const CREDIT_ACCOUNT_NUMBER = 1100;

const getDebitorInfo = (organization, invoice) => {
  const address = invoice.address;
  const line = address?.line1 + (address?.line2 ? `, ${address?.line2}` : '');

  return {
    address: line,
    city: address.city,
    country: address.country?.code?.toUpperCase(),
    name: organization.name,
    zip: address.zipCode,
  };
};

const getInvoiceBillingInfo = (organization, invoice) => ({
  amount: invoice.totalAmountInclVat,
  currency: 'CHF',
  reference: invoice.reference,
  creditor: creditor,
  debtor: getDebitorInfo(organization, invoice),
});

export { getDebitorInfo, getInvoiceBillingInfo };
