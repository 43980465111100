import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NEW, GOOD, NEED_CHECKUP, BAD } from '../../utils/health-status';
import EmphasisTag from '../base-components/EmphasisTag';
import Popover from '../base-components/Popover';
import Icon from '../base-components/Icon';
import IconButton from '../base-components/IconButton';

const HealthStatus = ({ status, task_count, messages_count }) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  let tag;

  switch (status) {
    case NEW:
      tag = <EmphasisTag type="normal" size="tiny" text={t('organisation.health.status.new')} />;
      break;
    case GOOD:
      tag = <EmphasisTag type="success" size="tiny" text={t('organisation.health.status.good')} />;
      break;
    case NEED_CHECKUP:
      tag = (
        <EmphasisTag
          type="warning"
          size="tiny"
          text={t('organisation.health.status.need_checkup')}
        />
      );
      break;
    case BAD:
      tag = <EmphasisTag type="danger" size="tiny" text={t('organisation.health.status.bad')} />;
      break;
  }

  return (
    <div className="organisation__table-row-health">
      {tag}
      <Popover
        isVisible={openModal}
        size="medium"
        content={
          <Fragment>
            <div className="organisation__table-row-health__popover">
              {t('organisation.health.overdue_tasks', { count: task_count })}
            </div>
            <div className="organisation__table-row-health--margin-top organisation__table-row-health__popover">
              {t('organisation.health.not_acknowledged_messages', { count: messages_count })}
            </div>
          </Fragment>
        }
        direction="bottom-right"
        onOutsideClick={() => setOpenModal(false)}
      >
        <IconButton
          icon={<Icon size="tiny" name="info" color="success" showBGColor={false} />}
          onClick={() => setOpenModal(!openModal)}
          isIconOnly={true}
          color="default"
        />
      </Popover>
    </div>
  );
};

HealthStatus.defaultProps = {
  status: null,
  task_count: 0,
  messages_count: 0,
};

HealthStatus.propTypes = {
  status: PropTypes.string,
  task_count: PropTypes.number,
  messages_count: PropTypes.number,
};

export default HealthStatus;
