import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { t } from 'i18next';
import { useParams } from 'react-router';
import { FieldArray } from 'react-final-form-arrays';
import { Trans } from 'react-i18next';
import Button from 'components/base-components/Button';
import classNames from 'classnames';
import { useForm, useFormState } from 'react-final-form';
import RadioWithoutCheck from 'components/base-components/RadioWithoutCheck';
import SearchUser from 'components/users/search-user.component';
import WarningModal from 'components/shared-components/modal/warning';
import { useGetOrganizationAssignmentsQuery } from 'api/organizations';
import { OPERATION_MANAGER } from 'utils/users-role';
import Card from 'components/base-components/Card';
import UpdateSingleEntity from '../../update-single-entity.component';
import { legalFormValues } from './../../producer/submit-form/legal-form';
import UserEditForm from './shared/user-edit-form.component';
import ContractPartnerList from './shared/contract-partner-list.component';
import LegalFormType from './shared/legal-form-type.component';

const LegalForm = ({
  organisation,
  enableWarningModal,
  setEnableWarningModal,
  updateOrganization,
  formData,
}) => {
  const { id } = useParams();
  const { data: assignments = [] } = useGetOrganizationAssignmentsQuery({
    organizationId: id,
    'roles[]': [OPERATION_MANAGER],
  });

  const form = useForm();
  const { values } = useFormState();

  const EXISTING_USER = 'existing_user';
  const NEW_USER = 'new_user';

  const handleSubmit = () => {
    legalFormValues(values, formData, false);
    setEnableWarningModal(false);
    updateOrganization(formData);
  };

  const operationsManagers = useMemo(() => {
    return assignments.map(assignment => ({
      userId: assignment.userId,
      operationManager: true,
    }));
  }, [JSON.stringify(assignments)]);

  return (
    <>
      <WarningModal
        onOutsideClick={() => {
          setEnableWarningModal(false);
        }}
        isVisible={enableWarningModal}
        title={t('legal_form.modal.wanring.title')}
        content={
          <div>
            <div>
              <Trans
                i18nKey="legal_form.modal.wanring.description"
                components={{ bold: <b /> }}
                values={{ organisation_name: organisation.name }}
              />
            </div>
            <div>{t('shared.modal.approve_confirmation.confirm_text')}</div>
          </div>
        }
        confirmActionText={t('shared.action.yes')}
        cancelActionText={t('shared.action.cancel')}
        onCancel={() => setEnableWarningModal(false)}
        onConfirm={handleSubmit}
      />
      <LegalFormType organisation={organisation} />
      <FieldArray name="legalFormPartners" initialValue={operationsManagers}>
        {({ fields }) => (
          <>
            <Card className="legal-form__card">
              <div className="boldest-font">
                {t('legal_form.contract_details.current_operations_manager')}
              </div>
              <div>
                {fields.map((name, index) => {
                  if (fields.value[index].destroy) {
                    return;
                  }

                  return (
                    <ContractPartnerList
                      validationError={{}}
                      values={fields.value[index]}
                      name={name}
                      key={index}
                      index={index}
                      onRemove={() => {
                        if (
                          operationsManagers.some(
                            operationManager =>
                              operationManager.userId === fields.value[index].userId,
                          )
                        ) {
                          fields.update(index, {
                            ...fields.value[index],
                            operationManager: false,
                            destroy: true,
                          });
                        } else {
                          fields.remove(index);
                        }
                      }}
                      changeOperationManagerOnly={true}
                      onEdit={(attributes = null) => {
                        const updatedAttributes = attributes || values[`modifyUser-${index}`];
                        fields.update(index, {
                          ...fields.value[index],
                          ...updatedAttributes,
                        });

                        form.change('modifyUser', undefined);
                        return Promise.resolve();
                      }}
                    />
                  );
                })}
              </div>
            </Card>
            <Card>
              <div className="boldest-font">
                {t('legal_form.contract_details.add_operation_manager')}
              </div>
              <div className="font-size-10 margin-top-8">
                {t('legal_form.contract_details.description')}
              </div>
              <div className="margin-top-20 align-horizontically">
                {[EXISTING_USER, NEW_USER].map(type => (
                  <Field name="userSelectionType" type="radio" value={type}>
                    {({ input }) => (
                      <RadioWithoutCheck
                        className="organisation__form--radio__item"
                        isChecked={input.checked}
                        name={type}
                        label={t(`legal_form.contract_details.${type}`)}
                        onChange={e => {
                          input.onChange(type);
                        }}
                        size="medium"
                        disabled={false}
                      />
                    )}
                  </Field>
                ))}
              </div>
              {values.userSelectionType && (
                <>
                  {values.userSelectionType === NEW_USER && <UserEditForm name="new" />}
                  {values.userSelectionType === EXISTING_USER && (
                    <Field name="selectedUser">
                      {({ input, meta }) => (
                        <>
                          <SearchUser
                            className={'width-50-percent display-inline-block margin-right-8'}
                            label={t('legal_form.contract_details.select_operation_manager')}
                            value={input.value}
                            placeholderText={t(
                              'legal_form.contract_details.select_operation_manager',
                            )}
                            onChange={input.onChange}
                          />
                        </>
                      )}
                    </Field>
                  )}

                  <Button
                    className={classNames({
                      'display-block margin-top-20': values.userSelectionType === NEW_USER,
                    })}
                    type="default"
                    label={t('shared.action.add')}
                    size="small"
                    onClick={() => {
                      let attributes;
                      if (values.userSelectionType === EXISTING_USER) {
                        attributes = { userId: values.selectedUser.value };
                      } else {
                        const firstName = values.new.firstName;
                        const lastName = values.new.lastName;
                        attributes = {
                          firstName,
                          lastName,
                          name: firstName + lastName,
                          email: values.new.email,
                        };
                      }
                      fields.push({ ...attributes, operationManager: true });
                    }}
                  />
                </>
              )}
            </Card>
          </>
        )}
      </FieldArray>
    </>
  );
};

LegalForm.propTypes = {
  organisation: PropTypes.shape().isRequired,
  enableWarningModal: PropTypes.bool.isRequired,
  setEnableWarningModal: PropTypes.func.isRequired,
  updateOrganization: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
};

const LegalFormEdit = () => (
  <UpdateSingleEntity warningModalNeeded={true}>
    <LegalForm />
  </UpdateSingleEntity>
);

export default LegalFormEdit;
