import routes from 'utils/routes';
import {
  GOODS,
  GOODS_OVERVIEW,
  ORGANISATION,
  ORGANISATION_DECLARATION_STATUS,
  ORGANISATION_OVERVIEW,
} from 'utils/tag-types';
import { apiSlice } from '../index';

const goodsDeclarationsApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getGoodsDeclaration: build.query({
      query: id => routes.goods.entity({ id }),
      providesTags: [GOODS],
    }),
    getOrganizationGoods: build.query({
      query: params => routes.goods.collectionOfGoods(params),
      transformResponse: (response, meta, arg) => {
        return {
          goods: response.collection,
          pagination: response.pagination,
        };
      },
      providesTags: [GOODS],
    }),
    getOrganizationActiveGoods: build.query({
      query: id => routes.goods.activeGoods({ id }),
      providesTags: [GOODS],
    }),
    createOrganisationGoods: build.mutation({
      query: ({ id, payload }) => ({
        url: routes.goods.collectionOfGoods({ id }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [GOODS, GOODS_OVERVIEW],
    }),
    updateOrganizationGoods: build.mutation({
      query: ({ params, payload }) => ({
        url: routes.goods.updateGoods(params),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [GOODS, GOODS_OVERVIEW],
    }),
    declareGoods: build.mutation({
      query: ({ id, payload }) => ({
        url: routes.goods.declareGoods({ organizationId: id }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [GOODS, GOODS_OVERVIEW, ORGANISATION_OVERVIEW, ORGANISATION],
    }),
    getActiveGoodsOverview: build.query({
      query: params => routes.goods.activeGoodsOverview(params),
      providesTags: [GOODS_OVERVIEW],
    }),
    createGoodsDeclarationsComplaint: build.mutation({
      query: ({ queryParams, payload }) => ({
        url: routes.goods.complaintsGoods(queryParams),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, id) => (!error ? [GOODS, GOODS_OVERVIEW] : []),
    }),
    approveGoods: build.mutation({
      query: queryParams => ({
        url: routes.goods.approveGoods(queryParams),
        method: 'PUT',
      }),
      invalidatesTags: [
        GOODS,
        GOODS_OVERVIEW,
        ORGANISATION_OVERVIEW,
        ORGANISATION_DECLARATION_STATUS,
        ORGANISATION,
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGoodsDeclarationQuery,
  useGetOrganizationGoodsQuery,
  useCreateOrganisationGoodsMutation,
  useGetOrganizationActiveGoodsQuery,
  useUpdateOrganizationGoodsMutation,
  useDeclareGoodsMutation,
  useApproveGoodsMutation,
  useCreateGoodsDeclarationsComplaintMutation,
  useGetActiveGoodsOverviewQuery,
} = goodsDeclarationsApi;
