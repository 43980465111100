import { t } from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';

const EmptyLegalForm = ({ title, description }) => {
  return (
    <div className="legal-form__operation-manage">
      <div className="legal-form__contract--title">{title}</div>
      <div className="legal-form__contract--description">{description}</div>
      <div className="legal-form__details--no-data">{t('legal_form.select_request')}</div>
    </div>
  );
};

EmptyLegalForm.defaultProps = {
  title: null,
  description: null,
};

EmptyLegalForm.propTypes = {
  title: PropTypes.node,
  description: PropTypes.node,
};

export default EmptyLegalForm;
