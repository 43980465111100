import React from 'react';
import PropTypes from 'prop-types';

function SideNavFooterBrand(props) {
  const { slogan } = props;

  return (
    <li>
      <div className="side-nav__footer-brand">
        <div className="side-nav__footer-brand-slogan">{slogan}</div>
      </div>
    </li>
  );
}

SideNavFooterBrand.defaultProps = {
  slogan: '',
};

SideNavFooterBrand.propTypes = {
  slogan: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default SideNavFooterBrand;
