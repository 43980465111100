const gardenValidation = values => {
  return {
    gardening_items:
      values?.production?.includes('garden') &&
      (!values?.gardening_items ||
        Object.keys(values?.gardening_items).every(item => !values?.gardening_items[item])),
  };
};

export default gardenValidation;
