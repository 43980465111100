import WarningModal from 'components/shared-components/modal/warning';
import React, { useState } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from 'components/base-components/Button';
import { Trans } from 'react-i18next';
import { useReopenInvoiceMutation } from '../../../api/invoices';
import SubmitModal from '../../shared-components/modal/submit-modal';
import { PAID } from '../helpers/invoice-tag-types';

const InvoiceReOpenComponent = ({ invoice, isAdmin }) => {
  const [isReOpenModalOpen, setIsReOpenModalOpen] = useState(false);
  const [reOpenInvoice, reOpenStatus] = useReopenInvoiceMutation();

  const onReOpen = () => {
    reOpenInvoice({ id: invoice.id })
      .unwrap()
      .then(() => setIsReOpenModalOpen(false));
  };

  return (
    <>
      {isAdmin && invoice.status === PAID && (
        <Button
          onClick={() => setIsReOpenModalOpen(true)}
          label={t('invoice.reopen_invoice')}
          size="small"
        />
      )}
      <WarningModal
        onOutsideClick={() => setIsReOpenModalOpen(false)}
        isVisible={isReOpenModalOpen}
        title={t('invoice.reopen_modal.warning_title')}
        content={
          <Trans
            i18nKey="invoice.reopen_modal.warning_description"
            values={{ invoiceId: invoice.invoiceId }}
          />
        }
        confirmActionText={t('shared.action.yes')}
        cancelActionText={t('shared.action.no')}
        onCancel={() => setIsReOpenModalOpen(false)}
        onConfirm={() => onReOpen(invoice.id)}
      />
      <SubmitModal
        isSuccess={reOpenStatus?.isSuccess}
        isLoading={reOpenStatus?.isLoading}
        isError={reOpenStatus?.isError}
        requestId={reOpenStatus?.requestId}
        successTitle={t('invoice.reopen_modal.title')}
        successContent={t('invoice.reopen_modal.description')}
        showError={false}
        showLoader={true}
      />
    </>
  );
};

InvoiceReOpenComponent.propTypes = {
  invoice: PropTypes.shape().isRequired,
  onReOpen: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default InvoiceReOpenComponent;
