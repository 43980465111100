import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import i18next from 'i18next';

moment.locale(i18next.language);

// Example: Nov 2022
const currentMonthNameWithYear = date => moment(date).format('MMM YYYY');
// Example: Dec 2022
const nextMonthNameWithYear = date => moment(date).add(1, 'month').format('MMM YYYY');
// Example: 28 Mar 2023
const currentMonthNameWithDateAndYear = date => moment(date).add(1, 'month').format('DD MMM YYYY');

export { currentMonthNameWithYear, nextMonthNameWithYear, currentMonthNameWithDateAndYear };
