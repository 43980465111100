import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Title = props => {
  const { align, children, className } = props;

  return (
    <div className={classNames('modal__title', `modal__title--${align}`, className)}>
      {children}
    </div>
  );
};

Title.defaultProps = {
  align: 'left',
  children: null,
  className: null,
};

Title.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Title;
