import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Icon from 'components/base-components/Icon';
import Modal from 'components/base-components/Modal';
import Button from 'components/base-components/Button';
import { NO_RATING } from 'utils/organisation-stars';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { useGetCurrentUserQuery } from 'api/users';
import TitleWithBadge from 'components/shared-components/title-with-badge';
import FilterContainer from './filter-container.component';

const OrganisationFilterModal = props => {
  const { isFilterVisible, setIsFilterVisible, setFilterQueryParams, setTotalFilterCount } = props;
  const { data: currentUser } = useGetCurrentUserQuery();
  const isAdmin = isBioSuisseAdmin(currentUser);

  const getMemberRange = members => {
    const minRangeOfUsers = [];
    const maxRangeOfUsers = [];

    members?.forEach(({ min, max }) => {
      if (max) {
        minRangeOfUsers.push(min);
        maxRangeOfUsers.push(max);
      }
    });

    return { minRangeOfUsers, maxRangeOfUsers };
  };

  const getTotalCount = ({ organizationIds, stars, members }) =>
    [organizationIds, stars, members].filter(Boolean).reduce((sum, arr) => sum + arr.length, 0);

  const onSubmit = values => {
    const { stars, members, organizationIds } = values;
    const noRating = stars?.includes(NO_RATING);
    const minimumUsers = members?.find(({ min, max }) => min && !max)?.min;
    const { minRangeOfUsers, maxRangeOfUsers } = getMemberRange(members);

    setFilterQueryParams({
      stars: stars?.filter(star => star !== NO_RATING),
      page: 1,
      noRating,
      minimumUsers,
      minRangeOfUsers,
      maxRangeOfUsers,
      organizationIds,
    });

    setTotalFilterCount(getTotalCount(values));
    setIsFilterVisible(false);
  };

  return (
    <Modal
      className="modal__filter"
      isVisible={isFilterVisible}
      onOutsideClick={() => setIsFilterVisible(false)}
    >
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Title className="modal__filter--title">
              <TitleWithBadge
                title={t('shared.action.filter')}
                count={getTotalCount(values)}
                showBadge={true}
              />
              <div onClick={() => setIsFilterVisible(false)}>
                <Icon size="small" color="tertiary" name="close" />
              </div>
            </Modal.Title>
            <Modal.Content>
              <FilterContainer isAdmin={isAdmin} form={form} />
            </Modal.Content>
            <Modal.Footer className="modal__filter--footer">
              <Button
                className="modal__filter--footer-button"
                type="success"
                label={t('shared.action.apply')}
                size="small"
                disabled={submitting}
                onClick={handleSubmit}
              />
              <Button
                className="modal__filter--footer-button"
                label={t('shared.action.cancel')}
                size="small"
                onClick={() => setIsFilterVisible(false)}
              />
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
};

OrganisationFilterModal.propTypes = {
  isFilterVisible: PropTypes.bool.isRequired,
  setIsFilterVisible: PropTypes.func.isRequired,
  setTotalFilterCount: PropTypes.func.isRequired,
  setFilterQueryParams: PropTypes.func.isRequired,
  selectedFilters: PropTypes.shape().isRequired,
};

export default OrganisationFilterModal;
