import {
  useGetSupplierCertificateDeclarationsQuery,
  useGetSupplierCertificateQuery,
} from 'api/supplier-certificates';
import PropTypes from 'prop-types';
import Button from 'components/base-components/Button';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import Pagination from 'components/base-components/Pagination/Pagination';
import {
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/base-components/Table';
import Table from 'components/base-components/Table/Table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import SUPPLIER_CERTIFICATE_TYPE from 'utils/supplier-certificate-type';
import { monthYearShort } from 'utils/date-time-formatter';

const SupplierCertificateTableRow = ({ history }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data: supplierCertificate = {} } = useGetSupplierCertificateQuery({
    organizationId: id,
    certificateId: history.certificateId,
  });

  return (
    <TableRow key={history.id}>
      <TableData>{supplierCertificate?.name || t('common.not_applicable')}</TableData>
      <TableData>{history.certificateId || t('common.not_applicable')}</TableData>
      <TableData>{supplierCertificate?.certificationDate || t('common.not_applicable')}</TableData>
      <TableData>{history.declarationDate || t('common.not_applicable')}</TableData>
      <TableData>
        <IconButton
          className="supplier-certificates__row--download-icon"
          icon={<Icon name="downloadFile" size="small" />}
          color="default"
          isIconOnly={true}
          onClick={() => supplierCertificate?.certificateUrl}
        />
      </TableData>
    </TableRow>
  );
};

SupplierCertificateTableRow.defaultProps = {
  history: {},
};

SupplierCertificateTableRow.propTypes = {
  history: PropTypes.shape({
    id: PropTypes.number,
    certificateId: PropTypes.number,
    declarationDate: PropTypes.node,
  }),
};

const HistoryItemsTable = ({ handleShowHistoryItems, validFrom, validUntil }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const { id } = useParams();

  let currentPage = parseInt(searchParams.get('page'), 10);

  const { data: expiredDeclarationsResponse } = useGetSupplierCertificateDeclarationsQuery(
    {
      organizationId: id,
      page: currentPage,
      valid_from: validFrom,
      valid_until: validUntil,
      status: SUPPLIER_CERTIFICATE_TYPE.expired,
    },
    { skip: !validFrom || !validUntil },
  );

  const expiredDeclarationsData = expiredDeclarationsResponse
    ? expiredDeclarationsResponse.declarations
    : [];

  const totalPages = expiredDeclarationsResponse
    ? parseInt(expiredDeclarationsResponse.pagination?.totalPages, 10)
    : 0;

  return (
    <div>
      <div className="supplier-certificates__history-item">
        <>
          {' '}
          {t('supplier_certificates.attributes.declaration_period')}: {monthYearShort(validFrom)} -{' '}
          {monthYearShort(validUntil)}
        </>
        <Button
          className="supplier-certificates__history-item--button"
          label={t('shared.go_back')}
          onClick={handleShowHistoryItems}
          size="small"
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>{t('supplier_certificates.attributes.supplier_name')}</TableHeader>
            <TableHeader>{t('supplier_certificates.attributes.certificate_id')}</TableHeader>
            <TableHeader>{t('supplier_certificates.attributes.certification_date')}</TableHeader>
            <TableHeader>{t('supplier_certificates.attributes.declaration_date')}</TableHeader>
            <TableHeader>{t('supplier_certificates.attributes.certificate')}</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {expiredDeclarationsData?.map(history => (
            <SupplierCertificateTableRow history={history} />
          ))}
        </TableBody>
      </Table>
      <Pagination
        onPaginationClick={newPage => {
          setSearchParams({ tab: searchParams.get('tab'), page: newPage });
        }}
        totalPages={totalPages}
        currentPage={currentPage}
        nextText={t('pagination.next')}
        previousText={t('pagination.prev')}
        firstText={t('pagination.first')}
        lastText={t('pagination.last')}
      />
    </div>
  );
};

HistoryItemsTable.defaultProps = {
  handleShowHistoryItems: () => {},
  validFrom: null,
  validUntil: null,
};

HistoryItemsTable.propTypes = {
  handleShowHistoryItems: PropTypes.func,
  validFrom: PropTypes.node,
  validUntil: PropTypes.node,
};

export default HistoryItemsTable;
