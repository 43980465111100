import { GASTRONOMY, PRODUCER } from 'utils/organisation-industries';

const legalFormCondtion = (
  { assignments, partner, operationManager, mainContact, companyType, type },
  department,
) => {
  let legalTabMessage;

  switch (department) {
    case PRODUCER:
      legalTabMessage = {
        errorStepCondition:
          assignments || partner || operationManager || mainContact || companyType,
        moveNextCondition:
          !assignments && !partner && !operationManager && !mainContact && !companyType,
        disableNext: true,
      };
      break;
    case GASTRONOMY:
      legalTabMessage = {
        errorStepCondition: type,
        moveNextCondition: !type,
        disableNext: true,
      };
      break;
    default:
      legalTabMessage = { errorStepCondition: false, moveNextCondition: true, disableNext: false };
  }

  return legalTabMessage;
};

export default legalFormCondtion;
