import React from 'react';
import UsersList from 'components/users/list/index.component';

const Users = () => {
  return (
    <div className="user__wrapper">
      <UsersList />
    </div>
  );
};

export default Users;
