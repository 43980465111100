import { Text, View } from '@react-pdf/renderer';
import { Break } from 'components/pdf/shared/shared-components';
import styles from 'components/pdf/styles/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { GERMAN } from 'utils/language-options';
import { addressHeader, roadNumber } from 'utils/producer-contract-data';
import { useLanguageContext } from 'contexts/languageContext';
import ADDRESS_TYPE from 'utils/address-type';
import organizationAddressForPdf from 'components/pdf/anerkennung/helpers/organization-address';
import producerContractStyles from '../producer-contract.styles';

const FrontPage = ({ organisation, language }) => {
  const { t } = useLanguageContext();
  return (
    <View>
      <Text style={producerContractStyles.pdfTitle}>{t('producer_contract.title')}</Text>
      <Text style={producerContractStyles.between}>{t('producer_contract.subtitle')}</Text>
      <Break count={10} />
      <View style={styles.grid}>
        <View style={{ width: '100%' }}>
          <Text>
            {addressHeader} <Break />
            {roadNumber} <Break />
            {t('producer_contract.header.city')}
          </Text>
        </View>
        <View style={{ width: '100%', textAlign: 'center' }}>
          <Text> {t('producer_contract.header.middle_address')}</Text>
        </View>
        <View style={{ width: '100%' }}>
          <Text style={styles.text}>
            {t('producer_contract.header.receiver_address_nr', {
              nrNumber: organisation?.numberAtCertificationBody || t('common.not_applicable'),
            })}
          </Text>
          <View>
            <Text>
              {t('producer_contract.header.bio_id', {
                bioId: organisation.bioId,
              })}
              <Break count={2} />
            </Text>
          </View>
          <Text>{organisation?.name}</Text>
          {organizationAddressForPdf(organisation, ADDRESS_TYPE.mailing, null, false)}
        </View>
        <Break count={3} />
      </View>
      <View style={styles.addressFooter}>
        <Text style={{ maxWidth: 200 }}>{t('producer_contract.header.sender_address_footer')}</Text>
        <Text>{t('producer_contract.header.receiver_address_footer')}</Text>
      </View>

      <Text style={producerContractStyles.pdfSubtitle}>
        {t('producer_contract.header.main_title.first_line')}
        <Break />
        <Text>
          {t('producer_contract.header.main_title.second_line')}
          {language === GERMAN && <Text style={{ verticalAlign: 'super' }}>„</Text>}
        </Text>
      </Text>
    </View>
  );
};

FrontPage.propTypes = {
  organisation: PropTypes.shape().isRequired,
  language: PropTypes.string.isRequired,
};

export default FrontPage;
