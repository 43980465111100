import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../base-components/Spinner';
import './default-spinner.styles.scss';

const DefaultSpinner = ({ className }) => {
  return (
    <div className={classNames('default-spinner', className)}>
      <Spinner bgColor="none" color="success" size="small" />
    </div>
  );
};

DefaultSpinner.defaultProps = {
  className: null,
};

DefaultSpinner.propTypes = {
  className: PropTypes.string,
};

export default DefaultSpinner;
