import { useGetOrganizationQuery } from 'api/organizations';
import Spinner from 'components/base-components/Spinner/Spinner';
import React from 'react';
import { useParams } from 'react-router';
import ORGANISATION_TYPE from 'utils/organisation-type';
import { PRODUCER } from 'utils/organisation-industries';
import OrganisationDetails from './bio-cuisine/organisation-details/index.component';
import SubOrganisationList from './bio-cuisine/sub-organisation-list/index.component';
import ProducerTab from './producer/index.component';

const OrganisationDetailsWrapper = () => {
  const { id } = useParams();

  const { data: organisation = {}, isFetching, isSuccess } = useGetOrganizationQuery(id);

  const isParentOrganisation =
    isSuccess && organisation?.type === ORGANISATION_TYPE.parent_organisation;

  const isProducer = organisation?.industry === PRODUCER;

  if (isFetching) {
    return (
      <div className="user__invite--modal-loading">
        <Spinner size="small" color="success" bgColor="none" />
      </div>
    );
  } else if (isParentOrganisation) {
    return <SubOrganisationList />;
  } else if (isProducer) {
    return <ProducerTab />;
  } else {
    return <OrganisationDetails />;
  }
};

export default OrganisationDetailsWrapper;
