import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useCreateChartaMutation } from 'api/chartas';
import Button from '../../base-components/Button';
import Card from '../../base-components/Card';
import '../organisations.styles.scss';

const CreateCharta = ({ organizationId, isAllowedToCreate }) => {
  const [createCharta, { isSuccess, isLoading }] = useCreateChartaMutation();

  const { t } = useTranslation();

  const onCreate = () => {
    createCharta({ organizationId });
  };

  return (
    <Card className="organisation-charta__create">
      <div>{t('charta.create.placeholder')}</div>
      <div className="organisation-charta__create-action">
        <Button
          label={t('charta.create.action')}
          size="small"
          onClick={onCreate}
          type="success"
          disabled={!isAllowedToCreate || isSuccess || isLoading}
        />
      </div>
    </Card>
  );
};

CreateCharta.propTypes = {
  organizationId: PropTypes.number.isRequired,
  isAllowedToCreate: PropTypes.bool.isRequired,
};

export default CreateCharta;
