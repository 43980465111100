import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useGetContractQuery } from 'api/contract';
import TASKS_STATUS from 'utils/task-status';
import ASSIGNED_TO_TASK from 'utils/task-assigned.js';
import isOrgMember from 'utils/is-org-member.js';
import {
  REVIEW_ACCOUNTING_PROCESS_ADDITIONAL_TASK,
  REVIEW_LOGO_ATTACHMENT_ADDITIONAL_TASK,
} from 'utils/task-types.js';
import PropTypes from 'prop-types';
import Icon from 'components/base-components/Icon';
import { useGetTasksQuery } from 'api/tasks';
import { PERMANENT } from 'utils/control-body-survey.js';
import Avatar from 'components/base-components/Avatar/Avatar';
import Popover from 'components/base-components/Popover';
import { useGetLastControlBodySurveyQuery } from 'api/control-body-survey';
import IconButton from 'components/base-components/IconButton';
import { useGetCurrentUserQuery } from 'api/users';
import isControlBodyMember from 'utils/is-control-body-member';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import ORGANISATION_TYPE from 'utils/organisation-type';
import { VERIFIED } from 'utils/signed-contract-statuses.js';
import { PRODUCER } from 'utils/organisation-industries.js';
import classNames from 'classnames';
import OrganizationAlertComponent from './organization-alert.component.jsx';
import PopoverContent from './view/overview/popover-content.component.jsx';

const OrganisationHeadComponent = ({ organisation, isInviteUserHeader, showContract }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const { id } = useParams();
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const alertList = [];
  const surveyTasks = [
    REVIEW_ACCOUNTING_PROCESS_ADDITIONAL_TASK,
    REVIEW_LOGO_ATTACHMENT_ADDITIONAL_TASK,
  ];
  const taskParams = {
    assigned_to: ASSIGNED_TO_TASK.me,
    status: TASKS_STATUS.pending,
    referenceable_id: id,
    'types[]': surveyTasks,
  };

  const { data: currentUser } = useGetCurrentUserQuery();
  const isAdmin = isBioSuisseAdmin(currentUser);
  const isControlBodyUser = isControlBodyMember(currentUser);
  const isOrgUser = isOrgMember(currentUser, organisation.id);

  const { data: lastControlBodySurveyData = {} } = useGetLastControlBodySurveyQuery(
    { organizationId: id },
    { skip: isControlBodyUser || isAdmin },
  );

  const { data: contractData, isFetching: isContractFetching } = useGetContractQuery(id, {
    skip: !showContract || isControlBodyUser,
  });

  const { data: taskData } = useGetTasksQuery(taskParams, { skip: !isAdmin });

  const taskDataCollection = taskData?.collection || [];

  if (isAdmin || isOrgUser) {
    const daysLeftToConfirmReceipt =
      lastControlBodySurveyData.status === PERMANENT &&
      parseInt((lastControlBodySurveyData.hourLeftToConfirmReceipt || 0) / 24, 10);

    (Object.is(daysLeftToConfirmReceipt, 0) || daysLeftToConfirmReceipt > 0) &&
      !lastControlBodySurveyData.receiptConfirmed &&
      alertList.push({
        title: t('control_body.control_complete'),
        body: t('control_body.confirm_receipt'),
        button: false,
        footer: t('control_body.survey.days_left', { count: daysLeftToConfirmReceipt }),
      });

    isOrgUser &&
      contractData?.status === VERIFIED &&
      alertList.push({
        title: t('contract.verified'),
        body: t('contract.verify_suggestion'),
        button: true,
        footer: null,
      });

    taskDataCollection?.length > 0 &&
      alertList.push({
        title: t('task.follow_up'),
        body: t('task.address_task'),
        button: false,
        footer: null,
      });

    !isContractFetching &&
      contractData?.status !== VERIFIED &&
      showContract &&
      alertList.push({
        title: t('contract.not_declared'),
        body: isAdmin ? t('contract.sign_request_admin') : t('contract.sign_request'),
        button: true,
        footer: null,
      });
  }

  const onIconClick = rightArrow => {
    rightArrow ? setIndex(prevIndex => prevIndex + 1) : setIndex(prevIndex => prevIndex - 1);
  };

  const mainAddress = organisation?.addresses?.find(address => address.main === true);

  const isProducer = organisation?.industry === PRODUCER;

  return (
    <div className="organisation-details__head">
      <div className="organisation-details__head--title">
        <Avatar
          className="organisation-details__head--image"
          src={organisation?.logoUrl}
          firstName={organisation?.name?.split(' ')[0]}
          lastName={organisation?.name?.split(' ')[1]}
          size="huge"
        />

        <div>
          <div className="organisation-details__head--title__main">{organisation.name}</div>
          {organisation.additionalName && (
            <div className="margin-left-10 font-14 margin-top-5">{organisation.additionalName}</div>
          )}
          <div className="organisation-details__head--title__main-address">{`${mainAddress?.line1}, ${mainAddress?.city}`}</div>
          <div className="organisation-details__head--title__id-wrapper">
            <div className="organisation-details__head--title__id-bio">
              {organisation.bioId || t('common.not_applicable')}
            </div>
            <div className="organisation-details__head--title__id-vertical-line" />
            <div className="organisation-details__head--title__id-uid">
              {organisation.uid || t('common.not_applicable')}
            </div>
          </div>
        </div>
        <div className="organisation-details__head--title__vertical-line" />
        {!isInviteUserHeader && (
          <Popover
            className="organisation__head-popover--wrapper"
            isVisible={isPopoverVisible}
            onOutsideClick={() => setIsPopoverVisible(false)}
            content={
              <PopoverContent
                isProducer={isProducer}
                id={id}
                organisation={organisation}
                isControlBodyUser={isControlBodyUser}
                isAdmin={isAdmin}
              />
            }
            size="medium"
            direction="bottom-left"
          >
            <IconButton
              icon={<Icon name="viewdetails" className="organisation__head-popover--icon" />}
              color="default"
              onClick={() => setIsPopoverVisible(true)}
              size="huge"
            />
          </Popover>
        )}
      </div>
      {alertList.length > 0 && (
        <div className="organisation-details__contract--warning">
          <div
            className={classNames('organisation-details__contract', {
              'organisation-details__contract-disabled':
                organisation?.type === ORGANISATION_TYPE.sub_organisation,
            })}
          >
            <OrganizationAlertComponent
              organisation={organisation}
              alert={alertList[index]}
              onIconClick={onIconClick}
              alertList={alertList}
              index={index}
            />
          </div>
        </div>
      )}
    </div>
  );
};

OrganisationHeadComponent.defaultProps = {
  organisation: null,
  isInviteUserHeader: false,
  showContract: false,
};

OrganisationHeadComponent.propTypes = {
  organisation: PropTypes.shape(),
  isInviteUserHeader: PropTypes.bool,
  showContract: PropTypes.bool,
};

export default OrganisationHeadComponent;
