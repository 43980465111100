import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TableHead = ({ className, children }) => (
  <thead className={classNames('table__head', className)}>
    {React.cloneElement(children, { isHeaderRow: true })}
  </thead>
);

TableHead.defaultProps = {
  className: null,
};

TableHead.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default TableHead;
