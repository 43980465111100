import { useGetLogoAttachmentsQuery } from 'api/control-body-survey';
import {
  useApproveLogoAttachmentsAdditionalTaskMutation,
  useDeclareLogoAttachmentsAdditionalTaskMutation,
  useGetActiveLogoAttachmentsAdditionalTaskQuery,
} from 'api/control-body-survey/logo-attachments';
import { useGetCurrentUserQuery } from 'api/users';
import Card from 'components/base-components/Card';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader/ContentLoader';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import ChooseFile from 'components/shared-components/choose-file/ChooseFile';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import bytesToMegabytes from 'utils/bytes-to-megabytes';
import { NO } from 'utils/control-body-survey';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import { ADMIN, USER } from 'utils/users-role';
import { ACTIVE, DRAFT, SUBMITTED } from 'utils/verification-status';
import NegativeReportsHeader from '../header.component';
import AdditionalTaskSubmitButton from '../helpers/additional-task-submit-button.component';
import NegativeTaskModal from '../helpers/negative-task-modal.component';

const LogoAttachments = ({ surveyId }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const { t } = useTranslation();
  const { data: currentUser = {} } = useGetCurrentUserQuery();
  const { data: activeLogos = [] } = useGetLogoAttachmentsQuery({ surveyId, type: ACTIVE });
  const { data: activeAdditionalTask, isFetching } = useGetActiveLogoAttachmentsAdditionalTaskQuery(
    { surveyId },
  );
  const [declare, submitStatus] = useDeclareLogoAttachmentsAdditionalTaskMutation();
  const [approve, approveStatus] = useApproveLogoAttachmentsAdditionalTaskMutation();

  if (isFetching)
    return (
      <div className="negative-reports__container">
        <ContentLoaderPlaceholder numberOfLines={6} isRounded={true} />
      </div>
    );
  if (!activeAdditionalTask) return null;

  const requiredFileCount = activeLogos.filter(logo => logo.attached === NO).length;
  const uploadedFilesCount =
    activeAdditionalTask.status !== DRAFT
      ? activeAdditionalTask.logos.length
      : uploadedFiles.length;

  const onSave = values => {
    if (activeAdditionalTask.status === SUBMITTED) {
      return approve({ surveyId })
        .unwrap()
        .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
    } else {
      const payload = new FormData();
      uploadedFiles.forEach(item => {
        payload.append('logos[]', item.file);
      });

      return declare({ surveyId, payload })
        .unwrap()
        .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
    }
  };

  return (
    <div className="negative-reports__container">
      <NegativeTaskModal submitStatus={submitStatus} approveStatus={approveStatus} />
      <Card>
        <NegativeReportsHeader
          status={activeAdditionalTask.status}
          text={t('negative_report.logo_text')}
          date={activeAdditionalTask.createdAt}
          dueDate={activeAdditionalTask.dueDate}
        />

        <Form
          onSubmit={onSave}
          render={({ handleSubmit, submitting, submitErrors }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid">
                <div className="negative-reports__left-panel">
                  <div className="negative-reports__item-header">{t('control_body.survey.form.first_step')}</div>
                  <ul className="negative-reports__item-list">
                    {activeLogos.map(logo => (
                      <li>
                        <div className="negative-reports__item-list__wrapper">
                          <span>{t(`control_body.survey.form.location.${logo.attachedTo}`)}</span>
                          <span>{t(`control_body.survey.shared.${logo.attached}`)}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="negative-reports__right-panel">
                  <div className="logo-attachment-task">
                    <div className="logo-attachment-task__header">
                      <div className="logo-attachment-task__header--title">
                        {t('negative_report.logo.uploaded_files')}
                      </div>
                      <div className="logo-attachment-task__header--files">
                        {uploadedFilesCount}/{requiredFileCount}{' '}
                        {t('negative_report.logo.uploaded')}
                        <ChooseFile
                          className="logo-attachment-task__header--upload-btn"
                          showText={false}
                          disabled={
                            uploadedFiles.length === requiredFileCount ||
                            activeAdditionalTask.status !== DRAFT ||
                            currentUser.role !== USER
                          }
                          onChange={file =>
                            setUploadedFiles(prev => [...prev, { file, id: new Date().getTime() }])
                          }
                          labelText={
                            <>
                              <Icon
                                className="choose-file__contract-delete--icon"
                                name="uploadFile"
                                size="normal"
                              />
                              <span className="choose-file__contract-delete--text">
                                {t('negative_report.logo.upload_logo')}
                              </span>
                            </>
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {activeAdditionalTask.status === DRAFT && currentUser.role === USER ? (
                    <>
                      <div>
                        {uploadedFiles.map(uploadedFile => (
                          <div className="col-6 col-bleed logo-attachment-task__file-dynamic-margin">
                            <div className="logo-attachment-task__file-wrapper">
                              <div className="logo-attachment-task__file-info">
                                <Icon name="photo" color="tertiary" />
                                <span className="logo-attachment-task__file-info--name">
                                  {uploadedFile?.file.name}
                                </span>
                              </div>
                              <div>{bytesToMegabytes(uploadedFile?.file.size)}MB</div>
                              <IconButton
                                className="topnav__back-button"
                                color="danger"
                                icon={
                                  <Icon
                                    className="choose-file__contract-delete--icon"
                                    name="delete"
                                    size="small"
                                    color="danger"
                                  />
                                }
                                onClick={() => {
                                  const filteredImage = uploadedFiles.filter(
                                    file => file.id !== uploadedFile.id,
                                  );
                                  setUploadedFiles(filteredImage);
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>

                      {!!submitErrors?.logos && (
                        <div className="logo-attachment-task__upload-errors">
                          {submitErrors?.logos}
                        </div>
                      )}
                    </>
                  ) : (
                    <div>
                      {activeAdditionalTask.logos.map(logo => (
                        <div className="col-6 col-bleed logo-attachment-task__file-dynamic-margin">
                          <div className="logo-attachment-task__file-wrapper">
                            <div className="logo-attachment-task__file-info">
                              <Icon name="photo" color="tertiary" />
                              <span className="logo-attachment-task__file-info--name">
                                {logo?.filename}
                              </span>
                            </div>

                            <div className="organisation-checklist__audio-card--icon">
                              <span>{bytesToMegabytes(logo?.sizeInByte)} MB</span>
                              <a href={logo?.url} target="_blank" rel="noreferrer">
                                <IconButton
                                  className="topnav__back-button"
                                  icon={
                                    <Icon
                                      className="choose-file__contract-delete--icon"
                                      name="downloadFile"
                                      size="small"
                                    />
                                  }
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="col-12">
                  <AdditionalTaskSubmitButton
                    status={activeAdditionalTask.status}
                    disabled={submitting || requiredFileCount !== uploadedFilesCount}
                    approverType={ADMIN}
                  />
                </div>
              </div>
            </form>
          )}
        />
      </Card>
    </div>
  );
};

LogoAttachments.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

export default LogoAttachments;
