import React from 'react';
import PropTypes from 'prop-types';
import '../checklist-form.styles.scss';
import { Field, useFormState } from 'react-final-form';
import Icon from 'components/base-components/Icon';
import { boolToYesNo } from 'utils/yes-no-helper';
import YesNoInput from 'components/base-components/YesNoInput';
import { t } from 'i18next';
import Textarea from 'components/base-components/Textarea/Textarea';
import { YES, RESTRICTED_INGREDIENTS, isTemporaryOrPermanent } from 'utils/control-body-survey';
import { useGetRestrictedIngredientAdditionalPhotosQuery } from 'api/control-body-survey/restricted-ingredients';
import UploadAdditionalPhotos from './upload-additional-photos.component';

const RestrictedIngredientsCheckbox = ({
  title,
  items,
  activeItems,
  error,
  comment,
  isCertifier,
  isAuditor,
  surveyId,
  status,
  activeRestrictedIngredientsAdditionalTask,
  uploadedRestrictedIngredientAttachments,
  setUploadedRestrictedIngredientAttachments,
}) => {
  const activeRestrictedIngredients = {};
  const formState = useFormState();

  let requiredPhotoUploadCount = 0;
  items?.forEach(item => {
    if (formState.values['restricted_' + item.value] === YES) {
      requiredPhotoUploadCount++;
    }
  });

  if (!items || !activeItems) return null;

  const errorMsg = error && error['activeRestrictedIngredients'];

  activeItems.forEach(({ type, exists }) => (activeRestrictedIngredients[type] = exists));

  return (
    <div className="organisation-checklist__checkbox-wrapper">
      <div className="organisation-checklist__checkbox-title organisation-checklist__checkbox-title--reserved">
        <div> {title} </div>
        {items.length === 0 && (
          <div>
            <span className="organisation-checklist__deviation-legend--status-disabled">
              {t('common.no_data_available')}
            </span>
          </div>
        )}
      </div>
      {items.length > 0 &&
        items.map(item => (
          <div key={item.label}>
            <ul className="organisation-checklist__unordered-list">
              <li>
                <Field
                  name={`restricted_${item.value}`}
                  initialValue={boolToYesNo(activeRestrictedIngredients[item.value])}
                >
                  {({ input, meta }) => (
                    <YesNoInput
                      {...input}
                      error={meta.submitError}
                      label={item.label}
                      disabled={isCertifier || isTemporaryOrPermanent(status)}
                    />
                  )}
                </Field>
              </li>
            </ul>
            <hr className="organisation-checklist__checkbox-horizontal-line" />
          </div>
        ))}

      {requiredPhotoUploadCount !== 0 && (
        <UploadAdditionalPhotos
          isAuditor={isAuditor}
          surveyId={surveyId}
          uploadedLogoAttachments={uploadedRestrictedIngredientAttachments}
          setUploadedLogoAttachments={setUploadedRestrictedIngredientAttachments}
          requiredLogoCount={requiredPhotoUploadCount}
          errors={error?.restrictedIngredientAdditionalPhotos}
          fetchPhotosDataQuery={useGetRestrictedIngredientAdditionalPhotosQuery}
          itemType={RESTRICTED_INGREDIENTS}
        />
      )}

      <Field name="restrictedIngredientsComment" initialValue={comment || ''}>
        {({ input }) => (
          <Textarea
            className="organisation-checklist__quarter-comment"
            label={
              isCertifier
                ? t('control_body.survey.form.auditor_comments')
                : t('control_body.survey.form.comments')
            }
            placeholder={t('control_body.survey.form.comments_placeholder')}
            disabled={isCertifier || isTemporaryOrPermanent(status)}
            {...input}
          />
        )}
      </Field>
      {isCertifier &&
        activeRestrictedIngredientsAdditionalTask &&
        Object.keys(activeRestrictedIngredientsAdditionalTask).length > 0 && (
          <div className="organisation-checklist__auditor-acknowledgement">
            <Field
              name="auditorAcknowledgement"
              initialValue={boolToYesNo(activeRestrictedIngredientsAdditionalTask?.fulfilled)}
            >
              {({ input, meta }) => (
                <YesNoInput
                  {...input}
                  error={meta.submitError}
                  label={t('control_body.survey.form.third_step_auditor_text')}
                  disabled={isTemporaryOrPermanent(status)}
                />
              )}
            </Field>
          </div>
        )}
      {errorMsg && (
        <div className="organisation-checklist__errors">
          <Icon name="invalid" color="danger" size="small" />
          <span className="organisation-checklist__errors-text">{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

RestrictedIngredientsCheckbox.defaultProps = {
  title: null,
  comment: null,
  status: null,
  items: null,
  activeItems: null,
  error: null,
  isCertifier: false,
  activeRestrictedIngredientsAdditionalTask: {},
};

RestrictedIngredientsCheckbox.propTypes = {
  title: PropTypes.node,
  comment: PropTypes.node,
  status: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  activeItems: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.shape(),
  activeRestrictedIngredientsAdditionalTask: PropTypes.shape(),
  isCertifier: PropTypes.bool,
  uploadedRestrictedIngredientAttachments: PropTypes.array.isRequired,
  setUploadedRestrictedIngredientAttachments: PropTypes.func.isRequired,
  isAuditor: PropTypes.bool.isRequired,
  surveyId: PropTypes.number.isRequired,
};

export default RestrictedIngredientsCheckbox;
