import { BlobProvider } from '@react-pdf/renderer';
import { useGetOrganizationQuery } from 'api/organizations';
import BwipJs from 'bwip-js';
import Link from 'components/base-components/Link';
import Spinner from 'components/base-components/Spinner';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import TVDStickerPDFDocument from './tvd-sticker.document';

const PDF_FILENAME = 'tvd-sticker.pdf';

const TVDGeneration = ({ organizationId, tvdNumber, labels, address, barcodeTvdNumber }) => {
  const [barcodeDataUrl, setBarcodeDataUrl] = useState(null);
  const { data: organization } = useGetOrganizationQuery(organizationId);

  useEffect(() => {
    let canvas = document.createElement('canvas');
    try {
      BwipJs.toCanvas(canvas, {
        bcid: 'code128',
        text: barcodeTvdNumber,
        scale: 10,
        height: 8,
        includetext: false,
      });
      setBarcodeDataUrl(canvas.toDataURL('image/png'));
    } catch (error) {
      throw error;
    }
  }, [barcodeTvdNumber]);

  return (
    organization &&
    barcodeDataUrl && (
      <>
        <BlobProvider
          document={
            <TVDStickerPDFDocument
              organization={organization}
              barcodeImageUrl={barcodeDataUrl}
              tvdNumber={tvdNumber}
              labels={labels}
              address={address}
            />
          }
        >
          {({ blob, url, loading, error }) => {
            return (
              <Link
                download={PDF_FILENAME}
                type="button"
                size="small"
                href={url}
                modifier="success"
              >
                {loading ? (
                  <Spinner bgColor="none" color="success" size="tiny" />
                ) : (
                  t(`tvd.order.actions.download`)
                )}
              </Link>
            );
          }}
        </BlobProvider>
      </>
    )
  );
};

TVDGeneration.defaultProps = {
  labels: [],
};

TVDGeneration.propTypes = {
  organizationId: PropTypes.number.isRequired,
  tvdNumber: PropTypes.string.isRequired,
  barcodeTvdNumber: PropTypes.string.isRequired,
  labels: PropTypes.array.isRequired,
  address: PropTypes.shape().isRequired,
};

export default TVDGeneration;
