import routes from 'utils/routes';
import { CONTRACT, ORGANISATION } from 'utils/tag-types';
import { apiSlice } from '../index';

const ContractApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getContract: build.query({
      query: organisationId => routes.contract.entity({ organisationId }),
      providesTags: [CONTRACT],
    }),
    getContracts: build.query({
      query: params => routes.contract.collection(params),
      invalidatesTags: [CONTRACT],
    }),
    uploadContract: build.mutation({
      query: ({ organisationId, payload }) => ({
        url: routes.contract.upload({ organisationId }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [CONTRACT],
    }),
    deleteAttachment: build.mutation({
      query: ({ contractId }) => ({
        url: routes.contract.delete({ contractId }),
        method: 'DELETE',
      }),
      invalidatesTags: [CONTRACT],
    }),
    updateContract: build.mutation({
      query: ({ contractId, payload }) => ({
        url: routes.contract.update({ contractId }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [CONTRACT],
    }),
    cancelContract: build.mutation({
      query: ({ contractId }) => ({
        url: routes.contract.cancel({ contractId }),
        method: 'PUT',
      }),
      invalidatesTags: [CONTRACT],
    }),
    archiveContract: build.mutation({
      query: ({ contractId }) => ({
        url: routes.contract.archive({ contractId }),
        method: 'PUT',
      }),
      invalidatesTags: [CONTRACT],
    }),
    terminateContract: build.mutation({
      query: ({ queryParams, payload }) => ({
        url: routes.contract.terminate(queryParams),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [CONTRACT],
    }),
    getTerminationReasonOptions: build.query({
      query: () => routes.contract.terminationReasons(),
    }),
    reinstateContract: build.mutation({
      query: ({ organisationId }) => ({
        url: routes.contract.reinstate({ organisationId }),
        method: 'PUT',
      }),
      invalidatesTags: [CONTRACT, ORGANISATION],
    }),
    sendContract: build.mutation({
      query: ({ organisationId }) => ({
        url: routes.contract.send({ organisationId }),
        method: 'PUT',
      }),
      invalidatesTags: [CONTRACT],
    }),

    approveContract: build.mutation({
      query: ({ organisationId }) => ({
        url: routes.contract.approve({ organisationId }),
        method: 'PUT',
      }),
      invalidatesTags: [CONTRACT, ORGANISATION],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetContractQuery,
  useGetContractsQuery,
  useUploadContractMutation,
  useUpdateContractMutation,
  useCancelContractMutation,
  useArchiveContractMutation,
  useTerminateContractMutation,
  useGetTerminationReasonOptionsQuery,
  useSendContractMutation,
  useApproveContractMutation,
  useDeleteAttachmentMutation,
  useReinstateContractMutation,
} = ContractApi;
