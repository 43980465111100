const ORGANISATION_TYPE = {
  single_organisation: 'standalone',
  parent_organisation: 'parent',
  sub_organisation: 'sub',
  controlBody: 'control_body'
};

export const CONTROL_BODY = 'control_body';

export default ORGANISATION_TYPE;
