import routes from 'utils/routes';
import { apiSlice } from '../index';

const producersApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getCompany: build.query({
      query: params => routes.companies.entity(params),
    }),
    getAddress: build.query({
      query: params => routes.companies.addresses.eitity(params),
    }),
    getCompanyUnits: build.query({
      query: params => routes.companies.companyUnits(params),
    }),
  }),
  overrideExisting: false,
});

export const { useGetCompanyQuery, useGetAddressQuery, useGetCompanyUnitsQuery } = producersApi;
