import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import BioLogo from 'assets/biosuisse-logo.png';
import { Break } from 'components/pdf/shared/shared-components';
import tvdStyles from 'components/pdf/styles/tvd-styles';
import moment from 'moment';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { formatAddressForPDF } from 'components/pdf/anerkennung/helpers/organization-address';
import { STATUS_VK } from 'utils/organization-certificate-status';
import { Trans } from 'react-i18next';
import Sticker from './sticker.view';

const StickerPage = ({ organization, barcodeImageUrl, tvdNumber, address, labels }) => {
  const year = moment().format('YY');
  const numberOfSticker = 16;

  const getOrganizationInfo = () => {
    return (
      <Text>
        {organization.name}
        <Break />
        {formatAddressForPDF(address)}
      </Text>
    );
  };

  return (
    <View wrap={true}>
      <View style={tvdStyles.stickerContainer}>
        {Array.from({ length: numberOfSticker }, (_, i) => i + 1).map(item => (
          <Sticker
            key={item}
            logo={BioLogo}
            logoTitle={
              organization?.certificationStatus === STATUS_VK ? (
                <Trans
                  i18nKey="pdf.tvd.bio_suisse"
                  components={{ bold: <Text style={{ fontWeight: 800 }} /> }}
                />
              ) : (
                t('pdf.tvd.logo_title')
              )
            }
            logoText={t('pdf.tvd.logo_text')}
            tvdNumber={tvdNumber}
            info={getOrganizationInfo()}
            year={year}
            barcodeImageUrl={barcodeImageUrl}
            labels={labels}
            status={organization?.certificationStatus}
          />
        ))}
      </View>
    </View>
  );
};

StickerPage.propTypes = {
  address: PropTypes.shape().isRequired,
  barcodeImageUrl: PropTypes.string.isRequired,
  organization: PropTypes.shape().isRequired,
  tvdNumber: PropTypes.string.isRequired,
  labels: PropTypes.array.isRequired,
};

export default StickerPage;
