import { useGetBeeHiveProductionQuery } from 'api/producers/productions';
import { t } from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import SharedProductionComponent from './shared/shared-production.component';

const BeeKeepingComponent = ({ id }) => {
  const { data: beeHiveProductionData } = useGetBeeHiveProductionQuery(
    {
      productionId: id,
    },
    { skip: !id },
  );

  return (
    <SharedProductionComponent title={t('plant_cultivation.bee_hives.bee_keeping')} icon="bee">
      <div className="margin-top-10">
        {beeHiveProductionData?.honeyProducedKg && (
          <div className="flex-space-between">
            <div>{t('plant_cultivation.bee_hives.quantity_of_honey')}</div>
            <div>{beeHiveProductionData?.honeyProducedKg}</div>
          </div>
        )}
        {beeHiveProductionData?.numberOfColonies && (
          <div className="flex-space-between margin-top-10">
            <div>{t('plant_cultivation.bee_hives.bee_colonies')}</div>
            <div>{beeHiveProductionData?.numberOfColonies}</div>
          </div>
        )}
      </div>
    </SharedProductionComponent>
  );
};

BeeKeepingComponent.defaultProps = {
  id: null,
};

BeeKeepingComponent.propTypes = {
  id: PropTypes.node,
};

export default BeeKeepingComponent;
