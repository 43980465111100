import Card from 'components/base-components/Card';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import EnsureTransparencyComponent from 'components/shared-components/ensure-transparency';
import Input from 'components/base-components/Input';
import CheckBox from 'components/base-components/CheckBox/CheckBox';
import TitleComponent from '../plant-cultivate/shared/title.component';
import CheckboxDependentInput from './checkbox-dependent-input.component';
import EmptyProducerContent from './empty-producer-content.component';
import CheckboxWithIcon from './checkbox-with-icon.component';
import './product-cultivation.styles.scss';

const SharedProductCultivationComponent = ({
  data,
  values,
  getInitialValue,
  component,
  isCheckboxComponent,
  activeItem,
  title,
  icon,
  fieldName,
  label,
  name,
  onChange,
  isTypePresent,
  fields,
  error,
  validationError,
  isDetailsCardVisible,
}) => {
  const { submitErrors } = useFormState();

  const onRenderComponent = () => {
    if (isTypePresent) {
      return (
        <>
          {fields.map(({ name, placeholder, initialValue, label, isRequired }) => (
            <Field name={name} initialValue={initialValue}>
              {({ input }) => (
                <Input
                  {...input}
                  className="product-cultivation__input"
                  size="tiny"
                  label={label}
                  placeholder={placeholder}
                  touched={true}
                  required={isRequired}
                  error={submitErrors?.productionDataErrors?.[name]}
                />
              )}
            </Field>
          ))}
          {error && <div className="error-text margin-left-10 margin-bottom-10">{error}</div>}
        </>
      );
    } else if (isCheckboxComponent) {
      return (
        <>
          {data.map(option => (
            <div className="product-cultivation__gardening-item" key={option.value}>
              <Field
                name={`${fieldName}.${option.value}`}
                type="checkbox"
                initialValue={!!activeItem[option.value]}
              >
                {({ input }) => (
                  <CheckBox
                    isChecked={input.checked}
                    size="tiny"
                    label={option.label}
                    onChange={e => input.onChange(e.target.checked)}
                  />
                )}
              </Field>
            </div>
          ))}
          {error && <div className="error-text margin-left-bottom-10">{error}</div>}
        </>
      );
    }

    return (
      <>
        {data.map((name, index) => (
          <CheckboxDependentInput
            key={index}
            checkboxFieldName={name}
            inputFieldName={`${name}_in_hectares`}
            values={values}
            checkboxLabel={t(`plant_cultivation.${component}.checkbox.${name}`)}
            inputLabel={t('plant_cultivation.shared.surface_area_input_label')}
            initialValue={getInitialValue(name)}
            error={validationError?.[name]}
          />
        ))}
        {error && <div className="error-text margin-left-bottom-10">{error}</div>}
      </>
    );
  };

  return (
    <>
      {isTypePresent && (
        <Card className="product-cultivation__outside-card">
          <div className="product-cultivation__header">{t('egg_production.produce_types')}</div>
          <span className="product-cultivation__description">
            {t('egg_production.produce_types_body')}
          </span>
          <div className="product-cultivation__wrapper">
            <CheckboxWithIcon
              label={label}
              fieldName={fieldName}
              icon={icon}
              name={name}
              isChecked={true}
              onChange={onChange}
            />
          </div>
        </Card>
      )}
      <EnsureTransparencyComponent />
      {isDetailsCardVisible ? (
        <Card className="product-cultivation__outside-card">
          <div className="product-cultivation__header">{t('egg_production.produce_details')}</div>
          <Card className="product-cultivation__card">
            <TitleComponent title={title} icon={icon} />
            <Card className="product-cultivation__inside-card">{onRenderComponent()}</Card>
          </Card>
        </Card>
      ) : (
        <EmptyProducerContent />
      )}
    </>
  );
};

SharedProductCultivationComponent.defaultProps = {
  values: {},
  activeItem: {},
  component: null,
  title: null,
  icon: null,
  fieldName: null,
  isCheckboxComponent: false,
  isTypePresent: false,
  isDetailsCardVisible: true,
  getInitialValue: () => {},
  name: null,
  label: null,
  onChange: () => {},
  fields: [],
  error: null,
  validationError: null,
};

SharedProductCultivationComponent.propTypes = {
  values: PropTypes.shape(),
  activeItem: PropTypes.shape(),
  data: PropTypes.number.isRequired,
  component: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  fieldName: PropTypes.string,
  isCheckboxComponent: PropTypes.bool,
  isTypePresent: PropTypes.bool,
  isDetailsCardVisible: PropTypes.bool,
  getInitialValue: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  fields: PropTypes.arrayOf(PropTypes.shape()),
  error: PropTypes.node,
  validationError: PropTypes.shape(),
};

export default SharedProductCultivationComponent;
