import ASSIGNMENT_ROLES from 'utils/assignment-roles';

const SUGGESTED = 'suggested';
const SCHEDULED = 'scheduled';
const ACCEPTED = 'accepted';
const TEMPORARY = 'temporary';
const PERMANENT = 'permanent';
const POSITIVE = 'positive';
const NEGATIVE = 'negative';
const SAVED = 'initiated';
const SUBMITTED = 'submitted';
const DRAFT = 'draft';
const DOOR = 'door';
const NO = 'no';
const YES = 'yes';
const NOT_RELEVANT = 'not_relevant';
const LOGOS = 'logos';
const RESERVED_INGREDIENTS = 'reserved_ingredients';
const RESTRICTED_INGREDIENTS = 'restricted_ingredients';

const VAT_PERCENTAGE_REASON = {
  other: 'other',
};

const hasPermissionWithRoles = (assignments, organizationId, roles) => {
  const associatedAssignment = assignments.find(
    assignment => assignment.organizationId === parseInt(organizationId, 10),
  );

  return roles.includes(associatedAssignment?.role);
};

const isTemporaryOrPermanent = status => status === TEMPORARY || status === PERMANENT;

const hasPermissionToApproveAdditonalTask = (assignments, organizationId) => {
  const { owner, auditor } = ASSIGNMENT_ROLES;

  return hasPermissionWithRoles(assignments, organizationId, [owner, auditor]);
};

const hasPermissionToFinshSurvey = (assignments, organizationId) => {
  const { owner, certifier } = ASSIGNMENT_ROLES;

  return hasPermissionWithRoles(assignments, organizationId, [owner, certifier]);
};

export {
  ACCEPTED,
  SCHEDULED,
  SUGGESTED,
  TEMPORARY,
  POSITIVE,
  NEGATIVE,
  PERMANENT,
  SAVED,
  SUBMITTED,
  DRAFT,
  DOOR,
  NO,
  YES,
  NOT_RELEVANT,
  VAT_PERCENTAGE_REASON,
  LOGOS,
  RESERVED_INGREDIENTS,
  RESTRICTED_INGREDIENTS,
  hasPermissionToApproveAdditonalTask,
  hasPermissionToFinshSurvey,
  isTemporaryOrPermanent,
};
