import React, { useState } from 'react';
import { useAbortOrganizationMutation, useGetOrganizationQuery } from 'api/organizations';
import { useGetCurrentUserQuery } from 'api/users';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import {
  useSendContractMutation,
  useCancelContractMutation,
  useArchiveContractMutation,
} from 'api/contract';
import { dateMonthYear } from 'utils/date-time-formatter';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { useParams } from 'react-router-dom';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import WarningModal from 'components/shared-components/modal/warning';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import { Trans } from 'react-i18next';
import { SENT } from 'utils/signed-contract-statuses';

const ContractSendComponent = ({ title, contractData }) => {
  const { id } = useParams();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: organisationData } = useGetOrganizationQuery(id);
  const [sendContract, { isLoading, isSuccess, requestId }] = useSendContractMutation();
  const [
    abortOrganization,
    { isLoading: isAbortLoading, isSuccess: isAbortSuccess, requestId: abortRequestId },
  ] = useAbortOrganizationMutation();
  const [
    cancelContract,
    { isLoading: isCancelLoading, isSuccess: isCancelSuccess, requestId: cancelRequestId },
  ] = useCancelContractMutation();

  const [
    archiveContract,
    { isLoading: isArchiveLoading, isSuccess: isArchiveSuccess, requestId: archiveRequestId },
  ] = useArchiveContractMutation();

  const handleSendContract = () => {
    return sendContract({
      organisationId: id,
    });
  };

  const handleCancelContract = () => {
    if (contractData) {
      return cancelContract({
        contractId: contractData.id,
      })
        .unwrap()
        .then(() => setIsCancelModalOpen(false));
    } else {
      return abortOrganization(id)
        .unwrap()
        .then(() => setIsCancelModalOpen(false));
    }
  };

  const handleArchiveContract = () => {
    return archiveContract({
      contractId: contractData?.id,
    })
      .unwrap()
      .then(() => setIsArchiveModalOpen(false));
  };

  const isDeactivated = organisationData?.status === ORGANISATIONS_STATUS.inactive;

  const isAdmin = isBioSuisseAdmin(currentUser);

  return (
    <>
      <Card>
        <div className="organisation-contract__second-step">{title}</div>
        {isAdmin && (
          <>
            <div className="organisation-contract__eligibility">
              {t('contract.producer.sent_or_cancel_step.text')}
            </div>
            <div className="organisation-contract__eligibility">
              {t('contract.producer.sent_or_cancel_step.sent_date')}
              {contractData?.sentAt
                ? dateMonthYear(contractData?.sentAt)
                : t('common.not_applicable')}
            </div>
            {(!contractData?.status || contractData?.status === SENT) && (
              <>
                <Button
                  label={t('contract.producer.button.sent')}
                  type="success"
                  size="small"
                  onClick={handleSendContract}
                  disabled={isDeactivated || contractData?.status === SENT}
                />
                {!contractData?.contract && (
                  <>
                    <Button
                      label={t('contract.producer.button.cancel')}
                      className="organisation-contract__success"
                      size="small"
                      onClick={() => setIsCancelModalOpen(true)}
                      disabled={isDeactivated}
                    />
                    {contractData?.status === SENT && (
                      <Button
                        label={t('contract.producer.button.correct')}
                        className="organisation-contract__success"
                        size="small"
                        onClick={() => setIsArchiveModalOpen(true)}
                        disabled={isDeactivated}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Card>
      <WarningModal
        onOutsideClick={() => setIsCancelModalOpen(false)}
        isVisible={isCancelModalOpen}
        title={t('contract.producer.cancel_modal.warning_title')}
        content={
          <div>
            <Trans
              i18nKey="contract.producer.cancel_modal.warning_description"
              values={{ name: organisationData?.name }}
              components={{ bold: <b /> }}
            />
          </div>
        }
        confirmActionText={t('contract.producer.button.cancel')}
        cancelActionText={t('shared.go_back')}
        onCancel={() => setIsCancelModalOpen(false)}
        onConfirm={() => handleCancelContract()}
      />

      <WarningModal
        onOutsideClick={() => setIsArchiveModalOpen(false)}
        isVisible={isArchiveModalOpen}
        title={t('contract.producer.correct_modal.warning_title')}
        content={
          <div>
            <Trans
              i18nKey="contract.producer.correct_modal.warning_description"
              values={{ name: organisationData?.name }}
              components={{ bold: <b /> }}
            />
          </div>
        }
        confirmActionText={t('shared.action.yes')}
        cancelActionText={t('shared.action.cancel')}
        onCancel={() => setIsArchiveModalOpen(false)}
        onConfirm={() => handleArchiveContract()}
      />
      <SubmitModal
        isLoading={isCancelLoading || isAbortLoading}
        isSuccess={isCancelSuccess || isAbortSuccess}
        requestId={cancelRequestId || abortRequestId}
        successTitle={t('contract.producer.cancel_modal.success_title')}
        successContent={
          <div>
            <Trans
              i18nKey="contract.producer.cancel_modal.success_description"
              values={{ name: organisationData?.name }}
              components={{ bold: <b /> }}
            />
          </div>
        }
        showError={false}
        showLoader={false}
      />
      <SubmitModal
        isLoading={isLoading}
        isSuccess={isSuccess}
        requestId={requestId}
        successTitle={t('contract.producer.sent_modal.success_title')}
        successContent={t('contract.producer.sent_modal.success_description')}
        showError={false}
        showLoader={false}
      />
      <SubmitModal
        isLoading={isArchiveLoading}
        isSuccess={isArchiveSuccess}
        requestId={archiveRequestId}
        successTitle={t('contract.producer.correct_modal.success_title')}
        successContent={
          <Trans
            i18nKey="contract.producer.correct_modal.success_description"
            values={{ name: organisationData?.name }}
            components={{ bold: <b /> }}
          />
        }
        showError={false}
        showLoader={false}
      />
    </>
  );
};

ContractSendComponent.defaultProps = {
  contractData: null,
};

ContractSendComponent.propTypes = {
  title: PropTypes.string.isRequired,
  contractData: PropTypes.shape(),
};

export default ContractSendComponent;
