import routes from 'utils/routes';
import {
  CONTROL_BODY,
  CONTROL_BODY_OVERVIEW,
  ORGANISATION,
  ORGANISATION_DECLARATION_STATUS,
  ORGANISATION_OVERVIEW,
} from 'utils/tag-types';
import { apiSlice } from '../index';

const controlBodyDeclarationsApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getControlBodyDeclarationsOverview: build.query({
      query: params => routes.controlBodyDeclarations.overview(params),
      providesTags: [CONTROL_BODY_OVERVIEW],
    }),
    getControlBodyDeclarationHistory: build.query({
      query: params => routes.controlBodyDeclarations.historyOfControlBodyDeclaration(params),
      providesTags: [CONTROL_BODY],
    }),
    getControlBodyDeclarationOptions: build.query({
      query: params => routes.controlBodyDeclarations.controlBodyDeclarationOptions(params),
      providesTags: [CONTROL_BODY],
    }),
    getActiveControlBodyDeclaration: build.query({
      query: id => routes.controlBodyDeclarations.entityOfActiveControlBody({ organizationId: id }),
      providesTags: [CONTROL_BODY],
    }),
    declareControlBodyDeclaration: build.mutation({
      query: ({ id, payload }) => ({
        url: routes.controlBodyDeclarations.declareControlBodyDeclaration({ id }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [CONTROL_BODY, CONTROL_BODY_OVERVIEW],
    }),
    raiseComplaintControlBodyDeclaration: build.mutation({
      query: ({ id, payload }) => ({
        url: routes.controlBodyDeclarations.raiseComplaintControlBodyDeclaration({ id }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [CONTROL_BODY, CONTROL_BODY_OVERVIEW],
    }),
    verifyControlBodyDeclaration: build.mutation({
      query: ({ id, payload }) => ({
        url: routes.controlBodyDeclarations.verifyControlBodyDeclaration({ id }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [
        CONTROL_BODY,
        ORGANISATION,
        ORGANISATION_OVERVIEW,
        ORGANISATION_DECLARATION_STATUS,
        CONTROL_BODY_OVERVIEW,
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetControlBodyDeclarationsOverviewQuery,
  useGetControlBodyDeclarationHistoryQuery,
  useGetControlBodyDeclarationOptionsQuery,
  useGetActiveControlBodyDeclarationQuery,
  useDeclareControlBodyDeclarationMutation,
  useRaiseComplaintControlBodyDeclarationMutation,
  useVerifyControlBodyDeclarationMutation,
} = controlBodyDeclarationsApi;
