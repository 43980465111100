import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'throttle-debounce';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PillTabs } from 'components/base-components/PillTabs';
import MESSAGES_STATUS from 'utils/message-status';
import { useGetMessagesQuery } from 'api/messages';
import { useSearchParams } from 'react-router-dom';
import Pagination from 'components/base-components/Pagination/Pagination';
import MessageTabContentComponent from './messages-tab-content.component';

const SharedMessages = ({ recipientType, senderType, currentUserId }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState();
  const [searchParams, setSearchParams] = useSearchParams({
    recipient: 'me',
    sender: '',
    'sender_ids[]': [],
    q: '',
    page: 1,
  });

  const currentTab = searchParams.get('type') || MESSAGES_STATUS.regular;
  const currentRecipient = searchParams.get('recipient');
  const currentSender = searchParams.get('sender');
  const currentPage = parseInt(searchParams.get('page'), 10);

  const handleMessageParams = () => {
    if (senderType) {
      return {
        q: searchParams.get('q'),
        sender: senderType,
        type: MESSAGES_STATUS[currentTab],
        page: currentPage,
        'sender_ids[]': [currentUserId],
      };
    } else if (recipientType) {
      return {
        q: searchParams.get('q'),
        recipient: recipientType,
        type: MESSAGES_STATUS[currentTab],
        page: currentPage,
        'sender_ids[]': [currentUserId],
      };
    } else if (currentUserId) {
      return {
        q: searchParams.get('q'),
        type: MESSAGES_STATUS[currentTab],
        page: currentPage,
        'sender_ids[]': [currentUserId],
      };
    }
  };

  const messageParams = handleMessageParams();

  const setSearchQueryParams = useCallback(
    params => {
      Object.keys(params).forEach(key => {
        searchParams.set(key, params[key]);
      });
      setSearchParams(searchParams);
    },
    [currentTab],
  );

  const { isFetching: isFetchingMessages, data: messageData } = useGetMessagesQuery(messageParams);
  const messagesDataCollection = messageData?.collection || [];

  const setSearchQueryInParams = React.useRef(
    debounce(500, value =>
      setSearchQueryParams(
        {
          q: value,
          type: MESSAGES_STATUS[currentTab],
          recipient: currentRecipient,
          sender: currentSender,
          page: 1,
        },
        currentRecipient,
        currentTab,
        currentSender,
      ),
    ),
  ).current;

  const onSearch = value => {
    setSearchQueryInParams(value);
  };

  useEffect(() => {
    setSearchQueryParams(
      {
        type: MESSAGES_STATUS[currentTab],
        recipient: recipientType || '',
        sender: senderType || '',
        page: 1,
      },
      currentRecipient,
      currentTab,
      currentSender,
    );
  }, []);

  return (
    <>
      <PillTabs
        items={[
          {
            getContent: () => (
              <MessageTabContentComponent
                type="regular"
                key="regular"
                recipient={recipientType}
                sender={senderType}
                messages={messagesDataCollection}
                onChangeParams={setSearchQueryParams}
                isFetchingMessages={isFetchingMessages}
                handleSearch={onSearch}
              />
            ),
            key: 0,
            title: t('messages.type.regular'),
          },
          {
            getContent: () => (
              <MessageTabContentComponent
                type="unconfirmed"
                key="unconfirmed"
                recipient={recipientType}
                sender={senderType}
                messages={messagesDataCollection}
                onChangeParams={setSearchQueryParams}
                isFetchingMessages={isFetchingMessages}
                handleSearch={onSearch}
              />
            ),
            key: 1,
            title: t('messages.type.unconfirmed'),
          },
          {
            getContent: () => (
              <MessageTabContentComponent
                type="confirmed"
                key="confirmed"
                recipient={recipientType}
                sender={senderType}
                messages={messagesDataCollection}
                onChangeParams={setSearchQueryParams}
                isFetchingMessages={isFetchingMessages}
                handleSearch={onSearch}
              />
            ),
            key: 2,
            title: t('messages.type.confirmed'),
          },
        ]}
        onChange={value => setSelectedTab(value)}
        selectedTabKey={selectedTab}
      />
      <Pagination
        onPaginationClick={newPage => {
          setSearchQueryParams({ page: newPage });
        }}
        totalPages={messageData && parseInt(messageData.pagination.totalPages, 10)}
        currentPage={currentPage}
        firstText={t('pagination.first')}
        lastText={t('pagination.last')}
        nextText={t('pagination.next')}
        previousText={t('pagination.prev')}
      />
    </>
  );
};

SharedMessages.defaultProps = {
  recipientType: null,
  senderType: null,
  currentUserId: null,
};

SharedMessages.propTypes = {
  recipientType: PropTypes.string,
  senderType: PropTypes.string,
  currentUserId: PropTypes.arrayOf(PropTypes.number),
};

export default SharedMessages;
