const getDimensions = node => {
  const { height, width } = node.getBoundingClientRect();

  return {
    height: parseFloat(height.toFixed(2)),
    width: parseFloat(width.toFixed(2)),
  };
};

export default getDimensions;
