import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from 'components/base-components/Icon';
import StackedItems from 'components/base-components/StackedItems';
import Tooltip from 'components/base-components/Tooltip';
import IconButton from 'components/base-components/IconButton/IconButton';
import Spinner from 'components/base-components/Spinner';
import Avatar from 'components/base-components/Avatar';
import Button from 'components/base-components/Button';
import Modal from 'components/base-components/Modal';
import OrganizationName from 'components/organisations/organization-name';
import {
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableData,
  TableRow,
} from 'components/base-components/Table';
import useNavigationLinks from 'hooks/useNavigationLinks';
import {
  useResendUserInvitationMutation,
  useReactivateUserMutation,
  useGetCurrentUserQuery,
} from 'api/users';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import TableLoaderPlaceholder from 'components/shared-components/placeholders/table-loader-placeholder';
import isControlBodyMember from 'utils/is-control-body-member';
import { SUPER_ADMIN, CONTROL_BODY_OWNER, OWNER } from 'utils/users-role';
import Link from 'components/base-components/Link';
import ConfirmationModalWrapper from '../ConfirmationModalWrapper';
import '../users.styles.scss';

const UsersTableRow = ({
  user,
  organizationId,
  isOrganisationView,
  tab,
  onResendUserInvitation,
}) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reactivateUser] = useReactivateUserMutation();
  const { data: currentUser = {} } = useGetCurrentUserQuery();
  const { t } = useTranslation();
  const navigationLinks = useNavigationLinks();
  let organisationRoles = [];

  const controlBodyMember = isControlBodyMember(user);

  if (isOrganisationView) {
    organisationRoles = user.assignments
      .filter(assignment => assignment.organizationId === organizationId)
      .map(assignment => t(`role.${assignment.role}`));
  } else if (user.superAdmin) {
    organisationRoles.push(t(`role.${SUPER_ADMIN}`));
  } else if (!controlBodyMember) {
    organisationRoles.push(t(`role.${user.role}`));
  } else if (user?.assignments[0]?.role === OWNER) {
    organisationRoles.push(t(`role.${CONTROL_BODY_OWNER}`));
  } else {
    organisationRoles.push(t(`role.${user?.assignments[0]?.role}`));
  }

  return (
    <TableRow className="user__list--table-row">
      <TableData>
        <Avatar
          className="user__list--table-avatar"
          showName={true}
          firstName={user.firstName}
          lastName={user.lastName}
          src={user.profilePictureUrl}
          size="tiny"
        />
      </TableData>
      <TableData>{organisationRoles.join(', ')}</TableData>
      {!isOrganisationView && !isControlBodyMember(currentUser) && (
        <TableData>
          {user.assignments.length > 0 ? (
            <StackedItems
              isPopoverVisible={isPopoverVisible}
              onClick={() => setIsPopoverVisible(!isPopoverVisible)}
              onOutsideClick={() => setIsPopoverVisible(false)}
            >
              {user.assignments.map(assignment => (
                <OrganizationName
                  key={`assignment-${assignment.id}`}
                  organizationId={assignment.organizationId}
                />
              ))}
            </StackedItems>
          ) : (
            t('common.not_applicable')
          )}
        </TableData>
      )}
      <TableData className="user__list--table-row-email">{user.email}</TableData>
      {tab === 'archived' ? (
        <TableData align="right">
          <div className="user__action">
            <Tooltip
              content={t('user.table_head.reactivate_user')}
              type="inverse"
              size="tiny"
              className="user__action--tooltip"
            >
              <IconButton
                className="user__action--show"
                icon={<Icon name="autoRenew" size="small" />}
                color="tertiary"
                size="tiny"
                onClick={() => setIsModalOpen(true)}
              />
            </Tooltip>
          </div>
          <Modal
            className="user-details__modal"
            isVisible={isModalOpen}
            onOutsideClick={() => setIsModalOpen(false)}
          >
            <Modal.Title className="user-details__modal--title">
              <Icon name="warning" showBGColor={true} size="medium" color="warning" />
              <div className="user-details__modal--title__name">
                {t('user_details.activate_user_modal.title')}
              </div>
            </Modal.Title>
            <Modal.Content className="user-details__modal--content">
              {t('user_details.activate_user_modal.description', { name: user?.name })}
            </Modal.Content>
            <Modal.Footer>
              <Button
                label={t('user_details.deactivate_user_modal.confirm')}
                type="success"
                onClick={() => {
                  reactivateUser(user.id);
                  setIsModalOpen(false);
                }}
              />
              <Button
                label={t('user_details.deactivate_user_modal.cancel_button')}
                onClick={() => setIsModalOpen(false)}
              />
            </Modal.Footer>
          </Modal>
        </TableData>
      ) : (
        <TableData align="right">
          <div className="user__action">
            {tab === 'invited' && (
              <Tooltip
                content={t('user.table_head.resend_invitation')}
                type="inverse"
                size="tiny"
                className="user__action--tooltip"
              >
                <IconButton
                  className="user__action--show"
                  icon={<Icon name="send" size="small" />}
                  size="tiny"
                  color="tertiary"
                  onClick={() => onResendUserInvitation(user.id)}
                />
              </Tooltip>
            )}
            {!isControlBodyMember(currentUser) && (
              <Tooltip
                content={t('user.table_head.view')}
                type="inverse"
                size="tiny"
                className="user__action--tooltip"
              >
                <Link type="icon-button" href={navigationLinks.userDetailsPage(user.id)}>
                  <IconButton
                    className="user__action--show"
                    icon={<Icon name="show" size="small" />}
                    size="tiny"
                    color="tertiary"
                  />
                </Link>
              </Tooltip>
            )}
            <Tooltip
              content={t('user.table_head.edit')}
              type="inverse"
              size="tiny"
              className="user__action--tooltip"
            >
              <Link
                type="icon-button"
                href={
                  isOrganisationView
                    ? navigationLinks.organisationUserEditPage(organizationId, user.id)
                    : navigationLinks.userEditPage(user.id)
                }
              >
                <IconButton
                  className="user__action--edit"
                  icon={<Icon name="edit" size="small" />}
                  size="tiny"
                  color="tertiary"
                />
              </Link>
            </Tooltip>
          </div>
        </TableData>
      )}
    </TableRow>
  );
};

UsersTableRow.defaultProps = {
  organizationId: null,
};

UsersTableRow.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    profilePictureUrl: PropTypes.string.isRequired,
    assignments: PropTypes.arrayOf(
      PropTypes.shape({
        organizationId: PropTypes.number,
        role: PropTypes.string,
        assignmentId: PropTypes.number,
      }),
    ).isRequired,
    role: PropTypes.string.isRequired,
    superAdmin: PropTypes.bool.isRequired,
  }).isRequired,
  tab: PropTypes.string.isRequired,
  isOrganisationView: PropTypes.bool.isRequired,
  organizationId: PropTypes.number,
  onResendUserInvitation: PropTypes.func.isRequired,
};

const UsersTable = ({ users, isLoading, organizationId, isOrganisationView, tab }) => {
  const { t } = useTranslation();
  const [resendInvitation, { isSuccess, isLoading: isSendingInvitaionLoading }] =
    useResendUserInvitationMutation();
  const { data: currentUser = {} } = useGetCurrentUserQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getModalContent = () => {
    if (isSendingInvitaionLoading) {
      return (
        <div className="user__invite--modal-loading">
          <Spinner size="small" color="success" bgColor="none" />
          <div className="user__invite--modal-loading-message">
            {t('user_invite.modal.sending_invitation')}
          </div>
        </div>
      );
    }

    if (isSuccess) {
      return (
        <div className="user__invite--modal-success">
          <Icon
            className="user__invite--modal-success-icon"
            name="check"
            size="large"
            showBGColor={true}
          />
          <div className="user__invite--modal-success-message">
            <div>{t('user_invite.modal.success_header')}</div>
            <div>{t('user_invite.modal.success_description')}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="user__invite--modal-failure">
        <Icon className="user__invite--modal-failure-icon" name="invalid" size="xxl" />
        <div className="user__invite--modal-failure-message">
          <div>{t('user_invite.modal.failure_header')}</div>
          <div>{t('user_invite.modal.failure_description')}</div>
          <div>{t('user_invite.modal.try_again')}</div>
        </div>
      </div>
    );
  };

  const onResendUserInvitation = userId => {
    setIsModalOpen(true);
    resendInvitation(userId);
  };

  const UserListTableHeader = () => (
    <TableHead>
      <TableRow>
        <TableHeader>{t('user.table_head.name')}</TableHeader>
        <TableHeader>{t('user.table_head.role')}</TableHeader>
        {!isOrganisationView && !isControlBodyMember(currentUser) && (
          <TableHeader>{t('user.table_head.associated_organisation')}</TableHeader>
        )}
        <TableHeader>{t('user.table_head.email')}</TableHeader>
        <TableHeader align="right">{t('user.table_head.actions')}</TableHeader>
      </TableRow>
    </TableHead>
  );

  return (
    <>
      {!isLoading && users?.length === 0 ? (
        <EmptyContentPlaceholder iconName="people" text={t('user.no_user_found')} />
      ) : (
        <Table className="user__list--table">
          <UserListTableHeader />
          <TableBody>
            {isLoading ? (
              <TableLoaderPlaceholder numberOfRows={10} numberOfColumns={5} />
            ) : (
              users?.map(user => (
                <UsersTableRow
                  key={`${user.id}`}
                  user={user}
                  isOrganisationView={isOrganisationView}
                  organizationId={organizationId}
                  tab={tab}
                  onResendUserInvitation={id => onResendUserInvitation(id)}
                />
              ))
            )}
          </TableBody>
          <ConfirmationModalWrapper
            isVisible={isModalOpen}
            contentAlign="center"
            footerAlign="center"
            content={getModalContent()}
            className="user__invite--modal"
            timeOut={!isSendingInvitaionLoading ? 3000 : 0}
            onTimeout={() => {
              setIsModalOpen(false);
            }}
          />
        </Table>
      )}
    </>
  );
};

UsersTable.defaultProps = {
  organizations: {},
  organizationId: null,
  isOrganisationView: false,
};

UsersTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  organizations: PropTypes.shape(),
  tab: PropTypes.string.isRequired,
  isLoading: PropTypes.func.isRequired,
  organizationId: PropTypes.number,
  isOrganisationView: PropTypes.bool,
};

export default UsersTable;
