import { useGetOrganizationQuery } from 'api/organizations';
import { useGetCurrentUserQuery } from 'api/users';
import Icon from 'components/base-components/Icon';
import Input from 'components/base-components/Input/Input';
import Link from 'components/base-components/Link';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { debounce } from 'throttle-debounce';
import isControlBodyMember from 'utils/is-control-body-member';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import { CONTROL_BODY_USER, OWNER } from 'utils/users-role';
import '../users.styles.scss';
import UsersTable from './table.component';

const TabContent = props => {
  const { tab, onChangeParams, users, isLoading, organizationId, isOrganisationView } = props;
  const [searchInput, setSearchInput] = useState('');

  const navigationLinks = useNavigationLinks();

  const setSearchQueryInParams = React.useRef(
    debounce(500, value => {
      onChangeParams({ q: value, page: 1 }, tab);
    }),
  ).current;

  const { data: currentUser = {} } = useGetCurrentUserQuery();

  const { data: organisation } = useGetOrganizationQuery(organizationId);

  const isDeactivated = organisation?.status === ORGANISATIONS_STATUS.inactive;

  const isInviteEnabled = () => {
    if (currentUser.role === CONTROL_BODY_USER) {
      const isOwner = currentUser.assignments?.some(assignment => assignment.role === OWNER);
      if (isOwner) return true;
      return false;
    }
    return true;
  };

  const onSearch = value => {
    setSearchInput(value);
    setSearchQueryInParams(value);
  };

  useEffect(() => {
    onChangeParams({ tab, page: 1 }, tab, true);
  }, []);

  return (
    <>
      <div className="user__list--header">
        <Input
          className="user__search align-self-start"
          placeholder={t('user.search')}
          preIcon={<Icon name="search" />}
          size="tiny"
          value={searchInput}
          onChange={onSearch}
        />
        {isInviteEnabled() && (
          <Link
            href={
              isOrganisationView
                ? navigationLinks.usersInvitationPageFromOrganisation(organizationId)
                : navigationLinks.usersInvitationPage()
            }
            modifier="success"
            type="button"
            disabled={isDeactivated || (organizationId && isControlBodyMember(currentUser))}
            size="small"
          >
            {t('user.invite_user')}
          </Link>
        )}
      </div>
      <div className="col-12 col-bleed-x">
        <UsersTable
          users={users}
          isLoading={isLoading}
          isOrganisationView={isOrganisationView}
          organizationId={organizationId}
          tab={tab}
        />
      </div>
    </>
  );
};

TabContent.defaultProps = {
  users: [],
  organizationId: null,
};

TabContent.propTypes = {
  tab: PropTypes.string.isRequired,
  onChangeParams: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool.isRequired,
  organizationId: PropTypes.number,
  isOrganisationView: PropTypes.bool.isRequired,
};

export default TabContent;
