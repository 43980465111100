import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Icon from 'components/base-components/Icon';
import Modal from 'components/base-components/Modal';
import Button from 'components/base-components/Button';
import { ANNUAL, INDIVIDUAL, INITIAL } from 'utils/invoice-types';
import { DRAFT, OPEN, OVERDUE, PAID, PENDING, CANCELLED } from 'utils/invoice-statuses';
import CheckboxFilter from 'components/shared-components/Filters/CheckboxFilter';
import { useParams, useSearchParams } from 'react-router-dom';
import OrganizationFilter from 'components/shared-components/organization-filter.jsx';
import DateRangeFilter from './DateRangeFilter';

const typeOptions = [
  { label: t('invoice.types.initial'), key: INITIAL },
  { label: t('invoice.types.annual'), key: ANNUAL },
  { label: t('invoice.types.individual'), key: INDIVIDUAL },
];

const statusOptions = [
  { label: t('invoice.tag_status.draft'), key: DRAFT },
  { label: t('invoice.tag_status.open'), key: OPEN },
  { label: t('invoice.tag_status.overdue'), key: OVERDUE },
  { label: t('invoice.tag_status.paid'), key: PAID },
  { label: t('invoice.tag_status.pending'), key: PENDING },
  { label: t('invoice.tag_status.cancelled'), key: CANCELLED },
];

const ModalContent = ({ form }) => {
  const { id } = useParams();
  return (
    <div>
      <span onClick={() => form.reset()} className="modal__content--reset-all">
        {t('shared.action.reset_all')}
      </span>
      <div className="modal__content__filter-set">
        {!id && <OrganizationFilter />}
        <DateRangeFilter title={t('invoice.date')} fieldName="invoiceDate" form={form} />
        <DateRangeFilter title={t('invoice.due_date')} fieldName="dueDate" form={form} />
        {id && (
          <>
            <CheckboxFilter
              title={t('invoice.status')}
              fieldName="statuses"
              checkboxList={statusOptions}
              form={form}
            />
            <CheckboxFilter
              title={t('invoice.type')}
              fieldName="types"
              checkboxList={typeOptions}
              form={form}
            />
          </>
        )}
      </div>
    </div>
  );
};

const InvoiceFilterModal = props => {
  const { isFilterVisible, setIsFilterVisible, setFilterQueryParams } = props;
  const [searchParams] = useSearchParams();

  const onSubmit = values => {
    setFilterQueryParams({
      invoiceStart: values.invoiceDate?.start_date,
      invoiceEnd: values.invoiceDate?.end_date,
      dueDateStart: values.dueDate?.start_date,
      dueDateEnd: values.dueDate?.end_date,
      statuses: values.statuses || searchParams.getAll('statuses'),
      types: values.types || searchParams.getAll('types'),
      organizationIds: values.organizationIds,
      date: searchParams.get('date'),
      industries: searchParams.getAll('industries'),
      jobIds: searchParams.getAll('jobIds'),
    });

    setIsFilterVisible(false);
  };

  return (
    <Modal
      className="modal__filter"
      isVisible={isFilterVisible}
      onOutsideClick={() => setIsFilterVisible(false)}
    >
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Title className="modal__filter--title">
              <div className="modal__filter--title-name">{t('shared.action.filter')}</div>
              <div onClick={() => setIsFilterVisible(false)}>
                <Icon size="small" color="tertiary" name="close" />
              </div>
            </Modal.Title>
            <Modal.Content>
              <ModalContent form={form} />
            </Modal.Content>
            <Modal.Footer className="modal__filter--footer">
              <Button
                className="modal__filter--footer-button"
                type="success"
                label={t('shared.action.apply')}
                size="small"
                disabled={submitting}
                onClick={handleSubmit}
              />
              <Button
                className="modal__filter--footer-button"
                label={t('shared.action.cancel')}
                size="small"
                onClick={() => setIsFilterVisible(false)}
              />
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
};

ModalContent.propTypes = {
  form: PropTypes.shape().isRequired,
};

InvoiceFilterModal.propTypes = {
  isFilterVisible: PropTypes.bool.isRequired,
  setIsFilterVisible: PropTypes.func.isRequired,
  setFilterQueryParams: PropTypes.func.isRequired,
};

export default InvoiceFilterModal;
