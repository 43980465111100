import React, { Fragment } from 'react';
import { monthYearShort } from 'utils/date-time-formatter';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TimePeriodComponent = ({ validFrom, validUntil, status, showToBeDeclaredText }) => {
  const { t } = useTranslation();

  const timeContent =
    validFrom && validUntil ? (
      <Fragment>
        <span
          className={classNames('organisation-overview__time-date', {
            'organisation-overview__time-date--expired': status === 'expired',
          })}
        >{`${monthYearShort(validFrom)} - ${monthYearShort(validUntil)}`}</span>
      </Fragment>
    ) : (
      showToBeDeclaredText && t('shared.tbd')
    );

  return <div className="organisation-overview__time">{timeContent}</div>;
};

TimePeriodComponent.defaultProps = {
  validFrom: null,
  validUntil: null,
  status: null,
  showToBeDeclaredText: true,
};

TimePeriodComponent.propTypes = {
  validFrom: PropTypes.string,
  validUntil: PropTypes.string,
  status: PropTypes.string,
  showToBeDeclaredText: PropTypes.bool,
};

export default TimePeriodComponent;
