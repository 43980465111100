import { Font, StyleSheet } from '@react-pdf/renderer';
import FuturaNormal from 'assets/fonts/FuturaStdBook.otf';
import FuturaBold from 'assets/fonts/FuturaStdBold.otf';
import {
  BASE_LINE_HEIGHT,
  COLOR_GRAY,
  FONT_SIZE_10,
  FONT_SIZE_14,
  FONT_SIZE_36,
  FONT_SIZE_8_5,
  FONT_WEIGHT_BOLD,
} from '../styles/variables';

Font.register({
  family: 'Futura',
  fonts: [{ src: FuturaNormal }, { src: FuturaBold, fontWeight: FONT_WEIGHT_BOLD }],
});

const recognitionStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontFamily: 'Futura',
    lineHeight: BASE_LINE_HEIGHT,
    fontSize: FONT_SIZE_10,
    paddingBottom: 20,
  },
  body: {
    flexGrow: 1,
    marginHorizontal: 72,
    fontFamily: 'Futura',
  },
  pdfTitle: {
    fontSize: FONT_SIZE_36,
    textAlign: 'center',
    fontFamily: 'Futura',
  },
  pdfSubtitle: {
    textAlign: 'center',
    marginTop: 40,
    marginBottom: 10,
    ontSize: FONT_SIZE_14,
    lineHeight: 2,
    fontWeight: FONT_WEIGHT_BOLD,
  },
  fad: {
    textAlign: 'center',
    fontWeight: FONT_WEIGHT_BOLD,
    marginTop: 30,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 25,
    paddingTop: 10,
  },
  footerText: {
    fontSize: FONT_SIZE_8_5,
    color: COLOR_GRAY,
    fontFamily: 'Futura',
  },

  headerLogo: {
    height: 90,
    width: '100%',
    objectFit: 'contain',
    marginBottom: 24,
    marginTop: 32,
  },
});

export default recognitionStyles;
