import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useGetOrganizationQuery } from 'api/organizations';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import Avatar from 'components/base-components/Avatar/Avatar';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/base-components/IconButton';
import Icon from 'components/base-components/Icon';
import { PRODUCER } from 'utils/organisation-industries';
import { useGetCurrentUserQuery } from 'api/users';
import useNavigationLinks from 'hooks/useNavigationLinks';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import isControlBodyMember from 'utils/is-control-body-member';
import Popover from 'components/base-components/Popover';
import { Link } from 'react-router-dom';
import DefaultSpinner from 'components/shared-components/default-spinner';
import OrganizationName from '../organization-name';
import PopoverContent from './overview/popover-content.component';

const OrganisationViewHeader = () => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigationLinks = useNavigationLinks();
  const { data: organisation, isFetching } = useGetOrganizationQuery(id);
  const { data: currentUser } = useGetCurrentUserQuery();
  if (!currentUser) return <DefaultSpinner />;

  const isAdmin = isBioSuisseAdmin(currentUser);
  const isControlBodyUser = isControlBodyMember(currentUser);

  const mainAddress = organisation?.addresses?.find(address => address.main === true);

  const isProducer = organisation?.industry === PRODUCER;

  return (
    <div className="organisation__view-header">
      <div className="organisation__view-header__title-logo">
        {isFetching ? (
          <div className="organisation__view-header__loader">
            <ContentLoaderPlaceholder numberOfLines={0} shape="circle" diameter="large" />
          </div>
        ) : (
          <Avatar
            className="organisation-details__head--image-details"
            src={organisation?.logoUrl}
            firstName={organisation?.name.split(' ')[0]}
            lastName={organisation?.name.split(' ')[1]}
            size="huge"
          />
        )}
        <div className="organisation__view-header__text">
          <div className="organisation__view-organisation-name">
            {isFetching ? (
              <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
            ) : (
              <div className="organisation__view-header--with-popover">
                <div>
                  <OrganizationName organizationId={organisation?.id} disableLink={true} />
                  <div className="organisation-details__head--title__main-address">{`${mainAddress?.line1}, ${mainAddress?.city}`}</div>
                  <div className="organisation-details__head--title__id-wrapper">
                    <div className="organisation-details__head--title__id-bio">
                      {organisation.bioId || t('common.not_applicable')}
                    </div>
                    <div className="organisation-details__head--title__id-vertical-line" />
                    <div className="organisation-details__head--title__id-uid">
                      {organisation.uid || t('common.not_applicable')}
                    </div>
                  </div>
                </div>
                <div className="organisation-details__head--title__vertical-line" />
                <Popover
                  className="organisation__head-popover--wrapper"
                  isVisible={isPopoverVisible}
                  onOutsideClick={() => setIsPopoverVisible(false)}
                  content={
                    <PopoverContent
                      isProducer={isProducer}
                      id={id}
                      organisation={organisation}
                      isControlBodyUser={isControlBodyUser}
                      isAdmin={isAdmin}
                    />
                  }
                  size="medium"
                  direction="bottom-left"
                >
                  <IconButton
                    className="organisation__head-popover--icon-button"
                    icon={<Icon name="viewdetails" className="organisation__head-popover--icon" />}
                    color="default"
                    onClick={() => setIsPopoverVisible(true)}
                    size="small"
                    isIconOnly={true}
                  />
                </Popover>
                <Link to={navigationLinks.organisationDetailsPage(id)}>
                  <IconButton
                    className="organisation__head-popover--go-back"
                    icon={<Icon name="goBack" size="small" />}
                    color="default"
                    size="medium"
                    isIconOnly={true}
                  />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganisationViewHeader;
