import routes from 'utils/routes';
import {
  INVOICE,
  INVOICE_ITEMS,
  INVOICE_JOB,
  INVOICE_REJECT,
  INVOICE_RELEASE,
  INVOICE_TRANSACTIONS,
} from 'utils/tag-types';
import { apiSlice } from '../index';

const invoiceApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getInvoices: build.query({
      query: params => routes.invoice.list(params),
      providesTags: [INVOICE],
    }),
    getInvoiceItems: build.query({
      query: ({ invoiceId }) => routes.invoice.collectionOfItems({ invoiceId }),
      providesTags: [INVOICE_ITEMS],
    }),
    getInvoice: build.query({
      query: ({ invoiceId }) => routes.invoice.entity({ invoiceId }),
      providesTags: [INVOICE, INVOICE_ITEMS],
    }),
    getInvoiceTransactions: build.query({
      query: ({ invoiceId }) => routes.invoice.collectionOfTransactions({ invoiceId }),
      providesTags: [INVOICE_TRANSACTIONS],
    }),
    createTransaction: build.mutation({
      query: ({ invoiceId, payload }) => ({
        url: routes.invoice.collectionOfTransactions({ invoiceId }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, id) => (!error ? [INVOICE, INVOICE_TRANSACTIONS] : []),
    }),
    createInvoice: build.mutation({
      query: ({ queryParams, payload }) => ({
        url: routes.invoice.manual(queryParams),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [INVOICE, INVOICE_ITEMS],
    }),
    updateInvoice: build.mutation({
      query: ({ payload, invoiceId }) => ({
        url: routes.invoice.entity({ invoiceId }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [INVOICE, INVOICE_ITEMS],
    }),
    correctInvoice: build.mutation({
      query: ({ payload, id }) => ({
        url: routes.invoice.correct({ id }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [INVOICE, INVOICE_ITEMS],
    }),
    issueCreditNote: build.mutation({
      query: ({ id }) => ({
        url: routes.invoice.issueCreditNote({ id }),
        method: 'POST',
      }),
      invalidatesTags: [INVOICE, INVOICE_ITEMS],
    }),
    rejectInvoice: build.mutation({
      query: ({ invoiceId, payload }) => ({
        url: routes.invoice.reject({ invoiceId }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [INVOICE, INVOICE_REJECT],
    }),
    releaseInvoice: build.mutation({
      query: ({ invoiceId, payload }) => ({
        url: routes.invoice.release({ invoiceId }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [INVOICE, INVOICE_RELEASE],
    }),
    reopenInvoice: build.mutation({
      query: ({ id }) => ({
        url: routes.invoice.reopen({ id }),
        method: 'PUT',
      }),
      invalidatesTags: [INVOICE, INVOICE_TRANSACTIONS],
    }),
    getInvoiceItemCostCenters: build.query({
      query: () => routes.invoice.costCenters(),
    }),
    getInvoiceItemCostElements: build.query({
      query: () => routes.invoice.costElements(),
    }),
    markAsOpen: build.mutation({
      query: ({ payload }) => ({
        url: routes.invoice.markAsOpen(),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, id) => (!error ? [INVOICE] : []),
    }),
    markAsCancel: build.mutation({
      query: ({ payload }) => ({
        url: routes.invoice.markAsCancel(),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, id) => (!error ? [INVOICE] : []),
    }),
    getInvoiceGroups: build.query({
      query: params => routes.invoice.invoiceGroups(params),
      providesTags: [INVOICE],
    }),
    createInvoiceJob: build.mutation({
      query: ({ queryParams, payload }) => ({
        url: routes.invoice.invoiceJob(queryParams),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [INVOICE_JOB],
    }),
    getInvoiceJobs: build.query({
      query: params => routes.invoice.invoiceJob(params),
      providesTags: [INVOICE_JOB],
    }),
    getInvoiceJob: build.query({
      query: ({ invoiceJobId }) => routes.invoice.entityOfInvoiceJob({ invoiceJobId }),
      providesTags: [INVOICE_JOB, INVOICE_RELEASE, INVOICE_REJECT],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetInvoicesQuery,
  useGetInvoiceItemsQuery,
  useGetInvoiceQuery,
  useCreateInvoiceMutation,
  useUpdateInvoiceMutation,
  useGetInvoiceItemCostElementsQuery,
  useGetInvoiceItemCostCentersQuery,
  useGetInvoiceTransactionsQuery,
  useCreateTransactionMutation,
  useMarkAsOpenMutation,
  useMarkAsCancelMutation,
  useCorrectInvoiceMutation,
  useGetInvoiceGroupsQuery,
  useRejectInvoiceMutation,
  useReleaseInvoiceMutation,
  useReopenInvoiceMutation,
  useCreateInvoiceJobMutation,
  useGetInvoiceJobsQuery,
  useGetInvoiceJobQuery,
  useIssueCreditNoteMutation,
} = invoiceApi;
