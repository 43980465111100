import React from 'react';
import { useGetOrganizationQuery } from 'api/organizations';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';

const BreadcrumbIndustry = ({ children, linkUrl }) => {
  const { id } = useParams();

  const isOrganisation = children?.props?.children === t('common.organisations');
  const CONTROL_BODY = 'control_body';

  const { data: organisation = {} } = useGetOrganizationQuery(id, { skip: !isOrganisation });

  const industryName = {
    producer: t('sidenav.producers'),
    gastronomy: t('sidenav.restaurants'),
    control_body: t('sidenav.control_body'),
  };

  const isControlBody = organisation?.type === CONTROL_BODY;

  const linkName = {
    producer: '/producers',
    gastronomy: '/restaurants',
    control_body: '/control-body',
  };

  const getName = () => {
    if (isControlBody) return industryName[organisation?.type];
    if (organisation?.industry) return industryName[organisation?.industry];

    return t('common.organisations');
  };

  const getLink = () => {
    if (isControlBody) return linkName[organisation?.type];
    if (organisation?.industry) return linkName[organisation?.industry];

    return linkUrl;
  };

  return <a href={isOrganisation ? getLink() : linkUrl}>{isOrganisation ? getName() : children}</a>;
};

BreadcrumbIndustry.defaultProps = {
  children: {},
  linkUrl: null,
};

BreadcrumbIndustry.propTypes = {
  children: PropTypes.shape(),
  linkUrl: PropTypes.node,
};

export default BreadcrumbIndustry;
