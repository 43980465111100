export const MANUAL = 'manual';
export const REVIEW_CONTROL_BODY_DECLARATION = 'review_control_body_declaration';
export const REVIEW_ACCOUNTING_PROCESS_ADDITIONAL_TASK =
  'review_accounting_process_additional_task';
export const REVIEW_LOGO_ATTACHMENT_ADDITIONAL_TASK = 'review_logo_attachment_additional_task';
export const REVIEW_INGREDIENT_RESERVE_ADDITIONAL_TASK =
  'review_ingredient_reserve_additional_task';
export const REVIEW_RESTRICTED_INGREDIENT_ADDITIONAL_TASK =
  'review_restricted_ingredient_additional_task';
export const REVIEW_CONTROL_FLOW_SCHEDULE = 'review_control_flow_schedule';
export const REVIEW_INVOICE_JOB = 'review_invoice_job';
export const REVIEW_CREATED_ANNERKENNUNG = 'review_created_anerkennung';
