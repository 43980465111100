import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { dateMonthYearFormatter, dateTimeFormatterWith24Hour } from 'utils/date-time-formatter';
import IconButton from 'components/base-components/IconButton';
import UserName from 'components/users/user-name.component';
import OrganizationName from 'components/organisations/organization-name';
import Popover from 'components/base-components/Popover';
import Icon from 'components/base-components/Icon';
import { Task } from 'components/base-components/TaskList';
import EmphasisTag from 'components/base-components/EmphasisTag';
import { useMarkAsDoneMutation, useUpdateTaskMutation } from 'api/tasks';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import TASKS_STATUS from 'utils/task-status';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { useGetCurrentUserQuery } from 'api/users';
import WarningModal from 'components/shared-components/modal/warning';
import RowLoaderPlaceHolder from 'components/shared-components/placeholders/row-loader-placeholder.component';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import UserInfo from 'components/users/user-info.component';
import Input from 'components/base-components/Input';
import Button from 'components/base-components/Button';
import Spinner from 'components/base-components/Spinner/Spinner';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { Link } from 'react-router-dom';
import ConfirmationModalWrapper from 'components/users/ConfirmationModalWrapper';
import { parseTaskTitle } from '../task-helper';
import TaskTitle from './task-title';

const TaskTabContentComponent = ({
  tasks,
  assigned_to,
  onChangeParams,
  status,
  isFetchingTasks,
  handleSearch,
}) => {
  const [showTaskOptions, setShowTaskOptions] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [isReopenModalOpen, setIsReopenModalOpen] = useState(false);
  const [isReopenConfirmationModalOpen, setIsReopenConfirmationModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] = useState(false);
  const [reopenTaskId, setReopenTaskId] = useState(null);
  const [cancelTaskId, setCancelTaskId] = useState(null);
  const [reopenTaskName, setReopenTaskName] = useState(null);
  const [cancelTaskName, setCancelTaskName] = useState(null);
  const navigationLinks = useNavigationLinks();
  const [updateTask, { isLoading, isError }] = useUpdateTaskMutation();
  const [markAsDone] = useMarkAsDoneMutation();
  const { data: currentUser, isSuccess: isCurrentUserFetched } = useGetCurrentUserQuery();
  const { t, i18n } = useTranslation();

  const handleExpandedChange = id => {
    setShowTaskOptions(handleStateToggle(showTaskOptions, id));
  };

  const emphasisTagType = {
    high: 'danger',
    low: 'disabled',
    medium: 'warning',
  };

  const modalType = {
    reopen: 'reopen',
    cancel: 'cancel',
  };

  const handleStateToggle = (state, id) => {
    if (state.includes(id)) {
      return state.filter(object => object !== id);
    }
    return [...state, id];
  };

  const handleReopenTask = () => {
    updateTask({
      id: reopenTaskId,
      payload: {
        status: TASKS_STATUS.pending,
      },
    })
      .unwrap()
      .then(() => setIsReopenModalOpen(false))
      .then(() => setIsReopenConfirmationModalOpen(true));
  };

  const handleCancelTask = () => {
    updateTask({
      id: cancelTaskId,
      payload: {
        status: TASKS_STATUS.cancelled,
      },
    })
      .unwrap()
      .then(() => setIsCancelModalOpen(false))
      .then(() => setIsCancelConfirmationModalOpen(true));
  };

  useEffect(() => {
    onChangeParams({ status, page: 1 }, status, true);
  }, []);

  const modalContent = contentType => {
    if (isLoading) {
      return (
        <div className="user__invite--modal-loading">
          <Spinner size="small" color="success" bgColor="none" />
          <div className="user__invite--modal-loading-message">
            {contentType === modalType.reopen &&
              t('task.reopen_modal.confirmation.loading_description')}
            {contentType === modalType.cancel &&
              t('task.cancel_modal.confirmation.loading_description')}
          </div>
        </div>
      );
    }

    if (isError) {
      return (
        <div className="user__invite--modal-failure">
          <Icon className="user__invite--modal-failure-icon" name="invalid" size="xxl" />
          <div className="user__invite--modal-failure-message">
            {contentType === modalType.reopen && (
              <>
                <div>{t('task.reopen_modal.confirmation.failure_text')}</div>
              </>
            )}
            {contentType === modalType.cancel && (
              <>
                <div>{t('task.cancel_modal.confirmation.failure_text')}</div>
              </>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="user__invite--modal-success">
        <Icon
          className="user__invite--modal-success-icon"
          name="check"
          size="large"
          showBGColor={true}
        />
        <div className="user__invite--modal-success-message">
          {contentType === modalType.reopen && (
            <>
              <div>{t('task.reopen_modal.confirmation.success_title')}</div>
              <div>{t('task.reopen_modal.confirmation.success_description')}</div>
            </>
          )}
          {contentType === modalType.cancel && (
            <>
              <div>{t('task.cancel_modal.confirmation.success_title')}</div>
              <div>{t('task.cancel_modal.confirmation.success_description')}</div>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="task__create-search">
        <Input
          className="task__search"
          placeholder={t('tasks.search_task_placeholer')}
          preIcon={<Icon name="search" />}
          size="tiny"
          value={searchInput}
          onChange={value => {
            handleSearch(value);
            setSearchInput(value);
          }}
        />
        {isCurrentUserFetched && isBioSuisseAdmin(currentUser) && (
          <Link to={navigationLinks.taskCreatePage()}>
            <Button label={t('tasks.create_task')} type="success" size="small" />
          </Link>
        )}
      </div>
      <div>
        {isFetchingTasks ? (
          <RowLoaderPlaceHolder numberOfLines={8} />
        ) : tasks.length > 0 ? (
          tasks.map(task => (
            <Task
              className="task__container"
              key={task.id}
              dueDate={dateMonthYearFormatter(task?.dueDate)}
              isOrganisation={task?.assignedOrganizationId}
              assignee={
                task?.assignedOrganizationId ? (
                  <OrganizationName organizationId={task?.assignedOrganizationId} />
                ) : task?.assignedToAdmin ? (
                  t('shared.bio_cuisine_admins')
                ) : (
                  <UserName userID={task?.assignedUserIds[0]} />
                )
              }
              assigneeLabel={t('task.assignee')}
              createdTime={dateTimeFormatterWith24Hour(task?.created_at)}
              description={task?.description}
              id={task.id}
              isExpanded={false}
              lastUpdatedTime={dateTimeFormatterWith24Hour(task?.updated_at)}
              lastUpdatedTimeLabel={t('task.last_update')}
              name={<TaskTitle task={task} />}
              priority={
                <EmphasisTag
                  radius="oval"
                  size="tiny"
                  text={t(`tasks.priority.${task?.priority}`)}
                  type={emphasisTagType[task?.priority]}
                />
              }
              reporter={<UserInfo userData={task?.author} />}
              reporterLabel="Creator"
              locales={i18n.language}
              showTaskOptions={
                <Popover
                  className="task__popover-wrapper"
                  isVisible={
                    !isReopenModalOpen &&
                    !isCancelModalOpen &&
                    showTaskOptions.some(taskId => taskId === task.id)
                  }
                  onOutsideClick={() => setShowTaskOptions([])}
                  content={
                    <div className="task__popover">
                      {task?.status !== TASKS_STATUS.done && (
                        <div
                          className={classNames('task__popover-content', {
                            'task__popover-content--hide': task?.status === TASKS_STATUS.cancelled,
                          })}
                          onClick={() => markAsDone({ id: task?.id })}
                        >
                          <span>{t('task.mark_done')}</span>
                        </div>
                      )}
                      <Link to={navigationLinks.taskDetailsPage(task?.id)}>
                        <div className="task__popover-content">{t('task.view')}</div>
                      </Link>
                      {isBioSuisseAdmin(currentUser) && (
                        <>
                          <Link to={navigationLinks.taskEditPage(task?.id)}>
                            <div
                              className={classNames('task__popover-content', {
                                'task__popover-content--hide':
                                  task?.status === TASKS_STATUS.cancelled,
                              })}
                            >
                              {t('common.edit_button')}
                            </div>
                          </Link>

                          <div
                            className={classNames('task__popover-content', {
                              'task__popover-content--hide':
                                task?.status === TASKS_STATUS.cancelled,
                            })}
                            onClick={() => {
                              setCancelTaskId(task?.id);
                              setIsCancelModalOpen(true);
                              setCancelTaskName(parseTaskTitle(task));
                            }}
                          >
                            {t('common.cancel_button')}
                          </div>
                          <div
                            className={classNames('task__popover-content', {
                              'task__popover-content--hide':
                                task?.status !== TASKS_STATUS.cancelled,
                            })}
                            onClick={() => {
                              setReopenTaskId(task?.id);
                              setIsReopenModalOpen(true);
                              setReopenTaskName(parseTaskTitle(task));
                            }}
                          >
                            {t('tasks.reopen')}
                          </div>
                        </>
                      )}
                    </div>
                  }
                >
                  <IconButton
                    icon={<Icon name="horizontalDots" />}
                    color="default"
                    onClick={() => handleExpandedChange(task?.id)}
                    isIconOnly={true}
                  />
                </Popover>
              }
            />
          ))
        ) : (
          <EmptyContentPlaceholder text={t('tasks.no_task_found')} iconName="tasks" />
        )}
      </div>
      <WarningModal
        onOutsideClick={() => setIsReopenModalOpen(false)}
        isVisible={isReopenModalOpen}
        title={t('task.reopen_modal.title')}
        content={t('task.reopen_modal.description', {
          title: reopenTaskName,
        })}
        confirmActionText={t('shared.action.yes')}
        cancelActionText={t('shared.action.cancel')}
        onCancel={() => setIsReopenModalOpen(false)}
        onConfirm={handleReopenTask}
      />
      <WarningModal
        onOutsideClick={() => setIsCancelModalOpen(false)}
        isVisible={isCancelModalOpen}
        title={t('task.cancel_modal.title')}
        content={t('task.cancel_modal.description', {
          title: cancelTaskName,
        })}
        confirmActionText={t('shared.action.yes')}
        cancelActionText={t('shared.action.cancel')}
        onCancel={() => setIsCancelModalOpen(false)}
        onConfirm={handleCancelTask}
      />
      <ConfirmationModalWrapper
        isVisible={isReopenConfirmationModalOpen}
        contentAlign="center"
        footerAlign="center"
        content={modalContent(modalType.reopen)}
        className="user__invite--modal"
        timeOut={!isLoading ? 3000 : 0}
        onTimeout={() => {
          setIsReopenConfirmationModalOpen(false);
        }}
      />
      <ConfirmationModalWrapper
        isVisible={isCancelConfirmationModalOpen}
        contentAlign="center"
        footerAlign="center"
        content={modalContent(modalType.cancel)}
        className="user__invite--modal"
        timeOut={!isLoading ? 3000 : 0}
        onTimeout={() => {
          setIsCancelConfirmationModalOpen(false);
        }}
      />
    </>
  );
};

TaskTabContentComponent.defaultProps = {
  tasks: [],
  assigned_to: null,
  onChangeParams: () => {},
  handleSearch: () => {},
  status: 'pending',
  isFetchingTasks: false,
};

TaskTabContentComponent.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape()),
  assigned_to: PropTypes.node,
  onChangeParams: PropTypes.func,
  handleSearch: PropTypes.func,
  status: PropTypes.node,
  isFetchingTasks: PropTypes.bool,
};

export default TaskTabContentComponent;
