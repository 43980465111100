import React from 'react';
import { t } from 'i18next';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import PropTypes from 'prop-types';
import ORGANIZATION_INTERESTS from 'utils/organization-interests-mapper';
import { isNonEmptyObject } from 'utils/check-object-length';
import Card from 'components/base-components/Card';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import INTERESTS_MAPPER from './helpers/interests_mapper';

const CompanyAttributes = ({ interests, isFetching }) => {
  const availableInterests = (interests || []).reduce((accumulator, currentValue) => {
    accumulator[currentValue] = true;
    return accumulator;
  }, {});

  return (
    <Card className="organisation-overview__card super-office-details__container-element">
      {isFetching ? (
        <ContentLoaderPlaceholder numberOfLines={4} showBackground={false} />
      ) : (
        <>
          <span className="organisation-overview__card-title">
            {t('organisation.super_office.attributes.company_attributes.title')}
          </span>
          {isNonEmptyObject(ORGANIZATION_INTERESTS) ? (
            <div className="super-office-details__company-attributes--contact-wrapper">
              {Object.entries(ORGANIZATION_INTERESTS).map(([key, values]) => (
                <div key={key}>
                  <span className="super-office-details__company-attribute-header">{key}</span>
                  <ul>
                    {values.map((value, idx) => {
                      if (availableInterests[value]) {
                        return (
                          <li key={idx}>
                            {t(
                              `organisation.super_office.attributes.company_attributes.${INTERESTS_MAPPER[value]}`,
                            )}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              ))}
            </div>
          ) : (
            <EmptyContentPlaceholder
              showCard={false}
              text={t(
                'organisation.super_office.attributes.company_attributes.no_company_attributes',
              )}
              iconName="neutralOutline"
            />
          )}
        </>
      )}
    </Card>
  );
};

CompanyAttributes.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  interests: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CompanyAttributes;
