import { useGetActiveSurveyQuery } from 'api/control-body-survey';
import React from 'react';
import { useParams } from 'react-router';
import AccountingProcess from './items/accounting-process.component';
import ContractualStatus from './items/contractual-status.component';
import Information from './items/information.component';
import IngredientsInStock from './items/ingredients-in-stock.component';
import LogoAttachments from './items/logo-attachments.component';
import RestrictedIngredients from './items/restricted-ingredients.component';
import ValuesOfGoods from './items/values-of-goods.component';

const NegativeReports = () => {
  const { id: organizationId } = useParams();
  const { data: activeSurvey, isFetching } = useGetActiveSurveyQuery({ organizationId });

  if (isFetching) return null;

  return (
    <>
      <LogoAttachments surveyId={activeSurvey.id} />
      <IngredientsInStock surveyId={activeSurvey.id} />
      <RestrictedIngredients surveyId={activeSurvey.id} />
      <ValuesOfGoods surveyId={activeSurvey.id} />
      <ContractualStatus organizationId={organizationId} date={activeSurvey.lastSubmittedAt} />
      <AccountingProcess surveyId={activeSurvey.id} />
      <Information surveyId={activeSurvey.id} />
    </>
  );
};

export default NegativeReports;
