import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import { dateMonthYearFormatter } from 'utils/date-time-formatter';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableData,
} from 'components/base-components/Table';
import {
  useGetSupplierCertificateQuery,
  useDeleteSupplierCertificateMutation,
} from 'api/supplier-certificates';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import EmphasisTag from 'components/base-components/EmphasisTag';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { useGetCurrentUserQuery } from 'api/users';
import SUPPLIER_CERTIFICATE_TYPE from 'utils/supplier-certificate-type';
import EditForm from './edit/edit.component';
import WarningModal from './../shared-components/modal/warning';

const SupplierCertificateTableRow = ({ certificateDeclaration }) => {
  const [enableModal, setEnableModal] = useState(false);
  const { t } = useTranslation();
  const { data: currentUser } = useGetCurrentUserQuery();
  const isAdmin = isBioSuisseAdmin(currentUser);
  const { isFetching, data: supplierCertificate = {} } = useGetSupplierCertificateQuery({
    organizationId: certificateDeclaration.organizationId,
    certificateId: certificateDeclaration.certificateId,
  });
  const [deleteSupplierCertificate] = useDeleteSupplierCertificateMutation();

  const complaint = certificateDeclaration.complaint;

  const onDeleteSupplierCertificate = () => {
    const params = {
      organizationId: certificateDeclaration.organizationId,
      certificateId: certificateDeclaration.certificateId,
    };

    deleteSupplierCertificate(params);
  };

  return (
    <Fragment>
      <WarningModal
        onOutsideClick={() => {
          setEnableModal(false);
        }}
        isVisible={enableModal}
        title={t('supplier_certificates.complain')}
        content={
          <div className="supplier-certificates__complain">
            <span className="supplier-certificates__complain-header">
              {complaint && complaint.title}
            </span>
            <span className="supplier-certificates__complain-body">
              {complaint && complaint.description}
            </span>
          </div>
        }
        confirmActionText={t('shared.action.okay')}
        cancelActionText={t('shared.action.cancel')}
        onCancel={() => setEnableModal(false)}
        onConfirm={() => setEnableModal(false)}
      />
      <TableRow className="supplier-certificates__row">
        <TableData>
          <div className="supplier-certificates__row-header">
            <span>{supplierCertificate.name}</span>
            {complaint && (
              <Fragment>
                <EmphasisTag
                  type="warning"
                  size="tiny"
                  text={
                    <div className="product-declarations__complain-view--tag-wrapper">
                      {t('product_declarations.status.complained')}
                      <div className="product-declarations__complain-view--vertical-line" />
                      <IconButton
                        icon={<Icon name="show" />}
                        size="tiny"
                        isIconOnly={true}
                        onClick={() => setEnableModal(true)}
                      />
                    </div>
                  }
                />
              </Fragment>
            )}
          </div>
        </TableData>
        <TableData>{supplierCertificate.certificationIdentifier}</TableData>
        <TableData>
          {supplierCertificate.certificationDate
            ? dateMonthYearFormatter(supplierCertificate.certificationDate)
            : t('shared.tbd')}
        </TableData>
        <TableData>
          {certificateDeclaration.declarationDate
            ? dateMonthYearFormatter(certificateDeclaration.declarationDate)
            : t('shared.tbd')}
        </TableData>
        <TableData align="center">
          <a href={supplierCertificate.certificateUrl} target="_blank" rel="noreferrer">
            <IconButton
              className="supplier-certificates__row--download-icon"
              icon={<Icon name="downloadFile" size="small" />}
              color="default"
              disabled={!supplierCertificate.diplomaForEducationUrl}
              isIconOnly={true}
            />
          </a>
        </TableData>
        {certificateDeclaration?.status !== SUPPLIER_CERTIFICATE_TYPE.approved && (
          <TableData>
            {!isFetching && (
              <div className="supplier-certificates__row-actions">
                <EditForm
                  organizationId={certificateDeclaration.organizationId}
                  supplierCertificate={supplierCertificate}
                  disabled={!certificateDeclaration.modifiable || isAdmin}
                />
                <IconButton
                  icon={<Icon name="delete" size="small" color="danger" />}
                  color="danger"
                  onClick={onDeleteSupplierCertificate}
                  disabled={!certificateDeclaration.modifiable || isAdmin}
                />
              </div>
            )}
          </TableData>
        )}
      </TableRow>
    </Fragment>
  );
};

SupplierCertificateTableRow.propTypes = {
  certificateDeclaration: PropTypes.shape({
    organizationId: PropTypes.number.isRequired,
    certificateId: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    complaint: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
    declarationDate: PropTypes.string.isRequired,
    modifiable: PropTypes.bool.isRequired,
  }).isRequired,
  declarationType: PropTypes.string.isRequired,
};

const SupplierCertificateTable = ({ certificateDeclarations }) => {
  const { t } = useTranslation();

  return (
    <>
      {certificateDeclarations?.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader>{t('supplier_certificates.attributes.supplier_name')}</TableHeader>
              <TableHeader>{t('supplier_certificates.attributes.certificate_id')}</TableHeader>
              <TableHeader>{t('supplier_certificates.attributes.certification_date')}</TableHeader>
              <TableHeader>{t('supplier_certificates.attributes.declaration_date')}</TableHeader>
              <TableHeader align="center">
                {t('supplier_certificates.attributes.certificate')}
              </TableHeader>
              {certificateDeclarations[0].status !== SUPPLIER_CERTIFICATE_TYPE.approved && (
                <TableHeader align="center">{t('shared.actions')}</TableHeader>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {certificateDeclarations.map(certificateDeclaration => (
              <SupplierCertificateTableRow certificateDeclaration={certificateDeclaration} />
            ))}
          </TableBody>
        </Table>
      ) : (
        <EmptyPlaceholder
          emptyCardText={t('supplier_certificates.no_current_declaration')}
          emptyIcon={<Icon name="add" color="tertiary" />}
        />
      )}
    </>
  );
};

SupplierCertificateTable.defaultProps = {
  certificateDeclarations: [],
};
SupplierCertificateTable.propTypes = {
  certificateDeclarations: PropTypes.arrayOf(PropTypes.shape()),
};

export default SupplierCertificateTable;
