const INTERESTS_MAPPER = {
  Kleinbauerngruppe: 'smallFarmersGroup',
  Hofverarbeiter: 'farmProcessor',
  Direktvermarktung: 'directMarketing',
  'Knospe-Komponenten-Küche': 'budComponentsKitchen',
  'MO Lizenznehmende': 'MOLicensee',
  'Betrieb mit Social Media Account': 'operationWithSocialMediaAccount',
  'Betrieb in Abklärung bei QS': 'operationInClarificationAtQS',
  'Kunde Ecert Web': 'customerEcertWeb',
  'Supply Chain Monitor': 'supplyChainMonitor',
  Lohnverarbeiter: 'contractProcessor',
  Alpbetrieb: 'alpineOperation',
  Sammelstelle: 'collectionPoint',
  Importeur: 'importer',
  'Knospe-Produkte-Küche': 'budProductsKitchen',
  'Mitarbeiter Importeur': 'employeeImporter',
  Lehrbetrieb: 'teachingCompany',
  'Kommunale Erholungs- und Grünflächen': 'communalGreenSpacesAndRecreationalAreas',
  'Schnittblumen Produzenten': 'cutFlowerProducers',
  'Import - anonymisierter Betrieb': 'importAnonymizedOperation',
  'Bildung & Beratung': 'educationAndAdvice',
  'Hofverarbeiter Brot': 'farmProcessorBread',
  Mischfutterhersteller: 'compoundFeedManufacturer',
  'Knospe-Küche': 'budKitchen',
  Stadtgärtnerei: 'cityGardening',
  'Private Erholungs- und Grünflächen': 'privateRecreationalAndGreenSpaces',
  'Öffentlich zugängliche Grünflächen': 'publiclyAccessibleGreenSpaces',
  'Co-Branding': 'coBranding',
  Fachhändler: 'specialistDealer',
  'Supply Chain Monitor Administrator': 'supplyChainMonitorAdministrator',
  Grossmetzgerei: 'largeButchersShop',
  'Öffentlich Erholungs- und Grünflächen': 'publicRecreationalAndGreenSpaces',
  farmProduction: 'farmProduction',
  directMarketing: 'directMarketing',
  teachingFarm: 'teachingFarm',
  'Imkerei (landlos)': 'beekeepingLandless',
  Kälbermäster: 'calfFattener',
  'Imkerei (Produzent)': 'beekeepingProducer',
  Schweinemäster: 'pigFattener',
  'Vermarktung Fleisch': 'marketingMeat',
  '2022 Registrierung für Bio Suisse Milch Pflichtmitgliedschaft':
    'registrationForBioSuisseMilk2022',
  'Bio-Weide-Beef': 'organicPastureBeef',
  'Schweinezüchter (geprüft)': 'pigBreederCertified',
  Insektenproduktion: 'insectProduction',
  'Bestätigung Pflichtmitgliedschaft Milch 2020': 'confirmationCompulsoryMembershipMilk2020',
  'Bestätigung Pflichtmitgliedschaft Milch 2018': 'confirmationCompulsoryMembershipMilk2018',
  Fischzucht: 'fishFarming',
  'Bestätigung Pflichtmitgliedschaft Milch 2019': 'confirmationCompulsoryMembershipMilk2019',
  Ziegenmilchverarbeiter: 'goatMilkProcessor',
  'Richtpreise Bio-Schlachtvieh': 'referencePricesOrganicSlaughterCattle',
  Schlachtviehhändler: 'slaughterCattleDealer',
  Schafmilchverarbeiter: 'sheepMilkProcessor',
  Büffelmilchverarbeiter: 'buffaloMilkProcessor',
  Eierhandel: 'eggTrade',
  Tierranspor: 'animalTransport',
  Kräuterproduzent: 'herbProducer',
  'Bio-Weinproduzenten': 'organicWineProducers',
  Pilzzucht: 'mushroomCultivation',
  'Brotgetreide-Verarbeiter': 'breadGrainProcessor',
  Gärtnerei: 'gardening',
  'Obstproduzent:in': 'fruitProducer',
  'Kernobst Spät-/Lagersorten': 'pomeFruitLateStorageVarieties',
  'Importeur Inlandsgetreide': 'importerOfDomesticGrain',
  'ZGM Obst befreit': 'zgmFruitExempt',
  'Kernobst Frühsorten': 'earlyPomeFruitVarieties',
  Bioweinproduzenten: 'organicWineProducers',
  'Bio-Kräuterproduzenten': 'organicHerbProducers',
  Weinkelterei: 'wineFactory',
};

export default INTERESTS_MAPPER;
