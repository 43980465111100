import React from 'react';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import TVD from './index.component';

const TVDWrapper = () => {
  const onSubmit = () => {};

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <TVD />
        </form>
      )}
    />
  );
};

export default TVDWrapper;
