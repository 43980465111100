import { useCreateOrganisationGoodsMutation } from 'api/goods-declarations';
import Button from 'components/base-components/Button';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import MonthYearPicker from 'components/base-components/MonthYearPicker';
import Tooltip from 'components/base-components/Tooltip';
import moment from 'moment/moment';
import React from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { toSnakecaseKeys } from 'utils/object-cleanup';

const GoodsFormComponent = ({ setIsSidepanelOpen }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [createGoods] = useCreateOrganisationGoodsMutation();

  const onSubmit = values => {
    const payload = { organization_id: id };

    if (values['start_date']) {
      payload['start_date'] = moment(values['start_date']).format('YYYY-MM-DD');
    }

    return createGoods({ id, payload })
      .unwrap()
      .then(() => setIsSidepanelOpen(false))
      .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
  };
  return (
    <div>
      <div className="goods-form__select-month">{t('goods.select_month_for')}</div>
      <div className="goods-form__select-good">{t('goods.goods_declaration')}</div>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Field name="start_date">
              {({ input, meta }) => (
                <div className="goods-form__month-picker">
                  <MonthYearPicker
                    onChange={e => input.onChange(e)}
                    placeholder={t('common.select')}
                    selectedMonthYear={input.value}
                    label={
                      <>
                        <span> {t('goods.select_month')}</span>
                        <span>
                          <Tooltip
                            content={t('goods.tooltip_content')}
                            type="inverse"
                            position="bottom-right"
                            size="small"
                            showArrow={true}
                          >
                            <IconButton
                              icon={<Icon name="info" size="tiny" />}
                              color="default"
                              size="tiny"
                              isIconOnly={true}
                            />
                          </Tooltip>
                        </span>
                      </>
                    }
                  />
                  <div className="task__create--form__field-error"> {meta?.submitError} </div>
                </div>
              )}
            </Field>
            <div className="goods-form__buttons">
              <Button
                label={t('common.save_button')}
                type="success"
                size="small"
                submitType="submit"
                disabled={submitting}
              />
              <Button
                className="goods-form__buttons-cancel"
                size="small"
                label={t('common.cancel_button')}
                onClick={() => setIsSidepanelOpen(false)}
              />
            </div>
          </form>
        )}
      />
    </div>
  );
};
GoodsFormComponent.propTypes = {
  setIsSidepanelOpen: PropTypes.func.isRequired,
};

export default GoodsFormComponent;
