import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Button from 'components/base-components/Button';
import Step from './step';

const Stepper = props => {
  const {
    className,
    steps,
    activeStepIndex,
    direction,
    onFinish,
    onMoveNext,
    onMoveBack,
    nextButtonLabel,
    previousButtonLabel,
    finishButtonLabel,
    disableMoveForward,
    disableMoveBackward,
    showActionButtons,
    showStepTitle,
    doneAllStep,
    isLastStepAccepted,
    additionalElement,
    hideStepsOnScroll,
    stepStates,
    isOrganizationStepper,
  } = props;
  const { t } = useTranslation();
  const stepperRef = useRef(null);
  const stepperBodyRef = useRef(null);
  const stepperFooterRef = useRef(null);

  const scrollThreshold = 138;

  const handleWindowScroll = () => {
    const currentScrollY = window.pageYOffset;
    const stepper = stepperRef.current;
    const stepperBody = stepperBodyRef.current;
    const stepperFooter = stepperFooterRef.current;

    if (currentScrollY <= scrollThreshold) {
      const opacity = 1 - currentScrollY / 100;

      stepperBody.style.opacity = opacity;
      stepper.classList.remove('stepper-sticky');
      stepperFooter.classList.remove('stepper__footer-sticky');
    } else if (currentScrollY > scrollThreshold + 1) {
      stepperBody.style.opacity = 0;
      stepper.classList.add('stepper-sticky');
      stepperFooter.classList.add('stepper__footer-sticky');
    }
  };

  useEffect(() => {
    if (hideStepsOnScroll && direction === 'horizontal') {
      window.addEventListener('scroll', handleWindowScroll);

      return () => {
        window.removeEventListener('scroll', handleWindowScroll);
      };
    }
  }, []);

  const isNextButtonVisible = () => {
    const maxStepIndex = isOrganizationStepper ? steps.length : steps.length - 1;
    return activeStepIndex < maxStepIndex;
  };

  return (
    <div ref={stepperRef} className={classNames('stepper', `stepper--${direction}`, className)}>
      {showStepTitle && direction === 'vertical' ? (
        <div className="stepper__header">
          <div className="stepper__header-step-description">
            {steps[activeStepIndex] && steps[activeStepIndex].stepName}
            {steps[activeStepIndex] &&
              steps[activeStepIndex].stepHint &&
              ` (${steps[activeStepIndex].stepHint})`}
          </div>
        </div>
      ) : null}
      <div ref={stepperBodyRef} className="stepper__body">
        {steps?.map((step, index) => (
          <Step
            stepStates={stepStates}
            key={step.stepName}
            stepName={step.stepName}
            stepIndex={index}
            isActive={index === activeStepIndex}
            isDone={index < activeStepIndex || doneAllStep}
            isLastStepAccepted={isLastStepAccepted}
          />
        ))}
      </div>
      {steps?.length > 0 && ((showStepTitle && steps[activeStepIndex]) || showActionButtons) && (
        <div ref={stepperFooterRef} className="stepper__footer">
          {showStepTitle && direction === 'horizontal' ? (
            <div className="stepper__footer-step-description">
              {hideStepsOnScroll &&
                t('organisation.form.step.current_step', { step_number: activeStepIndex + 1 })}
              {steps[activeStepIndex] && steps[activeStepIndex].stepName}
              {steps[activeStepIndex] &&
                steps[activeStepIndex].stepHint &&
                ` (${steps[activeStepIndex].stepHint})`}
            </div>
          ) : null}
          {showActionButtons && (
            <div className="stepper__footer-actions">
              {additionalElement}
              <Button
                className="stepper__footer-actions-button stepper__footer-actions-button--pre"
                version="v2"
                size="small"
                label={previousButtonLabel}
                disabled={
                  disableMoveBackward || activeStepIndex === 0 || activeStepIndex === steps.length
                }
                onClick={() => onMoveBack(activeStepIndex - 1)}
              />
              {isNextButtonVisible() && (
                <Button
                  className="stepper__footer-actions-button stepper__footer-actions-button--next"
                  version="v2"
                  size="small"
                  type="success"
                  label={nextButtonLabel}
                  disabled={disableMoveForward || activeStepIndex === steps.length - 1}
                  onClick={() => onMoveNext(activeStepIndex + 1)}
                />
              )}
              {activeStepIndex === steps.length - 1 && !isOrganizationStepper && (
                <Button
                  className="stepper__footer-actions-button stepper__footer-actions-button--finish"
                  version="v2"
                  size="small"
                  type="success"
                  label={finishButtonLabel}
                  disabled={disableMoveForward || activeStepIndex === steps.length}
                  onClick={onFinish}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Stepper.defaultProps = {
  className: null,
  direction: 'horizontal',
  finishButtonLabel: 'Finish',
  nextButtonLabel: 'Next Step',
  previousButtonLabel: 'Previous Step',
  disableMoveForward: false,
  disableMoveBackward: false,
  showActionButtons: true,
  showStepTitle: true,
  onFinish: () => {},
  onMoveBack: () => {},
  onMoveNext: () => {},
  doneAllStep: false,
  isLastStepAccepted: false,
  additionalElement: null,
  hideStepsOnScroll: false,
  stepStates: [],
  isOrganizationStepper: false,
};

Stepper.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
      stepHint: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
  ).isRequired,
  activeStepIndex: PropTypes.number.isRequired,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  finishButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  nextButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  previousButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disableMoveForward: PropTypes.bool,
  disableMoveBackward: PropTypes.bool,
  showActionButtons: PropTypes.bool,
  showStepTitle: PropTypes.bool,
  onFinish: PropTypes.func,
  onMoveBack: PropTypes.func,
  onMoveNext: PropTypes.func,
  doneAllStep: PropTypes.bool,
  isLastStepAccepted: PropTypes.bool,
  additionalElement: PropTypes.node,
  hideStepsOnScroll: PropTypes.bool,
  stepStates: PropTypes.arrayOf(PropTypes.number),
  isOrganizationStepper: PropTypes.bool,
};

export default Stepper;
