import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TabPane = props => {
  const { className, children, useTransitionOnChange } = props;
  const [awaitedChildren, setAwaitedChildren] = useState(children);
  const [opacityClass, setOpacityClass] = useState('rtabs__pane--opacity-100');

  useEffect(() => {
    if (useTransitionOnChange) {
      setOpacityClass('rtabs__pane--opacity-0');

      setTimeout(() => {
        setAwaitedChildren(children);
        setOpacityClass('rtabs__pane--opacity-100');
      }, 200);
    } else {
      setAwaitedChildren(children);
    }
  }, [children, useTransitionOnChange]);

  return (
    <div className={classNames('rtabs__pane', opacityClass, className)}>{awaitedChildren}</div>
  );
};

TabPane.defaultProps = {
  className: '',
  children: null,
  useTransitionOnChange: false,
};

TabPane.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  useTransitionOnChange: PropTypes.bool,
};

export default memo(TabPane);
