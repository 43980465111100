import routes from 'utils/routes';
import {
  SUPPLIER_CERTIFICATE,
  CERTIFICATE_DECLARATION,
  SUPPLIER_CERTIFICATE_OVERVIEW,
  ORGANISATION_OVERVIEW,
  ORGANISATION_DECLARATION_STATUS,
  ORGANISATION,
} from 'utils/tag-types';
import { apiSlice } from '../index';

const supplierCertificatesApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getSupplierCertificates: build.query({
      query: params => routes.suppliers.certificates.collectionOfOrganization(params),
      transformResponse: (response, meta, arg) => {
        return {
          certificates: response.collection,
          pagination: response.pagination,
        };
      },
      providesTags: [SUPPLIER_CERTIFICATE],
    }),
    getSupplierCertificate: build.query({
      query: params => routes.suppliers.certificates.entity(params),
      providesTags: (result, error, id) => [{ type: SUPPLIER_CERTIFICATE, id: result.id }],
    }),
    getSupplierCertificateHistory: build.query({
      query: params => routes.suppliers.certificates.collectionOfHistory(params),
      providesTags: result => [{ type: SUPPLIER_CERTIFICATE, id: result.id }],
    }),
    getSupplierCertificatesOverview: build.query({
      query: params => routes.suppliers.certificates.overview(params),
      providesTags: [SUPPLIER_CERTIFICATE_OVERVIEW],
    }),
    createSupplierCertificate: build.mutation({
      query: arg => ({
        url: routes.suppliers.certificates.collectionOfOrganization(arg.queryParams),
        method: 'POST',
        body: arg.body,
      }),
      invalidatesTags: result => (result ? [CERTIFICATE_DECLARATION] : []),
    }),
    updateSupplierCertificate: build.mutation({
      query: arg => ({
        url: routes.suppliers.certificates.entity(arg.queryParams),
        method: 'PATCH',
        body: arg.body,
      }),
      invalidatesTags: (result, error, id) => [{ type: SUPPLIER_CERTIFICATE, id: result.id }],
    }),
    deleteSupplierCertificate: build.mutation({
      query: params => ({
        url: routes.suppliers.certificates.entity(params),
        method: 'DELETE',
        body: params,
      }),
      invalidatesTags: [CERTIFICATE_DECLARATION],
    }),
    createSupplierCertificateComplaint: build.mutation({
      query: params => ({
        url: routes.suppliers.certificates.complaints(params),
        method: 'POST',
        body: params,
      }),
      invalidatesTags: (result, error, id) =>
        !error ? [CERTIFICATE_DECLARATION, SUPPLIER_CERTIFICATE_OVERVIEW] : [],
    }),
    getSupplierCertificateDeclarations: build.query({
      query: params => routes.suppliers.declarations.collectionOfOrganization(params),
      transformResponse: (response, meta, arg) => {
        return {
          declarations: response.collection,
          pagination: response.pagination,
        };
      },
      providesTags: [CERTIFICATE_DECLARATION],
    }),
    declareSupplierCertificateDeclarations: build.mutation({
      query: params => ({
        url: routes.suppliers.declarations.declare(params),
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: [CERTIFICATE_DECLARATION, SUPPLIER_CERTIFICATE_OVERVIEW],
    }),
    approveSupplierCertificateDeclarations: build.mutation({
      query: params => ({
        url: routes.suppliers.declarations.approve(params),
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: [
        CERTIFICATE_DECLARATION,
        SUPPLIER_CERTIFICATE_OVERVIEW,
        ORGANISATION,
        ORGANISATION_OVERVIEW,
        ORGANISATION_DECLARATION_STATUS,
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSupplierCertificateDeclarationsQuery,
  useGetSupplierCertificateQuery,
  useGetSupplierCertificateHistoryQuery,
  useCreateSupplierCertificateMutation,
  useUpdateSupplierCertificateMutation,
  useDeclareSupplierCertificateDeclarationsMutation,
  useGetSupplierCertificatesQuery,
  useCreateSupplierCertificateComplaintMutation,
  useApproveSupplierCertificateDeclarationsMutation,
  useDeleteSupplierCertificateMutation,
  useGetSupplierCertificatesOverviewQuery,
} = supplierCertificatesApi;
