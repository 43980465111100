import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import WelcomePageComponent from 'components/welcomepage/welcome-page.component';
import Input from 'components/base-components/Input';
import Button from 'components/base-components/Button';
import ConfirmationModalWrapper from 'components/users/ConfirmationModalWrapper';
import Spinner from 'components/base-components/Spinner/Spinner';
import Alert from 'components/base-components/Alert';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import './styles.scss';

const SetPasswordForm = ({ onSave, isSuccess }) => {
  const [modifyPasswordModalOpen, setModifyPasswordModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useCustomNavigate();
  const { t } = useTranslation();

  const onPasswordSet = values => {
    const params = {
      ...values,
      verify_token: searchParams.get('token'),
    };

    return onSave(params)
      .unwrap()
      .then(() => setModifyPasswordModalOpen(true))
      .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
  };

  const modalContent = () => {
    return (
      <div className="user__invite--modal-loading">
        <Spinner size="small" color="success" bgColor="none" />
        <div className="user__invite--modal-loading-message">
          {t('user_reset.modal.success_description')}
        </div>
      </div>
    );
  };

  return (
    <WelcomePageComponent>
      <Form
        onSubmit={onPasswordSet}
        render={({ handleSubmit, submitErrors, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="set-password__form">
              <div className="set-password__form-error">
                <Alert
                  isVisible={!!submitErrors?.base || submitErrors?.inactive}
                  hideClose={true}
                  type="danger"
                  isIconVisible={false}
                >
                  {submitErrors?.base || submitErrors?.inactive}
                </Alert>
              </div>
              <div className="set-password__form-password">
                <Field name="password">
                  {({ input, meta }) => (
                    <Input
                      label={t('signup.form.password')}
                      placeholder={t('signup.form.password_placeholder')}
                      type="password"
                      error={meta.submitError}
                      touched={!meta.dirtySinceLastSubmit}
                      {...input}
                    />
                  )}
                </Field>
              </div>
              <div className="set-password__form-password">
                <Field name="password_confirmation">
                  {({ input, meta }) => (
                    <Input
                      label={t('signup.form.confirm_password')}
                      placeholder={t('signup.form.password_placeholder')}
                      type="password"
                      touched={!meta.dirtySinceLastSubmit}
                      error={meta.submitError}
                      {...input}
                    />
                  )}
                </Field>
              </div>
              <Button
                label={t('signup.form.action.submit')}
                size="normal"
                type="success"
                className="set-password__form-button"
                submitType="submit"
                disabled={!(values.password && values.password_confirmation)}
              />
            </div>
          </form>
        )}
      />

      <ConfirmationModalWrapper
        isVisible={modifyPasswordModalOpen}
        contentAlign="center"
        footerAlign="center"
        content={modalContent()}
        className="user__invite--modal"
        timeOut={3000}
        onTimeout={() => {
          setModifyPasswordModalOpen(false);
          if (isSuccess) navigate.openRootPage();
        }}
      />
    </WelcomePageComponent>
  );
};

SetPasswordForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  isSuccess: PropTypes.bool.isRequired,
};

export default SetPasswordForm;
