import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Content = props => {
  const { align, children, className } = props;

  return (
    <div className={classNames('modal__content', `modal__content--${align}`, className)}>
      {children}
    </div>
  );
};

Content.defaultProps = {
  align: 'left',
  children: null,
  className: null,
};

Content.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Content;
