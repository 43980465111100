import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

const placementMap = {
  bottom: 'bottom',
  top: 'top',
  left: 'bottom-right',
  right: 'bottom-left',
};

const Radio = props => {
  const {
    label,
    size,
    disabled,
    id,
    name,
    hint,
    hintPlacement,
    isChecked,
    onChange,
    withCheckIcon,
  } = props;
  const uid = useMemo(() => uuidv4(), []);
  const radioId = id || uid;
  const radioName = name || 'radio_input_value';

  const radioInputCheckboxWrapper = (
    <>
      <input
        className="rcl-radio__input"
        id={radioId}
        name={radioName}
        type="radio"
        checked={isChecked}
        onChange={onChange}
        readOnly={true}
      />
      <div
        className={classNames('rcl-radio-circle', {
          'rcl-radio-circle--checked': isChecked && !withCheckIcon,
          'rcl-radio-circle__with-check-icon--checked': isChecked && withCheckIcon,
          'rcl-radio-circle__with-check-icon--not-checked': !isChecked && withCheckIcon,
        })}
      >
        {withCheckIcon && <Icon className="rcl-radio-circle--check-icon" name="check" />}
      </div>
    </>
  );

  return (
    <label
      className={classNames('rcl-radio', `rcl-radio--${size}`, {
        'rcl-radio--disabled': disabled,
      })}
      htmlFor={radioId}
    >
      {hint ? (
        <Tooltip
          className={`rcl-radio-circle-tooltip rcl-radio-circle-tooltip--${size}`}
          position={placementMap[hintPlacement]}
          type="accent"
          content={hint}
          showArrow={false}
        >
          {radioInputCheckboxWrapper}
        </Tooltip>
      ) : (
        radioInputCheckboxWrapper
      )}
      {label && <span className="rcl-radio__label">{label}</span>}
    </label>
  );
};

Radio.defaultProps = {
  label: null,
  size: 'small',
  disabled: false,
  hint: null,
  id: null,
  name: null,
  hintPlacement: 'bottom',
  isChecked: false,
  withCheckIcon: false,
  onChange: () => {},
};

Radio.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['large', 'huge', 'small', 'XL']),
  hintPlacement: PropTypes.oneOf(['bottom-right', 'bottom-left', 'top', 'bottom']),
  disabled: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isChecked: PropTypes.bool,
  withCheckIcon: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Radio;
