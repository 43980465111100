import { useGetProductDeclarationsQuery } from 'api/product-declarations';
import Spinner from 'components/base-components/Spinner';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PRODUCT_DECLARATION_STATUS } from 'utils/statuses';
import ProductTable from '../product-table/product-table.component';

const HistoryProductDeclarations = ({ organizationId }) => {
  const { t } = useTranslation();
  const { data, isFetching } = useGetProductDeclarationsQuery({
    organizationId: organizationId,
    type: PRODUCT_DECLARATION_STATUS.inactive,
  });

  if (isFetching)
    return (
      <Spinner
        className="product-declarations__spinner"
        bgColor="none"
        color="success"
        size="small"
      />
    );

  const items = data ? data.collection : [];

  return (
    <div className="product-declarations">
      <div className="product-declarations__content">
        {items.length > 0 ? (
          <ProductTable data={items} showActions={false} organizationId={organizationId} />
        ) : (
          <EmptyContentPlaceholder
            iconName="neutralOutline"
            text={t('shared.placeholder.no_history')}
          />
        )}
      </div>
    </div>
  );
};

HistoryProductDeclarations.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export default HistoryProductDeclarations;
