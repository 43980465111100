import Icon from 'components/base-components/Icon';
import Modal from 'components/base-components/Modal';
import Spinner from 'components/base-components/Spinner';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const LoadingModal = ({ content, ...otherProps }) => (
  <Modal className="modal__loader" {...otherProps}>
    <Modal.Title className="modal__loader-title">
      <Spinner size="small" color="success" bgColor="none" />
    </Modal.Title>
    <Modal.Content className="modal__loader--content">{content}</Modal.Content>
  </Modal>
);

const SuccessModal = ({ title, content, footer, ...otherProps }) => (
  <Modal className="modal__success" {...otherProps}>
    <Modal.Title className="modal__success-title">
      <Icon name="check" showBGColor={true} size="medium" color="success" />
      <div className="modal__success-title-header">{title}</div>
    </Modal.Title>
    <Modal.Content className="modal__success-body">{content}</Modal.Content>
    {footer && <Modal.Footer>{footer}</Modal.Footer>}
  </Modal>
);

const ErrorModal = ({ title, content, ...otherProps }) => (
  <Modal className="modal__error" {...otherProps}>
    <Modal.Title className="modal__error-title">
      <Icon name="invalid" showBGColor={true} size="medium" color="danger" />
      <div className="modal__error-title-header">{title}</div>
    </Modal.Title>
    <Modal.Content className="modal__error-body">{content}</Modal.Content>
  </Modal>
);

const SubmitModal = ({
  isLoading,
  isSuccess,
  isError,
  requestId,
  onSuccess,
  onFailed,
  loaderContent,
  successTitle,
  successContent,
  errorTitle,
  errorContent,
  showLoader,
  showSuccess,
  showError,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (requestId) setIsVisible(true);
  }, [requestId]);

  const onTimeout = () => {
    setIsVisible(false);
    if (isSuccess) onSuccess();
    if (isError) onFailed();
  };

  const props = () => {
    if (isSuccess || isError) return { isVisible, onTimeout, timeout: 3000 };

    return { isVisible };
  };

  if (showLoader && isLoading) {
    return <LoadingModal content={loaderContent} {...props()} />;
  }

  if (showSuccess && isSuccess) {
    return <SuccessModal title={successTitle} content={successContent} {...props()} />;
  }
  if (showError && isError) {
    return <ErrorModal title={errorTitle} content={errorContent} {...props()} />;
  }

  return null;
};

SubmitModal.defaultProps = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  onSuccess: () => {},
  onFailed: () => {},
  showLoader: true,
  showSuccess: true,
  showError: true,
  loaderContent: '',
  successTitle: '',
  successContent: '',
  errorTitle: '',
  errorContent: '',
  requestId: null,
};

SubmitModal.propTypes = {
  loaderContent: PropTypes.string,
  successTitle: PropTypes.string,
  successContent: PropTypes.string,
  errorTitle: PropTypes.string,
  errorContent: PropTypes.string,
  onSuccess: PropTypes.func,
  onFailed: PropTypes.func,
  showLoader: PropTypes.bool,
  showSuccess: PropTypes.bool,
  showError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  requestId: PropTypes.string,
};

LoadingModal.defaultProps = {
  title: '',
  content: '',
  modalProps: {},
};

LoadingModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  modalProps: PropTypes.shape(),
};

SuccessModal.defaultProps = {
  title: '',
  content: '',
  footer: null,
  modalProps: {},
};

SuccessModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  modalProps: PropTypes.shape(),
  footer: PropTypes.node,
};

ErrorModal.defaultProps = {
  title: '',
  content: '',
  modalProps: {},
};

ErrorModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  modalProps: PropTypes.shape(),
};

export { ErrorModal, SuccessModal, LoadingModal };

export default SubmitModal;
