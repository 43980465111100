import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Badge.styles.scss';

class Badge extends React.Component {
  badgeType = type => {
    if (type === 'inactive') return 'badge--inactive';
    if (type === 'active') return 'badge--active';
    if (type === 'new') return 'badge--new';
    if (type === 'warning') return 'badge--warning';

    return null;
  };

  badgeSize = size => {
    if (size === 'small') return 'badge--small';
    if (size === 'normal') return 'badge--normal';

    return null;
  };

  getTheNumber = label => {
    return label > 99 ? `${99}+` : label;
  };

  render() {
    const { type, size, classNameForBadge, label } = this.props;

    return (
      <div
        className={classNames('badge', `badge--${type}`, `badge--${size}`, classNameForBadge)}
        role="presentation"
      >
        {label && (
          <span className={classNames('badge__number', `badge__number--${size}`)}>
            {this.getTheNumber(label)}
          </span>
        )}
      </div>
    );
  }
}

Badge.defaultProps = {
  classNameForBadge: null,
  label: null,
  type: 'new',
  size: 'small',
};

Badge.propTypes = {
  classNameForBadge: PropTypes.string,
  label: PropTypes.number,
  type: PropTypes.oneOf(['inactive', 'active', 'new', 'warning', 'default']),
  size: PropTypes.oneOf(['small', 'normal']),
};

export default Badge;
