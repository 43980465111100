import { apiSlice } from 'api';
import routes from 'utils/routes';
import { CONTROL_BODY_SURVEY, RESTRICTED_INGREDIENTS_ADDITIONAL_TASK } from 'utils/tag-types';

const restrictedIngredientsApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getActiveRestrictedIngredientsAdditionalTask: build.query({
      query: params => routes.restrictedIngredients.active(params),
      providesTags: [RESTRICTED_INGREDIENTS_ADDITIONAL_TASK],
    }),
    declareRestrictedIngredientsAdditionalTask: build.mutation({
      query: ({ surveyId, payload }) => ({
        url: routes.restrictedIngredients.declare({ surveyId }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, id) =>
        !error ? [RESTRICTED_INGREDIENTS_ADDITIONAL_TASK] : [],
    }),
    approveRestrictedIngredientsAdditionalTask: build.mutation({
      query: ({ surveyId, payload }) => ({
        url: routes.restrictedIngredients.approve({ surveyId }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, id) =>
        !error ? [RESTRICTED_INGREDIENTS_ADDITIONAL_TASK, CONTROL_BODY_SURVEY] : [],
    }),
    getRestrictedIngredientAdditionalPhotos: build.query({
      query: params => routes.restrictedIngredients.getRestrictedIngredientAdditionalPhotos(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetActiveRestrictedIngredientsAdditionalTaskQuery,
  useDeclareRestrictedIngredientsAdditionalTaskMutation,
  useApproveRestrictedIngredientsAdditionalTaskMutation,
  useGetRestrictedIngredientAdditionalPhotosQuery,
} = restrictedIngredientsApi;
