import { useComplaintProductMutation } from 'api/product-declarations';
import Button from 'components/base-components/Button';
import Icon from 'components/base-components/Icon';
import SidePanel from 'components/base-components/SidePanel';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { removeEmptyFromObject, toSnakecaseKeys } from 'utils/object-cleanup';
import ProductComplaintForm from './product-complaint-form.component';

const ProductComplaint = ({ organizationId, isDisabled }) => {
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const { t } = useTranslation();
  const [createProductComplaint, complainStatus] = useComplaintProductMutation();

  const onSave = values => {
    const params = {
      productId: values?.product?.value,
      organizationId,
    };

    const payload = removeEmptyFromObject({
      title: values.title,
      description: values.description,
    });

    return createProductComplaint({ params, payload })
      .unwrap()
      .then(() => setSidePanelOpen(false))
      .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
  };

  return (
    <>
      <SubmitModal
        isSuccess={complainStatus.isSuccess}
        requestId={complainStatus.requestId}
        successTitle={t('product_declarations.modal.complain_successful.title')}
        successContent={t('product_declarations.modal.complain_successful.content')}
        showError={false}
        showLoader={false}
      />
      <SidePanel
        title={t('product_declarations.raise_complaint')}
        closeIcon={<Icon name="close" />}
        body={
          sidePanelOpen && (
            <ProductComplaintForm
              onSave={onSave}
              onCancel={() => setSidePanelOpen(false)}
              organizationId={organizationId}
            />
          )
        }
        isOpened={sidePanelOpen}
        onClose={() => {
          setSidePanelOpen(false);
        }}
      />
      <Button
        label={t('product_declarations.raise_a_complaint')}
        size="small"
        disabled={isDisabled}
        onClick={() => setSidePanelOpen(true)}
      />
    </>
  );
};

ProductComplaint.propTypes = {
  organizationId: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default ProductComplaint;
