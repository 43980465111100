import React from 'react';
import { useGetContractsQuery } from 'api/contract';
import Icon from 'components/base-components/Icon';
import Link from 'components/base-components/Link';
import Pagination from 'components/base-components/Pagination';
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/base-components/Table';
import { dateMonthYear } from 'utils/date-time-formatter';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import UserName from 'components/users/user-name.component';
import { t } from 'i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { TERMINATED } from 'utils/signed-contract-statuses';

const ContractHistory = () => {
  const { id: organisationId } = useParams();
  const { data } = useGetContractsQuery({ organisationId, 'statuses[]': [TERMINATED] });
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });

  const currentPage = parseInt(searchParams.get('page'), 10);

  const previousContracts = data?.collection || [];

  return (
    <div className="organisation-contract__wrapper">
      {previousContracts.length > 0 ? (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>{t('contract.attributes.upload_date')}</TableHeader>
                <TableHeader>{t('contract.attributes.contract_type')}</TableHeader>
                <TableHeader>{t('contract.attributes.termination_date')}</TableHeader>
                <TableHeader>{t('contract.attributes.terminated_by')}</TableHeader>
                <TableHeader>{t('contract.attributes.termination_reason')}</TableHeader>
                <TableHeader>{t('contract.attributes.action')}</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {previousContracts.map((history, index) => (
                <TableRow key={index}>
                  <TableData>
                    {history.contract?.uploadedAt
                      ? dateMonthYear(history.contract?.uploadedAt)
                      : t('common.not_applicable')}
                  </TableData>
                  <TableData>{history.contractType || t('common.not_applicable')}</TableData>
                  <TableData>
                    {history.terminatedAt
                      ? dateMonthYear(history.terminatedAt)
                      : t('common.not_applicable')}
                  </TableData>
                  <TableData>
                    {<UserName userID={history.terminatorId} /> || t('common.not_applicable')}
                  </TableData>
                  <TableData>{history.terminationReason || t('common.not_applicable')}</TableData>
                  <TableData>
                    <Link
                      href={history.contract.url}
                      modifier="icon-button"
                      type="anchor"
                      openNewTab={true}
                    >
                      <Icon name="download" color="tertiary" />
                    </Link>
                  </TableData>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            onPaginationClick={newPage => {
              setSearchParams({ tab: searchParams.get('tab'), page: newPage });
            }}
            totalPages={data && parseInt(data.pagination.totalPages, 10)}
            currentPage={currentPage}
            nextText={t('pagination.next')}
            previousText={t('pagination.prev')}
            firstText={t('pagination.first')}
            lastText={t('pagination.last')}
          />
        </>
      ) : (
        <EmptyPlaceholder
          emptyCardText={t('goods.empty_history')}
          emptyIcon={<Icon name="add" color="tertiary" />}
        />
      )}
    </div>
  );
};

export default ContractHistory;
