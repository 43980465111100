import React from 'react';
import PropTypes from 'prop-types';
import { useGetOrganizationAssignmentsQuery } from 'api/organizations';
import Icon from 'components/base-components/Icon';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import { t } from 'i18next';
import { useParams } from 'react-router';
import { OWNER } from 'utils/users-role';
import { Trans } from 'react-i18next';
import ModeOfCommunication from '../mode-of-communication.component';
import AddressOverview from './address-overview.component';
import ContactOverview from './contact-overview.component';
import OverviewHeader from './overview-header.component';

const GastronomyOverview = ({ organisation, isFetching, isSuccess }) => {
  const { id } = useParams();
  const { data: owners } = useGetOrganizationAssignmentsQuery({ organizationId: id, role: OWNER });

  const mainAddress = organisation?.addresses?.find(address => address.main === true);
  const billingAddress = organisation?.addresses?.find(address => address.type === 'invoice');
  const mailingAddress = organisation?.addresses?.find(address => address.type === 'mailing');
  const visitorAddress = organisation?.addresses?.find(address => address.type === 'visitor');

  return (
    <div className="grid">
      <OverviewHeader isFetching={isFetching} organisation={organisation} owners={owners} />
      <div className="grid col-12 col-bleed direction-row organisation-overview__content">
        <div className="col-4 col-bleed">
          <AddressOverview
            address={mainAddress}
            type={t('organisation_view.overview.main_address')}
            isFetching={isFetching}
            isAddressPresent={mainAddress}
            emptyPlaceholderTranslationKey="organisation_view.overview.no_main_address_found"
          />
        </div>
        <div className="col-4 col-bleed-y">
          <AddressOverview
            address={mailingAddress}
            type={t('organisation_view.overview.mailing_address')}
            isFetching={isFetching}
            isAddressPresent={mailingAddress}
            emptyPlaceholderTranslationKey="organisation_view.overview.no_mailing_address_found"
          />
        </div>
        <div className="col-4 col-bleed">
          <AddressOverview
            address={billingAddress}
            type={t('organisation_view.overview.invoice_mailing_address')}
            isFetching={isFetching}
            isAddressPresent={billingAddress}
            emptyPlaceholderTranslationKey="organisation_view.overview.no_billing_address_found"
          />
        </div>
        <div className="col-4 col-bleed">
          <AddressOverview
            address={visitorAddress}
            type={t('organisation_view.overview.visitor_address')}
            isFetching={isFetching}
            isAddressPresent={visitorAddress}
            emptyPlaceholderTranslationKey="organisation_view.overview.no_visitor_address_found"
          />
        </div>
        <div className="col-6 col-bleed-y">
          <ContactOverview organisation={organisation} isFetching={isFetching} />
        </div>
        <div className="col-2 col-bleed">
          {isSuccess ? (
            <ModeOfCommunication organisation={organisation} isFetching={isFetching} />
          ) : (
            <EmptyPlaceholder
              emptyCardText={
                <Trans i18nKey="organisation_view.overview.no_billing_address_found" />
              }
              emptyIcon={<Icon name="add" color="tertiary" />}
            />
          )}
        </div>
      </div>
    </div>
  );
};

GastronomyOverview.propTypes = {
  organisation: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
};

export default GastronomyOverview;
