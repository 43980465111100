import React from 'react';
import { useParams } from 'react-router-dom';
import EditForm from '../components/organisations/edit/index.component';

const EditOrganisation = () => {
  const { id } = useParams();

  return <EditForm orgID={id} />;
};

export default EditOrganisation;
