import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DefaultSpinner from 'components/shared-components/default-spinner';
import Card from '../../../base-components/Card';
import CreateForm from './create/create.component';
import EducationDeclarationTable from './table.component';

const EducationDeclarations = ({
  educationAssignee,
  isFetching,
  educationDeclarations,
  currentUser,
  isAdmin,
}) => {
  const { t } = useTranslation();

  if (isFetching) {
    return <DefaultSpinner className='default-spinner--center'/>;
  }

  if (!(educationDeclarations.length > 0) && !isAdmin) {
    return (
      <Card className="organization-education__declarations">
        <div className="organization-education__declarations-not-created">
          {t('educations.declarations.empty')}
        </div>
        <CreateForm associatedUserId={educationAssignee.userId} />
      </Card>
    );
  }

  return (
    <Fragment>
      <EducationDeclarationTable
        educationDeclarations={educationDeclarations}
        currentUser={currentUser}
        educationAssigneeId={educationAssignee.id}
      />
      {!isAdmin && <CreateForm associatedUserId={educationAssignee.userId} />}
    </Fragment>
  );
};

EducationDeclarations.propTypes = {
  educationAssignee: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
  educationDeclarations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentUser: PropTypes.shape().isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default EducationDeclarations;
