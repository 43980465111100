import { apiSlice } from 'api';
import routes from 'utils/routes';
import { CONTROL_BODY_SURVEY, OFFLINE_SESSTIONS_ADDITIONAL_TASK } from 'utils/tag-types';

const offlineSessionsApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getOfflineSesstions: build.query({
      query: params => routes.offlineSession.collection(params),
      providesTags: [OFFLINE_SESSTIONS_ADDITIONAL_TASK],
    }),
    verifyOfflineSession: build.mutation({
      query: ({ sessionId, payload }) => ({
        url: routes.offlineSession.verify({ sessionId }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, id) =>
        !error ? [OFFLINE_SESSTIONS_ADDITIONAL_TASK, CONTROL_BODY_SURVEY] : [],
    }),
  }),
  overrideExisting: false,
});

export const { useGetOfflineSesstionsQuery, useVerifyOfflineSessionMutation } = offlineSessionsApi;
