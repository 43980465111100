const animalValidation = values => {
  return {
    otherFarms:
      values?.production?.includes('animal') &&
      (!values?.otherFarms ||
        Object.keys(values?.otherFarms)?.every(item => !values?.otherFarms?.[item])),
  };
};

export default animalValidation;
