import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../Icon';

const MenuItem = props => {
  const { linkUrl, onClick, iconName, iconPosition, children, className } = props;
  const icon = iconName && <Icon name={iconName} />;

  return (
    <li
      className={classNames(
        'rcl-menu__list-item',
        `rcl-menu__list-item--icon-${iconPosition}`,
        className
      )}
    >
      {linkUrl ? (
        <a href={linkUrl} onClick={onClick} onKeyDown={onClick} data-testid="wrapperWithLink">
          {children}
          {icon}
        </a>
      ) : (
        <span
          role="button"
          onClick={onClick}
          onKeyDown={onClick}
          tabIndex="0"
          data-testid="wrapperWithoutLink"
        >
          {children}
          {icon}
        </span>
      )}
    </li>
  );
};

MenuItem.defaultProps = {
  children: null,
  className: null,
  iconName: null,
  iconPosition: 'left',
  linkUrl: null,
  onClick: () => {},
};

MenuItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  iconName: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  linkUrl: PropTypes.string,
  onClick: PropTypes.func,
};

export default MenuItem;
