import snakecaseKeys from 'snakecase-keys';

const removeEmptyFromObject = obj => {
  for (let key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  }
  return obj;
};

const toSnakecaseKeys = obj => snakecaseKeys(obj);

export { removeEmptyFromObject, toSnakecaseKeys };
