const OPEN = 'open';
const OVERDUE = 'overdue';
const PAID = 'paid';
const PARTIALLY_PAID = 'partially_paid';
const CANCELLED = 'cancelled';
const DRAFT = 'draft';
const JOBS = 'jobs';
const FAILED = 'failed';
const SCHEDULED = 'scheduled';

export { OPEN, OVERDUE, PAID, PARTIALLY_PAID, CANCELLED, DRAFT, JOBS, FAILED, SCHEDULED };
