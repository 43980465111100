import React from 'react';
import EmailEditComponent from './email/email-edit.component';
import PhoneEditComponent from './phone/phone-edit.component';

const ContactEditComponent = () => {
  return (
    <>
      <EmailEditComponent />
      <div className="user__phone-wrapper">
        <PhoneEditComponent />
      </div>
    </>
  );
};

export default ContactEditComponent;
