import React, { Fragment } from 'react';
import { useGetActiveGoodsOverviewQuery } from 'api/goods-declarations';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import EmphasisTag from 'components/base-components/EmphasisTag';
import classNames from 'classnames';
import { monthYearShort } from 'utils/date-time-formatter';
import Icon from 'components/base-components/Icon';
import Link from 'components/base-components/Link';
import { useGetCurrentUserQuery } from 'api/users';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import DefaultSpinner from 'components/shared-components/default-spinner';
import TimePeriodComponent from './time-period.component';
import OverviewTextComponent from './overview-text.component';

const GoodsOverviewComponent = ({ onClick, organizationId, chartaEnabled }) => {
  const { t } = useTranslation();
  const {
    data: activeGoodsOverview = {},
    isFetching,
    error,
  } = useGetActiveGoodsOverviewQuery({
    organizationId,
  });

  const { data: currentUser = {} } = useGetCurrentUserQuery();

  const isAdmin = isBioSuisseAdmin(currentUser);

  if (isFetching) return <DefaultSpinner className="default-spinner--overview" />;

  const goodsNextPeriodFrom = monthYearShort(activeGoodsOverview.nextPeriodFrom);
  const goodsNextPeriodUntil = monthYearShort(activeGoodsOverview.nextPeriodUntil);

  const handleText = status => {
    if (!chartaEnabled || error) {
      return t('organisation_view.overview.charta.not_enabled');
    }
    if (activeGoodsOverview.gracePeriodEnabled) {
      return t('organisation_overview.messages.goods.grace_period_enabled', {
        validUntil: activeGoodsOverview.gracePeriod,
      });
    }
    if (status === null || status === 'new_declaration' || status === 'draft') {
      return t('organisation_overview.messages.goods.not_declared');
    } else if (status === 'submitted') {
      return t('organisation_overview.messages.shared.submitted');
    } else if (status === 'complained') {
      return t('organisation_overview.messages.shared.complained');
    } else if (status === 'approved') {
      return t('organisation_overview.messages.shared.approved', {
        goodsNextPeriodFrom,
        goodsNextPeriodUntil,
      });
    }

    return (
      <span>
        <Trans
          i18nKey="organisation_overview.messages.goods.warning"
          components={{ bold: <b /> }}
        />
      </span>
    );
  };

  const handleIconColor = status => {
    if (status === 'expired') return 'danger';
    if (activeGoodsOverview.gracePeriodEnabled === true) return 'warning';
    if (status === 'approved') return 'success';

    return null;
  };

  const handleIconName = status => {
    if (status === 'expired') return 'highPriority';
    if (status === 'approved' || activeGoodsOverview.gracePeriodEnabled === true) return 'check';

    return null;
  };

  const declarationOverview = (
    <OverviewTextComponent
      text={handleText(activeGoodsOverview.status)}
      status={activeGoodsOverview.status}
      goodsValidFrom={activeGoodsOverview.validFrom}
      goodsValidUntil={activeGoodsOverview.validUntil}
    />
  );

  const showViewDetails =
    activeGoodsOverview.allDeclared ||
    activeGoodsOverview.status === 'submitted' ||
    activeGoodsOverview.status === 'approved' ||
    activeGoodsOverview.status === 'complained';
  const startDeclaration = chartaEnabled && !showViewDetails;

  return (
    <Fragment>
      <div className="organisation-overview__title">
        <div className="organisation-overview__title-header">
          <span className="organisation-overview__title-header-text">
            {t('organisation_overview.goods')}
          </span>
          <Icon
            className={classNames('organisation-overview__title-header--icon', {
              'organisation-overview__title-header--icon__success':
                activeGoodsOverview.status === 'approved' &&
                activeGoodsOverview.gracePeriodEnabled === false,
              'organisation-overview__title-header--icon__submitted':
                activeGoodsOverview.status === 'submitted',
              'organisation-overview__title-header--icon__danger':
                activeGoodsOverview.status === 'expired',
              'organisation-overview__title-header--icon__warning':
                activeGoodsOverview.gracePeriodEnabled === true,
            })}
            size="xs"
            name={handleIconName(activeGoodsOverview.status)}
            color={handleIconColor(activeGoodsOverview.status)}
            showBGColor={true}
          />
        </div>
        {activeGoodsOverview.status === 'submitted' ||
        activeGoodsOverview.status === 'complained' ? (
          <EmphasisTag
            type={activeGoodsOverview.status === 'submitted' ? 'accent' : 'warning'}
            text={
              activeGoodsOverview.status === 'submitted'
                ? t('shared.submitted')
                : t('shared.complained')
            }
          />
        ) : (
          <TimePeriodComponent
            validFrom={activeGoodsOverview.validFrom}
            validUntil={activeGoodsOverview.validUntil}
            status={activeGoodsOverview.status}
            showToBeDeclaredText={false}
          />
        )}
      </div>
      <div>{declarationOverview}</div>
      <div>
        <Link
          type="button"
          className="organisation-overview__details"
          size="small"
          onClick={onClick}
          modifier={startDeclaration ? 'success' : 'default'}
          disabled={startDeclaration && isAdmin}
        >
          {startDeclaration
            ? t('organisation_view.overview.start_declaration')
            : t('shared.view_details')}
        </Link>
        {activeGoodsOverview.status === 'approved' && (
          <Link
            type="button"
            className="organisation-overview__add-good"
            size="small"
            onClick={onClick}
            modifier={'success'}
          >
            {t('organisation_view.overview.goods.add_goods')}
          </Link>
        )}
      </div>
    </Fragment>
  );
};

GoodsOverviewComponent.defaultProps = {
  onClick: () => {},
  chartaEnabled: false,
};

GoodsOverviewComponent.propTypes = {
  organizationId: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  chartaEnabled: PropTypes.bool,
};

export default GoodsOverviewComponent;
