import {
  useAcceptScheduleMutation,
  useGetControlBodyScheduleQuery,
  useGetActiveSurveyQuery,
  useGetDraftControlQuery,
} from 'api/control-body-survey';
import { useGetActiveControlBodyDeclarationQuery } from 'api/control-body-declarations';
import { useGetCurrentUserQuery, useGetUserQuery } from 'api/users';
import classNames from 'classnames';
import { APPROVED } from 'utils/verification-status';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import SidePanel from 'components/base-components/SidePanel/SidePanel';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import isControlBodyMember from 'utils/is-control-body-member';
import { useTranslation } from 'react-i18next';
import { ACCEPTED } from 'utils/control-body-survey';
import { dayDifference } from 'utils/day-difference';
import { DRAFT } from 'utils/control-body-survey';
import {
  dateTimeWithMonthNameFormatterWith24Hour,
  monthYearShort,
} from 'utils/date-time-formatter';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import ControlFlowFormComponent from './control-flow-form.component';
import UpcomingControlVerificationTag from './control-verification-tag';
import LastControlFlowNavigation from './last-control-flow-navigation';

const UpcomingControlFlow = () => {
  const { id } = useParams();
  const [isSidepanelOpen, setIsSidepanelOpen] = useState(false);

  const { data: activeSurvey = {} } = useGetActiveSurveyQuery({ organizationId: id });
  const { data: controlBodyScheduleData } = useGetControlBodyScheduleQuery(
    {
      surveyId: activeSurvey.id,
    },
    {
      skip: !activeSurvey.id || activeSurvey?.status !== DRAFT,
    },
  );

  const { data: draftControl = {} } = useGetDraftControlQuery({
    organizationId: id,
  });

  const { data: contolBodyData } = useGetActiveControlBodyDeclarationQuery(id);

  const { data: proposerUser = {} } = useGetUserQuery(
    { id: controlBodyScheduleData?.proposerId },
    {
      skip: !controlBodyScheduleData?.proposerId,
    },
  );
  const { data: currentUser = {} } = useGetCurrentUserQuery();
  const [acceptSchedule] = useAcceptScheduleMutation();
  const proposerUserRole = proposerUser?.role;
  const isAdmin = isBioSuisseAdmin(currentUser);

  const handleAcceptSchedule = () => {
    acceptSchedule(
      {
        surveyId: controlBodyScheduleData?.controlBodySurveyId,
        scheduleId: controlBodyScheduleData?.id,
      },
      { skip: !controlBodyScheduleData?.id && !controlBodyScheduleData?.controlBodySurveyId },
    )
      .unwrap()
      .then(() => setIsSidepanelOpen(false));
  };

  const { t } = useTranslation();

  const controlBodySurveyQuarter = draftControl.goodsDeclarationQuarters?.map(
    quarter => `${monthYearShort(quarter.periodFrom)} - ${monthYearShort(quarter.periodUntil)}`,
  );

  const showPerformControl =
    isControlBodyMember(currentUser) && controlBodyScheduleData?.status === ACCEPTED;

  return (
    <>
      <div>
        <div className="control-flow__overview">{t('control_flow.overview')}</div>
        <Card
          className={classNames('control-flow__overview-card', {
            'control-flow__overview-card--shrink': !isControlBodyMember(currentUser),
          })}
        >
          <div className="control-flow__upcoming-control">{t('control_flow.upcoming_control')}</div>
          <div className="control-flow__wrapper">
            <div
              className={classNames({
                'control-flow__wrapper-control': isControlBodyMember(currentUser),
                'control-flow__wrapper-control--user': !isControlBodyMember(currentUser),
              })}
            >
              <div className="control-flow__wrapper-status">
                <div className="control-flow__wrapper-status--label">
                  {t('control_flow.status')}
                </div>
                <div className="control-flow__wrapper-status--value">
                  <UpcomingControlVerificationTag status={controlBodyScheduleData?.status} />
                </div>
              </div>
              <div className="control-flow__wrapper-date">
                <div className="control-flow__wrapper-date--label">{t('control_flow.date')}</div>
                <div className="control-flow__wrapper-date--value">
                  {controlBodyScheduleData?.startsAt
                    ? dateTimeWithMonthNameFormatterWith24Hour(controlBodyScheduleData?.startsAt)
                    : t('shared.not_available')}
                </div>
              </div>
              <div className="control-flow__wrapper-remaining">
                <div className="control-flow__wrapper-remaining--label">
                  {t('control_flow.days_remaining')}
                </div>
                <div className="control-flow__wrapper-remaining--value">
                  {controlBodyScheduleData?.startsAt
                    ? t('control_flow.days_remaining', {
                        count: dayDifference(controlBodyScheduleData?.startsAt, new Date()),
                      })
                    : t('shared.not_available')}
                </div>
              </div>
              <div className="control-flow__wrapper-remaining">
                <div className="control-flow__wrapper-remaining--label">
                  {t('control_flow.quarters')}
                </div>
                <div className="control-flow__wrapper-date--value">
                  {draftControl.goodsDeclarationQuarters?.length > 0
                    ? controlBodySurveyQuarter.join(', ')
                    : t('shared.not_available')}
                </div>
              </div>
              {((controlBodyScheduleData && controlBodyScheduleData?.status !== ACCEPTED) ||
                !isControlBodyMember(currentUser)) && (
                <div>
                  <Button
                    className="control-flow__btn-change-date"
                    label={
                      isControlBodyMember(currentUser)
                        ? t('control_flow.button.change_date')
                        : t('control_flow.button.reschedule')
                    }
                    size="small"
                    disabled={
                      proposerUserRole === currentUser?.role ||
                      isAdmin ||
                      controlBodyScheduleData?.status === ACCEPTED ||
                      !(controlBodyScheduleData && !showPerformControl)
                    }
                    onClick={() => setIsSidepanelOpen(true)}
                  />
                  <Button
                    label={t('control_flow.button.accept')}
                    type="success"
                    size="small"
                    onClick={handleAcceptSchedule}
                    disabled={
                      controlBodyScheduleData?.status === ACCEPTED ||
                      isAdmin ||
                      proposerUserRole === currentUser?.role ||
                      !(controlBodyScheduleData && !showPerformControl)
                    }
                  />
                </div>
              )}
              {showPerformControl && (
                <div>
                  <Button
                    className="control-flow__btn-change-date"
                    label={t('control_flow.button.change_date')}
                    size="small"
                    disabled={true}
                  />
                  <LastControlFlowNavigation
                    survey={activeSurvey}
                    disabledByParent={dayDifference(controlBodyScheduleData?.startsAt) > 0}
                  />
                </div>
              )}
            </div>
            {isControlBodyMember(currentUser) && (
              <>
                <div className="control-flow__seperator control-flow__seperator-upcoming" />
                <div className="control-flow__wrapper-report">
                  <div className="control-flow__set-control--header">
                    {t('control_flow.set_date')}
                  </div>
                  <div className="control-flow__set-control--description">
                    {t('control_flow.should_be_scheduled_desc')}
                  </div>
                  <Button
                    label={t('control_flow.date_set')}
                    type="success"
                    size="small"
                    disabled={
                      controlBodyScheduleData ||
                      currentUser?.role === 'user' ||
                      isAdmin ||
                      contolBodyData?.status !== APPROVED ||
                      activeSurvey.status
                    }
                    onClick={() => setIsSidepanelOpen(true)}
                  />
                </div>
              </>
            )}
          </div>
        </Card>
      </div>
      <SidePanel
        isOpened={isSidepanelOpen}
        title={t('control_body.sidepanel_upcoming_header')}
        body={<ControlFlowFormComponent setIsSidepanelOpen={setIsSidepanelOpen} />}
        onClose={() => setIsSidepanelOpen(false)}
        onClick={() => setIsSidepanelOpen(false)}
      />
    </>
  );
};

export default UpcomingControlFlow;
