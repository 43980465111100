import routes from 'utils/routes';
import { apiSlice } from '../index';

const countriesApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getCountries: build.query({
      query: () => routes.countries.collection(),
    }),
    getCantons: build.query({
      query: ({ code }) => routes.countries.cantons({ code }),
    }),
  }),

  overrideExisting: false,
});

export const { useGetCountriesQuery, useGetCantonsQuery } = countriesApi;
