import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import PLANT_CULTIVATION from 'utils/plant-cultivation';
import { useGetProductionQuery } from 'api/producers/productions';
import PlantCultivationContent from './shared/plant-cultivation-content.component';
import FruitData from './fruit-data.component';
import HerbsData from './herbs-data.component';

const PlantCultivationDataComponent = ({ id }) => {
  const { data: plantCultivationData } = useGetProductionQuery(
    {
      productionId: id,
    },
    { skip: !id },
  );

  const getInitialValues = subType => {
    if (!plantCultivationData) return null;
    let initialValue;
    if (subType === PLANT_CULTIVATION.fruit || subType === PLANT_CULTIVATION.herb) {
      initialValue = plantCultivationData.filter(plant => plant?.subType === subType);
      return initialValue;
    }
    initialValue = plantCultivationData.find(plant => plant?.subType === subType);

    if (!initialValue) return null;

    return initialValue.productionDetails;
  };

  const plantDatas = ['potato', 'vegetable', 'mushroom_farming', 'ornamental', 'viticulture'];

  return (
    <div className="operations__plant">
      <PlantCultivationContent
        title={t('plant_cultivation.arable_crops.title')}
        icon="arable_crop"
        values={getInitialValues(PLANT_CULTIVATION.arable_crop)}
        isCheckboxData={true}
      />
      <FruitData
        title={t('plant_cultivation.fruits.title')}
        icon="fruit"
        values={getInitialValues(PLANT_CULTIVATION.fruit)}
      />
      <HerbsData
        title={t('plant_cultivation.plant_types.herb')}
        icon="herb"
        values={getInitialValues(PLANT_CULTIVATION.herb)}
      />
      {plantDatas.map(plant => (
        <PlantCultivationContent
          title={t(`plant_cultivation.plant_types.${plant}`)}
          icon={plant}
          name={plant}
          values={getInitialValues(PLANT_CULTIVATION[plant])}
        />
      ))}
    </div>
  );
};

PlantCultivationDataComponent.defaultProps = {
  id: null,
};

PlantCultivationDataComponent.propTypes = {
  id: PropTypes.node,
};

export default PlantCultivationDataComponent;
