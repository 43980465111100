import { Tabs } from 'components/base-components/RTabs';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MyTopnavTask from './my-topnav-task.component';
import OrganisationTopnavTask from './organisation-topnav-task.component';

const TopnavTaskComponent = ({ setIsSidePanelOpen }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t } = useTranslation();

  return (
    <div className="topnav__task-wrapper">
      <Tabs
        className="topnav__task-tab"
        controlled={true}
        selectedTabKey={selectedTab}
        items={[
          {
            title: t('tasks.tab.my_tasks'),
            key: 0,
            getContent: () => <MyTopnavTask setIsSidePanelOpen={setIsSidePanelOpen} />,
          },
          {
            title: t('tasks.tab.organisation_tasks'),
            key: 1,
            getContent: () => <OrganisationTopnavTask setIsSidePanelOpen={setIsSidePanelOpen} />,
          },
        ]}
        onChange={key => setSelectedTab(key)}
      />
    </div>
  );
};

TopnavTaskComponent.defaultProps = {
  setIsSidePanelOpen: () => {},
};

TopnavTaskComponent.propTypes = {
  setIsSidePanelOpen: PropTypes.func,
};

export default TopnavTaskComponent;
