import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const NavUnderline = props => {
  const {
    className,
    containerWidth,
    showIndicator,
    indicatorWidth,
    offsetLeftOfIndicator,
    hidden,
  } = props;

  return (
    <div
      className={classNames('rtabs__nav-underline', className, {
        'rtabs__nav-underline--hide': hidden,
      })}
      style={{ width: containerWidth }}
    >
      <div
        className={classNames('rtabs__nav-underline-indicator', {
          'rtabs__nav-underline-indicator--hidden': !showIndicator,
        })}
        style={{
          transform: `translateX(${offsetLeftOfIndicator}px)`,
          width: indicatorWidth,
        }}
      />
    </div>
  );
};

NavUnderline.defaultProps = {
  className: '',
  containerWidth: '100%',
  indicatorWidth: 0,
  offsetLeftOfIndicator: 0,
  showIndicator: false,
  hidden: false,
};

NavUnderline.propTypes = {
  className: PropTypes.string,
  containerWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  indicatorWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offsetLeftOfIndicator: PropTypes.number,
  showIndicator: PropTypes.bool,
  hidden: PropTypes.bool,
};

export default memo(NavUnderline);
