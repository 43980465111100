import showdown from 'showdown';
import xss from 'xss';

const options = {
  whiteList: {
    a: ['href', 'title', 'target'],
    b: [],
    br: [],
  },
};

const FilterXSS = new xss.FilterXSS(options);

const customExtensions = () => {
  const organizationPath = (_, orgId) => `(/organisations/${orgId})`;
  const recognitionPath = (_, date) => `(/recognition/${date}?tab=unsent)`;

  const organizationUrlReplacer = {
    type: 'lang',
    regex: /\(Organization#(\d+)\)/g,
    replace: organizationPath,
  };

  const recognitionUrlReplacer = {
    type: 'lang',
    regex: /\(Recognition#(\d{4}-\d{2}-\d{2})\)/g,
    replace: recognitionPath,
  };

  const xssFilter = {
    type: 'output',
    filter: text => FilterXSS.process(text),
  };

  return [organizationUrlReplacer, recognitionUrlReplacer, xssFilter];
};

const markdownToHTML = text => {
  const converter = new showdown.Converter({
    extensions: [customExtensions],
    simpleLineBreaks: false,
    ghCodeBlocks: false,
  });

  return converter
    .makeHtml(text)
    .trim()
    .replace(/^&lt;p&gt;/, '')
    .replace(/&lt;\/p&gt;$/, '');
};

export const removeMarkdownLink = text => {
  if (!text) return null;

  return text.replace(/\[(.*?)\]\(.*?\)/g, `$1`);
};

export default markdownToHTML;
