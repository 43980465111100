import TitleComponent from 'components/organisations/form/producer/plant-cultivate/shared/title.component';
import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/base-components/Card';
import { removeEmptyFromObject, toSnakecaseKeys } from 'utils/object-cleanup';
import { t } from 'i18next';
import { isNonEmptyObject } from 'utils/check-object-length';

const HerbContent = ({ data, title }) => {
  const herbData = {
    openField: data?.productionDetails?.openFieldInAres,
    coveredCultivation: data?.productionDetails?.coveredFieldInAres,
    cultivationTime: data?.productionDetails?.cultivationPeriods,
  };

  const getCultivationPeriod = periods => {
    const cultivationPeriod = periods.map(period => t(`plant_cultivation.herbs.${period}`));

    return cultivationPeriod.join(', ');
  };

  const herbsDataToSnakeCase = toSnakecaseKeys(removeEmptyFromObject(herbData));
  const CULTIVATION_TIME = 'cultivation_time';

  return (
    <>
      {isNonEmptyObject(herbsDataToSnakeCase) && (
        <Card className="operations__production__fruit-content margin-top-10">
          <TitleComponent title={title} isOutsideForm={true} />
          <hr className="organisation-checklist__checkbox-horizontal-line" />
          {Object.keys(herbsDataToSnakeCase)?.map(key => (
            <div key={key} className="flex-space-between margin-top-10">
              <div>{t(`operations.shared.${key}`)}</div>
              <div>
                {key === CULTIVATION_TIME
                  ? getCultivationPeriod(herbsDataToSnakeCase[key])
                  : t('operations.unit.ares', { value: herbsDataToSnakeCase[key] })}
              </div>
            </div>
          ))}
        </Card>
      )}
    </>
  );
};

HerbContent.defaultProps = {
  title: null,
  data: [],
};

HerbContent.propTypes = {
  title: PropTypes.node,
  data: PropTypes.arrayOf(PropTypes.shape()),
};

export default HerbContent;
