import VerificationTag from 'components/shared-components/verification-tag';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { dayDifference } from 'utils/day-difference';
import Card from 'components/base-components/Card';
import CreateForm from '../create/create.componnent';
import ComplaintForm from './create-complaint.component';
import DeclareCertificate from './declare-certificate.component';
import ApprovedDeclaration from './approve-declaration.component';

const DraftDeclareAction = ({
  draftCertificateDeclarations,
  certificatesOverview,
  organizationId,
  isComplained,
  isApproved,
  isDeclared,
  isAdmin,
}) => {
  const { t } = useTranslation();

  if (isAdmin) {
    return (
      <>
        <div className="supplier-certificates__header-actions">
          <div className="supplier-certificates__header-actions-complain">
            <ComplaintForm
              organizationId={organizationId}
              isDisabled={!(isComplained || isDeclared)}
            />
          </div>
          <ApprovedDeclaration organizationId={organizationId} isDeclared={isDeclared} />
        </div>
        <Card className="supplier-certificates">
          <div className="supplier-certificates__eligibility">
            {t('supplier_certificates.placeholder.add_certificate')}
          </div>
          <CreateForm
            organizationId={organizationId}
            type="default"
            disabled={isAdmin || isDeclared}
          />
        </Card>
      </>
    );
  }

  if (isComplained) {
    return (
      <>
        <DeclareCertificate
          draftCertificateDeclarations={draftCertificateDeclarations}
          organizationId={organizationId}
          isComplained={isComplained}
          isDisabled={isApproved || isDeclared}
        />
        {draftCertificateDeclarations?.length === 0 && (
          <Card className="supplier-certificates">
            <div className="supplier-certificates__eligibility">
              {t('supplier_certificates.placeholder.add_certificate')}
            </div>
            <CreateForm
              organizationId={organizationId}
              type="default"
              disabled={isAdmin || isDeclared}
            />
          </Card>
        )}
      </>
    );
  }

  return (
    <>
      <div className="supplier-certificates__initial">
        <div className="supplier-certificates__initial-text">
          <div className="supplier-certificates__initial-text--wrapper">
            <div className="supplier-certificates__initial-text--header">
              {t('product_declarations.draft_declaration')}
            </div>
            <VerificationTag status={certificatesOverview.status} />
          </div>
          <div className="supplier-certificates__initial-text--description">
            {t('supplier_certificates.declaration_preparation')}
          </div>
        </div>
        <div className="supplier-certificates__initial-button">
          <div className="supplier-certificates__initial-button--text">
            {certificatesOverview &&
              certificatesOverview.validUntil &&
              t('organisation_view.overview.remaining_days', {
                days: dayDifference(certificatesOverview.validUntil, new Date()),
              })}
          </div>
          <DeclareCertificate
            draftCertificateDeclarations={draftCertificateDeclarations}
            organizationId={organizationId}
            isComplained={isComplained}
            isDisabled={isApproved || isDeclared}
          />
        </div>
      </div>
      {draftCertificateDeclarations?.length === 0 && (
        <Card className="supplier-certificates">
          <div className="supplier-certificates__eligibility">
            {t('supplier_certificates.placeholder.add_certificate')}
          </div>
          <CreateForm
            organizationId={organizationId}
            type="default"
            disabled={isAdmin || isDeclared}
          />
        </Card>
      )}
    </>
  );
};

DraftDeclareAction.defaultProps = {
  certificatesOverview: {},
  isDeclared: false,
  isComplained: false,
  isApproved: false,
  isAdmin: false,
};

DraftDeclareAction.propTypes = {
  organizationId: PropTypes.number.isRequired,
  draftCertificateDeclarations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  certificatesOverview: PropTypes.shape(),
  isDeclared: PropTypes.bool,
  isComplained: PropTypes.bool,
  isApproved: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

export default DraftDeclareAction;
