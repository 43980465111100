import { groupBy } from 'utils/groupBy';
import ADDRESS_TYPE from 'utils/address-type';
import { MOBILE, PHONE } from 'utils/contact_type';
import { t } from 'i18next';
import {
  CONTACT_AND_ADDRESS,
  CONTROL_BODY,
  FARMLAND,
  LEGAL_FORM,
  PRODUCTION_DATA,
  TVD,
} from 'utils/organisation-create-options';
import { ANIMAL, EGG, MILK } from 'utils/production-data-type';
import { MEAT_PRODUCTION } from 'utils/production-type';

const hasRequiredFields = (partner, errors) => {
  const { firstName, lastName, email } = partner;
  let isValidEmail = false;
  if (email && !errors?.organization?.assignmentsUserEmail) {
    isValidEmail = true;
  }
  return firstName && lastName && isValidEmail;
};

const getValidationErrors = (status, errors, activeIndex, values, department, TAB_MAPPER) => {
  let errorMessages = {};

  if (status === 422) {
    const generalErrors = errors.organization || errors;

    errorMessages = {
      registeredName: generalErrors.name,
      secondSpokenLanguage: generalErrors.secondSpokenLanguage,
    };

    errorMessages['subOrganizations'] = errors?.subOrganizations;

    if (TAB_MAPPER[department][activeIndex] === LEGAL_FORM) {
      const legalFormErrors = {
        operationManager: generalErrors?.operationManager,
        mainContact: generalErrors?.mainContact,
        partner: generalErrors?.legalForm || generalErrors?.numberOfPartners,
        companyType: !values?.companyType,
      };

      errorMessages = { ...errorMessages, ...legalFormErrors };

      if (values?.legalForm && values?.numberOfPartners) {
        let filteredLegalFormPartners = values?.legalFormPartners;

        if (values.numberOfPartners < values.legalFormPartners.length) {
          filteredLegalFormPartners = values.legalFormPartners.slice(0, values.numberOfPartners);
        }

        if (!filteredLegalFormPartners.every(partner => hasRequiredFields(partner, errors))) {
          errorMessages = { ...errorMessages, assignments: true };
        }

        if (values?.otherPartner && !hasRequiredFields(values?.otherPartner, errors)) {
          errorMessages = { ...errorMessages, assignments: true };
        }

        if (errors?.organization?.assignments) {
          errorMessages = { ...errorMessages, assignments: errors.organization.assignments };
        }
      }
    }

    if (TAB_MAPPER[department][activeIndex] === CONTACT_AND_ADDRESS) {
      if (errors.addresses) {
        errors.addresses.forEach(address => {
          const addressFields = {
            mainAddress: address.main,
            billingAddress: address.type === ADDRESS_TYPE.invoice,
            mailingAddress: address.type === ADDRESS_TYPE.mailing,
          };

          const errorAttributes = {
            zipCode: address.zipCode,
            city: address.city,
            country: address.countryCode,
            line1: address.line1,
            canton: address.canton,
          };

          Object.entries(addressFields).forEach(([key, value]) => {
            if (value) {
              errorMessages = { ...errorMessages, [key]: errorAttributes };
            }
          });
        });
      }

      const contactErrors = {
        preferredContact: !values.preferredContact && generalErrors?.phoneNumbers,
        phoneError: values.preferredContact === PHONE && generalErrors?.phoneNumbers,
        mobileError: values.preferredContact === MOBILE && generalErrors?.phoneNumbers,
      };

      errorMessages = { ...errorMessages, ...contactErrors };

      if (errors.phoneNumbers) {
        const errorByType = groupBy(errors.phoneNumbers, 'type');

        errorMessages = {
          ...errorMessages,
          phone: errorByType[PHONE]?.at(0)?.number,
          MOBILE: errorByType[MOBILE]?.at(0)?.number,
        };
      }
    }

    if (TAB_MAPPER[department][activeIndex] === CONTROL_BODY) {
      errorMessages = { ...errorMessages, controlBody: generalErrors?.controlBodyOrganization };
    }

    if (TAB_MAPPER[department][activeIndex] === FARMLAND) {
      const isDGVERequired = values.production?.some(item =>
        [EGG, MEAT_PRODUCTION, MILK, ANIMAL].includes(item),
      );

      if (isDGVERequired && !values?.dgve) {
        errorMessages = { ...errorMessages, dgve: t('production_error_text.missing_field') };
      }

      const productionError = values?.production?.every(data => data === null);

      if (productionError) {
        errorMessages = { ...errorMessages, production: t('production_error_text.one_product') };
      }

      if (errors.productions) {
        errorMessages = {
          ...errorMessages,
          agriculturalLandInHectare: errors.productions.agriculturalLandInHectare,
          greenlandInHectare: errors.productions.greenlandInHectare,
          openCultivatedLandInHectare: errors.productions.openCultivatedLandInHectare,
          customerSince: generalErrors.customerSince,
        };
      }
    }

    if (TAB_MAPPER[department][activeIndex] === PRODUCTION_DATA) {
      if (errors?.productions?.products) {
        errorMessages = {
          ...errorMessages,
          empty_production: t('production_error_text.one_field'),
        };
      }

      if (
        errors['productionDetails[dairyFarms][milkProducedKg]'] ||
        errors['productionDetails[dairyFarms][numberOfAnimals]']
      ) {
        errorMessages = {
          ...errorMessages,
          dairy_goat: t('production_error_text.all_field'),
        };
      }

      if (
        errors['productionDetails[dairyFarms][milkProducedKg]'] ||
        errors['productionDetails[dairyFarms][numberOfAnimals]']
      ) {
        errorMessages = {
          ...errorMessages,
          dairy_ewe: t('production_error_text.all_field'),
        };
      }

      if (
        errors['productionDetails[beeHive][honeyProducedKg]'] ||
        errors['productionDetails[beeHive][numberOfColonies]']
      ) {
        errorMessages = {
          ...errorMessages,
          bee_hive: t('production_error_text.all_field'),
        };
      }
    }

    if (TAB_MAPPER[department][activeIndex] === TVD && errors?.tvdStickerNumbers) {
      errorMessages = { ...errorMessages, tvdStickerNumbers: errors?.tvdStickerNumbers };
    }

    return errorMessages;
  }

  return errorMessages;
};

export default getValidationErrors;
