import popoverOutsideOfWindow from './popoverOutsideOfWindow';
import getSortedPositions from './getSortedPositions';

/**
 * Returns the best direction in which the popover won't go outside the window.
 *
 * @param {node} parentNode The DOM node of the popover-parent.
 * @param {string} direction The direction in which user wants to render popover.
 * @param {object} popoverDimension Popover height and width as an object {height, width}.
 * @param {string} tipSize Size of tip 'tiny' or 'small'.
 *
 * @return {string} The best direction for popover.
 */

const getDerivedPopoverDirection = (parentNode, direction, popoverDimension, tipSize) => {
  const { isVerticallyOutside, isHorizontallyOutside } = popoverOutsideOfWindow(
    parentNode,
    direction,
    popoverDimension,
    tipSize
  );
  const sortedPositionsBasedOnDirectionFromProp = getSortedPositions(direction);
  let derivedDirection = direction;

  if (isVerticallyOutside || isHorizontallyOutside) {
    sortedPositionsBasedOnDirectionFromProp.some(position => {
      const isOutsideWindow = popoverOutsideOfWindow(
        parentNode,
        position,
        popoverDimension,
        tipSize
      );

      derivedDirection = position;

      return !isOutsideWindow.isVerticallyOutside && !isOutsideWindow.isHorizontallyOutside;
    });
  }

  return derivedDirection;
};

export default getDerivedPopoverDirection;
