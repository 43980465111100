import React from 'react';
import PropTypes from 'prop-types';
import SharedTopnavTaskComponent from './shared-topnav-task.component';

const MyTopnavTask = ({ setIsSidePanelOpen }) => {
  return <SharedTopnavTaskComponent taskType="me" setIsSidePanelOpen={setIsSidePanelOpen} />;
};

MyTopnavTask.defaultProps = {
  setIsSidePanelOpen: () => {},
};

MyTopnavTask.propTypes = {
  setIsSidePanelOpen: PropTypes.func,
};

export default MyTopnavTask;
