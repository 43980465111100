/*eslint-disable */
import React, {
  useState,
  useMemo,
  memo,
  useRef,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash.throttle';
import { v4 as uuidv4 } from 'uuid';
import Icon from '../Icon';
import isTouchDevice from './helpers/isTouchDevice';
import { TabNav, TabPane, NavButton, NavUnderline } from './sub-components';

const Tabs = props => {
  const {
    className,
    items,
    onChange,
    selectedTabKey,
    useTransitionOnChange,
    controlled,
    isConditional,
  } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState(selectedTabKey < 0 ? 0 : selectedTabKey);
  const [totalScrollWidthOfUnderlineContainer, setTotalScrollWidthOfUnderlineContainer] =
    useState('100%');
  const [widthOfUnderlineIndicator, setWidthOfUnderlineIndicator] = useState(0);
  const [offsetLeftOfUnderlineIndicator, setOffsetLeftOfUnderlineIndicator] = useState(0);
  const [scrollLeftOfNavContainer, setScrollLeftOfNavContainer] = useState(0);
  const [maximumScrollLeftOfNavContainer, setMaximumScrollLeftOfNavContainer] = useState(0);
  const [sumOfTabNavWidths, setSumOfTabNavWidths] = useState(0);
  const [showIndicator, setShowIndicator] = useState(false);
  const [showNavButton, setShowNavButton] = useState(false);
  const isTouchScreen = isTouchDevice();
  const tabNavContainerRef = useRef();
  const tabNavRefs = items?.length > 0 ? items.map(() => useRef()) : [];
  const [tabNavMargin, setTabNavMargin] = useState();

  let marginArray = [];

  if (isConditional) {
    return;
  }

  const getUUIDKeys = arrayLength => {
    let keys = [];

    for (let i = 0; i <= arrayLength; i += 1) {
      const key = useMemo(() => uuidv4(), []);
      keys = [...keys, key];
    }

    return keys;
  };

  const uuidKeys = getUUIDKeys(items.length);

  const onTabNavClickHandler = useCallback(
    index => {
      if (!controlled) {
        setSelectedTabIndex(index);
      } else onChange(index);
    },
    [controlled],
  );

  useLayoutEffect(() => {
    tabNavRefs.forEach(tab => {
      const marginRightData = parseInt(
        getComputedStyle(tab.current).marginRight.match(/(\d+)/),
        10,
      );
      const marginLeftData = parseInt(getComputedStyle(tab.current).marginLeft.match(/(\d+)/), 10);
      const totalMarginPerTab = marginRightData + marginLeftData;
      marginArray = [...marginArray, totalMarginPerTab];
    });

    setTabNavMargin(marginArray.reduce((total, margin) => total + margin));
  }, []);

  const getActiveIndex = controlled ? selectedTabKey : selectedTabIndex;

  const onLeftNavButtonClickHandler = useCallback(() => {
    const totalVisibleWidthOfNavContainer = tabNavContainerRef?.current.offsetWidth;

    setScrollLeftOfNavContainer(preState => {
      const newScrollLeft = preState - totalVisibleWidthOfNavContainer;

      return newScrollLeft < 0 ? 0 : newScrollLeft;
    });
  }, [tabNavContainerRef]);

  const onRightNavButtonClickHandler = useCallback(() => {
    const totalVisibleWidthOfNavContainer = tabNavContainerRef?.current.offsetWidth;

    setScrollLeftOfNavContainer(preState => {
      const newScrollLeft = preState + totalVisibleWidthOfNavContainer;

      return newScrollLeft > maximumScrollLeftOfNavContainer
        ? maximumScrollLeftOfNavContainer
        : newScrollLeft;
    });
  }, [maximumScrollLeftOfNavContainer, tabNavContainerRef]);

  useEffect(() => {
    tabNavContainerRef.current.scrollLeft = scrollLeftOfNavContainer;
  }, [scrollLeftOfNavContainer]);

  useLayoutEffect(() => {
    setTotalScrollWidthOfUnderlineContainer(
      sumOfTabNavWidths < tabNavContainerRef?.current.offsetWidth ? '100%' : sumOfTabNavWidths,
    );

    setMaximumScrollLeftOfNavContainer(
      sumOfTabNavWidths < tabNavContainerRef?.current.offsetWidth
        ? 0
        : sumOfTabNavWidths - tabNavContainerRef?.current.offsetWidth,
    );
  }, [tabNavContainerRef, tabNavRefs]);

  useLayoutEffect(() => {
    setSumOfTabNavWidths(
      tabNavRefs.reduce(
        (accumulatedWidth, tabNav) => accumulatedWidth + tabNav?.current.offsetWidth,
        0,
      ) + tabNavMargin,
    );
  }, [tabNavRefs]);

  useLayoutEffect(() => {
    if (tabNavRefs && tabNavRefs[getActiveIndex]?.current) {
      setWidthOfUnderlineIndicator(tabNavRefs[getActiveIndex].current.offsetWidth);
      setOffsetLeftOfUnderlineIndicator(tabNavRefs[getActiveIndex].current.offsetLeft);
    }
  }, [getActiveIndex, tabNavRefs]);

  useEffect(() => {
    onChange(selectedTabIndex);
  }, [selectedTabIndex]);

  useLayoutEffect(() => {
    const onWindowResizeAndScrollHandler = throttle(
      () => {
        setScrollLeftOfNavContainer(tabNavContainerRef?.current.scrollLeft);

        setTotalScrollWidthOfUnderlineContainer(
          sumOfTabNavWidths < tabNavContainerRef?.current.offsetWidth ? '100%' : sumOfTabNavWidths,
        );

        setMaximumScrollLeftOfNavContainer(
          tabNavContainerRef?.current.scrollWidth - tabNavContainerRef?.current.offsetWidth,
        );
      },
      200,
      { trailing: true },
    );

    window.addEventListener('resize', onWindowResizeAndScrollHandler);

    if (isTouchScreen) {
      tabNavContainerRef.current.addEventListener('scroll', onWindowResizeAndScrollHandler);
    } else {
      tabNavContainerRef.current.removeEventListener('scroll', onWindowResizeAndScrollHandler);
    }

    return () => {
      window.removeEventListener('resize', onWindowResizeAndScrollHandler);
      tabNavContainerRef.current.removeEventListener('scroll', onWindowResizeAndScrollHandler);
    };
  }, [sumOfTabNavWidths, tabNavContainerRef]);

  useLayoutEffect(() => {
    document.fonts.ready.then(() => {
      if (tabNavRefs && tabNavRefs[getActiveIndex]?.current) {
        setWidthOfUnderlineIndicator(tabNavRefs[getActiveIndex].current.offsetWidth);
        setOffsetLeftOfUnderlineIndicator(tabNavRefs[getActiveIndex].current.offsetLeft);
      }
      setShowIndicator(true);
      setShowNavButton(true);
    });
  }, []);

  return (
    <div
      className={classNames(
        'rtabs',
        {
          'rtabs--touchable': isTouchScreen,
        },
        className,
      )}
    >
      <div className="rtabs__nav">
        <div className="rtabs__nav-container" ref={tabNavContainerRef}>
          {items?.length > 0 &&
            items.map((item, index) => (
              <TabNav
                key={uuidKeys[index]}
                ref={tabNavRefs[index]}
                index={index}
                title={item.title}
                isDisabled={item.disabled}
                isActive={index === getActiveIndex}
                onClick={item.disabled ? () => {} : onTabNavClickHandler}
                className={classNames({
                  'rtabs__nav-item--disabled': item.disabled,
                  'rtabs__nav-item--hidden': item.hidden,
                })}
              />
            ))}
          <NavUnderline
            containerWidth={totalScrollWidthOfUnderlineContainer}
            indicatorWidth={widthOfUnderlineIndicator}
            offsetLeftOfIndicator={offsetLeftOfUnderlineIndicator}
            showIndicator={showIndicator}
            hidden={true}
          />
        </div>
        {showNavButton && (
          <NavButton
            className={classNames('rtabs__nav-button--left', {
              'rtabs__nav-button--hidden': scrollLeftOfNavContainer <= 0,
            })}
            onClick={onLeftNavButtonClickHandler}
          >
            <Icon name="arrowForward" color="tertiary" size="small" rotate={180} />
          </NavButton>
        )}
        {showNavButton && (
          <NavButton
            className={classNames('rtabs__nav-button--right', {
              'rtabs__nav-button--hidden':
                scrollLeftOfNavContainer >= maximumScrollLeftOfNavContainer,
            })}
            onClick={onRightNavButtonClickHandler}
          >
            <Icon name="arrowForward" color="tertiary" size="small" />
          </NavButton>
        )}
      </div>

      <TabPane useTransitionOnChange={useTransitionOnChange}>
        {items?.length > 0 && !items[getActiveIndex].disabled && !items[getActiveIndex].hidden
          ? items[getActiveIndex].getContent()
          : null}
      </TabPane>
    </div>
  );
};

Tabs.defaultProps = {
  className: '',
  items: [],
  selectedTabKey: 0,
  controlled: false,
  isConditional: false,
  useTransitionOnChange: false,
  onChange: () => {},
};

Tabs.propTypes = {
  className: PropTypes.string,
  selectedTabKey: PropTypes.number,
  controlled: PropTypes.bool,
  isConditional: PropTypes.bool,
  useTransitionOnChange: PropTypes.bool,
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      getContent: PropTypes.func,
      disabled: PropTypes.bool,
      hidden: PropTypes.bool,
    }),
  ),
};

export default memo(Tabs);
