import { useGetMeatFirmsQuery } from 'api/producers/productions';
import Card from 'components/base-components/Card';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import sortTitleOrder from './helpers/sortTitleOrder';
import SharedProductionComponent from './shared/shared-production.component';

const MeatProductionDataComponent = ({ id }) => {
  const { data: meatProductionCollection } = useGetMeatFirmsQuery(
    { productionId: id },
    { skip: !id },
  );

  const meatTypes = ['cow', 'pig', 'poultry', 'ewe_sheep', 'ewe_goat'];
  let meatProductionData = {};

  meatTypes.forEach(type => {
    const meatData = meatProductionCollection?.find(meat => meat.subType === type);

    if (meatData) {
      meatProductionData[type] = meatData?.productionDetails;
    }
  });

  const meatProductionDataTOSnakeCase = toSnakecaseKeys(meatProductionData);
  const OTHER_POULTRY = 'other_poultry';

  return (
    <Card className="operations__production--content">
      <div className="flex-wrap">
        {Object.entries(meatProductionDataTOSnakeCase)?.map(([key, value]) => (
          <SharedProductionComponent
            title={t(`meat_production.types.${key}`)}
            icon={key}
            isMultipleCard={true}
          >
            {sortTitleOrder(key, value)?.map(meat => (
              <div key={meat} className="flex-space-between margin-top-10">
                <div className="operations__production--meat-title">
                  {t(`operations.meat.${meat}`)}
                </div>
                {meat === OTHER_POULTRY ? (
                  <div>
                    {(value[meat] || [])
                      .map(poultry => t(`meat_production.poultry_data.${poultry}`))
                      .join(', ') || t('common.not_applicable')}
                  </div>
                ) : (
                  value[meat] || t('common.not_applicable')
                )}
              </div>
            ))}
          </SharedProductionComponent>
        ))}
      </div>
    </Card>
  );
};

MeatProductionDataComponent.defaultProps = {
  id: null,
};

MeatProductionDataComponent.propTypes = {
  id: PropTypes.node,
};

export default MeatProductionDataComponent;
