import React, { useState } from 'react';
import { useDeleteUserEmailMutation, useGetUserEmailsQuery } from 'api/users';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import EmphasisTag from 'components/base-components/EmphasisTag';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import SidePanel from 'components/base-components/SidePanel/SidePanel';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import DeleteModal from 'components/shared-components/delete-modal';
import ContactLoader from '../../loaders/ContactLoader';
import EmailForm from './email-form.component';

const EmailEditComponent = () => {
  const [isEmailSidepanelOpen, setIsEmailSidepanelOpen] = useState(false);
  const [deleteCallbackParams, setDeleteCallbackParams] = useState(null);
  const [editEmail, setEditEmail] = useState(null);
  const { userId } = useParams();
  const [deleteEmail, deleteStatus] = useDeleteUserEmailMutation();
  const { data: userEmailList, isLoading: isEmailListFetching } = useGetUserEmailsQuery(userId);
  const { t } = useTranslation();

  return (
    <>
      <DeleteModal
        modelName="email_address"
        callback={deleteEmail}
        status={deleteStatus}
        deleteCallbackParams={deleteCallbackParams}
        setDeleteCallbackParams={setDeleteCallbackParams}
      />
      <div className="user__email-header">
        <div className="user__form--title">{t('user_edit.email_title')}</div>
        <Button
          type="success"
          size="small"
          label={t('user_contact.add_button')}
          onClick={() => {
            setEditEmail(null);
            setIsEmailSidepanelOpen(true);
          }}
        />
      </div>
      {isEmailListFetching ? (
        <ContactLoader />
      ) : userEmailList?.length > 0 ? (
        <div className="user__email--list">
          {userEmailList.map(email => (
            <Card>
              <div key={email.id} className="user__email-content">
                <div>
                  <span> {email.email} </span>
                  {email.primary && (
                    <EmphasisTag type="accent" text={t('user_contact.primary')} size="tiny" />
                  )}
                  <EmphasisTag
                    type="disabled"
                    text={t(`user.email_type.${email.emailType}`)}
                    size="tiny"
                  />
                </div>
                <div>
                  <div className="user-details__action">
                    <IconButton
                      className="user-details__action--show"
                      icon={<Icon name="delete" size="small" />}
                      size="tiny"
                      color="tertiary"
                      disabled={email.primary}
                      onClick={() => setDeleteCallbackParams({ userId, id: email.id })}
                    />
                    <IconButton
                      className="user-details__action--edit"
                      icon={<Icon name="edit" size="small" />}
                      size="tiny"
                      color="tertiary"
                      onClick={() => {
                        setEditEmail(email);
                        setIsEmailSidepanelOpen(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>
      ) : (
        <EmptyPlaceholder
          emptyCardText={t('user_contact.email_address.empty_placeholder')}
          emptyIcon={<Icon name="email" />}
        />
      )}

      <SidePanel
        isOpened={isEmailSidepanelOpen}
        title={editEmail ? t('user_edit.email_edit_title') : t('user_edit.email_create_title')}
        body={
          isEmailSidepanelOpen && (
            <EmailForm setIsEmailSidepanelOpen={setIsEmailSidepanelOpen} editEmail={editEmail} />
          )
        }
        onClose={() => setIsEmailSidepanelOpen(false)}
        onClick={() => setIsEmailSidepanelOpen(false)}
      />
    </>
  );
};

export default EmailEditComponent;
