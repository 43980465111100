import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PillTabs } from 'components/base-components/PillTabs';
import { useGetTasksQuery } from 'api/tasks';
import { useSearchParams } from 'react-router-dom';
import TASKS_STATUS from 'utils/task-status';
import moment from 'moment';
import TopnavTaskTabContentComponent from './topnav-task-tab-content.component';

const SharedTopnavTaskComponent = ({ taskType, currentUserId, setIsSidePanelOpen }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState();
  const currentDate = moment().format('DD-MM-YYYY');
  const [searchParams, setSearchParams] = useSearchParams({
    status: 'pending',
    assigned_to: 'me',
    q: '',
    page: 1,
    'author_ids[]': [],
    due_date: currentDate,
  });

  const currentTab = searchParams.get('status');
  const currentAsignee = searchParams.get('assigned_to');
  const currentPage = parseInt(searchParams.get('page'), 10);
  const taskParams = {
    q: searchParams.get('q'),
    assigned_to: taskType,
    status: TASKS_STATUS[currentTab],
    page: currentPage,
    due_date: selectedTab === 1 ? currentDate : null,
  };

  const setSearchQueryParams = useCallback(
    params => {
      Object.keys(params).forEach(key => {
        searchParams.set(key, params[key]);
      });
      setSearchParams(searchParams);
    },
    [currentTab],
  );

  const { data: taskData, isSuccess: isFetched } = useGetTasksQuery(taskParams);

  const taskDataCollection = taskData?.collection || [];

  useEffect(() => {
    setSearchQueryParams(
      {
        status: TASKS_STATUS[currentTab],
        assigned_to: currentAsignee,
        author_ids: [currentUserId],
        page: 1,
        due_date: '',
      },
      taskType,
      currentTab,
    );
  }, []);

  return (
    <>
      <PillTabs
        items={[
          {
            getContent: () => (
              <TopnavTaskTabContentComponent
                status="pending"
                key="pending"
                assigned_to={taskType}
                tasks={taskDataCollection}
                isFetched={isFetched}
                onChangeParams={setSearchQueryParams}
                setIsSidePanelOpen={setIsSidePanelOpen}
              />
            ),
            key: 0,
            title: t('task.to_do'),
          },
          {
            getContent: () => (
              <TopnavTaskTabContentComponent
                tasks={taskDataCollection}
                status="pending"
                key="pending"
                isFetched={isFetched}
                assigned_to={taskType}
                onChangeParams={setSearchQueryParams}
                setIsSidePanelOpen={setIsSidePanelOpen}
              />
            ),
            key: 1,
            title: t('task.due_today'),
          },
        ]}
        onChange={value => setSelectedTab(value)}
        selectedTabKey={selectedTab}
      />
    </>
  );
};

SharedTopnavTaskComponent.defaultProps = {
  taskType: null,
  currentUserId: null,
  setIsSidePanelOpen: () => {},
};

SharedTopnavTaskComponent.propTypes = {
  taskType: PropTypes.string,
  currentUserId: PropTypes.number,
  setIsSidePanelOpen: PropTypes.func,
};

export default SharedTopnavTaskComponent;
