import React from 'react';
import {
  useGetEducationAssigneeQuery,
  useGetEducationDeclarationsQuery,
} from 'api/educations-asignee';
import atLeastOneEntitiesInExpectedStatus from 'utils/at-least-one-entities-in-expected-status';
import allEntitiesInExpectedStatus from 'utils/all-entities-in-expected-status';
import './styles.scss';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetEducationsOverviewQuery } from 'api/educations';
import PropTypes from 'prop-types';
import { APPROVED, COMPLAINED, DRAFT, SUBMITTED } from '../../../utils/verification-status';
import ChartaValidity from '../../shared-components/charta-validity/charta-validity.component';
import isBioSuisseAdmin from '../../../utils/is-bio-suisse-admin';
import DefaultSpinner from '../../shared-components/default-spinner';
import EducationAssignee from './education-asignee/education-assignee.component';
import EducationDeclarations from './education-declarations/list.component';

const OrganizationsEducation = ({ organizationId }) => {
  const { data: currentUser } = useGetCurrentUserQuery();

  const isAdmin = isBioSuisseAdmin(currentUser);

  const {
    data: educationAssignee = {},
    isFetching,
    refetch,
  } = useGetEducationAssigneeQuery({
    organizationId,
  });

  const { data: educationsOverview = {} } = useGetEducationsOverviewQuery({
    organizationId,
  });

  const { data: educationDeclarationsResponse, isFetching: isFetchingEducationDeclarations } =
    useGetEducationDeclarationsQuery(
      { educationAssigneeId: educationAssignee?.id },
      { skip: !educationAssignee?.id },
    );

  const educationDeclarations = educationDeclarationsResponse
    ? educationDeclarationsResponse.declarations
    : [];

  const isDeclared = atLeastOneEntitiesInExpectedStatus(educationDeclarations, SUBMITTED);

  const isApproved = allEntitiesInExpectedStatus(educationDeclarations, APPROVED);

  const isComplained = atLeastOneEntitiesInExpectedStatus(educationDeclarations, COMPLAINED);
  const isDraft = atLeastOneEntitiesInExpectedStatus(educationDeclarations, DRAFT);

  if (isFetching) {
    return <DefaultSpinner className="default-spinner--center" />;
  }

  return (
    <div className="organization-education">
      <div className="organization-education__charta-availability">
        <ChartaValidity
          validFrom={educationsOverview?.validFrom}
          validUntil={educationsOverview?.validUntil}
          declaredAt={educationsOverview?.declaredOn}
          declaredOnTransKey={'educations.assignee.time_period.declared_on'}
        />
      </div>
      <EducationAssignee
        currentUser={currentUser}
        educationAssignee={educationAssignee}
        organizationId={organizationId}
        educationDeclarationsStatus={{ isDeclared, isApproved, isComplained, isDraft }}
        refetchEducationAssignee={refetch}
      />
      {educationAssignee?.id && (
        <EducationDeclarations
          isAdmin={isAdmin}
          educationAssignee={educationAssignee}
          isFetching={isFetchingEducationDeclarations}
          educationDeclarations={educationDeclarations}
          currentUser={currentUser}
        />
      )}
    </div>
  );
};

OrganizationsEducation.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export default OrganizationsEducation;
