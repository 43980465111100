import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import SelectBox from 'components/base-components/SelectBox';
import { useGetPreviousCultivationTypeOptionsQuery } from 'api/organizations';

const FarmlandPreviousCultivation = ({ previousCultivation }) => {
  const { data: previousCultivationOptionsData = [] } = useGetPreviousCultivationTypeOptionsQuery();
  const previousCultivationOptions = previousCultivationOptionsData.map(cultivation => {
    return {
      label: t(`farmland.previous_cultivation.${cultivation}`),
      value: cultivation,
    };
  });

  return (
    <div>
      <div className="col-6 col-bleed">
        <Field name="cultivationStatus" initialValue={previousCultivation || ''}>
          {({ input, meta }) => (
            <div className="user__invite--form__wrapper">
              <SelectBox
                className="farmland__previous--select"
                size="tiny"
                label={t('farmland.previous_cultivation_title')}
                placeholderText={t('user_general.placeholder')}
                isClearable={false}
                options={previousCultivationOptions}
                value={previousCultivationOptions.find(option => option.value === input.value)}
                selectedValue={input.value}
                onChange={e => input.onChange(e.value)}
                errorMsg={meta?.submitError}
              />
            </div>
          )}
        </Field>
      </div>
    </div>
  );
};

FarmlandPreviousCultivation.defaultProps = {
  previousCultivation: null,
};

FarmlandPreviousCultivation.propTypes = {
  previousCultivation: PropTypes.string,
};

export default FarmlandPreviousCultivation;
