import React from 'react';
import { Text } from '@react-pdf/renderer';
import { Break } from 'components/pdf/shared/shared-components';
import { suissCountryCode } from 'utils/country-code';

const addressFormatter = value => {
  if (!value) return;

  return (
    <>
      {value} <Break />
    </>
  );
};

const SCHWEIZ = 'Schweiz';

const onChangeCountryName = country => {
  if (country.code === suissCountryCode) return SCHWEIZ;
  return country?.name;
};

const organizationAddressForPdf = (
  organization,
  type = null,
  format = null,
  showCountry = true,
) => {
  let address;

  if (type && type !== 'coverLetter') {
    address = organization.addresses.find(address => address.type === type);
  }
  address = address || organization.addresses.find(address => address.main === true);

  if (address) {
    return (
      <Text>
        {addressFormatter(address.line1)}
        {addressFormatter(address.line2)}
        {address.zipCode && `${address.zipCode} `}
        {addressFormatter(address.city)}
        {showCountry &&
          (address.country && format === 'coverLetter'
            ? onChangeCountryName(address.country)
            : address.country.name)}
      </Text>
    );
  }
};

export const formatAddressForPDF = (address, withCountry) => (
  <Text>
    {addressFormatter(address.line1)}
    {addressFormatter(address.line2)}
    {address.zipCode && `${address.zipCode} `}
    {addressFormatter(address.city)}
    {withCountry && address.country && address.country?.name}
  </Text>
);

export default organizationAddressForPdf;
