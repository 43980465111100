import {
  useGetOrganizationQuery,
  useGetSpecificOrganizationsOverviewQuery,
} from 'api/organizations';
import { Tabs } from 'components/base-components/RTabs';
import DeactivationWrapper from 'components/organisations/deactivation-wrapper.component';
import StatusComponent from 'components/shared-components/status-component';
import useCustomNavigate from 'hooks/useCustomNavigate';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { PRODUCER } from 'utils/organisation-industries';
import ORGANISATION_TYPE from 'utils/organisation-type';
import ControlBodyComponent from '../../../../control-body/control-body.component';
import WithChartaWrapper from '../../../../shared-components/charta-wrapper/with-charta-wrapper';
import SupplierListWrapper from '../../../../supplier-certificates/supplier-certificate-wrapper.component';
import Charta from '../../../charta/charta.component';
import OrganizationsEducation from '../../../educations/organizations-education.component';
import GoodsComponent from '../../../goods/goods.component';
import OrganisationHeadComponent from '../../../organisation-head.component';
import '../../../organisations.styles.scss';
import OverviewComponent from '../../../overview/overview.component';
import ProductDeclarations from '../../../product-declarations/product-declarations.component';

const OrganisationDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams({ tab: 'overview' });
  const { id } = useParams();

  const organizationId = parseInt(id, 10);

  const { t } = useTranslation();
  const { data: organisation, isSuccess: isOrganisationFetched } =
    useGetOrganizationQuery(organizationId);
  const { data: organisationOverview = {} } =
    useGetSpecificOrganizationsOverviewQuery(organizationId);

  const navigate = useCustomNavigate();

  useEffect(() => {
    if (organisation?.type === ORGANISATION_TYPE.controlBody) {
      navigate.openRootPage();
    }
  }, [organisation?.id]);

  const currentTab = searchParams.get('tab');

  const isProducer = organisation?.industry === PRODUCER;

  const TAB_MAPPER = {
    overview: 0,
    charta: 1,
    control_body: 2,
    educations: 3,
    supplier_certificate: 4,
    products: 5,
    goods: 6,
  };

  const onChangeTab = selectedTabKey => {
    const selectedTab = Object.keys(TAB_MAPPER).find(tab => TAB_MAPPER[tab] === selectedTabKey);
    if (selectedTabKey === TAB_MAPPER.supplier_certificate) {
      setSearchParams(prevParams => {
        prevParams.set('tab', selectedTab);
        return prevParams;
      });
    } else {
      setSearchParams({ tab: selectedTab });
    }
  };

  const enableGoodsDeclaration =
    organisationOverview.chartaEnabled &&
    organisationOverview.suppliersEnabled &&
    organisationOverview.productsEnabled &&
    organisationOverview.suppliersEnabled &&
    organisationOverview.controlBodyEnabled;

  return (
    <>
      {isOrganisationFetched && (
        <OrganisationHeadComponent organisation={organisation} showContract={true} />
      )}
      <div>
        <StatusComponent organisationOverview={organisationOverview} />
      </div>
      <div className="organisation-details__rtabs">
        <Tabs
          controlled={true}
          selectedTabKey={TAB_MAPPER[currentTab]}
          items={[
            {
              title: t('organisation.details.tabs.overview'),
              key: 0,
              getContent: () => (
                <DeactivationWrapper>
                  <OverviewComponent
                    organizationId={organizationId}
                    setSearchParams={setSearchParams}
                    chartaEnabled={organisationOverview.chartaEnabled}
                    enableGoodsDeclaration={enableGoodsDeclaration}
                    declaredChartaPreviously={organisationOverview.chartaDeclaredPreviously}
                    isProducer={isProducer}
                  />
                </DeactivationWrapper>
              ),
            },
            {
              title: t('organisation.details.tabs.charta'),
              key: 1,
              getContent: () => (
                <DeactivationWrapper>
                  <Charta organizationId={parseInt(id, 10)} />
                </DeactivationWrapper>
              ),
            },
            {
              title: t('organisation.details.tabs.control'),
              key: 2,
              getContent: () => (
                <div>
                  {organisation?.type === ORGANISATION_TYPE.single_organisation ? (
                    <WithChartaWrapper organizationId={organizationId}>
                      <ControlBodyComponent />
                    </WithChartaWrapper>
                  ) : (
                    <ControlBodyComponent />
                  )}
                </div>
              ),
            },
            {
              title: t('organisation.details.tabs.education'),
              key: 3,
              getContent: () => (
                <WithChartaWrapper organizationId={organizationId}>
                  <DeactivationWrapper>
                    <OrganizationsEducation organizationId={organizationId} />
                  </DeactivationWrapper>
                </WithChartaWrapper>
              ),
            },
            {
              title: t('organisation.details.tabs.supplier_certificate'),
              key: 4,
              getContent: () => (
                <WithChartaWrapper organizationId={organizationId}>
                  <SupplierListWrapper organizationId={organizationId} />
                </WithChartaWrapper>
              ),
            },
            {
              title: t('organisation.details.tabs.products'),
              key: 5,
              getContent: () => (
                <WithChartaWrapper organizationId={organizationId}>
                  <ProductDeclarations organizationId={organizationId} />
                </WithChartaWrapper>
              ),
            },
            {
              title: t('organisation.details.tabs.goods'),
              key: 6,
              getContent: () => (
                <WithChartaWrapper organizationId={organizationId}>
                  <GoodsComponent />
                </WithChartaWrapper>
              ),
            },
          ]}
          onChange={onChangeTab}
        />
      </div>
    </>
  );
};

export default OrganisationDetails;
