const addressHeader = 'BIO SUISSE';
const mainAddress = 'Peter Merian-Strasse 34 · CH-4052 Basel';
const contact = 'Tel. 061 204 66 66';
const urlLink = 'www.bio-suisse.ch · bio@bio-suisse.ch';
const footerFirstText = 'Vereinigung Schweizer Biolandbau-Organisationen';
const footerSecondText = "Association suisse des organisations d'agriculture biologique";
const footerThirdText = 'Vereinigung Schweizer Biolandbau-Organisationen';
const footerFourthText = "Uniun svizra da las organisaziuns d'agricultura biologica";
const roadNumber = 'Peter Merian-Strasse 34';
const cityWithZip = '4052 Basel';

export {
  addressHeader,
  mainAddress,
  contact,
  urlLink,
  footerFirstText,
  footerSecondText,
  footerThirdText,
  footerFourthText,
  roadNumber,
  cityWithZip,
};
