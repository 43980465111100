const meatValidation = values => {
  return {
    meat: values?.meat && Object.keys(values?.meat)?.every(data => !values?.meat?.[data]),
    cow:
      !!values?.meat?.cow &&
      !values?.cows?.numberOfCalves &&
      !values?.cows?.numberOfCattle &&
      !values?.cows?.numberOfMotherCows,
    pig:
      !!values?.meat?.pig &&
      !values?.pigs?.numberOfBreedingPigs &&
      !values?.pigs?.numberOfFatteningPigs,
    ewe_sheep: values?.meat?.ewe_sheep && !values?.numberOfEweSheep,
    ewe_goat: values?.meat?.ewe_goat && !values?.numberOfEweGoat,
    poultry:
      values?.meat?.poultry &&
      !values?.poultry?.numberOfFatteningChicken &&
      !values?.poultry?.numberOfGeese &&
      !values?.poultry?.numberOfMalePullets &&
      !values?.poultry?.numberOfTurkeys &&
      !values?.poultryCheckbox?.some(poultry => !!poultry),
  };
};

export default meatValidation;
