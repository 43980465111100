import React from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import BioLogo from 'assets/bio_logo_large.png';
import organizationAddressForPdf from 'components/pdf/anerkennung/helpers/organization-address';
import Footer from 'components/pdf/recognition/footer.view';
import coverLetterStyles from 'components/pdf/styles/anerkennung/cover-letter';
import { useLanguageContext } from 'contexts/languageContext';
import PropTypes from 'prop-types';
import { addressChange } from 'utils/recognition_types';
import { Break } from '../../shared/shared-components';
import AddressChangeContent from './address-change-content';
import BioSuisseStatusChangeContent from './bio-suisse-status-change-content';

const CoverLetterPDF = ({
  organization,
  generationDate,
  status,
  user,
  contolBodyOrganization,
  language,
}) => {
  const { t } = useLanguageContext();
  const isAddressChangeCoverLetter = status === addressChange;
  const salutationOptions = ['mr', 'ms'];

  return (
    <Document>
      <Page size="A4" style={coverLetterStyles.page}>
        <View fixed={true}>
          <Image style={coverLetterStyles.headerLogo} src={BioLogo} />
        </View>
        <View style={coverLetterStyles.body}>
          <Text>
            {organization.name}
            <Break />
            {organizationAddressForPdf(organization)}
            <Break count={2} />
            {t('pdf.anerkennung.basel', { generationDate })}/
            {t('producer_contract.header.bio_id', { bioId: organization?.bioId })}
            <Break count={2} />
          </Text>

          <Text style={coverLetterStyles.subject}>
            {t(`pdf.anerkennung.cover-letter.${status}.subject`)}
          </Text>

          <Text style={isAddressChangeCoverLetter && coverLetterStyles.userName}>
            {salutationOptions.includes(user.salutation)
              ? t(`producer_contract.greeting_message.${user.salutation}`)
              : user.salutation}{' '}
            {user.lastName}
          </Text>

          <Break count={2} />

          {isAddressChangeCoverLetter ? (
            <AddressChangeContent />
          ) : (
            <BioSuisseStatusChangeContent
              status={status}
              organization={organization}
              contolBodyOrganization={contolBodyOrganization}
              language={language}
            />
          )}
          <Text>
            <Break count={2} />
            {t('pdf.anerkennung.cover-letter.best_regards')}
            <Break />
            {t('pdf.anerkennung.cover-letter.regards_form')}
            <Break count={2} />
            {t(`pdf.anerkennung.cover-letter.${status}.from`)}
          </Text>
        </View>
        <Footer />
      </Page>
    </Document>
  );
};

CoverLetterPDF.propTypes = {
  organization: PropTypes.shape().isRequired,
  generationDate: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  user: PropTypes.shape().isRequired,
  contolBodyOrganization: PropTypes.shape().isRequired,
};

export default CoverLetterPDF;
