import EmphasisTag from 'components/base-components/EmphasisTag';
import { t } from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { SCHEDULED } from 'utils/control-body-survey';

const InvoiceJobTag = ({ status, scheduleDate }) => {
  const getStatus = () => {
    const checkNeedsAction = status === SCHEDULED && moment().isAfter(moment(scheduleDate));

    const invoiceJobStatus = {
      scheduled: {
        type: 'accent',
        text: t('invoice.tag_status.scheduled'),
      },
      scheduled_for_release: {
        type: 'accent',
        text: t('invoice.tag_status.scheduled_for_release'),
      },
      needsAction: {
        type: 'warning',
        text: t('invoice.needs_action'),
      },
      completed: {
        type: 'success',
        text: t('invoice.tag_status.completed'),
      },
      cancelled: {
        type: 'disabled',
        text: t('invoice.tag_status.cancelled'),
      },
    };

    if (checkNeedsAction) return invoiceJobStatus['needsAction'];
    return invoiceJobStatus[status];
  };

  return (
    <EmphasisTag
      type={getStatus(status, scheduleDate)?.type}
      size="tiny"
      text={getStatus(status, scheduleDate)?.text}
    />
  );
};

InvoiceJobTag.propTypes = {
  status: PropTypes.string.isRequired,
  scheduleDate: PropTypes.string.isRequired,
};

export default InvoiceJobTag;
