import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popover from '../Popover';

const Menu = props => {
  const { children, className, label, size, popOverDirection, isVisible, onOutsideClick } = props;

  return (
    <Popover
      className={classNames('rcl-menu', className)}
      isVisible={isVisible}
      size={size}
      direction={popOverDirection}
      onOutsideClick={onOutsideClick}
      content={
        <ul className="rcl-menu__item">
          {React.Children.map(children, child => React.cloneElement(child))}
        </ul>
      }
    >
      {label}
    </Popover>
  );
};

Menu.defaultProps = {
  children: null,
  size: 'medium',
  label: null,
  popOverDirection: 'bottom-left',
  className: '',
  onOutsideClick: () => {},
};

Menu.propTypes = {
  children: PropTypes.node,
  label: PropTypes.node,
  popOverDirection: PropTypes.oneOf([
    'top',
    'top-left',
    'top-right',
    'right',
    'right-top',
    'right-bottom',
    'bottom',
    'bottom-left',
    'bottom-right',
    'left',
    'left-top',
    'left-bottom',
  ]),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  isVisible: PropTypes.bool.isRequired,
  onOutsideClick: PropTypes.func,
  className: PropTypes.string,
};

export default Menu;
