import { useGetInvoiceItemsQuery } from 'api/invoices';
import classNames from 'classnames';
import { TableBody, TableData, TableRow } from 'components/base-components/Table';
import AmountComponent from 'components/shared-components/Amount';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import TableLoaderPlaceholder from 'components/shared-components/placeholders/table-loader-placeholder';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { monthYearShort } from 'utils/date-time-formatter';
import numberFormatter from 'utils/number-formatter';
import '../../invoice.styles.scss';

const LineItemVatSummary = ({ invoice }) => {
  const { totalAmountExclVat, totalAmountInclVat, totalVatAmountsByVatRates } = invoice;

  return (
    <>
      <TableRow className="invoice-table--row--border-top">
        <TableData colspan={3} />
        <TableData align="right">
          <span>{t('invoice.total_net_amount')}</span>
        </TableData>
        <TableData align="right">
          <span>
            {t('invoice.chf')} {totalAmountExclVat}
          </span>
        </TableData>
      </TableRow>

      {totalVatAmountsByVatRates?.map((totalVatAmount, index) => {
        const isVatAvailable = totalVatAmount.percentage && totalVatAmount.percentage !== '0.0';

        return (
          isVatAvailable && (
            <TableRow key={index} className="invoice-table--row--border-bottom">
              <TableData colspan={3} />
              <TableData
                className={classNames({
                  'invoice-table--row--vat-summary-data':
                    index === totalVatAmountsByVatRates.length - 1,
                })}
                align="right"
              >
                <span>
                  {t('invoice.total_vat_with_percentage', {
                    percentage: totalVatAmount.percentage,
                  })}
                </span>
              </TableData>
              <TableData
                className={classNames({
                  'invoice-table--row--vat-summary-data':
                    index === totalVatAmountsByVatRates.length - 1,
                })}
                align="right"
              >
                <span>
                  <AmountComponent value={numberFormatter(totalVatAmount.value)} />
                </span>
              </TableData>
            </TableRow>
          )
        );
      })}
      <TableRow className="invoice-table--row invoice__total--including-vat">
        <TableData colspan={3} />
        <TableData align="right">
          <span>{t('invoice.total_amount_chf')}</span>
        </TableData>
        <TableData align="right">
          <span>
            {t('invoice.chf')} {totalAmountInclVat}
          </span>
        </TableData>
      </TableRow>
      <TableRow className="invoice-table--row invoice__paid-amount">
        <TableData colspan={3} />
        <TableData align="right">{t('invoice.paid_chf')}</TableData>
        <TableData align="right">
          <AmountComponent value={invoice?.totalReceivedAmount} />
        </TableData>
      </TableRow>
      <TableRow className="invoice-table--row invoice__total--including-vat">
        <TableData colspan={3} />
        <TableData align="right">{t('invoice.remaining_due')}</TableData>
        <TableData align="right">
          <AmountComponent value={invoice?.totalRemainingDue} />
        </TableData>
      </TableRow>
    </>
  );
};

const InvoiceLineItems = ({ invoice }) => {
  const { data: lineItems, isFetching } = useGetInvoiceItemsQuery({ invoiceId: invoice.id });
  let serialNo = 1;

  return (
    <>
      {isFetching ? (
        <TableLoaderPlaceholder numberOfRows={5} numberOfColumns={5} />
      ) : (
        <>
          {lineItems.length ? (
            <TableBody>
              {lineItems.map((lineItem, index) => {
                const { id, name, description, quantity, vatInformations } = lineItem;

                return vatInformations?.map(data => (
                  <TableRow key={id} className="invoice-table--row">
                    <TableData align="center">{serialNo++}.</TableData>
                    <TableData className="invoice__service">
                      <span>
                        {name}{' '}
                        {data.periodFrom && data.periodUntil && (
                          <>
                            (
                            {data.periodFrom !== data.periodUntil &&
                              `${monthYearShort(data.periodFrom)} - `}
                            {monthYearShort(data.periodUntil)})
                          </>
                        )}
                      </span>
                      <div>
                        <span className="invoice__service--description">
                          {t('invoice.description')}
                        </span>{' '}
                        : <span>{description}</span>
                      </div>
                      <div>
                        {`${t('invoice.vat_chf')} ${data.vatAmountInTotalMonths} (${
                          data.percentage || '0.0'
                        }%)`}
                      </div>
                    </TableData>
                    <TableData align="center">{quantity}</TableData>
                    <TableData align="right">{data.priceForTotalMonth}</TableData>
                    <TableData align="right">{data.amountInTotalMonth}</TableData>
                  </TableRow>
                ));
              })}
              <LineItemVatSummary invoice={invoice} />
            </TableBody>
          ) : (
            <TableRow className="invoice-table--row">
              <TableData colspan={5}>
                <EmptyContentPlaceholder iconName="neutralOutline" text={t('invoice.no_invoice')} />
              </TableData>
            </TableRow>
          )}
        </>
      )}
    </>
  );
};

LineItemVatSummary.propTypes = {
  invoice: PropTypes.shape().isRequired,
};

InvoiceLineItems.propTypes = {
  invoice: PropTypes.shape().isRequired,
};

export default InvoiceLineItems;
