import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useGetPrivateAndMunicipalAreasQuery } from 'api/producers/productions';
import SharedGreenAreaAndPrivatePark from './shared/green-area-and-private-parks.component';

const PrivateParksComponent = ({ id }) => {
  const { data: privateAndMunicipalAreas } = useGetPrivateAndMunicipalAreasQuery(
    { productionId: id },
    { skip: !id },
  );

  return (
    <SharedGreenAreaAndPrivatePark
      title={t('plant_cultivation.private_park.title')}
      icon="private_park"
      data={privateAndMunicipalAreas}
      name="private_park"
    />
  );
};

PrivateParksComponent.defaultProps = {
  id: null,
};

PrivateParksComponent.propTypes = {
  id: PropTypes.node,
};

export default PrivateParksComponent;
