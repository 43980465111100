import React from 'react';
import PropTypes from 'prop-types';
import { useGetActiveRestrictedIngredientsAdditionalTaskQuery } from 'api/control-body-survey/restricted-ingredients';
import { useGetRestrictedIngredientsQuery } from 'api/control-body-survey';
import { ACTIVE } from 'utils/verification-status';
import RestrictedIngredientsCheckbox from '../entities/restricted-ingredients-checkbox.component';

const RestrictedIngredients = ({
  surveyId,
  title,
  items,
  error,
  isCertifier,
  isAuditor,
  comment,
  status,
  uploadedRestrictedIngredientAttachments,
  setUploadedRestrictedIngredientAttachments,
}) => {
  const { data: activeRestrictedIngredientsAdditionalTask } =
    useGetActiveRestrictedIngredientsAdditionalTaskQuery({ surveyId }, { skip: !surveyId });

  const { data: activeRestrictedIngredients } = useGetRestrictedIngredientsQuery(
    { surveyId, type: ACTIVE },
    { skip: !surveyId },
  );

  return (
    <RestrictedIngredientsCheckbox
      title={title}
      items={items}
      activeItems={activeRestrictedIngredients}
      error={error}
      surveyId={surveyId}
      comment={comment}
      isAuditor={isAuditor}
      isCertifier={isCertifier}
      status={status}
      activeRestrictedIngredientsAdditionalTask={activeRestrictedIngredientsAdditionalTask}
      uploadedRestrictedIngredientAttachments={uploadedRestrictedIngredientAttachments}
      setUploadedRestrictedIngredientAttachments={setUploadedRestrictedIngredientAttachments}
    />
  );
};

RestrictedIngredients.defaultProps = {
  surveyId: null,
  title: null,
  status: null,
  items: null,
  isCertifier: false,
  isAuditor: false,
  surveyDraft: {},
  error: null,
  comment: null,
};

RestrictedIngredients.propTypes = {
  surveyId: PropTypes.node,
  title: PropTypes.node,
  comment: PropTypes.node,
  status: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.shape(),
  surveyDraft: PropTypes.shape(),
  isCertifier: PropTypes.bool,
  isAuditor: PropTypes.bool,
  uploadedRestrictedIngredientAttachments: PropTypes.array.isRequired,
  setUploadedRestrictedIngredientAttachments: PropTypes.func.isRequired,
};

export default RestrictedIngredients;
