const parseUrlTemplate = (url, data) => {
  let link = url;

  Object.keys(data).forEach(key => {
    link = link.replaceAll(`:${key}`, data[key]);
  });

  return link;
};

export default parseUrlTemplate;
