import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { HeaderDataContext } from '../contexts/HeaderDataContext';

const TableRow = ({ className, children, isHeaderRow }) => {
  const { headerData, setHeaderData } = useContext(HeaderDataContext);

  useEffect(() => {
    if (isHeaderRow) {
      setHeaderData(
        children.map(
          tableHeader => React.isValidElement(tableHeader) && tableHeader.props.children,
        ),
      );
    }
  }, []);

  return (
    <tr className={classNames('table__row', className)}>
      {isHeaderRow
        ? children
        : React.Children.map(
            children,
            (data, index) =>
              React.isValidElement(data) &&
              React.cloneElement(data, {
                heading: headerData[index] || null,
              }),
          )}
    </tr>
  );
};

TableRow.defaultProps = {
  className: null,
  isHeaderRow: false,
};

TableRow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isHeaderRow: PropTypes.bool,
};

export default TableRow;
