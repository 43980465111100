import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import Card from 'components/base-components/Card';
import './assignment-loader.styles.scss';

const AssignmentLoader = () => {
  return (
    <Card>
      <div className="col-grid col-bleed direction-row justify-space-between">
        <div className="col-3 col-bleed first-col">
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="50%" />
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="50%" />
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="50%" />
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
        </div>
        <div className="col-3 col-bleed second-col">
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="50%" />
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="50%" />
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="50%" />
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
        </div>
        <div className="col-3 col-bleed third-col">
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="50%" />
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="50%" />
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
        </div>
        <div className="col-2 col-bleed fourth-col">
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="35%" />
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="35%" />
        </div>
      </div>
    </Card>
  );
};

export default AssignmentLoader;
