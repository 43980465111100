import React from 'react';
import OrganizationName from 'components/organisations/organization-name';
import PropTypes from 'prop-types';

const BreadcrumbOrganizationName = ({ match }) => {
  const id = match?.params?.id;

  return <OrganizationName organizationId={id} disableLink={true} />;
};

BreadcrumbOrganizationName.defaultProps = {
  match: null,
};

BreadcrumbOrganizationName.propTypes = {
  match: PropTypes.shape(),
};

export default BreadcrumbOrganizationName;
