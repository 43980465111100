import React from 'react';
import SetPasswordForm from 'components/password-management/set-password-form';
import { useResetPasswordMutation } from 'api/password-management';

const ResetPasswordComponent = () => {
  const [changePassword, { isSuccess }] = useResetPasswordMutation();

  return <SetPasswordForm onSave={changePassword} isSuccess={isSuccess} />;
};

export default ResetPasswordComponent;
