import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Menu, MenuItem } from '../Menu';
import { isSmallMobile, isMobile } from './responsive-layout';

class Breadcrumbs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCollapsed: false,
    };
  }

  componentDidMount() {
    this.shouldBeCollapsed();
    window.addEventListener('resize', this.shouldBeCollapsed);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.shouldBeCollapsed);
  }

  shouldBeCollapsed = () => {
    const { children } = this.props;
    const currentScreenSize = window.innerWidth;
    const isCollapsed =
      (isSmallMobile(currentScreenSize) && children.length >= 3) ||
      (isMobile(currentScreenSize) && children.length >= 5);

    this.setState({ isCollapsed });
  };

  getItemsForMenu = () => {
    const { children } = this.props;
    const { isCollapsed } = this.state;
    const currentScreenSize = window.innerWidth;
    let itemsToShowInMenu = [];

    if (isCollapsed && isSmallMobile(currentScreenSize)) {
      itemsToShowInMenu = [...children].splice(1, children.length - 2);
    } else if (isCollapsed && isMobile(currentScreenSize)) {
      itemsToShowInMenu = [...children].splice(1, children.length - 4);
    }

    return itemsToShowInMenu;
  };

  render() {
    const { children, className, dividerSymbol } = this.props;
    const { isCollapsed } = this.state;
    const itemsToShowInMenu = this.getItemsForMenu();
    const childrenWithPropsFromParent = React.Children.map(children, (child, index) => {
      return React.cloneElement(child, {
        lastChild: index === children.length - 1,
        dividerSymbol,
      });
    });

    return (
      <ul className={classNames('breadcrumbs', className)}>
        {isCollapsed && (
          <>
            {childrenWithPropsFromParent[0]}
            <Menu menuButtonIcon="horizontalDots">
              {itemsToShowInMenu.map(item => {
                return (
                  <MenuItem key={item.props.linkUrl} linkUrl={item.props.linkUrl}>
                    {item.props.children}
                  </MenuItem>
                );
              })}
            </Menu>
            <span className="breadcrumbs__barrier">{dividerSymbol}</span>
            {childrenWithPropsFromParent.splice(itemsToShowInMenu.length + 1)}
          </>
        )}
        {!isCollapsed && childrenWithPropsFromParent}
      </ul>
    );
  }
}

Breadcrumbs.defaultProps = {
  children: null,
  className: null,
  dividerSymbol: '/',
};

Breadcrumbs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dividerSymbol: PropTypes.node,
};

export default Breadcrumbs;
