import React from 'react';
import { Text, Link } from '@react-pdf/renderer';
import { adminEmail, easyCertWebsite } from 'utils/pdf_links';
import { useLanguageContext } from 'contexts/languageContext';
import { Break } from '../../shared/shared-components';

const AddressChangeContent = () => {
  const { t } = useLanguageContext();

  return (
    <Text>
      {t('pdf.anerkennung.cover-letter.address_change.line1')}
      <Break count={2} />
      {t('pdf.anerkennung.cover-letter.address_change.line2.part1')}
      <Link src={`https://${easyCertWebsite}`}>{easyCertWebsite}</Link>
      {t('pdf.anerkennung.cover-letter.address_change.line2.part2')}
      <Break count={2} />
      {t('pdf.anerkennung.cover-letter.address_change.line3')}
      <Link src={`mailto:${adminEmail}`}>{adminEmail}</Link>
      <Break count={2} />
      {t('pdf.anerkennung.cover-letter.address_change.line4')}
      <Break count={4} />
    </Text>
  );
};

export default AddressChangeContent;
