import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'components/base-components/Button';
import SanitizedHTML from 'components/shared-components/sanitized-html';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import Tooltip from 'components/base-components/Tooltip';
import IconButton from '../../IconButton';
import Icon from '../../Icon';
import DateTime from '../../DateTime';
import Avatar from '../../Avatar';

const Message = props => {
  const {
    onEdit,
    onDelete,
    locales,
    id,
    priority,
    name,
    assignDate,
    description,
    assignee,
    reporter,
    assigneeLabel,
    reporterLabel,
    assosiatedOrder,
    lastUpdatedTime,
    createdTime,
    lastUpdatedTimeLabel,
    taskCreatedLabel,
    isExpanded,
    isChecked,
    onExpand,
    showConfirmButton,
    onButtonConfirm,
    isAdmin,
  } = props;

  const { t } = useTranslation();

  const getFirstandLastName = value => {
    const namesArray = value.split(' ');

    const lastName = namesArray.pop();
    const firstName = namesArray.join(' ');

    return { firstName, lastName };
  };

  const getAssigneeAvatar = (value, showName) => {
    if (value) {
      const { firstName, lastName } = getFirstandLastName(value);

      return <Avatar showName={showName} firstName={firstName} lastName={lastName} size="tiny" />;
    }

    return <Avatar firstName="Un" lastName="Assigned" size="tiny" showName={showName} />;
  };

  const getReporterAvatar = (value, showName) => {
    if (value.toLowerCase() === 'auto generated') {
      return (
        <Avatar showName={showName} firstName="Auto" lastName="Generated" src={null} size="tiny" />
      );
    }

    if (value) {
      const { firstName, lastName } = getFirstandLastName(value);

      return <Avatar showName={showName} firstName={firstName} lastName={lastName} size="tiny" />;
    }

    return <Avatar firstName="Un" lastName="Assigned" size="tiny" showName={showName} />;
  };

  return (
    <div
      className={classNames('message-list__item', {
        'message-list__item--expanded': isExpanded,
      })}
    >
      <div>{priority}</div>

      <div
        className={classNames('message-list__item-title', {
          'message-list__item-title--checked': isChecked,
        })}
      >
        {name}
      </div>

      <div
        className={classNames('message-list__item-date', {
          'message-list__item-date--with-no-margin': !assignee,
        })}
      >
        <span className="message-list__item-date--sent-at">{t('messages.sent_at')}</span>{' '}
        <DateTime format="short" locales={locales} dateTime={assignDate} />
      </div>
      {assignee && (
        <div className="message-list__item-avatar">
          {typeof assignee === 'string' ? getAssigneeAvatar(assignee, false) : assignee}
        </div>
      )}
      <div className="message-list__item-icons">
        {isAdmin && (
          <>
            <Tooltip
              content={t('common.edit_button')}
              type="inverse"
              size="tiny"
              className="user__action--tooltip"
            >
              <IconButton
                icon={<Icon name="edit" />}
                color="tertiary"
                size="tiny"
                onClick={() => onEdit({ id })}
              />
            </Tooltip>
            <Tooltip
              content={t('common.delete_button')}
              type="inverse"
              size="tiny"
              className="user__action--tooltip"
            >
              <IconButton
                icon={<Icon name="delete" />}
                color="danger"
                size="tiny"
                onClick={() => onDelete({ id })}
              />
            </Tooltip>
          </>
        )}
        <IconButton
          icon={<Icon name="iconDownChevron" rotate={isExpanded ? '180' : '0'} />}
          color="tertiary"
          size="tiny"
          isIconOnly={true}
          onClick={() => onExpand(id)}
        />
      </div>

      <div className="message-list__item-details">
        <div className="message-list__item-largeTitle">{name}</div>
        <div className="message-list__item-assosiatedOrder">{assosiatedOrder}</div>
        <div className="message-list__item-description">
          <SanitizedHTML html={description} />
        </div>
        <div className="message-list__item-avatars">
          <div className="message-list__item-avatars-assignee">
            <div className="message-list__item-avatars-assignee-label">{assigneeLabel}</div>
            <div className="message-list__item-avatars-assignee-name">
              {typeof assignee === 'string' || assignee === null
                ? getAssigneeAvatar(assignee, true)
                : assignee}{' '}
            </div>
          </div>

          <div className="message-list__item-avatars-reporter">
            <div className="message-list__item-avatars-reporter-label">{reporterLabel}</div>
            <div className="message-list__item-avatars-reporter-name">
              {typeof reporter === 'string' || reporter === null
                ? getReporterAvatar(reporter, true)
                : reporter}{' '}
            </div>
          </div>
        </div>
        <div className="message-list__item-times">
          <div>
            {lastUpdatedTimeLabel} {lastUpdatedTime}
          </div>
          <div>
            {taskCreatedLabel} {createdTime}
          </div>
        </div>
        {showConfirmButton && (
          <Button
            className="messages__confirm-button"
            label={t('shared.action.okay')}
            type="success"
            size="small"
            onClick={() => onButtonConfirm()}
          />
        )}
      </div>
    </div>
  );
};

Message.defaultProps = {
  id: null,
  priority: null,
  assignee: null,
  name: '',
  assignDate: '',
  description: '',
  reporter: null,
  assosiatedOrder: null,
  lastUpdatedTime: '',
  createdTime: '',
  locales: 'en',
  isExpanded: false,
  isChecked: false,
  assigneeLabel: t('messages.create_form.assignee'),
  reporterLabel: t('shared.reporter'),
  lastUpdatedTimeLabel: t('shared.last_updated'),
  taskCreatedLabel: t('messages.message_created'),
  showConfirmButton: true,
  onExpand: () => {},
  onEdit: () => {},
  onDelete: () => {},
  onButtonConfirm: () => {},
  isAdmin: false,
};

Message.propTypes = {
  id: PropTypes.number,
  priority: PropTypes.node,
  assignee: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  assignDate: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  reporter: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  assosiatedOrder: PropTypes.string,
  lastUpdatedTime: PropTypes.string,
  createdTime: PropTypes.string,
  isExpanded: PropTypes.bool,
  isChecked: PropTypes.bool,
  showConfirmButton: PropTypes.bool,
  assigneeLabel: PropTypes.string,
  reporterLabel: PropTypes.string,
  lastUpdatedTimeLabel: PropTypes.string,
  taskCreatedLabel: PropTypes.string,
  locales: PropTypes.oneOf(['en', 'af', 'de', 'ms', 'th']),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onExpand: PropTypes.func,
  onButtonConfirm: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default Message;
