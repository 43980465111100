import React from 'react';
import PropTypes from 'prop-types';
import { useGetOrganizationQuery } from 'api/organizations';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/base-components/Spinner';
import { Link } from 'react-router-dom';

const OrganizationName = ({
  organizationId,
  fetchedOrganisation,
  className,
  onClick,
  disableLink,
}) => {
  const { t } = useTranslation();
  const { data, error, isFetching } = useGetOrganizationQuery(organizationId, {
    skip: !!fetchedOrganisation?.id,
  });

  const organization = fetchedOrganisation || data;
  if (isFetching) return <Spinner bgColor="none" color="success" size="tiny" />;
  if (error) return null;

  if (!organization?.name) return t('common.not_applicable');

  return (
    <div className={classNames('organization-name', className)} onClick={onClick}>
      {disableLink ? (
        organization.name
      ) : (
        <Link to={`/organisations/${organization.id}`} className="organisation__link">
          {organization.name}
        </Link>
      )}
    </div>
  );
};

OrganizationName.defaultProps = {
  onClick: () => {},
  className: null,
  disableLink: false,
  fetchedOrganisation: null,
  organizationId: null,
};

OrganizationName.propTypes = {
  organizationId: PropTypes.number,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disableLink: PropTypes.bool,
  fetchedOrganisation: PropTypes.shape(),
};

export default OrganizationName;
