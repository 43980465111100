import { useCreateLocalUnitMutation } from 'api/producers/local-unit';
import React from 'react';
import { useParams } from 'react-router';
import TVDFormComponent from '../index.component';

const TvdCreateComponent = () => {
  const [createTvd] = useCreateLocalUnitMutation();
  const { id } = useParams();

  const onCreate = formData => {
    return createTvd({
      organisationId: id,
      payload: formData,
    });
  };

  return <TVDFormComponent onSave={onCreate} />;
};

export default TvdCreateComponent;
