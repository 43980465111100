const dairyFarmValidation = values => {
  return {
    dairy_firm:
      !values?.dairy_farms?.sub_types?.dairy_goat &&
      !values?.dairy_farms?.sub_types?.dairy_ewe &&
      !values?.dairy_farms?.sub_types?.milk_cow,
    milk_cow:
      !!values?.dairy_farms?.sub_types?.milk_cow &&
      (!values?.dairy_farms?.milk_cow?.number_of_animals ||
        !values?.dairy_farms?.milk_cow?.milk_produced_kg ||
        values?.isMilkOrganisationMember === undefined ||
        (!values?.milkProducerOrganization && !values?.milkProcessingSource)),
    dairy_goat:
      !!values?.dairy_farms?.sub_types?.dairy_goat &&
      (!values?.dairy_farms?.dairy_goat?.number_of_animals ||
        !values?.dairy_farms?.dairy_goat?.milk_produced_kg),
    dairy_ewe:
      !!values?.dairy_farms?.sub_types?.dairy_ewe &&
      (!values?.dairy_farms?.dairy_ewe?.number_of_animals ||
        !values?.dairy_farms?.dairy_ewe?.milk_produced_kg),
  };
};

export default dairyFarmValidation;
