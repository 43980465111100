import React from 'react';
import { useGetOrganizationQuery } from 'api/organizations';
import { useGetCurrentUserQuery } from 'api/users';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import EmphasisTag from 'components/base-components/EmphasisTag';
import Icon from 'components/base-components/Icon';
import Link from 'components/base-components/Link';
import WarningModal from 'components/shared-components/modal/warning';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import bytesToMegabytes from 'utils/bytes-to-megabytes';
import { dateMonthYear } from 'utils/date-time-formatter';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { PRODUCER } from 'utils/organisation-industries';
import { CANCELLED } from 'utils/invoice-statuses';
import Alert from 'components/base-components/Alert';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import { ABORTED, TERMINATED, VERIFIED } from 'utils/signed-contract-statuses';
import Tooltip from 'components/base-components/Tooltip';
import IconButton from 'components/base-components/IconButton';
import classNames from 'classnames';
import moment from 'moment';
import { useReinstateContractMutation } from 'api/contract';
import UploadContract from './upload-contract.component';

const ContractVerification = ({ title, contractData }) => {
  const [isReinstateModalOpen, setIsReinstateModalOpen] = useState(false);

  const { id } = useParams();

  const { data: organisationData } = useGetOrganizationQuery(id);
  const { data: currentUser } = useGetCurrentUserQuery();
  const [reinstate] = useReinstateContractMutation();

  const isDeactivated = organisationData?.status === ORGANISATIONS_STATUS.inactive;
  const isAdmin = isBioSuisseAdmin(currentUser);

  const producer = organisationData?.industry === PRODUCER;

  const DAY_30 = 30;

  const REMAINING_DAY_30 = contractData?.remainingDaysToUpload === 30;
  const REMAINING_DAY_0 = contractData?.remainingDaysToUpload === 0;
  const REMAINING_DAY_LESS_THAN_5 = contractData?.remainingDaysToUpload <= 5;
  const REMAINING_DAY_MORE_THAN_5 = contractData?.remainingDaysToUpload > 5;
  const REMAINING_DAY_MORE_THAN_30 = contractData?.remainingDaysToUpload > 30;
  const REMAINING_DAY_LESS_THAN_30 = contractData?.remainingDaysToUpload < 30;

  const onReinstate = () => {
    setIsReinstateModalOpen(false);

    return reinstate({ organisationId: id }).unwrap();
  };

  const handleTag = isVerified => {
    if (isVerified) {
      return (
        <EmphasisTag type="success" size="tiny" text={t('supplier_certificates.status.verified')} />
      );
    } else {
      return <EmphasisTag type="warning" size="tiny" text={t('contract.not_declared_tag')} />;
    }
  };

  const getHeaderText = () => {
    if (contractData?.status === TERMINATED) {
      return t('contract.producer.terminate_header');
    }

    if (contractData?.status === ABORTED) {
      return t('contract.producer.abort_header');
    }

    if (!contractData?.contract && contractData?.cancelledAt) {
      return t('contract.producer.cancel_header');
    }

    if (contractData?.contract && contractData?.status !== TERMINATED) {
      return t('contract.producer.signed_header');
    }

    return t('contract.producer.verify_or_terminate_step.text');
  };

  const getDateText = () => {
    const uploadDateText = (
      <div>
        {t('contract.producer.upload_date', {
          date: contractData?.contract?.uploadedAt
            ? dateMonthYear(contractData?.contract?.uploadedAt)
            : t('common.not_applicable'),
        })}
      </div>
    );

    const scheduledText = contractData?.status === VERIFIED && contractData.terminatedAt && (
      <div className="organisation-contract__terminate-text">
        <div className="choose-file__contract-vertical--line__right" />
        {t('contract.producer.terminate_date_pending')}
      </div>
    );

    const terminationText = contractData?.status === TERMINATED && (
      <div className="organisation-contract__terminate-text">
        <div className="choose-file__contract-vertical--line__right" />
        {t('contract.producer.terminate_date', {
          date: dateMonthYear(contractData?.terminatedAt),
        })}
      </div>
    );

    const reminderEmailSentText = REMAINING_DAY_30 && (
      <div className="organisation-contract__terminate-text">
        <div className="choose-file__contract-vertical--line__right" />
        {t('contract.producer.reminder_email_date', {
          date: dateMonthYear(moment(contractData?.sentAt).add(DAY_30, 'days')),
        })}
      </div>
    );

    const cancelText = contractData?.status === ABORTED && contractData?.cancelledAt && (
      <div className="organisation-contract__terminate-text">
        <div className="choose-file__contract-vertical--line__right" />
        {t('contract.producer.cancel_date', {
          date: contractData?.cancelledAt
            ? dateMonthYear(contractData?.cancelledAt)
            : t('common.not_applicable'),
        })}
      </div>
    );

    const daysRemainingText = (contractData?.remainingDaysToUpload || REMAINING_DAY_0) && (
      <>
        <div className="choose-file__contract-vertical--line__right" />
        <div className="organisation-contract__remaining-days--wrapper">
          <span
            className={classNames('organisation-contract__remaining-days', {
              'organisation-contract__remaining-days--success': REMAINING_DAY_MORE_THAN_30,
              'organisation-contract__remaining-days--warning':
                REMAINING_DAY_LESS_THAN_30 && REMAINING_DAY_MORE_THAN_5,
              'organisation-contract__remaining-days--danger': REMAINING_DAY_LESS_THAN_5,
            })}
          >
            {contractData?.remainingDaysToUpload < 0
              ? t('contract.producer.overdue_text', {
                  days: Math.abs(contractData?.remainingDaysToUpload),
                })
              : t('contract.producer.days_remaining_count', {
                  days: contractData?.remainingDaysToUpload,
                })}
          </span>
          <span className="organisation-contract__remaining-days--text">
            {t('contract.producer.days_remaining_text')}
          </span>
          <Tooltip
            content={
              <ul className="organisation-contract__tooltip--list">
                <li>{t('contract.producer.remaining_day_info.first_list')}</li>
                <li>{t('contract.producer.remaining_day_info.second_list')}</li>
                <li>{t('contract.producer.remaining_day_info.third_list')}</li>
              </ul>
            }
            type="inverse"
            position="right"
            size="large"
            showArrow={true}
          >
            <IconButton
              icon={<Icon name="info" size="tiny" />}
              color="default"
              size="tiny"
              isIconOnly={true}
            />
          </Tooltip>
        </div>
      </>
    );

    return (
      <div className="organisation-contract__date-wrapper">
        {uploadDateText}
        {scheduledText}
        {terminationText}
        {daysRemainingText}
        {reminderEmailSentText}
        {cancelText}
      </div>
    );
  };

  const getAlertText = () => {
    if (contractData?.status === TERMINATED) {
      return {
        text: (
          <div className="align-center-with-space-between">
            <div>
              <Trans
                i18nKey={'contract.producer.terminated_text'}
                values={{ date: dateMonthYear(contractData?.terminatedAt) }}
                components={{ bold: <b /> }}
              />
            </div>
            <Button
              label={t('contract.producer.reinstate_contract')}
              size="small"
              onClick={() => setIsReinstateModalOpen(true)}
            />
          </div>
        ),
        type: 'danger-outline',
      };
    } else if (contractData?.status === VERIFIED && contractData?.terminatedAt) {
      return {
        text: (
          <div className="align-center-with-space-between">
            <div>
              <Trans
                i18nKey={'contract.producer.terminate_text'}
                values={{ date: dateMonthYear(contractData?.terminatedAt) }}
                components={{ bold: <b /> }}
              />
            </div>
          </div>
        ),
        type: 'danger-outline',
      };
    } else if (contractData?.status === CANCELLED) {
      return {
        text: (
          <Trans
            i18nKey={'contract.producer.cancel_text'}
            values={{ date: dateMonthYear(contractData?.cancelledAt) }}
            components={{ bold: <b /> }}
          />
        ),
        type: 'danger-outline',
      };
    } else if (contractData?.status === ABORTED) {
      return {
        text: <Trans i18nKey={'contract.producer.abort_text'} components={{ bold: <b /> }} />,
        type: 'danger-outline',
      };
    } else if (contractData?.remainingDaysToUpload === 0) {
      return {
        text: (
          <Trans
            i18nKey={'contract.producer.action_text'}
            values={{ date: dateMonthYear(moment(contractData?.sentAt).add(DAY_30, 'days')) }}
            components={{ bold: <b /> }}
          />
        ),
        type: 'warning-medium',
      };
    } else if (REMAINING_DAY_30) {
      return {
        text: (
          <Trans
            i18nKey={'contract.producer.reminder_text'}
            values={{ date: dateMonthYear(moment(contractData?.sentAt).add(DAY_30, 'days')) }}
            components={{ bold: <b /> }}
          />
        ),
        type: 'neutral',
      };
    }
  };

  return (
    <>
      <Card>
        <div className="organisation-contract__second-step">
          <div>{title}</div>
          {!producer && (
            <div className="organisation-contract__second-step--tag">
              {handleTag(contractData?.status === VERIFIED)}
            </div>
          )}
        </div>
        {!isAdmin ? (
          <>
            <div className="organisation-contract__eligibility">
              {t('contract.declare_suggestion')}
            </div>
            <div>
              <div className="organisation-contract__address-wrapper">
                {t('contract.office_address_text')}
              </div>
              <div className="organisation-contract__address">
                <Icon name="location" color="tertiary" size="medium" />
                <span className="organisation-contract__address-text">
                  <Trans i18nKey="contract.office_address" />
                </span>
              </div>
              <div className="organisation-contract__address">
                <Icon name="call" color="tertiary" size="medium" />
                <span className="organisation-contract__address-text">
                  {t('contract.office_number')}
                </span>
              </div>
            </div>
          </>
        ) : (
          <div className="organisation-contract__eligibility">
            {producer ? getHeaderText() : t('contract.admin_verify_request')}
          </div>
        )}

        {contractData && contractData?.contract && (
          <div className="choose-file__contract-footer">
            <div className="choose-file__contract-filename">
              <Link
                modifier="default"
                size="tiny"
                openNewTab={true}
                href={contractData.contract.url}
              >
                {contractData.contract.filename}
              </Link>
            </div>

            <div className="choose-file__contract-vertical--line__right" />
            <div>
              {bytesToMegabytes(contractData?.contract?.sizeInByte)} {t('shared.mb')}
            </div>
            {contractData?.approvedAt && !producer && (
              <>
                <div className="choose-file__contract-vertical--line__right" />
                <div>{dateMonthYear(contractData.approvedAt)}</div>
              </>
            )}
          </div>
        )}

        {!isAdmin && contractData?.contract && (
          <div className="organisation-contract__save">
            <Link
              type="button"
              modifier="default"
              size="small"
              openNewTab={true}
              href={'contractData.contract.url'}
              disabled={isDeactivated}
            >
              <>
                <Icon color="tertiary" name="downloadFile" />
                <span className="choose-file__contract-delete--text">
                  {t('contract.download_button')}
                </span>
              </>
            </Link>
          </div>
        )}

        {isAdmin && producer && getDateText()}

        {isAdmin && producer && (
          <Alert
            className={classNames({
              'organisation-contract__terminate-icon': contractData?.status === TERMINATED,
            })}
            isVisible={
              (contractData?.status === CANCELLED && !contractData?.contract) ||
              (contractData?.status === VERIFIED && contractData?.terminatedAt) ||
              contractData?.status === TERMINATED ||
              contractData?.status === ABORTED ||
              REMAINING_DAY_30 ||
              REMAINING_DAY_0
            }
            isIconVisible={true}
            hideClose={true}
            type={getAlertText()?.type}
          >
            {getAlertText()?.text}
          </Alert>
        )}

        <UploadContract contractData={contractData} />
      </Card>

      <WarningModal
        onOutsideClick={() => setIsReinstateModalOpen(false)}
        isVisible={isReinstateModalOpen}
        title={t('contract.producer.reinstate_modal.warning_title')}
        content={t('contract.producer.reinstate_modal.warning_description')}
        confirmActionText={t('contract.producer.reinstate_contract')}
        cancelActionText={t('shared.action.cancel')}
        onCancel={() => setIsReinstateModalOpen(false)}
        onConfirm={onReinstate}
      />
    </>
  );
};

ContractVerification.defaultProps = {
  contractData: null,
};

ContractVerification.propTypes = {
  title: PropTypes.string.isRequired,
  contractData: PropTypes.shape(),
};

export default ContractVerification;
