import CheckBox from 'components/base-components/CheckBox/CheckBox';
import PropTypes from 'prop-types';
import React from 'react';
import BooleanFieldWrapper from './boolean-field-wrapper.component';

const CheckboxContent = ({ input, label, name, onChange }) => (
  <CheckBox
    isChecked={input.checked}
    size="tiny"
    label={label}
    onChange={e => {
      input.onChange(e.target.checked ? name : null);
      onChange(name, e.target.checked);
    }}
  />
);

CheckboxContent.propTypes = {
  input: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CheckboxWithIcon = ({ label, icon, fieldName, isChecked, name, onChange }) => {
  return (
    <BooleanFieldWrapper
      name={fieldName}
      initialValue={isChecked ? name : null}
      icon={icon}
      type="checkbox"
    >
      <CheckboxContent label={label} name={name} onChange={onChange} />
    </BooleanFieldWrapper>
  );
};

CheckboxWithIcon.defaultProps = {
  icon: null,
  isChecked: false,
  onChange: () => {},
};

CheckboxWithIcon.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  fieldName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CheckboxWithIcon;
