import React from 'react';
import PropTypes from 'prop-types';
import { WARNING, SUCCESS } from 'utils/status';
import classNames from 'classnames';
import Icon from '../Icon';

const Step = props => {
  const { stepName, isActive, isDone, stepIndex, isLastStepAccepted, stepStates } = props;
  const successState = stepStates[stepIndex] === SUCCESS && !isActive;
  const errorState = stepStates[stepIndex] === WARNING;

  return (
    <div
      role="presentation"
      className={classNames('stepper__body-step', {
        'stepper__body-step--active': isActive,
        'stepper__body-step--done': isDone || isLastStepAccepted || successState,
        'stepper__body-step--warning': errorState,
      })}
    >
      <div className="stepper__body-step-index">
        {errorState ? (
          <Icon className="stepper__body-step-check-icon" name="highPriority" />
        ) : (
          <>
            {isDone || isLastStepAccepted || successState ? (
              <Icon fill="#72B469" className="stepper__body-step-check-icon" name="check" />
            ) : (
              <Icon fill="#00393F29" className="stepper__body-step-check-icon" name="minimize" />
            )}
          </>
        )}
      </div>
      <div className="stepper__body-step-name">{stepName}</div>
      {stepIndex > 0 ? <div className="stepper__body-step-connector" /> : null}
    </div>
  );
};

Step.defaultProps = {
  isActive: false,
  isDone: false,
  isLastStepAccepted: false,
  stepStates: [],
};

Step.propTypes = {
  stepName: PropTypes.string.isRequired,
  stepIndex: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  isDone: PropTypes.bool,
  isLastStepAccepted: PropTypes.bool,
  stepStates: PropTypes.arrayOf(PropTypes.number),
};

export default Step;
