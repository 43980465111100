import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import styles from 'components/pdf/styles/styles';

export const ListItem = ({ children, prefix, listStyle, style, prefixStyle, textStyle }) => (
  <View style={[styles.listItemContainer, style]}>
    {prefix ? (
      <Text style={{ ...styles.listPrefix, ...styles.text }}>{prefix}</Text>
    ) : (
      <View style={[styles.listPrefixContainer, prefixStyle]} wrap={false}>
        <View style={styles[listStyle]} />
      </View>
    )}
    <Text style={[styles.text, textStyle]}>{children}</Text>
  </View>
);

export const Section = ({ children, title }) => (
  <View style={styles.section}>
    <Text style={styles.heading2}>{title}</Text>
    {children}
  </View>
);

export const SubSection = ({ children, title, style }) => (
  <View style={[styles.subSection, style]}>
    <Text style={styles.heading2}>{title}</Text>
    {children}
  </View>
);

export const InputField = ({ title, style, value, valueType, lineStyle }) => (
  <View style={style}>
    {valueType === 'text' ? <Text style={{ fontSize: 10 }}>{value}</Text> : <View>{value}</View>}
    <View style={[styles.horizontalLine, lineStyle, value && styles.checklistMarginZero]} />
    <Text style={{ fontSize: 8, marginTop: 4 }}>{title}</Text>
  </View>
);

export const Break = ({ count }) => '\n'.repeat(count);
export const HorizontalLine = () => <View style={styles.hr} />;

ListItem.defaultProps = {
  prefix: null,
  style: {},
  listStyle: 'square',
  prefixStyle: {},
  textStyle: {},
};

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  prefix: PropTypes.string,
  prefixStyle: PropTypes.shape(),
  textStyle: PropTypes.shape(),
  listStyle: PropTypes.oneOf(['bullet', 'square', 'largeSquare', 'dash']),
  style: PropTypes.shape(),
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

SubSection.defaultProps = {
  style: null,
};

SubSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  style: PropTypes.shape(),
};

InputField.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.node,
  valueType: PropTypes.string,
  style: PropTypes.shape(),
  lineStyle: PropTypes.shape(),
};

InputField.defaultProps = {
  style: null,
  lineStyle: null,
  value: null,
  valueType: null,
};

Break.defaultProps = {
  count: 1,
};

Break.propTypes = {
  count: PropTypes.number,
};
