import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';

const DateTime = ({ dateTime }) => {
  return <span>{dateTime}</span>;
};

DateTime.defaultProps = {
  dateTime: moment().format(),
  format: 'long',
  locales: 'en',
  localOverrides: {},
};

DateTime.propTypes = {
  dateTime: PropTypes.string,
  format: PropTypes.oneOf(['long', 'short']),
  locales: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  localOverrides: PropTypes.object,
};

export default DateTime;
