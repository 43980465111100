export const dayDifference = date => {
  const date1 = new Date(date);
  const date2 = new Date();
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);
  const differenceInMilliseconds = date1.getTime() - date2.getTime();
  const minute = 1000 * 60;
  const hour = minute * 60;
  const day = hour * 24;
  return Math.floor(differenceInMilliseconds / day);
};
