import routes from 'utils/routes';
import { CONTROL_BODY_SURVEY } from 'utils/tag-types';
import { apiSlice } from '../index';

const controlBodySurveysApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getControlBodySurveys: build.query({
      query: params => routes.controlBodySurveys.collection(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
    getSchedule: build.query({
      query: params => routes.controlBodySurveys.schedules.entity(params),
    }),
  }),
  overrideExisting: false,
});

export const { useGetControlBodySurveysQuery, useGetScheduleQuery } = controlBodySurveysApi;
