import { Document, Image, Page, View } from '@react-pdf/renderer';
import BioLogo from 'assets/bio_logo_large.png';
import PropTypes from 'prop-types';
import React from 'react';
import { STATUS_UK, STATUS_VK } from 'utils/organization-certificate-status';
import Footer from '../recognition/footer.view';
import tvdStyles from '../styles/tvd-styles';
import FirstPage from './first-page/first-page.view';
import PPAInfo from './ppa-info.view';
import SecondPage from './second-page/second-page.view';
import StickerPage from './sticker-page/sticker-page.view';

const TVDStickerPDFDocument = ({ organization, barcodeImageUrl, tvdNumber, labels, address }) => {
  const status = organization.certificationStatus === STATUS_VK ? STATUS_VK : STATUS_UK;

  return (
    <Document>
      <Page size="A4" style={[tvdStyles.page, { paddingBottom: 20 }]}>
        <View>
          <Image style={tvdStyles.headerLogo} src={BioLogo} />
        </View>
        <View style={tvdStyles.body}>
          <PPAInfo />
          <FirstPage organization={organization} address={address} status={status} />
          <SecondPage status={status} />
        </View>
        <Footer customStyle={tvdStyles.footer} />
      </Page>
      <Page size="A4" style={tvdStyles.page}>
        <StickerPage
          organization={organization}
          barcodeImageUrl={barcodeImageUrl}
          tvdNumber={tvdNumber}
          address={address}
          labels={labels}
        />
      </Page>
    </Document>
  );
};

TVDStickerPDFDocument.propTypes = {
  organization: PropTypes.shape().isRequired,
  barcodeImageUrl: PropTypes.string.isRequired,
  tvdNumber: PropTypes.string.isRequired,
  address: PropTypes.shape().isRequired,
  labels: PropTypes.array.isRequired,
};

export default TVDStickerPDFDocument;
