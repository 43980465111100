import classNames from 'classnames';
import Icon from 'components/base-components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

const BooleanFieldWrapper = ({ icon, children, ...fieldProps }) => {
  return (
    <Field {...fieldProps}>
      {({ input }) => (
        <div
          className={classNames('farmland__checkbox', {
            'farmland__checkbox--checked': input.checked,
          })}
          data-testid="boolean-field-wrapper"
        >
          {React.cloneElement(children, { input })}
          <Icon name={icon} size="large" color="neutral" />
        </div>
      )}
    </Field>
  );
};

BooleanFieldWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BooleanFieldWrapper;
