export const screenSizes = {
  largeDesktop: 1200,
  desktopTab: 991,
  tabMobile: 767,
  mobile: 480,
  smallMobile: 360,
}

export function isLargeDesktop(currentScreenSize) {
  return currentScreenSize >= screenSizes.largeDesktop
}

export function isDesktopTab(currentScreenSize) {
  return (
    currentScreenSize >= screenSizes.desktopTab &&
    currentScreenSize < screenSizes.largeDesktop
  )
}

export function isTabMobile(currentScreenSize) {
  return (
    currentScreenSize >= screenSizes.tabMobile &&
    currentScreenSize < screenSizes.desktopTab
  )
}

export function isMobile(currentScreenSize) {
  return (
    currentScreenSize >= screenSizes.mobile &&
    currentScreenSize < screenSizes.tabMobile
  )
}

export function isSmallMobile(currentScreenSize) {
  return currentScreenSize < screenSizes.mobile
}
