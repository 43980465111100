import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import OrganizationName from '../organization-name';

const ParentOrganisation = ({ organization, parentOrganisationId }) => {
  const { t } = useTranslation();

  return (
    <>
      {parentOrganisationId ? (
        <OrganizationName
          className="organisation__table-row--violet-color"
          key={`main-org-${parentOrganisationId}`}
          organizationId={parentOrganisationId}
        />
      ) : (
        <>
          {organization.parentId ? (
            <OrganizationName
              className="organisation__table-row--violet-color"
              key={`main-org-${organization.parentId}`}
              organizationId={organization.parentId}
            />
          ) : (
            t('shared.no_availability')
          )}
        </>
      )}
    </>
  );
};

ParentOrganisation.propTypes = {
  organization: PropTypes.shape().isRequired,
  parentOrganisationId: PropTypes.number.isRequired,
};

export default ParentOrganisation;
