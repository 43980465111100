import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';
import { t } from 'i18next';
import { NO, NOT_RELEVANT, YES } from 'utils/control-body-survey';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

const YesNoInput = ({
  className,
  disabled,
  error,
  label,
  name,
  onChange,
  value,
  showNotRelevant,
}) => {
  const onSelectionChange = value => {
    if (disabled) return;
    onChange(value);
  };

  return (
    <div className={classNames('yes-no-input', className)}>
      <div className="yes-no-input__container">
        <div className="yes-no-input__label">
          {label && <div className="yes-no-input__label--text">{label}</div>}
          {error && (
            <div className="yes-no-input__label--error">
              <Tooltip content={error} type="danger" position="bottom-right" gap={0}>
                <Icon name="invalid" color="danger" />
              </Tooltip>
            </div>
          )}
        </div>
        <div className="yes-no-input__options">
          <div
            className={classNames('yes-no-input__option', {
              'yes-no-input__option--disabled': disabled,
            })}
            onClick={() => onSelectionChange(YES)}
          >
            <div
              className={classNames('yes-no-input__option-checkbox', {
                'yes-no-input__option-checkbox--selected': value === YES,
              })}
            >
              <Icon className="yes-no-input__option-checkbox--icon" name="check" />
            </div>
            <span>{t('shared.action.yes')}</span>
          </div>
          <div
            className={classNames('yes-no-input__option', {
              'yes-no-input__option--disabled': disabled,
            })}
            onClick={() => onSelectionChange(NO)}
          >
            <div
              className={classNames('yes-no-input__option-checkbox', {
                'yes-no-input__option-checkbox--selected': value === NO,
              })}
            >
              <Icon className="yes-no-input__option-checkbox--icon" name="check" />
            </div>
            <span>{t('control_body.survey.shared.no')}</span>
          </div>
          {showNotRelevant && (
            <div
              className={classNames('yes-no-input__option', {
                'yes-no-input__option--disabled': disabled,
              })}
              onClick={() => onSelectionChange(NOT_RELEVANT)}
            >
              <div
                className={classNames('yes-no-input__option-checkbox', {
                  'yes-no-input__option-checkbox--selected': value === NOT_RELEVANT,
                })}
              >
                <Icon className="yes-no-input__option-checkbox--icon" name="check" />
              </div>
              <span>{t('control_body.survey.shared.not_relevant')}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

YesNoInput.defaultProps = {
  className: null,
  disabled: false,
  error: null,
  label: null,
  onChange: () => {},
  value: null,
  showNotRelevant: false,
};

YesNoInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOf([YES, NO, NOT_RELEVANT, null]),
  showNotRelevant: PropTypes.bool,
};

export default YesNoInput;
