import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TableHeader = ({ className, children, align }) => (
  <th
    className={classNames(
      'table__field',
      'table__field--header',
      className,
      `table__field--align-${align}`,
    )}
  >
    {children}
  </th>
);

TableHeader.defaultProps = {
  className: null,
  align: 'left',
};

TableHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  align: PropTypes.oneOf(['left', 'center', 'right']),
};

export default TableHeader;
