import React, { useState } from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from 'components/base-components/Button';
import { useGetOrganizationQuery, useUpdateOrganizationMutation } from 'api/organizations';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { useParams } from 'react-router';
import Card from 'components/base-components/Card';
import arrayMutators from 'final-form-arrays';
import { handleContactSumbit } from './contact-and-address/change-contact/contact-submit';

const IndividualForm = ({ children, warningModalNeeded, type }) => {
  const [enableWarningModal, setEnableWarningModal] = useState(false);
  const { id } = useParams();
  const navigate = useCustomNavigate();
  const [updateOrganization] = useUpdateOrganizationMutation();
  const formData = new FormData();
  const { data: organisation = {} } = useGetOrganizationQuery(id);

  const redirectToTheRoot = () => navigate.openOrganisationView(id, 'overview');

  const onUpdateOrganization = (values, form) => {
    if (type === 'language') {
      formData.append('language', values.language);
      formData.append('second_spoken_language', values?.secondSpokenLanguage);
    }

    if (type === 'contact') {
      handleContactSumbit(organisation, values, formData);
    }

    const organizationParams = { formData: formData, id };
    return updateOrganization(organizationParams)
      .unwrap()
      .then(() => redirectToTheRoot())
      .catch(({ data: { errors } }) => {
        return { email: errors?.organization?.emailAddresses };
      });
  };

  const handleSubmit = (values, form) => {
    if (warningModalNeeded) {
      setEnableWarningModal(true);
    } else {
      return onUpdateOrganization(values, form);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{}}
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit, values, submitErrors, form }) => (
        <div className="organisation__form-container">
          <Card className="organisation__form--main-card">
            {React.cloneElement(children, {
              formData,
              organisation,
              enableWarningModal,
              setEnableWarningModal,
              updateOrganization: onUpdateOrganization,
            })}
            <div className="margin-top-20">
              <Button
                className="margin-right-8"
                type="success"
                label={t('shared.action.save')}
                size="small"
                onClick={() => handleSubmit(values, form)}
              />
              <Button label={t('shared.action.cancel')} size="small" onClick={redirectToTheRoot} />
            </div>
          </Card>
        </div>
      )}
    />
  );
};

IndividualForm.defaultProps = {
  warningModalNeeded: true,
  type: null,
};

IndividualForm.propTypes = {
  children: PropTypes.node.isRequired,
  warningModalNeeded: PropTypes.bool,
  type: PropTypes.string,
};

export default IndividualForm;
