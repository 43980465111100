import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import Card from 'components/base-components/Card';
import Textarea from 'components/base-components/Textarea';
import { t } from 'i18next';
import { useGetOrganizationQuery, useUpdateAddressMutation } from 'api/organizations';
import WarningModal from 'components/shared-components/modal/warning';
import { useParams, useSearchParams } from 'react-router-dom';
import addressView from 'utils/address-view';
import Button from 'components/base-components/Button';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { GENERAL } from 'utils/organisation-create-options';
import AddressField from '../AddressField';

const ChangeOrganisationAddressForm = () => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const navigationLinks = useCustomNavigate();
  const { id } = useParams();
  const addressType = searchParams.get('type');
  const { data: organisation = {} } = useGetOrganizationQuery(id);
  const [updateAddress, { isLoading, isSuccess, isError, requestId }] = useUpdateAddressMutation();

  const onSubmit = values => {
    let formData = new FormData();
    const addressData = {
      line1: values?.address?.line1,
      line2: values?.address?.line2,
      main: addressType === GENERAL,
      zip_code: values?.address?.zipCode,
      city: values?.address?.city,
      canton: values?.address?.canton,
      country_code: values?.address?.country?.value || 'CH',
      address_type: addressType,
      reason_to_change: values?.reasonToChange,
    };

    Object.keys(addressData).forEach(key => {
      formData.append(key, addressData[key] || '');
    });

    updateAddress({
      formData,
      organisationId: id,
    })
      .unwrap()
      .catch(({ data: { errors } }) => {
        let errorMessages;

        const errorAttributes = {
          zipCode: errors?.addressZipCode,
          line1: errors?.addressLine1,
          city: errors?.city,
          canton: errors.canton,
        };

        errorMessages = { ...errorMessages, address: errorAttributes };

        return errorMessages;
      });
  };

  const address = addressType
    ? addressView(organisation.addresses?.find(address => address?.type === addressType))
    : t('common.not_applicable');

  const addressTyeOptions = {
    general: t('organisation.change_address.types.general'),
    invoice: t('organisation.change_address.types.invoice'),
    mailing: t('organisation.change_address.types.mailing'),
  };

  return (
    <div className="grid">
      <Card className="col-10 user__invite-card">
        <div className="organisation__change-address--request">
          {t('organisation.change_address.request_header')}
        </div>
        <div className="organisation__change-address--description">
          {t('organisation.change_address.request_description')}
        </div>
        <div className="organisation__change-address--request">
          {t('organisation.change_address.current_address', {
            addressType: addressTyeOptions[addressType],
          })}
        </div>
        <div className="organisation__change-address--current">{address}</div>
        <div className="organisation__change-address--new">
          {t('organisation.change_address.new_address', {
            addressType: addressTyeOptions[addressType],
          })}
        </div>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <AddressField parentKey="address" />
                <Field name="reasonToChange">
                  {({ input }) => (
                    <Textarea
                      className="organisation-checklist__quarter-comment"
                      label={t('organisation.change_address.change_reason_label')}
                      placeholder={t('organisation.change_address.change_reason_placeholder')}
                      {...input}
                    />
                  )}
                </Field>
                <div className="control-body-form__buttons col-12 col-bleed-x">
                  <Button
                    label={t('common.submit')}
                    type="success"
                    size="small"
                    disabled={submitting}
                    onClick={() => setIsConfirmModalOpen(true)}
                  />
                  <Button
                    className="control-body-form__buttons-cancel"
                    label={t('common.cancel_button')}
                    size="small"
                    onClick={() => navigationLinks.openOrganisationView(id)}
                  />
                </div>
                <WarningModal
                  isVisible={isConfirmModalOpen}
                  onConfirm={() => {
                    setIsConfirmModalOpen(false);
                    onSubmit(values);
                  }}
                  onCancel={() => setIsConfirmModalOpen(false)}
                  title={t('organisation.change_address.modal.warning_title')}
                  content={t('organisation.change_address.modal.warning_description')}
                  confirmActionText={t('common.confirm_button')}
                  cancelActionText={t('shared.action.cancel')}
                />
                <SubmitModal
                  isLoading={isLoading}
                  isSuccess={isSuccess}
                  isError={isError}
                  requestId={requestId}
                  successContent={t('organisation.change_address.modal.success_description')}
                  successTitle={t('organisation.change_address.modal.success_title')}
                  onSuccess={() => navigationLinks.organisationViewPage(id, 'overview')}
                  onFailed={() => {}}
                  showError={true}
                  showLoader={true}
                />
              </form>
            );
          }}
        />
      </Card>
    </div>
  );
};

export default ChangeOrganisationAddressForm;
