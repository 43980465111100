const handleMoveToProductionStep = (values, currentTab, movement) => {
  const filteredSelectedTabsArray = values.production?.filter(value => value !== null);
  const currentTabIndex = filteredSelectedTabsArray?.indexOf(currentTab);
  const nextTabIndex = movement === 'next' ? currentTabIndex + 1 : currentTabIndex - 1;
  const nextTabValue = filteredSelectedTabsArray[nextTabIndex];

  return nextTabValue;
};

export default handleMoveToProductionStep;
