import { useGetDairyFarmsQuery } from 'api/producers/productions';
import Card from 'components/base-components/Card';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import SharedProductionComponent from './shared/shared-production.component';

const DairyFarmsComponent = ({ id }) => {
  const { data: dairyFarms = [] } = useGetDairyFarmsQuery({ productionId: id }, { skip: !id });

  const iconMapper = {
    milk_cow: 'cow',
    dairy_ewe: 'dairyEwe',
    dairy_goat: 'dairyGoat',
  };

  return (
    <Card className="operations__production--content">
      <div className="flex-wrap">
        {dairyFarms?.map(dairy => (
          <SharedProductionComponent
            title={t(`milk_production.types.${dairy?.subType}`)}
            icon={iconMapper[dairy?.subType]}
          >
            <div className="margin-top-10">
              <div className="flex-space-between margin-top-10">
                <div>{t(`milk_production.labels.number_of_animals.${dairy?.subType}`)}</div>
                <div>{dairy?.productionDetails?.numberOfAnimals}</div>
              </div>
              <div className="flex-space-between margin-top-10">
                <div>{t(`milk_production.labels.quantity.${dairy?.subType}`)}</div>
                <div>{dairy?.productionDetails?.milkProducedKg}</div>
              </div>
              {dairy?.subType === 'milk_cow' && (
                <div className="flex-space-between margin-top-10">
                  <div>{t('operations.shared.milk_processing_source')}</div>
                  <div>
                    {dairy?.productionDetails?.organicMilkProducerOrganization ||
                      t(`milk_production.source.${dairy?.productionDetails?.milkProcessingSource}`)}
                  </div>
                </div>
              )}
            </div>
          </SharedProductionComponent>
        ))}
      </div>
    </Card>
  );
};

DairyFarmsComponent.defaultProps = {
  id: null,
};

DairyFarmsComponent.propTypes = {
  id: PropTypes.node,
};

export default DairyFarmsComponent;
