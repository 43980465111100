import { Image, Text, View } from '@react-pdf/renderer';
import Mic from 'assets/mic.png';
import styles from 'components/pdf/styles/checklist-styles';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { FINAL } from 'utils/verification-status';

const AudioResponse = ({ audioResponses, reportType, comments }) => {
  const audioQuestions = {
    one: t('control_body.survey.form.first_audio_ques'),
    two: t('control_body.survey.form.second_audio_ques'),
    three: t('control_body.survey.form.third_audio_ques'),
  };

  return (
    <>
      <Text style={styles.checklistMarginForty}>
        <Text style={styles.bold}>8. </Text> {t('negative_report.information')}
      </Text>
      <Text style={styles.checklistMarginTwenty}>{t('control_body.survey.form.audio_header')}</Text>
      <View>
        {audioResponses?.length > 0 &&
          audioResponses.map((audio, index) => (
            <>
              <View style={styles.checkListFlexSpaceBetween}>
                <Text style={styles.checklistMarginTwenty}>
                  {index + 1}. {audioQuestions[audio.audioAnswer]}
                </Text>
              </View>
              <View
                style={{ ...styles.checkListFlexSpaceBetween, ...styles.checklistMarginTwenty }}
              >
                <Text style={styles.checklistMarginFiveLeft}>
                  <Image style={{ ...styles.checklistMarginTwenty }} src={Mic} />
                  <Text style={styles.checklistMarginTwentyLeft}>
                    {audio.audio.filename || t('common.not_applicable')}
                  </Text>
                </Text>
                <Text>{t('control_body.survey.form.audio.satisfied')}</Text>
                <Text>{audio.satisfied ? t('shared.action.yes') : t('control_body.survey.shared.no')} </Text>
              </View>
              {reportType === FINAL && audio.comment && (
                <View style={styles.audioComment}>
                  <Text>
                    {t('control_body.survey.form.reason_of_change')} {audio.comment}
                  </Text>
                </View>
              )}
            </>
          ))}
      </View>
      {comments.audioResponses && (
        <View style={styles.baseComment}>
          <Text>
            {t('control_body.survey.form.comment_from_auditor')} {comments.audioResponses}
          </Text>
        </View>
      )}
    </>
  );
};

AudioResponse.propTypes = {
  audioResponses: PropTypes.array.isRequired,
  reportType: PropTypes.string.isRequired,
  comments: PropTypes.shape().isRequired,
};

export default AudioResponse;
