import React from 'react';
import { useSearchParams } from 'react-router-dom';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { useGetCurrentUserQuery } from 'api/users';
import { Tabs } from 'components/base-components/RTabs';
import { useTranslation } from 'react-i18next';
import MyTasks from './task-lists/my-tasks.component.component';
import OrganisationTasks from './task-lists/organisation-tasks.component';
import BioSuisseTasks from './task-lists/bio-suisse-tasks.component';
import AllTasks from './task-lists/all-tasks.component';
import ReportedTasks from './task-lists/reported-tasks.component';
import './task-management.styles.scss';

const TaskManagementComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams({ tab: 'all_task' });
  const { data: currentUser, isSuccess: isCurrentUserFetched } = useGetCurrentUserQuery();
  const { t } = useTranslation();

  const getTabMappers = () => {
    return isBioSuisseAdmin(currentUser)
      ? {
          all_task: 0,
          organisation_task: 1,
          my_task: 2,
          reported_task: 3,
          biosuisse_task: 4,
        }
      : {
          all_task: 0,
          organisation_task: 1,
          my_task: 2,
        };
  };

  const TAB_MAPPER = getTabMappers();

  const currentTab = searchParams.get('tab');

  const onChangeTab = selectedTabKey => {
    const selectedTab = Object.keys(TAB_MAPPER).find(tab => TAB_MAPPER[tab] === selectedTabKey);
    setSearchParams({ tab: selectedTab });
  };

  const tabs_for_admin = [
    {
      title: t('tasks.tab.all_tasks'),
      key: TAB_MAPPER.all_task,
      getContent: () => <AllTasks />,
    },
    {
      title: t('tasks.tab.organisation_tasks'),
      key: TAB_MAPPER.organisation_task,
      getContent: () => <OrganisationTasks />,
    },
    {
      title: t('tasks.tab.my_tasks'),
      key: TAB_MAPPER.my_task,
      getContent: () => <MyTasks />,
    },
    {
      title: t('tasks.tab.reported_tasks'),
      key: TAB_MAPPER.reported_task,
      getContent: () => <ReportedTasks />,
    },
    {
      title: t('tasks.tab.biosuisse_tasks'),
      key: TAB_MAPPER.biosuisse_task,
      getContent: () => <BioSuisseTasks />,
    },
  ];

  const tabs_for_user = [
    {
      title: t('tasks.tab.all_tasks'),
      key: TAB_MAPPER.all_task,
      getContent: () => <AllTasks />,
    },
    {
      title: t('tasks.tab.organisation_tasks'),
      key: TAB_MAPPER.organisation_task,
      getContent: () => <OrganisationTasks />,
    },
    {
      title: t('tasks.tab.my_tasks'),
      key: TAB_MAPPER.my_task,
      getContent: () => <MyTasks />,
    },
  ];

  const tabs =
    isCurrentUserFetched && isBioSuisseAdmin(currentUser) ? tabs_for_admin : tabs_for_user;

  return (
    <div className="task__wrapper">
      <Tabs
        className="task__tab"
        controlled={true}
        selectedTabKey={TAB_MAPPER[currentTab]}
        items={tabs}
        onChange={onChangeTab}
      />
    </div>
  );
};

export default TaskManagementComponent;
