import { useGetProductionByYearQuery, useGetProductionsQuery } from 'api/producers/productions';
import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import { PillTabs } from 'components/base-components/PillTabs';
import ProducerTypeWithIcon from 'components/organisations/form/producer/shared/production-type-with-icon.component';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { isNonEmptyObject } from 'utils/check-object-length';
import { PLANT_CULTIVATION_FROM_BACKEND } from 'utils/plant-cultivation';
import AnimalComponent from './animal.component';
import BeeKeepingComponent from './bee-keeping.component';
import DairyFarmsComponent from './dairy-farms.component';
import EggProductionComponent from './egg-production.component';
import FarmlandComponent from './farmland.component';
import FishComponent from './fish.component';
import GardenItemComponent from './garden-item.component';
import GreenAreasComponent from './green-areas.component';
import MeatProductionDataComponent from './meat-production-data.component';
import './operations.styles.scss';
import PlantCultivationDataComponent from './plant-cultivation-data.component';
import PrivateParksComponent from './private-parks.component';

const OperationComponent = ({ year, emptyCardText, yearText }) => {
  const { id } = useParams();
  const [isCardOpen, setIsCardOpen] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: productionDataCollection } = useGetProductionsQuery(
    { organizationId: id },
    { skip: !id },
  );
  const { data, isLoading, isSuccess } = useGetProductionByYearQuery(
    {
      organisationId: id,
      year,
    },
    { skip: !year },
  );

  const productionData = isSuccess ? data : {};

  const currentTab = searchParams.get('status');
  const currentSection = searchParams.get('section');
  const currentSeason = searchParams.get('season');

  const TAB_MAPPER = {
    plant_cultivation: 0,
    meat_production: 1,
    egg: 2,
    milk: 3,
    fish: 4,
    bee: 5,
    animal: 6,
    garden: 7,
    greenArea: 8,
    privatePark: 9,
  };

  const TAB_COMPONENT = {
    plant_cultivation: <PlantCultivationDataComponent id={productionData?.id} />,
    meat_production: <MeatProductionDataComponent id={productionData?.id} />,
    egg: <EggProductionComponent id={productionData?.id} />,
    milk: <DairyFarmsComponent id={productionData?.id} />,
    fish: <FishComponent id={productionData?.id} />,
    bee: <BeeKeepingComponent id={productionData?.id} />,
    animal: <AnimalComponent id={productionData?.id} />,
    garden: <GardenItemComponent id={productionData?.id} />,
    green_area: <GreenAreasComponent id={productionData?.id} />,
    private_park: <PrivateParksComponent id={productionData?.id} />,
  };

  const onChangeTab = selectedTabKey => {
    const selectedTab = Object.keys(TAB_MAPPER).find(tab => TAB_MAPPER[tab] === selectedTabKey);
    setSearchParams({ status: selectedTab, section: currentSection, season: currentSeason });
  };

  const isProductionDataEmpty = productionDataCollection?.productTypes?.length === 0;

  if (!isLoading && !isNonEmptyObject(productionData)) {
    return <EmptyContentPlaceholder iconName="plant_little" text={emptyCardText} />;
  }

  if (!productionDataCollection?.productTypes) {
    return <EmptyContentPlaceholder iconName="add" text={t('operations.no_data_available')} />;
  }

  const filteredComponents = Object.keys(TAB_COMPONENT).filter(tabName =>
    productionDataCollection?.productTypes?.includes(PLANT_CULTIVATION_FROM_BACKEND[tabName]),
  );

  return (
    <>
      <div className="margin-bottom-20 font-bold">{yearText}</div>
      <Card className="operations__main-card">
        <div className="flex-space-between">
          <div className="font-bold">{t('operations.self_declared')}</div>
          <IconButton
            icon={<Icon name={isCardOpen ? 'upArrow' : 'downArrow'} />}
            isIconOnly={true}
            onClick={() => setIsCardOpen(prev => !prev)}
          />
        </div>
        {isCardOpen && (
          <>
            <hr className="organisation-checklist__checkbox-horizontal-line" />
            <FarmlandComponent farmlandData={productionData} />
            <div className="font-bold margin-top-10 margin-bottom-10">
              {t('organisation.form.step.production_data')}
            </div>
            {isProductionDataEmpty ? (
              <div className="product-cultivation__empty-description">
                {t('egg_production.produce_details_description')}
              </div>
            ) : (
              <PillTabs
                useTransitionOnChange={true}
                controlled={true}
                selectedTabKey={TAB_MAPPER[currentTab]}
                items={filteredComponents.map(component => {
                  return {
                    title: (
                      <ProducerTypeWithIcon
                        name={component}
                        i18nTranslation={t(`plant_cultivation.tab.${component}`)}
                      />
                    ),
                    key: TAB_MAPPER[component],
                    getContent: () => TAB_COMPONENT[component],
                  };
                })}
                onChange={onChangeTab}
              />
            )}
          </>
        )}
      </Card>
    </>
  );
};

OperationComponent.defaultProps = {
  year: null,
  emptyCardText: null,
  yearText: null,
};

OperationComponent.propTypes = {
  year: PropTypes.node,
  emptyCardText: PropTypes.node,
  yearText: PropTypes.node,
};

export default OperationComponent;
