import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BreadcrumbIndustry from 'utils/breadcrum-helper/breadcrumb-industry';

const BreadcrumbItem = props => {
  const { className, children, linkUrl, lastChild, dividerSymbol } = props;

  return (
    <>
      <li className={classNames('breadcrumbs__item', className)}>
        <BreadcrumbIndustry children={children} linkUrl={linkUrl} />
      </li>
      {!lastChild && <span className="breadcrumbs__barrier">{dividerSymbol}</span>}
    </>
  );
};

BreadcrumbItem.defaultProps = {
  className: null,
  lastChild: false,
  dividerSymbol: '/',
};

BreadcrumbItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  linkUrl: PropTypes.string.isRequired,
  lastChild: PropTypes.bool,
  dividerSymbol: PropTypes.node,
};

export default BreadcrumbItem;
