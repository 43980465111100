import EmphasisTag from 'components/base-components/EmphasisTag';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const InvoiceStatusTag = ({ status }) => {
  const { t } = useTranslation();

  const types = {
    open: 'warning',
    overdue: 'danger',
    paid: 'success',
    pending: 'warning',
    draft: 'normal',
    cancelled: 'disabled',
    closed: 'accent',
    scheduled: 'accent',
    completed: 'success',
  };

  if (!types[status]) return t('shared.not_available');

  return <EmphasisTag type={types[status]} size="tiny" text={t(`invoice.tag_status.${status}`)} />;
};

InvoiceStatusTag.propTypes = {
  status: PropTypes.string.isRequired,
};

export default InvoiceStatusTag;
