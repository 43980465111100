import React from 'react';
import Card from 'components/base-components/Card';
import Title from 'components/base-components/Typography/components/Title';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { useTranslation } from 'react-i18next';
import { useGetMessagesQuery } from 'api/messages';
import { Link } from 'react-router-dom';
import MessageComponent from './message.component';

const MessageList = () => {
  const navigationLinks = useNavigationLinks();
  const { t } = useTranslation();

  const searchParams = {
    q: '',
    page: 1,
    per_page: 5,
  };

  const { data: messageDataCollection, isSuccess: isMessageFetched } =
    useGetMessagesQuery(searchParams);
  const messageData = (isMessageFetched && messageDataCollection.collection) || [];

  return (
    <div className="dashboard__container-item">
      <Card>
        <Title className="dashboard__messages-title" weight="boldest" level={6}>
          {t('dashboard.my_message')}
        </Title>
        <MessageComponent messageData={messageData} isFetched={isMessageFetched} />
        {isMessageFetched && messageData.length > 0 && (
          <Link to={navigationLinks.messagesListPage()}>
            <div className="dashboard__tasks-see-all">{t('dashboard.see_message')}</div>
          </Link>
        )}
      </Card>
    </div>
  );
};

export default MessageList;
