import { useGetRecognitionsQuery } from 'api/recognition';
import Pagination from 'components/base-components/Pagination/Pagination';
import { Tabs } from 'components/base-components/RTabs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { RECOGNITION } from 'utils/data-center-tabs';
import { UNSENT } from 'utils/recognition-status';
import RecognitionTable from './table.component';

const Recognition = () => {
  const [showDetailsPage, setShowDetailsPage] = useState(false);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams({
    tab: RECOGNITION,
    status: UNSENT,
    page: 1,
  });
  const currentStatus = searchParams.get('status');
  const currentTab = searchParams.get('tab');
  const currentPage = searchParams.get('page');

  const SUBTAB_MAPPER = {
    unsent: 0,
    sent: 1,
  };

  const onChangeSubtab = selectedTabKey => {
    const selectedStatus = Object.keys(SUBTAB_MAPPER).find(
      status => SUBTAB_MAPPER[status] === selectedTabKey,
    );
    setSearchParams({ tab: currentTab, status: selectedStatus, page: 1 });
    setShowDetailsPage(false);
  };

  const params = {
    'statuses[]': [currentStatus],
    page: currentPage,
  };

  const { data: recognitionListCollection } = useGetRecognitionsQuery(params);

  const recognitionList = recognitionListCollection?.collection || [];

  return (
    <>
      <div className="grid">
        <div className="col-12 col-bleed">
          <Tabs
            controlled={true}
            selectedTabKey={SUBTAB_MAPPER[currentStatus]}
            items={[
              {
                title: t('recognition.tab.new'),
                key: 0,
                getContent: () => (
                  <RecognitionTable
                    recognitionList={recognitionList}
                    currentStatus={currentStatus}
                    showDetailsPage={showDetailsPage}
                    setShowDetailsPage={setShowDetailsPage}
                  />
                ),
              },
              {
                title: t('recognition.tab.sent'),
                key: 1,
                getContent: () => (
                  <RecognitionTable
                    recognitionList={recognitionList}
                    currentStatus={currentStatus}
                    showDetailsPage={showDetailsPage}
                    setShowDetailsPage={setShowDetailsPage}
                  />
                ),
              },
            ]}
            onChange={onChangeSubtab}
          />
        </div>
      </div>
      <div className="user-details__assignment-list">
        <Pagination
          onPaginationClick={newPage => {
            setSearchParams({ page: newPage, tab: currentTab, status: currentStatus });
          }}
          totalPages={
            recognitionListCollection &&
            parseInt(recognitionListCollection.pagination.totalPages, 10)
          }
          currentPage={currentPage}
          nextText={t('pagination.next')}
          previousText={t('pagination.prev')}
          firstText={t('pagination.first')}
          lastText={t('pagination.last')}
        />
      </div>
    </>
  );
};

export default Recognition;
