import React from 'react';
import PropTypes from 'prop-types';
import OrganisationsList from 'components/organisations/list/index.component';

const Organizations = ({ industry }) => <OrganisationsList industry={industry} />;

Organizations.propTypes = {
  industry: PropTypes.string.isRequired,
};

export default Organizations;
