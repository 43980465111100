import { Text, View } from '@react-pdf/renderer';
import styles from 'components/pdf/styles/checklist-styles';
import { t } from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';

const CommentField = ({ comment }) =>
  comment ? (
    <View style={styles.checklistMarginFive}>
      <Text>
        {t('control_body.survey.form.comment_from_auditor')} {comment}
      </Text>
    </View>
  ) : null;

CommentField.propTypes = {
  comment: PropTypes.string.isRequired,
};

const DeclarationStatus = ({
  declarationDeviations,
  deviationAcknowledgement,
  deadlinesMissedComment,
  monthlyVariationsComment,
  valueOfGoodsComment,
}) => (
  <>
    <Text style={styles.checklistMarginForty}>
      <Text style={styles.bold}>4. </Text>{' '}
      {t('control_body.survey.form.pdf_list.declaration_status')}
    </Text>
    <View style={styles.checklistMarginTwenty}></View>
    <View style={styles.checkListFlexSpaceBetween}>
      <Text>{t('control_body.survey.form.legend.variation')} </Text>
      <Text>
        {declarationDeviations?.goodsDeclarationsDeviationsPercentage?.value !== null
          ? `${declarationDeviations?.goodsDeclarationsDeviationsPercentage?.value}% ${t(
              'control_body.survey.form.legend.deviation',
            )}`
          : t('common.not_applicable')}
      </Text>
    </View>
    <CommentField comment={monthlyVariationsComment} />
    <View style={styles.checklistMarginTwenty}></View>
    <View style={styles.checkListFlexSpaceBetween}>
      <Text>{t('control_body.survey.form.legend.missed_deadline')} </Text>
      <Text>
        {declarationDeviations?.deadlinesMissed?.value !== null
          ? `${declarationDeviations?.deadlinesMissed?.value} ${t(
              'control_body.survey.form.pdf_list.days_within',
            )}`
          : t('common.not_applicable')}
      </Text>
    </View>
    <CommentField comment={deadlinesMissedComment} />
    <View style={styles.checklistMarginTwenty}></View>
    <View style={styles.checkListFlexSpaceBetween}>
      <Text>{t('control_body.survey.form.legend.declaration_supplier_relation')}</Text>
      <Text>
        {declarationDeviations?.certificatesDeclarationsRatio?.value !== null
          ? declarationDeviations?.certificatesDeclarationsRatio?.value
          : t('common.not_applicable')}
      </Text>
    </View>
    <CommentField comment={valueOfGoodsComment} />
    <View style={{ ...styles.contractualStatusHR, ...styles.checklistMarginTwenty }} />
    <View style={styles.checkListFlexSpaceBetween}>
      <Text style={styles.checklistMarginTen}>{t('control_body.survey.form.control_pass')}</Text>
      <Text style={styles.checklistMarginTen}>
        {deviationAcknowledgement ? t('shared.action.yes') : t('control_body.survey.shared.no')}
      </Text>
    </View>
  </>
);
DeclarationStatus.propTypes = {
  declarationDeviations: PropTypes.shape().isRequired,
  deviationAcknowledgement: PropTypes.bool.isRequired,
  deadlinesMissedComment: PropTypes.string.isRequired,
  monthlyVariationsComment: PropTypes.string.isRequired,
  valueOfGoodsComment: PropTypes.string.isRequired,
};

export default DeclarationStatus;
