import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Field, useForm, useFormState } from 'react-final-form';
import { useGetLegalFormOptionsQuery } from 'api/organizations';
import CheckBox from 'components/base-components/CheckBox';
import SelectBox from 'components/base-components/SelectBox';

const LegalFormType = ({ organisation, onChangePartnerCount, validationError }) => {
  const { data: legalFormOptions = [] } = useGetLegalFormOptionsQuery();
  const form = useForm();
  const { values } = useFormState();

  const numberOfPartnersOptions = [...Array(10).keys()].map(number => {
    return {
      label: number + 1,
      value: number + 1,
    };
  });

  return (
    <>
      <div className="legal-form__title">{t('legal_form.title')}</div>
      <div className="col-6 col-bleed">
        <div>
          <Field name="legalForm" initialValue={organisation?.legalForm}>
            {({ input, meta }) => (
              <SelectBox
                size="tiny"
                width="full"
                label={t('legal_form.select_box.form_label')}
                placeholderText={t('legal_form.select_box.form_placeholder')}
                isClearable={true}
                options={legalFormOptions}
                value={legalFormOptions.find(option => option.value === input.value)}
                touched={!meta.dirtySinceLastSubmit}
                errorMsg={meta.submitError || validationError?.partner}
                selectedValue={input.label}
                required={true}
                onChange={e => {
                  input.onChange(e.value);
                  form.change('numberOfPartners', null);
                  form.change('legalFormCheckbox', false);
                }}
              />
            )}
          </Field>
          {values.legalForm === 'commercial_registry_entry' && (
            <Field name="legalFormCheckbox" type="checkbox">
              {({ input }) => (
                <>
                  <CheckBox
                    className="legal-form__permit-checkbox"
                    isChecked={input.checked}
                    size="tiny"
                    label={t('legal_form.select_box.permit_checkbox')}
                    onChange={e => {
                      input.onChange(e.target.checked);
                      if (e.target.checked) {
                        form.change('numberOfPartners', 1);
                      }
                    }}
                  />
                </>
              )}
            </Field>
          )}
        </div>
      </div>
      <div className="col-6 col-bleed-y">
        <Field name="numberOfPartners" initialValue={organisation?.numberOfPartners}>
          {({ input, meta }) => (
            <SelectBox
              size="tiny"
              width="full"
              label={t('legal_form.select_box.partner_number')}
              placeholderText={t('user_general.placeholder')}
              isClearable={true}
              options={numberOfPartnersOptions}
              value={numberOfPartnersOptions.find(option => option.value === input.value)}
              selectedValue={input.label}
              onChange={e => {
                input.onChange(e.value);
                onChangePartnerCount(e.value);
              }}
              isDisabled={values.legalFormCheckbox}
              className="invoice__line-input"
              touched={!meta.dirtySinceLastSubmit}
              required={true}
              errorMsg={meta.submitError || validationError?.partner}
            />
          )}
        </Field>
      </div>
    </>
  );
};

LegalFormType.defaultProps = {
  validationError: {},
  onChangePartnerCount: () => {},
};

LegalFormType.propTypes = {
  organisation: PropTypes.shape().isRequired,
  onChangePartnerCount: PropTypes.func,
  validationError: PropTypes.shape(),
};

export default LegalFormType;
