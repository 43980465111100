import { useRaiseComplaintControlBodyDeclarationMutation } from 'api/control-body-declarations';
import PropTypes from 'prop-types';
import Button from 'components/base-components/Button';
import Input from 'components/base-components/Input';
import Textarea from 'components/base-components/Textarea';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { toSnakecaseKeys } from 'utils/object-cleanup';

const ControlBodyComplaintComponent = ({ setIsComplaintSidepanelOpen }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [raiseComplaintControlBody] = useRaiseComplaintControlBodyDeclarationMutation();

  const onSave = values => {
    return raiseComplaintControlBody({
      id,
      payload: values,
    })
      .unwrap()
      .then(() => {
        setIsComplaintSidepanelOpen(false);
        return null;
      })
      .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
  };

  return (
    <Form
      onSubmit={onSave}
      validate={() => {}}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="grid">
            <div className="col-12 col-bleed-x">
              <Field name="title">
                {({ input, meta }) => (
                  <Input
                    size="tiny"
                    label={t('control_body.title.label')}
                    placeholder={t('control_body.title.placeholder')}
                    touched={!meta.dirtySinceLastSubmit}
                    error={meta.submitError}
                    required={true}
                    {...input}
                  />
                )}
              </Field>
            </div>
            <div className="col-12 col-bleed">
              <Field name="description">
                {({ input, meta }) => (
                  <Textarea
                    size="small"
                    label={t('shared.attributes.describe.label')}
                    placeholder={t('shared.attributes.describe.placeholder')}
                    touched={!meta.dirtySinceLastSubmit}
                    error={meta.submitError}
                    required={true}
                    {...input}
                  />
                )}
              </Field>
            </div>
            <div className="control-body-form__buttons col-12 col-bleed-x">
              <Button
                label={t('common.submit')}
                type="success"
                size="small"
                disabled={submitting}
                submitType="submit"
              />
              <Button
                className="control-body-form__buttons-cancel"
                label={t('common.cancel_button')}
                size="small"
                onClick={() => setIsComplaintSidepanelOpen(false)}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

ControlBodyComplaintComponent.defaultProps = {
  setIsComplaintSidepanelOpen: () => {},
};

ControlBodyComplaintComponent.propTypes = {
  setIsComplaintSidepanelOpen: PropTypes.func,
};

export default ControlBodyComplaintComponent;
