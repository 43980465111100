import React from 'react';
import PropTypes from 'prop-types';
import RadioWithoutCheck from 'components/base-components/RadioWithoutCheck';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

const TVDMailing = ({ initialValue }) => {
  const { t } = useTranslation();

  return (
    <div className="tvd-list__sidepanel--input-field">
      <div className="tvd-list__sidepanel-label">
        {t('organisation_view.tvd.sidepanel.tvd_mailing')}
      </div>
      <Field name="tvdMailing">
        {({ input, meta }) => (
          <>
            {['yes', 'no'].map(mailingType => (
              <RadioWithoutCheck
                key={mailingType}
                className="tvd-list__sidepanel--radio-button"
                isChecked={input.value === mailingType}
                label={t(`shared.action.${mailingType}`)}
                name={mailingType}
                onChange={() => input.onChange(mailingType)}
                touched={!meta.dirtySinceLastSubmit}
                error={meta.submitError}
                size="medium"
                data-testid={mailingType}
              />
            ))}
          </>
        )}
      </Field>
    </div>
  );
};

TVDMailing.defaultProps = {
  initialValue: '',
};

TVDMailing.propTypes = {
  initialValue: PropTypes.string,
};

export default TVDMailing;
