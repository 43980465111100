import Card from 'components/base-components/Card';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import TitleComponent from '../../plant-cultivate/shared/title.component';
import SharedMeatField from './shared-field.component';

const CowForm = ({ label, icon, isVisible, initialValue, error }) => {
  if (!isVisible) return;
  const cowData = [
    {
      name: 'numberOfMotherCows',
      label: t('meat_production.cow_data.mother_cows'),
    },
    {
      name: 'numberOfCalves',
      label: t('meat_production.cow_data.calves'),
    },
    {
      name: 'numberOfCattle',
      label: t('meat_production.cow_data.cattle'),
    },
  ];

  return (
    <Card className="product-cultivation__card">
      <TitleComponent title={label} icon={icon} />
      <Card className="product-cultivation__inside-card">
        {cowData.map(({ name, label }, index) => (
          <SharedMeatField
            fieldName={`cows.${name}`}
            name={name}
            label={label}
            index={index}
            initialValue={initialValue}
          />
        ))}
      </Card>
      {error && <div className="error-text margin-left-20 margin-bottom-20">{error}</div>}
    </Card>
  );
};

CowForm.defaultProps = {
  label: null,
  icon: null,
  error: null,
  isVisible: false,
  initialValue: {},
};

CowForm.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  isVisible: PropTypes.string,
  initialValue: PropTypes.shape(),
  error: PropTypes.string,
};

export default CowForm;
