import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetOrganizationGoodsQuery } from 'api/goods-declarations';
import Spinner from 'components/base-components/Spinner';
import STARS_STATUS from 'utils/star_status';
import { monthYearShort, dateMonthYear } from 'utils/date-time-formatter';
import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import Pagination from 'components/base-components/Pagination';
import BioCuisineStatus from '../bio-cuisine-status.component';
import GoodsEntity from './goods-entity.component';

const SummaryItemWrapper = ({ title, content }) => {
  return (
    <div className="goods__expired-list-item-summary-wrapper">
      <div className="goods__expired-list-item-summary-wrapper-title">{title}</div>
      <div className="goods__expired-list-item-summary-wrapper-content">{content}</div>
    </div>
  );
};

SummaryItemWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

const PercentageDifferenceWrapper = ({ percentage, percentageDifference }) => {
  const isPositive = percentageDifference >= 0;

  const roundedPercentageDifference = parseFloat(percentageDifference).toFixed(2);
  const percentageWithSign = isPositive
    ? `+${roundedPercentageDifference}%`
    : `-${Math.abs(roundedPercentageDifference)}%`;

  return (
    <div className="goods__expired-list-item-percentage">
      <span className="goods__expired-list-item-percentage-current">{`${parseFloat(
        percentage,
      ).toFixed(2)}%`}</span>
      <div
        className={classnames(`goods__expired-list-item-percentage-difference`, {
          'goods__expired-list-item-percentage-difference--increased': isPositive,
          'goods__expired-list-item-percentage-difference--decreased': !isPositive,
        })}
      >
        {percentageWithSign}
        <Icon
          name={isPositive ? 'caretUp' : 'caretDown'}
          size="small"
          color={isPositive ? 'success' : 'danger'}
        />
      </div>
    </div>
  );
};

PercentageDifferenceWrapper.propTypes = {
  percentageDifference: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
};

const GoodsDeclarationSummary = ({ goods }) => {
  const { t } = useTranslation();

  return (
    <Card className="goods__expired-list-item-summary">
      <span>{t('goods.average_was')}</span>
      <SummaryItemWrapper
        title={t('goods.bio_certified_percentage')}
        content={
          <PercentageDifferenceWrapper
            percentage={goods.shareBioAveragePercentage}
            percentageDifference={goods.differenceShareBioPercentage}
          />
        }
      />
      <SummaryItemWrapper
        title={t('goods.bio_bud')}
        content={
          <PercentageDifferenceWrapper
            percentage={goods.shareBioBudAveragePercentage}
            percentageDifference={goods.differenceShareKnospePercentage}
          />
        }
      />
      <SummaryItemWrapper
        title={t('goods.rating_was')}
        content={
          <BioCuisineStatus
            status={STARS_STATUS[goods.stars]}
            iconSize="medium"
            displayAllStars={true}
          />
        }
      />
    </Card>
  );
};

GoodsDeclarationSummary.propTypes = {
  goods: PropTypes.shape({
    shareBioAveragePercentage: PropTypes.number.isRequired,
    shareBioBudAveragePercentage: PropTypes.number.isRequired,
    differenceShareBioPercentage: PropTypes.number.isRequired,
    differenceShareKnospePercentage: PropTypes.number.isRequired,
    stars: PropTypes.number.isRequired,
  }).isRequired,
};

const ExpiredDeclaration = ({ organizationId }) => {
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const { t } = useTranslation();

  const currentPage = parseInt(searchParams.get('page'), 10);
  const { data, isFetching } = useGetOrganizationGoodsQuery({
    id: organizationId,
    page: currentPage,
    status: 'expired',
  });

  const goodsDeclarations = data ? data.goods : [];
  const totalPages = data ? parseInt(data.pagination.totalPages, 10) : 0;

  if (isFetching)
    return (
      <Spinner
        className="product-declarations__spinner"
        bgColor="none"
        color="success"
        size="small"
      />
    );

  return (
    <div className="goods__expired-list">
      {goodsDeclarations.length > 0 ? (
        goodsDeclarations.map(goodsDeclaration => (
          <div className="goods__expired-list-item">
            <div className="goods__expired-list-item-duration">
              {`${monthYearShort(goodsDeclaration.periodFrom)} - ${monthYearShort(
                goodsDeclaration.periodUntil,
              )}`}
            </div>
            <div className="goods__expired-list-item-declaration">
              {t('goods.declaration_date')}
              <span className="goods__expired-list-item-declaration-date">
                {dateMonthYear(goodsDeclaration.confirmationDate)}
              </span>
            </div>
            <div className="goods__expired-list-item-wrapper">
              <GoodsEntity
                key={goodsDeclaration.id}
                goods={goodsDeclaration}
                goodsDeclarationSummary={<GoodsDeclarationSummary goods={goodsDeclaration} />}
              />
            </div>
          </div>
        ))
      ) : (
        <EmptyPlaceholder
          emptyCardText={t('goods.empty_history')}
          emptyIcon={<Icon name="history" />}
        />
      )}
      <Pagination
        onPaginationClick={newPage => {
          setSearchParams({ tab: searchParams.get('tab'), page: newPage });
        }}
        totalPages={totalPages}
        currentPage={currentPage}
        nextText={t('pagination.next')}
        previousText={t('pagination.prev')}
        firstText={t('pagination.first')}
        lastText={t('pagination.last')}
      />
    </div>
  );
};

ExpiredDeclaration.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export default ExpiredDeclaration;
