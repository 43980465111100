import React from 'react';
import GeneralForm from './general-info-form.component';

const GeneralInfoEditComponent = () => {
  return (
    <>
      <GeneralForm />
    </>
  );
};

export default GeneralInfoEditComponent;
