import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { InputField } from 'components/pdf/shared/shared-components';
import styles from 'components/pdf/styles/styles';
import PropTypes from 'prop-types';
import { dateMonthYearFormatter } from 'utils/date-time-formatter';
import { useLanguageContext } from 'contexts/languageContext';

const SignatureForm = ({ organisation, isContractPdf }) => {
  const currentDate = dateMonthYearFormatter(new Date());
  const { t } = useLanguageContext();

  return (
    <>
      <view style={styles.signatureForm}>
        <Text style={{ ...styles.textBold, width: '100%', marginRight: 10 }}>
          {t('producer_contract.signature_form.left.title')}
        </Text>
        <Text style={{ ...styles.textBold, width: '100%', marginLeft: 10 }}>
          {organisation.name}
        </Text>
      </view>
      <View style={{ ...styles.signatureForm, marginTop: 0 }}>
        <View style={{ width: '100%', marginRight: 10 }}>
          <InputField
            title={
              isContractPdf
                ? t('producer_contract.signature_form.left.second_input')
                : t('producer_contract.signature_form.left.first_input')
            }
          />
          <InputField
            style={{ marginTop: 34 }}
            title={
              isContractPdf
                ? t('producer_contract.signature_form.left.fourth_input')
                : t('producer_contract.signature_form.left.second_input')
            }
          />
          <InputField
            style={{ marginTop: isContractPdf ? 52 : 62 }}
            title={t('producer_contract.signature_form.right.third_input')}
            value={
              isContractPdf
                ? t('producer_contract.signature_form.right.third_input_value', {
                    currentDate,
                  })
                : `Basel, ${currentDate}`
            }
            valueType="text"
          />
        </View>
        <View style={{ width: '100%', marginLeft: 10 }}>
          <InputField title={t('producer_contract.signature_form.right.first_input')} />
          <InputField
            lineStyle={{ marginTop: 30 }}
            title={t('producer_contract.signature_form.right.second_input')}
          />
          <InputField
            lineStyle={{ marginTop: 30 }}
            title={t('producer_contract.signature_form.right.first_input')}
          />
          <InputField
            lineStyle={{ marginTop: 30 }}
            title={t('producer_contract.signature_form.right.second_input')}
          />
          <InputField
            lineStyle={{ marginTop: 20 }}
            style={{ marginTop: 10 }}
            title={t('producer_contract.signature_form.right.third_input')}
          />
        </View>
      </View>
    </>
  );
};

SignatureForm.defaultProps = {
  isContractPdf: false,
};

SignatureForm.propTypes = {
  organisation: PropTypes.shape().isRequired,
  isContractPdf: PropTypes.bool,
};

export default SignatureForm;
