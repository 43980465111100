import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';
import IconButton from '../IconButton';

const SidePanel = props => {
  const { className, children, title, closeIcon, isOpened, onClick, body, onClose } = props;
  const [transition, setTransition] = useState(false);
  const [transformOpen, setTransformOpen] = useState(false);
  const handleOnClick = onClose || onClick;

  useEffect(() => {
    if (isOpened) {
      setTimeout(() => {
        setTransition(true);
        setTransformOpen(true);
      }, 0);
    } else {
      setTransformOpen(false);
      setTimeout(() => {
        setTransition(false);
      }, 300);
    }
  }, [isOpened]);

  return (
    <div
      className={classNames('side-panel__backdrop', {
        'side-panel__backdrop--visible': transformOpen && transition,
      })}
      onClick={onClick}
      onKeyDown={onClick}
      role="presentation"
    >
      <div
        onClick={event => event.stopPropagation()}
        onKeyDown={event => event.stopPropagation()}
        role="presentation"
        className={classNames(
          'side-panel',
          {
            'side-panel--open': transformOpen,
            'side-panel__transform': transition,
          },
          className,
        )}
      >
        <div className="side-panel__header">
          {title && <div className="side-panel__title">{title}</div>}
          <IconButton
            version="v2"
            className="side-panel__close-btn"
            size="large"
            isIconOnly={true}
            icon={closeIcon}
            color="default"
            onClick={handleOnClick}
          />
        </div>
        {body && <div className="side-panel__body">{body}</div>}
        {children}
      </div>
    </div>
  );
};

SidePanel.defaultProps = {
  className: null,
  children: null,
  title: null,
  closeIcon: <Icon name="close" />,
  isOpened: false,
  onClick: () => {},
  onClose: null,
  body: null,
};

SidePanel.propTypes = {
  children: PropTypes.node,
  body: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  title: PropTypes.string,
  closeIcon: PropTypes.node,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  isOpened: PropTypes.bool,
  className: PropTypes.string,
};

export default SidePanel;
