import React from 'react';
import PropTypes from 'prop-types';
import { useGetRecognitionCertificateQuery } from 'api/recognition';
import RecognitionDetailsTable from 'components/recognition/details/table.component';
import { useSearchParams } from 'react-router-dom';
import { t } from 'i18next';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import Pagination from 'components/base-components/Pagination';

const RecognitionComponent = ({ organisationId }) => {
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const currentPage = searchParams.get('page');

  const params = {
    page: currentPage,
    'organization_ids[]': [organisationId],
  };

  const { data, isFetching } = useGetRecognitionCertificateQuery(params);
  const certificateList = data?.collection || [];

  return (
    <>
      <Form
        onSubmit={() => {}}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <RecognitionDetailsTable
              certificateList={certificateList}
              isFetching={isFetching}
              currentPage={currentPage}
              showCheckbox={false}
            />
          </form>
        )}
      />
      <div className="user-details__assignment-list">
        <Pagination
          onPaginationClick={newPage => {
            setSearchParams({ page: newPage, section: 'recognition' });
          }}
          totalPages={data && parseInt(data.pagination.totalPages, 10)}
          currentPage={parseInt(currentPage, 10)}
          nextText={t('pagination.next')}
          previousText={t('pagination.prev')}
          firstText={t('pagination.first')}
          lastText={t('pagination.last')}
        />
      </div>
    </>
  );
};

RecognitionComponent.propTypes = {
  organisationId: PropTypes.number.isRequired,
};

export default RecognitionComponent;
