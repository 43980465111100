import { useDeleteProductMutation } from 'api/product-declarations';
import SidePanel from 'components/base-components/SidePanel';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/base-components/Table';
import DeleteModal from 'components/shared-components/delete-modal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProductForm from '../form/product-form.component';
import ProductRow from './product-row.component';

const ProductTable = ({
  data,
  isSidepanelOpen,
  setIsSidepanelOpen,
  editableProduct,
  setEditableProduct,
  organizationId,
  showActions,
}) => {
  const { t } = useTranslation();
  const [deleteProduct, deleteStatus] = useDeleteProductMutation();
  const [deleteCallbackParams, setDeleteCallbackParams] = useState(null);

  if (!data) return null;

  return (
    <>
      <DeleteModal
        modelName="product"
        callback={deleteProduct}
        deleteCallbackParams={deleteCallbackParams}
        status={deleteStatus}
        setDeleteCallbackParams={setDeleteCallbackParams}
        customTranslation={true}
      />
      {data?.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader>{t('product_declarations.attributes.product_name')}</TableHeader>
              <TableHeader>{t('product_declarations.attributes.category')}</TableHeader>
              <TableHeader>{t('product_declarations.attributes.supplier')}</TableHeader>
              {showActions && (
                <TableHeader align="right">
                  {t('product_declarations.attributes.actions')}
                </TableHeader>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(productDeclaration => (
              <ProductRow
                key={productDeclaration.id}
                productDeclaration={productDeclaration}
                setIsSidepanelOpen={setIsSidepanelOpen}
                setEditableProduct={setEditableProduct}
                setDeleteCallbackParams={setDeleteCallbackParams}
                showActions={showActions}
              />
            ))}
          </TableBody>
        </Table>
      )}

      <SidePanel
        isOpened={isSidepanelOpen}
        title={
          editableProduct
            ? t('product_declarations.edit_product')
            : t('product_declarations.add_product')
        }
        body={
          isSidepanelOpen && (
            <ProductForm
              product={editableProduct}
              organizationId={organizationId}
              setIsSidepanelOpen={setIsSidepanelOpen}
            />
          )
        }
        onClose={() => setIsSidepanelOpen(false)}
      />
    </>
  );
};
ProductTable.defaultProps = {
  data: [],
  editableProduct: null,
  showActions: true,
  setIsSidepanelOpen: () => {},
  setEditableProduct: () => {},
  isSidepanelOpen: false,
};

ProductTable.propTypes = {
  data: PropTypes.array,
  organizationId: PropTypes.number.isRequired,
  setIsSidepanelOpen: PropTypes.func,
  setEditableProduct: PropTypes.func,
  editableProduct: PropTypes.shape(),
  isSidepanelOpen: PropTypes.bool,
  showActions: PropTypes.bool,
};

export default ProductTable;
