import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

const HeaderDataContext = createContext();

function HeaderDataProvider({ initialHeaderData, children }) {
  const [headerData, setHeaderData] = useState(initialHeaderData);
  const value = { headerData, setHeaderData };

  return <HeaderDataContext.Provider value={value}>{children}</HeaderDataContext.Provider>;
}

HeaderDataProvider.defaultProps = {
  initialHeaderData: [],
};

HeaderDataProvider.propTypes = {
  initialHeaderData: PropTypes.arrayOf(PropTypes.string, PropTypes.number, PropTypes.node),
  children: PropTypes.node.isRequired,
};

export { HeaderDataContext, HeaderDataProvider };
