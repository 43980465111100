import { ReactComponent as Add } from './assets/icons/add.svg';
import { ReactComponent as AddOrder } from './assets/icons/add_order.svg';
import { ReactComponent as Animal } from './assets/icons/animal.svg';
import { ReactComponent as Archive } from './assets/icons/archive.svg';
import { ReactComponent as ArrowBack } from './assets/icons/arrow_back.svg';
import { ReactComponent as ArrowBackAlt } from './assets/icons/arrow_back_alt.svg';
import { ReactComponent as ArrowDown } from './assets/icons/arrowDown.svg';
import { ReactComponent as ArrowForward } from './assets/icons/arrow_forward.svg';
import { ReactComponent as ArrowForwardAlt } from './assets/icons/arrow_forward_alt.svg';
import { ReactComponent as ArrowRightAlt } from './assets/icons/arrow_right_alt.svg';
import { ReactComponent as AutoRenew } from './assets/icons/autorenew.svg';
import { ReactComponent as Audio } from './assets/icons/audio.svg';
import { ReactComponent as Baggage } from './assets/icons/baggage.svg';
import { ReactComponent as Bank } from './assets/icons/bank.svg';
import { ReactComponent as BankOutline } from './assets/icons/bank_outline.svg';
import { ReactComponent as Bee } from './assets/icons/bee.svg';
import { ReactComponent as Bowl } from './assets/icons/bowl.svg';
import { ReactComponent as CalendarAlt } from './assets/icons/calendar_alt.svg';
import { ReactComponent as Calendar } from './assets/icons/calendar.svg';
import { ReactComponent as Call } from './assets/icons/call.svg';
import { ReactComponent as CameraAlt } from './assets/icons/cameraAlt.svg';
import { ReactComponent as CaretDown } from './assets/icons/caret_down.svg';
import { ReactComponent as CaretUp } from './assets/icons/caret_up.svg';
import { ReactComponent as Vegetable } from './assets/icons/vegetable.svg';
import { ReactComponent as Certificate } from './assets/icons/certificate.svg';
import { ReactComponent as CertificateActive } from './assets/icons/certificate_active.svg';
import { ReactComponent as Chat } from './assets/icons/messaging.svg';
import { ReactComponent as Check } from './assets/icons/check.svg';
import { ReactComponent as CheckCircle } from './assets/icons/check_circle.svg';
import { ReactComponent as CheckCircleOutline } from './assets/icons/check_circle_outline.svg';
import { ReactComponent as Close } from './assets/icons/close.svg';
import { ReactComponent as CloudUpload } from './assets/icons/cloud_upload.svg';
import { ReactComponent as Contract } from './assets/icons/contract.svg';
import { ReactComponent as Cow } from './assets/icons/cow.svg';
import { ReactComponent as Cows } from './assets/icons/cows.svg';
import { ReactComponent as CowWithMountain } from './assets/icons/cow_mountain.svg';
import { ReactComponent as Crops } from './assets/icons/crops.svg';
import { ReactComponent as DairyEwe } from './assets/icons/dairy_ewe.svg';
import { ReactComponent as DairyGoat } from './assets/icons/dairy_goat.svg';
import { ReactComponent as Dashboard } from './assets/icons/dashboard.svg';
import { ReactComponent as Database } from './assets/icons/database.svg';
import { ReactComponent as Delete } from './assets/icons/delete.svg';
import { ReactComponent as DeleteAlt } from './assets/icons/delete_alt.svg';
import { ReactComponent as DownloadFile } from './assets/icons/download_file.svg';
import { ReactComponent as Download } from './assets/icons/download.svg';
import { ReactComponent as DownloadAlt } from './assets/icons/downloadAlt.svg';
import { ReactComponent as DownArrow } from './assets/icons/down_arrow.svg';
import { ReactComponent as Edit } from './assets/icons/edit.svg';
import { ReactComponent as EditAlt } from './assets/icons/editAlt.svg';
import { ReactComponent as Egg } from './assets/icons/egg.svg';
import { ReactComponent as Email } from './assets/icons/email.svg';
import { ReactComponent as ExternalLink } from './assets/icons/external_link.svg';
import { ReactComponent as Farm } from './assets/icons/farm.svg';
import { ReactComponent as FarmHouse } from './assets/icons/farm_house.svg';
import { ReactComponent as Garden } from './assets/icons/garden.svg';
import { ReactComponent as Fruit } from './assets/icons/fruit.svg';
import { ReactComponent as Fish } from './assets/icons/fish.svg';
import { ReactComponent as Filter } from './assets/icons/filter.svg';
import { ReactComponent as Frank } from './assets/icons/frank.svg';
import { ReactComponent as Globe } from './assets/icons/globe.svg';
import { ReactComponent as Goat } from './assets/icons/goat.svg';
import { ReactComponent as GoBack } from './assets/icons/go_back.svg';
import { ReactComponent as GreenArea } from './assets/icons/green_area.svg';
import { ReactComponent as Herbs } from './assets/icons/herbs.svg';
import { ReactComponent as HighPriority } from './assets/icons/high_priority.svg';
import { ReactComponent as History } from './assets/icons/history.svg';
import { ReactComponent as Home } from './assets/icons/home.svg';
import { ReactComponent as HorizontalDots } from './assets/icons/more_horiz.svg';
import { ReactComponent as IconDownChevron } from './assets/icons/icon_down_chevron.svg';
import { ReactComponent as IconUpChevron } from './assets/icons/icon_up_chevron.svg';
import { ReactComponent as Importer } from './assets/icons/importer.svg';
import { ReactComponent as Info } from './assets/icons/info.svg';
import { ReactComponent as Invalid } from './assets/icons/invalid.svg';
import { ReactComponent as InvalidOutline } from './assets/icons/invalid_outline.svg';
import { ReactComponent as Invoice } from './assets/icons/invoice.svg';
import { ReactComponent as Meat } from './assets/icons/meat.svg';
import { ReactComponent as Milk } from './assets/icons/milk.svg';
import { ReactComponent as Mushroom } from './assets/icons/mushroom.svg';
import { ReactComponent as Loading } from './assets/icons/loading.svg';
import { ReactComponent as Location } from './assets/icons/location.svg';
import { ReactComponent as Maf } from './assets/icons/maf.svg';
import { ReactComponent as Konturlinie } from './assets/icons/konturlinie.svg';
import { ReactComponent as Marketing } from './assets/icons/marketing.svg';
import { ReactComponent as Minimize } from './assets/icons/minimize.svg';
import { ReactComponent as Neutral } from './assets/icons/neutral_icon.svg';
import { ReactComponent as NeutralOutline } from './assets/icons/neutral_outline.svg';
import { ReactComponent as Newspaper } from './assets/icons/newspaper.svg';
import { ReactComponent as Organization } from './assets/icons/organization.svg';
import { ReactComponent as Ornamental } from './assets/icons/ornamental.svg';
import { ReactComponent as privatePark } from './assets/icons/park.svg';
import { ReactComponent as Pdf } from './assets/icons/pdf.svg';
import { ReactComponent as People } from './assets/icons/people.svg';
import { ReactComponent as Person } from './assets/icons/user.svg';
import { ReactComponent as Photo } from './assets/icons/photo.svg';
import { ReactComponent as Pig } from './assets/icons/pig.svg';
import { ReactComponent as Plant } from './assets/icons/plant.svg';
import { ReactComponent as PlantLittle } from './assets/icons/plant_little.svg';
import { ReactComponent as PlantTab } from './assets/icons/plant_tab.svg';
import { ReactComponent as Potatoes } from './assets/icons/potatoes.svg';
import { ReactComponent as Poultry } from './assets/icons/poultry.svg';
import { ReactComponent as PowerOff } from './assets/icons/powerOff.svg';
import { ReactComponent as Percent } from './assets/icons/percent.svg';
import { ReactComponent as Restaurant } from './assets/icons/restaurant.svg';
import { ReactComponent as RestaurantAlt } from './assets/icons/restaurant_alt.svg';
import { ReactComponent as Search } from './assets/icons/search.svg';
import { ReactComponent as Send } from './assets/icons/send.svg';
import { ReactComponent as SendAlt } from './assets/icons/send_alt.svg';
import { ReactComponent as Settings } from './assets/icons/settings.svg';
import { ReactComponent as Sheep } from './assets/icons/sheep.svg';
import { ReactComponent as Show } from './assets/icons/show.svg';
import { ReactComponent as ShowAlt } from './assets/icons/showAlt.svg';
import { ReactComponent as Star } from './assets/icons/star.svg';
import { ReactComponent as StopWatch } from './assets/icons/stopwatch.svg';
import { ReactComponent as StickyNote } from './assets/icons/sticky_note.svg';
import { ReactComponent as Tasks } from './assets/icons/tasks.svg';
import { ReactComponent as TeachingFirm } from './assets/icons/teaching_firm.svg';
import { ReactComponent as Today } from './assets/icons/today.svg';
import { ReactComponent as UpArrow } from './assets/icons/up_arrow.svg';
import { ReactComponent as UploadFile } from './assets/icons/upload_file.svg';
import { ReactComponent as UploadFileAlt } from './assets/icons/upload_file_alt.svg';
import { ReactComponent as UserDeactivate } from './assets/icons/user_deactivate.svg';
import { ReactComponent as Viticulture } from './assets/icons/viticulture.svg';
import { ReactComponent as Warning } from './assets/icons/warning.svg';
import { ReactComponent as ViewDetails } from './assets/icons/view_details.svg';
import { ReactComponent as Producer } from './assets/icons/producer.svg';
import { ReactComponent as ProducerAlt } from './assets/icons/producer_alt.svg';
import { ReactComponent as ControlBody } from './assets/icons/control_body.svg';
import { ReactComponent as ControlBodyAlt } from './assets/icons/control_body_alt.svg';
import { ReactComponent as LeftArrowHead } from './assets/icons/left_arrow.svg';
import { ReactComponent as RightArrowHead } from './assets/icons/right_arrow.svg';

const registeredIcon = {
  add: Add,
  addOrder: AddOrder,
  animal: Animal,
  arable_crop: Konturlinie,
  arableCrops: Crops,
  archive: Archive,
  arrowBack: ArrowBack,
  arrowBackAlt: ArrowBackAlt,
  arrowDown: ArrowDown,
  arrowForward: ArrowForward,
  arrowForwardAlt: ArrowForwardAlt,
  arrowRightAlt: ArrowRightAlt,
  autoRenew: AutoRenew,
  audio: Audio,
  baggage: Baggage,
  bank: Bank,
  bankOutline: BankOutline,
  bee: Bee,
  bowl: Bowl,
  calendar: Calendar,
  calendarAlt: CalendarAlt,
  call: Call,
  cameraAlt: CameraAlt,
  caretDown: CaretDown,
  caretUp: CaretUp,
  vegetable: Vegetable,
  certificate: Certificate,
  certificateActive: CertificateActive,
  chat: Chat,
  check: Check,
  checkCircle: CheckCircle,
  checkCircleOutline: CheckCircleOutline,
  close: Close,
  cloudUpload: CloudUpload,
  contract: Contract,
  controlBody: ControlBody,
  controlBodyAlt: ControlBodyAlt,
  cow: Cow,
  cows: Cows,
  cowWithMountain: CowWithMountain,
  dairyEwe: DairyEwe,
  dairyGoat: DairyGoat,
  dashboard: Dashboard,
  database: Database,
  delete: Delete,
  deleteAlt: DeleteAlt,
  download: Download,
  downloadAlt: DownloadAlt,
  downloadFile: DownloadFile,
  downArrow: DownArrow,
  edit: Edit,
  editAlt: EditAlt,
  egg: Egg,
  email: Email,
  externalLink: ExternalLink,
  farm: Farm,
  farmHouse: FarmHouse,
  fruit: Fruit,
  fish: Fish,
  garden: Garden,
  filter: Filter,
  frank: Frank,
  globe: Globe,
  ewe_goat: Goat,
  goBack: GoBack,
  green_area: GreenArea,
  herb: Herbs,
  highPriority: HighPriority,
  history: History,
  home: Home,
  horizontalDots: HorizontalDots,
  iconDownChevron: IconDownChevron,
  iconUpChevron: IconUpChevron,
  importer: Importer,
  info: Info,
  invalid: Invalid,
  invalidOutline: InvalidOutline,
  invoice: Invoice,
  leftarrowhead: LeftArrowHead,
  loading: Loading,
  location: Location,
  maf: Maf,
  marketing: Marketing,
  meat_production: Meat,
  milk: Milk,
  mushroom_farming: Mushroom,
  minimize: Minimize,
  neutral: Neutral,
  neutralOutline: NeutralOutline,
  newspaper: Newspaper,
  organization: Organization,
  ornamental: Ornamental,
  private_park: privatePark,
  pdf: Pdf,
  people: People,
  person: Person,
  percent: Percent,
  photo: Photo,
  pig: Pig,
  plant_cultivation: Plant,
  plant_little: PlantLittle,
  plantTab: PlantTab,
  potatoes: Potatoes,
  poultry: Poultry,
  potato: Potatoes,
  producer: Producer,
  producerAlt: ProducerAlt,
  powerOff: PowerOff,
  restaurant: Restaurant,
  restaurantAlt: RestaurantAlt,
  rightarrowhead: RightArrowHead,
  search: Search,
  send: Send,
  sendAlt: SendAlt,
  settings: Settings,
  ewe_sheep: Sheep,
  show: Show,
  showAlt: ShowAlt,
  star: Star,
  stopwatch: StopWatch,
  stickyNote: StickyNote,
  teachingFarm: TeachingFirm,
  tasks: Tasks,
  viticulture: Viticulture,
  today: Today,
  warning: Warning,
  uploadFile: UploadFile,
  uploadFileAlt: UploadFileAlt,
  userDeactivate: UserDeactivate,
  upArrow: UpArrow,
  viewdetails: ViewDetails,
};

export default registeredIcon;
