import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import Tooltip from '../RadioWithoutCheck';

const placementMap = {
  bottom: 'bottom',
  top: 'top',
  left: 'bottom-right',
  right: 'bottom-left',
};

const RadioWithoutCheck = props => {
  const { className, label, size, disabled, id, name, hint, hintPlacement, isChecked, onChange } =
    props;
  const radioId = id || uuidv4();
  const radioName = name || 'radio_input_value';

  const radioInputCheckboxWrapper = (
    <>
      <input
        className="radio__input"
        id={radioId}
        name={radioName}
        type="radio"
        checked={isChecked}
        onChange={onChange}
        readOnly={true}
      />
      <div
        className={classNames('radio-circle', {
          'radio-circle--checked': isChecked,
        })}
      />
    </>
  );

  return (
    <div className={className}>
      <label
        className={classNames('radio', `radio--${size}`, {
          'radio--disabled': disabled,
        })}
        htmlFor={radioId}
      >
        {hint ? (
          <Tooltip
            className={`radio-circle-tooltip radio-circle-tooltip--${size}`}
            position={placementMap[hintPlacement]}
            type="accent"
            content={hint}
            showArrow={false}
          >
            {radioInputCheckboxWrapper}
          </Tooltip>
        ) : (
          radioInputCheckboxWrapper
        )}
        {label && <span className="radio__label">{label}</span>}
      </label>
    </div>
  );
};

RadioWithoutCheck.defaultProps = {
  label: null,
  className: null,
  size: 'small',
  disabled: false,
  hint: null,
  id: null,
  name: null,
  hintPlacement: 'bottom',
  isChecked: false,
  onChange: () => {},
};

RadioWithoutCheck.propTypes = {
  label: PropTypes.string,
  className: PropTypes.node,
  size: PropTypes.oneOf(['large', 'huge', 'small', 'medium']),
  hintPlacement: PropTypes.oneOf(['bottom-right', 'bottom-left', 'top', 'bottom']),
  disabled: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default RadioWithoutCheck;
