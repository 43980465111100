import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetOrganizationsQuery } from 'api/organizations';
import { Tabs } from 'components/base-components/RTabs';
import { GASTRONOMY, PRODUCER } from 'utils/organisation-industries';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import isControlBodyMember from 'utils/is-control-body-member';
import { CONTROL_BODY } from 'utils/organisation-type';
import { useGetCurrentUserQuery } from 'api/users';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import Pagination from '../../base-components/Pagination';
import TabContent from './tab-content.component';
import '../organisations.styles.scss';

const OrganisationsList = ({ industry }) => {
  const isProducer = industry === PRODUCER;
  const isControlBody = industry === CONTROL_BODY;
  const { all } = ORGANISATIONS_STATUS;
  const { data: currentUser = null } = useGetCurrentUserQuery();
  const isControlBodyUser = isControlBodyMember(currentUser);
  const [searchParams, setSearchParams] = useSearchParams({
    q: '',
    page: 1,
    stars: [],
    organizationIds: [],
    minRangeOfUsers: [],
    maxRangeOfUsers: [],
  });
  const { t } = useTranslation();
  const currentTab = searchParams.get('tab');
  const currentPage = parseInt(searchParams.get('page'));
  const searchQuery = searchParams.get('q');
  const stars = searchParams.getAll('stars');
  const organizationIds = searchParams.getAll('organizationIds');
  const noRating = searchParams.get('noRating');
  const minimumUsers = searchParams.get('minimumUsers');
  const minRangeOfUsers = searchParams.getAll('minRangeOfUsers');
  const maxRangeOfUsers = searchParams.getAll('maxRangeOfUsers');
  const department = isProducer ? [PRODUCER] : [GASTRONOMY];
  const types = isControlBody ? [CONTROL_BODY] : [];

  let status = currentTab;

  if (!currentTab) {
    status = all;
  }

  const organizationParams = {
    q: searchQuery,
    status: status,
    page: currentPage,
    no_rating: noRating,
    minimum_users: minimumUsers,
    'department[]': isControlBody ? [] : department,
    'star_count[]': stars,
    'organization_ids[]': organizationIds,
    range_of_users: minRangeOfUsers.map((min, index) => ({ min, max: maxRangeOfUsers[index] })),
    'types[]': isControlBodyUser ? [] : types,
  };

  if (status === all) delete organizationParams.status;

  const { isFetching, data } = useGetOrganizationsQuery(organizationParams);
  const organizations = data ? data.organizations : [];

  const setSearchQueryParams = useCallback(
    params => {
      const validParams = {};

      Object.entries(params).forEach(([key, value]) => {
        if (value) validParams[key] = params[key];
      });

      setSearchParams(validParams);
    },
    [currentTab],
  );

  const producerTabs = {
    all: 0,
    interested: 1,
    registered: 2,
    active: 3,
    inactive: 4,
    aborted: 5,
  };

  const gastronomyTabs = {
    all: 0,
    active: 1,
    inactive: 2,
    draft: 3,
  };

  let controlBodyTabs;

  if (isControlBodyUser) {
    controlBodyTabs = {
      verified: 1,
      unverified: 2,
    };
  } else {
    controlBodyTabs = {
      active: 1,
      inactive: 2,
    };
  }

  controlBodyTabs = { all: 0, ...controlBodyTabs }

  let tabMapper;

  if (isControlBody) {
    tabMapper = controlBodyTabs;
  } else if (isProducer) {
    tabMapper = producerTabs;
  } else {
    tabMapper = gastronomyTabs;
  }

  const onChangeParams = (params = {}) => {
    const allParams = {
      q: searchQuery,
      tab: currentTab,
      page: currentPage,
      stars,
      organizationIds,
      noRating,
      minimumUsers,
      minRangeOfUsers,
      maxRangeOfUsers,
    };
    setSearchQueryParams({ ...allParams, ...params });
  };

  const onChangeTab = selectedTabKey => {
    const selectedTab = Object.keys(tabMapper).find(tab => tabMapper[tab] === selectedTabKey);
    onChangeParams({ page: 1, tab: selectedTab });
  };

  // TODO: Needs to remove this one
  if (!currentUser) {
    return;
  }

  return (
    <>
      <div className="organisation__rtabs">
        <Tabs
          key={industry}
          controlled={true}
          selectedTabKey={tabMapper[currentTab]}
          items={Object.keys(tabMapper).map(tab => ({
            title: t(`organisation.list.tab.${tab}`),
            key: tab,
            getContent: () => (
              <TabContent
                tab={tab}
                key={tab}
                searchQuery={searchQuery}
                organizations={organizations}
                onChangeParams={onChangeParams}
                isLoading={isFetching}
                industry={industry}
              />
            ),
          }))}
          onChange={onChangeTab}
        />
      </div>
      <div className="organisation__pagination">
        <Pagination
          onPaginationClick={newPage => {
            onChangeParams({ tab: currentTab, q: searchQuery, page: newPage });
          }}
          totalPages={data && parseInt(data.pagination.totalPages, 10)}
          currentPage={currentPage}
          firstText="First"
          lastText="Last"
          nextText="Next"
          previousText="Prev"
        />
      </div>
    </>
  );
};

OrganisationsList.propTypes = {
  industry: PropTypes.string.isRequired,
};

export default OrganisationsList;
