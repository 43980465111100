import React from 'react';
import { t } from 'i18next';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import PropTypes from 'prop-types';
import Card from 'components/base-components/Card';
import OverviewContent from './overview-content.component';

const OrganizationIDS = ({ organisation, isFetching, isAdmin }) => (
  <Card className="organisation-overview__card">
    {isFetching ? (
      <ContentLoaderPlaceholder numberOfLines={6} showBackground={false} />
    ) : (
      <>
        <span className="organisation-overview__card-title">
          {t('organisation_view.overview.attributes.ids.header')}
        </span>
        <div className="organisation-overview__content-wrapper">
          <OverviewContent
            label={t('organisation_view.overview.attributes.ids.bio_id')}
            value={organisation?.bioId}
          />
          <OverviewContent label={t('organisation_type.uid')} value={organisation?.uid} />
          <OverviewContent label={t('organisation_view.overview.attributes.ids.bur')} />
          <OverviewContent
            label={t('organisation_view.overview.attributes.ids.biobetriebsnummer')}
            noMargin={true}
            value={organisation?.debitNumber}
          />
          <OverviewContent
            label={t('organisation_view.overview.attributes.ids.kt_id_b_1')}
            noMargin={true}
          />
          <OverviewContent
            label={t('organisation_view.overview.attributes.ids.kt_id_b_2')}
            noMargin={true}
          />
        </div>
      </>
    )}
  </Card>
);

OrganizationIDS.propTypes = {
  organisation: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default OrganizationIDS;
