import React from 'react';
import IndividualForm from '../../update-single-entity.component';
import OrganisationContact from '../contact.component';

const ChangeOranisationContactForm = () => {
  return (
    <IndividualForm warningModalNeeded={false} type="contact">
      <OrganisationContact isChangeContactForm={true} />
    </IndividualForm>
  );
};

export default ChangeOranisationContactForm;
