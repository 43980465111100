import React from 'react';
import PropTypes from 'prop-types';
import { useGetIngredientReservesQuery } from 'api/control-body-survey';
import { useGetActiveIngredientReservesAdditionalTaskQuery } from 'api/control-body-survey/ingredient-reserves';
import { ACTIVE } from 'utils/verification-status';
import ReservedIngredientsCheckbox from '../entities/reserved-ingredients-checkbox.component';

const ReservedIngredients = ({
  surveyId,
  title,
  items,
  error,
  isCertifier,
  comment,
  status,
  isAuditor,
  uploadedReservedIngredientAttachments,
  setUploadedReservedIngredientAttachments,
}) => {
  const { data: activeReservedIngredientsAdditionalTask } =
    useGetActiveIngredientReservesAdditionalTaskQuery({ surveyId }, { skip: !surveyId });

  const { data: activeReservedIngredients } = useGetIngredientReservesQuery(
    { surveyId, type: ACTIVE },
    { skip: !surveyId },
  );
  return (
    <ReservedIngredientsCheckbox
      title={title}
      items={items}
      activeItems={activeReservedIngredients}
      error={error}
      comment={comment}
      isCertifier={isCertifier}
      isAuditor={isAuditor}
      status={status}
      surveyId={surveyId}
      activeReservedIngredientsAdditionalTask={activeReservedIngredientsAdditionalTask}
      uploadedReservedIngredientAttachments={uploadedReservedIngredientAttachments}
      setUploadedReservedIngredientAttachments={setUploadedReservedIngredientAttachments}
    />
  );
};

ReservedIngredients.defaultProps = {
  surveyId: null,
  title: null,
  status: null,
  items: null,
  isCertifier: false,
  isAuditor: false,
  surveyDraft: {},
  error: null,
  comment: null,
};

ReservedIngredients.propTypes = {
  surveyId: PropTypes.node,
  title: PropTypes.node,
  comment: PropTypes.node,
  status: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.shape(),
  surveyDraft: PropTypes.shape(),
  isCertifier: PropTypes.bool,
  isAuditor: PropTypes.bool,
  uploadedReservedIngredientAttachments: PropTypes.array.isRequired,
  setUploadedReservedIngredientAttachments: PropTypes.func.isRequired,
};

export default ReservedIngredients;
