import React from 'react';
import PropTypes from 'prop-types';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import { t } from 'i18next';

const NegativeTaskModal = ({ submitStatus, approveStatus, onSuccess }) => {
  return (
    <>
      {submitStatus && (
        <SubmitModal
          isLoading={submitStatus.isLoading}
          isSuccess={submitStatus.isSuccess}
          requestId={submitStatus.requestId}
          successTitle={t('negative_report.negative_task_modal.submit_title')}
          successContent={t('negative_report.negative_task_modal.submit_content')}
          onSuccess={onSuccess}
          showError={false}
        />
      )}
      {approveStatus && (
        <SubmitModal
          isLoading={approveStatus.isLoading}
          isSuccess={approveStatus.isSuccess}
          requestId={approveStatus.requestId}
          successTitle={t('negative_report.negative_task_modal.success_title')}
          successContent={t('negative_report.negative_task_modal.success_content')}
          onSuccess={onSuccess}
          showError={false}
        />
      )}
    </>
  );
};

NegativeTaskModal.defaultProps = {
  submitStatus: null,
  approveStatus: null,
  onSuccess: () => {},
};

NegativeTaskModal.propTypes = {
  submitStatus: PropTypes.shape(),
  approveStatus: PropTypes.shape(),
  onSuccess: PropTypes.func,
};

export default NegativeTaskModal;
