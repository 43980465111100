import React from 'react';
import { useGetControlBodyDeclarationHistoryQuery } from 'api/control-body-declarations';
import Pagination from 'components/base-components/Pagination';
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/base-components/Table';
import DefaultSpinner from 'components/shared-components/default-spinner';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { dateMonthYear, monthYearShort } from 'utils/date-time-formatter';

const ControlBodyHistoryComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const currentPage = parseInt(searchParams.get('page'), 10);

  const { data, isFetching } = useGetControlBodyDeclarationHistoryQuery({
    organizationId: id,
    page: currentPage,
  });

  if (isFetching) return <DefaultSpinner />;

  const controlBodyList = data.collection;

  return (
    <div className="control-body__history">
      {controlBodyList && controlBodyList.length > 0 ? (
        <>
          {controlBodyList.map(controlBody => (
            <div className="control-body__history-item" key={controlBody.id}>
              <div className="control-body__history--time-period">
                {monthYearShort(controlBody?.approvedAt)} -{' '}
                {monthYearShort(controlBody?.nextDeclarationDate)}
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeader className="control-body__history--title">
                      {t('control_body.table_field.control_body')}
                    </TableHeader>
                    <TableHeader>{t('control_body.table_field.declaration_date')}</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableData className="control-body__history--title">
                      <span>{controlBody.name}</span>
                    </TableData>
                    <TableData>{dateMonthYear(controlBody?.dateOfDeclaration)}</TableData>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          ))}
          <Pagination
            onPaginationClick={newPage => {
              setSearchParams({ page: newPage, tab: searchParams.get('tab') });
            }}
            totalPages={data && parseInt(data.pagination.totalPages, 10)}
            currentPage={currentPage}
            firstText={t('pagination.first')}
            lastText={t('pagination.last')}
            nextText={t('pagination.next')}
            previousText={t('pagination.prev')}
          />
        </>
      ) : (
        <EmptyContentPlaceholder
          iconName="neutralOutline"
          text={t('shared.placeholder.no_history')}
        />
      )}
    </div>
  );
};

export default ControlBodyHistoryComponent;
