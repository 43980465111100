import { Font, StyleSheet } from '@react-pdf/renderer';
import CenturyGothic from 'assets/fonts/centurygothic.ttf';
import CenturyGothicBold from 'assets/fonts/centurygothic_bold.ttf';
import { FONT_SIZE_10, FONT_WEIGHT_BOLD } from '../../styles/variables';

Font.register({
  family: 'CenturyGothic',
  fonts: [{ src: CenturyGothic }, { src: CenturyGothicBold, fontWeight: FONT_WEIGHT_BOLD }],
});

const coverLetterStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontFamily: 'CenturyGothic',
    lineHeight: 1.21,
    fontSize: FONT_SIZE_10,
    paddingBottom: 20,
    letterSpacing: 0.03,
  },
  body: {
    flexGrow: 1,
    marginLeft: 50,
    marginRight: 50,
    fontFamily: 'CenturyGothic',
  },
  headerLogo: {
    height: 90,
    width: '100%',
    objectFit: 'contain',
    marginBottom: 24,
    marginTop: 32,
  },
  subject: {
    fontWeight: FONT_WEIGHT_BOLD,
    marginBottom: 10,
  },
  description: {
    marginTop: 10,
    marginBottom: 5,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    marginBottom: 10,
    borderBottom: 'none',
  },
  tableRow: {
    margin: '0 auto',
    flexDirection: 'row',
    borderBottom: 1,
  },
  lastTableRow: {
    margin: '0 auto',
    flexDirection: 'row',
  },
  tableCol1: {
    width: '38%',
    borderRight: '1px solid black',
  },
  tableCol2: {
    marginLeft: 10,
    width: '60%',
  },
  cell: {
    marginTop: 2,
    marginBottom: 2,
  },
  userName: {
    marginBottom: 10,
    marginTop: 10,
    textTransform: 'capitalize',
  },
  bottomMargin: {
    marginBottom: 10,
  },

  superscript: {
    fontSize: 6,
    verticalAlign: 'super',
  },
});

export default coverLetterStyles;
