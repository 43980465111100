import { useVerifyAmountMutation } from 'api/control-body-survey';
import classNames from 'classnames';
import { debounce } from 'throttle-debounce';
import Icon from 'components/base-components/Icon';
import Input from 'components/base-components/Input';
import { useField } from 'react-final-form';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { PERMANENT, TEMPORARY } from 'utils/control-body-survey';
import { AVERAGE, BAD, GOOD } from 'utils/difference-status';

const AmountInput = ({
  amount,
  id,
  validFrom,
  validUntil,
  handleVerifyInputAmount,
  status,
  differenceStatus,
  isCertifier,
}) => {
  const [verifyCustomInput, { data: verifiyInputData }] = useVerifyAmountMutation();

  const {
    input: { value: vatPercentage },
  } = useField('vat_percentage');

  const {
    input: { value: customAmount },
  } = useField(`custom_input_${id}`);

  const verifyInput = React.useRef(
    debounce(500, (value, percentage) => {
      verifyCustomInput({
        id,
        payload: {
          custom_vat_percentage: percentage,
          random_goods_declaration_id: id,
          custom_input: value || amount,
        },
      });
    }),
  ).current;

  useEffect(() => {
    if (status !== PERMANENT ) {
      verifyInput(customAmount, vatPercentage);
    }
  }, [vatPercentage, status]);

  useEffect(() => {
    handleVerifyInputAmount(verifiyInputData, vatPercentage);
  }, [verifiyInputData]);

  let differenceIndicatorClass;

  switch (verifiyInputData?.differenceStatus || differenceStatus) {
    case GOOD:
      differenceIndicatorClass = 'status__step-icon--success';
      break;
    case AVERAGE:
      differenceIndicatorClass = 'status__step-icon--grace-period';
      break;

    case BAD:
      differenceIndicatorClass = 'status__step-icon--expired';
      break;

    default:
      break;
  }

  return (
    <div className="organisation-checklist__quarter-input">
      <div>
        {t('negative_report.random_goods.vat')}{' '}
        <span className="organisation-checklist__quarter-goods--bold">
          {' '}
          {validFrom || t('common.not_applicable')} - {validUntil || t('common.not_applicable')}{' '}
        </span>
      </div>
      <Field name={`custom_input_${id}`} initialValue={amount}>
        {({ input }) => (
          <div className="organisation-checklist__quarter-input--wrapper">
            {(verifiyInputData?.differenceStatus || differenceStatus) && (
              <div
                className={classNames({
                  'status__step-icon--wrapper': differenceStatus,
                })}
              >
                <Icon
                  className={`status__step-icon ${differenceIndicatorClass}`}
                  size="tiny"
                  name="check"
                  showBGColor={true}
                />
              </div>
            )}
            <Input
              className="organisation-checklist__quarter-input--input"
              label={t('control_body.survey.form.input_in_chf')}
              size="tiny"
              value={input.value}
              onChange={value => {
                input.onChange(value);
                verifyInput(value);
              }}
              disabled={status === TEMPORARY || isCertifier || status === PERMANENT}
            />
          </div>
        )}
      </Field>
    </div>
  );
};

AmountInput.defaultProps = {
  amount: '',
  validFrom: null,
  validUntil: null,
  status: null,
  differenceStatus: null,
  isCertifier: false,
  handleVerifyInputAmount: () => {},
};

AmountInput.propTypes = {
  amount: PropTypes.string,
  validFrom: PropTypes.string,
  validUntil: PropTypes.string,
  status: PropTypes.string,
  differenceStatus: PropTypes.string,
  isCertifier: PropTypes.bool,
  handleVerifyInputAmount: PropTypes.func,
  id: PropTypes.number.isRequired,
};

export default AmountInput;
