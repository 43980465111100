import { useDeleteImageMutation } from 'api/control-body-survey';
import classNames from 'classnames';
import Icon from 'components/base-components/Icon';
import ChooseFile from 'components/shared-components/choose-file/ChooseFile';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { LOGOS } from 'utils/control-body-survey';
import UploadLogoAttachmentItem from './upload-logo-attachment-item.component';

const UploadAdditionalPhotos = ({
  errors,
  isAuditor,
  surveyId,
  uploadedLogoAttachments,
  setUploadedLogoAttachments,
  requiredLogoCount,
  fetchPhotosDataQuery,
  itemType,
}) => {
  const { data, isSuccess } = fetchPhotosDataQuery({ surveyId });
  const [deleteImage] = useDeleteImageMutation();

  const uploadedFilesCount = uploadedLogoAttachments.length;
  const handleFileDelete = uploadedFile => {
    const filteredImage = uploadedLogoAttachments.filter(file => file.id !== uploadedFile.id);
    setUploadedLogoAttachments(filteredImage);

    if (uploadedFile.url) {
      deleteImage({ attachmentId: uploadedFile.id });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setUploadedLogoAttachments(data);
    }
  }, [isSuccess]);

  return (
    <div className="col-12 col-bleed-x">
      <div className="logo-attachment-task">
        <div
          className={classNames('logo-attachment-task__header', {
            'logo-attachment-task__header-zero': uploadedFilesCount === 0,
          })}
        >
          <div className="logo-attachment-task__header--title">
            {t('negative_report.logo.uploaded_files')}
          </div>
          <div className="logo-attachment-task__header--files">
            {uploadedFilesCount}/{requiredLogoCount} {t('negative_report.logo.uploaded')}
            <ChooseFile
              className="logo-attachment-task__header--upload-btn"
              showText={false}
              disabled={uploadedFilesCount >= requiredLogoCount || !isAuditor}
              onChange={file =>
                setUploadedLogoAttachments(prev => [...prev, { file, id: new Date().getTime() }])
              }
              labelText={
                <>
                  <Icon
                    className="choose-file__contract-delete--icon"
                    name="uploadFile"
                    size="normal"
                  />
                  <span className="choose-file__contract-delete--text">
                    {itemType === LOGOS ? t('negative_report.logo.upload_logo') : t('negative_report.logo.image_upload')}
                  </span>
                </>
              }
            />
          </div>
        </div>
      </div>

      <div className="logo-attachment-list">
        {uploadedLogoAttachments.map(uploadedFile => (
          <UploadLogoAttachmentItem
            uploadedFile={uploadedFile}
            handleFileDelete={handleFileDelete}
            isAuditor={isAuditor}
          />
        ))}
      </div>

      {errors && (
        <div className="organisation-checklist__errors">
          <Icon name="invalid" color="danger" size="small" />
          <span className="organisation-checklist__errors-text">
            {errors}
          </span>
        </div>
      )}
    </div>
  );
};

UploadAdditionalPhotos.defaultProps = {
  errors: null,
};

UploadAdditionalPhotos.propTypes = {
  surveyId: PropTypes.number.isRequired,
  requiredLogoCount: PropTypes.number.isRequired,
  errors: PropTypes.string,
  isAuditor: PropTypes.bool.isRequired,
  uploadedLogoAttachments: PropTypes.array.isRequired,
  setUploadedLogoAttachments: PropTypes.func.isRequired,
  fetchPhotosDataQuery: PropTypes.func.isRequired,
  itemType: PropTypes.string.isRequired,
};

export default UploadAdditionalPhotos;
