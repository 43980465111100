import { PillTabs } from 'components/base-components/PillTabs';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DeactivationWrapper from '../deactivation-wrapper.component';

import DeclaredProductDeclarations from './list/declared-list.component';
import DraftProductDeclarations from './list/draft-list.component';
import HistoryProductDeclarations from './list/history-list.component';

import './styles.scss';

const ProductDeclarations = ({ organizationId }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div>
      <PillTabs
        controlled={true}
        selectedTabKey={selectedTab}
        useTransitionOnChange={true}
        onChange={key => setSelectedTab(key)}
        items={[
          {
            getContent: () => (
              <DeactivationWrapper>
                <DeclaredProductDeclarations
                  organizationId={organizationId}
                  setSelectedTab={setSelectedTab}
                />
              </DeactivationWrapper>
            ),
            key: 0,
            title: t('product_declarations.tabs.active'),
          },
          {
            getContent: () => (
              <DeactivationWrapper>
                <DraftProductDeclarations organizationId={organizationId} />
              </DeactivationWrapper>
            ),
            key: 1,
            title: t('product_declarations.tabs.draft'),
          },
          {
            getContent: () => (
              <DeactivationWrapper>
                <HistoryProductDeclarations organizationId={organizationId} />
              </DeactivationWrapper>
            ),
            key: 2,
            title: t('product_declarations.tabs.history'),
          },
        ]}
      />
    </div>
  );
};

ProductDeclarations.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export default ProductDeclarations;
