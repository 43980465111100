import Avatar from 'components/base-components/Avatar';
import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/base-components/Spinner';
import { useGetUserQuery } from '../../api/users';

const UserInfo = ({ userData, userId, size }) => {
  const {
    data: fetchedUser,
    error,
    isFetching,
  } = useGetUserQuery({ id: userId }, { skip: userData });
  if (isFetching) return <Spinner bgColor="none" color="success" size="tiny" />;
  if (error) return null;

  const user = userData || fetchedUser;

  return (
    <>
      <span className="user-avatar">
        <Avatar
          src={user?.profilePictureUrl}
          firstName={user?.firstName}
          lastName={user?.lastName}
          bgColor={5}
          size={size}
        />
      </span>
      <span className="user-name">{user?.name}</span>
    </>
  );
};

UserInfo.defaultProps = {
  userData: null,
  userId: null,
  size: 'small',
};

UserInfo.propTypes = {
  userData: PropTypes.shape({
    profilePictureUrl: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
  }),
  userId: PropTypes.number,
  size: PropTypes.string,
};

export default UserInfo;
