import getNodePosition from './getNodePosition';
import getNodeDimension from './getNodeDimension';
import popover from './popoverDefaultData';

/**
 * Returns an object with the distance of popover left and right from the HTML page.
 *
 * @param {node} parentNode The DOM node of the popover-parent.
 * @param {string} direction The direction in which user wants to render popover.
 * @param {object} popoverDimension Popover height and width as an object {height, width}.
 *
 * @return {string} An object with the distance of popover left and right from the HTML page.
 */

const popoverPositionFromPage = (parentNode, direction, popoverDimension, tipSize) => {
  const { height: popoverHeight, width: popoverWidth } = popoverDimension;
  const popoverParentDimension = getNodeDimension(parentNode);
  const popoverParentPosition = getNodePosition(parentNode);
  const [directionPrefix, directionSuffix] = direction.split('-');
  let top = 0;
  let left = 0;

  if (['top', 'bottom'].includes(directionPrefix)) {
    top =
      directionPrefix === 'bottom'
        ? popoverParentPosition.y +
          window.scrollY +
          popoverParentDimension.height +
          popover.tip.size[tipSize].height +
          popover.tip.gap
        : popoverParentPosition.y +
          window.scrollY -
          (popoverHeight + popover.tip.size[tipSize].height + popover.tip.gap);

    if (directionSuffix === 'left') {
      left = popoverParentPosition.x + window.scrollX;
    } else if (directionSuffix === 'right') {
      left =
        popoverParentPosition.x + window.scrollX - (popoverWidth - popoverParentDimension.width);
    } else {
      left =
        popoverParentPosition.x +
        window.scrollX -
        (popoverWidth - popoverParentDimension.width) / 2;
    }
  } else if (['right', 'left'].includes(directionPrefix)) {
    left =
      directionPrefix === 'left'
        ? popoverParentPosition.x +
          window.scrollX -
          (popoverWidth + popover.tip.size[tipSize].height + popover.tip.gap)
        : popoverParentPosition.x +
          window.scrollX +
          popoverParentDimension.width +
          popover.tip.size[tipSize].height +
          popover.tip.gap;

    if (directionSuffix === 'top') {
      top = popoverParentPosition.y + window.scrollY;
    } else if (directionSuffix === 'bottom') {
      top =
        popoverParentPosition.y + window.scrollY - (popoverHeight - popoverParentDimension.height);
    } else {
      top =
        popoverParentPosition.y +
        window.scrollY -
        (popoverHeight - popoverParentDimension.height) / 2;
    }
  } else if (directionPrefix === 'stretch') {
    left = 0;

    if (directionSuffix === 'top') {
      top =
        popoverParentPosition.y +
        window.scrollY -
        (popoverHeight + popover.tip.size[tipSize].height + popover.tip.gap);
    } else if (directionSuffix === 'bottom') {
      top =
        popoverParentPosition.y +
        window.scrollY +
        popoverParentDimension.height +
        popover.tip.size[tipSize].height +
        popover.tip.gap;
    }
  }

  return { top: parseFloat(top.toFixed(2)), left: parseFloat(left.toFixed(2)) };
};

export default popoverPositionFromPage;
