import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/base-components/Table';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import TableLoaderPlaceholder from 'components/shared-components/placeholders/table-loader-placeholder';
import { t } from 'i18next';
import { dateMonthYear } from 'utils/date-time-formatter';
import PropTypes from 'prop-types';
import React from 'react';
import EmphasisTag from 'components/base-components/EmphasisTag';
import { Link } from 'react-router-dom';
import useNavigationLinks from 'hooks/useNavigationLinks';

const InvoiceGroupsTableRow = ({ invoice }) => {
  const { creationDate, identifier, industry, invoiceCount } = invoice;
  const navigationLinks = useNavigationLinks();

  return (
    <>
      <TableRow className="invoice-list__table-row">
        <TableData>{dateMonthYear(creationDate)}</TableData>
        <TableData>{identifier || t('common.not_applicable')}</TableData>
        <TableData>
          <EmphasisTag type="normal" size="tiny" text={t(`organisation.industry.${industry}`)} />
        </TableData>
        {/* //TODO Have to do this after backend is ready. */}
        <TableData>{t('common.not_applicable')}</TableData>
        <TableData>{invoiceCount}</TableData>
        <TableData align="center">
          <Link
            to={navigationLinks.invoiceListPage(
              invoice.identifier,
              invoice.creationDate,
              invoice.type,
              invoice.industry,
              invoice.status,
            )}
          >
            <IconButton icon={<Icon name="showAlt" />} />
          </Link>
        </TableData>
      </TableRow>
    </>
  );
};

export const InvoiceGroupsTableHeader = ({ currentTab }) => (
  <TableHead>
    <TableRow>
      <TableHeader>{t('invoice.creation_date')}</TableHeader>
      <TableHeader>{t('invoice.identifier')}</TableHeader>
      <TableHeader>{t('user_details.department')}</TableHeader>
      <TableHeader>{t('invoice.job_type')}</TableHeader>
      <TableHeader>{t('invoice.current_page_invoices', { current_page: currentTab })}</TableHeader>
      <TableHeader align="center">{t('shared.actions')}</TableHeader>
    </TableRow>
  </TableHead>
);

const InvoiceGroupsTable = ({ invoices, isFetching, currentTab }) => {
  return (
    <>
      {!isFetching && invoices.length === 0 ? (
        <EmptyContentPlaceholder iconName="invoice" text={t('invoice.no_invoice')} />
      ) : (
        <>
          <Table>
            <InvoiceGroupsTableHeader currentTab={currentTab} />
            <TableBody>
              {isFetching ? (
                <TableLoaderPlaceholder numberOfRows={10} numberOfColumns={6} />
              ) : (
                invoices.map((invoice, i) => <InvoiceGroupsTableRow key={i} invoice={invoice} />)
              )}
            </TableBody>
          </Table>
        </>
      )}
    </>
  );
};

InvoiceGroupsTableHeader.propTypes = {
  currentTab: PropTypes.string.isRequired,
};

InvoiceGroupsTableRow.propTypes = {
  invoice: PropTypes.shape().isRequired,
};

InvoiceGroupsTable.defaultProps = {
  isFetching: false,
};

InvoiceGroupsTable.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isFetching: PropTypes.bool,
  currentTab: PropTypes.string.isRequired,
};

export default InvoiceGroupsTable;
