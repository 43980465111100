import { useGetOrganizationQuery } from 'api/organizations';
import PropTypes from 'prop-types';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import Input from 'components/base-components/Input';
import OrganisationHeadComponent from 'components/organisations/organisation-head.component';
import CountrySelector from 'components/shared-components/country-selector';
import useCustomNavigate from 'hooks/useCustomNavigate';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const TVDFormComponent = ({ onSave, localUnitId, localUnitData }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useCustomNavigate();

  const onSubmit = async (values, form) => {
    const formData = new FormData();
    formData.append('tvd_sticker_number', values.tvdStickerNumber || '');
    formData.append('address[line1]', values.line1 || '');
    formData.append('address[city]', values.city || '');
    formData.append('address[line2]', values?.line2 || '');
    formData.append('address[zip_code]', values?.zipCode || '');
    formData.append('address[country_code]', values?.country?.value || '');
    formData.append('organization_id', id);

    return onSave(formData)
      .unwrap()
      .then(() => navigate.openOrganisationViewPage(id, 'tvd'))
      .catch(({ status, data: { errors } }) => {
        if (status === 422) {
          let errorMessages = {
            tvdStickerNumber: errors?.tvdStickerNumber,
            line1: errors?.addressLine1,
            city: errors?.addressCity,
            zipCode: errors?.addressZipCode,
            countryCode: errors?.countryCode,
          };

          return errorMessages;
        }

        return errors;
      });
  };

  const { data: organisation, isSuccess: isOrganisationFetched } = useGetOrganizationQuery(id);

  const countryData = localUnitData &&
    localUnitData?.address?.country && {
      label: localUnitData.address.country.name,
      value: localUnitData.address.country.code,
    };

  return (
    <>
      {isOrganisationFetched && (
        <OrganisationHeadComponent organisation={organisation} showContract={false} />
      )}
      <div className="grid justify-center">
        <Card className="col-5 user__general-form--card">
          <Form
            onSubmit={onSubmit}
            validate={() => {}}
            render={({ handleSubmit, form, submitting, submitErrors }) => (
              <form onSubmit={handleSubmit}>
                <div className="col-6 col-bleed">
                  <Field
                    name="tvdStickerNumber"
                    initialValue={localUnitData?.tvdStickerNumber || ''}
                  >
                    {({ input, meta }) => (
                      <Input
                        {...input}
                        size="tiny"
                        label={t('tvd.form.tvd_number')}
                        placeholder={t('tvd.placeholder.tvd_number')}
                        required={true}
                        touched={!meta.dirtySinceLastSubmit}
                        error={meta.submitError}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-12 col-bleed-x">
                  <Field name="line1" initialValue={localUnitData?.address?.line1 || ''}>
                    {({ input, meta }) => (
                      <Input
                        size="tiny"
                        label={t('organisation_view.overview.address_header.address_line')}
                        placeholder={t('tvd.placeholder.address_line')}
                        {...input}
                        touched={!meta.dirtySinceLastSubmit}
                        error={meta.submitError}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-12 col-bleed">
                  <Field name="line2" initialValue={localUnitData?.address?.line2 || ''}>
                    {({ input }) => (
                      <Input
                        size="tiny"
                        label={t('organisation_view.overview.address_header.address_suffix')}
                        placeholder={t('tvd.placeholder.address_suffix')}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-12 col-bleed-x">
                  <Field name="city" initialValue={localUnitData?.address?.city || ''}>
                    {({ input, meta }) => (
                      <Input
                        size="tiny"
                        label={t('control_body.input_field.city')}
                        placeholder={t('shared.placeholder.address.city')}
                        {...input}
                        touched={!meta.dirtySinceLastSubmit}
                        error={meta.submitError}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-12 col-bleed">
                  <div className="col-4 col-bleed">
                    <Field name="zipCode" initialValue={localUnitData?.address?.zipCode || ''}>
                      {({ input, meta }) => (
                        <Input
                          size="tiny"
                          label={t('organisation_view.overview.address_header.zip')}
                          placeholder={t('shared.placeholder.address.zip_code')}
                          touched={!meta.dirtySinceLastSubmit}
                          error={meta.submitError}
                          {...input}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="col-4 col-bleed-y">
                    <Field name="canton">
                      {({ input }) => (
                        <Input
                          size="tiny"
                          label={t('organisation_view.overview.address_header.canton')}
                          placeholder={t('tvd.placeholder.canton')}
                          {...input}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="col-4 col-bleed">
                    <Field name="country" initialValue={countryData}>
                      {({ input, meta }) => (
                        <CountrySelector
                          size="tiny"
                          width="large"
                          required={true}
                          isClearable={false}
                          value={input.value}
                          selectedValue={input.label}
                          onChange={value => input.onChange(value)}
                          errorMsg={meta.submitError}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="col-12 col-bleed user__general-form--footer">
                  <Button
                    label={localUnitId ? t('tvd.form.update_tvd') : t('tvd.form.add_tvd')}
                    type="success"
                    size="small"
                    disabled={submitting}
                    submitType="submit"
                  />
                  <Button
                    className="user__general-form--footer__cancel-btn"
                    size="small"
                    label={t('user_general.cancel_button')}
                    onClick={() => navigate.openOrganisationViewPage(id, 'tvd')}
                  />
                </div>
              </form>
            )}
          />
        </Card>
      </div>
    </>
  );
};

TVDFormComponent.defaultProps = {
  onSave: () => {},
  localUnitId: null,
  localUnitData: {},
};

TVDFormComponent.propTypes = {
  onSave: PropTypes.func,
  localUnitId: PropTypes.node,
  localUnitData: PropTypes.shape(),
};

export default TVDFormComponent;
