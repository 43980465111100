import { removeEmptyFromObject } from 'utils/object-cleanup';

export const greenAreaValues = (values, formData) => {
  const greenAreaAttributes = {
    publicly_accessible_green_area: values?.publicly_accessible_green_area_in_hectares,
    urban_gardening: values?.urban_gardening_in_hectares,
  };

  const filteredGreenAreaAttributes = removeEmptyFromObject(greenAreaAttributes);

  Object.keys(filteredGreenAreaAttributes).forEach(area => {
    if (values[area]) {
      formData.append('production_details[recreational_and_green_area][][sub_type]', area);
      formData.append(
        'production_details[recreational_and_green_area][][areas_in_hectare]',
        filteredGreenAreaAttributes[area],
      );
    }
  });
};
