import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/base-components/Card';
import { t } from 'i18next';
import TitleComponent from 'components/organisations/form/producer/plant-cultivate/shared/title.component';
import { isNonEmptyArray } from 'utils/check-object-length';

const FruitContent = ({ data, title, name }) => {
  return (
    <>
      {isNonEmptyArray(data) && (
        <div key={name} className="operations__production__fruit-content-card">
          <TitleComponent title={title} isOutsideForm={true} />
          <hr className="organisation-checklist__checkbox-horizontal-line" />
          {data?.map(fruit => (
            <Card className="operations__production__fruit-content margin-top-10">
              <div className="operations__production__fruit-content-title">
                {t(`plant_cultivation.fruits.${name}.${fruit.fruitType}`)}
              </div>
              {fruit?.productionDetails?.openFieldSurfaceAreaInAres !== 0 &&
                fruit?.productionDetails?.openFieldSurfaceAreaInAres !== null && (
                  <div className="flex-space-between">
                    <span>{t(`operations.shared.open_field`)}</span>
                    <span>
                      {t('operations.unit.ares', {
                        value: fruit.productionDetails.openFieldSurfaceAreaInAres,
                      })}
                    </span>
                  </div>
                )}
              {fruit?.productionDetails?.coveredFieldSurfaceAreaInAres !== 0 &&
                fruit?.productionDetails?.coveredFieldSurfaceAreaInAres !== null && (
                  <div className="flex-space-between margin-top-10">
                    <span>{t(`operations.shared.covered_cultivation`)}</span>
                    <span>
                      {t('operations.unit.ares', {
                        value: fruit.productionDetails.coveredFieldSurfaceAreaInAres,
                      })}
                    </span>
                  </div>
                )}
            </Card>
          ))}
        </div>
      )}
    </>
  );
};

FruitContent.defaultProps = {
  title: null,
  data: [],
};

FruitContent.propTypes = {
  title: PropTypes.node,
  data: PropTypes.arrayOf(PropTypes.shape()),
  name: PropTypes.string.isRequired,
};

export default FruitContent;
