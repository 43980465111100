import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popover from '../Popover';
import EmptyIcon from './empty_stack.png';

const getItemsForDisplay = items => {
  const isIcon = items && (items[0].type === 'Icon' || items[0].type === 'Avatar');

  if (isIcon) {
    return items && items.length > 3 ? items.slice(0, 3) : items;
  }
  return items && items.length > 1 ? items.slice(0, 1) : items;
};

const getItemQuantity = items => {
  if (items.length >= 3) return 'full';
  switch (items.length) {
    case 2:
      return 'two';
    default:
      return 'one';
  }
};

const StackedItems = props => {
  const {
    className,
    children,
    isPopoverVisible,
    itemSize,
    itemName,
    emptyPlaceholder,
    direction,
    popoverSize,
    onOutsideClick,
    onClick,
  } = props;
  const displayItems = React.Children.map(getItemsForDisplay(children), child => {
    return React.cloneElement(child, {
      className: classNames(
        'rcl-stacked-items__display-item',
        `rcl-stacked-items__display-item--${itemSize}`,
      ),
      size: itemSize,
      showBGColor: true,
    });
  });

  const itemAmount = displayItems && (children.length - 1 > 0 ? `+${children.length - 1}` : '');
  const itemQuantity = displayItems && getItemQuantity(children);

  return (
    <div
      className={classNames('rcl-stacked-items', `rcl-stacked-items--${itemQuantity}`, className)}
    >
      <Popover
        isVisible={isPopoverVisible}
        className="rcl-stacked-items__list"
        direction={direction}
        content={React.Children.map(children, child =>
          React.cloneElement(child, {
            className: 'rcl-stacked-items__list-item',
            size: 'tiny',
            showName: true,
            showLabel: true,
          }),
        )}
        onOutsideClick={onOutsideClick}
        size={popoverSize}
      >
        {displayItems ? (
          <div
            className="rcl-stacked-items__display"
            role="button"
            tabIndex={0}
            onClick={onClick}
            onKeyDown={onClick}
          >
            <div
              className={classNames(
                'rcl-stacked-items__display-items',
                `rcl-stacked-items__display-items--${itemSize}`,
              )}
            >
              {displayItems}
              <span
                className={classNames(
                  'rcl-stacked-items__display-item-amount',
                  `rcl-stacked-items__display-item-amount--${itemSize}`,
                )}
              >
                {itemName ? `${itemAmount} ${itemName}` : itemAmount}
              </span>
            </div>
          </div>
        ) : (
          <div
            className={classNames(
              'rcl-stacked-items__empty',
              `rcl-stacked-items__empty--${itemSize}`,
            )}
          >
            <img src={EmptyIcon} alt="empty_stack" />
            <span>{emptyPlaceholder}</span>
          </div>
        )}
      </Popover>
    </div>
  );
};

StackedItems.defaultProps = {
  className: '',
  children: null,
  isPopoverVisible: false,
  itemSize: 'tiny',
  itemName: null,
  emptyPlaceholder: null,
  direction: 'bottom-left',
  popoverSize: 'medium',
  onOutsideClick: null,
  onClick: null,
};

StackedItems.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isPopoverVisible: PropTypes.bool,
  itemSize: PropTypes.oneOf(['tiny', 'small', 'normal', 'large', 'huge', 'xl', 'xxl']),
  direction: PropTypes.oneOf([
    'right',
    'right-top',
    'right-bottom',
    'left',
    'left-top',
    'left-bottom',
    'top',
    'top-left',
    'top-right',
    'bottom',
    'bottom-left',
    'bottom-right',
  ]),
  itemName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  emptyPlaceholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  popoverSize: PropTypes.oneOf(['small', 'medium', 'large']),
  onOutsideClick: PropTypes.func,
  onClick: PropTypes.func,
};

export default StackedItems;
