const titleOrderMap = {
  cow: ['number_of_mother_cows', 'number_of_calves', 'number_of_cattle'],
  pig: ['number_of_fattening_pigs', 'number_of_breeding_pigs'],
  poultry: [
    'number_of_male_pullets',
    'number_of_fattening_chicken',
    'number_of_turkeys',
    'number_of_geese',
    'other_poultry',
  ],
  ewe_sheep: ['number_of_ewe_sheep'],
  ewe_goat: ['number_of_ewe_goat'],
  gardening_item: [
    'perennial_nursery',
    'gardening_landscaping',
    'herbs',
    'ornamental_plant_nursery',
    'tree_nursery',
    'other',
  ],
  arable_crops: [
    'feed_grain',
    'bread_cerals',
    'sugar_beet',
    'grain_legumes_for_fodder',
    'roughage',
    'oats',
    'seed_production',
    'oil_seeds',
    'mixed_seed_arable_crops_for_fodder_purposes',
    'other',
  ],
};

const sortTitleOrder = (titleType, value) => {
  const titleOrder = titleOrderMap[titleType] || [];

  if (Array.isArray(value)) {
    return titleOrder.filter(title => value.includes(title));
  }

  if (typeof value === 'object' && value !== null) {
    return titleOrder.filter(title => value[title]);
  }

  return [];
};

export default sortTitleOrder;
