import { t } from 'i18next';
import {
  EATING_BERRIES,
  HIGH_TRUNK_TREE,
  INDUSTRY_BERRIES,
  ORCHARD,
} from 'utils/plant-cultivation';
import {
  ANIMAL,
  ARABLE_CROP,
  DAIRY_FIRM,
  EGG,
  MILK,
  MUSHROOM,
  MUSHROOM_OTHERS,
  ORNAMENTAL,
  OTHER,
  PLANT,
  POTATO,
  TABLE_GRAPE,
  VEGETABLE,
} from 'utils/production-data-type';
import { MEAT_PRODUCTION, PLANT_CULTIVATION } from 'utils/production-type';
import { FARMLAND, PRODUCTION_DATA, TVD } from 'utils/organisation-create-options';
import plantValidation from './field-validation/plant-validation';
import meatValidation from './field-validation/meat-validation';
import dairyFarmValidation from './field-validation/dairy-farm-validation';
import greenAreaValidation from './field-validation/green-area-validation';
import animalValidation from './field-validation/animal-validation';
import gardenValidation from './field-validation/garden-validation';
import privateParkValidation from './field-validation/private-park-validation';

const getFieldValidationErrors = (activeIndex, values, tab, department, TAB_MAPPER) => {
  let errorMessages = {};

  const onHandleProductionFields = (key, fields) => {
    const commonErrorFields = [
      PLANT,
      ARABLE_CROP,
      ORNAMENTAL,
      OTHER,
      MUSHROOM,
      MUSHROOM_OTHERS,
      VEGETABLE,
      POTATO,
      TABLE_GRAPE,
    ];

    const getErrorText = (key, area) => {
      if (key === PLANT_CULTIVATION && !commonErrorFields?.includes(area)) {
        return t('production_error_text.missing_field'); // Only for the input field error
      }

      if (key === MILK && area !== DAIRY_FIRM) {
        return t('production_error_text.all_field'); // div error where all field is mandatory
      }

      return t('production_error_text.one_field'); // div error
    };

    values?.production?.includes(tab) &&
      tab === key && // check only the current tab value
      Object.keys(fields)?.forEach(area => {
        if (fields[area]) {
          errorMessages = { ...errorMessages, [area]: getErrorText(key, area) }; // contains error of production data field, e.g: potato, viticulture, mil cow etc
        }
      });
  };

  if (TAB_MAPPER[department][activeIndex] === FARMLAND) {
    const isDGVERequired = values.production?.some(item =>
      [EGG, MEAT_PRODUCTION, MILK, ANIMAL].includes(item),
    );
    const productionError = values?.production?.every(data => data === null);

    if (isDGVERequired && !values?.dgve) {
      errorMessages = { ...errorMessages, dgve: t('production_error_text.missing_field') };
    }

    if (productionError) {
      errorMessages = { ...errorMessages, production: t('production_error_text.one_product') };
    }

    errorMessages = {
      ...errorMessages,
      agriculturalLandInHectare: !values?.agriculturalLandInHectare,
      greenlandInHectare: !values?.greenlandInHectare,
      openCultivatedLandInHectare: !values?.openCultivatedLandInHectare,
    };
  }

  if (TAB_MAPPER[department][activeIndex] === PRODUCTION_DATA) {
    const fruitTypes = [HIGH_TRUNK_TREE, ORCHARD, EATING_BERRIES, INDUSTRY_BERRIES];

    const plantError = plantValidation(values);
    const meatError = meatValidation(values);
    const diaryFarmError = dairyFarmValidation(values);
    const gardenError = gardenValidation(values);
    const animalError = animalValidation(values);
    const greenArea = greenAreaValidation(values);
    const privateParkError = privateParkValidation(values);

    const eggError = {
      egg: !values?.numberOfFemalePullets && !values?.numberOfLayingHen && !values?.numberOfQuails,
    };

    const beeError = {
      bee_hive: !values?.numberOfBeeColonies || !values.quantityOfHoney,
    };

    const fishError = {
      fishFarming: !values?.quantity_of_fish,
    };

    onHandleProductionFields('privatePark', privateParkError);
    onHandleProductionFields('greenArea', greenArea);
    onHandleProductionFields('animal', animalError);
    onHandleProductionFields('garden', gardenError);
    onHandleProductionFields('egg', eggError);
    onHandleProductionFields('bee', beeError);
    onHandleProductionFields('fish', fishError);
    onHandleProductionFields('milk', diaryFarmError);
    onHandleProductionFields('meat_production', meatError);
    onHandleProductionFields('plant_cultivation', plantError);

    if (
      values?.plantTypes?.includes('fruit') &&
      !values?.fruitData &&
      !values?.table_grapes_checkbox &&
      !values?.other_fruits
    ) {
      errorMessages = { ...errorMessages, fruit: t('production_error_text.one_field') }; // check if there's any fruit type selected
    }

    fruitTypes?.forEach(type => {
      if (values?.[type] && !values?.fruitData?.[type]) {
        errorMessages = { ...errorMessages, [type]: t('production_error_text.one_field') };
      }

      if (values?.[type] && values?.fruitData?.[type]) {
        values?.fruitData?.[type]?.forEach(fruit => {
          if (
            fruit?.fruit_type &&
            type === HIGH_TRUNK_TREE &&
            fruit?.open_field &&
            !fruit?.number_of_trees
          ) {
            errorMessages = {
              ...errorMessages,
              [fruit?.fruit_type]: {
                fruit: HIGH_TRUNK_TREE,
                text: t('production_error_text.one_field'), // check if there's any fruit selected inside high_trunk_tree when it is selected
              },
              [type]: t('production_error_text.one_field'),
            };
          } else if (
            fruit?.fruit_type &&
            type !== HIGH_TRUNK_TREE &&
            ((fruit?.covered_field && !fruit?.covered_surface_area_in_ares) ||
              (fruit?.open_field && !fruit?.open_surface_area_in_ares)) // check if there's any fruit selected other than high_trunk_tree
          ) {
            errorMessages = {
              ...errorMessages,
              [fruit?.fruit_type]: {
                fruit: type,
                text: t('production_error_text.one_field'),
              },
              [type]: t('production_error_text.one_field'),
            };
            // contains error of fruit items, e.g: eating_apple, strawberry etc
          }
        });
      }
    });

    if (
      values?.plantTypes?.includes('herb') &&
      values?.herbsData?.every(herb => herb.type === null)
    ) {
      errorMessages = { ...errorMessages, herb: t('production_error_text.one_field') }; // check if any herb is selected when herb type is true
    }

    values?.herbsData?.forEach(herb => {
      if (herb?.type) {
        if (!herb?.open_checkbox && !herb?.covered_checkbox) {
          errorMessages = {
            ...errorMessages,
            herbInput: {
              herb: herb?.type,
              text: t('production_error_text.one_field'), // check if any herb type is selected but fields are empty
            },
          };
        }

        if (herb?.open_checkbox && !herb?.open_surface_area_in_ares) {
          errorMessages = {
            ...errorMessages,
            herbs_open_surface_area_in_ares: {
              herb: herb?.type,
              text: t('production_error_text.missing_field'), // check if any herb type is selected and open field is also selected but value is empty
            },
          };
        }

        if (herb?.covered_checkbox && !herb?.covered_surface_area_in_ares) {
          errorMessages = {
            ...errorMessages,
            herbs_covered_surface_area_in_ares: {
              herb: herb?.type,
              text: t('production_error_text.missing_field'), // check if any herb type is selected and covered field is also selected but value is empty
            },
          };
        }

        if (herb?.cultivation_periods?.every(period => period === null)) {
          errorMessages = {
            ...errorMessages,
            herbs_cultivation_period: {
              herb: herb?.type,
              text: t('production_error_text.cultivation_period'), // check if any herb type is selected and cultivation period is empty
            },
          };
        }
      }
    });
  }

  if (TAB_MAPPER[department][activeIndex] === TVD) {
    if (values?.tvd_sticker_enabled === null || values?.tvd_sticker_enabled === undefined) {
      errorMessages = { ...errorMessages, tvd: t('production_error_text.missing_field') };
    }
  }

  return errorMessages;
};

export default getFieldValidationErrors;
