import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DefaultSpinner from 'components/shared-components/default-spinner';
import { useGetSupplierCertificatesOverviewQuery } from 'api/supplier-certificates';
import Link from 'components/base-components/Link';
import Icon from 'components/base-components/Icon';
import classNames from 'classnames';
import EmphasisTag from 'components/base-components/EmphasisTag';
import { Trans, useTranslation } from 'react-i18next';
import { useGetCurrentUserQuery } from 'api/users';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { APPROVED, COMPLAINED, DRAFT, SUBMITTED, EXPIRED } from 'utils/verification-status';
import { NEW_DECLARATION } from 'utils/declaration-status';
import TimePeriodComponent from './time-period.component';
import OverviewTextComponent from './overview-text.component';

const SupplierCertificatesOverview = ({ onClick, organizationId, chartaEnabled }) => {
  const { t } = useTranslation();

  const { data: certificatesOverview = {}, isFetching } = useGetSupplierCertificatesOverviewQuery({
    organizationId,
  });

  const { data: currentUser = {} } = useGetCurrentUserQuery();

  const isAdmin = isBioSuisseAdmin(currentUser);

  if (isFetching) return <DefaultSpinner className="default-spinner--overview" />;

  const handleText = status => {
    if (!chartaEnabled) {
      return t('organisation_view.overview.charta.not_enabled');
    }
    if (certificatesOverview.gracePeriodEnabled) {
      return t('organisation_overview.messages.supplier_certificates.grace_period_enabled', {
        validUntil: certificatesOverview.validUntil,
      });
    }
    if (status === null || status === NEW_DECLARATION || status === DRAFT) {
      return t('organisation_overview.messages.supplier_certificates.not_declared');
    } else if (status === SUBMITTED) {
      return t('organisation_overview.messages.shared.submitted');
    } else if (status === COMPLAINED) {
      return t('organisation_overview.messages.shared.complained');
    } else if (status === APPROVED) {
      return t('organisation_overview.messages.shared.approved');
    }

    return (
      <span>
        <Trans
          i18nKey="organisation_overview.messages.supplier_certificates.warning"
          components={{ bold: <b /> }}
        />
      </span>
    );
  };

  const handleIconColor = status => {
    if (status === EXPIRED) return 'danger';
    if (certificatesOverview.gracePeriodEnabled === true) return 'warning';
    if (status === APPROVED) return 'success';

    return null;
  };

  const handleIconName = status => {
    if (status === EXPIRED) return 'highPriority';
    if (status === APPROVED || certificatesOverview.gracePeriodEnabled === true) return 'check';

    return null;
  };

  const declarationOverview = (
    <OverviewTextComponent
      text={handleText(certificatesOverview.status)}
      status={certificatesOverview.status}
    />
  );

  const showViewDetails = [APPROVED, SUBMITTED, COMPLAINED].includes(certificatesOverview.status);

  const startDeclaration = chartaEnabled && !showViewDetails;

  return (
    <Fragment>
      <div className="organisation-overview__title">
        <div className="organisation-overview__title-header">
          <span className="organisation-overview__title-header-text">
            {t('organisation_overview.supplier')}
          </span>
          <Icon
            className={classNames('organisation-overview__title-header--icon', {
              'organisation-overview__title-header--icon__success':
                certificatesOverview.status === APPROVED &&
                certificatesOverview.gracePeriodEnabled === false,
              'organisation-overview__title-header--icon__submitted':
                certificatesOverview.status === SUBMITTED,
              'organisation-overview__title-header--icon__danger':
                certificatesOverview.status === EXPIRED,
              'organisation-overview__title-header--icon__warning':
                certificatesOverview.gracePeriodEnabled === true,
            })}
            size="xs"
            name={handleIconName(certificatesOverview.status)}
            color={handleIconColor(certificatesOverview.status)}
            showBGColor={true}
          />
        </div>
        {certificatesOverview.status === SUBMITTED || certificatesOverview.status === COMPLAINED ? (
          <EmphasisTag
            type={certificatesOverview.status === SUBMITTED ? 'accent' : 'warning'}
            text={
              certificatesOverview.status === SUBMITTED
                ? t('shared.submitted')
                : t('shared.complained')
            }
          />
        ) : (
          <TimePeriodComponent
            validUntil={certificatesOverview.validUntil}
            validFrom={certificatesOverview.validFrom}
            status={certificatesOverview.status}
            showToBeDeclaredText={false}
          />
        )}
      </div>
      <div>{declarationOverview}</div>
      <Link
        type="button"
        className="organisation-overview__details"
        size="small"
        onClick={onClick}
        modifier={startDeclaration ? 'success' : 'default'}
        disabled={startDeclaration && isAdmin}
      >
        {startDeclaration
          ? t('organisation_view.overview.start_declaration')
          : t('shared.view_details')}
      </Link>
    </Fragment>
  );
};

SupplierCertificatesOverview.defaultProps = {
  onClick: () => {},
  chartaEnabled: false,
};

SupplierCertificatesOverview.propTypes = {
  organizationId: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  chartaEnabled: PropTypes.bool,
};

export default SupplierCertificatesOverview;
