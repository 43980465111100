import React, { forwardRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as Tip } from 'assets/tip.svg';
import { defaultPopoverCSSVariables } from '../helpers/index';
import useOutsideClickHandler from '../hooks/useOutsideClickHandler';

const Popover = forwardRef((props, ref) => {
  const {
    className,
    content,
    direction,
    size,
    tipSize,
    showTip,
    position,
    onOutsideClick,
    parentElementRef,
  } = props;

  // if we click on the parent or popover, onOutsideClick won't be invoked.
  useOutsideClickHandler([ref, parentElementRef], onOutsideClick);

  return ReactDOM.createPortal(
    <div
      className={classNames(`rcl-popover rcl-popover--${direction}`, className)}
      style={{
        ...defaultPopoverCSSVariables(tipSize),
        top: `${position.top}px`,
        left: `${position.left}px`,
      }}
      ref={ref}
    >
      {showTip && direction.split('-')[0] !== 'stretch' ? (
        <span className={classNames(`rcl-popover__tip rcl-popover__tip--${direction}`)}>
          <Tip />
        </span>
      ) : null}
      <div className={classNames(`rcl-popover__content rcl-popover__content--${size}`)}>
        {content}
      </div>
    </div>,
    document.body,
  );
});

Popover.defaultProps = {
  content: null,
  className: null,
  direction: 'bottom',
  size: 'medium',
  tipSize: 'small',
  showTip: false,
  position: { top: 0, left: 0 },
  onOutsideClick: () => {},
  parentElementRef: { current: null },
};

Popover.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  tipSize: PropTypes.oneOf(['tiny', 'small']),
  direction: PropTypes.oneOf([
    'top',
    'top-left',
    'top-right',
    'right',
    'right-top',
    'right-bottom',
    'bottom',
    'bottom-left',
    'bottom-right',
    'left',
    'left-top',
    'left-bottom',
    'stretch-top',
    'stretch-bottom',
  ]),
  className: PropTypes.string,
  showTip: PropTypes.bool,
  position: PropTypes.shape({ top: PropTypes.number, left: PropTypes.number }),
  onOutsideClick: PropTypes.func,
  parentElementRef: PropTypes.shape(),
};

export default Popover;
