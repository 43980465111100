const appendAddress = (address, formData, destroyed = false) => {
  if (!address) {
    return {};
  }

  let attributes = {
    address_type: 'general',
    id: address.id,
    address_name: address.addressName || null,
    city: address.city || null,
    zip_code: address.zipCode || null,
    line1: address.line1 || null,
    line2: address.line2 || null,
    country_code: address?.country?.value,
    canton: address?.canton,
  };

  if (destroyed) {
    attributes = { ...attributes, destroy: true };
  }

  prepareNestedAttributes({ entityName: 'address', attributes, formData });
};

const prepareNestedAttributes = ({ entityName, attributes, formData }) => {
  Object.keys(attributes).forEach(key => {
    if (attributes[key]) {
      formData.append(`${entityName}[${key}]`, attributes[key]);
    }
  });
};

export default appendAddress;
