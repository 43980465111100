import React from 'react';
import routes from 'utils/routes';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Link from 'components/base-components/Link';

const DownloadPdf = ({ entityType, type, label, ...params }) => {
  const url = routes[entityType].entity({
    format: 'pdf',
    ...params,
  });

  return (
    <Link
      href={`${process.env.REACT_APP_BACKEND_URL}/api/v1${url}`}
      download
      type={type}
      size="small"
      modifier="default"
    >
      <span className="invoice__list--download">{label}</span>
    </Link>
  );
};

DownloadPdf.defaultProps = {
  params: {},
  label: t('invoice.download'),
};

DownloadPdf.propTypes = {
  entityId: PropTypes.number.isRequired,
  entityType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  params: PropTypes.shape(),
  label: PropTypes.string,
};

export default DownloadPdf;
