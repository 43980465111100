import Button from 'components/base-components/Button';
import Icon from 'components/base-components/Icon';
import Modal from 'components/base-components/Modal';
import Spinner from 'components/base-components/Spinner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const DeactivateReactivateModal = ({
  type,
  status,
  setIsModalOpen,
  isModalOpen,
  callback,
  orgId,
  orgName,
  refetch,
}) => {
  const { t } = useTranslation();

  if (!isModalOpen) return null;

  const hideModal = () => {
    setIsModalOpen(false);
    if (status.isSuccess || status.isError) {
      refetch();
      status.reset();
    }
  };

  const modalProps = () => {
    if (status.isSuccess || status.isError) {
      return {
        onOutsideClick: hideModal,
        timeout: 2000,
        onTimeout: hideModal,
      };
    } else {
      return {
        onOutsideClick: hideModal,
      };
    }
  };

  const actionType = type === 'inactive' ? 'reactivate' : 'deactivate';

  const modalContent = () => {
    if (status.isLoading) {
      return (
        <>
          <Modal.Title className="user-details__modal--title">
            <Spinner size="small" color="success" bgColor="none" />
          </Modal.Title>
          <Modal.Content className="user-details__modal--content">
            {t(`organisation_view.manage_organisation.modal.${actionType}.loading.description`)}
          </Modal.Content>
        </>
      );
    } else if (status.isSuccess) {
      return (
        <>
          <Modal.Title className="user-details__modal--title">
            <Icon name="check" showBGColor={true} size="medium" color="success" />
            <div className="user-details__modal--title__name">
              {t(`organisation_view.manage_organisation.modal.${actionType}.success.title`)}
            </div>
          </Modal.Title>
          <Modal.Content className="user-details__modal--content">
            {t(`organisation_view.manage_organisation.modal.${actionType}.success.description`, {
              organisation_name: orgName,
            })}
          </Modal.Content>
        </>
      );
    } else if (status.isError) {
      return (
        <>
          <Modal.Title className="user-details__modal--title">
            <Icon name="invalid" showBGColor={false} size="xxl" color="danger" />
            <div className="user-details__modal--title__name">
              {t(`organisation_view.manage_organisation.modal.${actionType}.failed.title`)}
            </div>
          </Modal.Title>
          <Modal.Content className="user-details__modal--content">
            {t(`organisation_view.manage_organisation.modal.${actionType}.failed.description`, {
              organisation_name: orgName,
            })}
            <br />
            {t('shared.try_again')}
          </Modal.Content>
        </>
      );
    } else {
      return (
        <>
          <Modal.Title className="user-details__modal--title">
            <Icon name="warning" showBGColor={true} size="large" color="danger" />
            <div className="user-details__modal--title__name">
              {t(`organisation_view.manage_organisation.modal.${actionType}.show.title`, {
                organisation_name: orgName,
              })}
            </div>
          </Modal.Title>
          <Modal.Content className="user-details__modal--content">
            <p>
              {t(`organisation_view.manage_organisation.modal.${actionType}.show.description`, {
                organisation_name: orgName,
              })}
            </p>
          </Modal.Content>
          <Modal.Footer>
            <Button
              label={t('shared.action.yes_please')}
              type="success"
              onClick={() => callback(orgId)}
            />
            <Button label={t('shared.action.no')} onClick={hideModal} />
          </Modal.Footer>
        </>
      );
    }
  };

  return (
    <Modal className="user-details__modal" isVisible={true} {...modalProps()}>
      {modalContent()}
    </Modal>
  );
};

DeactivateReactivateModal.defaultProps = {
  status: {},
  setIsModalOpen: false,
  isModalOpen: false,
  callback: () => {},
  orgName: null,
  refetch: () => {},
  onClick: () => {},
};

DeactivateReactivateModal.propTypes = {
  type: PropTypes.string.isRequired,
  status: PropTypes.shape(),
  setIsModalOpen: PropTypes.func,
  isModalOpen: PropTypes.bool,
  callback: PropTypes.func,
  orgId: PropTypes.number.isRequired,
  orgName: PropTypes.string,
  refetch: PropTypes.func,
  organizationId: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

export default DeactivateReactivateModal;
