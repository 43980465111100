import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';

// eslint-disable-next-line import/prefer-default-export
export const isOutsideRange = (day, minDate, maxDate, enablePastDates, disableFutureDates) => {
  if (minDate && !maxDate) {
    return day.isBefore(minDate);
  }

  if (maxDate && !minDate) {
    return day.isAfter(maxDate);
  }

  if (minDate && maxDate) {
    return !day.isBetween(minDate, maxDate);
  }

  if (enablePastDates && disableFutureDates) {
    return day.isAfter(moment());
  }

  if (!enablePastDates && disableFutureDates) {
    if (day.isSame(moment(), 'day')) {
      return false;
    }

    return true;
  }

  if (!enablePastDates) {
    return day.isBefore(moment());
  }

  return false;
};
