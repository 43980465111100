import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import IconButton from 'components/base-components/IconButton';
import Icon from 'components/base-components/Icon';
import EmphasisTag from '../base-components/EmphasisTag';
import WarningModal from './modal/warning';

const ViewComplaint = ({ complaint }) => {
  const [enableModal, setEnableModal] = useState(false);
  const { t } = useTranslation();

  return (
    <Fragment>
      <WarningModal
        onOutsideClick={() => {
          setEnableModal(false);
        }}
        isVisible={enableModal}
        title={t('supplier_certificates.complain')}
        content={
          <div className="supplier-certificates__complain">
            <span className="supplier-certificates__complain-header">{complaint.title}</span>
            <span className="supplier-certificates__complain-body">{complaint.description}</span>
          </div>
        }
        confirmActionText={t('shared.action.okay')}
        cancelActionText={t('shared.action.cancel')}
        onCancel={() => setEnableModal(false)}
        onConfirm={() => setEnableModal(false)}
      />
      <div>
        <EmphasisTag
          type="warning"
          size="tiny"
          text={
            <div className="product-declarations__complain-view--tag-wrapper">
              {t('product_declarations.status.complained')}
              <div className="product-declarations__complain-view--vertical-line" />
              <IconButton
                icon={<Icon name="show" />}
                size="tiny"
                isIconOnly={true}
                onClick={() => setEnableModal(true)}
              />
            </div>
          }
        />
      </div>
    </Fragment>
  );
};

ViewComplaint.propTypes = {
  complaint: PropTypes.shape().isRequired,
};

export default ViewComplaint;
