import React, { Fragment } from 'react';
import Icon from 'components/base-components/Icon';
import { useGetControlBodyDeclarationsOverviewQuery } from 'api/control-body-declarations';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import EmphasisTag from 'components/base-components/EmphasisTag';
import {
  EXPIRED,
  APPROVED,
  SUBMITTED,
  COMPLAINED,
  NEW_DECLARATION,
} from 'utils/declaration-status';
import classNames from 'classnames';
import { useGetCurrentUserQuery } from 'api/users';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import Link from 'components/base-components/Link';
import { useGetOrganizationQuery } from 'api/organizations';
import DefaultSpinner from 'components/shared-components/default-spinner';
import ORGANISATION_TYPE from 'utils/organisation-type';
import { DRAFT } from 'utils/verification-status';
import TimePeriodComponent from './time-period.component';
import OverviewTextComponent from './overview-text.component';

const ControlBodyOverview = ({ organizationId, onClick, chartaEnabled }) => {
  const { t } = useTranslation();

  const { data: organisation } = useGetOrganizationQuery(organizationId);

  const { data: controlBodyOverview = {}, isFetching } = useGetControlBodyDeclarationsOverviewQuery({
    organizationId,
  });

  const { data: currentUser = {} } = useGetCurrentUserQuery();

  const isAdmin = isBioSuisseAdmin(currentUser);

  if (isFetching) return <DefaultSpinner className="default-spinner--overview" />;

  const handleIconColor = status => {
    if (status === EXPIRED) return 'danger';
    if (status === APPROVED) return 'success';

    return null;
  };

  const handleIconName = status => {
    if (status === EXPIRED) return 'highPriority';
    if (status === APPROVED) return 'check';

    return null;
  };

  const handleText = status => {
    if (!chartaEnabled) {
      return t('organisation_view.overview.charta.not_enabled');
    }
    if (status === null || status === NEW_DECLARATION || status === DRAFT) {
      return t('organisation_overview.messages.control_body.not_declared');
    } else if (status === SUBMITTED || status === COMPLAINED || status === APPROVED) {
      return t(`organisation_overview.messages.shared.${status}`);
    }

    return (
      <span>
        <Trans
          i18nKey="organisation_overview.messages.control_body.warning"
          components={{ bold: <b /> }}
        />
      </span>
    );
  };

  const declarationOverview = (
    <OverviewTextComponent
      text={
        organisation?.type === ORGANISATION_TYPE.sub_organisation
          ? t('control_body.parent_manage')
          : handleText(controlBodyOverview.status)
      }
      status={controlBodyOverview.status}
    />
  );

  const showViewDetails =
    controlBodyOverview.completed ||
    controlBodyOverview.status === SUBMITTED ||
    controlBodyOverview.status === COMPLAINED;

  const startDeclaration = chartaEnabled && !showViewDetails;

  return (
    <Fragment>
      <div className="organisation-overview__title">
        <div className="organisation-overview__title-header">
          <span className="organisation-overview__title-header-text">
            {t('organisation_overview.control_body')}
          </span>
          <Icon
            className={classNames('organisation-overview__title-header--icon', {
              'organisation-overview__title-header--icon__success':
                controlBodyOverview.status === APPROVED,
              'organisation-overview__title-header--icon__submitted':
                controlBodyOverview.status === SUBMITTED,
              'organisation-overview__title-header--icon__danger':
                controlBodyOverview.status === EXPIRED,
            })}
            size="xs"
            name={handleIconName(controlBodyOverview.status)}
            color={handleIconColor(controlBodyOverview.status)}
            showBGColor={true}
          />
        </div>
        {controlBodyOverview.status === SUBMITTED || controlBodyOverview.status === COMPLAINED ? (
          <EmphasisTag
            type={controlBodyOverview.status === SUBMITTED ? 'accent' : 'warning'}
            text={
              controlBodyOverview.status === SUBMITTED
                ? t('control_body.status.submitted')
                : t('shared.complained')
            }
          />
        ) : (
          <TimePeriodComponent
            validFrom={controlBodyOverview.validFrom}
            validUntil={controlBodyOverview.validUntil}
            status={controlBodyOverview.status}
            showToBeDeclaredText={false}
          />
        )}
      </div>
      <div>{declarationOverview}</div>
      <Link
        type="button"
        className="organisation-overview__details"
        size="small"
        onClick={onClick}
        modifier={startDeclaration ? 'success' : 'default'}
        disabled={
          (startDeclaration && isAdmin) || organisation?.type === ORGANISATION_TYPE.sub_organisation
        }
      >
        {startDeclaration
          ? t('organisation_view.overview.start_declaration')
          : t('shared.view_details')}
      </Link>
    </Fragment>
  );
};

ControlBodyOverview.defaultProps = {
  chartaEnabled: false,
  onClick: () => {},
};

ControlBodyOverview.propTypes = {
  organizationId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  chartaEnabled: PropTypes.bool,
};

export default ControlBodyOverview;
