import { apiSlice } from 'api';
import routes from 'utils/routes';
import {
  ACCOUNTING_PROCESSES,
  ACCOUNTING_PROCESSES_ADDITIONAL_TASK,
  CONTROL_BODY_SURVEY,
} from 'utils/tag-types';

const accountingProcessesApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getAccountingProcess: build.query({
      query: params => routes.accountingProcesses.entity(params),
      providesTags: [ACCOUNTING_PROCESSES],
    }),
    getActiveAccountingProcessesAdditionalTask: build.query({
      query: params => routes.accountingProcesses.active(params),
      providesTags: [ACCOUNTING_PROCESSES_ADDITIONAL_TASK],
    }),
    declareAccountingProcessesAdditionalTask: build.mutation({
      query: ({ surveyId, payload }) => ({
        url: routes.accountingProcesses.declare({ surveyId }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, id) =>
        !error ? [ACCOUNTING_PROCESSES_ADDITIONAL_TASK] : [],
    }),
    approveAccountingProcessesAdditionalTask: build.mutation({
      query: ({ surveyId }) => ({
        url: routes.accountingProcesses.approve({ surveyId }),
        method: 'PUT',
      }),
      invalidatesTags: (result, error, id) =>
        !error ? [ACCOUNTING_PROCESSES_ADDITIONAL_TASK, CONTROL_BODY_SURVEY] : [],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAccountingProcessQuery,
  useGetActiveAccountingProcessesAdditionalTaskQuery,
  useDeclareAccountingProcessesAdditionalTaskMutation,
  useApproveAccountingProcessesAdditionalTaskMutation,
} = accountingProcessesApi;
