import React from 'react';
import PropTypes from 'prop-types';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader/';

const RowLoaderPlaceHolder = ({ numberOfLines }) => {
  return Array(numberOfLines)
    .fill(0)
    .map(() => (
      <div className="row-loader-placeholder">
        <ContentLoaderPlaceholder numberOfLines={1} showBackground={true} isRounded={true} />
      </div>
    ));
};

RowLoaderPlaceHolder.defaultProps = {
  numberOfLines: 10,
};

RowLoaderPlaceHolder.propTypes = {
  numberOfLines: PropTypes.number,
};

export default RowLoaderPlaceHolder;
