import React from 'react';
import Button from 'components/base-components/Button';
import Icon from 'components/base-components/Icon';
import Modal from 'components/base-components/Modal';
import Spinner from 'components/base-components/Spinner';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DeleteModal = ({
  modelName,
  callback,
  status,
  deleteCallbackParams,
  setDeleteCallbackParams,
  customTranslation,
}) => {
  const { t } = useTranslation();

  const hideModal = () => {
    setDeleteCallbackParams(null);
    status.reset();
  };

  if (!deleteCallbackParams) return null;

  const modalProps = () => {
    if (status.isSuccess || status.isError) {
      return {
        onOutsideClick: hideModal,
        timeout: 2000,
        onTimeout: hideModal,
      };
    } else {
      return {
        onOutsideClick: hideModal,
      };
    }
  };

  const modalContent = () => {
    if (status.isLoading) {
      return (
        <>
          <Modal.Title className="user-details__modal--title">
            <Spinner size="small" color="success" bgColor="none" />
          </Modal.Title>
          <Modal.Content className="user-details__modal--content">
            {t(`shared.delete_modal.in_progress.description`, {
              modelName: t(`models.${modelName}.capitalized`),
            })}
          </Modal.Content>
        </>
      );
    } else if (status.isSuccess) {
      return (
        <>
          <Modal.Title className="user-details__modal--title">
            <Icon name="check" showBGColor={true} size="medium" color="success" />
            <div className="user-details__modal--title__name">
              {t('shared.delete_modal.success.title', {
                modelName: t(`models.${modelName}.capitalized`),
              })}
            </div>
          </Modal.Title>
          <Modal.Content className="user-details__modal--content">
            {t('shared.delete_modal.success.description', {
              modelName: t(`models.${modelName}.capitalized`),
            })}
          </Modal.Content>
        </>
      );
    } else if (status.isError) {
      return (
        <>
          <Modal.Title className="user-details__modal--title">
            <Icon name="invalid" showBGColor={false} size="xxl" color="danger" />
            <div className="user-details__modal--title__name">
              {t('shared.delete_modal.failed.title')}
            </div>
          </Modal.Title>
          <Modal.Content className="user-details__modal--content">
            {t('shared.delete_modal.failed.description', {
              modelName: t(`models.${modelName}.lowercase`),
            })}
            <br />
            {t('shared.try_again')}
          </Modal.Content>
        </>
      );
    } else {
      return (
        <>
          <Modal.Title className="user-details__modal--title">
            <Icon name="warning" showBGColor={true} size="large" color="warning" />
            <div className="user-details__modal--title__name">
              {customTranslation
                ? t(`shared.delete_modal.custom.${modelName}.confirmation.title`)
                : t('shared.delete_modal.confirmation.title', {
                    modelName: t(`models.${modelName}.capitalized`),
                  })}
            </div>
          </Modal.Title>
          <Modal.Content className="user-details__modal--content">
            {customTranslation
              ? t(`shared.delete_modal.custom.${modelName}.confirmation.description`)
              : t('shared.delete_modal.confirmation.description', {
                  modelName: t(`models.${modelName}.capitalized`),
                })}
          </Modal.Content>
          <Modal.Footer>
            <Button
              label={t('shared.action.delete')}
              type="success"
              onClick={() => callback(deleteCallbackParams)}
            />
            <Button label={t('shared.action.cancel')} onClick={hideModal} />
          </Modal.Footer>
        </>
      );
    }
  };

  return (
    <Modal className="user-details__modal" isVisible={true} {...modalProps()}>
      {modalContent()}
    </Modal>
  );
};

DeleteModal.defaultProps = {
  deleteCallbackParams: null,
  customTranslation: false,
};

DeleteModal.propTypes = {
  modelName: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  status: PropTypes.shape().isRequired,
  deleteCallbackParams: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.number]),
  setDeleteCallbackParams: PropTypes.func.isRequired,
  customTranslation: PropTypes.bool,
};

export default DeleteModal;
