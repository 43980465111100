import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Link = props => {
  const {
    children,
    className,
    disabled,
    href,
    isInversed,
    labelHint,
    labelHintPosition,
    modifier,
    onClick,
    openNewTab,
    rel,
    size,
    type,
    ...other
  } = props;

  const getClassNames = () => {
    return classNames({
      [`${modifier} ${size}`]: type === 'anchor',
      [`button button--v2 button--${size} button--${modifier}`]: type === 'button',
      [`icon-button icon-button--v2 icon-button--${size} icon-button--${modifier}`]:
        type === 'icon-button',
      [`button--disabled`]: disabled && type === 'button',
      [`icon-button--disabled`]: disabled && type === 'icon-button',
      [`disabled`]: disabled && type === 'anchor',
      [`icon-button--${modifier}-inverse`]: isInversed && type === 'icon-button',
    });
  };

  return (
    <a
      {...other}
      className={classNames(`link link--as-${type}`, getClassNames(), className)}
      target={openNewTab ? '_blank' : '_self'}
      rel={rel}
      onClick={onClick}
      href={href || undefined}
    >
      {type === 'icon-button' ? <div className="icon-button__icon">{children}</div> : children}
      {labelHint && (
        <span
          className={`link__label-hint link__label-hint--${modifier} link__label-hint--${labelHintPosition}`}
        >
          {labelHint}
        </span>
      )}
    </a>
  );
};

Link.defaultProps = {
  className: '',
  disabled: false,
  href: '',
  isInversed: false,
  labelHint: null,
  labelHintPosition: 'bottom',
  modifier: 'primary',
  onClick: undefined,
  openNewTab: false,
  rel: undefined,
  size: 'normal',
  type: 'anchor',
};

Link.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  isInversed: PropTypes.bool,
  labelHint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelHintPosition: PropTypes.oneOf(['right', 'left', 'top', 'bottom']),
  modifier: PropTypes.oneOf([
    'primary',
    'tertiary',
    'default',
    'danger',
    'warning',
    'success',
    'info',
  ]),
  onClick: PropTypes.func,
  openNewTab: PropTypes.bool,
  rel: PropTypes.string,
  size: PropTypes.oneOf(['tiny', 'small', 'normal', 'large', 'huge']),
  type: PropTypes.oneOf(['anchor', 'button', 'icon-button', 'plain']),
};

export default Link;
