import React, { Fragment, useState } from 'react';
import { Field, Form } from 'react-final-form';
import SidePanel from 'components/base-components/SidePanel';
import Icon from 'components/base-components/Icon';
import Button from 'components/base-components/Button';
import { useTranslation } from 'react-i18next';
import Input from 'components/base-components/Input';
import Textarea from 'components/base-components/Textarea';
import PropTypes from 'prop-types';

const ComplaintForm = ({ onSave, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSave}
      validate={() => {}}
      render={({ handleSubmit, form }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="supplier-certificates__form--margin-top">
              <Field name="title">
                {({ input, meta }) => (
                  <Input
                    size="tiny"
                    label={t('supplier_certificates.comment.title.label')}
                    placeholder={t('supplier_certificates.comment.title.placeholder')}
                    touched={!meta.dirtySinceLastSubmit}
                    error={meta.submitError}
                    required={true}
                    {...input}
                  />
                )}
              </Field>
            </div>
            <div className="supplier-certificates__form--margin-top">
              <Field name="description">
                {({ input, meta }) => (
                  <Textarea
                    size="tiny"
                    label={t('supplier_certificates.comment.describe.label')}
                    placeholder={t('supplier_certificates.comment.describe.placeholder')}
                    touched={!meta.dirtySinceLastSubmit}
                    error={meta.submitError}
                    required={true}
                    {...input}
                  />
                )}
              </Field>
            </div>
            <div className="supplier-certificates__form--margin-top supplier-certificates__form-action">
              <Button
                label={t('shared.action.save')}
                size="small"
                onClick={handleSubmit}
                type="success"
              />
              <Button
                label={t('shared.action.cancel')}
                size="small"
                onClick={() => {
                  form.reset();
                  onCancel();
                }}
              />
            </div>
          </form>
        );
      }}
    />
  );
};

ComplaintForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const Complaint = ({ isDisabled, label, onSave }) => {
  const [enableSidePanel, setEnableSidePanel] = useState(false);
  const { t } = useTranslation();

  const onCreatingComplaint = values =>
    onSave(values)
      .unwrap()
      .then(() => {
        setEnableSidePanel(false);
        return null;
      })
      .catch(({ data: { errors } }) => {
        return errors;
      });

  return (
    <Fragment>
      <SidePanel
        title={t('shared.raise_complaint')}
        closeIcon={<Icon name="close" />}
        body={
          <ComplaintForm onSave={onCreatingComplaint} onCancel={() => setEnableSidePanel(false)} />
        }
        isOpened={enableSidePanel}
        onClick={() => {}}
        onClose={() => {
          setEnableSidePanel(false);
        }}
      />
      <Button
        label={label}
        size="small"
        disabled={isDisabled}
        onClick={() => {
          setEnableSidePanel(true);
        }}
      />
    </Fragment>
  );
};

Complaint.defaultProps = {
  label: null,
  onSave: () => {},
};

Complaint.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  label: PropTypes.node,
  onSave: PropTypes.func,
};

export default Complaint;
