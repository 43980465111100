import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Text(props) {
  const { children, className, level, weight, isInline } = props;
  const classes = classNames('text', className, `text--level-${level}`, `text--${weight}`);

  if (isInline) {
    return <span className={classes}>{children}</span>;
  }

  return <p className={classes}>{children}</p>;
}

Text.defaultProps = {
  children: null,
  className: null,
  level: 1,
  weight: 'normal',
  isInline: false,
};

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  level: PropTypes.oneOf([1, 2, 3]),
  weight: PropTypes.oneOf(['bold', 'medium', 'normal', 'light']),
  isInline: PropTypes.bool,
};

export default Text;
