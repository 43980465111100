import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const NavButton = props => {
  const { className, children, onClick } = props;

  return (
    <div className={classNames('rtabs__nav-button-container', className)}>
      <button type="button" className="rtabs__nav-button" onClick={onClick}>
        {children}
      </button>
    </div>
  );
};

NavButton.defaultProps = {
  className: '',
  children: null,
  onClick: () => {},
};

NavButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default memo(NavButton);
