import React from 'react';
import { t } from 'i18next';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import PropTypes from 'prop-types';
import Card from 'components/base-components/Card';
import Button from 'components/base-components/Button';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { MOBILE, PHONE } from 'utils/contact_type';
import { useParams } from 'react-router-dom';
import OverviewContent from './overview-content.component';

const ContactInformation = ({ organisation, isFetching, isAdmin }) => {
  const navigate = useCustomNavigate();
  const { id } = useParams();
  const phone = organisation?.phoneNumbers?.find(number => number.numberType === PHONE);
  const mobile = organisation?.phoneNumbers?.find(number => number.numberType === MOBILE);
  const emailAddresses = organisation?.emailAddresses.map(obj => obj?.email).join(', ');

  return (
    <Card className="organisation-overview__card">
      {isFetching ? (
        <ContentLoaderPlaceholder numberOfLines={4} showBackground={false} />
      ) : (
        <>
          <span className="organisation-overview__card-title">
            {t('organisation_view.overview.attributes.contact.header')}
          </span>
          <div className="organisation-overview__contact-wrapper">
            <OverviewContent
              label={t('organisation_view.overview.attributes.contact.mobile')}
              value={mobile?.number}
              preferred={mobile?.primary}
            />
            <OverviewContent
              label={t('organisation_view.overview.attributes.contact.website')}
              value={organisation?.website}
            />
            <OverviewContent
              label={t('organisation_view.overview.attributes.contact.phone')}
              value={phone?.number}
              preferred={phone?.primary}
              noMargin={true}
            />
            <OverviewContent
              label={t('organisation_view.overview.attributes.contact.email')}
              value={emailAddresses}
              noMargin={true}
            />
          </div>
          {isAdmin && (
            <Button
              className="organisation-overview__content--button"
              label={t('organisation_view.overview.attributes.contact.edit_button')}
              size="small"
              onClick={() => navigate.openOrganisationChangeContactPage(id)}
            />
          )}
        </>
      )}
    </Card>
  );
};

ContactInformation.propTypes = {
  organisation: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default ContactInformation;
