import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { useParams } from 'react-router-dom';
import OverviewContent from './overview-content.component';

const ModeOfCommunication = ({ organisation, isFetching, isAdmin }) => {
  const navigate = useCustomNavigate();
  const { id } = useParams();

  return (
    <Card className="organisation-overview__card">
      {isFetching ? (
        <ContentLoaderPlaceholder numberOfLines={4} showBackground={false} />
      ) : (
        <>
          <span className="organisation-overview__card-title">
            {t('organisation_view.overview.attributes.mode_of_communication')}
          </span>
          <div className="organisation-overview__communication-wrapper">
            <OverviewContent
              label={t('organisation_view.overview.address_header.language')}
              value={t(`languages.${organisation?.language}`)}
            />
            <OverviewContent
              label={t('user_general.spoken_language')}
              value={
                organisation?.secondSpokenLanguage &&
                t(`languages.${organisation?.secondSpokenLanguage}`)
              }
              noMargin={true}
            />
          </div>
          {isAdmin && (
            <Button
              className="organisation-overview__content--button"
              label={t('organisation_view.overview.attributes.edit_mode_of_communication')}
              size="small"
              onClick={() => navigate.openOrganisationChangeModeOfCommunicationPage(id)}
            />
          )}
        </>
      )}
    </Card>
  );
};

ModeOfCommunication.propTypes = {
  organisation: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default ModeOfCommunication;
