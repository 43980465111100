import React from 'react';
import {
  useCreateProductMutation,
  useGetProductCategoriesQuery,
  useUpdateProductMutation,
} from 'api/product-declarations';
import Button from 'components/base-components/Button';
import Input from 'components/base-components/Input';
import SelectBox from 'components/base-components/SelectBox';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import snakecaseKeys from 'snakecase-keys';
import { removeEmptyFromObject } from 'utils/object-cleanup';
import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';

const ProductForm = ({ product, setIsSidepanelOpen, organizationId }) => {
  const { t } = useTranslation();
  const [createProduct] = useCreateProductMutation();
  const [updateProduct, updateStatus] = useUpdateProductMutation();
  const { data: productCategories = [] } = useGetProductCategoriesQuery();

  const productCategoryOptions = productCategories.map(value => ({
    label: t(`products.categories.${value}`),
    value,
  }));

  const initialCategory = product
    ? { label: t(`products.categories.${product.category}`), value: product.category }
    : null;

  const onSubmit = values => {
    const payload = removeEmptyFromObject({ ...values, category: values?.category?.value });

    if (product) {
      return updateProduct({ params: { productId: product.id, organizationId }, payload })
        .unwrap()
        .then(() => setIsSidepanelOpen(false))
        .catch(({ data: { errors } }) => snakecaseKeys(errors));
    } else {
      return createProduct({ organizationId, payload })
        .unwrap()
        .then(() => setIsSidepanelOpen(false))
        .catch(({ data: { errors } }) => snakecaseKeys(errors));
    }
  };

  return (
    <>
      <SubmitModal
        isSuccess={updateStatus.isSuccess}
        requestId={updateStatus.requestId}
        successTitle={t('product_declarations.modal.update_successful.title')}
        successContent={t('product_declarations.modal.update_successful.content')}
        showError={false}
        showLoader={false}
      />
      <div className="product-form__subtitle">
        {product
          ? t('product_declarations.product.editting_for')
          : t('product_declarations.product.adding_for')}
      </div>
      <div className="product-form__subtitle--bold">
        {t('product_declarations.draft_declaration')}
      </div>
      <Card className="product-form__alert-card">
        <div className="product-form__alert">
          <Icon name="warning" showBGColor={true} size="tiny" color="warning" />
          <div className="product-form__alert-text">
            <span className="product-form__alert-text--bold">
              {t('product_declarations.alert_title')}
            </span>
            <span> {t('product_declarations.alert_description')}</span>
          </div>
        </div>
      </Card>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Field name="name" initialValue={product?.name}>
              {({ input, meta }) => (
                <Input
                  {...input}
                  className="product-form__field--input"
                  error={meta?.submitError}
                  size="tiny"
                  label={t('product_declarations.attributes.product_name')}
                  placeholder={t('product_declarations.placeholders.product_name')}
                  required={true}
                  touched={!meta.dirtySinceLastSubmit}
                />
              )}
            </Field>

            <Field name="category" initialValue={initialCategory}>
              {({ input, meta }) => (
                <SelectBox
                  {...input}
                  errorMsg={meta?.submitError}
                  isClearable={false}
                  label={t('product_declarations.attributes.category')}
                  options={productCategoryOptions}
                  placeholderText={t('product_declarations.placeholders.category')}
                  required={true}
                  size="tiny"
                  touched={!meta.dirtySinceLastSubmit}
                />
              )}
            </Field>

            <Field name="supplier" initialValue={product?.supplier}>
              {({ input, meta }) => (
                <Input
                  {...input}
                  className="product-form__field--input"
                  error={meta?.submitError}
                  size="tiny"
                  label={t('product_declarations.attributes.supplier')}
                  placeholder={t('product_declarations.placeholders.supplier')}
                  required={true}
                  touched={!meta.dirtySinceLastSubmit}
                />
              )}
            </Field>

            <Button
              className="product-form__field--button"
              label={t('shared.action.save')}
              type="success"
              size="small"
              disabled={submitting}
              submitType="submit"
            />
            <Button
              className="product-form__field--button"
              size="small"
              label={t('shared.action.cancel')}
              onClick={() => setIsSidepanelOpen(false)}
            />
          </form>
        )}
      />
    </>
  );
};

ProductForm.defaultProps = {
  product: null,
};

ProductForm.propTypes = {
  organizationId: PropTypes.number.isRequired,
  setIsSidepanelOpen: PropTypes.func.isRequired,
  product: PropTypes.shape(),
};

export default ProductForm;
