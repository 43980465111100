import React, { forwardRef } from 'react';
import { BlobProvider } from '@react-pdf/renderer';
import Spinner from 'components/base-components/Spinner';
import bytesToMegabytes from 'utils/bytes-to-megabytes';
import PropTypes from 'prop-types';

const ProducerBlobProvider = forwardRef(function ProducerBlobProvider(props, ref) {
  const { document, fileName } = props;
  return (
    <BlobProvider document={document}>
      {({ blob, url, loading, error }) => {
        if (loading) return <Spinner bgColor="none" color="success" size="tiny" />;
        if (error) return <div>Error: {error.message}</div>;
        const fileSize = blob ? blob.size : 0;
        return (
          <>
            <div className="organisation-contract__download">
              <div className="organisation-contract__download-producer--file">
                <div>{fileName}</div>
                <div className="organisation-contract__download-file--seperator" />
                <div>{bytesToMegabytes(fileSize)} MB</div>
                <a download={fileName} href={url} ref={ref} />
              </div>
            </div>
          </>
        );
      }}
    </BlobProvider>
  );
});

ProducerBlobProvider.propTypes = {
  document: PropTypes.node.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default ProducerBlobProvider;
