const greenAreaValidation = values => {
  return {
    greenArea: !values.publicly_accessible_green_area && !values.urban_gardening,
    publicly_accessible_green_area:
      values.publicly_accessible_green_area && !values.publicly_accessible_green_area_in_hectares,
    urban_gardening: values.urban_gardening && !values.urban_gardening_in_hectares,
  };
};

export default greenAreaValidation;
