import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { Break } from 'components/pdf/shared/shared-components';
import styles from 'components/pdf/styles/styles';
import PropTypes from 'prop-types';

const PdfFrontPage = ({ organisation }) => {
  const mainAddress = () => {
    const address = organisation?.addresses.find(address => address.main === true);
    if (address) {
      return (
        <Text>
          {address.line1 && (
            <>
              {address.line1} <Break />
            </>
          )}
          {address.line2 && (
            <>
              {address.line2} <Break />
            </>
          )}
          {address.zipCode && `${address.zipCode} `}
          {address.city && (
            <>
              {address.city} <Break />
            </>
          )}
          {address.country && (address.country.code === 'ch' ? 'Schweiz' : address.country?.name)}
        </Text>
      );
    }
  };

  return (
    <View>
      <Text style={styles.title}>Bio Cuisine Markennutzungsvertrag mit Verwendung der Knospe</Text>
      <Text style={styles.frontPageDescription}>
        zwischen <Break count={2} />
        BIO SUISSE <Break />
        Peter Merian-Strasse 34 <Break />
        4052 Basel <Break />
        <Text style={{ fontSize: 9 }}>
          (nachstehend "Bio Suisse" genannt; einem Verein im Sinne von Art. 60 ZGB)
        </Text>
        <Break count={2} />
        und <Break count={2} />
        {organisation?.name} <Break />
        {mainAddress()}
        <Break count={2} />
        <Text style={{ fontSize: 9 }}>(nachstehend "Markennutzer"; weibliche Form mitgemeint)</Text>
        <Break count={2} />
      </Text>
    </View>
  );
};

PdfFrontPage.propTypes = {
  organisation: PropTypes.shape().isRequired,
};

export default PdfFrontPage;
