import React from 'react';
import PropTypes from 'prop-types';

function SideNavBrand(props) {
  const { icon, logo, url } = props;

  return (
    <a href={url} className="side-nav__brand">
      <div className="side-nav__brand-icon">
        <img className="side-nav__brand-img" src={icon} alt="brand icon" />
      </div>
      <div className="side-nav__brand-logo">
        <img className="side-nav__brand-title-img" src={logo} alt="brand logo" />
      </div>
    </a>
  );
}

SideNavBrand.defaultProps = {
  url: './',
};

SideNavBrand.propTypes = {
  logo: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  url: PropTypes.string,
};

export default SideNavBrand;
