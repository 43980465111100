import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

const Modal = props => {
  const { className, children, isVisible, onOutsideClick, timeout, onTimeout } = props;
  let modalToggleTimer;
  let modalToggleTimerKey;
  let onTimeoutKey;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasTransition, setHasTransition] = useState(false);
  const [transformVisible, setTransformVisible] = useState(false);

  if (timeout && !onTimeout) {
    console.warn('onTimeout callback should be declared if timeout is declared!');
  }

  const handleOnOutsideClick = () => {
    if (!timeout) {
      onOutsideClick();
    }
  };

  useEffect(() => {
    if (isVisible) {
      setIsModalVisible(true);
      modalToggleTimer = () => {
        setHasTransition(true);
        setTransformVisible(true);
      };
      setTimeout(modalToggleTimer, 100);
    } else {
      setTransformVisible(false);
      modalToggleTimer = () => {
        setHasTransition(false);
        setIsModalVisible(false);
      };
      modalToggleTimerKey = setTimeout(modalToggleTimer, 300);
    }

    if (timeout && isVisible) {
      onTimeoutKey = setTimeout(onTimeout, timeout);
    }

    return () => {
      clearTimeout(modalToggleTimerKey);
      clearTimeout(onTimeoutKey);
    };
  }, [isVisible, timeout]);

  return (
    isModalVisible &&
    createPortal(
      <div className={classNames('modal', className)}>
        <div
          className={classNames('modal__backdrop', {
            'modal__backdrop--transform-visible': transformVisible && hasTransition,
          })}
          onClick={handleOnOutsideClick}
          role="presentation"
        />
        <div
          className={classNames('modal__container', {
            'modal__container--transform-visible': transformVisible,
            'modal__container--use-transition': hasTransition,
          })}
        >
          {children}
        </div>
      </div>,
      document.body
    )
  );
};

Modal.defaultProps = {
  className: null,
  children: null,
  isVisible: false,
  onOutsideClick: () => {},
  timeout: null,
  onTimeout: null,
};

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isVisible: PropTypes.bool,
  onOutsideClick: PropTypes.func,
  timeout: PropTypes.number,
  onTimeout: PropTypes.func,
};

export default Modal;
