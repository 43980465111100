import routes from 'utils/routes';
import { LOCAL_UNIT, TVD } from 'utils/tag-types';
import { apiSlice } from '../../index';

const localUnitApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getLocalUnits: build.query({
      query: params => routes.localUnit.collection(params),
      providesTags: [LOCAL_UNIT, TVD],
    }),
    getLocalUnit: build.query({
      query: params => routes.localUnit.entity(params),
      providesTags: [LOCAL_UNIT],
      TVD,
    }),
    createLocalUnit: build.mutation({
      query: ({ organisationId, payload }) => ({
        url: routes.localUnit.collection({ organisationId }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [LOCAL_UNIT, TVD],
    }),
    deleteLocalUnit: build.mutation({
      query: ({ localUnitId }) => ({
        url: routes.localUnit.delete({ localUnitId }),
        method: 'PUT',
      }),
      invalidatesTags: [LOCAL_UNIT, TVD],
    }),
    updateLocalUnit: build.mutation({
      query: ({ payload, localUnitId }) => ({
        url: routes.localUnit.entity({ localUnitId }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [LOCAL_UNIT, TVD],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLocalUnitsQuery,
  useGetLocalUnitQuery,
  useCreateLocalUnitMutation,
  useDeleteLocalUnitMutation,
  useUpdateLocalUnitMutation,
} = localUnitApi;
