import Alert from 'components/base-components/Alert';
import Button from 'components/base-components/Button';
import CheckBox from 'components/base-components/CheckBox/CheckBox';
import DatePicker from 'components/base-components/DatePicker/DatePicker';
import TimePickerComponent from 'components/base-components/TimePicker';
import moment from 'moment';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useGetInvoiceJobQuery, useReleaseInvoiceMutation } from 'api/invoices';
import { useParams, useSearchParams } from 'react-router-dom';
import { dateMonthYear, dateTimeWithZone } from 'utils/date-time-formatter';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import { toSnakecaseKeys } from 'utils/object-cleanup';

const ReleaseInvoiceForm = ({ setEnableSidePanel }) => {
  const { id } = useParams();
  const [isDueDateOpen, setIsDueDateOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobIds');

  const [releaseInvoice, { isLoading, isSuccess, requestId }] = useReleaseInvoiceMutation();

  const { data: invoiceJob = {} } = useGetInvoiceJobQuery(
    {
      invoiceJobId: jobId,
    },
    { skip: id || jobId === 'null' },
  );

  const onSave = values => {
    return releaseInvoice({
      invoiceId: jobId,
      payload: {
        release_at: dateTimeWithZone(`${values.invoiceDate} ${values.invoiceTime}`),
        change_invoice_date: values.changeInvoiceDate,
        id: jobId,
      },
    })
      .unwrap()
      .then(() => setEnableSidePanel(false))
      .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
  };

  return (
    <div>
      <div className="invoice__release-date">{t('invoice.release.release_date')}</div>
      <Form
        onSubmit={onSave}
        render={({ handleSubmit, values, submitErrors }) => (
          <form onSubmit={handleSubmit}>
            <Field name="invoiceDate">
              {({ input, meta }) => (
                <DatePicker
                  focused={isDueDateOpen}
                  required={true}
                  date={input.value}
                  onChange={e => input.onChange(e)}
                  onFocusChange={() => setIsDueDateOpen(!isDueDateOpen)}
                  displayFormat="LL"
                  label={t('invoice.release.select_date')}
                  placeholder={t('tasks.create_form.due_date_placeholder')}
                  size="tiny"
                  touched={!meta.dirtySinceLastSubmit}
                  error={meta.submitError || submitErrors?.release_at}
                  hideNavButtons={false}
                  minDate={moment().format('YYYY-MM-DD')}
                  locale={i18n.language}
                  enablePastDates={true}
                />
              )}
            </Field>
            <Field name="invoiceTime" initialValue={'00:00'}>
              {({ input }) => (
                <TimePickerComponent
                  className="invoice__time-picker"
                  onChange={e => input.onChange(e)}
                  getTime={input.value && input.value.split(':')[1]}
                  value={input.value}
                  locale="sv-sv"
                  disableClock={true}
                  handleTime={e => input.onChange(e)}
                  header={t('invoice.release.select_time')}
                />
              )}
            </Field>
            <div className="invoice__release-date">{t('invoice.release.invoice_date')}</div>
            <div className="invoice__current-date">
              {t('invoice.release.current_date', {
                date: dateMonthYear(invoiceJob?.scheduledFor),
              })}
            </div>
            <Alert
              className="invoice__alert"
              isVisible={true}
              hideClose={true}
              type="warning-medium"
              isIconVisible={true}
              iconSize="small"
            >
              {t('invoice.release.change_date_alert')}
            </Alert>
            <Field name="changeInvoiceDate" type="checkbox">
              {({ input }) => (
                <>
                  <CheckBox
                    className="invoice__checkbox"
                    isChecked={input.checked}
                    size="tiny"
                    label={t('invoice.release.change_date_checkbox')}
                    onChange={e => input.onChange(e.target.checked)}
                  />
                </>
              )}
            </Field>
            {values.changeInvoiceDate && (
              <div className="invoice__changed-date">
                {t('invoice.release.invoice_set_date', {
                  date: dateMonthYear(values.invoiceDate),
                })}
              </div>
            )}

            <div className="invoice__release-button">
              <Button
                className="invoice__reject-button"
                type="success"
                size="small"
                label={t('invoice.release.release_invoices')}
                submitType="submit"
              />
              <Button
                type="default"
                size="small"
                label={t('shared.action.cancel')}
                onClick={() => setEnableSidePanel(false)}
              />
            </div>
          </form>
        )}
      />
      <SubmitModal
        isLoading={isLoading}
        isSuccess={isSuccess}
        requestId={requestId}
        successTitle={t('invoice.release.modal.success_title')}
        successContent={t('invoice.release.modal.success_description')}
        errorTitle={t('invoice.release.modal.error_title')}
        errorContent={t('invoice.release.modal.error_description')}
        showError={true}
        showLoader={true}
      />
    </div>
  );
};

ReleaseInvoiceForm.defaultProps = {
  setEnableSidePanel: false,
};

ReleaseInvoiceForm.propTypes = {
  setEnableSidePanel: PropTypes.bool,
};

export default ReleaseInvoiceForm;
