import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useGetCurrentUserQuery } from 'api/users';
import SidenavComponent from 'components/sidenav/sidenav.component';
import TopnavComponent from 'components/topnav/topnav.component';

const ProtectedRoute = ({ allowedRoles }) => {
  const { data: currentUser, isSuccess: isCurrentUserFetched } = useGetCurrentUserQuery();

  if (isCurrentUserFetched) {
    return allowedRoles.includes(currentUser.role) ? (
      <>
        <TopnavComponent user={currentUser} />
        <SidenavComponent currentUser={currentUser} />
        <Outlet />
      </>
    ) : (
      (window.location.href = '/')
    );
  }
  return null;
};

ProtectedRoute.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProtectedRoute;
