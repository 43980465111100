import { useGetMembershipOrganizationsQuery, useGetOrganizationQuery } from 'api/organizations';
import SelectBox from 'components/base-components/SelectBox';
import { t } from 'i18next';
import React from 'react';
import { Field } from 'react-final-form';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';

const Memberships = () => {
  const { id } = useParams();
  const { data: organization = {} } = useGetOrganizationQuery(id, { skip: !id });
  const { data = [] } = useGetMembershipOrganizationsQuery();
  const membershipOptions = data.map(item => ({ label: item, value: item }));

  return (
    <>
      <div className="organisation__form--font-bold">{t('producer.memberships.title')}</div>
      <div className="organisation__form-sub-label organisation__form-contacts-placeholder">
        {/* TODO: Link will be updated later */}
        <Trans
          i18nKey="producer.memberships.sub_title"
          components={{
            anchor: (
              <a
                href="https://example.com"
                target="_blank"
                rel="noreferrer"
                className="organisation__form-sub-label--color"
              />
            ),
          }}
        />
      </div>
      <div className="organisation__form--margin-top organisation__form-primary-member-input">
        <Field name="membership_organization" initialValue={organization.membershipOrganization}>
          {({ input }) => (
            <SelectBox
              size="tiny"
              width="small"
              label={t('producer.memberships.form.label')}
              placeholderText={t('user_invite.organisation.placeholder')}
              isClearable={false}
              options={membershipOptions}
              value={membershipOptions.find(option => option.value === input.value)}
              selectedValue={input.label}
              onChange={e => input.onChange(e.value)}
            />
          )}
        </Field>
      </div>
    </>
  );
};

export default Memberships;
