import './modal.styles.scss';
import Modal from './Modal';
import Title from './subComponents/Title';
import Content from './subComponents/Content';
import Footer from './subComponents/Footer';
import Feedback from './subComponents/Feedback';

Modal.Title = Title;
Modal.Content = Content;
Modal.Footer = Footer;
Modal.Feedback = Feedback;

export default Modal;
