import React from 'react';
import { useGetOrganizationDeclarationStatusQuery } from 'api/organizations';
import { useGetControlBodyDeclarationsOverviewQuery } from 'api/control-body-declarations';
import { useGetCurrentUserQuery } from 'api/users';
import Icon from 'components/base-components/Icon';
import { SUBMITTED } from 'utils/declaration-status';
import IconButton from 'components/base-components/IconButton';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import WarningModal from '../modal/warning';
import './styles.scss';

const StarsList = ({ starsCount }) => (
  <div className="approval-modal__stars">
    {[1, 2, 3].map(current => (
      <span key={`stars-${current}`}>
        <IconButton
          icon={<Icon name="star" color="success" size="medium" />}
          disabled={starsCount < current}
          isIconOnly={true}
        />
      </span>
    ))}
  </div>
);

const ApprovalConfirmationModal = ({
  organizationId,
  isModalOpen,
  setIsModalOpen,
  onConfirm,
  title,
  content,
  confirmActionText,
  cancelActionText,
}) => {
  const { data: status = {} } = useGetOrganizationDeclarationStatusQuery({
    organizationId,
  });
  const { data: controlBodyOverview = {} } = useGetControlBodyDeclarationsOverviewQuery({
    organizationId,
  });
  const { data: currentUser } = useGetCurrentUserQuery();

  const isControlBodySecondLastToBeApproved =
    controlBodyOverview.status === SUBMITTED &&
    status.newDeclarationsLeft === 2 &&
    isBioSuisseAdmin(currentUser);

  const isControlBodyAlreadyApproved =
    controlBodyOverview.completed && status.newDeclarationsLeft === 1;

  const showConfirmation =
    status?.starsCountChanged &&
    (isControlBodySecondLastToBeApproved || isControlBodyAlreadyApproved);

  const newStarsCount = status.newStarsCount || 0;

  const approvalTitle = () =>
    status.firstDeclaration
      ? t('shared.modal.approve_confirmation.initial_title')
      : t('shared.modal.approve_confirmation.existing_title');

  const approvalContent = () => {
    if (isControlBodySecondLastToBeApproved) {
      return (
        <div>
          <div className="approval-modal__description">
            <Trans
              i18nKey="shared.modal.approve_confirmation.cb_description"
              components={{ bold: <b /> }}
            />
          </div>
          <span>{t('shared.modal.approve_confirmation.confirm_text')}</span>
        </div>
      );
    } else {
      return status.firstDeclaration ? (
        <div>
          <div className="approval-modal__description">
            <Trans
              i18nKey="shared.modal.approve_confirmation.initial_description"
              components={{ bold: <b /> }}
            />
          </div>
          <div className="approval-modal__rating-type approval-modal__rating-type--bold">
            {t('shared.modal.approve_confirmation.new_rating')}
          </div>
          <StarsList starsCount={newStarsCount} />
          <span>{t('shared.modal.approve_confirmation.confirm_text')}</span>
        </div>
      ) : (
        <div>
          <div className="approval-modal__description">
            {t('shared.modal.approve_confirmation.existing_description')}
          </div>
          <div className="approval-modal__rating-container">
            <div>
              <div className="approval-modal__rating-type">
                {t('shared.modal.approve_confirmation.current_rating')}
              </div>
              <StarsList starsCount={status.currentStarsCount} />
            </div>
            <div>
              <div className="approval-modal__rating-type">
                {t('shared.modal.approve_confirmation.new_rating')}
              </div>
              <StarsList starsCount={newStarsCount} />
            </div>
          </div>
          <span>{t('shared.modal.approve_confirmation.confirm_text')}</span>;
        </div>
      );
    }
  };

  return (
    <WarningModal
      onOutsideClick={() => setIsModalOpen(false)}
      isVisible={isModalOpen}
      title={showConfirmation ? approvalTitle() : title}
      content={showConfirmation ? approvalContent() : content}
      confirmActionText={showConfirmation ? t('shared.action.yes') : confirmActionText}
      cancelActionText={showConfirmation ? t('shared.action.cancel') : cancelActionText}
      onCancel={() => setIsModalOpen(false)}
      onConfirm={onConfirm}
    />
  );
};

StarsList.propTypes = {
  starsCount: PropTypes.number.isRequired,
};

ApprovalConfirmationModal.defaultProps = {
  confirmActionText: t('shared.action.okay'),
  cancelActionText: t('shared.action.cancel'),
};

ApprovalConfirmationModal.propTypes = {
  organizationId: PropTypes.number.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  confirmActionText: PropTypes.string,
  cancelActionText: PropTypes.string,
};

export default ApprovalConfirmationModal;
