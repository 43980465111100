import React from 'react';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetOrganizationQuery } from 'api/organizations';
import { useParams } from 'react-router';
import { PRODUCER } from 'utils/organisation-industries';
import GastronomyOverview from './gastronomy-view/gastronomy-overview.component';
import ProducerOverview from './producer-overview.component';

const OverViewComponent = () => {
  const { id } = useParams();
  const { data: organisation, isFetching, isSuccess } = useGetOrganizationQuery(id);
  const isProducer = organisation?.industry === PRODUCER;
  const { data: currentUser } = useGetCurrentUserQuery();
  const isAdmin = isBioSuisseAdmin(currentUser);

  return (
    <>
      {isProducer ? (
        <ProducerOverview organisation={organisation} isFetching={isFetching} isAdmin={isAdmin} />
      ) : (
        <GastronomyOverview
          organisation={organisation}
          isFetching={isFetching}
          isSuccess={isSuccess}
        />
      )}
    </>
  );
};

export default OverViewComponent;
