import { SUCCESS, WARNING } from 'utils/status';
import { PRODUCTION_DATA } from 'utils/organisation-create-options';
import productionDataErrorCondtion from './error-condition/production-data';
import farmlandErrorCondtion from './error-condition/farmland';
import addressFormCondtion from './error-condition/address-form';
import legalFormCondtion from './error-condition/legal-form';

const handleMoveToNextStep = (
  validationError = {},
  activeIndex,
  setActiveIndex,
  setNextStepDisabled,
  setStepStates,
  stepStates,
  department,
  currentTab,
  triggerStepperProductionTabs,
  TAB_MAPPER,
) => {
  const { registeredName, secondSpokenLanguage, controlBody, tvd, tvdStickerNumbers } =
    validationError;
  let steps = [...stepStates];
  steps[activeIndex] = SUCCESS;

  const index = activeIndex + 1;
  const hasValidationErrors = Object.keys(validationError).length !== 0;

  const stepConditions = {
    0: {
      errorStepCondition: registeredName || secondSpokenLanguage,
      moveNextCondition:
        stepStates[0] === SUCCESS ||
        (hasValidationErrors && !registeredName && !secondSpokenLanguage),
    },
    1: legalFormCondtion(validationError, department),
    2: {
      errorStepCondition: addressFormCondtion(validationError),
      moveNextCondition: !addressFormCondtion(validationError),
      disableNext: true,
    },
    3: {
      errorStepCondition: controlBody,
      moveNextCondition: !controlBody,
      disableNext: true,
    },
    4: {
      errorStepCondition: farmlandErrorCondtion(validationError),
      moveNextCondition: !farmlandErrorCondtion(validationError),
    },
    5: {
      errorStepCondition: productionDataErrorCondtion(validationError),
    },
    6: {
      errorStepCondition: tvd || tvdStickerNumbers,
      moveNextCondition: !tvd && !tvdStickerNumbers,
    },
  };

  const step = stepConditions[activeIndex];

  if (step) {
    if (TAB_MAPPER?.[department]?.[activeIndex] === PRODUCTION_DATA && currentTab === 'undefined') {
      if (triggerStepperProductionTabs === 'previous') {
        setActiveIndex(activeIndex - 1);
      } else {
        setActiveIndex(index);
      }
    } else if (step.errorStepCondition) {
      steps[activeIndex] = WARNING;

      if (step.disableNext) {
        setNextStepDisabled(true);
      }
    } else if (
      TAB_MAPPER?.[department]?.[activeIndex] === PRODUCTION_DATA &&
      currentTab !== 'undefined'
    ) {
      return null;
    } else if (step.moveNextCondition) {
      setActiveIndex(index);
    }
  } else {
    setActiveIndex(index);
  }

  setStepStates(steps);
};

export default handleMoveToNextStep;
