import React, { useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ChooseFile from 'components/shared-components/choose-file/ChooseFile';
import Input from '../../base-components/Input';
import DatePicker from '../../base-components/DatePicker';
import Button from '../../base-components/Button';
import { useGetActiveChartaQuery } from '../../../api/chartas';
import ChartaValidity from '../../shared-components/charta-validity/charta-validity.component';

const SupplierCertificateForm = ({ onSave, onCancel, supplierCertificate, organizationId }) => {
  const [enableDatePicker, setEnableDatePicker] = useState(null);
  const { t, i18n } = useTranslation();

  const { data: activeCharta } = useGetActiveChartaQuery({
    organizationId,
  });

  const onSubmit = values => {
    // TODO: Find a good way to handle form

    let formData = new FormData();

    if (values.name) {
      formData.append('name', values.name);
    }

    if (values.certificationIdentifier) {
      formData.append('certification_identifier', values.certificationIdentifier);
    }

    if (values.certificationDate) {
      formData.append('certification_date', values.certificationDate);
    }

    if (values.certificate) {
      formData.append('certificate', values.certificate);
    }

    return onSave(formData)
      .then(() => {
        return null;
      })
      .catch(({ data: { errors } }) => {
        return errors;
      });
  };

  const initialValues = useMemo(() => {
    if (!supplierCertificate) {
      return {};
    }
    return {
      name: supplierCertificate.name,
      certificationIdentifier: supplierCertificate.certificationIdentifier,
      certificationDate: supplierCertificate.certificationDate,
    };
  }, [JSON.stringify(supplierCertificate)]);

  return (
    <div className="supplier-certificates__form">
      <div className="supplier-certificates__form-certificate-for">
        {t('supplier_certificates.placeholder.certificate_for')}
      </div>
      <div className="supplier-certificates__form-validity">
        <ChartaValidity
          validFrom={activeCharta?.validFrom}
          validUntil={activeCharta?.validUntil}
          withValidityOnly={true}
          currentTimePeriodTransKey={'supplier_certificates.time_period.current'}
        />
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={() => {}}
        render={({ handleSubmit, values, form, submitErrors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="supplier-certificates__form--margin-top">
                <Field name="name">
                  {({ input, meta }) => (
                    <Input
                      size="tiny"
                      label={t('supplier_certificates.attributes.supplier_name')}
                      placeholder={t('supplier_certificates.placeholder.supplier_name')}
                      touched={!meta.dirtySinceLastSubmit}
                      error={meta.submitError}
                      required={true}
                      {...input}
                    />
                  )}
                </Field>
              </div>
              <div className="supplier-certificates__form--margin-top">
                <Field name="certificate">
                  {({ input, meta }) => (
                    <ChooseFile
                      fileName={input.value?.name}
                      label={t('supplier_certificates.attributes.certification_document')}
                      onChange={file => {
                        input.onChange(file);
                      }}
                      errorMsg={meta?.submitError}
                      touched={!meta.dirtySinceLastSubmit}
                    />
                  )}
                </Field>
              </div>
              <div className="supplier-certificates__form--margin-top">
                <Field name="certificationIdentifier">
                  {({ input, meta }) => (
                    <Input
                      size="tiny"
                      label={t('supplier_certificates.attributes.certificate_id')}
                      placeholder={t('supplier_certificates.placeholder.certificate_id')}
                      touched={!meta.dirtySinceLastSubmit}
                      error={meta.submitError}
                      required={true}
                      {...input}
                    />
                  )}
                </Field>
              </div>
              <div className="supplier-certificates__form--margin-top">
                <Field name="certificationDate">
                  {({ input, meta }) => (
                    <DatePicker
                      className="user__education--form"
                      focused={enableDatePicker}
                      date={input.value}
                      onChange={e => input.onChange(e)}
                      touched={!meta.dirtySinceLastSubmit}
                      error={meta.submitError}
                      onFocusChange={() => setEnableDatePicker(!enableDatePicker)}
                      displayFormat="LL"
                      label={t('supplier_certificates.attributes.certification_date')}
                      placeholder={t('supplier_certificates.placeholder.certification_date')}
                      navNext="arrowForwardAlt"
                      navPrev="arrowBackAlt"
                      size="small"
                      enablePastDates={true}
                      futureYearsCount={0}
                      disableFutureDates={true}
                      required={true}
                      locale={i18n.language}
                    />
                  )}
                </Field>
                <div className="supplier-certificates__form--margin-top supplier-certificates__form-action">
                  <Button
                    label={t('shared.action.save')}
                    size="small"
                    onClick={handleSubmit}
                    disabled={!(Object.values(values).length > 0)}
                    type="success"
                  />
                  <Button
                    label={t('shared.action.cancel')}
                    size="small"
                    onClick={() => {
                      form.reset();
                      onCancel();
                    }}
                  />
                </div>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

SupplierCertificateForm.defaultProps = {
  supplierCertificate: null,
  organizationId: null,
};

SupplierCertificateForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  supplierCertificate: PropTypes.shape(),
  organizationId: PropTypes.node,
};

export default SupplierCertificateForm;
