import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { suissCountryCode } from 'utils/country-code';
import SelectBox from '../base-components/SelectBox';
import { useGetCountriesQuery } from '../../api/countries';

const CountrySelector = ({ parentKey, initialValue }) => {
  const { t } = useTranslation();
  const {
    data: countries = [],
    isFetching,
    isSuccess: isCountriesFetched,
  } = useGetCountriesQuery();

  const initialCountry =
    isCountriesFetched &&
    countries.find(country => country.code.toLowerCase() === suissCountryCode);

  const initialCountryOptions = isCountriesFetched && {
    label: initialCountry?.name,
    value: initialCountry?.code,
  };

  const initialValueOption = useMemo(() => {
    return initialValue || initialCountryOptions;
  }, [initialValue, isFetching]);

  const countriesOptions = useMemo(
    () => countries.map(country => ({ label: country.name, value: country.code })),
    [JSON.stringify(countries)],
  );

  return (
    <Field name={`${parentKey}.country`} initialValue={initialValueOption}>
      {({ input, meta }) => (
        <SelectBox
          size="tiny"
          width="large"
          className="organisation__form-address-country"
          required={true}
          isClearable={false}
          value={input.value}
          selectedValue={input.label}
          isDisabled={isFetching}
          label={t('shared.label.address.country')}
          placeholderText={t('shared.placeholder.address.country')}
          options={countriesOptions}
          onChange={value => {
            input.onChange(value);
          }}
          errorMsg={meta.submitError}
        />
      )}
    </Field>
  );
};

CountrySelector.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  parentKey: PropTypes.string.isRequired,
  initialValue: PropTypes.shape().isRequired,
};

export default CountrySelector;
