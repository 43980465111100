import React from 'react';
import PropTypes from 'prop-types';
import { Tabs as RTabs } from '../RTabs';

const PillTabs = props => {
  return (
    <div className="pill-tabs">
      <RTabs {...props} />
    </div>
  );
};

PillTabs.defaultProps = {
  items: [],
  useTransitionOnChange: false,
  selectedTabKey: 0,
  onChange: () => {},
};

PillTabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  useTransitionOnChange: PropTypes.bool,
  selectedTabKey: PropTypes.number,
  onChange: PropTypes.func,
};

export default PillTabs;
