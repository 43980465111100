import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '../Tooltip/index';
import Icon from '../Icon/index';
import IconButton from '../IconButton/index';

const generateClassName = (element, modifier) => {
  if (modifier) return `${element}--${modifier}`;

  return null;
};

const inputHasPreIcon = (size, preIcon) => {
  if (!preIcon) return null;

  return generateClassName('input-with-pre-icon', size);
};

const inputHasPostIcon = (size, postIcon) => {
  if (!postIcon) return null;

  return generateClassName('input-with-post-icon', size);
};

const inputHasClearIcon = (size, isClearable) => {
  if (isClearable && !size) return `input-with-clear-icon`;
  if (isClearable && size) return `input-with-clear-icon--${size}`;
  return null;
};

const inputHasClearIconWithPostErrorHint = (size, postIcon, hint, errorText, isClearable) => {
  if (isClearable && !size && (postIcon || hint || errorText))
    return 'input-with-clear-icon-and-post-icon-error-hint';
  if (isClearable && size && (postIcon || hint || errorText))
    return `input-with-clear-icon-and-post-icon-error-hint--${size}`;
  return null;
};

const inputWithPostErrorHintOnly = (size, postIcon, hint, errorText, isClearable) => {
  if (!isClearable && !size && (postIcon || hint || errorText))
    return 'input-with-post-icon-error-hint';
  if (!isClearable && size && (postIcon || hint || errorText))
    return `input-with-post-icon-error-hint--${size}`;
  return null;
};

const Input = props => {
  const {
    className,
    touched,
    error,
    dark,
    label,
    size,
    hint,
    preIcon,
    disabled,
    postIcon,
    isClearable,
    value,
    onChange,
    onInput,
    onPaste,
    required,
    fullWidth,
    ...other
  } = props;
  const errorText = (touched && error) || undefined;
  const inputRef = useRef(null);

  const clearTextHandler = () => {
    onChange('');
    onInput('');
    inputRef.current.focus();
  };

  return (
    <div
      className={classNames('input', className, {
        'input-dark': props.dark,
        'input-full-width': fullWidth,
      })}
    >
      {label && (
        <div
          className={classNames('input__label', generateClassName('input__label', size), {
            'input__label--disabled': disabled,
            'input__label--required': required,
          })}
        >
          {label && <span>{label}</span>}
        </div>
      )}

      <div
        className={classNames(
          'input__field-holder',
          inputHasPreIcon(size, preIcon),
          inputHasPostIcon(size, postIcon),
          inputHasClearIcon(size, isClearable),
          inputWithPostErrorHintOnly(size, postIcon, hint, errorText, isClearable),
          inputHasClearIconWithPostErrorHint(size, postIcon, hint, errorText, isClearable),
          generateClassName('input__field-holder', size),
          {
            'input--disabled': disabled,
            'input--with-error': errorText,
          },
        )}
      >
        {preIcon && (
          <span
            className={classNames(
              'input__pre-icon',
              {
                'input__pre-icon--disabled': disabled,
              },
              generateClassName('input__pre-icon', size),
            )}
          >
            {preIcon}
          </span>
        )}
        <input
          {...other}
          className={classNames('input__field', generateClassName('input__field', size), {
            'input__field--disabled': disabled,
            'input__field--error': errorText,
          })}
          value={value}
          onChange={event => onChange(event.target?.value)}
          onInput={event => onInput(event.target?.value)}
          onPaste={onPaste}
          ref={inputRef}
          disabled={disabled}
        />
        <span
          className={classNames(
            'input__clear-icon',
            { 'input__clear-icon--visibility': isClearable && value?.length !== 0 },
            {
              [generateClassName('input__clear-icon-right', size)]: postIcon || hint || errorText,
            },
            generateClassName('input__post-icon', size),
          )}
        >
          <IconButton
            icon={<Icon name="close" />}
            version="v2"
            isIconOnly={true}
            onClick={clearTextHandler}
            size={size}
            padding="left"
            color="default"
            disabled={disabled}
          />
        </span>

        {postIcon && (
          <span
            className={classNames(
              'input__post-icon',
              {
                'input__post-icon--disabled': disabled,
              },
              generateClassName('input__post-icon', size),
            )}
          >
            {postIcon}
          </span>
        )}

        {errorText && (
          <div className="input__field-tooltip-icon">
            <Tooltip
              className="input__field-tooltip"
              content={errorText}
              type="danger"
              position="bottom-right"
              gap={0}
            >
              <Icon
                className={classNames(
                  'input__field-tooltip-wrapper',
                  'input__field-tooltip-wrapper--error',
                  generateClassName('input__field-tooltip-wrapper--error', size),
                )}
                name="invalid"
                color="danger"
              />
            </Tooltip>
          </div>
        )}
        {!errorText && hint && (
          <div className="input__field-tooltip-icon">
            <Tooltip
              className="input__field-tooltip"
              content={hint}
              type="accent"
              position="top-right"
              gap={0}
            >
              <Icon
                className={classNames(
                  'input__field-tooltip-wrapper',
                  'input__field-tooltip-wrapper--hint',
                  generateClassName('input__field-tooltip-wrapper--hint', size),
                )}
                name="invalid"
                type="tertiary"
              />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

Input.defaultProps = {
  touched: false,
  className: null,
  error: null,
  dark: false,
  size: 'small',
  hint: null,
  preIcon: null,
  postIcon: null,
  isClearable: true,
  disabled: false,
  label: null,
  required: false,
  value: '',
  onChange: () => {},
  onInput: () => {},
  onPaste: () => {},
  fullWidth: false,
};

Input.propTypes = {
  touched: PropTypes.bool,
  className: PropTypes.string,
  dark: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'tiny', 'normal', 'large', 'huge']),
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  preIcon: PropTypes.node,
  postIcon: PropTypes.node,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  onPaste: PropTypes.func,
  fullWidth: PropTypes.bool,
};

export default Input;
