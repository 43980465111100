import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useGetRecognitionQuery } from 'api/recognition';
import classNames from 'classnames';
import RecognitionAllPDF from 'components/recognition/recognition-all-pdf';

const Anerkennung = ({ organisation, noMargin }) => {
  const { data: certificate } = useGetRecognitionQuery(
    {
      recognitionId: organisation?.lastRecognitionId,
    },
    { skip: !organisation?.lastRecognitionId },
  );

  return (
    <div
      className={classNames('organisation-overview__content', {
        'organisation-overview__content--margin-none': noMargin,
      })}
    >
      <span className="organisation-overview__content-label">
        {t('organisation_view.overview.attributes.status.anerkennung')}
      </span>
      {organisation?.lastRecognitionId ? (
        <RecognitionAllPDF organisation={organisation} certificate={certificate}>
          <span className="organisation-overview__latest-anerkennung">
            {t('organisation_view.overview.attributes.status.anerkennung_link', {
              bioId: organisation.bioId,
              certificationStatus: organisation.certificationStatus,
            })}
          </span>
        </RecognitionAllPDF>
      ) : (
        <span className="organisation-overview__content-value--disabled">
          {t('common.not_applicable')}
        </span>
      )}
    </div>
  );
};

Anerkennung.defaultProps = {
  noMargin: false,
};

Anerkennung.propTypes = {
  organisation: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  noMargin: PropTypes.bool,
};

export default Anerkennung;
