export const legalFormValues = (values, formData, editMainContact = true) => {
  const mapContractPerson = assignments => {
    const partnerMapper = {
      firstName: 'first_name',
      lastName: 'last_name',
      email: 'email',
      salutation: 'salutation',
      operationManager: 'operation_manager',
    };

    assignments.forEach((assignment, index) => {
      Object.keys(partnerMapper).forEach(key => {
        const apiKey = partnerMapper[key];
        if (apiKey === 'operation_manager') {
          formData.append(`assignments[][roles][][role]`, 'operation_manager');
          formData.append(
            `assignments[][roles][][destroy]`,
            !(values.operationManagers?.includes(assignment.email) || assignment.operationManager),
          );
        } else {
          formData.append(`assignments[][${apiKey}]`, assignment[key]);
        }
      });
      if (editMainContact) {
        formData.append(`assignments[][roles][][role]`, 'main_contact');
        formData.append(
          `assignments[][roles][][destroy]`,
          values.mainContactEmail !== assignment.email,
        );
      }

      if (assignment.partner) {
        formData.append(`assignments[][roles][][role]`, 'partner');
        formData.append(`assignments[][roles][][destroy]`, !!assignment.destroy);
      }

      if (assignment?.userId) {
        formData.append(`assignments[][user_id]`, assignment?.userId);
      }
    });
  };

  if (values.legalForm) {
    formData.append('legal_form', values.legalForm);
  }

  if (values.companyType) {
    formData.append('producer_type', values.companyType);
  }

  if (values.numberOfPartners) {
    formData.append('number_of_partners', values.numberOfPartners);
  }

  const needsToAdd = values.legalFormPartners || [];

  const needsToRemove = values.deletedPartnerIds || [];

  mapContractPerson([...needsToAdd, ...needsToRemove]);
  if (values?.otherPartner) {
    formData.append(`assignments[][first_name]`, values.otherPartner.firstName);
    formData.append(`assignments[][last_name]`, values.otherPartner.lastName);
    formData.append(`assignments[][email]`, values.otherPartner.email);
    formData.append(`assignments[][salutation]`, values.otherPartner.salutation);
    formData.append(`assignments[][roles][][role]`, 'main_contact');
    formData.append(`assignments[][roles][][destroy]`, !!values.otherPartner.destroy);

    if (values?.otherPartner?.userId) {
      formData.append(`assignments[][user_id]`, values?.otherPartner?.userId);
    }
  }
};
