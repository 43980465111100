import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SideNav = props => {
  const { children, className } = props;

  return <div className={classNames('side-nav', className)}>{children}</div>;
};

SideNav.defaultProps = {
  className: null
};

SideNav.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default SideNav;
