import { useGetLocalUnitQuery, useUpdateLocalUnitMutation } from 'api/producers/local-unit';
import React from 'react';
import { useParams } from 'react-router';
import TVDFormComponent from '../index.component';

const TVDEditComponent = () => {
  const [updateTvd] = useUpdateLocalUnitMutation();
  const { localUnitId } = useParams();

  const { data: localUnitData } = useGetLocalUnitQuery(
    {
      localUnitId: localUnitId,
    },
    { skip: !localUnitId },
  );

  const onEdit = formData => {
    return updateTvd({
      localUnitId: localUnitId,
      payload: formData,
    });
  };

  return (
    <TVDFormComponent onSave={onEdit} localUnitId={localUnitId} localUnitData={localUnitData} />
  );
};

export default TVDEditComponent;
