import React, { useState } from 'react';
import { t } from 'i18next';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-final-form';
import { useGetOrganizationsQuery } from '../../api/organizations';
import CheckboxFilter from './Filters/CheckboxFilter';

const OrganizationFilter = () => {
  const form = useForm();
  const [searchInput, setSearchInput] = useState('');
  const organizationParams = {
    q: searchInput,
  };

  const { data } = useGetOrganizationsQuery(organizationParams);
  const organizations = data ? data.organizations : [];

  const organizationOptions = organizations?.map(organization => ({
    label: organization.name,
    key: organization.id,
  }));

  const [searchParams] = useSearchParams();
  const organizationIds = searchParams.getAll('organizationIds');
  const selectedOrganizationIds = organizationIds.map(id => parseInt(id));

  return (
    <CheckboxFilter
      title={t('organisation.filter.title.organization')}
      fieldName="organizationIds"
      checkboxList={organizationOptions}
      form={form}
      selectedOptions={selectedOrganizationIds}
      showBadge={true}
      isScrollable={true}
      showSearchInput={true}
      setSearchInput={setSearchInput}
      searchInput={searchInput}
    />
  );
};

export default OrganizationFilter;
