import routes from 'utils/routes';
import { apiSlice } from '../index';

const sessionApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    verifyPin: build.mutation({
      query: payload => ({
        url: routes.verifyPin(),
        method: 'PUT',
        body: payload,
      }),
    }),
    logIn: build.mutation({
      query: payload => ({
        url: routes.currentUser.session(),
        method: 'PUT',
        body: payload,
      }),
    }),
    logOut: build.mutation({
      query: () => ({
        url: routes.currentUser.session(),
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useVerifyPinMutation, useLogInMutation, useLogOutMutation } = sessionApi;
