import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSubmitEducationDeclarationsMutation } from 'api/educations-asignee';
import Button from 'components/base-components/Button';
import { useTranslation } from 'react-i18next';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import useCustomNavigate from 'hooks/useCustomNavigate';

const SubmitDeclarations = ({ educationAssigneeId, isDisabled, organizationId }) => {
  const { t } = useTranslation();
  const [submitDeclarations, { isSuccess, requestId }] = useSubmitEducationDeclarationsMutation();
  const navigate = useCustomNavigate();

  const onSubmit = () => submitDeclarations({ educationAssigneeId });
  return (
    <Fragment>
      <SubmitModal
        isSuccess={isSuccess}
        requestId={requestId}
        successTitle={t('educations.modal.declarations.success.title')}
        successContent={t('educations.modal.declarations.success.body')}
        showError={false}
        showLoader={false}
        onSuccess={() => navigate.openOrganisationDetailsPage(organizationId)}
      />
      <Button
        label={t('educations.declarations.action.declare_module')}
        size="small"
        onClick={onSubmit}
        type="success"
        disabled={isDisabled}
      />
    </Fragment>
  );
};

SubmitDeclarations.defaultProps = {
  isDisabled: false,
};

SubmitDeclarations.propTypes = {
  educationAssigneeId: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  organizationId: PropTypes.number.isRequired,
};

export default SubmitDeclarations;
