import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { useApproveGoodsMutation } from 'api/goods-declarations';
import ApprovalConfirmationModal from 'components/shared-components/ApprovalModal/approval-confirmation-modal.component';
import Button from 'components/base-components/Button';
import { currentMonthNameWithYear } from 'utils/month-year-formatter';

const ApproveGoods = ({ periodFrom, periodUntil, disabled }) => {
  const { t } = useTranslation();
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [approveGoods] = useApproveGoodsMutation();
  const { id } = useParams();

  const onApprovingGoodsDeclaration = () => approveGoods({ organizationId: id });

  return (
    <Fragment>
      <ApprovalConfirmationModal
        organizationId={id}
        isModalOpen={isApproveModalOpen}
        setIsModalOpen={setIsApproveModalOpen}
        onConfirm={() => {
          onApprovingGoodsDeclaration();
          setIsApproveModalOpen(false);
        }}
        title={t('goods.modal.approve_confirmation.title')}
        content={
          <Trans
            i18nKey="goods.modal.approve_confirmation.content"
            values={{
              start: currentMonthNameWithYear(periodFrom),
              end: currentMonthNameWithYear(periodUntil),
            }}
          />
        }
        confirmActionText={t('goods.approve_goods')}
        cancelActionText={t('shared.action.cancel')}
      />
      <Button
        label={t('goods.approve_goods')}
        disabled={disabled}
        type="success"
        size="small"
        onClick={() => setIsApproveModalOpen(true)}
      />
    </Fragment>
  );
};

ApproveGoods.propTypes = {
  periodFrom: PropTypes.string.isRequired,
  periodUntil: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ApproveGoods;
