import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import TvdSample from 'assets/tvd_sample.png';
import { ListItem } from 'components/pdf/shared/shared-components';
import tvdStyles from 'components/pdf/styles/tvd-styles';
import { t } from 'i18next';

const VkContent = () => {
  const listItemProps = {
    listStyle: 'largeSquare',
    style: tvdStyles.listPadding,
    prefixStyle: { paddingTop: 2 },
    textStyle: { letterSpacing: -0.1 },
  };

  return (
    <>
      <View style={{ ...tvdStyles.flex, ...tvdStyles.marginTop10 }}>
        <Image src={TvdSample} />
        <View style={tvdStyles.stickerText}>
          <Text>{t('pdf.tvd.second.sticker_text1')}</Text>
          <Text style={tvdStyles.marginTop20}>{t('pdf.tvd.second.sticker_text2')}</Text>
        </View>
      </View>
      <Text style={{ ...tvdStyles.marginTop30, ...tvdStyles.textBold }}>
        {t('pdf.tvd.second.line2_title')}
      </Text>
      <Text style={{ ...tvdStyles.marginTop10, ...tvdStyles.textBold }}>
        {t('pdf.tvd.second.line3_title')}
      </Text>
      <ListItem {...listItemProps}>{t('pdf.tvd.second.line3_description.list1')}</ListItem>
      <ListItem {...listItemProps}>{t('pdf.tvd.second.line3_description.list2')}</ListItem>
      <ListItem {...listItemProps}>{t('pdf.tvd.second.line3_description.list3')}</ListItem>
      <ListItem {...listItemProps}>{t('pdf.tvd.second.line3_description.list4')}</ListItem>
      <ListItem {...listItemProps}>{t('pdf.tvd.second.line3_description.list5')}</ListItem>s
    </>
  );
};

export default VkContent;
