const firstOccur = (...params) => {
  const [iconList, low, high, charToSearch] = params;

  if (high >= low) {
    const mid = Math.floor(low + (high - low) / 2);

    if (
      (mid === 0 || charToSearch > iconList[mid - 1][0].charCodeAt(0)) &&
      iconList[mid][0].charCodeAt(0) === charToSearch
    ) {
      return mid;
    }

    if (charToSearch > iconList[mid][0].charCodeAt(0)) {
      return firstOccur(iconList, mid + 1, high, charToSearch);
    }

    return firstOccur(iconList, low, mid - 1, charToSearch);
  }

  return -1;
};

const lastOccur = (...params) => {
  const [iconList, low, high, charToSearch] = params;

  if (high >= low) {
    const mid = Math.floor(low + (high - low) / 2);

    if (
      (mid === iconList.length - 1 || charToSearch < iconList[mid + 1][0].charCodeAt(0)) &&
      iconList[mid][0].charCodeAt(0) === charToSearch
    ) {
      return mid;
    }

    if (charToSearch < iconList[mid][0].charCodeAt(0)) {
      return lastOccur(iconList, low, mid - 1, charToSearch);
    }

    return lastOccur(iconList, mid + 1, high, charToSearch);
  }

  return -1;
};

const getErrorMessage = (list, char) => {
  const totalIcons = list.length;
  const charToSearch = char;
  const iconList = list;
  const startIndex = firstOccur(iconList, 0, totalIcons - 1, charToSearch, totalIcons);
  let suggestedIcons = '';
  let errorMessage = 'The icon name you provided is invalid.';

  if (startIndex !== -1) {
    const endIndex = lastOccur(iconList, 0, totalIcons - 1, charToSearch, totalIcons);

    for (let i = startIndex; i <= endIndex; i += 1) {
      suggestedIcons = `${suggestedIcons}${iconList[i]}\n`;
    }

    errorMessage = `${errorMessage} Did you mean any of the following icon names?\n---------------------\n${suggestedIcons}`;
  }

  return errorMessage;
};

export default getErrorMessage;
