import UsersList from 'components/users/list/index.component';
import { useParams } from 'react-router';

const UserComponent = () => {
  const { id } = useParams();

  return <UsersList isOrganisationView={true} organizationId={parseInt(id, 10)} />;
};

export default UserComponent;
