import { StyleSheet } from '@react-pdf/renderer';
import { FONT_SIZE_10, FONT_WEIGHT_BOLD } from '../../styles/variables';

const producerCoverLetterStyles = StyleSheet.create({
  page: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  headerLogo: {
    height: 116,
    width: '100%',
    objectFit: 'contain',
    marginBottom: 44,
    marginTop: 5,
  },
  body: {
    flexGrow: 1,
    marginHorizontal: 72,
    marginLeft: 50,
    marginRight: 35,
  },
  pdfTitle: {
    fontSize: FONT_SIZE_10,
    fontWeight: FONT_WEIGHT_BOLD,
  },
  link: {
    color: 'blue',
    textDecoration: 'underline',
  },
});

export default producerCoverLetterStyles;
