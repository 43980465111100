import React from 'react';
import { useGetCurrentUserQuery } from 'api/users';
import Card from 'components/base-components/Card';
import EmphasisTag from 'components/base-components/EmphasisTag';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import OrganizationName from 'components/organisations/organization-name';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import UserAvatar from 'components/users/user-avatar.component';
import useNavigationLinks from 'hooks/useNavigationLinks';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { dateMonthYearFormatter } from 'utils/date-time-formatter';
import TaskTitle from 'components/task-management/task-lists/task-title';
import { Link } from 'react-router-dom';

const TaskComponent = ({ taskData, isFetched }) => {
  const { data: currentUser = {} } = useGetCurrentUserQuery();
  const { t } = useTranslation();
  const navigationLinks = useNavigationLinks();

  const emphasisTagType = {
    high: 'danger',
    low: 'disabled',
    medium: 'warning',
  };

  return (
    <div>
      {isFetched && taskData.length > 0 ? (
        taskData.map(task => (
          <Card className="dashboard__tasks-wrapper">
            <div className="dashboard__tasks">
              <TaskTitle task={task} />
              <div>
                {task?.assignedOrganizationId ? (
                  <OrganizationName organizationId={task.assignedOrganizationId} />
                ) : (
                  <UserAvatar userID={currentUser.id} />
                )}
              </div>
            </div>

            <div className="dashboard__tasks-tags">
              <EmphasisTag
                type={emphasisTagType[task?.priority]}
                text={t(`tasks.priority.${task?.priority}`)}
                size="tiny"
              />

              <Link to={navigationLinks.taskDetailsPage(task?.id)}>
                <IconButton icon={<Icon name="show" size="small" />} size="tiny" color="tertiary" />
              </Link>
            </div>

            <div className="dashboard__tasks-dates">
              <div>
                {t('dashboard.created_task', {
                  date: dateMonthYearFormatter(task?.createdAt),
                })}
              </div>
              <div>
                {t('dashboard.due_task', {
                  date: dateMonthYearFormatter(task?.dueDate),
                })}
              </div>
            </div>
          </Card>
        ))
      ) : (
        <EmptyContentPlaceholder
          text={t('tasks.no_task_found')}
          iconName="tasks"
          showCard={false}
        />
      )}
    </div>
  );
};

TaskComponent.defaultProps = {
  taskData: [],
  isFetched: false,
};

TaskComponent.propTypes = {
  isFetched: PropTypes.bool,
  taskData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default TaskComponent;
