import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import styles from 'components/pdf/styles/styles';
import {
  Break,
  InputField,
  ListItem,
  Section,
  SubSection,
} from 'components/pdf/shared/shared-components';

const SignatureForm = () => (
  <View style={styles.signatureForm}>
    <View style={{ width: '100%', marginRight: 10 }}>
      <Text>Bio Suisse</Text>
      <InputField title="Timo Pekgüçer, Leiter Marketing und Kommunikation" />
      <InputField title="Dieter Peltzer, Leiter QS und -entwicklung" />
      <InputField title="Basel, den" />
    </View>
    <View style={{ width: '100%', marginLeft: 10 }}>
      <Text>Markennutzer</Text>
      <InputField title="Rechtsgültige Unterschrift" />
      <InputField title=" " />
      <InputField title="Ort und Datum" />
    </View>
  </View>
);

const PdfContent = () => (
  <>
    <Section title="1. Zweck des Vertrages">
      <Text>
        Der Vertrag regelt die Vergabe und den Gebrauch der eingetragenen Marken „Knospe/Bio
        Cuisine“, „Knospe/Bio Suisse“ sowie die partnerschaftliche Zusammenarbeit zwischen Bio
        Suisse und dem Markennutzer. Beide Vertragspartner streben in enger Zusammenarbeit die
        nachhaltige Entwicklung der biologischen Landwirtschaft, eine hohe Qualität von
        Knospe-Produkten und eine breite Präsenz und Visibilität für die Knospe und die
        Knospe-Produkte an.
      </Text>
    </Section>

    <Section title="2. Vertragsgegenstand">
      <SubSection title="2.1. Marke Knospe/Bio Cuisine">
        <Text style={styles.heading2}></Text>
        <Text style={{ paddingBottom: 6 }}>
          Mit der Unterzeichnung des Markennutzungsvertrages verleiht Bio Suisse dem Markennutzer
          das Recht zu einer eingeschränkten Benutzung der beim Eidgenössischen Institut für
          geistiges Eigentum eingetragenen Marke „Knospe/Bio Cuisine“ mit der Registrierungsnummer
          788732 (Wort- /Bildmarke). Der Gebrauch der Marke „Knospe/Bio Cuisine“ ist im mitgeltenden
          Dokument "CI/CD Manual für Markennutzer Knospe/Bio Cuisine" erläutert und an die
          Einhaltung der in diesem Vertrag genannten Bedingungen gebunden:
        </Text>
        <ListItem>
          Der Markennutzer verpflichtet sich, den Schutz der Konsument:innen vor gesundheitlichen
          Schäden zu garantieren, den hygienischen Umgang mit Lebensmitteln sicherzustellen und die
          KonsumentInnen im Zusammenhang mit Lebensmitteln vor Täuschungen zu bewahren.
        </ListItem>

        <ListItem>
          Die Marke darf nur zur Auslobung von Produkten eingesetzt werden, die nachweislich von
          einem Knospe-Landwirtschaftsbetrieb oder einem Knospe-Lizenznehmer stammen und die auf
          Rechnungen und/oder Lieferscheinen ausdrücklich als Knospe-Produkte bezeichnet sind. Bio
          Suisse Organic-Rohstoffe müssen von einem in der Schweiz registrierten KnospeLizenznehmer
          importiert werden.
        </ListItem>

        <ListItem>
          Die Marke darf am Verkaufspunkt, auf den elektronischen Plattformen des Markennutzers, und
          auf Werbematerialien am Verkaufspunkt und ausserhalb des Verkaufspunktes (Drucksachen,
          Webseite) genutzt werden, sofern ein direkter Zusammenhang zum KnospeProdukt oder
          Knospe-Sortimentes des Markennutzers hergestellt werden kann und keine Täuschung entsteht.
        </ListItem>
        <ListItem>
          Verpackungs- und Werbematerialien inkl. elektronische Medien, auf welchen die Marke
          verwendet werden soll, müssen Bio Suisse vorgängig zur Prüfung und Genehmigung eingereicht
          werden.
        </ListItem>
      </SubSection>

      <SubSection title="2.2. Marke Knospe Bio Suisse">
        <ListItem>
          Mit der Unterzeichnung des Markennutzungsvertrages verleiht Bio Suisse dem Markennutzer
          das Recht zu einer eingeschränkten Benutzung der beim Eidgenössischen Institut für
          geistiges Eigentum eingetragenen Marke „Knospe/Bio Suisse“ (Registrierungsnummer pendent
          per April 2023) (Wort-/Bildmarke).
        </ListItem>
        <ListItem>
          Die Marke kann in Kommunikationsmitteln wie z.B. Speisekarten, auf Herkunftsebene für
          Knospe-Produkte mit Schweizerischer und internationaler Herkunft verwendet werden. Der
          Gebrauch der Marke „Knospe/Bio Suisse“ ist im mitgeltenden Dokument "CI/CD Manual für
          Markennutzer Knospe/Bio Suisse" erläutert.
        </ListItem>
      </SubSection>

      <SubSection title="2.3. Mitgeltende Dokumente" style={{ marginBottom: 0 }}>
        <Text>
          Folgende mitgeltenden Dokumente sind integraler Bestandteil der Vertragsbeziehung:
        </Text>
        <ListItem>
          Richtlinien Bio Suisse Teil III, Kapitel 16 Gastronomie. Ausgabe 1.1.2023 und folgende
        </ListItem>
        <ListItem>Charta für eine nachhaltige Gastronomie</ListItem>
        <ListItem>Jeweils geltende Gebührenordnung Gastronomie</ListItem>
        <ListItem>CI/CD Manual für Markennutzer Knospe/Bio Cuisine</ListItem>
        <ListItem>CI/CD Manual für Markennutzer Knospe/Bio Suisse</ListItem>
        <ListItem>Merkblatt Bildung</ListItem>
        <ListItem>Merkblatt Rollen im Model Bio Cuisine</ListItem>
      </SubSection>
    </Section>

    <Section title="3. Pflichten und Leistungen von Bio Suisse">
      <SubSection title="3.1. Schutz der eingetragenen Marke „Knospe/Bio Suisse“">
        <Text>
          Bio Suisse ist eine unabhängige Non-Profit-Organisation, welche die Interessen der
          Schweizer Knospe-Produzenten, der Lizenznehmer und der Markennutzer vertritt. Sie ist
          Inhaberin der eingetragenen Marke „Knospe/Bio Suisse“ und „Knospe/Bio Cuisine“ und
          verwaltet und schützt deren rechtmässige Verwendung. Verstösse gegen die Bio Suisse
          Richtlinien bzw. missbräuchliche Verwendungen der eingetragenen Marken ahndet Bio Suisse
          mit strengen Sanktionen. Bio Suisse verpflichtet sich zudem, gegen missbräuchliche
          Verwendungen der Marke „Knospe/Bio Suisse“ und „Knospe/Bio Cuisine“ oder den unzulässigen
          Hinweis auf die Bio Suisse Richtlinien sowie gegen unberechtigte Nachahmungen{' '}
        </Text>
      </SubSection>

      <SubSection title="3.2. Information">
        <Text>
          Bio Suisse informiert die Vertragspartner regelmässig über Biolandbau, Verarbeitung,
          Bio-Markt und Qualitätssicherung. Bio Suisse informiert über geeignete Kanäle die
          Vertragspartner und steht für Auskünfte zur Verfügung.
        </Text>
      </SubSection>

      <SubSection title="3.3. Öffentlichkeitsarbeit, Kommunikation und Unterstützung des Bio-Marktes">
        <Text>
          Bio Suisse informiert die Öffentlichkeit regelmässig über die biologische Landwirtschaft
          und die Vorzüge von Knospe-Produkten. Sie setzt sich auf politischer Ebene für den
          Biolandbau ein und betreibt ein aktives und professionelles Marketing für Knospe-Produkte.{' '}
          <Break />
          Bio Suisse pflegt Kontakte zu Verarbeitungs-, Handels- und Einfuhrunternehmen und betreibt
          aktiv Absatzförderung für Knospe-Produkte. <Break />
          Bio Suisse unterstützt aktiv den Aufbau eines Netzwerkes in der Bio-Gastronomie.
        </Text>
      </SubSection>

      <SubSection title="3.4. Werbematerial">
        <Text>
          Über das Bio Suisse-Portal stellt Bio Suisse Werbe- und Kommunikationsmaterial zur
          Verfügung.
        </Text>
      </SubSection>

      <SubSection title="3.5. Bildungsprogramm">
        <Text>
          Bio Suisse stellt den Markennutzern ein umfassendes Bildungsprogramm zur Verfügung. Die
          Kurse sind grundsätzlich öffentlich und kostenpflichtig. Für gewisse praktische Module
          wird die Eignung der Teilnehmenden geprüft. <Break />
          Für den oder die Beauftragten Bio Cuisine ist ein Modul pro Jahr Pflicht; das Angebot ist
          aber für alle Mitarbeitenden eines Vertragspartners offen. <Break />
          Es gelten die Ausführungen im mitgeltenden Dokument Merkblatt Bildung.
        </Text>
      </SubSection>

      <SubSection title="3.6. Bio Suisse-Portal">
        <Text>
          Bio Suisse stellt den Markennutzern von Bio Cuisine eine Kommunikationsplattform zur
          Verfügung, über die alle relevanten Informationen geteilt werden können. Insbesondere sind
          die Vertragsdokumente dort abgelegt und können digital signiert werden (vgl. Ziff. 8
          dieses Vertrages). Ebenfalls erfolgen die verlangten Deklarationen über die Plattform
          sowie das Bereitstellen von Werbematerial und weiteren relevanten und nützlichen
          Informationen.
        </Text>
      </SubSection>

      <SubSection title="3.7. Datenschutz" style={{ marginBottom: 0 }}>
        <Text>
          Bio Suisse bearbeitet alle Daten des Markennutzers entsprechend dem jeweilig geltenden
          Schweizer Datenschutzgesetz. Bio Suisse verpflichtet ihre Mitarbeitenden zur strengsten
          Verschwiegenheit bezüglich aller im Zusammenhang mit dem Markennutzungsvertrag
          eingeforderten Daten. Sollte der Markennutzer der Bio Suisse Daten Dritter bekanntgeben,
          so ist der Markennutzer verpflichtet die Dritten über die Bekanntgabe an Bio Suisse zu
          informieren.
        </Text>
      </SubSection>
    </Section>

    <Section title="4. Pflichten des Markennutzers">
      <SubSection title="4.1 Einhaltung der Bio Suisse Vorgaben">
        <Text>
          Der Markennutzer verpflichtet sich zur Einhaltung der Bio Suisse Vorgaben sowie der
          jeweils anwendbaren gesetzlichen Bestimmungen. Er verpflichtet sich zur Bezahlung der in
          der jeweils geltenden "Gebührenordnung Gastronomie" festgelegten Markennutzungsgebühren.
        </Text>
      </SubSection>
      <SubSection title="4.2 Meldung von Beanstandungen">
        <Text>
          Stellt der Markennutzer bei seinen Lieferanten eine Übertretung der Bio Suisse Richtlinien
          fest (Beanstandung oder Information durch Dritte oder direkt aus seinem Betrieb) oder hat
          er schwerwiegende Zweifel an der Knospe-Konformität der gelieferten Produkte, so ist er
          verpflichtet, sofort Meldung an Bio Suisse und an die Zertifizierungsstelle des
          Lieferanten zu machen. Meldepflichtig sind insbesondere Rückstandsfunde von im Biolandbau
          unerlaubten Mitteln und betrügerische Aktivitäten von Lieferanten oder Abnehmern in der
          Warenflusskette von KnospeProdukten.
        </Text>
      </SubSection>
      <SubSection title="4.3 Verwendung der Marke „Knospe/Bio Cuisine“">
        <Text>
          Der Markennutzer verpflichtet sich zur korrekten Verwendung der eingetragenen Marke
          „Knospe/Bio Cuisine“ gemäss Corporate Design Bio Cuisine.{' '}
        </Text>
      </SubSection>
      <SubSection title="4.4 Verwendung der Marke „Knospe/Bio Suisse“">
        <Text>
          Der Markennutzer verpflichtet sich zur korrekten Verwendung der eingetragenen Marke
          „Knospe/Bio Suisse“ gemäss Corporate Design Bio Suisse.{' '}
        </Text>
      </SubSection>
      <SubSection
        title="4.5 Geschäftspolitik bezüglich Knospe-Produkten"
        style={{ marginBottom: 0 }}
      >
        <Text>
          Der Markennutzer bekennt sich ausdrücklich zur Förderung der biologischen Landwirtschaft
          in der Schweiz und trägt die hohe Qualität der Knospe-Produkte mit. Der Markennutzer
          strebt eine kontinuierliche Umsatzausdehnung mit Knospe-Produkten an. Der Markennutzer
          setzt sich für eine faire und gerechte Preisgestaltung bei Knospe-Produkten ein, die sich
          langfristig an den Marktgegebenheiten, an den Produktionskosten sowie an den
          Konsumentenanliegen orientiert. Er informiert Bio Suisse und von ihr beauftragte
          Organisationen unter Zusicherung von absoluter Vertraulichkeit auf Wunsch über abgesetzte
          Mengen. Der Markennutzer akzeptiert das Bio Suisse-Konzept der fairen Handelsbeziehungen
          insbesondere der Gesprächsrunden und der Ombudsstelle. Er informiert seine Kunden über die
          Vorzüge von KnospeProdukten und gestaltet das positive Image der Knospe wesentlich mit.
        </Text>
      </SubSection>
    </Section>

    <Section title="5. Vertragsverletzungen">
      <Text>
        Bio Suisse behält sich für den Verdachtsfall das Recht vor, beim Markennutzer den Einsatz
        der Marken „Knospe/Bio Suisse“ und „Knospe/Bio Cuisine“ zu kontrollieren oder die Einhaltung
        der staatlichen Gesetzgebungen durch eine anerkannte Stelle überprüfen zu lassen. Eine
        Verletzung des Markennutzungsvertrages, insbesondere eine Verletzung der Vorgaben, die
        missbräuchliche Verwendung der Marke „Knospe/Bio Suisse“ und „Knospe/Bio Cuisine“, die
        Nichteinhaltung der "Gebührenordnung Gastronomie" oder das Verschweigen meldepflichtiger
        Informationen können die Rückerstattung eines zu Unrecht erzielten Erlöses für
        Knospe-Produkte an Bio Suisse oder die fristlose Auflösung des Markennutzungsvertrages sowie
        die Bezahlung einer Konventionalstrafe in Höhe von maximal CHF 20'000 zur Folge haben. Die
        Geltendmachung weiterer Schadenersatzforderungen bleibt vorbehalten. Mit der Auflösung des
        Markennutzungsvertrages erlischt das Recht zur Benützung der eingetragenen Marken
        „Knospe/Bio Suisse“ und „Knospe/Bio Cuisine“.
      </Text>
    </Section>

    <Section title="6. Vertragsänderungen">
      <Text>
        Vertragsänderungen oder Änderungen der Gebühren gemäss der "Gebührenordnung Gastronomie"
        teilt Bio Suisse bis jeweils spätestens am 20. Juni mit. Die neuen Gebühren treten auf
        Anfang des Folgejahres in Kraft.
      </Text>
    </Section>

    <Section title="7. Vertragsdauer, Vertragsauflösung und Kündigungsfristen">
      <Text>
        Der vorliegende Vertrag ist unbefristet, er kann jedoch mit eingeschriebenem Brief mit einer
        Frist von 6 (sechs) Monaten auf das Ende eines Kalenderjahres gekündigt werden. Im Falle
        einer ordentlichen Kündigung durch einen der Vertragspartner darf der Markennutzer bis zum
        Ablauf der Kündigungsfrist die Marke "Knospe/Bio Cuisine" und "Knospe/Bio Suisse" nutzen. Im
        Falle einer ausserordentlichen Kündigung seitens Bio Suisse aufgrund von schwerwiegenden
        Verletzungen durch den Markennutzer dürfen beide Marken ab Kündigungsdatum nicht mehr
        benutzt werden. <Break />
        Der Vertrag wird automatisch sistiert, wenn in zwei aufeinanderfolgenden Quartalen die
        Bedingungen für die Nutzung der Marke Bio Cuisine nicht mehr erfüllt sind. Die Sistierung
        betrifft die Marke "Knospe/Bio Cuisine" und die Marke "Knospe/Bio Suisse" gleichermassen.
        Grund für diese Sistierung können die Nichteinhaltung des Mindest-Bio-Anteils sein oder die
        Nichteinhaltung von Bedingungen für die Legitimierung.
        <Break />
        Der Vertrag lebt wieder auf, wenn alle Bedingungen von neuem erfüllt sind, und keine
        schwerwiegende Vertragsverletzung im oben genannten Sinne vorliegt.
      </Text>
    </Section>

    <Section title="8. Schrifterfordernis, Anforderung an die Dokumentation, salvatorische Klausel">
      <Text>
        Es gilt ausschliesslich diese Vereinbarung nebst der unter Ziff. 2.3 aufgeführten
        mitgeltenden Dokumente. Änderungen und Ergänzungen dieses Vertrages und dessen
        Vertragsbestandteile, einschliesslich der Aufhebung der Schriftformerfordernis sind nur
        gültig, wenn sie von den Parteien schriftlich vereinbart werden. Nebenabreden bestehen
        nicht. Ergänzende, abweichende oder entgegenstehende Vereinbarungen, Geschäftsbedingungen
        oder sonstige Vorgaben werden nur dann Vertragsbestandteil, sofern die Vertragsparteien
        ihrer Geltung ausdrücklich zugestimmt haben und sie als Anlage oder Nachtrag bzw.
        Vertragsergänzung zu diesem Vertrag genommen haben.
        <Break count={2} />
        Der eigenhändigen Unterschrift gleichgestellt ist die mit einem qualifizierten Zeitstempel
        verbundene qualifizierte elektronische Unterschrift gemäss Bundesgesetz über die
        elektronische Signatur. Diese erfüllt vorliegend das vereinbarte Schriftformerfordernis
        dieses Vertrages.
        <Break count={2} />
        Sollte eine Bestimmung oder ein Teil einer Bestimmung dieses Vertrages unwirksam sein oder
        werden, so wird die Gültigkeit dieses Vertrages im Übrigen nicht berührt. Anstelle der
        unwirksamen Bestimmung soll eine angemessene Regelung gelten, die dem am nächsten kommt, was
        die Parteien gewollt hätten, wenn sie bei Abschluss dieses Vertrages den Punkt bedacht
        hätten. Entsprechendes gilt für die Ausfüllung von Vertragslücken.
      </Text>
    </Section>

    <Section title="9. Gerichtsstand und anwendbares Recht">
      <Text>
        Gerichtsstand für Streitigkeiten aus diesem Vertrag ist das Domizil von Bio Suisse (Basel).
        Anwendbar ist schweizerisches Recht unter Ausschluss der Kollisionsnormen.
      </Text>
    </Section>

    <SignatureForm />
  </>
);

export default PdfContent;
