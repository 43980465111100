import { Document, Page, View } from '@react-pdf/renderer';
import PdfFooter from 'components/pdf/footer/pdf-footer.component';
import PDFHeader from 'components/pdf/header/pdf-header.component';
import styles from 'components/pdf/styles/checklist-styles';
import PropTypes from 'prop-types';
import React from 'react';
import { twelveHourTimeFormat } from 'utils/date-time-formatter';
import AccountingProcess from './content/accounting-process';
import AudioResponse from './content/audio-response';
import ContractualStatus from './content/contractual-status';
import DeclarationStatus from './content/declaration-status';
import Header from './content/header';
import IngredientsInStock from './content/ingredients-in-stock';
import LogoAttachments from './content/logo-attachments';
import RandomGoods from './content/random-goods';
import RestrictedIngredients from './content/restricted-ingredients';

const CheckListPDFDocument = ({
  survey,
  reportType,
  organization,
  schedule,
  comments,
  logoAttachmentsData,
  ingredientsInStockData,
  restrictedIngredientsData,
  declarationDeviations,
  goodsDeclarationEvaluation,
  randomGoodsDatas,
  requiredDeliveryNotesCount,
  contractualStatus,
  accountingProcess,
  updatedAccountingTask,
  audioResponses,
  logoAttachmentPhotos,
  ingredientsLogoAttachmentPhotos,
  restrictedIngredientsAttachmentPhotos,
  followUpLogoAttachmentsPhotos,
  activeLogosFromAuditor,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PDFHeader />
        <View style={styles.checkListBody}>
          <Header
            organizationName={organization?.name}
            date={schedule?.startsAt}
            time={schedule?.startsAt && twelveHourTimeFormat(schedule?.startsAt)}
          />
          <LogoAttachments
            data={logoAttachmentsData}
            comments={comments}
            reportType={reportType}
            logoAttachmentPhotos={logoAttachmentPhotos}
            followUpLogoAttachmentsPhotos={followUpLogoAttachmentsPhotos}
            activeLogosFromAuditor={activeLogosFromAuditor}
          />
          <IngredientsInStock
            data={ingredientsInStockData}
            reportType={reportType}
            ingredientsLogoAttachmentPhotos={ingredientsLogoAttachmentPhotos}
            comments={comments}
          />
          <RestrictedIngredients
            data={restrictedIngredientsData}
            reportType={reportType}
            restrictedIngredientsAttachmentPhotos={restrictedIngredientsAttachmentPhotos}
            comments={comments}
          />
          <DeclarationStatus
            declarationDeviations={declarationDeviations}
            comments={comments}
            deviationAcknowledgement={survey?.deviationAcknowledgement}
            deadlinesMissedComment={goodsDeclarationEvaluation?.deadlinesMissedComment}
            monthlyVariationsComment={goodsDeclarationEvaluation?.monthlyVariationsComment}
            valueOfGoodsComment={goodsDeclarationEvaluation?.goodsDeclarationsValueComment}
          />
          <RandomGoods
            goodsDeclarationEvaluation={goodsDeclarationEvaluation}
            randomGoodsDatas={randomGoodsDatas}
            requiredDeliveryNotesCount={requiredDeliveryNotesCount}
            comments={comments}
            reportType={reportType}
          />
          <ContractualStatus
            contractAcknowledgementStatus={survey?.contractAcknowledgementStatus}
            contractualStatus={contractualStatus}
            comments={comments}
          />
          <AccountingProcess
            accountingProcess={accountingProcess}
            comments={comments}
            updatedAccountingTask={updatedAccountingTask}
            reportType={reportType}
          />
          <AudioResponse
            audioResponses={audioResponses}
            comments={comments}
            reportType={reportType}
          />
        </View>
        <PdfFooter />
      </Page>
    </Document>
  );
};

CheckListPDFDocument.defaultProps = {
  updatedAccountingTask: null,
};

CheckListPDFDocument.propTypes = {
  survey: PropTypes.shape().isRequired,
  reportType: PropTypes.string.isRequired,
  schedule: PropTypes.shape().isRequired,
  comments: PropTypes.shape().isRequired,
  organization: PropTypes.shape().isRequired,
  logoAttachmentsData: PropTypes.array.isRequired,
  ingredientsInStockData: PropTypes.array.isRequired,
  restrictedIngredientsData: PropTypes.array.isRequired,
  declarationDeviations: PropTypes.shape().isRequired,
  goodsDeclarationEvaluation: PropTypes.shape().isRequired,
  randomGoodsDatas: PropTypes.array.isRequired,
  requiredDeliveryNotesCount: PropTypes.func.isRequired,
  contractualStatus: PropTypes.shape().isRequired,
  accountingProcess: PropTypes.shape().isRequired,
  updatedAccountingTask: PropTypes.shape(),
  audioResponses: PropTypes.array.isRequired,
  logoAttachmentPhotos: PropTypes.array.isRequired,
  ingredientsLogoAttachmentPhotos: PropTypes.array.isRequired,
  restrictedIngredientsAttachmentPhotos: PropTypes.array.isRequired,
  followUpLogoAttachmentsPhotos: PropTypes.array.isRequired,
  activeLogosFromAuditor: PropTypes.array.isRequired,
};

export default CheckListPDFDocument;
