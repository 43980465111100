import PropTypes from 'prop-types';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import Button from 'components/base-components/Button';
import { useGetUserQuery } from 'api/users';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton/IconButton';
import Card from 'components/base-components/Card';
import UserEditForm from './user-edit-form.component';

const ContractPartnerList = ({
  partner,
  name,
  status,
  validationError,
  values,
  onRemove,
  onEdit,
  index,
  changeOperationManagerOnly,
}) => {
  const [editUser, setEditUser] = useState(false);

  const { data: user = {}, isSuccess } = useGetUserQuery(
    { id: values.userId },
    { skip: !values.userId },
  );

  useEffect(() => {
    if (isSuccess) {
      onEdit({
        firstName: user.firstName,
        lastName: user.lastName,
        salutation: user.salutation,
        email: user.email,
      });
    }
  }, [isSuccess]);

  if (!changeOperationManagerOnly) {
    return (
      <Card className="legal-form__card" dataTestId="legal-form-partner">
        {partner !== null && (
          <div className="legal-form__card--header">
            {status === 'other'
              ? t('shared.other')
              : t('legal_form.input.header', {
                  partner: partner + 1,
                })}
          </div>
        )}
        <UserEditForm user={user} validationError={validationError} name={name} />
      </Card>
    );
  }

  const userData = (
    <div className="baseline-with-space-between" data-testid="operation-manager">
      <span>
        <span className="boldest-font margin-right-8" data-testid="name">
          {[values.firstName, values.lastName].join(' ')}
        </span>
        <span>{values.email}</span>
      </span>
      <span>
        <IconButton
          icon={<Icon name="edit" />}
          color="tertiary"
          size="tiny"
          onClick={() => setEditUser(true)}
        />
        <IconButton icon={<Icon name="delete" />} color="danger" size="tiny" onClick={onRemove} />
      </span>
    </div>
  );

  if (editUser) {
    return (
      <Card className="legal-form__card">
        <UserEditForm user={user} validationError={validationError} name={`modifyUser-${index}`} />
        <div className="margin-top-20">
          <Button
            className="margin-right-8"
            type="success"
            label={t('shared.action.update')}
            size="small"
            onClick={() => onEdit().then(() => setEditUser(false))}
          />
          <Button
            label={t('shared.action.cancel')}
            size="small"
            onClick={() => setEditUser(false)}
          />
        </div>
      </Card>
    );
  }

  return (
    <Card className="legal-form__card" data-testid="legal-form-partner">
      {partner !== null && (
        <div className="legal-form__card--header">
          {status === 'other'
            ? t('shared.other')
            : t('legal_form.input.header', {
                partner: partner + 1,
              })}
        </div>
      )}
      {editUser ? <UserEditForm user={user} validationError={validationError} /> : userData}
    </Card>
  );
};

ContractPartnerList.defaultProps = {
  partner: null,
  name: null,
  status: null,
  validationError: {},
  onEdit: () => {},
  changeOperationManagerOnly: false,
  onRemove: () => {},
};

ContractPartnerList.propTypes = {
  partner: PropTypes.number,
  name: PropTypes.string,
  status: PropTypes.string,
  validationError: PropTypes.shape(),
  onEdit: PropTypes.func,
  changeOperationManagerOnly: PropTypes.bool.isRequired,
  values: PropTypes.shape().isRequired,
  onRemove: PropTypes.func,
  index: PropTypes.number.isRequired,
};

export default ContractPartnerList;
