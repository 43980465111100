import { t } from 'i18next';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormState, useField } from 'react-final-form';
import classNames from 'classnames';
import RadioWithoutCheck from 'components/base-components/RadioWithoutCheck';
import { useGetUserQuery } from 'api/users';
import EmptyLegalForm from './shared/empty-legal-form.component';
import ContractPartnerList from './shared/contract-partner-list.component';

const OTHER = 'other';

const ContactPersons = ({ selectedNumberOfPartners, mainContactId, validationError }) => {
  const otherpartnerForm = useField('otherPartner');

  const { data: mainContact = {} } = useGetUserQuery(
    { id: mainContactId },
    { skip: !mainContactId },
  );

  const { values } = useFormState();

  const contractPersonEmails = (values.legalFormPartners || []).map(partner => partner.email);
  const externalContact = !contractPersonEmails.includes(mainContact.email);
  let initialValue;

  if (mainContact.email) {
    initialValue = externalContact ? OTHER : mainContact.email;
  }

  useEffect(() => {
    if (initialValue === OTHER) {
      otherpartnerForm.input.onChange({ userId: mainContactId });
    }
  }, [initialValue]);

  if (!selectedNumberOfPartners) {
    return (
      <div className="margin-top-20">
        <EmptyLegalForm
          title={t('legal_form.contract_person.title')}
          description={t('legal_form.contract_person.description')}
        />
      </div>
    );
  }

  return (
    <>
      <div className="legal-form__contract--title">
        <span>{t('legal_form.contract_person.title')}</span>
        <span>*</span>
      </div>
      <div className="legal-form__contract--description">
        {t('legal_form.contract_person.description')}
      </div>
      <div className="legal-form__radio-button">
        {[...contractPersonEmails, OTHER].map((email, index) => (
          <div
            className={classNames('legal-form__radio-button--item', {
              'legal-form__radio-button--second-row': index > 2,
            })}
          >
            <Field name="mainContactEmail" type="radio" value={email} initialValue={initialValue}>
              {({ input }) => (
                <RadioWithoutCheck
                  isChecked={input.checked}
                  name={email}
                  label={
                    email === OTHER
                      ? t('shared.other')
                      : t('legal_form.input.header', {
                          partner: index + 1,
                        })
                  }
                  onChange={e => {
                    if (email === OTHER) {
                      otherpartnerForm.input.onChange({ userId: null });
                    } else {
                      const updatedContact = values.otherPartner?.userId
                        ? { ...values.otherPartner, destroy: true }
                        : null;
                      otherpartnerForm.input.onChange(updatedContact);
                    }
                    input.onChange(email);
                  }}
                  size="medium"
                  disabled={!email}
                />
              )}
            </Field>
          </div>
        ))}
      </div>
      {validationError?.mainContact && (
        <div className="legal-form__required-field">
          {t('legal_form.contract_person.error_text')}
        </div>
      )}
      {values.mainContactEmail === OTHER && (
        <div className="col-12 col-bleed">
          <ContractPartnerList
            name="otherPartner"
            status="other"
            validationError={validationError}
            values={otherpartnerForm.input.value}
          />
        </div>
      )}
    </>
  );
};

ContactPersons.defaultProps = {
  selectedNumberOfPartners: null,
  contractUsers: [],
  validationError: {},
  mainContactId: null,
};

ContactPersons.propTypes = {
  selectedNumberOfPartners: PropTypes.number,
  contractUsers: PropTypes.arrayOf(PropTypes.shape()),
  validationError: PropTypes.shape(),
  mainContactId: PropTypes.number,
};

export default ContactPersons;
