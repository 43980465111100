import { useApproveEducationDeclarationsMutation } from 'api/educations-asignee';
import Button from 'components/base-components/Button';
import ApprovalConfirmationModal from 'components/shared-components/ApprovalModal/approval-confirmation-modal.component';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ApproveDeclarations = ({ educationAssigneeId, isDisabled, organizationId }) => {
  const [enableVerificationModal, setEnableVerificationModal] = useState(false);
  const { t } = useTranslation();
  const [approveDeclarations, { isSuccess, requestId, isLoading }] = useApproveEducationDeclarationsMutation();

  const onApprove = () => {
    setEnableVerificationModal(false);
    return approveDeclarations({ educationAssigneeId });
  };
  return (
    <Fragment>
      <SubmitModal
        isSuccess={isSuccess}
        requestId={requestId}
        successTitle={t('educations.modal.declarations.verification.success.title')}
        successContent={t('educations.modal.declarations.verification.success.body')}
        showError={false}
        showLoader={false}
      />
      <ApprovalConfirmationModal
        organizationId={organizationId}
        isModalOpen={enableVerificationModal}
        setIsModalOpen={setEnableVerificationModal}
        title={t('educations.modal.declarations.verification.warning.title')}
        content={t('educations.modal.declarations.verification.warning.body')}
        onConfirm={onApprove}
        confirmActionText={t('shared.action.verify')}
        cancelActionText={t('shared.action.cancel')}
      />
      <Button
        label={t('educations.declarations.action.verify_module')}
        size="small"
        onClick={() => setEnableVerificationModal(true)}
        type="success"
        disabled={isLoading || isDisabled}
      />
    </Fragment>
  );
};

ApproveDeclarations.propTypes = {
  educationAssigneeId: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  organizationId: PropTypes.number.isRequired,
};

export default ApproveDeclarations;
