import { Image, Text, View } from '@react-pdf/renderer';
import tvdSuisseLogo from 'assets/tvdSuisseLogo.png';
import { Break } from 'components/pdf/shared/shared-components';
import tvdStyles from 'components/pdf/styles/tvd-styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';
import alp from 'assets/Alp_D-e.png';
import berg from 'assets/Berg_D-e.png';
import { t } from 'i18next';
import { FONT_WEIGHT_BOLD } from 'components/pdf/styles/variables';
import { STATUS_VK } from 'utils/organization-certificate-status';

const Sticker = ({
  tvdNumber,
  logo,
  logoTitle,
  logoText,
  info,
  year,
  barcodeImageUrl,
  labels,
  status,
}) => {
  const LABELS_WITH_ICON = ['alp', 'berg'];
  const LABELS_WITHOUT_ICON = ['bts_mothercow', 'bts_milkcow'];
  const labelsString = labels
    .filter(label => LABELS_WITHOUT_ICON.includes(label))
    ?.map(label => t(`tvd.order.extra_labels.${label}`))
    .join(', ');
  const labelWithIcon = labels?.find(label => LABELS_WITH_ICON.includes(label));

  const ICON_MAPPER = { alp, berg };

  return (
    <View style={tvdStyles.sticker}>
      <View style={[tvdStyles.flexRowItem, tvdStyles.stickerPart, { width: '80%' }]}>
        <View style={[tvdStyles.leftStickerDivider, { width: '100%' }]}>
          <View style={[tvdStyles.flexRow, tvdStyles.logosContainer]}>
            <View style={tvdStyles.logoContainer}>
              <Image
                source={logo}
                style={status === STATUS_VK ? tvdStyles.logoWithText : tvdStyles.logo}
              />
              <View>
                <Text style={[tvdStyles.logoTitle, status !== STATUS_VK && tvdStyles.logoTitleUk]}>
                  {logoTitle}
                </Text>
                {status !== STATUS_VK && (
                  <Text style={tvdStyles.logoText} wrap={true}>
                    {logoText}
                  </Text>
                )}
              </View>
            </View>
            <View style={[tvdStyles.yearContainer]}>
              <Text style={[tvdStyles.yearText, status !== STATUS_VK && tvdStyles.yearTextUk]}>
                {year}
              </Text>
            </View>
            <View style={[tvdStyles.tvdWrapper]}>
              <View style={tvdStyles.tvdNumberContainerVk}>
                <Text style={tvdStyles.tvdNumber}>TVD</Text>
                <Text style={tvdStyles.tvdNumber}>{tvdNumber}</Text>
              </View>
              <Image src={barcodeImageUrl} style={tvdStyles.tvdBarcode} />
            </View>
          </View>
        </View>
        <View style={tvdStyles.leftStickerDivider}>
          <View style={tvdStyles.infoContainer}>
            <View style={tvdStyles.flexRow}>
              <View style={[tvdStyles.bsNrVk, status !== STATUS_VK && { marginTop: 6 }]}>
                <Text style={tvdStyles.bsNrText}>
                  BS-Nr.
                  <Break />
                </Text>
                <Text style={[tvdStyles.bsNrText, { marginTop: 2 }]}>11776</Text>
              </View>
              <View>
                <Text style={[tvdStyles.address, status !== STATUS_VK && { marginTop: '15px' }]}>
                  {info}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={[tvdStyles.stickerPart, tvdStyles.suisseLogoContainer]}>
        <Image source={tvdSuisseLogo} style={tvdStyles.suisseLogo} />
        <Text style={tvdStyles.extraLabels}>{labelsString}</Text>
        {labelWithIcon && (
          <View style={tvdStyles.flex}>
            <View>
              <Text style={tvdStyles.stickeLabel} wrap={false}>
                {t(`pdf.tvd.sticker_${labelWithIcon}_first`)}
              </Text>
              <Text style={tvdStyles.stickeLabel} wrap={false}>
                {
                  <Trans
                    i18nKey={`pdf.tvd.sticker_${labelWithIcon}_second`}
                    components={{ bold: <Text style={{ fontWeight: FONT_WEIGHT_BOLD }} /> }}
                  />
                }
              </Text>
            </View>
            <Image src={ICON_MAPPER[labelWithIcon]} style={tvdStyles.alpBergLogo} />
          </View>
        )}
      </View>
    </View>
  );
};

Sticker.propTypes = {
  tvdNumber: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  barcodeImageUrl: PropTypes.string.isRequired,
  logo: PropTypes.node.isRequired,
  logoTitle: PropTypes.string.isRequired,
  logoText: PropTypes.string.isRequired,
  info: PropTypes.node.isRequired,
  labels: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
};

export default Sticker;
