import CheckBox from 'components/base-components/CheckBox/CheckBox';
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Card from 'components/base-components/Card';
import { Field } from 'react-final-form';
import Icon from 'components/base-components/Icon';
import classNames from 'classnames';
import { HERB } from 'utils/production-data-type';
import HerbsInputWithCheckbox from './herbs-checkbox-input.component';

const HerbsComponent = ({ isVisible, initialValue, values, validationError }) => {
  if (!isVisible) return;

  const herbs = ['medicinal_herbs', 'kitchen_herbs', 'spice_and_tea_herbs', 'potted_herbs'];

  const herbsData = herbs.map(herb => {
    return {
      label: t(`plant_cultivation.herbs.${herb}`),
      name: herb,
    };
  });

  return (
    <>
      <FieldArray name="herbsData">
        {() =>
          herbsData.map(({ name, label }, index) => (
            <Card
              className={classNames('plant-cultivation__method--card', {
                'plant-cultivation__method--card__selected':
                  initialValue?.some(herb => herb.plantType === name) ||
                  values?.herbsData?.[index]?.type,
              })}
            >
              <div key={index}>
                <Field
                  name={`herbsData[${index}].type`}
                  type="checkbox"
                  initialValue={
                    initialValue && initialValue.some(herb => herb.plantType === name) ? name : null
                  }
                >
                  {({ input }) => (
                    <CheckBox
                      className={classNames('plant-cultivation__method--fruit-main-checkbox', {
                        'plant-cultivation__method--fruit-main-checkbox__deselected':
                          !input.checked,
                      })}
                      isChecked={input.checked}
                      size="tiny"
                      label={
                        <div className="plant-cultivation__method--header-wrapper">
                          {label}
                          <Icon size="tiny" name={input.checked ? 'upArrow' : 'downArrow'} />
                        </div>
                      }
                      onChange={e => input.onChange(e.target.checked ? name : null)}
                    />
                  )}
                </Field>
                <HerbsInputWithCheckbox
                  isVisible={values?.herbsData && values?.herbsData[index]?.type === name}
                  fieldName={`herbsData[${index}]`}
                  index={index}
                  name={name}
                  initialValue={initialValue}
                  values={values}
                  validationError={validationError}
                />
              </div>
            </Card>
          ))
        }
      </FieldArray>
      {validationError?.[HERB] && (
        <div className="error-text margin-top-10 margin-left-10 margin-bottom-10">
          {validationError?.[HERB]}
        </div>
      )}
    </>
  );
};

HerbsComponent.defaultProps = {
  isVisible: false,
  initialValue: null,
  values: {},
  validationError: null,
};

HerbsComponent.propTypes = {
  isVisible: PropTypes.bool,
  initialValue: PropTypes.shape(),
  values: PropTypes.shape(),
  validationError: PropTypes.shape(),
};

export default HerbsComponent;
