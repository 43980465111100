import { StyleSheet } from '@react-pdf/renderer';
import { FONT_SIZE_14, FONT_SIZE_16, FONT_WEIGHT_BOLD } from '../styles/variables';

const producerContractStyles = StyleSheet.create({
  page: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  headerLogo: {
    height: 84,
    width: '100%',
    objectFit: 'contain',
    marginBottom: 54,
    marginTop: 5,
  },
  pdfTitle: {
    fontSize: FONT_SIZE_16,
    fontWeight: FONT_WEIGHT_BOLD,
    textAlign: 'center',
  },
  between: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  pdfSubtitle: {
    textAlign: 'center',
    marginTop: 40,
    marginBottom: 10,
    ontSize: FONT_SIZE_14,
    lineHeight: 2,
    fontWeight: FONT_WEIGHT_BOLD,
  },
});

export default producerContractStyles;
