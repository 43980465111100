function truncateFilename(filename, maxLength = 80) {
  if (!filename) return;

  if (filename.length <= maxLength) return filename;

  const length = Math.floor(maxLength / 2);
  const prefix = filename.substring(0, length);
  const suffix = filename.substring(filename.length - length);

  return `${prefix}...${suffix}`;
}

export default truncateFilename;
