import { useGetOrganizationQuery } from 'api/organizations';
import Button from 'components/base-components/Button';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import SidePanel from 'components/base-components/SidePanel';
import { VERIFIED } from 'utils/signed-contract-statuses';
import TerminateContractForm from '../producer/terminate-contract-form';

const TerminateContract = ({ contractData }) => {
  const { id } = useParams();
  const [isSidepanelOpen, setIsSidepanelOpen] = useState(false);
  const { data: organisationData } = useGetOrganizationQuery(id);
  return (
    <div>
      <Button
        label={t('contract.producer.button.terminate')}
        className="organisation-contract__success"
        size="small"
        onClick={() => setIsSidepanelOpen(true)}
        disabled={contractData.terminatedAt && contractData.status === VERIFIED}
      />
      {isSidepanelOpen && (
        <SidePanel
          isOpened={isSidepanelOpen}
          title={t('contract.fields.schedule_for')}
          body={
            <TerminateContractForm
              organisationName={organisationData?.name}
              contractId={contractData.id}
              setIsSidepanelOpen={setIsSidepanelOpen}
            />
          }
          onClose={() => setIsSidepanelOpen(false)}
          onClick={() => setIsSidepanelOpen(false)}
        />
      )}
    </div>
  );
};

TerminateContract.defaultProps = {
  contractData: null,
};

TerminateContract.propTypes = {
  title: PropTypes.string.isRequired,
  contractData: PropTypes.shape(),
};

export default TerminateContract;
