import React from 'react';
import PropTypes from 'prop-types';
import Status from './status.component';
import OrganizationIDS from './organization-ids.component';
import GeneralDetails from './general-details.component';
import ContactInformation from './contact-information.component';
import OtherAddress from './other-address.component';
import ModeOfCommunication from './mode-of-communication.component';
import LegalForm from './legal-form.component';

const ProducerOverview = ({ organisation, isFetching, isAdmin }) => (
  <div className="organisation-overview__component-wrapper">
    <div>
      <Status organisation={organisation} isFetching={isFetching} />
      <OrganizationIDS organisation={organisation} isFetching={isFetching} isAdmin={isAdmin} />
      <GeneralDetails organisation={organisation} isFetching={isFetching} />
      <LegalForm organisation={organisation} isAdmin={isAdmin} />
    </div>
    <div>
      <ContactInformation organisation={organisation} isFetching={isFetching} isAdmin={isAdmin} />
      <OtherAddress organisation={organisation} isFetching={isFetching} isAdmin={isAdmin} />
      <ModeOfCommunication organisation={organisation} isFetching={isFetching} isAdmin={isAdmin} />
    </div>
  </div>
);

ProducerOverview.propTypes = {
  organisation: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default ProducerOverview;
