import { apiSlice } from 'api';
import routes from 'utils/routes';
import { CONTROL_BODY_SURVEY, INGREDIENT_RESERVES_ADDITIONAL_TASK } from 'utils/tag-types';

const ingredientReservesApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getActiveIngredientReservesAdditionalTask: build.query({
      query: params => routes.ingredientReserves.active(params),
      providesTags: [INGREDIENT_RESERVES_ADDITIONAL_TASK],
    }),
    declareIngredientReservesAdditionalTask: build.mutation({
      query: ({ surveyId, payload }) => ({
        url: routes.ingredientReserves.declare({ surveyId }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, id) => (!error ? [INGREDIENT_RESERVES_ADDITIONAL_TASK] : []),
    }),
    approveIngredientReservesAdditionalTask: build.mutation({
      query: ({ surveyId, payload }) => ({
        url: routes.ingredientReserves.approve({ surveyId }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, id) =>
        !error ? [INGREDIENT_RESERVES_ADDITIONAL_TASK, CONTROL_BODY_SURVEY] : [],
    }),
    getIngredientReservesAdditionalPhotos: build.query({
      query: params => routes.ingredientReserves.getReservedIngredientPhotos(params),
      providesTags: [CONTROL_BODY_SURVEY],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetActiveIngredientReservesAdditionalTaskQuery,
  useDeclareIngredientReservesAdditionalTaskMutation,
  useApproveIngredientReservesAdditionalTaskMutation,
  useGetIngredientReservesAdditionalPhotosQuery
} = ingredientReservesApi;
