import { useGetGardeningItemQuery } from 'api/producers/productions';
import { t } from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import SharedProductionComponent from './shared/shared-production.component';
import sortTitleOrder from './helpers/sortTitleOrder';

const GardenItemComponent = ({ id }) => {
  const { data: gardeningItemCollection } = useGetGardeningItemQuery(
    { productionId: id },
    { skip: !id },
  );

  const gardenItem = gardeningItemCollection?.productionDetails
    ? toSnakecaseKeys(gardeningItemCollection.productionDetails)
    : [];

  const filteredGardeningItem = Object.keys(gardenItem)?.filter(key => !!gardenItem[key]);

  return (
    <SharedProductionComponent title={t('plant_cultivation.tab.garden')} icon="garden">
      <div className="margin-top-10">
        {sortTitleOrder('gardening_item', filteredGardeningItem).map(value => (
          <div className="margin-top-10">{t(`operations.garden.${value}`)}</div>
        ))}
      </div>
    </SharedProductionComponent>
  );
};

GardenItemComponent.defaultProps = {
  id: null,
};

GardenItemComponent.propTypes = {
  id: PropTypes.node,
};

export default GardenItemComponent;
