import { useGetSingleTaskQuery, useMarkAsDoneMutation, useUpdateTaskMutation } from 'api/tasks';
import { useGetCurrentUserQuery } from 'api/users';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import EmphasisTag from 'components/base-components/EmphasisTag';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import Popover from 'components/base-components/Popover';
import { Task } from 'components/base-components/TaskList';
import OrganizationName from 'components/organisations/organization-name';
import UserInfo from 'components/users/user-info.component';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  dateMonthYearFormatter,
  dateTimeFormatterWithAmPm,
  monthYearShort,
} from 'utils/date-time-formatter';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import TASKS_STATUS from 'utils/task-status';
import { useGetGoodsDeclarationQuery } from 'api/goods-declarations';
import { useGetActiveGoodsDeclarationEvaluationQuery } from 'api/control-body-survey';
import {
  MANUAL,
  REVIEW_CONTROL_BODY_DECLARATION,
  REVIEW_CONTROL_FLOW_SCHEDULE,
} from 'utils/task-types';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { Link } from 'react-router-dom';
import { parseTaskDescription, parseTaskTitle } from '../task-helper';

const GoodsDeclarationPeriod = ({ goodsDeclarationId }) => {
  const { data: goodsDeclaration, isFetching } = useGetGoodsDeclarationQuery(goodsDeclarationId);
  if (isFetching) {
    return;
  }

  return (
    <div>
      <span className="task__details--boldest-font">
        {monthYearShort(goodsDeclaration.periodFrom)}
      </span>
      -
      <span className="task__details--boldest-font">
        {monthYearShort(goodsDeclaration.periodUntil)}
      </span>
    </div>
  );
};

GoodsDeclarationPeriod.propTypes = {
  goodsDeclarationId: PropTypes.number.isRequired,
};

const TaskDetailsComponent = () => {
  const { id } = useParams();
  const { data: task, isSuccess } = useGetSingleTaskQuery(id);
  const { data: goodsDeclarationEvaluation } = useGetActiveGoodsDeclarationEvaluationQuery(
    { surveyId: task?.referenceableId },
    { skip: task?.type !== REVIEW_CONTROL_FLOW_SCHEDULE },
  );

  const { t } = useTranslation();
  const [showTaskOptions, setShowTaskOptions] = useState(false);
  const { data: currentUser } = useGetCurrentUserQuery();
  const [updateTask] = useUpdateTaskMutation();
  const [markAsDone] = useMarkAsDoneMutation();
  const isAdmin = isBioSuisseAdmin(currentUser);

  const navigationLinks = useNavigationLinks();

  const emphasisTagType = {
    high: 'danger',
    low: 'disabled',
    medium: 'warning',
  };

  const assignee = task => {
    if (!task) {
      return null;
    }
    if (task.assignedOrganizationId) {
      return <OrganizationName organizationId={task.assignedOrganizationId} />;
    } else if (task.assignedToAdmin) {
      return t('shared.bio_cuisine_admins');
    } else if (task.type === REVIEW_CONTROL_BODY_DECLARATION) {
      return t('shared.control_body_users');
    } else {
      return (
        <div className="task-list__item-user">
          <UserInfo userId={task.assignedUserIds[0]} />
        </div>
      );
    }
  };

  const description =
    task?.type !== REVIEW_CONTROL_FLOW_SCHEDULE ? (
      parseTaskDescription(task)
    ) : (
      <Fragment>
        {parseTaskDescription(task)}
        <p>{t('task.description.control_schedule.following_quarters')}</p>
        {goodsDeclarationEvaluation?.randomGoodsDeclarations?.map(randomDeclaration => (
          <GoodsDeclarationPeriod goodsDeclarationId={randomDeclaration.goodsDeclarationId} />
        ))}
        <p>{t('task.description.control_schedule.document')}</p>
      </Fragment>
    );

  return (
    <>
      {isSuccess && (
        <Task
          isExpanded={true}
          key={task?.id}
          assignDate={dateMonthYearFormatter(task?.createdAt)}
          showTaskDetails={true}
          assignee={assignee(task)}
          assigneeLabel={<span className="task__assignee"> {t('task.assignee')} </span>}
          createdTime={dateTimeFormatterWithAmPm(task?.createdAt)}
          dueDate={dateMonthYearFormatter(task?.dueDate)}
          description={description}
          id={task.id}
          lastUpdatedTime={dateTimeFormatterWithAmPm(task?.updatedAt)}
          lastUpdatedTimeLabel={t('task.last_update')}
          name={parseTaskTitle(task)}
          priority={
            <EmphasisTag
              radius="oval"
              size="tiny"
              text={t(`tasks.priority.${task?.priority}`)}
              type={emphasisTagType[task?.priority]}
            />
          }
          reporter={
            task?.type !== MANUAL ? (
              t('shared.system')
            ) : (
              <div className="task-list__item-reporter">
                <UserInfo userData={task?.author} />
              </div>
            )
          }
          reporterLabel={<span className="task__assignee"> {t('task.creator')} </span>}
          showTaskOptions={
            (task?.status === TASKS_STATUS.pending || isAdmin) && (
              <Popover
                className="task__popover-wrapper"
                isVisible={showTaskOptions}
                onOutsideClick={() => setShowTaskOptions(false)}
                content={
                  <div className="task__popover">
                    {task?.status !== TASKS_STATUS.done && (
                      <div
                        className={classNames('task__popover-content', {
                          'task__popover-content--hide': task?.status === TASKS_STATUS.cancelled,
                        })}
                        onClick={() => {
                          setShowTaskOptions(false);
                          markAsDone({ id: task?.id });
                        }}
                      >
                        <span>{t('task.mark_done')}</span>
                      </div>
                    )}
                    {isAdmin && (
                      <>
                        <Link to={navigationLinks.taskEditPage(task?.id)}>
                          <div
                            className={classNames('task__popover-content', {
                              'task__popover-content--hide':
                                task?.status === TASKS_STATUS.cancelled,
                            })}
                          >
                            {t('common.edit_button')}
                          </div>
                        </Link>
                        <div
                          className={classNames('task__popover-content', {
                            'task__popover-content--hide': task?.status === TASKS_STATUS.cancelled,
                          })}
                          onClick={() => {
                            setShowTaskOptions(false);
                            updateTask({
                              id: task?.id,
                              payload: {
                                status: TASKS_STATUS.cancelled,
                              },
                            });
                          }}
                        >
                          {t('common.cancel_button')}
                        </div>
                        <div
                          className={classNames('task__popover-content', {
                            'task__popover-content--hide': task?.status !== TASKS_STATUS.cancelled,
                          })}
                          onClick={() => {
                            setShowTaskOptions(false);
                            updateTask({
                              id: task?.id,
                              payload: {
                                status: TASKS_STATUS.pending,
                              },
                            });
                          }}
                        >
                          {t('tasks.reopen')}
                        </div>
                      </>
                    )}
                  </div>
                }
              >
                <IconButton
                  icon={<Icon name="horizontalDots" />}
                  color="default"
                  onClick={() => setShowTaskOptions(!showTaskOptions)}
                  isIconOnly={true}
                />
              </Popover>
            )
          }
        />
      )}
    </>
  );
};

export default TaskDetailsComponent;
