import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const AttributeAlignment = ({ label, value }) => {
  const { t } = useTranslation();

  return (
    <div className="attribute-alignment">
      <span>{label}</span>
      <span>{[null, undefined].includes(value) ? t('common.not_applicable') : value}</span>
    </div>
  );
};

AttributeAlignment.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default AttributeAlignment;
