import React from 'react';
import { useVerifyTwoFactorAuthenticationMutation } from 'api/two-factor-authentication';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import Button from '../base-components/Button';
import Input from '../base-components/Input';
import './login.style.scss';

export default function VerifyOtp() {
  const [verifyOtp, { error: { data: errorResponse } = {} }] =
    useVerifyTwoFactorAuthenticationMutation();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const OnVerifyOtp = values => {
    verifyOtp({ otp: values.otp })
      .unwrap()
      .then(() => {
        navigate.openRootPage();
      })
      .catch(() => {});
  };

  return (
    <Form
      onSubmit={OnVerifyOtp}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="login__verify-otp">
          <div className="login__verify-otp-label">{t('login.two_factor_label')}</div>
          <Field name="otp">
            {({ input }) => (
              <Input
                className="login__form-email"
                label={t('user_credential.two_factor.authentication_code.label')}
                touched={true}
                placeholder={t('user_credential.two_factor.enter_otp')}
                error={errorResponse?.error}
                {...input}
              />
            )}
          </Field>
          <Button
            label={t('shared.action.verify')}
            type="success"
            size="normal"
            className="login__button"
            submitType="submit"
          />
        </form>
      )}
    />
  );
}
