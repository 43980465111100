import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import PropTypes from 'prop-types';
import Textarea from 'components/base-components/Textarea';
import { useTranslation } from 'react-i18next';
import Input from 'components/base-components/Input';
import { Table } from 'components/base-components/Table';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import Button from 'components/base-components/Button';
import SelectBox from 'components/base-components/SelectBox';
import {
  useGetInvoiceItemCostCentersQuery,
  useGetInvoiceItemCostElementsQuery,
} from 'api/invoices';

const LineItems = ({ setRemovedItems }) => {
  const { t } = useTranslation();

  const { data: invoiceItemsCostCenters = [] } = useGetInvoiceItemCostCentersQuery();
  const { data: invoiceItemsCostElements = [] } = useGetInvoiceItemCostElementsQuery();

  return (
    <div>
      <FieldArray name="items">
        {({ fields }) => (
          <Table className="invoice__item--table">
            {fields.map((name, index) => (
              <>
                <div className="invoice__item--wrapper">
                  <Field name={`${name}.id`}>
                    {({ input, meta }) => <input type="hidden" {...input} />}
                  </Field>
                  <div className="invoice__item">
                    {t('invoice.line_item')} {index + 1}
                  </div>
                  <IconButton
                    icon={<Icon name="delete" size="small" />}
                    size="small"
                    color="danger"
                    onClick={() => {
                      fields.remove(index);
                      setRemovedItems(prevRemovedItems => [
                        ...prevRemovedItems,
                        fields.value[index],
                      ]);
                    }}
                  />
                </div>
                <div className="col-6 col-bleed-x">
                  <Field name={`${name}.name`}>
                    {({ input, meta }) => (
                      <Input
                        className="invoice__line-input"
                        label={t('invoice.line_items.service')}
                        size="tiny"
                        placeholder={t('invoice.enter_service')}
                        error={meta.error || meta.submitError}
                        touched={!meta.dirtySinceLastSubmit}
                        isClearable={false}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-3">
                  <Field name={`${name}.price`}>
                    {({ input, meta }) => (
                      <Input
                        className="invoice__line-input"
                        label={t('invoice.line_items.price')}
                        type="number"
                        size="tiny"
                        placeholder={t('invoice.enter_chf')}
                        error={meta?.submitError}
                        touched={meta.touched}
                        isClearable={false}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-3 col-bleed-x">
                  <Field name={`${name}.quantity`}>
                    {({ input, meta }) => (
                      <Input
                        className="invoice__line-input"
                        label={t('invoice.line_items.qty')}
                        type="number"
                        size="tiny"
                        placeholder={t('invoice.enter_quantity')}
                        error={meta?.submitError}
                        touched={meta.touched}
                        isClearable={false}
                        {...input}
                        onChange={value => input.onChange(parseInt(value, 10))}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-12 col-bleed-x">
                  <Field name={`${name}.description`}>
                    {({ input, meta }) => (
                      <Textarea
                        placeholder={t('invoice.enter_description')}
                        label={t('invoice.line_items.des')}
                        error={meta.submitError}
                        touched={!meta.dirtySinceLastSubmit}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-4 col-bleed">
                  <Field
                    name={`${name}.cost_center`}
                    initialValue={invoiceItemsCostCenters[0]?.value}
                  >
                    {({ input, meta }) => (
                      <SelectBox
                        className="invoice__line-input"
                        size="tiny"
                        width="large"
                        label={t('invoice.line_items.cost_center')}
                        placeholderText={t('organisation.form.placeholder.industry')}
                        isClearable={false}
                        options={invoiceItemsCostCenters}
                        value={invoiceItemsCostCenters.find(option => option.value === input.value)}
                        onChange={e => {
                          input.onChange(e.value);
                        }}
                        errorMsg={meta?.submitError}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-4 col-bleed-y">
                  <Field
                    name={`${name}.cost_element`}
                    initialValue={invoiceItemsCostElements[0]?.value}
                  >
                    {({ input, meta }) => (
                      <SelectBox
                        className="invoice__line-input"
                        size="tiny"
                        width="large"
                        label={t('invoice.line_items.cost_element')}
                        placeholderText={t('organisation.form.placeholder.industry')}
                        isClearable={false}
                        options={invoiceItemsCostElements}
                        value={invoiceItemsCostElements.find(
                          option => option.value === input.value,
                        )}
                        onChange={value => {
                          input.onChange(value.value);
                        }}
                        errorMsg={meta.submitError}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-4 col-bleed">
                  <Field name={`${name}.vat_rate`}>
                    {({ input, meta }) => (
                      <Input
                        className="invoice__line-input"
                        label={t('invoice.line_items.vat')}
                        type="number"
                        size="tiny"
                        placeholder={t('invoice.enter_vat')}
                        error={meta.submitError}
                        touched={meta.touched}
                        isClearable={false}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
              </>
            ))}
            <div className="invoice__form-button">
              <Button
                label={t('invoice.line_items.add_line_item')}
                size="small"
                onClick={() => {
                  fields.push({});
                }}
                type="default"
              />
            </div>
          </Table>
        )}
      </FieldArray>
    </div>
  );
};

LineItems.propTypes = {
  setRemovedItems: PropTypes.func.isRequired,
};
export default LineItems;
