import React from 'react';
import useNavigationLinks from 'hooks/useNavigationLinks';
import Logo from 'assets/farelogix.png';
import Icon from 'components/base-components/Icon';
import { useGetCurrentUserQuery } from 'api/users';
import { Link } from 'react-router-dom';

const HeaderComponent = () => {
  const { data: currentUser } = useGetCurrentUserQuery();

  const navigationLinks = useNavigationLinks();
  return currentUser && currentUser.admin ? (
    <div className="user__head">
      <img className="user__head--image" src={Logo} height="60" alt="Bio Logo" />
      <div className="user__head--title">
        <div className="user__head--title__main">Organisation Name</div>
        <div className="user__head--title__subtitle">UID | Registration Date</div>
      </div>
      <div className="user__head--address">
        <div className="user__head--address__with-icon">
          {' '}
          <span className="user__head--address__icon">
            <Icon name="location" />{' '}
          </span>{' '}
          <span className="user__head--address__info">
            {' '}
            Building Number, Name, Street Name, Zip Code, City/State, Country{' '}
          </span>
        </div>
        <div className="user__head--address__with-icon">
          {' '}
          <span className="user__head--address__icon">
            <Icon name="addOrder" />{' '}
          </span>{' '}
          <span className="user__head--address__info">
            {' '}
            Building Number, Name, Street Name, Zip Code, City/State, Country{' '}
          </span>
        </div>
      </div>
      <Link to={navigationLinks.rootPage()}>
        <div className="user__head--view">Back to Overview</div>
      </Link>
    </div>
  ) : null;
};

export default HeaderComponent;
