import React from 'react';
import PropTypes from 'prop-types';
import './negative-report.styles.scss';
import VerificationTag from 'components/shared-components/verification-tag';
import { dateMonthYear } from 'utils/date-time-formatter';
import { dayDifference } from 'utils/day-difference';
import { DRAFT, PENDING } from 'utils/verification-status';
import { t } from 'i18next';

const NegativeReportsHeader = ({ text, status, date, dueDate }) => {
  const dateDiff = dayDifference(dueDate);
  const daysLeft = `${dateDiff} ${t('negative_report.days_left')}`;

  return (
    <div className="negative-reports__header-wrapper">
      <div className="negative-reports__header-text">{text}</div>
      {status && (
        <div className="negative-reports__header-status">
          <span>
            {t('negative_report.status')} <VerificationTag status={status === DRAFT ? PENDING : status} />
          </span>
        </div>
      )}

      <div className="negative-reports__header-date">{t('shared.date')} {dateMonthYear(date)}</div>
      {dueDate && <div className="negative-reports__header-dayleft">{daysLeft}</div>}
    </div>
  );
};

NegativeReportsHeader.defaultProps = {
  status: null,
  date: null,
  dueDate: null,
};

NegativeReportsHeader.propTypes = {
  text: PropTypes.string.isRequired,
  status: PropTypes.string,
  date: PropTypes.string,
  dueDate: PropTypes.string,
};

export default NegativeReportsHeader;
