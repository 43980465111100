import React from 'react';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useGetOrganizationQuery } from 'api/organizations';
import SharedFarmlandCard from './shared/farmland-card.component';

const FarmlandComponent = ({ farmlandData }) => {
  const { id } = useParams();
  const { data: organization } = useGetOrganizationQuery(id);
  const previousCultivation = organization?.previousCultivation;
  const producerType = organization?.producerType;

  const farmlandContent = [
    {
      title: t('legal_form.company_type'),
      name: producerType
        ? t(`legal_form.company_type_options.${producerType}`)
        : t('common.not_applicable'),
    },
    {
      title: t('operations.land_information'),
      landInfo: [
        {
          name: t('operations.agriculture_land'),
          value: farmlandData.agriculturalLandInHectare,
        },
        {
          name: t('operations.green_land'),
          value: farmlandData.greenlandInHectare,
        },
        {
          name: t('operations.open_land'),
          value: farmlandData.openCultivatedLandInHectare,
        },
      ],
    },
    {
      title: t('farmland.previous_cultivation_title'),
      name: previousCultivation
        ? t(`farmland.previous_cultivation.${previousCultivation}`)
        : t('common.not_applicable'),
    },
    {
      title: t('farmland.dgve_title'),
      name: t('farmland.dgve_estimation_label'),
      value: farmlandData?.dgve || t('common.not_applicable'),
    },
  ];

  return (
    <div>
      <div className="font-bold">{t('farmland.title')}</div>
      <div className="operations__farmland">
        {farmlandContent?.map(farmland => (
          <SharedFarmlandCard content={farmland} />
        ))}
      </div>
    </div>
  );
};

FarmlandComponent.defaultProps = {
  farmlandData: {},
};

FarmlandComponent.propTypes = {
  farmlandData: PropTypes.shape(),
};

export default FarmlandComponent;
