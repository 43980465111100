import { t } from 'i18next';

export const seasonalDatas = {
  plant_cultivation: {
    name: t('plant_cultivation.tab.plant_cultivation'),
    icon: 'plant_cultivation',
  },
  milk_production: {
    name: t('plant_cultivation.tab.milk'),
    icon: 'milk',
  },
  fish_production: {
    name: t('plant_cultivation.tab.fish'),
    icon: 'fish',
  },
  egg_production: {
    name: t('plant_cultivation.tab.egg'),
    icon: 'egg',
  },
  meat_production: {
    name: t('plant_cultivation.tab.meat_production'),
    icon: 'meat_production',
  },
  gardening: {
    name: t('plant_cultivation.tab.garden'),
    icon: 'garden',
  },
  bee_keeping: {
    name: t('plant_cultivation.tab.bee'),
    icon: 'bee',
  },
  other: {
    name: t('plant_cultivation.tab.animal'),
    icon: 'animal',
  },
  recreational_and_green_areas: {
    name: t('plant_cultivation.tab.green_area'),
    icon: 'green_area',
  },
  private_parks_and_municipalities: {
    name: t('plant_cultivation.tab.private_park'),
    icon: 'private_park',
  },
};
