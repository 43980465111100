import { Image, Text, View } from '@react-pdf/renderer';
import styles from 'components/pdf/styles/checklist-styles';
import { t } from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import truncateFilename from 'utils/truncate-filename';
import ImageUpload from 'assets/image-upload.png';
import { FINAL } from 'utils/verification-status';

const RestrictedIngredients = ({
  data,
  reportType,
  restrictedIngredientsAttachmentPhotos,
  comments,
}) => {
  return (
    <>
      <Text style={styles.checklistMarginForty}>
        <Text style={styles.bold}>3. </Text>
        {t('control_body.survey.form.third_step')}
      </Text>
      <View>
        {data?.length > 0
          ? data.map(restrictedData => (
              <>
                <View style={styles.checkListFlexSpaceBetween}>
                  <View style={styles.yesNoListItemContainer}>
                    <View style={styles.yesNoBulletPoint} />
                    <Text>{restrictedData.label}</Text>
                  </View>

                  <Text>
                    {restrictedData.exists === true
                      ? t('shared.action.yes')
                      : t('control_body.survey.shared.no')}
                  </Text>
                </View>
                <View style={styles.checklistHorizontalLine} />
              </>
            ))
          : t('control_body.survey.form.pdf_list.no_restricted_ingredients')}
      </View>

      {reportType === FINAL && restrictedIngredientsAttachmentPhotos.length > 0 && (
        <>
          <View style={styles.checklistMarginTwenty}>
            <View style={styles.checkListFlexSpaceBetween}>
              <Text style={styles.heading1}> {t('control_body.survey.form.uploaded_files')} </Text>
            </View>
          </View>
          <View style={styles.checklistMarginTen}>
            {restrictedIngredientsAttachmentPhotos.map(logo => (
              <View style={{ ...styles.checkListFlex, ...styles.checklistMarginTen }}>
                <Image src={ImageUpload} style={styles.imageLogo} />
                <Text>{truncateFilename(logo?.filename) || t('common.not_applicable')}</Text>
              </View>
            ))}
          </View>
        </>
      )}

      {comments.restrictedIngredients && (
        <View style={styles.baseComment}>
          <Text>
            {t('control_body.survey.form.comment_from_auditor')} {comments.restrictedIngredients}
          </Text>
        </View>
      )}
    </>
  );
};

RestrictedIngredients.propTypes = {
  data: PropTypes.array.isRequired,
  comments: PropTypes.shape().isRequired,
  reportType: PropTypes.string.isRequired,
  restrictedIngredientsAttachmentPhotos: PropTypes.array.isRequired,
};

export default RestrictedIngredients;
