import React from 'react';
import { useCreateInvoiceMutation } from 'api/invoices';
import ManualInvoiceForm from 'components/invoices/Form/index.component';

const CreateManualInvoice = () => {
  const [createManualInvoice, status] = useCreateInvoiceMutation();

  const onCreateInvoice = values =>
    createManualInvoice({
      queryParams: { organisationId: values.organization },
      payload: values,
    });

  return <ManualInvoiceForm onSave={onCreateInvoice} status={status} />;
};
export default CreateManualInvoice;
