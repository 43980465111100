import WarningModal from 'components/shared-components/modal/warning';
import React, { useState } from 'react';
import { useUpdateInvoiceMutation } from 'api/invoices';
import { CANCELLED } from 'utils/invoice-statuses';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import Button from 'components/base-components/Button';

const InvoiceDeleteModal = ({ invoice, isPopover }) => {
  const [updateInvoice, status] = useUpdateInvoiceMutation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const cancelInvoice = id => {
    updateInvoice({ invoiceId: id, payload: { status: CANCELLED } })
      .unwrap()
      .then(() => setIsDeleteModalOpen(false));
  };

  return (
    <>
      {isPopover ? (
        <div onClick={() => setIsDeleteModalOpen(true)} className="task__popover-content">
          {t('invoice.cancel_invoice')}
        </div>
      ) : (
        <Button
          onClick={() => setIsDeleteModalOpen(true)}
          label={t('invoice.cancel_invoice')}
          size="small"
        />
      )}

      <SubmitModal
        isSuccess={status?.isSuccess}
        requestId={status?.requestId}
        successTitle={
          invoice.status === CANCELLED
            ? t('invoice.cancel_modal.title')
            : t('invoice.publish_success_title')
        }
        successContent={
          invoice.status === CANCELLED
            ? t('invoice.cancel_modal.description')
            : t('invoice.publish_success_desc')
        }
        showError={false}
        showLoader={false}
      />

      <WarningModal
        onOutsideClick={() => setIsDeleteModalOpen(false)}
        isVisible={isDeleteModalOpen}
        title={t('invoice.cancel_modal.warning_title')}
        content={t('invoice.cancel_modal.warning_description')}
        confirmActionText={t('shared.action.yes')}
        cancelActionText={t('shared.action.no')}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={() => cancelInvoice(invoice.id)}
      />
    </>
  );
};

InvoiceDeleteModal.defaultProps = {
  invoice: {},
  isDeleteModalOpen: false,
  isPopover: false,
  setIsDeleteModalOpen: () => {},
};

InvoiceDeleteModal.propTypes = {
  invoice: PropTypes.shape(),
  isDeleteModalOpen: PropTypes.bool,
  isPopover: PropTypes.bool,
  setIsDeleteModalOpen: PropTypes.func,
};

export default InvoiceDeleteModal;
