const farmlandErrorCondtion = ({
  agriculturalLandInHectare,
  greenlandInHectare,
  openCultivatedLandInHectare,
  customerSince,
  dgve,
  production,
}) =>
  agriculturalLandInHectare ||
  greenlandInHectare ||
  openCultivatedLandInHectare ||
  customerSince ||
  dgve ||
  production;

export default farmlandErrorCondtion;
