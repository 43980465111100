import React from 'react';
import { useParams } from 'react-router';
import useNavigationLinks from 'hooks/useNavigationLinks';
import Icon from 'components/base-components/Icon';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from 'components/base-components/Button';
import IconButton from 'components/base-components/IconButton';
import { Link } from 'react-router-dom';

const OrganizationAlertComponent = ({ alert, onIconClick, alertList, index }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigationLinks = useNavigationLinks();

  return (
    <>
      {alert && (
        <div className="organisation-details__view-btn">
          <div className="organisation-details__view-btn--wrapper">
            <Icon name="warning" showBGColor={true} size="small" color="warning" />
            <div className="organisation-details__contract-declare--wrapper">
              <span className="organisation-details__contract-declare--text">{alert.title}</span>
              <span className="organisation-details__contract-declare--request">{alert.body}</span>
              {alert.footer && (
                <span className="organisation-details__contract-declare--footer-text">
                  {alert.footer}
                </span>
              )}
            </div>
          </div>
          <div className="organisation-details__view-btn--wrapper">
            {alert.button && (
              <Link to={navigationLinks.organisationContractPage(id)}>
                <Button label={t('contract.view_button')} size="small" type="success" />
              </Link>
            )}
            <div className="organisation-details__head__side">
              <IconButton
                icon={<Icon name="rightarrowhead" size="tiny" />}
                onClick={() => onIconClick(false)}
                disabled={index === 0}
                isIconOnly={true}
              />
              <IconButton
                className="organisation-details__head__side--right-arrorw"
                icon={<Icon name="leftarrowhead" size="tiny" />}
                onClick={() => onIconClick(true)}
                disabled={index === alertList.length - 1}
                isIconOnly={true}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

OrganizationAlertComponent.defaultProps = {
  alert: null,
  alertList: [],
  index: null,
  onIconClick: () => {},
};

OrganizationAlertComponent.propTypes = {
  alert: PropTypes.shape(),
  onIconClick: PropTypes.func,
  alertList: PropTypes.arrayOf(PropTypes.node),
  index: PropTypes.node,
};

export default OrganizationAlertComponent;
