import React from 'react';
import TimePicker from 'react-time-picker';
import PropTypes from 'prop-types';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import HourMinuteCalculator from 'utils/hour-minute-calculator';
import { Header } from 'components/base-components/Typography';
import './style.scss';
import IconButton from '../IconButton';
import Icon from '../Icon';

const TimePickerComponent = ({
  className,
  label,
  value,
  clearIcon,
  onChange,
  handleTime,
  minTime,
  maxTime,
  disableClock,
  footer,
  header,
}) => {
  let time = value;

  return (
    <div className={className}>
      <div className="time-picker__header">{header}</div>
      <div className="time-picker__container">
        <Header weight="6">{label}</Header>
        <div className="control-body__form-time--wrapper">
          <IconButton
            className="time-picker__up-arrow time-picker__up-arrow--hour"
            size="tiny"
            icon={<Icon name="upArrow" />}
            onClick={() => {
              const hour = HourMinuteCalculator({
                time,
                isHourSelected: true,
                isMinuteSelected: false,
                upArrow: true,
                downArrow: false,
                minTime,
                maxTime,
              });
              handleTime(hour);
            }}
          />
          <IconButton
            className="time-picker__down-arrow time-picker__down-arrow--hour"
            size="tiny"
            icon={<Icon name="downArrow" />}
            onClick={() => {
              const hour = HourMinuteCalculator({
                time,
                isHourSelected: true,
                isMinuteSelected: false,
                upArrow: false,
                downArrow: true,
                minTime,
                maxTime,
              });
              handleTime(hour);
            }}
          />
          <IconButton
            className="time-picker__up-arrow time-picker__up-arrow--minute"
            size="tiny"
            icon={<Icon name="upArrow" />}
            onClick={() => {
              const minute = HourMinuteCalculator({
                time,
                isHourSelected: false,
                isMinuteSelected: true,
                upArrow: true,
                downArrow: false,
                minTime,
                maxTime,
              });
              handleTime(minute);
            }}
          />
          <IconButton
            className="time-picker__down-arrow time-picker__down-arrow--minute"
            size="tiny"
            icon={<Icon name="downArrow" />}
            onClick={() => {
              const minute = HourMinuteCalculator({
                time,
                isHourSelected: false,
                isMinuteSelected: true,
                upArrow: false,
                downArrow: true,
                minTime,
                maxTime,
              });
              handleTime(minute);
            }}
          />
        </div>
        <TimePicker
          onChange={onChange}
          clearIcon={clearIcon}
          value={time}
          locale="sv-sv"
          disableClock={disableClock}
          minTime={minTime}
          maxTime={maxTime}
          format="HH:mm"
        />
        <div className="time-picker__footer">{footer}</div>
      </div>
    </div>
  );
};

TimePickerComponent.defaultProps = {
  className: null,
  label: null,
  footer: null,
  header: null,
  clearIcon: null,
  minTime: '00:00',
  maxTime: '23:59',
  disableClock: true,
  handleTime: () => {},
};

TimePickerComponent.propTypes = {
  value: PropTypes.node.isRequired,
  className: PropTypes.node,
  clearIcon: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  handleTime: PropTypes.func,
  minTime: PropTypes.string,
  label: PropTypes.string,
  footer: PropTypes.string,
  header: PropTypes.string,
  maxTime: PropTypes.string,
  disableClock: PropTypes.bool,
};

export default TimePickerComponent;
