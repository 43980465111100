import CheckBox from 'components/base-components/CheckBox/CheckBox';
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Input from 'components/base-components/Input';
import { t } from 'i18next';
import PLANT_CULTIVATION from 'utils/plant-cultivation';
import Tooltip from 'components/base-components/Tooltip';
import IconButton from 'components/base-components/IconButton';
import Icon from 'components/base-components/Icon';

const HerbsInputWithCheckbox = ({
  isVisible,
  fieldName,
  initialValue,
  index,
  name,
  values,
  validationError,
}) => {
  const savedHerbsData = initialValue && initialValue.find(value => value?.plantType === name);

  const herbsData =
    `herbsData[${index}]` === fieldName && values?.herbsData?.find(herbs => herbs?.type === name);

  if (!isVisible) return;

  return (
    <div>
      <div className="plant-cultivation__method--open-fruit">
        <div className="plant-cultivation__method--open-fruit__check">
          <Field
            name={`${fieldName}.open_checkbox`}
            type="checkbox"
            initialValue={savedHerbsData?.productionDetails?.openFieldInAres}
          >
            {({ input }) => (
              <CheckBox
                isChecked={input.checked}
                size="tiny"
                label={t('plant_cultivation.shared.open_field')}
                onChange={e => input.onChange(e.target.checked)}
              />
            )}
          </Field>
          <Field
            name={`${fieldName}.open_surface_area_in_ares`}
            initialValue={savedHerbsData?.productionDetails?.openFieldInAres}
          >
            {({ input, meta }) => (
              <Input
                size="tiny"
                label={t('plant_cultivation.mushroom.surface_area_ares')}
                placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
                disabled={!herbsData?.open_checkbox}
                touched={!meta.dirtySinceLastSubmit}
                error={
                  meta.submitError ||
                  (validationError?.herbs_open_surface_area_in_ares?.herb === name &&
                    validationError?.herbs_open_surface_area_in_ares?.text)
                }
                {...input}
              />
            )}
          </Field>
        </div>
        <div className="plant-cultivation__method--open-fruit__check">
          <Field
            name={`${fieldName}.covered_checkbox`}
            type="checkbox"
            initialValue={savedHerbsData?.productionDetails?.coveredFieldInAres}
          >
            {({ input }) => (
              <CheckBox
                isChecked={input.checked}
                size="tiny"
                label={
                  <div>
                    <span className="plant-cultivation__method--open__tooltip-label">
                      {t('plant_cultivation.shared.covered_cultivation')}
                    </span>
                    <span>
                      <Tooltip
                        content={t('plant_cultivation.shared.covered_cultivation_info')}
                        type="inverse"
                        position="right-top"
                        size="small"
                        showArrow={true}
                      >
                        <IconButton
                          icon={<Icon name="info" size="tiny" />}
                          color="default"
                          size="tiny"
                          isIconOnly={true}
                        />
                      </Tooltip>
                    </span>
                  </div>
                }
                onChange={e => input.onChange(e.target.checked)}
              />
            )}
          </Field>
          <Field
            name={`${fieldName}.covered_surface_area_in_ares`}
            initialValue={savedHerbsData?.productionDetails?.coveredFieldInAres}
          >
            {({ input, meta }) => (
              <Input
                size="tiny"
                label={t('plant_cultivation.mushroom.surface_area_ares')}
                placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
                disabled={!herbsData?.covered_checkbox}
                touched={!meta.dirtySinceLastSubmit}
                error={
                  meta.submitError ||
                  (validationError?.herbs_covered_surface_area_in_ares?.herb === name &&
                    validationError?.herbs_covered_surface_area_in_ares)
                }
                {...input}
              />
            )}
          </Field>
        </div>
        <div className="plant-cultivation__method--cultivation-time">
          <div className="plant-cultivation__time--text">
            <span>{t('plant_cultivation.herbs.cultivation_time')}</span>
            <span className="plant-cultivation__required">*</span>
          </div>
          <div className="plant-cultivation__time">
            <Field
              name={`${fieldName}.cultivation_periods[${index}]`}
              type="checkbox"
              initialValue={
                savedHerbsData?.productionDetails?.cultivationPeriods?.includes(
                  PLANT_CULTIVATION.annual,
                )
                  ? PLANT_CULTIVATION.annual
                  : null
              }
            >
              {({ input }) => (
                <CheckBox
                  className="plant-cultivation__herbs-checkbox"
                  isChecked={input.checked}
                  size="tiny"
                  label={t('plant_cultivation.herbs.annual')}
                  onChange={e => input.onChange(e.target.checked ? PLANT_CULTIVATION.annual : null)}
                />
              )}
            </Field>
            <Field
              name={`${fieldName}.cultivation_periods[${index + 1}]`}
              initialValue={
                savedHerbsData?.productionDetails?.cultivationPeriods?.includes(
                  PLANT_CULTIVATION.perennial,
                )
                  ? PLANT_CULTIVATION.perennial
                  : null
              }
              type="checkbox"
            >
              {({ input }) => (
                <CheckBox
                  className="plant-cultivation__herbs-checkbox plant-cultivation__time--checkbox"
                  isChecked={input.checked}
                  size="tiny"
                  label={t('plant_cultivation.herbs.perennial')}
                  onChange={e =>
                    input.onChange(e.target.checked ? PLANT_CULTIVATION.perennial : null)
                  }
                />
              )}
            </Field>
          </div>
        </div>

        {(validationError?.herbInput?.herb === name ||
          validationError?.herbs_cultivation_period?.herb === name) && (
          <div className="error-text margin-top-10">
            {validationError?.herbInput?.text || validationError?.herbs_cultivation_period?.text}
          </div>
        )}
      </div>
    </div>
  );
};

HerbsInputWithCheckbox.defaultProps = {
  showCoveredCultivation: true,
  showCultivationTime: false,
  isVisible: false,
  fieldName: '',
  name: '',
  initialValue: {},
  index: 0,
  values: {},
  validationError: null,
};

HerbsInputWithCheckbox.propTypes = {
  showCoveredCultivation: PropTypes.bool,
  showCultivationTime: PropTypes.bool,
  isVisible: PropTypes.bool,
  fieldName: PropTypes.string,
  name: PropTypes.string,
  index: PropTypes.number,
  initialValue: PropTypes.shape(),
  values: PropTypes.shape(),
  validationError: PropTypes.shape(),
};

export default HerbsInputWithCheckbox;
