import { useGetOrganizationQuery } from 'api/organizations';
import { useOrderTvdMutation } from 'api/tvd';
import Button from 'components/base-components/Button';
import Input from 'components/base-components/Input';
import RadioWithoutCheck from 'components/base-components/RadioWithoutCheck';
import SelectBox from 'components/base-components/SelectBox';
import OrganizationName from 'components/organisations/organization-name';
import TVDGeneration from 'components/pdf/tvd-sticker/tvd-generation.component';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import addressView from 'utils/address-view';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import React from 'react';
import { tvdLabelOptions } from 'utils/tvd-label-options';
import TVDLabelCheckbox from './shared/tvd-label-checkbox';

const TvdOrderForm = ({ tvd, setTvdForOrder }) => {
  const [order, status] = useOrderTvdMutation();
  const portalOrgId = tvd.organizationId;
  const { data: organization = {} } = useGetOrganizationQuery(portalOrgId, {
    skip: !portalOrgId,
  });

  const mainAddress = organization.addresses?.find(address => address.main === true) || {};
  const mailingAddress = organization.addresses?.find(address => address.type === 'mailing') || {};
  const addressOptions = [];

  const tvdAddress = tvd.stickerAddress || {};

  const addressMapper = { mainAddress, mailingAddress, tvdAddress };

  const orderTypes = [
    {
      name: t('tvd.order.title'),
      value: 'order',
    },
    {
      name: t('tvd.order.actions.download'),
      value: 'download',
    },
  ];

  if (Object.keys(tvdAddress).length > 0) {
    addressOptions.push({
      label: `${t('tvd.order.tvd_address')} - ${addressView(tvdAddress)}`,
      value: 'tvdAddress',
    });
  }

  if (Object.keys(mainAddress).length > 0) {
    addressOptions.push({
      label: `${t('organisation.form.attributes.main_address')}  - ${addressView(mainAddress)}`,
      value: 'mainAddress',
    });
  }

  if (Object.keys(mailingAddress).length > 0) {
    addressOptions.push({
      label: `${t('organisation.form.attributes.mailing_address')} - ${addressView(
        mailingAddress,
      )}`,
      value: 'mailingAddress',
    });
  }

  const onLabelChange = ({ event, form, label }) => {
    const selectedLabels = form.getFieldState('labels').value || [];
    const excludeMap = {
      alp: 'berg',
      berg: 'alp',
      bts_milkcow: 'bts_mothercow',
      bts_mothercow: 'bts_milkcow',
    };

    if (event.target.checked) {
      form.change(
        'labels',
        selectedLabels.filter(item => item !== excludeMap[label]).concat(label),
      );
    } else {
      form.change(
        'labels',
        selectedLabels.filter(item => item !== label),
      );
    }
  };

  const onSubmit = values => {
    const payload = new FormData();
    payload.append('quantity', values.quantity);
    return order({ id: tvd.id, payload })
      .unwrap()
      .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
  };

  const initialValues = {
    type: orderTypes[0].value,
  };

  const disabledLabels = tvdLabelOptions.filter(label => !tvd.labels.includes(label));

  return (
    <div>
      <SubmitModal
        isSuccess={status.isSuccess}
        requestId={status.requestId}
        successTitle={t('tvd.order.success_modal_title')}
        successContent={t('tvd.order.success_modal_content')}
        showError={false}
        showLoader={false}
        onSuccess={() => setTvdForOrder(null)}
      />

      <div className="tvd-order">
        <div className="tvd-order__title">{t('tvd.order.org_name')}</div>
        <div className="tvd-order__sub-title">
          <OrganizationName organizationId={tvd.organizationId} />
        </div>
        <div className="tvd-order__title">{t('tvd.order.tvd_nr')}</div>
        <div className="tvd-order__sub-title">
          {tvd.tvdStickerNumber || t('common.not_applicable')}
        </div>
        <div className="tvd-order__hr" />
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, values, form, submitting }) => {
            const isOrder = values.type === 'order';

            return (
              <form>
                <div className="tvd-order__title">{t('tvd.order.sidepanel_title')}</div>
                <div className="tvd-order__type">
                  <Field name="type">
                    {({ input }) =>
                      orderTypes.map((item, index) => (
                        <RadioWithoutCheck
                          className="tvd-order__radio-item"
                          isChecked={item.value === input.value}
                          label={item.name}
                          name={`item-${item.value}`}
                          key={`item-${item.value}`}
                          onChange={() => input.onChange(item.value)}
                          size="medium"
                        />
                      ))
                    }
                  </Field>
                </div>
                <Field name="quantity">
                  {({ input, meta }) => (
                    <Input
                      {...input}
                      error={meta?.submitError}
                      size="tiny"
                      label={t('tvd.order.amount')}
                      placeholder={t('tvd.order.enter_amount')}
                      required={true}
                      touched={!meta.dirtySinceLastSubmit}
                      disabled={!isOrder}
                    />
                  )}
                </Field>

                <Field
                  name="address"
                  initialValue={addressOptions.length > 0 && addressOptions[0].value}
                >
                  {({ input, meta }) => (
                    <SelectBox
                      size="tiny"
                      width="full"
                      label={t('tvd.list.table.address')}
                      isClearable={true}
                      options={addressOptions}
                      value={addressOptions.find(option => option.value === input.value)}
                      isDisabled={isOrder}
                      selectedValue={input.label}
                      onChange={e => input.onChange(e.value)}
                      className="tvd-order__input"
                    />
                  )}
                </Field>

                <TVDLabelCheckbox
                  title={t('tvd.order.extra_label')}
                  disabledLabels={disabledLabels}
                  tvd={tvd}
                  onChange={onLabelChange}
                />

                <div className="tvd-order__actions">
                  {isOrder ? (
                    <Button
                      label={t(`tvd.order.actions.order`)}
                      type="success"
                      size="small"
                      disabled={submitting}
                      onClick={handleSubmit}
                    />
                  ) : (
                    <TVDGeneration
                      organizationId={portalOrgId}
                      labels={values.labels}
                      address={addressMapper[values.address] || {}}
                      tvdNumber={tvd.tvdStickerNumber}
                      barcodeTvdNumber={tvd.barcodeTvdNumber}
                    />
                  )}
                  <Button
                    size="small"
                    label={t('shared.action.cancel')}
                    onClick={() => setTvdForOrder(null)}
                  />
                </div>
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};

TvdOrderForm.propTypes = {
  setTvdForOrder: PropTypes.func.isRequired,
  tvd: PropTypes.shape().isRequired,
};

export default TvdOrderForm;
