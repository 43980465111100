import React from 'react';
import PropTypes from 'prop-types';
import LanguageComponent from 'components/shared-components/language';
import { useTranslation } from 'react-i18next';
import { Header } from '../base-components/Typography';
import LoginImage from '../../assets/bio_image.png';
import LoginLogo from '../../assets/bio_logo.png';
import './welcome-page.styles.scss';

const WelcomePageComponent = ({ children, isForgotPassword, onlyChildren }) => {
  const { t } = useTranslation();

  return (
    <div className="welcome-page__wrapper grid">
      <div className="col-3 col-bleed">
        <img className="welcome-page__image" src={LoginImage} alt="LoginImage" />
      </div>
      <div className="col-9 welcome-page__form">
        <LanguageComponent className="welcome-page__language" />
        <div className="welcome-page__form--content">
          {!onlyChildren && (
            <>
              <img className="welcome-page__logo" src={LoginLogo} alt="BioSuisseLogo" />
              <Header className="welcome-page__title" weight="boldest" level={3}>
                {isForgotPassword
                  ? t('forgot_password.title_message')
                  : t('welcome_page.welcome_message')}
              </Header>
            </>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

WelcomePageComponent.defaultProps = {
  children: null,
  isForgotPassword: false,
  onlyChildren: false,
};

WelcomePageComponent.propTypes = {
  children: PropTypes.node,
  isForgotPassword: PropTypes.bool,
  onlyChildren: PropTypes.bool,
};

export default WelcomePageComponent;
