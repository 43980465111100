const addTransformToCalender = (size, id) => {
  // An unique id is passed to SingleDatePicker Component, selecting the .DayPicker class element according to the id
  const dayPickerCalender =
    document.getElementById(id) && document.getElementById(id).parentNode.nextSibling.firstChild;

  // Checking if the .DayPicker class exists in the Selected node
  const dayPickerCalendarExist =
    dayPickerCalender && dayPickerCalender.classList.contains('DayPicker');

  if (dayPickerCalendarExist) {
    const transformConstant = -7;
    const transformMultiplier = {
      tiny: 3,
      small: 2,
      large: 1,
      huge: 0,
    };

    // The .DayPicker class element is selected and the transform property is set
    dayPickerCalender.style.transform = `translate(1px, ${transformConstant *
      transformMultiplier[size]}px)`;
  }
};

export default addTransformToCalender;
