import React, { useState } from 'react';
import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';
import { useGetTVDListQuery } from 'api/tvd';
import IconButton from 'components/base-components/IconButton';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import useNavigationLinks from 'hooks/useNavigationLinks';
import SidePanel from 'components/base-components/SidePanel';
import { t } from 'i18next';
import { Link, useParams } from 'react-router-dom';
import TvdOrderForm from 'components/tvd/tvd-order-form.component';

const TvdComponent = () => {
  const [tvdForOrder, setTvdForOrder] = useState(null);
  const { id } = useParams();
  const navigationLinks = useNavigationLinks();

  const tvdParams = {
    per_page: 3,
    'organization_ids[]': [id],
  };

  const { data: tvdData } = useGetTVDListQuery(tvdParams);

  const tvdList = tvdData?.collection || [];

  return (
    <div>
      <div className="font-bold margin-bottom-20 margin-top-20">{t('producer.dashboard.tvd')}</div>
      <SidePanel
        isOpened={!!tvdForOrder}
        title={t('tvd.order.title')}
        body={tvdForOrder && <TvdOrderForm tvd={tvdForOrder} setTvdForOrder={setTvdForOrder} />}
        onClose={() => setTvdForOrder(null)}
      />
      {tvdList.length > 0 && (
        <Card className="producer__invoice--wrapper producer__invoice--header">
          <div className="producer__invoice--card">
            <div>{t('producer.dashboard.tvd_no')}</div>
            <div>{t('shared.actions')}</div>
          </div>
        </Card>
      )}
      {tvdList.map(tvd => (
        <Card className="producer__invoice--wrapper">
          <div className="producer__invoice--card">
            <div>{tvd.tvdStickerNumber}</div>
            <IconButton
              className="user__action--show"
              icon={<Icon name="sendAlt" size="small" />}
              color="tertiary"
              size="tiny"
              dataTestId="send-order"
              onClick={() => setTvdForOrder(tvd)}
            />
          </div>
        </Card>
      ))}
      {tvdList.length === 0 && (
        <div className="font-bold font-16 margin-bottom-20 producer__empty-card">
          <EmptyContentPlaceholder iconName="maf" text={t('tvd.no_tvd')} />
        </div>
      )}
      {tvdList.length > 0 && (
        <div className="margin-top-20 producer__links">
          <Link to={navigationLinks.organisationViewPage(id, 'tvd')}>
            {t('producer.dashboard.view_tvd')}
          </Link>
        </div>
      )}
    </div>
  );
};

export default TvdComponent;
