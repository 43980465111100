import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import SidePanel from 'components/base-components/SidePanel';
import { useCreateUserEducationMutation } from 'api/educations';
import Icon from 'components/base-components/Icon';
import Button from 'components/base-components/Button';
import { useTranslation } from 'react-i18next';
import EducationForm from '../form/form.component';

const CreateForm = ({ associatedUserId, disabled }) => {
  const [enableSidePannel, setEnableSidePanel] = useState(false);
  const { t } = useTranslation();

  const [createEducation] = useCreateUserEducationMutation();

  const onCreateEducation = formData => {
    return createEducation({
      payload: formData,
      queryParams: { userId: associatedUserId },
    }).unwrap();
  };

  return (
    <Fragment>
      <SidePanel
        title={t('educations.add_module')}
        closeIcon={<Icon name="close" />}
        body={
          <EducationForm
            onSave={onCreateEducation}
            onCancel={() => {
              setEnableSidePanel(false);
            }}
          />
        }
        isOpened={enableSidePannel}
        onClick={() => {}}
        onClose={() => {
          setEnableSidePanel(false);
        }}
      />
      <Button
        label={t('educations.declarations.action.add_module')}
        size="small"
        onClick={() => setEnableSidePanel(true)}
        disabled={disabled}
      />
    </Fragment>
  );
};

CreateForm.propTypes = {
  associatedUserId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CreateForm;
