import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import STARS_STATUS from 'utils/star_status';
import BioCuisineStatus from '../bio-cuisine-status.component';

const OrganisationRating = ({ organization }) => {
  const { t } = useTranslation();

  if (organization?.bioCuisineStars === 0) {
    return (
      <span className="organisation__stars-not-certified">
        {t('bio_cuisine_status.not_certified')}
      </span>
    );
  }

  if (organization?.bioCuisineStars) {
    return (
      <BioCuisineStatus
        status={STARS_STATUS[organization?.bioCuisineStars]}
        isDowngraded={organization.downgraded}
      />
    );
  }

  return t('common.not_applicable');
};

OrganisationRating.propTypes = {
  organization: PropTypes.shape().isRequired,
};

export default OrganisationRating;
