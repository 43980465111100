export const gardeningValues = (values, formData) => {
  if (values.gardening_items) {
    for (const key in values.gardening_items) {
      formData.append('production_details[gardening_items][][name]', key);
      formData.append(
        'production_details[gardening_items][][available]',
        values.gardening_items[key],
      );
    }
  }
};
