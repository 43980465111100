const getNodePosition = node => {
  const { top, left } = node.getBoundingClientRect();

  return {
    y: top,
    x: left,
  };
};

export default getNodePosition;
