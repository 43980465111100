import React from 'react';
import { useGetProductQuery } from 'api/product-declarations';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

const ProductName = ({ productId }) => {
  const { id: organizationId } = useParams();
  const { data: product = {} } = useGetProductQuery({ organizationId, productId });

  return <>{product.name}</>;
};

ProductName.propTypes = {
  productId: PropTypes.number.isRequired,
};

export default ProductName;
