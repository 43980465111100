import React, { useEffect } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Field, useField } from 'react-final-form';
import CheckBox from 'components/base-components/CheckBox';
import Input from 'components/base-components/Input';
import Icon from 'components/base-components/Icon';
import classNames from 'classnames';
import TitleWithBadge from '../title-with-badge';

const CheckboxFilter = ({
  title,
  fieldName,
  checkboxList,
  form,
  showBadge,
  setSearchInput,
  searchInput,
  isScrollable,
  selectedOptions,
  showSearchInput,
}) => {
  const count = useField(fieldName).input.value.length;

  useEffect(() => form.change(fieldName, selectedOptions), []);

  const handleCheckboxChange = (formValues, key) => {
    const isChecked = formValues.includes(key);
    const newValue = isChecked ? formValues.filter(value => value !== key) : [...formValues, key];

    form.change(fieldName, newValue);
  };

  const handleSelectAll = () => {
    const newValues = checkboxList.map(checkbox => checkbox.key);
    form.change(fieldName, newValues);
  };

  const handleResetAll = () => form.change(fieldName, []);

  const onSearch = value => {
    setSearchInput(value);
  };

  return (
    <div className="modal__content__checkbox-container">
      <TitleWithBadge title={title} count={count} showBadge={showBadge} />
      <div className="modal__content__action-buttons">
        <span onClick={handleSelectAll}>{t('shared.action.select_all')}</span>
        <span> | </span>
        <span onClick={handleResetAll}>{t('shared.action.reset')}</span>
      </div>
      {showSearchInput && (
        <Input
          className="modal__content__checkbox--search"
          placeholder={t('organisation.search_organisation')}
          preIcon={<Icon name="search" />}
          size="tiny"
          value={searchInput}
          onChange={onSearch}
        />
      )}
      <Field name={fieldName}>
        {({ input, meta }) => (
          <div className={classNames({ 'modal__content__checkbox--scrollable': isScrollable })}>
            {checkboxList.map(checkbox => (
              <CheckBox
                key={checkbox.key}
                className="modal__content__checkbox--option"
                label={checkbox.label}
                isChecked={input.value.includes(checkbox.key)}
                size="tiny"
                onChange={() => handleCheckboxChange(input.value, checkbox.key)}
              />
            ))}
          </div>
        )}
      </Field>
    </div>
  );
};

CheckboxFilter.defaultProps = {
  setSearchInput: () => {},
  searchInput: '',
  isScrollable: false,
  selectedOptions: [],
  showSearchInput: false,
};

CheckboxFilter.propTypes = {
  isScrollable: PropTypes.bool,
  searchInput: PropTypes.string,
  setSearchInput: PropTypes.func,
  form: PropTypes.node.isRequired,
  showBadge: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  checkboxList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selectedOptions: PropTypes.array,
  showSearchInput: PropTypes.bool,
};

export default CheckboxFilter;
