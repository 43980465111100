import { useGetChartaOverviewQuery } from 'api/chartas';
import { useGetControlBodyDeclarationsOverviewQuery } from 'api/control-body-declarations';
import { useGetEducationsOverviewQuery } from 'api/educations';
import { useGetActiveGoodsOverviewQuery } from 'api/goods-declarations';
import { useGetProductDeclarationsOverviewQuery } from 'api/product-declarations';
import { useGetSupplierCertificatesOverviewQuery } from 'api/supplier-certificates';
import classNames from 'classnames';
import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import NegativeReportsHeader from '../header.component';

// It will be replaced with shared component
const ContractualStatus = ({ organizationId, date }) => {
  const { data: chartaOverview = {} } = useGetChartaOverviewQuery({ organizationId });
  const { data: controlBodyOverview = {} } = useGetControlBodyDeclarationsOverviewQuery({
    organizationId,
  });
  const { data: certificatesOverview = {} } = useGetSupplierCertificatesOverviewQuery({
    organizationId,
  });
  const { data: educationsOverview = {} } = useGetEducationsOverviewQuery({
    organizationId,
  });
  const { data: productDeclarationOverview = {} } = useGetProductDeclarationsOverviewQuery({
    organizationId,
  });
  const { data: activeGoodsOverview = {} } = useGetActiveGoodsOverviewQuery({
    organizationId,
  });

  const statuses = [
    {
      label: t('control_body.survey.shared.contract_status_header.charta'),
      value: chartaOverview?.status,
    },
    {
      label: t('control_body.survey.shared.contract_status_header.education'),
      value: educationsOverview?.status,
    },
    {
      label: t('control_body.survey.shared.contract_status_header.control_body'),
      value: controlBodyOverview?.status,
    },
    {
      label: t('control_body.survey.shared.contract_status_header.supplier'),
      value: certificatesOverview?.status,
    },
    {
      label: t('control_body.survey.shared.contract_status_header.products'),
      value: productDeclarationOverview?.status,
    },
    {
      label: t('control_body.survey.shared.contract_status_header.goods'),
      value: activeGoodsOverview?.status,
    },
  ];

  const dueDate = moment(date).add(14, 'days');

  return (
    <div className="negative-reports__container">
      <Card>
        <NegativeReportsHeader
          status={null}
          text={t('negative_report.contract_status_fulfilled')}
          date={date}
          dueDate={dueDate}
        />

        <div className="grid">
          <div className="negative-reports__left-panel">
            <div>
              {statuses.map(status => (
                <ul className="organisation-checklist__condition-list">
                  <li>
                    <div className="organisation-checklist__condition-wrapper">
                      <div className="organisation-checklist__condition-text">{status.label}</div>
                      <Icon
                        name="check"
                        size="tiny"
                        showBGColor={true}
                        className={classNames('status__step-icon', {
                          'status__step-icon--disabled': status.value === null,
                          'status__step-icon--submitted':
                            status.value === 'submitted' ||
                            status.value === 'draft' ||
                            status.value === 'complained' ||
                            status.value === 'new_declaration',
                          'status__step-icon--success':
                            status.value === 'approved' || status.value === 'active',
                          'status__step-icon--expired': status.value === 'expired',
                        })}
                      />
                    </div>
                  </li>
                </ul>
              ))}
            </div>
          </div>
          <div className="negative-reports__right-panel">
            <ul className="negative-reports__item-list">
              <li>{t('negative_report.list_1')}</li>
              <li>{t('negative_report.list_2')}</li>
            </ul>
          </div>
        </div>
      </Card>
    </div>
  );
};

ContractualStatus.propTypes = {
  organizationId: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
};

export default ContractualStatus;
