import routes from 'utils/routes';
import { CHARTA, ORGANISATION_OVERVIEW } from 'utils/tag-types';
import { apiSlice } from '../index';

const chartasApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getChartaItems: build.query({
      query: params => routes.chartas.items.collection(params),
      providesTags: [CHARTA],
    }),
    getChartaOverview: build.query({
      query: params => routes.chartas.overview(params),
      providesTags: [CHARTA],
    }),
    getActiveCharta: build.query({
      query: params => routes.chartas.active(params),
      providesTags: [CHARTA],
    }),
    getWipCharta: build.query({
      query: params => routes.chartas.wip(params),
      providesTags: [CHARTA],
    }),
    createCharta: build.mutation({
      query: params => ({
        url: routes.chartas.collection(params),
        method: 'POST',
      }),
      invalidatesTags: [CHARTA],
    }),
    declareCharta: build.mutation({
      query: params => ({
        url: routes.chartas.declare(params),
        method: 'PUT',
      }),
      invalidatesTags: [CHARTA, ORGANISATION_OVERVIEW],
    }),
    acceptChartaItem: build.mutation({
      query: params => ({
        url: routes.chartas.items.accept(params),
        method: 'PUT',
      }),
      invalidatesTags: [CHARTA],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetChartaItemsQuery,
  useGetActiveChartaQuery,
  useGetWipChartaQuery,
  useAcceptChartaItemMutation,
  useDeclareChartaMutation,
  useCreateChartaMutation,
  useGetChartaOverviewQuery,
} = chartasApi;
