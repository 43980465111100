import { useGetOtherFarmItemQuery } from 'api/producers/productions';
import { t } from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import SharedProductionComponent from './shared/shared-production.component';

const AnimalComponent = ({ id }) => {
  const { data: otherFarmProductionData } = useGetOtherFarmItemQuery(
    { productionId: id },
    { skip: !id },
  );

  const animalData = otherFarmProductionData?.productionDetails
    ? toSnakecaseKeys(otherFarmProductionData.productionDetails)
    : [];

  const filteredAnimalData = Object.keys(animalData)?.filter(key => !!animalData[key]);

  return (
    <SharedProductionComponent title={t('plant_cultivation.tab.animal')} icon="animal">
      <div className="margin-top-10">
        {filteredAnimalData.map(value => (
          <div className="margin-top-10">{t(`operations.animals.${value}`)}</div>
        ))}
      </div>
    </SharedProductionComponent>
  );
};

AnimalComponent.defaultProps = {
  id: null,
};

AnimalComponent.propTypes = {
  id: PropTypes.node,
};

export default AnimalComponent;
