import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useGetUsersQuery } from 'api/users';
import { debounce } from 'throttle-debounce';
import SelectBox from 'components/base-components/SelectBox';

const SearchUser = ({
  skip,
  value,
  onChange,
  paramsToSearch,
  label,
  placeholderText,
  className,
}) => {
  const [query, setQuery] = useState('');
  const userParams = { ...paramsToSearch, q: query };
  const { data } = useGetUsersQuery(userParams, { skip: skip });
  const users = data ? data.users : [];

  const userOptions = users.map(user => ({ label: user.name, value: user.id }));

  const onSearchUser = debounce(500, value => {
    setQuery(value);
  });

  return (
    <SelectBox
      className={className}
      onInputChange={onSearchUser}
      isSearchable={true}
      size="tiny"
      width="large"
      label={label}
      placeholderText={placeholderText}
      required={true}
      isClearable={false}
      options={userOptions}
      value={value}
      onChange={user => onChange(user)}
    />
  );
};

SearchUser.defaultProps = {
  skip: false,
  value: null,
  paramsToSearch: {},
  className: null,
};

SearchUser.propTypes = {
  skip: PropTypes.bool,
  value: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
  paramsToSearch: PropTypes.shape(),
  label: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SearchUser;
