/* eslint-disable react-hooks/exhaustive-deps */
import {
  useApproveProductDeclarationsMutation,
  useGetProductDeclarationsQuery,
  useSubmitProductDeclarationMutation,
} from 'api/product-declarations';
import { useGetCurrentUserQuery } from 'api/users';
import Button from 'components/base-components/Button';
import EmphasisTag from 'components/base-components/EmphasisTag';
import Spinner from 'components/base-components/Spinner';
import ApprovalConfirmationModal from 'components/shared-components/ApprovalModal/approval-confirmation-modal.component';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import WarningModal from 'components/shared-components/modal/warning';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import useCustomNavigate from 'hooks/useCustomNavigate';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import allEntitiesInExpectedStatus from 'utils/all-entities-in-expected-status';
import atLeastOneEntitiesInExpectedStatus from 'utils/at-least-one-entities-in-expected-status';
import { dateMonthYearFormatter } from 'utils/date-time-formatter';
import { COMPLAINED, SUBMITTED } from 'utils/declaration-status';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import isControlBodyMember from 'utils/is-control-body-member';
import { PRODUCT_DECLARATION_STATUS } from 'utils/statuses';
import ProductComplaint from '../complaints/product-complaint.component';
import ProductTable from '../product-table/product-table.component';

const DraftProductDeclarations = ({ organizationId }) => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { data: currentUser } = useGetCurrentUserQuery();
  const [isSidepanelOpen, setIsSidepanelOpen] = useState(false);
  const [editableProduct, setEditableProduct] = useState(null);
  const [isDeclareModalOpen, setIsDeclareModalOpen] = useState(false);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [submitProductDeclarations, submitStatus] = useSubmitProductDeclarationMutation();
  const [approveProductDeclarations, approveStatus] = useApproveProductDeclarationsMutation();

  const { isFetching, data } = useGetProductDeclarationsQuery({
    organizationId,
    type: PRODUCT_DECLARATION_STATUS.draft,
  });

  const draftDeclarations = data ? data.collection : [];
  const isAdmin = isBioSuisseAdmin(currentUser);

  const hasItems = draftDeclarations && draftDeclarations.length > 0;
  const hasFiveItems = hasItems && draftDeclarations.length === 5;
  const submittedOn = hasItems && draftDeclarations.find(item => !!item.submittedOn)?.submittedOn;

  const isSubmitted = allEntitiesInExpectedStatus(draftDeclarations, SUBMITTED);
  const isComplained = atLeastOneEntitiesInExpectedStatus(draftDeclarations, COMPLAINED);

  const tag = useMemo(() => {
    if (isComplained) {
      return (
        <EmphasisTag
          type="warning"
          size="tiny"
          text={t('product_declarations.status.complained')}
        />
      );
    }

    if (isSubmitted) {
      return (
        <EmphasisTag type="accent" size="tiny" text={t('product_declarations.status.submitted')} />
      );
    }
  }, [isComplained, isSubmitted]);

  const actions = useMemo(() => {
    if (!currentUser) return null;

    if (isAdmin) {
      return (
        <>
          <div className="product-declarations__header-actions-complain">
            <ProductComplaint
              organizationId={organizationId}
              isDisabled={!isSubmitted && !isComplained}
            />
          </div>
          <Button
            label={t('product_declarations.verify')}
            size="small"
            onClick={() => setIsVerifyModalOpen(true)}
            type="success"
            disabled={isComplained || !isSubmitted}
          />
        </>
      );
    }
    if (isComplained) {
      return (
        <>
          <div className="product-declarations__header-message">
            {t('product_declarations.messages.redeclare')}
          </div>
          <Button
            label={t('product_declarations.declare_products')}
            size="small"
            disabled={!hasFiveItems}
            onClick={() => setIsDeclareModalOpen(true)}
            type="success"
          />
        </>
      );
    }

    return (
      <>
        <div className="product-declarations__header-message">
          {isSubmitted && t('product_declarations.messages.submited')}
          {!hasFiveItems && t('product_declarations.messages.five_products_required')}
        </div>

        <Button
          onClick={() => setIsDeclareModalOpen(true)}
          type="success"
          label={t('product_declarations.declare_products')}
          size="small"
          disabled={isSubmitted || !hasFiveItems}
        />
      </>
    );
  }, [currentUser && currentUser.id, isComplained, isSubmitted, hasFiveItems]);

  return (
    <div className="product-declarations">
      <WarningModal
        isVisible={isDeclareModalOpen}
        onConfirm={() => {
          submitProductDeclarations(organizationId);
          setIsDeclareModalOpen(false);
        }}
        onCancel={() => setIsDeclareModalOpen(false)}
        title={t('product_declarations.modal.declare_confirmation.title')}
        content={t('product_declarations.modal.declare_confirmation.content')}
        confirmActionText={t('product_declarations.declare_products')}
        cancelActionText={t('shared.action.cancel')}
      />

      <SubmitModal
        isSuccess={submitStatus.isSuccess}
        requestId={submitStatus.requestId}
        successTitle={t('product_declarations.modal.declare_successful.title')}
        successContent={t('product_declarations.modal.declare_successful.content')}
        showError={false}
        showLoader={false}
        onSuccess={() => navigate.openOrganisationDetailsPage(organizationId)}
      />

      <ApprovalConfirmationModal
        organizationId={organizationId}
        isModalOpen={isVerifyModalOpen}
        setIsModalOpen={setIsVerifyModalOpen}
        onConfirm={() => {
          approveProductDeclarations({ organizationId });
          setIsVerifyModalOpen(false);
        }}
        title={t('product_declarations.modal.verify_confirmation.title')}
        content={t('product_declarations.modal.verify_confirmation.content')}
        confirmActionText={t('product_declarations.verify')}
        cancelActionText={t('shared.action.cancel')}
      />

      <SubmitModal
        isSuccess={approveStatus.isSuccess}
        requestId={approveStatus.requestId}
        successTitle={t('product_declarations.modal.verification_successful.title')}
        successContent={t('product_declarations.modal.verification_successful.content')}
        showError={false}
        showLoader={false}
      />

      <div className="product-declarations__header">
        <div>
          <div className="product-declarations__header--title">
            {t('product_declarations.draft_declaration')} {tag}
          </div>
          <div className="product-declarations__header--subtitle">
            {submittedOn ? (
              <>
                {t('product_declarations.messages.submitted_on')}{' '}
                <span className="product-declarations__header-text--bold">
                  {dateMonthYearFormatter(submittedOn)}
                </span>
              </>
            ) : (
              !isAdmin && t('product_declarations.messages.you_can_prepare')
            )}
          </div>
        </div>
        <div className="product-declarations__header-actions">{actions}</div>
      </div>
      <div className="product-declarations__content">
        {isFetching ? (
          <Spinner
            className="product-declarations__spinner"
            bgColor="none"
            color="success"
            size="small"
          />
        ) : (
          <>
            <ProductTable
              data={draftDeclarations}
              isSidepanelOpen={isSidepanelOpen}
              setIsSidepanelOpen={setIsSidepanelOpen}
              editableProduct={editableProduct}
              setEditableProduct={setEditableProduct}
              organizationId={organizationId}
              showActions={!isAdmin}
            />
            {draftDeclarations?.length === 0 && (
              <EmptyContentPlaceholder
                className="product-declarations__empty-draft"
                iconName="neutralOutline"
                text={t('shared.placeholder.no_draft')}
              />
            )}
            {!isAdmin && (
              <Button
                label={t('product_declarations.add_product')}
                size="small"
                disabled={hasFiveItems || isControlBodyMember(currentUser)}
                onClick={() => {
                  setEditableProduct(null);
                  setIsSidepanelOpen(true);
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

DraftProductDeclarations.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export default DraftProductDeclarations;
