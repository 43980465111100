import {
  useGetOrganizationRegistryQuery,
  useValidateOrganizationMutation,
} from 'api/organizations';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import Stepper from 'components/base-components/stepper';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import arrayMutators from 'final-form-arrays';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { PRODUCER } from 'utils/organisation-industries.js';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';
import ADDRESS_TYPE from 'utils/address-type';
import { MOBILE, PHONE } from 'utils/contact_type';
import { groupBy } from 'utils/groupBy';
import {
  CONTACT_AND_ADDRESS,
  FARMLAND,
  GENERAL,
  LEGAL_FORM,
  MEMBERSHIPS,
  PRODUCTION_DATA,
  TVD,
} from 'utils/organisation-create-options';
import classNames from 'classnames';
import { useParams, useSearchParams } from 'react-router-dom';
import CheckBox from 'components/base-components/CheckBox';
import WarningModal from 'components/shared-components/modal/warning';
import { GASTRONOMY } from 'utils/organisation-industries';
import { isNonEmptyObject } from 'utils/check-object-length';
import ORGANISATION_TYPE, { CONTROL_BODY } from 'utils/organisation-type';
import { removeEmptyFromObject } from 'utils/object-cleanup';
import GeneralForm from '../form/general.component';
import BioCusineLegalForm from './bio-cuisine/legal.component';
import ProducerLegalForm from './producer/legal-form/index.component';
import ContactAndAddressForm from './contact-and-address/index.component';
import ControlBodyForm from './producer/control-body.component';
import FarmlandWrapperForm from './producer/farmland/index.component';
import Memberships from './producer/membership/index.component';
import { milksTypes } from './producer/milk/milk-types.component';
import ProductionCultivation from './producer/production-cultivation.component';
import { addressValues } from './producer/submit-form/address';
import { beeValues } from './producer/submit-form/bee';
import { eggValues } from './producer/submit-form/egg';
import { farmlandValues } from './producer/submit-form/farmland';
import { fishValues } from './producer/submit-form/fish';
import { gardeningValues } from './producer/submit-form/gardening';
import { greenAreaValues } from './producer/submit-form/green-areas';
import { InitialValuesdata } from './producer/submit-form/initial-value';
import { meatValues } from './producer/submit-form/meat';
import { membershipValues } from './producer/submit-form/membership';
import { milkValues } from './producer/submit-form/milk';
import { plantValues } from './producer/submit-form/plants';
import { privateParkValues } from './producer/submit-form/private-park';
import { subOrganisationValues } from './producer/submit-form/sub-organisation';
import TVDinProducer from './producer/tvd/index.component';
import { tvdSticker } from './producer/submit-form/tvd-sticker';
import { legalFormValues } from './producer/submit-form/legal-form';
import getFormData from './helpers/getFormData';
import getValidationErrors from './helpers/getValidationErrors';
import validateFormFields from './helpers/validateFormFields';
import handleMoveToNextStep from './helpers/handleMoveToNextStep';
import getFieldValidationErrors from './helpers/getFieldValidationErrors';
import '../organisations.styles.scss';

const OrganizationForm = props => {
  const { onSave, department, organization, isLoading, status, industry, isCreateForm } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [validationError, setValidationError] = useState({});
  const [stepStates, setStepStates] = useState([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isNextStepDisabled, setNextStepDisabled] = useState(false);
  const [uidSearchQuery, setUidSearchQuery] = useState('');
  const [triggerStepperProductionTabs, setTriggerStepperProductionTabs] = useState(false);
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const [isDraft, setIsDraft] = useState(false);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  let navigateToListPage;
  const currentTab = searchParams.get('tab');

  if (!industry) {
    navigateToListPage = navigate.openControlBodyPage;
  } else if (industry === PRODUCER) {
    navigateToListPage = navigate.openProducersPage;
  } else {
    navigateToListPage = navigate.openRestaurantsPage;
  }

  const [validateOrganization] = useValidateOrganizationMutation();

  useEffect(() => {
    handleMoveToNextStep(
      validationError,
      activeIndex,
      setActiveIndex,
      setNextStepDisabled,
      setStepStates,
      stepStates,
      department,
      currentTab,
      triggerStepperProductionTabs,
      TAB_MAPPER,
    );
  }, [validationError, currentTab]);

  const { data: organizationRegistryResponse, isFetching: isFetchingOrganizationRegistry } =
    useGetOrganizationRegistryQuery({ uid: uidSearchQuery }, { skip: !uidSearchQuery });

  const { addressLine1, addressLine2, ...organizationRegistryDetails } =
    organizationRegistryResponse || {};

  const organizationRegistry = organizationRegistryResponse
    ? {
        line1: addressLine1,
        line2: addressLine2,
        ...organizationRegistryDetails,
        country: {
          label: organizationRegistryResponse.country.name,
          value: organizationRegistryResponse.country.code,
        },
      }
    : {};

  const TAB_MAPPER = {
    control_body: {
      0: GENERAL,
      1: CONTACT_AND_ADDRESS,
    },
    gastronomy: {
      0: GENERAL,
      1: LEGAL_FORM,
      2: CONTACT_AND_ADDRESS,
    },
    producer: {
      0: GENERAL,
      1: LEGAL_FORM,
      2: CONTACT_AND_ADDRESS,
      3: CONTROL_BODY,
      4: FARMLAND,
      5: PRODUCTION_DATA,
      6: TVD,
      7: MEMBERSHIPS,
    },
  };

  useEffect(() => {
    handleMoveToNextStep(
      validationError,
      activeIndex,
      setActiveIndex,
      setNextStepDisabled,
      setStepStates,
      stepStates,
      department,
      currentTab,
      triggerStepperProductionTabs,
      TAB_MAPPER,
    );
  }, [validationError, currentTab]);

  const COMPONENT_FORM = {
    general: (
      <GeneralForm
        organization={organization}
        isFetchingOrganizationRegistry={isFetchingOrganizationRegistry}
        setUidSearchQuery={setUidSearchQuery}
        organizationRegistry={organizationRegistry}
        validationError={validationError}
      />
    ),
    contact_and_address: (
      <ContactAndAddressForm
        organizationRegistry={organizationRegistry}
        department={department}
        validationError={validationError}
      />
    ),
    legal_form:
      department === GASTRONOMY ? (
        <BioCusineLegalForm
          uidSearchQuery={uidSearchQuery}
          organizationRegistryResponse={organizationRegistryResponse}
          parentOrganizationId={organization?.parentId}
          validationError={validationError}
        />
      ) : (
        <ProducerLegalForm validationError={validationError} />
      ),
    control_body: <ControlBodyForm validationError={validationError} />,
    farmland: <FarmlandWrapperForm validationError={validationError} />,
    production_data: (
      <ProductionCultivation
        validationError={validationError}
        triggerStepperProductionTabs={triggerStepperProductionTabs}
        setTriggerStepperProductionTabs={setTriggerStepperProductionTabs}
      />
    ),
    memberships: <Memberships />,
    tvd: (
      <TVDinProducer
        tvdStickerEnabled={organization?.tvdStickerEnabled}
        validationError={validationError}
      />
    ),
  };

  const onMoveNextOrPrevious = index => {
    if (TAB_MAPPER[department][activeIndex] === PRODUCTION_DATA) {
      setTriggerStepperProductionTabs('previous');
    } else {
      setActiveIndex(index);
    }
  };

  const onMoveNext = values => {
    let tab;

    if (TAB_MAPPER[department][activeIndex] === FARMLAND) {
      const filteredProductionData = values?.production?.filter(data => data !== null);
      tab = filteredProductionData[0];
    }

    if (TAB_MAPPER[department][activeIndex] === PRODUCTION_DATA) {
      tab = currentTab;
    } else {
      setNextStepDisabled(true);
    }
    const formData = getFormData(values, department, organization, industry);

    validateOrganization(formData)
      .unwrap()
      .then(() => {
        const errorMessages = removeEmptyFromObject(
          getFieldValidationErrors(activeIndex, values, tab, department, TAB_MAPPER),
        );

        if (isNonEmptyObject(errorMessages)) {
          setValidationError(errorMessages);
        } else {
          setTriggerStepperProductionTabs('next');
          setValidationError({});
          return null;
        }
      })
      .catch(({ status, data }) => {
        const errors = data ? data.errors : null;
        if (status === 404) {
          const errorMessages = getFieldValidationErrors(
            activeIndex,
            values,
            tab,
            department,
            TAB_MAPPER,
          );

          if (
            TAB_MAPPER[department][activeIndex] === PRODUCTION_DATA &&
            !isNonEmptyObject(errorMessages)
          ) {
            setTriggerStepperProductionTabs('next');
          }
          setValidationError(errorMessages);
        } else {
          const errorMessages = getValidationErrors(
            status,
            errors,
            activeIndex,
            values,
            department,
            TAB_MAPPER,
          );
          setValidationError(errorMessages);
        }
      });
  };

  const phoneNumberByType = groupBy(organization?.phoneNumbers || [], 'numberType');

  const onSubmit = (values, form) => {
    let formData = new FormData();
    const parentOrganization = values.parentOrganisation;

    farmlandValues(values, formData);
    legalFormValues(values, formData, id);
    plantValues(values, formData);
    meatValues(values, formData);
    milkValues(values, formData, milksTypes);
    gardeningValues(values, formData);
    eggValues(values, formData);
    beeValues(values, formData);
    membershipValues(values, formData);
    tvdSticker(values, formData);
    fishValues(values, formData);
    greenAreaValues(values, formData);
    privateParkValues(values, formData);

    if (organization) {
      (values.removedProductionTypes || []).forEach(productType => {
        formData.append('production_details[remove_details][]', productType);
      });

      Object.keys(values.removedSubTypes || {}).forEach(productType => {
        if (values.removedSubTypes[productType].length > 0) {
          formData.append('production_details[remove_sub_types][][main_type]', productType);
        }

        values.removedSubTypes[productType].forEach(subType => {
          formData.append('production_details[remove_sub_types][][sub_types][]', subType);
        });
      });
    }

    if (values?.otherFarms) {
      for (const key in values.otherFarms) {
        if (values.otherFarms[key]) {
          formData.append('production_details[other_farms][animal_types][]', key);
        }
      }
    }

    let params = {
      additional_name: values.additionalName,
      name: values.registeredName,
      industry: (values.industry && values.industry.value) || null,
      language: values.language,
      second_spoken_language: values.secondSpokenLanguage,
      parent_id: parentOrganization?.value,
      control_body_organization_id: values.control_body_organization_id,
    };

    subOrganisationValues(values, formData, department);

    addressValues(values, formData, phoneNumberByType);
    // TODO: needs to remove after implementing legal tab for producer
    formData.append('type', values.organisationType || 'standalone');
    formData.append('second_spoken_language', values.secondSpokenLanguage || '');

    formData.append('draft', values.draft);
    setIsDraft(values.draft);

    if (!organization) {
      params['uid'] = values.uid || null;
    }

    const logo = values.logo;

    if (logo) {
      params = { ...params, logo };
    }

    formData.append('is_invoice_recipient', !!values.receiveInvoice);

    Object.keys(params).forEach(key => {
      if (params[key]) {
        formData.append(key, params[key]);
      }
    });

    return onSave(formData)
      .then(() => {
        return null;
      })
      .catch(({ status, data: { errors } }) => {
        if (status === 422) {
          const generalErrors = errors.organization || errors;
          let errorMessages = {
            uid: generalErrors.uid,
            industry: generalErrors.industry,
            registeredName: generalErrors.name,
            secondSpokenLanguage: generalErrors.secondSpokenLanguage,
            customerSince: generalErrors.customerSince,
          };

          errorMessages['subOrganizations'] = errors?.subOrganizations;

          if (errors.addresses) {
            errors.addresses.forEach(address => {
              const errorAttributes = {
                zipCode: address.zipCode,
                city: address.city,
                country: address.countryCode,
                line1: address.line1,
                canton: address.canton,
              };

              if (address.main) {
                errorMessages = { ...errorMessages, mainAddress: errorAttributes };
              } else if (address.type === ADDRESS_TYPE.invoice) {
                errorMessages = { ...errorMessages, billingAddress: errorAttributes };
              } else if (address.type === ADDRESS_TYPE.mailing) {
                errorMessages = { ...errorMessages, mailingAddress: errorAttributes };
              }
            });
          }

          if (errors.productions) {
            errorMessages = {
              ...errorMessages,
              agriculturalLandInHectare: errors.productions.agriculturalLandInHectare,
              greenlandInHectare: errors.productions.greenlandInHectare,
              openCultivatedLandInHectare: errors.productions.openCultivatedLandInHectare,
              dgve: errors.productions.dgve,
            };
          }

          const productionDataErrors = {
            numberOfBeeColonies: errors['productionDetails[beeHive][numberOfColonies]'],
            quantityOfHoney: errors['productionDetails[beeHive][honeyProducedKg]'],
            milkProducedKg: errors['productionDetails[dairyFarms][milkProducedKg]'],
            numberOfAnimals: errors['productionDetails[dairyFarms][numberOfAnimals]'],
            organicMilkProducerOrganization: errors.organicMilkProducerOrganization,
            firstMilkBuyer: errors.firstMilkBuyer,
          };

          errorMessages = {
            ...errorMessages,
            productionDataErrors,
          };

          if (errors?.tvdStickerNumbers) {
            errorMessages = { ...errorMessages, tvdStickerNumbers: errors.tvdStickerNumbers };
          }

          if (errors.phoneNumbers) {
            const errorByType = groupBy(errors.phoneNumbers, 'type');

            errorMessages = {
              ...errorMessages,
              phone: errorByType[PHONE]?.at(0)?.number,
              MOBILE: errorByType[MOBILE]?.at(0)?.number,
            };
          }

          return errorMessages;
        }
        return errors;
      });
  };

  const handleSubmit = (values, form) => onSubmit(values, form);

  const successModalTitle = () => {
    if (isDraft) return t(`shared.modal.draft_progress.title`);
    if (organization) return t(`shared.modal.organization_edit_department.${department}.title`);
    return t(`shared.modal.organization_create_department.${department}.title`);
  };

  const successModalContent = () => {
    if (isDraft) return t(`shared.modal.draft_progress.content`);
    if (organization) return t(`shared.modal.organization_edit_department.${department}.content`);
    return t(`shared.modal.organization_create_department.${department}.content`, {
      bioId: status?.data?.bioId,
    });
  };

  if (isLoading) {
    return <ContentLoaderPlaceholder numberOfLines={15} showBackground={false} />;
  }

  const errorModalContent = () => {
    if (status.error?.status === 422) return t(`shared.modal.organization_error.422.content`);

    return t(`shared.modal.organization_error.500.content`);
  };

  const getInitialValue = () => {
    if (isCreateForm) {
      return {
        organisationType:
          department === CONTROL_BODY ? CONTROL_BODY : ORGANISATION_TYPE.single_organisation,
      };
    }

    return InitialValuesdata(organization, isLoading, phoneNumberByType);
  };

  return (
    <div className="organisation__form">
      <Form
        onSubmit={handleSubmit}
        initialValues={getInitialValue()}
        validate={values =>
          validateFormFields(values, activeIndex, setNextStepDisabled, validationError)
        }
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, values, submitErrors, form }) => (
          <Fragment>
            <Stepper
              activeStepIndex={activeIndex}
              stepStates={stepStates}
              showError={true}
              direction="horizontal"
              nextButtonLabel={t('shared.action.next_step')}
              onMoveBack={onMoveNextOrPrevious}
              onMoveNext={() => onMoveNext(values)}
              previousButtonLabel={t('shared.action.previous_step')}
              showActionButtons={true}
              disableMoveForward={isNextStepDisabled}
              showStepTitle={true}
              doneAllStep={false}
              hideStepsOnScroll={true}
              steps={
                department &&
                Object.values(TAB_MAPPER[department]).map(label => ({
                  stepName: t(`organisation.form.step.${label}`),
                }))
              }
              isOrganizationStepper={true}
              isLastStepAccepted={false}
              additionalElement={
                <div className="organisation__form-stepper-actions">
                  <Field name="draft" type="hidden" component="input" />
                  <Button
                    label={
                      id
                        ? t('organisation_view.edit_organisation_button')
                        : t('shared.action.save_&_create')
                    }
                    type="success"
                    disabled={
                      isCreateForm
                        ? industry === PRODUCER
                          ? activeIndex !== 7
                          : industry === GASTRONOMY
                          ? activeIndex !== 2
                          : activeIndex !== 1
                        : false
                    }
                    size="small"
                    onClick={() => {
                      form.change('draft', false);
                      if (department === PRODUCER && !id) {
                        setIsConfirmationModalOpen(true);
                      } else {
                        handleSubmit();
                      }
                    }}
                  />
                  <Button
                    label={t('shared.action.save_&_go_back')}
                    size="small"
                    onClick={() => {
                      form.change('draft', true);
                      handleSubmit();
                    }}
                    disabled={values?.mainContact === undefined || values?.mainContact === null}
                  />
                  <Button
                    label={t('shared.action.cancel')}
                    size="small"
                    onClick={navigateToListPage}
                  />
                </div>
              }
            />
            <div
              className={classNames('organisation__form-container', {
                'organisation__form-container--no-shadow':
                  TAB_MAPPER[department][activeIndex] === PRODUCTION_DATA,
              })}
            >
              {department && (
                <Card className="organisation__form--main-card">
                  {COMPONENT_FORM[TAB_MAPPER[department][activeIndex]]}
                </Card>
              )}
            </div>
            <WarningModal
              onOutsideClick={() => setIsConfirmationModalOpen(false)}
              isVisible={isConfirmationModalOpen}
              title={t('organisation.form.modal.create_confirmation.title')}
              content={
                <div className="organisation__security-modal">
                  <div className="organisation__security-modal--first-description">
                    {t('organisation.form.modal.create_confirmation.description1')}
                  </div>
                  <div className="organisation__security-modal--second-description">
                    <Trans
                      i18nKey="organisation.form.modal.create_confirmation.description2"
                      components={{
                        anchor: (
                          <a
                            href="https://www.bio-suisse.ch/de/info/datenschutz/vertrag.html"
                            target="_blank"
                            rel="noreferrer"
                            className="organisation__form-sub-label--color"
                          >
                            <i aria-hidden="true"></i>
                          </a>
                        ),
                      }}
                    />
                  </div>
                  <Field name="acceptTermsAndPolicies" type="checkbox">
                    {({ input }) => (
                      <CheckBox
                        className="organisation__security-modal--checkbox"
                        isChecked={input.checked}
                        size="tiny"
                        label={t('organisation.form.modal.create_confirmation.description3')}
                        onChange={e => input.onChange(e.target.checked)}
                      />
                    )}
                  </Field>
                </div>
              }
              iconName="check"
              iconColor="success"
              confirmActionText={t('shared.action.submit')}
              cancelActionText={t('shared.action.cancel')}
              onCancel={() => setIsConfirmationModalOpen(false)}
              onConfirm={() => {
                setIsConfirmationModalOpen(false);
                handleSubmit();
              }}
              isDisabled={!values?.acceptTermsAndPolicies}
            />
          </Fragment>
        )}
      />

      <SubmitModal
        isLoading={status.isLoading}
        isSuccess={status.isSuccess}
        requestId={status.requestId}
        isError={status.isError}
        errorTitle={t(`shared.modal.organization_error.title`)}
        errorContent={errorModalContent()}
        successTitle={successModalTitle()}
        successContent={successModalContent()}
        onSuccess={navigateToListPage}
        showError={true}
      />
    </div>
  );
};

OrganizationForm.defaultProps = {
  isLoading: false,
  isSuccess: false,
  organization: null,
  actionLabel: null,
  status: {},
  onSave: () => {},
  isCreateForm: false,
};

OrganizationForm.propTypes = {
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  actionLabel: PropTypes.string,
  organization: PropTypes.shape(),
  status: PropTypes.shape(),
  onSave: PropTypes.func,
  department: PropTypes.string.isRequired,
  bioId: PropTypes.string.isRequired,
  industry: PropTypes.string.isRequired,
  isCreateForm: PropTypes.bool,
};

export default OrganizationForm;
