import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TableBody = ({ className, children }) => (
  <tbody className={classNames('table__body', className)}>{children}</tbody>
);

TableBody.defaultProps = {
  className: null,
};

TableBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default TableBody;
