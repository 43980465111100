import { useDeactivateUserMutation, useGetUserQuery, useReactivateUserMutation } from 'api/users';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import WarningModal from 'components/shared-components/modal/warning';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { USERS_STATUS } from 'utils/statuses';

const ManageUserComponent = () => {
  const { t } = useTranslation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { userId } = useParams();
  const [deactivateUser] = useDeactivateUserMutation();
  const [reactivateUser] = useReactivateUserMutation();

  const { data: user = {} } = useGetUserQuery({ id: userId });

  const handleDeactivateUser = () => {
    const functionToChangeStatus =
      user.status === USERS_STATUS.active ? deactivateUser : reactivateUser;

    functionToChangeStatus(userId)
      .unwrap()
      .then(() => setIsConfirmModalOpen(false));
  };

  return (
    <>
      <Card>
        <div className="user__manage-header">{t('user_edit.deactivate_user')}</div>
        <div className="user__manage-description">{t('user_edit.deactivate_rule')} </div>
        <Button
          type={user?.status === USERS_STATUS.active ? 'danger' : 'success'}
          size="small"
          label={
            user?.status === USERS_STATUS.active
              ? t('user_details.deactivate_user')
              : t('user_details.activate_user')
          }
          onClick={() => setIsConfirmModalOpen(true)}
        />
      </Card>
      <WarningModal
        onOutsideClick={() => setIsConfirmModalOpen(false)}
        isVisible={isConfirmModalOpen}
        title={
          user?.status === USERS_STATUS.active
            ? t('user_edit.modal.title')
            : t('user_edit.modal.active_title')
        }
        content={
          user?.status === USERS_STATUS.active
            ? t('user_edit.modal.content')
            : t('user_edit.modal.active_content')
        }
        confirmActionText={
          user?.status === USERS_STATUS.active
            ? t('shared.action.deactivate')
            : t('shared.action.activate')
        }
        cancelActionText={t('shared.action.cancel')}
        onCancel={() => setIsConfirmModalOpen(false)}
        onConfirm={handleDeactivateUser}
      />
    </>
  );
};

export default ManageUserComponent;
