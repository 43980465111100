import React from 'react';
import SetPasswordForm from 'components/password-management/set-password-form';
import { useSetPasswordMutation } from 'api/password-management';

const SetPassword = () => {
  const [setPassword, { isSuccess }] = useSetPasswordMutation();

  return <SetPasswordForm onSave={setPassword} isSuccess={isSuccess} />;
};

export default SetPassword;
