import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';

const Card = props => {
  const {
    imageSrc,
    size,
    dark,
    transparent,
    actions,
    title,
    headerCenter,
    hasPadding,
    emptyCardImageSrc,
    emptyCardText,
    color,
    children,
    className,
    showMoreOption,
    showMoreText,
    hideMoreText,
    shadow,
    cardBodySize,
    isCollapsible,
    isInitiallyCollapsed,
    dataTestId,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(isInitiallyCollapsed);
  const titlePresent = title !== null;
  const darkTransparent = dark && transparent;

  const handleExpanded = useCallback(() => {
    setIsExpanded(prevState => !prevState);
  }, []);

  const collapsibleButtonClickHandler = useCallback(() => {
    if (isCollapsible) {
      setIsCollapsed(prevState => !prevState);
    }
  }, [isCollapsible]);

  return (
    <div
      className={classNames(
        'card',
        'card--v2',
        className,
        `card--${shadow}-shadow`,
        `card--${size}`,
        {
          'card--dark': dark,
          'card--dark-transparent': darkTransparent,
          'card--transparent': transparent,
        },
      )}
      data-testid={dataTestId}
    >
      {children ? (
        <div className="card--v2__wrapper">
          {imageSrc ? <img className="card--v2__image" src={imageSrc} alt="Avatar" /> : null}
          {title || actions ? (
            <div
              className={classNames('card__header', {
                'card__header--dark': dark,
                'card__header--collapsible': isCollapsible,
                'card__header--dark-transparent': darkTransparent,
                'card__header--transparent': transparent,
                'card__header--title': titlePresent && !isCollapsed,
                'card__header--title-center': headerCenter,
              })}
              onClick={collapsibleButtonClickHandler}
              role="presentation"
            >
              <div
                className={classNames('card__header-text', {
                  'card__header-text--center': headerCenter,
                })}
              >
                {title}
              </div>
              {actions ? <div className="card__header-actions">{actions}</div> : null}
              {isCollapsible ? (
                <div className="card__header-actions">
                  <Icon name="iconUpChevron" rotate={isCollapsed ? 180 : 0} />
                </div>
              ) : null}
            </div>
          ) : null}
          {!isCollapsed ? (
            <div
              className={classNames({
                card__body: hasPadding,
              })}
            >
              {showMoreOption ? (
                <div>
                  <div
                    className={classNames('card__body-section', {
                      [`card__body--${cardBodySize}`]: isExpanded,
                    })}
                  >
                    {children}
                  </div>
                  <div
                    className={classNames('card__footer', {
                      'card__footer--dark': dark,
                    })}
                    onClick={handleExpanded}
                    onKeyDown={handleExpanded}
                    role="presentation"
                  >
                    <div className="card__footer-options">
                      {isExpanded ? (
                        <div className="card__footer-expand-indicator">
                          {showMoreText}
                          <Icon name="iconDownChevron" />
                        </div>
                      ) : (
                        <div className="card__footer-expand-indicator">
                          {hideMoreText}
                          <Icon name="iconUpChevron" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card__body-section">{children}</div>
              )}
            </div>
          ) : null}
        </div>
      ) : (
        <div className="card--v2__empty-card">
          <div className="card--v2__empty-title">{title}</div>
          <div className="card--v2__empty-wrapper">
            {emptyCardImageSrc ? (
              <div
                className={classNames('card--v2__empty-image', `card--v2__empty-image--${color}`)}
              >
                {emptyCardImageSrc}
              </div>
            ) : null}
            <div className="card--v2__empty-text">{emptyCardText}</div>
          </div>
        </div>
      )}
    </div>
  );
};

Card.defaultProps = {
  dark: false,
  transparent: false,
  actions: null,
  title: null,
  headerCenter: false,
  hasPadding: false,
  className: null,
  imageSrc: null,
  isCollapsible: false,
  isInitiallyCollapsed: false,
  emptyCardText: 'Empty Card',
  size: null,
  shadow: 'normal',
  color: null,
  emptyCardImageSrc: null,
  children: null,
  showMoreOption: false,
  cardBodySize: 'normal',
  showMoreText: 'Show',
  hideMoreText: 'Hide',
  dataTestId: null,
};

Card.propTypes = {
  className: PropTypes.string,
  transparent: PropTypes.bool,
  hasPadding: PropTypes.bool,
  headerCenter: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  isInitiallyCollapsed: PropTypes.bool,
  title: PropTypes.node,
  dark: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.node),
  emptyCardImageSrc: PropTypes.node,
  emptyCardText: PropTypes.node,
  color: PropTypes.oneOf(['tertiary', 'success', null]),
  children: PropTypes.node,
  imageSrc: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'full', null]),
  shadow: PropTypes.oneOf(['normal', 'dark']),
  showMoreOption: PropTypes.bool,
  cardBodySize: PropTypes.oneOf(['small', 'normal', 'medium', 'large']),
  showMoreText: PropTypes.node,
  hideMoreText: PropTypes.node,
  dataTestId: PropTypes.string,
};

export default Card;
