import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useGetOrganizationQuery } from 'api/organizations';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import classNames from 'classnames';

const DeactivationWrapper = ({ children }) => {
  const { id } = useParams();
  const organizationId = parseInt(id, 10);
  const { data: organisation } = useGetOrganizationQuery(organizationId);

  const isOrganisationDeactivated = organisation?.status === ORGANISATIONS_STATUS.inactive;

  return (
    <div
      className={classNames({
        'organisation--deactivated': isOrganisationDeactivated,
      })}
    >
      {children}
    </div>
  );
};

DeactivationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DeactivationWrapper;
