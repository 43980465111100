import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDeclareGoodsMutation } from 'api/goods-declarations';
import { Trans, useTranslation } from 'react-i18next';
import { currentMonthNameWithYear } from 'utils/month-year-formatter';
import useCustomNavigate from 'hooks/useCustomNavigate';
import Button from 'components/base-components/Button';
import WarningModal from 'components/shared-components/modal/warning';
import SubmitModal from 'components/shared-components/modal/submit-modal';

const DeclareGoods = ({
  periodFrom,
  periodUntil,
  setGoodsDeclarationError,
  disabledDeclaration,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useCustomNavigate();
  const [isDeclareModalOpen, setIsDeclareModalOpen] = useState(false);
  const [declareGoods, { isSuccess: isDeclareSuccess, requestId }] = useDeclareGoodsMutation();

  const handleDeclareGoods = () => {
    declareGoods({
      id,
      payload: {
        organization_id: id,
      },
    })
      .unwrap()
      .catch(({ data: { errors } }) => {
        if (errors) {
          setGoodsDeclarationError(errors.base);
        }
      });
  };

  return (
    <Fragment>
      <WarningModal
        isVisible={isDeclareModalOpen}
        onConfirm={() => {
          handleDeclareGoods();
          setIsDeclareModalOpen(false);
        }}
        onCancel={() => setIsDeclareModalOpen(false)}
        title={t('goods.modal.declare_confirmation.title')}
        content={
          <Trans
            i18nKey="goods.modal.declare_confirmation.content"
            values={{
              start: currentMonthNameWithYear(periodFrom),
              end: currentMonthNameWithYear(periodUntil),
            }}
          />
        }
        confirmActionText={t('goods.declare_goods')}
        cancelActionText={t('shared.action.cancel')}
      />
      <SubmitModal
        isSuccess={isDeclareSuccess}
        requestId={requestId}
        successTitle={t('goods.modal.declare_success.title')}
        successContent={t('goods.modal.declare_success.content')}
        showError={false}
        showLoader={false}
        onSuccess={() => navigate.openOrganisationDetailsPage(id)}
      />
      <Button
        label={t('goods.declare_goods')}
        disabled={disabledDeclaration}
        type="success"
        size="small"
        onClick={() => setIsDeclareModalOpen(true)}
      />
    </Fragment>
  );
};

DeclareGoods.propTypes = {
  periodFrom: PropTypes.string.isRequired,
  periodUntil: PropTypes.string.isRequired,
  setGoodsDeclarationError: PropTypes.func.isRequired,
  disabledDeclaration: PropTypes.bool.isRequired,
};

export default DeclareGoods;
