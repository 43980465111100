import { useRef, useEffect, useMemo } from 'react';
import { debounce } from 'throttle-debounce';

const useDebounce = callback => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(500, func);
  }, []);

  return debouncedCallback;
};

export default useDebounce;
