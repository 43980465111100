import React from 'react';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import Card from 'components/base-components/Card';
import './education-loader.styles.scss';

const EducationLoader = () => {
  return (
    <Card>
      <div className="user__education-loader">
        <div className="user__education-loader--first-row col-grid col-bleed direction-row">
          <div className="col-grid col-bleed direction-row col-4">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
          <div className="col-grid col-bleed direction-row justify-end offset-6 col-2">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
        </div>
        <div className="col-grid col-bleed direction-row">
          <div className="col-bleed col-5">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
        </div>
        <div className="col-grid col-bleed direction-row">
          <div className="col-bleed col-2">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
          <div className="col-bleed offset-5 col-2 ">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
        </div>
        <div className="col-grid col-bleed direction-row">
          <div className="col-bleed col-5">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
          <div className="col-bleed offset-2 col-5">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
        </div>
        <div className="col-grid col-bleed direction-row">
          <div className="col-bleed col-2">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
          <div className="col-bleed offset-5 col-2 ">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
        </div>
        <div className="col-grid col-bleed direction-row">
          <div className="col-bleed col-5">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
          <div className="col-bleed offset-2 col-5">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default EducationLoader;
