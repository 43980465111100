import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const OrganisationMembers = ({ organization }) => {
  const totalUsers = organization.userCount;
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        'organisation__table-row--violet-color organisation__table-row--members': totalUsers > 0,
      })}
    >
      {totalUsers
        ? t('organisation.members.total', { count: totalUsers })
        : t('common.not_applicable')}
    </div>
  );
};

OrganisationMembers.propTypes = {
  organization: PropTypes.shape().isRequired,
};

export default OrganisationMembers;
