import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';
import { useTranslation } from 'react-i18next';

const MonthYearPicker = ({ dateFormat, label, onChange, placeholder, selectedMonthYear }) => {
  const { i18n } = useTranslation();
  return (
    <div className="month-year-picker">
      <label className="month-year-picker__label">{label}</label>
      <DatePicker
        calendarClassName="month-year-picker__container"
        dateFormat={dateFormat}
        onChange={monthYear => onChange(monthYear)}
        placeholderText={placeholder}
        selected={selectedMonthYear}
        showMonthYearPicker={true}
        showPopperArrow={false}
        locale={i18n.language}
      />
    </div>
  );
};

MonthYearPicker.defaultProps = {
  dateFormat: 'MMMM yyyy',
  label: '',
  onChange: () => {},
  placeholder: '',
  selectedMonthYear: null,
};

MonthYearPicker.propTypes = {
  dateFormat: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  selectedMonthYear: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

export default MonthYearPicker;
