import React from 'react';
import { useGetOrganizationQuery, useUpdateAddressMutation } from 'api/organizations';
import Card from 'components/base-components/Card';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { yearFromDate } from 'utils/date-time-formatter';
import addressView from 'utils/address-view';
import ChangeMainAddress from './change-main-address.component';
import OverviewContent from './overview-content.component';

const GeneralDetails = ({ organisation, isFetching }) => {
  const [updateAddress, { isLoading, isSuccess, isError, requestId }] = useUpdateAddressMutation();

  const { data: contolBodyOrganisation } = useGetOrganizationQuery(
    organisation?.controlBodyOrganizationId,
    {
      skip: !organisation?.controlBodyOrganizationId,
    },
  );
  const department = organisation?.industry || organisation?.type;

  return (
    <Card className="organisation-overview__card">
      {isFetching ? (
        <ContentLoaderPlaceholder numberOfLines={6} showBackground={false} />
      ) : (
        <>
          <span className="organisation-overview__card-title">
            {t('organisation_view.overview.attributes.general_details.header')}
          </span>
          <div className="organisation-overview__content-wrapper">
            <OverviewContent
              label={t('organisation_view.overview.attributes.general_details.organisation_name')}
              value={organisation?.name}
            />
            <OverviewContent
              label={t('organisation.form.attributes.organisation_additional_name')}
              value={organisation?.additionalName}
            />
            <OverviewContent
              label={t('organisation_view.overview.attributes.general_details.department')}
              value={
                department && t(`organisation_view.overview.attributes.tag_status.${department}`)
              }
            />
            <OverviewContent
              label={t('organisation_view.overview.attributes.general_details.member_organisation')}
              value={organisation?.membershipOrganization}
              noMargin={true}
            />
            <OverviewContent
              label={t('organisation_view.overview.attributes.general_details.control_body')}
              value={contolBodyOrganisation?.name}
            />
            <OverviewContent
              label={t('organisation_view.overview.attributes.general_details.starting_year')}
              value={
                organisation?.customerSince
                  ? yearFromDate(organisation.customerSince)
                  : t('common.not_applicable')
              }
            />
            <OverviewContent
              label={t('organisation_view.overview.attributes.general_details.main_address')}
              value={addressView(organisation?.mainAddress)}
              noMargin={true}
            >
              <ChangeMainAddress organisation={organisation} updateAddress={updateAddress} />
            </OverviewContent>
            <OverviewContent
              label={t('organisation_view.overview.attributes.general_details.company_type')}
              value={
                organisation?.producerType &&
                t(`organisation_view.overview.attributes.tag_status.${organisation?.producerType}`)
              }
              noMargin={true}
            />
          </div>
        </>
      )}

      <SubmitModal
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        requestId={requestId}
        successContent={t('organisation.change_address.modal.main_address.success.content')}
        successTitle={t('organisation.change_address.modal.main_address.success.title')}
        showLoader={true}
      />
    </Card>
  );
};

GeneralDetails.propTypes = {
  organisation: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default GeneralDetails;
