import Icon from 'components/base-components/Icon';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TitleComponent = ({ title, icon, isOutsideForm }) => {
  return (
    title && (
      <div
        className={classNames({
          'plant-cultivation__method--title': !isOutsideForm,
          'flex-start': isOutsideForm,
        })}
      >
        <Icon name={icon} color="neutral" size="huge" />
        <div className="plant-cultivation__method--text">{title}</div>
      </div>
    )
  );
};

TitleComponent.defaultProps = {
  title: null,
  icon: null,
  isOutsideForm: false,
};

TitleComponent.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  isOutsideForm: PropTypes.bool,
};

export default TitleComponent;
