import React, { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TabNav = forwardRef((props, ref) => {
  const { className, index, title, isActive, onClick } = props;

  const onTabClickHandler = () => onClick(index);

  return (
    <>
      <div
        className={classNames(
          'rtabs__nav-item',
          {
            'rtabs__nav-item--active': isActive,
          },
          className,
        )}
        ref={ref}
        role="button"
        tabIndex={0}
        onClick={onTabClickHandler}
        onKeyDown={onTabClickHandler}
      >
        <span
          style={{ minWidth: `${9 * title.length}px` }}
          className={classNames('rtabs__nav-item--text', {
            'rtabs__nav-item--text__active': isActive,
          })}
        >
          {title}
        </span>
      </div>
      <div className="rtabs__nav-item--separator" />
    </>
  );
});

TabNav.defaultProps = {
  className: '',
  title: '',
  isActive: false,
  onClick: () => {},
};

TabNav.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isActive: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

export default memo(TabNav);
