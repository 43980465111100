import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useGetSpecificOrganizationsOverviewQuery } from 'api/organizations';
import DefaultSpinner from '../default-spinner';
import Alert from '../../base-components/Alert';

const WithChartaWrapper = ({ children, organizationId }) => {
  const { t } = useTranslation();
  const { data } = useGetSpecificOrganizationsOverviewQuery(organizationId);

  if (!data) return <DefaultSpinner className="default-spinner--center" />;

  if (data.chartaEnabled || data.chartaDeclaredPreviously) {
    return children;
  } else {
    return (
      <Alert isVisible={true} hideClose={true} type="warning" isIconVisible={false}>
        {t('supplier_certificates.no_active_charta')}
      </Alert>
    );
  }
};

WithChartaWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  organizationId: PropTypes.number.isRequired,
};

export default WithChartaWrapper;
