import { BreadcrumbItem, Breadcrumbs } from 'components/base-components/Breadcrumbs';
import PropTypes from 'prop-types';
import React from 'react';
import parseUrlTemplate from 'utils/parse-url-template';

const BreadCrumbComponent = ({ breadcrumbs }) => {
  return (
    <Breadcrumbs>
      {breadcrumbs &&
        breadcrumbs.map(({ breadcrumb, key, match, link }) => {
          const parsedLink = link ? parseUrlTemplate(link, match.params) : match.pathname;

          return (
            <BreadcrumbItem key={key} linkUrl={parsedLink}>
              {breadcrumb}
            </BreadcrumbItem>
          );
        })}
    </Breadcrumbs>
  );
};

BreadCrumbComponent.defaultProps = {
  breadcrumbs: [],
};

BreadCrumbComponent.propTypes = {
  breadcrumbs: PropTypes.array,
};

export default BreadCrumbComponent;
