import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Feedback = props => {
  const { align, children, className, showFeedback } = props;

  return (
    showFeedback && (
      <div
        className={classNames('modal__feedback', `modal__feedback--${align}`, className)}
      >
        {children}
      </div>
    )
  );
};

Feedback.defaultProps = {
  align: 'center',
  children: null,
  className: null,
  showFeedback: false,
};

Feedback.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node,
  className: PropTypes.string,
  showFeedback: PropTypes.bool,
};

export default Feedback;
