import React from 'react';
import { useGetOrganizationQuery, useGetOrganizationAssignmentsQuery } from 'api/organizations';
import { useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { groupBy } from 'utils/groupBy';
import { OPERATION_MANAGER, MAIN_CONTACT, PARTNER } from 'utils/users-role';
import Card from 'components/base-components/Card';
import CompanyType from './company-type.component';
import ContractDetails from './contract-details.component';
import OperationsManager from './operations-manager.component';
import ContactPersons from './contact-person.component';
import LegalFormType from './shared/legal-form-type.component';

const LegalForm = ({ validationError }) => {
  const { id } = useParams();

  const form = useForm();
  const { values } = useFormState();

  const { data: organisation = {} } = useGetOrganizationQuery(id);
  const { data: assignments = [] } = useGetOrganizationAssignmentsQuery({
    organizationId: id,
    'roles[]': [OPERATION_MANAGER, MAIN_CONTACT, PARTNER],
  });
  const assignmentsByRole = groupBy(assignments, 'role');

  const contractUserIds = (assignmentsByRole[PARTNER] || []).map(assignment => assignment.userId);

  const operationManagerIds = (assignmentsByRole[OPERATION_MANAGER] || []).map(
    assignment => assignment.userId,
  );

  const mainContact = (assignmentsByRole[MAIN_CONTACT] || [])[0];

  const onChangePartnerCount = count => {
    const currentContent = values.legalFormPartners || [];
    const needsToAdd = count - currentContent.length;
    let updatedData;
    if (needsToAdd > 0) {
      updatedData = currentContent.concat(Array(needsToAdd).fill({ email: null, partner: true }));
    } else {
      updatedData = currentContent.slice(0, needsToAdd);
    }
    const newUserIds = updatedData.map(partner => partner.userId);
    const needsToRemove = contractUserIds
      .filter(partner => !newUserIds.includes(partner.id))
      .map(partner => ({ userId: partner.id, destroy: true }));

    form.change('deletedPartnerIds', needsToRemove);
    form.change('legalFormPartners', updatedData);
  };

  return (
    <div>
      <LegalFormType
        onChangePartnerCount={onChangePartnerCount}
        organisation={organisation}
        validationError={validationError}
      />
      <ContractDetails contractUserIds={contractUserIds} validationError={validationError} />
      <Card className="legal-form__card">
        <OperationsManager
          selectedNumberOfPartners={values.numberOfPartners}
          operationManagerIds={operationManagerIds}
          validationError={validationError}
        />
        <ContactPersons
          selectedNumberOfPartners={values.numberOfPartners}
          mainContactId={mainContact?.userId}
          validationError={validationError}
        />
      </Card>
      <CompanyType initialValue={organisation.producerType} validationError={validationError} />
    </div>
  );
};

LegalForm.defaultProps = {
  validationError: {},
};

LegalForm.propTypes = {
  validationError: PropTypes.shape(),
};

export default LegalForm;
