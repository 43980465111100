import ORGANISATION_TYPE, { CONTROL_BODY } from 'utils/organisation-type';

export const subOrganisationValues = (values, formData, department) => {
  const subOrganisationAttributes = (values, formData, industry) => {
    values.subOrganisations?.forEach(subOrg => {
      if (subOrg.name) formData.append('sub_organizations[][name]', subOrg.name);
      if (subOrg.uid) formData.append('sub_organizations[][uid]', subOrg.uid);
      if (industry) formData.append('sub_organizations[][industry]', industry);
      if (subOrg.main_address) {
        Object.keys(subOrg.main_address).forEach(key =>
          formData.append(
            `sub_organizations[][main_address][${key}]`,
            subOrg.main_address[key] || '',
          ),
        );
      }
    });
  };

  if (values.organisationType === ORGANISATION_TYPE.parent_organisation) {
    subOrganisationAttributes(values, formData, department !== CONTROL_BODY ? department : null);
  }
};
