import React from 'react';
import PropTypes from 'prop-types';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { Link, useSearchParams } from 'react-router-dom';
import ORGANISATION_TYPE from 'utils/organisation-type';
import IconButton from 'components/base-components/IconButton';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import Icon from 'components/base-components/Icon';
import { useGetCurrentUserQuery } from '../../../api/users';
import isBioSuisseAdmin from '../../../utils/is-bio-suisse-admin';

const OrganisationAction = ({ organization }) => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const navigationLinks = useNavigationLinks();
  const [searchParams] = useSearchParams({});
  const tab = searchParams.get('tab');
  const showButton = tab !== ORGANISATIONS_STATUS.inactive && tab !== ORGANISATIONS_STATUS.aborted;

  return (
    <div>
      <Link
        to={
          organization.type === ORGANISATION_TYPE.controlBody
            ? navigationLinks.organisationViewPage(organization.id)
            : navigationLinks.organisationDetailsPage(organization.id)
        }
      >
        <IconButton
          className="organisation__action-show"
          icon={<Icon name="showAlt" size="normal" />}
          size="tiny"
          color="tertiary"
        />
      </Link>
      {showButton && (
        <>
          {isBioSuisseAdmin(currentUser) &&
            (organization.status === ORGANISATIONS_STATUS.inactive ? (
              <Link to={navigationLinks.organisationReactivePage(organization.id)}>
                <IconButton
                  className="organisation__action-reactive"
                  icon={<Icon name="userDeactivate" size="medium" />}
                  size="tiny"
                  color="tertiary"
                />
              </Link>
            ) : (
              <Link to={navigationLinks.organisationsEditPage(organization.id)}>
                <IconButton
                  className="organisation__action-edit"
                  icon={<Icon name="editAlt" size="normal" />}
                  size="tiny"
                  color="tertiary"
                />
              </Link>
            ))}
        </>
      )}
    </div>
  );
};

OrganisationAction.propTypes = {
  organization: PropTypes.shape().isRequired,
};

export default OrganisationAction;
