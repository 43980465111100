import { useGetFishFarmProductionQuery } from 'api/producers/productions';
import { t } from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import SharedProductionComponent from './shared/shared-production.component';

const FishComponent = ({ id }) => {
  const { data: fishProductionData = {} } = useGetFishFarmProductionQuery(
    { productionId: id },
    { skip: !id },
  );

  return (
    <SharedProductionComponent title={t('plant_cultivation.fish_farming.self')} icon="fish">
      <div className="flex-space-between margin-top-10">
        <div>{t('plant_cultivation.fish_farming.quantity_of_fish')}</div>
        <div>{fishProductionData?.fishProducedKg}</div>
      </div>
    </SharedProductionComponent>
  );
};

FishComponent.defaultProps = {
  id: null,
};

FishComponent.propTypes = {
  id: PropTypes.node,
};

export default FishComponent;
