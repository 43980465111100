import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from 'components/base-components/Button';
import AmountComponent from 'components/shared-components/Amount';
import numberFormatter from 'utils/number-formatter';
import Card from 'components/base-components/Card';
import SidePanel from 'components/base-components/SidePanel';
import GoodsEditFormComponent from './goods-edit-form.component';

const MonthlyData = ({
  goodsId,
  month,
  period,
  bioCertifiedAmount,
  shareBioAveragePercentage,
  bioBudCertifiedAmount,
  shareBioBudAveragePercentage,
  totalAmount,
  disabled,
  showEditButton,
}) => {
  const { t } = useTranslation();
  const [openSidePanel, setOpenSidePanel] = useState(false);

  const formattedTotalAmount = totalAmount && numberFormatter(totalAmount);

  const formatGoodsValue = (value, percentage) => {
    if (!value || percentage === null) return t('shared.tbd');
    const updatedPercentage = parseFloat(percentage).toFixed(2);

    const formattedValue = numberFormatter(value);

    return (
      <>
        <AmountComponent currency="CHF" value={formattedValue} />
        <span> ({updatedPercentage}%)</span>
      </>
    );
  };

  return (
    <Card>
      <SidePanel
        isOpened={openSidePanel}
        title={t('goods.select_value_text')}
        body={
          <GoodsEditFormComponent
            setIsEditSidepanelOpen={setOpenSidePanel}
            goodsId={goodsId}
            initialEditData={{
              date: period,
              shareBio: bioCertifiedAmount,
              shareKnospe: bioBudCertifiedAmount,
              totalAmount: totalAmount,
            }}
            selectedMonth={month}
          />
        }
        onClose={() => setOpenSidePanel(false)}
        onClick={() => setOpenSidePanel(false)}
      />
      <div className="goods__card-edit-wrapper">
        <div>{period}</div>
        {showEditButton && (
          <Button
            label={t('goods.edit')}
            size="small"
            disabled={disabled}
            onClick={() => {
              setOpenSidePanel(true);
            }}
          />
        )}
      </div>
      <div className="goods__card-bio-certified"> {t('goods.bio_certified')}</div>
      <div className="goods__card-bio-certified--amount">
        {formatGoodsValue(bioCertifiedAmount, shareBioAveragePercentage)}
      </div>
      <div className="goods__card-bio-certified"> {t('goods.bio_bud_certified')}</div>
      <div className="goods__card-bio-certified--amount">
        {formatGoodsValue(bioBudCertifiedAmount, shareBioBudAveragePercentage)}
      </div>
      <div className="goods__card-bio-certified">{t('goods.total_amount')}</div>
      <div className="goods__card-bio-certified--amount">
        {totalAmount ? (
          <AmountComponent currency="CHF" value={formattedTotalAmount} />
        ) : (
          t('shared.tbd')
        )}
      </div>
    </Card>
  );
};

MonthlyData.defaultProps = {
  disabled: true,
  showEditButton: false,
};

MonthlyData.propTypes = {
  goodsId: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  period: PropTypes.string.isRequired,
  bioCertifiedAmount: PropTypes.number.isRequired,
  shareBioAveragePercentage: PropTypes.number.isRequired,
  bioBudCertifiedAmount: PropTypes.number.isRequired,
  shareBioBudAveragePercentage: PropTypes.number.isRequired,
  totalAmount: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  showEditButton: PropTypes.bool,
};

export default MonthlyData;
