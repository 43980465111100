import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Popover from 'components/base-components/Popover';
import { dateMonthYearFormatter } from 'utils/date-time-formatter.js';
import RecognitionPDFComponent from 'components/recognition/details/recognition-pdf.component';
import DownloadPdf from 'components/invoices/shared/download-pdf.component';

const RecognitionAllPDF = ({ certificate, organisation, children, additionalElments }) => {
  const [showPopover, setShowPopover] = useState(false);
  const { t } = useTranslation();
  return (
    <Popover
      className="task__popover-wrapper"
      direction="bottom-left"
      isVisible={showPopover}
      onOutsideClick={() => setShowPopover(false)}
      content={
        <div className="task__popover">
          <RecognitionPDFComponent
            organization={organisation}
            certificateGenDate={dateMonthYearFormatter(certificate?.createdAt)}
            certificate={certificate}
          />
          <div className="task__popover-content">
            <DownloadPdf
              recognitionId={certificate?.id}
              entityType="recognition"
              label={t('recognition.details.table.popovers.download_certificate_with_logo')}
              with_logo={true}
            />
          </div>
          <div className="task__popover-content">
            <DownloadPdf
              recognitionId={certificate?.id}
              entityType="recognition"
              label={t('recognition.details.table.popovers.download_certificate_without_logo')}
            />
          </div>
          {additionalElments}
        </div>
      }
    >
      <div onClick={() => setShowPopover(true)}>{children}</div>
    </Popover>
  );
};

RecognitionAllPDF.defaultProps = {
  additionalElments: null,
};

RecognitionAllPDF.propTypes = {
  certificate: PropTypes.shape().isRequired,
  organisation: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
  additionalElments: PropTypes.node,
};

export default RecognitionAllPDF;
