import React from 'react';
import { PillTabs } from 'components/base-components/PillTabs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ContractHistory from './contract-history.component';
import ProducerContractComponent from './producer-contract.component';

const ContractTabComponent = ({ contractData }) => {
  const { t } = useTranslation();
  const TAB_MAPPER = {
    current: 0,
    previous: 1,
  };

  const [searchParams, setSearchParams] = useSearchParams({ subTab: 'current' });
  const currentTab = searchParams.get('subTab');
  const section = searchParams.get('section');

  const onChangeTab = selectedTabKey => {
    const selectedTab = Object.keys(TAB_MAPPER).find(tab => TAB_MAPPER[tab] === selectedTabKey);
    setSearchParams({ subTab: selectedTab, section });
  };

  return (
    <PillTabs
      controlled={true}
      selectedTabKey={TAB_MAPPER[currentTab]}
      useTransitionOnChange={true}
      onChange={onChangeTab}
      items={[
        {
          getContent: () => <ProducerContractComponent contractData={contractData} />,
          key: 0,
          title: t('contract.tabs.current_contract'),
        },
        {
          getContent: () => <ContractHistory />,
          key: 1,
          title: t('contract.tabs.previous_contract'),
        },
      ]}
    />
  );
};

ContractTabComponent.defaultProps = {
  contractData: null,
};

ContractTabComponent.propTypes = {
  contractData: PropTypes.shape(),
};

export default ContractTabComponent;
