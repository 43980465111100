import React from 'react';
import PropTypes from 'prop-types';

const AmountComponent = ({ currency, value }) => {
  return (
    <>
      {' '}
      {currency} {value}
    </>
  );
};

AmountComponent.defaultProps = {
  currency: 'CHF',
  value: null,
};

AmountComponent.propTypes = {
  currency: PropTypes.string,
  value: PropTypes.number,
};

export default AmountComponent;
