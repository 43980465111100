import { useDeclareControlBodySurveyMutation } from 'api/control-body-survey';
import Button from 'components/base-components/Button';
import DatePicker from 'components/base-components/DatePicker/DatePicker';
import TimePicker from 'components/base-components/TimePicker';
import { dateTimeWithZone } from 'utils/date-time-formatter';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import moment from 'moment';

const ControlFlowFormComponent = ({ setIsSidepanelOpen }) => {
  const [declareControlBodySurvey] = useDeclareControlBodySurveyMutation();
  const { t, i18n } = useTranslation();
  const [isDueDateOpen, setIsDueDateOpen] = useState(false);
  const [time, setTime] = useState('08:00');
  const { id } = useParams();

  const getTime = time && time.split(':')[1];

  const onSubmit = values => {
    let formData = new FormData();
    formData.append('starts_at', dateTimeWithZone(`${values.starts_at} ${time}`));

    return declareControlBodySurvey({ organizationId: id, payload: formData })
      .unwrap()
      .then(() => setIsSidepanelOpen(false))
      .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
  };
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="grid">
            <div className="col-12 col-bleed">
              <Field name="starts_at">
                {({ input, meta }) => (
                  <DatePicker
                    focused={isDueDateOpen}
                    required={true}
                    date={input.value}
                    onChange={e => input.onChange(e)}
                    onFocusChange={() => setIsDueDateOpen(!isDueDateOpen)}
                    displayFormat="LL"
                    label={t('control_body.sidepanel_due_date')}
                    placeholder={t('tasks.create_form.due_date_placeholder')}
                    size="tiny"
                    touched={!meta.dirtySinceLastSubmit}
                    error={meta.submitError}
                    hideNavButtons={false}
                    minDate={moment().format('YYYY-MM-DD')}
                    locale={i18n.language}
                    enablePastDates={true}
                  />
                )}
              </Field>
            </div>
            <div className="col-12 col-bleed control-flow__time-picker">
              {t('control_body.select_time')}
            </div>
            <div className="col-12 col-bleed">
              <TimePicker
                onChange={setTime}
                getTime={getTime}
                value={time}
                locale="sv-sv"
                disableClock={true}
                minTime="00:00"
                maxTime="23:59"
                handleTime={setTime}
              />
            </div>
            <div className="control-body-form__buttons col-12 col-bleed-x">
              <Button
                label={t('common.submit')}
                type="success"
                size="small"
                submitType="submit"
                disabled={submitting}
              />
              <Button
                className="control-body-form__buttons-cancel"
                label={t('common.cancel')}
                size="small"
                onClick={() => setIsSidepanelOpen(false)}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

ControlFlowFormComponent.propTypes = {
  setIsSidepanelOpen: PropTypes.func.isRequired,
};

export default ControlFlowFormComponent;
