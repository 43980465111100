import React from 'react';
import PropTypes from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import CheckBox from 'components/base-components/CheckBox';
import { CONTROL_BODY } from 'utils/organisation-type';
import AddressField from './AddressField';
import OrganisationContact from './contact.component';
import '../../organisations.styles.scss';

const ContactAndAddressForm = ({ organizationRegistry, department, validationError }) => {
  const { t } = useTranslation();
  const { values } = useFormState();

  return (
    <>
      <OrganisationContact
        preferredContact={values?.preferredContact}
        validationError={validationError}
      />
      <div className="organisation__form--margin-top organisation__form--font-bold">
        {t('organisation.form.attributes.main_address')}
      </div>
      <AddressField
        parentKey="mainAddress"
        values={organizationRegistry}
        validationError={validationError?.mainAddress}
      />
      {department !== CONTROL_BODY && (
        <>
          <div className="organisation__form--margin-top organisation__form--font-bold">
            {t('organisation.form.attributes.billing_address')}
          </div>
          <div>
            <Field name="receiveInvoice" type="checkbox">
              {({ input }) => (
                <>
                  <CheckBox
                    className="organisation__form-receive-invoice"
                    isChecked={input.checked}
                    size="tiny"
                    label={t('organisation.form.attributes.receives_invoices')}
                    onChange={e => input.onChange(e.target.checked)}
                  />
                  <div className="organisation__form--margin-top organisation__form-sub-label organisation__form--disabled">
                    {t('organisation.form.attributes.receives_invoices_label')}
                  </div>
                </>
              )}
            </Field>
          </div>
          {values.receiveInvoice && (
            <AddressField
              parentKey="billingAddress"
              isDisabled={!values.receiveInvoice}
              showAddressName={true}
              validationError={validationError?.billingAddress}
            />
          )}
          <div className="organisation__form--margin-top organisation__form--font-bold">
            {t('organisation.form.attributes.mailing_address')}
          </div>
          <Field name="enableMailingAddress" type="checkbox">
            {({ input }) => (
              <CheckBox
                className="organisation__form--margin-top organisation__form-other-address"
                isChecked={input.checked}
                size="tiny"
                label={t('organisation.form.attributes.add_mailing_address')}
                onChange={e => input.onChange(e.target.checked)}
              />
            )}
          </Field>
          {values.enableMailingAddress && (
            <AddressField
              parentKey="mailingAddress"
              isDisabled={!values.enableMailingAddress}
              showAddressName={true}
              validationError={validationError?.mailingAddress}
            />
          )}
        </>
      )}
    </>
  );
};

ContactAndAddressForm.defaultProps = {
  organizationRegistry: null,
  department: null,
  validationError: {},
};

ContactAndAddressForm.propTypes = {
  organizationRegistry: PropTypes.shape(),
  department: PropTypes.string,
  validationError: PropTypes.shape(),
};

export default ContactAndAddressForm;
