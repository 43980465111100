export const milkValues = (values, formData, milksTypes) => {
  if (values.dairy_farms) {
    milksTypes.forEach(({ name }) => {
      if (values.dairy_farms[name]) {
        formData.append('production_details[dairy_farms][][sub_type]', name);
        formData.append(
          'production_details[dairy_farms][][number_of_animals]',
          values.dairy_farms[name].number_of_animals,
        );
        formData.append(
          'production_details[dairy_farms][][milk_produced_kg]',
          values.dairy_farms[name].milk_produced_kg,
        );

        if (name === 'milk_cow') {
          const attributes = {
            organic_milk_producer_organization: values?.milkProducerOrganization,
            milk_processing_source: values?.milkProcessingSource,
            first_milk_buyer: values?.milkFirstBuyer,
          };

          Object.keys(attributes).forEach(item => {
            if (attributes[item]) {
              formData.append(`production_details[dairy_farms][][${item}]`, attributes[item]);
            }
          });
        }
      }
    });

    if (values.dairy_farms.other_goat_species) {
      formData.append('production_details[dairy_farms][][other_goat_species][]', 'dwarf_goats');
    }
  }
};
