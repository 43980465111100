import React from 'react';
import { Image, Link, Text, View } from '@react-pdf/renderer';
import HeaderImage from 'assets/merkblatt.png';
import tvdStyles from 'components/pdf/styles/tvd-styles';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { COLOR_BLACK } from 'components/pdf/styles/variables';
import { STATUS_VK } from 'utils/organization-certificate-status';
import VkContent from './vk.content';
import UkContent from './uk.content';

const SecondPage = ({ status }) => (
  <View>
    <Image style={tvdStyles.statusLogo} src={HeaderImage} />
    <View style={tvdStyles.secondPageContentBody}>
      <Text style={tvdStyles.headerTitle}>{t(`pdf.tvd.${status}.second_page.title`)}</Text>
      <Text>{t('pdf.tvd.second.date')}</Text>
      <Text style={tvdStyles.marginTop20}>{t(`pdf.tvd.${status}.second_page.line1`)}</Text>

      {status === STATUS_VK ? <VkContent /> : <UkContent />}

      <Text style={{ ...tvdStyles.marginTop10 }}>
        <Trans
          i18nKey="pdf.tvd.second.line4"
          components={{ link: <Link src="https://www.bio-suisse.ch/vignetten" /> }}
        />
      </Text>
      <Text style={tvdStyles.marginTop20}>
        {t('pdf.tvd.second.line_5.part1')}
        <Link src="mailto:vignetten@bio-suisse.ch" style={{ color: COLOR_BLACK }}>
          vignetten@bio-suisse.ch
        </Link>
        {t('pdf.tvd.second.line_5.part2')}
      </Text>
    </View>
  </View>
);

SecondPage.propTypes = {
  status: PropTypes.string.isRequired,
};

export default SecondPage;
