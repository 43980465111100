import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useGetProductDeclarationsOverviewQuery } from 'api/product-declarations';
import { Trans, useTranslation } from 'react-i18next';
import { useGetCurrentUserQuery } from 'api/users';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import EmphasisTag from 'components/base-components/EmphasisTag';
import Link from 'components/base-components/Link';
import DefaultSpinner from 'components/shared-components/default-spinner';
import Icon from '../../base-components/Icon';
import TimePeriodComponent from './time-period.component';
import OverviewTextComponent from './overview-text.component';

const ProductDeclarationsOverview = ({ organizationId, onClick, declaredChartaPreviously }) => {
  const { t } = useTranslation();
  const { data: productDeclarationOverview = {}, isFetching } =
    useGetProductDeclarationsOverviewQuery({ organizationId });

  const { data: currentUser = {} } = useGetCurrentUserQuery();

  const isAdmin = isBioSuisseAdmin(currentUser);

  if (isFetching) return <DefaultSpinner className="default-spinner--overview" />;

  const handleText = status => {
    if (!declaredChartaPreviously) {
      return t('organisation_view.overview.charta.not_enabled');
    }
    if (productDeclarationOverview.gracePeriodEnabled) {
      return t('organisation_overview.messages.product_declarations.grace_period_enabled', {
        validUntil: productDeclarationOverview.validUntil,
      });
    }
    if (status === null || status === 'new_declaration' || status === 'draft') {
      return t('organisation_overview.messages.product_declarations.not_declared');
    } else if (status === 'submitted') {
      return t('organisation_overview.messages.shared.submitted');
    } else if (status === 'complained') {
      return t('organisation_overview.messages.shared.complained');
    } else if (status === 'approved') {
      return t('organisation_overview.messages.shared.approved');
    }

    return (
      <span>
        <Trans
          i18nKey="organisation_overview.messages.product_declarations.warning"
          components={{ bold: <b /> }}
        />
      </span>
    );
  };

  const handleIconColor = status => {
    if (status === 'expired') return 'danger';
    if (productDeclarationOverview.gracePeriodEnabled === true) return 'warning';
    if (status === 'approved') return 'success';

    return null;
  };

  const handleIconName = status => {
    if (status === 'expired') return 'highPriority';
    if (status === 'approved' || productDeclarationOverview.gracePeriodEnabled === true)
      return 'check';

    return null;
  };

  const declarationOverview = (
    <OverviewTextComponent
      text={handleText(productDeclarationOverview.status)}
      status={productDeclarationOverview.status}
    />
  );

  const showViewDetails = ['approved', 'submitted', 'complained'].includes(
    productDeclarationOverview.status,
  );

  const startDeclaration = declaredChartaPreviously && !showViewDetails;

  return (
    <Fragment>
      <div className="organisation-overview__title">
        <div className="organisation-overview__title-header">
          <span className="organisation-overview__title-header-text">
            {t('organisation_overview.product')}
          </span>
          <Icon
            className={classNames('organisation-overview__title-header--icon', {
              'organisation-overview__title-header--icon__success':
                productDeclarationOverview.status === 'approved' &&
                productDeclarationOverview.gracePeriodEnabled === false,
              'organisation-overview__title-header--icon__submitted':
                productDeclarationOverview.status === 'submitted',
              'organisation-overview__title-header--icon__danger':
                productDeclarationOverview.status === 'expired',
              'organisation-overview__title-header--icon__warning':
                productDeclarationOverview.gracePeriodEnabled === true,
            })}
            size="xs"
            name={handleIconName(productDeclarationOverview.status)}
            color={handleIconColor(productDeclarationOverview.status)}
            showBGColor={true}
          />
        </div>
        {productDeclarationOverview.status === 'submitted' ||
        productDeclarationOverview.status === 'complained' ? (
          <EmphasisTag
            type={productDeclarationOverview.status === 'submitted' ? 'accent' : 'warning'}
            text={
              productDeclarationOverview.status === 'submitted'
                ? t('shared.submitted')
                : t('shared.complained')
            }
          />
        ) : (
          <TimePeriodComponent
            validFrom={productDeclarationOverview.validFrom}
            validUntil={productDeclarationOverview.validUntil}
            status={productDeclarationOverview.status}
            showToBeDeclaredText={false}
          />
        )}
      </div>
      <div>{declarationOverview}</div>
      <Link
        type="button"
        className="organisation-overview__details"
        size="small"
        onClick={onClick}
        modifier={startDeclaration ? 'success' : 'default'}
        disabled={startDeclaration && isAdmin}
      >
        {startDeclaration
          ? t('organisation_view.overview.start_declaration')
          : t('shared.view_details')}
      </Link>
    </Fragment>
  );
};

ProductDeclarationsOverview.defaultProps = {
  declaredChartaPreviously: PropTypes.bool.isRequired,
  onClick: () => {},
};

ProductDeclarationsOverview.propTypes = {
  declaredChartaPreviously: PropTypes.bool.isRequired,
  organizationId: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

export default ProductDeclarationsOverview;
