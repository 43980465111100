export default {
  tip: {
    // in px
    size: {
      tiny: { height: 5, width: 14 },
      small: { height: 8, width: 20 },
    },
    gap: 4,
  },
  positions: [
    'top',
    'top-left',
    'top-right',
    'right',
    'right-top',
    'right-bottom',
    'bottom',
    'bottom-left',
    'bottom-right',
    'left',
    'left-top',
    'left-bottom',
    'stretch-top',
    'stretch-bottom',
  ],
};
