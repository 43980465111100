import routes from 'utils/routes';
import { apiSlice } from '../index';

const passwordApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    setPassword: build.mutation({
      query: payload => ({
        url: routes.password.setPassword(),
        method: 'PUT',
        body: payload,
      }),
    }),
    resetPasswordRequest: build.mutation({
      query: payload => ({
        url: routes.password.resetRequest(),
        method: 'PUT',
        body: payload,
      }),
    }),
    resetPassword: build.mutation({
      query: payload => ({
        url: routes.password.resetPassword(),
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
});

export const { useSetPasswordMutation, useResetPasswordRequestMutation, useResetPasswordMutation } = passwordApi;
