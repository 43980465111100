import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Avatar from 'components/base-components/Avatar/Avatar';
import { useGetUserQuery } from 'api/users';

const ContactUser = ({ assignment }) => {
  const { data: user = {} } = useGetUserQuery({ id: assignment.userId });

  return (
    <div className="organisation-overview__contact-user">
      <Avatar src={user.profilePictureUrl} firstName={user.firstName} lastName={user.lastName} />
      <div className="organisation-overview__contact-user--details">
        <span>{user.name}</span>
        <span className="organisation-overview__contact-user__user-role">
          {t(`role.${assignment.role}`)}
        </span>
      </div>
    </div>
  );
};

ContactUser.propTypes = {
  assignment: PropTypes.shape({
    userId: PropTypes.number.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContactUser;
