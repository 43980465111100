import React, { useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import Input from 'components/base-components/Input';
import { useTranslation } from 'react-i18next';
import DatePicker from 'components/base-components/DatePicker';
import Button from 'components/base-components/Button';
import { useGetEducationInstitutesQuery, useGetEducationDegreesQuery } from 'api/educations';
import ChooseFile from 'components/shared-components/choose-file/ChooseFile';
import SelectBox from '../../../../base-components/SelectBox';

const EducationForm = ({ onSave, onCancel, educationCertificate }) => {
  const [enableDatePicker, setEnableDatePicker] = useState(null);
  const { t, i18n } = useTranslation();
  const { data: institutes = [] } = useGetEducationInstitutesQuery();
  const { data: degrees = [] } = useGetEducationDegreesQuery();

  const institutesOptions = institutes.map(institute => ({
    label: t(`educations.institute.${institute}`),
    value: institute,
  }));
  const degreesOptions = degrees.map(degree => ({
    label: t(`educations.degree.${degree}`),
    value: degree,
  }));

  const onSubmit = values => {
    // TODO: Find a good way to handle form
    let formData = new FormData();

    if (values.degree) {
      formData.append('degree', values.degree.value);
    }

    if (values.institute) {
      formData.append('institute', values.institute.value);
    }

    if (values.certificationId) {
      formData.append('certification_id', values.certificationId);
    }

    if (values.certificationDate) {
      formData.append('certification_date', values.certificationDate);
    }

    if (values.diplomaForEducation) {
      formData.append('diploma_for_education', values.diplomaForEducation);
    }
    return onSave(formData)
      .then(() => {
        return null;
      })
      .catch(({ data: { errors } }) => {
        return errors;
      });
  };

  const initialValues = useMemo(() => {
    if (!educationCertificate) {
      return {};
    }
    return {
      degree: {
        label: t(`educations.degree.${educationCertificate.degree}`),
        value: educationCertificate.degree,
      },
      institute: {
        label: t(`educations.institute.${educationCertificate.institute}`),
        value: educationCertificate.institute,
      },
      certificationId: educationCertificate.certificationId,
      certificationDate: educationCertificate.certificationDate,
    };
  }, [JSON.stringify(educationCertificate)]);

  return (
    <div className="organization-education__form">
      <Form
        onSubmit={onSubmit}
        validate={() => {}}
        initialValues={initialValues}
        render={({ handleSubmit, values, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field name="degree">
                {({ input, meta }) => (
                  <SelectBox
                    placeholderText={t('shared.attributes.degree.placeholder')}
                    size="tiny"
                    width="large"
                    label={t('shared.attributes.degree.label')}
                    required={true}
                    isClearable={false}
                    options={degreesOptions}
                    value={input.value}
                    selectedValue={input.label}
                    onChange={value => {
                      input.onChange(value);
                    }}
                    errorMsg={meta.submitError}
                  />
                )}
              </Field>
              <div className="organization-education--margin-top">
                <Field name="institute" initialValue={institutesOptions[0]}>
                  {({ input, meta }) => (
                    <SelectBox
                      size="tiny"
                      width="large"
                      label={t('shared.attributes.institution.label')}
                      required={true}
                      isClearable={false}
                      options={institutesOptions}
                      value={input.value}
                      selectedValue={input.label}
                      onChange={value => {
                        input.onChange(value);
                      }}
                      errorMsg={meta.submitError}
                      placeholderText={t('educations.assignee.select.institute')}
                    />
                  )}
                </Field>
              </div>
              <div className="organization-education--margin-top">
                <Field name="diplomaForEducation">
                  {({ input, meta }) => (
                    <ChooseFile
                      fileName={input.value?.name || educationCertificate?.fileNameForDiploma}
                      label={t('shared.attributes.diploma.label')}
                      onChange={file => {
                        input.onChange(file);
                      }}
                      errorMsg={meta?.submitError}
                      touched={!meta.dirtySinceLastSubmit}
                    />
                  )}
                </Field>
              </div>
              <div className="organization-education--margin-top">
                <Field name="certificationId">
                  {({ input, meta }) => (
                    <Input
                      size="tiny"
                      label={t('shared.attributes.certification_id.label')}
                      placeholder={t('shared.attributes.certification_id.placeholder')}
                      touched={!meta.dirtySinceLastSubmit}
                      error={meta.submitError}
                      {...input}
                    />
                  )}
                </Field>
              </div>
              <div className="organization-education--margin-top">
                <Field name="certificationDate">
                  {({ input, meta }) => (
                    <DatePicker
                      className="user__education--form"
                      focused={enableDatePicker}
                      date={input.value}
                      onChange={e => input.onChange(e)}
                      touched={!meta.dirtySinceLastSubmit}
                      error={meta.submitError}
                      onFocusChange={() => setEnableDatePicker(!enableDatePicker)}
                      displayFormat="LL"
                      label={t('shared.attributes.certification_date.label')}
                      placeholder={t('supplier_certificates.placeholder.certification_date')}
                      navNext="arrowForwardAlt"
                      navPrev="arrowBackAlt"
                      size="small"
                      enablePastDates={true}
                      disableFutureDates={true}
                      futureYearsCount={0}
                      required={true}
                      locale={i18n.language}
                    />
                  )}
                </Field>
                <div className="organization-education--margin-top organization-education__form-action">
                  <Button
                    label={t('shared.action.save')}
                    className="organization-education--margin-right"
                    size="small"
                    onClick={handleSubmit}
                    disabled={!(Object.values(values).length > 0)}
                    type="success"
                  />
                  <Button
                    label={t('shared.action.cancel')}
                    size="small"
                    onClick={() => {
                      form.reset();
                      onCancel();
                    }}
                  />
                </div>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

EducationForm.defaultProps = {
  supplierCertificate: null,
  educationCertificate: null,
};

EducationForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  supplierCertificate: PropTypes.shape(),
  educationCertificate: PropTypes.shape(),
};

export default EducationForm;
