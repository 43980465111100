const ASSIGNMENT_ROLES = {
  member: 'member',
  owner: 'owner',
  certifier: 'certifier',
  auditor: 'auditor',
  operation_manager: 'operation_manager',
  manager: 'manager',
  teacher: 'teacher',
  main_contact: 'main_contact',
  partner: 'partner',
};

export default ASSIGNMENT_ROLES;
