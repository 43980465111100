import PropTypes from 'prop-types';
import CheckBox from 'components/base-components/CheckBox/CheckBox';
import Input from 'components/base-components/Input';
import React from 'react';
import { t } from 'i18next';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import Card from 'components/base-components/Card';
import {
  VEGETABLE,
  VEGETABLES_COVERED_CULTIVATION_WITHOUT_SOLID_LAND_IN_ARES,
  VEGETABLES_COVERED_SOLID_LAND_IN_ARES,
  VEGETABLES_OPEN_FIELD_IN_HECTARES,
} from 'utils/production-data-type';
import TitleComponent from '../shared/title.component';

const VegetablesForm = ({ isVisible, vegetableData, values, validationError }) => {
  if (!isVisible) return;
  return (
    <Card className="plant-cultivation__card">
      <TitleComponent title={t('plant_cultivation.vegetables.title')} icon="vegetable" />
      <div
        className={classNames('plant-cultivation__method--open', {
          'plant-cultivation__method--open__checked': values['vegetables_open_checkbox'],
        })}
      >
        <Field
          name="vegetables_open_checkbox"
          type="checkbox"
          initialValue={vegetableData?.openFieldInHectares}
        >
          {({ input }) => (
            <CheckBox
              isChecked={input.checked}
              size="tiny"
              label={t('plant_cultivation.vegetables.open_field')}
              onChange={e => input.onChange(e.target.checked)}
            />
          )}
        </Field>
        <Field
          name="vegetables_open_field_in_hectares"
          initialValue={vegetableData?.openFieldInHectares}
        >
          {({ input, meta }) => (
            <Input
              size="tiny"
              label={t('plant_cultivation.shared.surface_area_input_label')}
              placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
              disabled={!values.vegetables_open_checkbox}
              touched={!meta.dirtySinceLastSubmit}
              error={meta.submitError || validationError?.[VEGETABLES_OPEN_FIELD_IN_HECTARES]}
              {...input}
            />
          )}
        </Field>
      </div>
      <div
        className={classNames('plant-cultivation__method--open', {
          'plant-cultivation__method--open__checked': values['vegetables_covered_checkbox'],
        })}
      >
        <Field
          name="vegetables_covered_checkbox"
          type="checkbox"
          initialValue={vegetableData?.coveredSolidLandInAres}
        >
          {({ input }) => (
            <CheckBox
              className="plant-cultivation__method--open__vegetable"
              isChecked={input.checked}
              size="tiny"
              label={t('plant_cultivation.vegetables.covered_cultivation_without_foundation')}
              onChange={e => input.onChange(e.target.checked)}
            />
          )}
        </Field>
        <Field
          name="vegetables_covered_solid_land_in_ares"
          initialValue={vegetableData?.coveredSolidLandInAres}
        >
          {({ input, meta }) => (
            <Input
              size="tiny"
              label={t('plant_cultivation.mushroom.surface_area_ares')}
              placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
              disabled={!values.vegetables_covered_checkbox}
              touched={!meta.dirtySinceLastSubmit}
              error={meta.submitError || validationError?.[VEGETABLES_COVERED_SOLID_LAND_IN_ARES]}
              {...input}
            />
          )}
        </Field>
      </div>
      <div
        className={classNames('plant-cultivation__method--open', {
          'plant-cultivation__method--open__checked':
            values['vegetables_covered_without_solid_land_checkbox'],
        })}
      >
        <Field
          name="vegetables_covered_without_solid_land_checkbox"
          type="checkbox"
          initialValue={vegetableData?.coveredCultivationWithoutSolidLandInAres}
        >
          {({ input }) => (
            <CheckBox
              className="plant-cultivation__method--open__vegetable"
              isChecked={input.checked}
              size="tiny"
              label={t('plant_cultivation.vegetables.covered_cultivation_with_foundation')}
              onChange={e => input.onChange(e.target.checked)}
            />
          )}
        </Field>
        <Field
          name="vegetables_covered_cultivation_without_solid_land_in_ares"
          initialValue={vegetableData?.coveredCultivationWithoutSolidLandInAres}
        >
          {({ input, meta }) => (
            <Input
              size="tiny"
              label={t('plant_cultivation.mushroom.surface_area_ares')}
              placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
              disabled={!values.vegetables_covered_without_solid_land_checkbox}
              touched={!meta.dirtySinceLastSubmit}
              error={
                meta.submitError ||
                validationError?.[VEGETABLES_COVERED_CULTIVATION_WITHOUT_SOLID_LAND_IN_ARES]
              }
              {...input}
            />
          )}
        </Field>
      </div>
      {validationError?.[VEGETABLE] && (
        <div className="error-text margin-top-10 margin-bottom-10 margin-left-20">
          {validationError?.[VEGETABLE]}
        </div>
      )}
    </Card>
  );
};

VegetablesForm.defaultProps = {
  isVisible: false,
  vegetableData: {},
  values: {},
  validationError: null,
};

VegetablesForm.propTypes = {
  isVisible: PropTypes.bool,
  vegetableData: PropTypes.shape(),
  values: PropTypes.shape(),
  validationError: PropTypes.shape(),
};

export default VegetablesForm;
