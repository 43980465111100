import React from 'react';
import { useCreateUserPhoneMutation, useUpdateUserPhoneMutation } from 'api/users';
import Button from 'components/base-components/Button';
import CheckBox from 'components/base-components/CheckBox';
import Input from 'components/base-components/Input';
import SelectBox from 'components/base-components/SelectBox';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { removeEmptyFromObject, toSnakecaseKeys } from 'utils/object-cleanup';

const PhoneForm = ({ setIsPhoneSidepanelOpen, editPhone }) => {
  const [createPhone] = useCreateUserPhoneMutation();
  const [updatePhone] = useUpdateUserPhoneMutation();
  const { t } = useTranslation();
  const phoneTypeOptions = [
    {
      label: t('user.phone_type.cellphone'),
      value: 'cellphone',
    },
    {
      label: t('user.phone_type.home'),
      value: 'home',
    },
    {
      label: t('user.phone_type.work'),
      value: 'work',
    },
    {
      label: t('user.phone_type.other'),
      value: 'other',
    },
  ];

  const { userId } = useParams();

  const onSubmit = (values, form) => {
    values['number'] = values['number']?.replace(/ /g, '');
    values['user_id'] = userId;

    const payload = removeEmptyFromObject(values);

    if (editPhone) {
      return updatePhone({ params: { userId, id: editPhone.id }, payload })
        .unwrap()
        .then(() => {
          form.reset();
          setIsPhoneSidepanelOpen(false);
        })
        .catch(({ status, data: { errors } }) => toSnakecaseKeys(errors));
    } else {
      return createPhone({ userId, payload })
        .unwrap()
        .then(() => {
          form.reset();
          setIsPhoneSidepanelOpen(false);
        })
        .catch(({ status, data: { errors } }) => toSnakecaseKeys(errors));
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="user__edit--form-content">
              <div className="col-12 col-bleed">
                <Field name="number_type" initialValue={editPhone?.numberType || null}>
                  {({ input, meta }) => (
                    <SelectBox
                      size="small"
                      width="large"
                      label={t('user_contact.phone_type.label')}
                      placeholderText={t('user_contact.phone_type.placeholder')}
                      isClearable={false}
                      options={phoneTypeOptions}
                      value={phoneTypeOptions.find(option => option.value === input.value)}
                      selectedValue={input.label}
                      onChange={e => input.onChange(e.value)}
                      touched={!meta.dirtySinceLastSubmit}
                      errorMsg={meta?.submitError}
                      required={true}
                    />
                  )}
                </Field>
              </div>
              <div className="col-12 col-bleed">
                <Field name="number" initialValue={editPhone?.number || null}>
                  {({ input, meta }) => (
                    <Input
                      size="small"
                      label={t('user_contact.phone_number.label')}
                      placeholder={t('user_contact.phone_number.placeholder')}
                      touched={!meta.dirtySinceLastSubmit}
                      error={meta?.submitError}
                      required={true}
                      {...input}
                    />
                  )}
                </Field>
              </div>
              <div className="col-12 col-grid justify-center col-bleed">
                <Field name="primary" type="checkbox" initialValue={editPhone?.primary || false}>
                  {({ input, meta }) => (
                    <CheckBox
                      label={t('user_contact.phone_check')}
                      isChecked={input.checked}
                      size="tiny"
                      onChange={e => input.onChange(e.target.checked)}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="col-12 col-bleed">
              <Button
                className="user__edit--submit"
                label={editPhone ? t('common.save_button') : t('user_education.add_button')}
                type="success"
                submitType="submit"
                size="small"
                disabled={submitting}
              />
            </div>
          </form>
        );
      }}
    />
  );
};

PhoneForm.defaultProps = {
  editPhone: null,
  setIsPhoneSidepanelOpen: () => {},
};

PhoneForm.propTypes = {
  editPhone: PropTypes.shape(),
  setIsPhoneSidepanelOpen: PropTypes.func,
};

export default PhoneForm;
