import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Tabs } from 'components/base-components/RTabs';
import { useSearchParams } from 'react-router-dom';
import '../../organisations.styles.scss';
import ControlBodyComponent from '../../../control-body/control-body.component';

const ControlAndOverview = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams({
    tab: 'overview',
  });

  const TAB_MAPPER = {
    overview: 0,
    control_body: 1,
  };

  const currentTab = searchParams.get('tab');

  const onChangeTab = selectedTabKey => {
    const selectedTab = Object.keys(TAB_MAPPER).find(tab => TAB_MAPPER[tab] === selectedTabKey);
    setSearchParams({ tab: selectedTab });
  };

  return (
    <>
      <div className="organisation__rtabs">
        <Tabs
          controlled={true}
          selectedTabKey={TAB_MAPPER[currentTab]}
          items={[
            {
              title: t('organisation_view.tabs.overview'),
              key: TAB_MAPPER.overview,
              getContent: () => {
                return children;
              },
            },
            {
              title: t('organisation_overview.control_body'),
              key: TAB_MAPPER.control_body,
              getContent: () => <ControlBodyComponent />,
            },
          ]}
          onChange={onChangeTab}
        />
      </div>
    </>
  );
};

ControlAndOverview.propTypes = {
  children: PropTypes.node.isRequired,
  currentTab: PropTypes.node.isRequired,
  onChangeTab: PropTypes.func.isRequired,
};

export default ControlAndOverview;
