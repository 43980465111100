import { Text, View } from '@react-pdf/renderer';
import styles from 'components/pdf/styles/checklist-styles';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { FINAL } from 'utils/verification-status';

const AccountingProcess = ({ accountingProcess, comments, updatedAccountingTask, reportType }) => {
  return (
    <>
      <View style={styles.checkListFlexSpaceBetween}>
        <Text style={styles.checklistMarginForty}>
          <Text style={styles.bold}>7. </Text> {t('control_body.survey.form.seventh_step')}
        </Text>
        <Text style={styles.checklistMarginForty}>
          {accountingProcess?.label || t('common.not_applicable')}
        </Text>
      </View>
      {comments.accountingProcess && (
        <View style={styles.baseComment}>
          <Text>
            {t('control_body.survey.form.comment_from_auditor')} {comments.accountingProcess}
          </Text>
        </View>
      )}

      {reportType === FINAL && updatedAccountingTask && (
        <View style={styles.checklistMarginTwenty}>
          <Text>
            <Text style={styles.heading2}>{t('control_body.survey.form.newly_declared')}:</Text>{' '}
            {updatedAccountingTask.label}
          </Text>
          <Text>{t('control_body.survey.form.bio_suisse_verify_title')}</Text>
        </View>
      )}
    </>
  );
};

AccountingProcess.defaultProps = {
  updatedAccountingTask: null,
};

AccountingProcess.propTypes = {
  accountingProcess: PropTypes.shape().isRequired,
  comments: PropTypes.shape().isRequired,
  updatedAccountingTask: PropTypes.shape(),
  reportType: PropTypes.string.isRequired,
};

export default AccountingProcess;
