import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'components/base-components/Button';
import IconButton from 'components/base-components/IconButton';
import Tooltip from 'components/base-components/Tooltip';
import Icon from 'components/base-components/Icon';

import './choose-file.styles.scss';

const ChooseFile = ({
  className,
  fileName,
  showText,
  labelText,
  label,
  disabled,
  touched,
  errorMsg,
  onChange,
  withIconButton,
  disableIconButton,
  acceptedType,
  size,
  isIconOnly,
}) => {
  const inputFile = useRef(null);
  const { t } = useTranslation();

  const openFileWindow = () => {
    inputFile.current.click();
  };

  if (withIconButton) {
    return (
      <Fragment>
        <IconButton
          className="topnav__back-button"
          icon={<Icon name="uploadFile" size={size} />}
          dataTestId="upload-file"
          color="default"
          isIconOnly={isIconOnly}
          onClick={openFileWindow}
          disabled={disableIconButton}
        />
        <input
          type="file"
          id="file"
          accept={acceptedType}
          ref={inputFile}
          data-testid="input-file"
          hidden={true}
          onChange={e => {
            onChange(e.target.files[0]);
            e.target.value = null;
          }}
        />
      </Fragment>
    );
  }

  return (
    <div className={classNames(className, 'choose-file')}>
      {label && <div className="choose-file__label">{label}</div>}
      <div className="choose-file__input">
        <input
          type="file"
          accept={acceptedType}
          id="file"
          ref={inputFile}
          hidden={true}
          data-testid="input-file"
          onChange={e => {
            onChange(e.target.files[0]);
            e.target.value = null;
          }}
        />
        <Button
          className="choose-file__button"
          label={labelText || t('user.form.choose_file')}
          size="small"
          version="v2"
          disabled={disabled}
          onClick={openFileWindow}
        />
        {showText && (fileName || t('user.form.no_file_chosen'))}
        {touched && errorMsg && (
          <Tooltip content={errorMsg} type="danger" position="bottom-right" gap={0}>
            <Icon name="invalid" color="danger" />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

ChooseFile.defaultProps = {
  className: null,
  fileName: null,
  label: null,
  size: 'small',
  acceptedType: null,
  labelText: null,
  touched: true,
  disabled: false,
  showText: true,
  errorMsg: null,
  onChange: () => {},
  withIconButton: false,
  disableIconButton: false,
  isIconOnly: true,
};

ChooseFile.propTypes = {
  className: PropTypes.string,
  acceptedType: PropTypes.string,
  fileName: PropTypes.node,
  label: PropTypes.string,
  labelText: PropTypes.node,
  size: PropTypes.string,
  touched: PropTypes.bool,
  showText: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMsg: PropTypes.string,
  onChange: PropTypes.func,
  withIconButton: PropTypes.bool,
  disableIconButton: PropTypes.bool,
  isIconOnly: PropTypes.bool,
};

export default ChooseFile;
