import { NO_RATING } from 'utils/organisation-stars';
import { memberOptions } from '../filter/filter-container.component';

export const getSelectedMembers = (minRangeOfUsers, minimumUsers) => {
  const membersRange = minRangeOfUsers.map(
    range => memberOptions.find(option => option.key.min === parseInt(range)).key,
  );

  if (minimumUsers) {
    membersRange.push(memberOptions.find(option => !option.key.max).key);
  }

  return membersRange;
};

export const getSelectedStars = (stars, noRating) => {
  const selectedStars = stars.map(star => parseInt(star));
  if (noRating) selectedStars.push(NO_RATING);

  return selectedStars;
};
