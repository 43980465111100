import {
  useAcceptChartaItemMutation,
  useDeclareChartaMutation,
  useGetActiveChartaQuery,
  useGetChartaItemsQuery,
  useGetWipChartaQuery,
} from 'api/chartas';
import { useGetCurrentUserQuery } from 'api/users';
import classNames from 'classnames';
import EmphasisTag from 'components/base-components/EmphasisTag';
import Stepper from 'components/base-components/stepper';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CHARTA_ITEMS_LABEL from 'utils/charta-items-label';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import useCustomNavigate from 'hooks/useCustomNavigate';
import Button from '../../base-components/Button';
import Card from '../../base-components/Card';
import Radio from '../../base-components/Radio';
import ChartaValidity from '../../shared-components/charta-validity/charta-validity.component';
import WarningModal from '../../shared-components/modal/warning';
import SanitizedHTML from '../../shared-components/sanitized-html';
import DefaultSpinner from '../../shared-components/default-spinner';
import CreateCharta from './create.component';

const Charta = ({ organizationId }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [chartaState, setChartaState] = useState(CHARTA_ITEMS_LABEL.sustainability);
  const [modalOpen, setModalOpen] = useState(false);
  const [fetchActiveCharta, setFetchActiveCharta] = useState(true);
  const [isLastStepAccepted, setIsLastStepAccepted] = useState(false);
  const { data: currentUser } = useGetCurrentUserQuery();

  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  let charta;

  const { data: activeCharta, isFetching: isFetchingActiveCharta } = useGetActiveChartaQuery(
    { organizationId },
    { skip: !fetchActiveCharta },
  );

  const {
    data: wipCharta,
    error,
    isFetching: isFetchingWipCharta,
  } = useGetWipChartaQuery({ organizationId }, { skip: isFetchingActiveCharta || activeCharta });

  charta = activeCharta || wipCharta;

  const params = {
    state: chartaState,
    organizationId: organizationId,
    chartaId: charta && charta.id,
  };
  const { data: chartaItems } = useGetChartaItemsQuery(params, {
    skip: !chartaState || !charta,
  });

  const [acceptChartaItem] = useAcceptChartaItemMutation();

  const [declareCharta, { isSuccess: isDeclared, requestId }] = useDeclareChartaMutation();

  const onAcceptChartaItem = chartaItemId => {
    acceptChartaItem({ organizationId, chartaId: charta.id, chartaItemId });
  };

  const onFinish = () => {
    setModalOpen(true);
  };

  const onDeclare = () => {
    declareCharta({ organizationId, chartaId: charta.id })
      .unwrap()
      .then(() => {
        setModalOpen(false);
        setFetchActiveCharta(true);
      });
  };

  const STEP_MAPPER = {
    0: 'sustainability',
    1: 'fraudProtection',
    2: 'restrictedProduct',
  };

  const onMoveNextOrPrevious = index => {
    const state = STEP_MAPPER[index];
    setChartaState(CHARTA_ITEMS_LABEL[state]);
    setActiveIndex(index);
  };

  const onFetchedCharta = status => {
    if (!status.sustainability.completed) {
      setChartaState(CHARTA_ITEMS_LABEL.sustainability);
      setActiveIndex(0);
    } else if (!status.fraudProtection.completed) {
      setChartaState(CHARTA_ITEMS_LABEL.fraudProtection);
      setActiveIndex(1);
    } else if (!status.restrictedProduct.completed) {
      setChartaState(CHARTA_ITEMS_LABEL.restrictedProduct);
      setActiveIndex(2);
    } else if (status.restrictedProduct.completed) {
      setIsLastStepAccepted(true);
    }
  };

  useEffect(() => {
    if (charta) {
      onFetchedCharta(charta.status);
    }
  }, [JSON.stringify(charta)]);

  useEffect(() => {
    if (charta && !charta.completed) {
      setFetchActiveCharta(false);
    }
  }, [charta && charta.completed]);

  const items = chartaItems ? chartaItems : [];

  const isChartaDeclared = charta && !!charta.declaredAt;

  const isCompletedLastStep =
    activeIndex + 1 == Object.keys(STEP_MAPPER).length && isChartaDeclared;

  const disableMoveForward =
    !(charta && charta.status[STEP_MAPPER[activeIndex]].completed) || isCompletedLastStep;

  const isDeclareDisabled =
    activeIndex === Object.keys(STEP_MAPPER).length - 1 && isBioSuisseAdmin(currentUser);

  const isAllowedToModify = charta && charta.isAllowedToModify;

  const isFetching = isFetchingActiveCharta || isFetchingWipCharta;

  if (isFetching && !charta) {
    return <DefaultSpinner className="default-spinner--center" />;
  }

  if (!isFetching && !charta) {
    return (
      <CreateCharta
        organizationId={organizationId}
        isAllowedToCreate={error && error.data?.isAllowedToModify}
      />
    );
  }

  return (
    <div className="organisation-charta">
      <SubmitModal
        isSuccess={isDeclared}
        requestId={requestId}
        successTitle={t('charta.modal.declaration_successful.title')}
        successContent={t('charta.modal.declaration_successful.body')}
        showError={false}
        showLoader={false}
        onSuccess={() => navigate.openOrganisationDetailsPage(organizationId)}
      />
      <WarningModal
        onOutsideClick={() => {
          setModalOpen(false);
        }}
        isVisible={modalOpen}
        title={t('charta.modal.title')}
        content={
          <div className="organisation-charta__modal-body">
            <span className="organisation-charta__modal-body-placeholder">
              {t('charta.modal.body.message')}
            </span>
            <span className="organisation-charta__modal-body-proceed">
              {t('charta.modal.body.proceed_permission')}
            </span>
          </div>
        }
        confirmActionText={t('charta.action.declare')}
        cancelActionText={t('shared.action.cancel')}
        isDisabled={!isAllowedToModify}
        onCancel={() => setModalOpen(false)}
        onConfirm={onDeclare}
      />
      <div className="organisation-charta__validity">
        {activeCharta ? (
          <div className="organisation-charta__validity-time">
            <ChartaValidity
              validFrom={activeCharta.validFrom}
              validUntil={activeCharta.validUntil}
              declaredAt={activeCharta.declaredAt}
              tag={
                <span
                  className={classNames({
                    'organisation-charta__validity-time--tag-spacing':
                      !activeCharta.validFrom || !activeCharta.validUntil,
                  })}
                >
                  {isChartaDeclared && (
                    <EmphasisTag
                      radius="oval"
                      size="tiny"
                      text={t('shared.verified')}
                      type="success"
                    />
                  )}
                </span>
              }
            />
          </div>
        ) : (
          <Fragment>
            <span>
              <Trans
                i18nKey="charta.time_period.current"
                values={{ timePeriod: t('charta.time_period.no_date_available') }}
                components={{ bold: <b /> }}
              />
            </span>
            <span>
              <Trans
                i18nKey="charta.time_period.declared_on"
                values={{ timePeriod: t('charta.time_period.no_date_available') }}
                components={{ bold: <b /> }}
              />
            </span>
          </Fragment>
        )}
      </div>
      <Stepper
        activeStepIndex={activeIndex}
        direction="horizontal"
        finishButtonLabel={t('charta.action.declare')}
        nextButtonLabel={t('shared.action.next_step')}
        onFinish={onFinish}
        onMoveBack={onMoveNextOrPrevious}
        onMoveNext={onMoveNextOrPrevious}
        previousButtonLabel={t('shared.action.previous_step')}
        showActionButtons={true}
        disableMoveForward={disableMoveForward || isDeclareDisabled}
        showStepTitle={true}
        doneAllStep={charta.declared}
        steps={[
          { stepName: t('charta.attributes.sustainability') },
          { stepName: t('charta.attributes.fraud_protection') },
          { stepName: t('charta.attributes.restricted_product') },
        ]}
        isLastStepAccepted={isLastStepAccepted}
      />
      <Card className="organisation-charta--margin-top">
        {chartaState && (
          <div className="organisation-charta__title">
            {t(`charta.attributes_title.${chartaState}`)}
          </div>
        )}
        <div className="organisation-charta__placeholder">
          <div className="organisation-charta__placeholder-policy-label">
            {t('charta.policy_label')}
          </div>
          <div className="organisation-charta__placeholder-declare-policy">
            {t('charta.declare_policy')}
          </div>
        </div>

        <div className="organisation-charta__items">
          {items.map(item => (
            <div className="organisation-charta__items-item" key={item.id}>
              <Radio
                hint={null}
                isChecked={!!item.accepted}
                label={null}
                name={`item-${item.id}`}
                onChange={() => {}}
                size="XL"
                withCheckIcon={true}
              />
              <div className="organisation-charta__items-item-details">
                <div className="organisation-charta__items-item-details-title">{item.title}</div>
                <div className="organisation-charta__items-item-details-description">
                  <SanitizedHTML html={item.description} />
                </div>
                <Button
                  label={
                    chartaState == CHARTA_ITEMS_LABEL.fraudProtection
                      ? t('charta.action.fraud_protection.accept')
                      : t('charta.action.accept')
                  }
                  onClick={() => {
                    onAcceptChartaItem(item.id);
                  }}
                  type="success"
                  size="tiny"
                  disabled={item.accepted || !isAllowedToModify}
                />
              </div>
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};

Charta.propTypes = {
  organizationId: PropTypes.node.isRequired,
};

export default Charta;
