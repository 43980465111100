import Button from 'components/base-components/Button';
import DatePicker from 'components/base-components/DatePicker/DatePicker';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import SelectBox from 'components/base-components/SelectBox';
import WarningModal from 'components/shared-components/modal/warning';
import { useGetTerminationReasonOptionsQuery, useTerminateContractMutation } from 'api/contract';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import { dateMonthYear } from 'utils/date-time-formatter';

const TerminateContractForm = ({ organisationName, contractId, setIsSidepanelOpen }) => {
  const { t, i18n } = useTranslation();
  const [isStartsAtOpen, setIsStartsAtOpen] = useState(false);
  const [isTerminateConfirmationOpen, setIsTerminateConfirmationOpen] = useState(false);

  const { data: terminateReasonsData = [] } = useGetTerminationReasonOptionsQuery();

  const terminationReasonOptions = terminateReasonsData.map(reason => ({
    label: t(`contract.reasons.${reason}`),
    value: reason,
  }));

  const [
    terminateContract,
    { isLoading: isTerminateLoading, isSuccess: isTerminateSuccess, requestId: terminateRequestId },
  ] = useTerminateContractMutation();

  const onSubmit = (values, form) => {
    return terminateContract({
      queryParams: { contractId },
      payload: {
        date: values.date,
        reason: values.reason,
      },
    })
      .unwrap()
      .then(() => {
        form.reset();
        setIsTerminateConfirmationOpen(false);
      })
      .catch(({ data: { errors } }) => {
        setIsTerminateConfirmationOpen(false);
        return errors;
      });
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Field name="date">
            {({ input, meta }) => (
              <DatePicker
                focused={isStartsAtOpen}
                required={true}
                date={input.value}
                onChange={e => input.onChange(e)}
                onFocusChange={() => setIsStartsAtOpen(!isStartsAtOpen)}
                displayFormat="LL"
                label={t('contract.fields.termination_date')}
                placeholder={t('contract.fields.termination_placeholder')}
                size="tiny"
                hideNavButtons={false}
                minDate={moment().format('YYYY-MM-DD')}
                touched={!meta.dirtySinceLastSubmit}
                error={meta.submitError}
                locale={i18n.language}
              />
            )}
          </Field>
          <div className="organisation-contract__reason">
            <Field name="reason">
              {({ input, meta }) => (
                <SelectBox
                  size="tiny"
                  width="large"
                  label={t('contract.fields.reason_for_termination')}
                  placeholderText={t('contract.fields.reason_placeholder')}
                  isClearable={false}
                  options={terminationReasonOptions}
                  value={terminationReasonOptions.find(option => option.value === input.value)}
                  onChange={e => input.onChange(e.value)}
                  required={true}
                  errorMsg={meta.submitError}
                />
              )}
            </Field>
          </div>
          <div>
            <Button
              className="organisation-contract__buttons"
              label={t('common.continue')}
              type="success"
              size="small"
              disabled={submitting}
              onClick={() => setIsTerminateConfirmationOpen(true)}
            />
            <Button
              className="organisation-contract__buttons-cancel"
              label={t('common.cancel')}
              size="small"
              onClick={() => setIsSidepanelOpen(false)}
            />
          </div>
          <WarningModal
            onOutsideClick={() => setIsTerminateConfirmationOpen(false)}
            isVisible={isTerminateConfirmationOpen}
            title={t('contract.producer.terminate_modal.warning_title')}
            content={
              <div>
                <Trans
                  i18nKey="contract.producer.terminate_modal.warning_description"
                  values={{ name: organisationName, date: dateMonthYear(values.date) }}
                  components={{ bold: <b /> }}
                />
              </div>
            }
            confirmActionText={t('contract.producer.button.terminate')}
            cancelActionText={t('shared.go_back')}
            onCancel={() => setIsTerminateConfirmationOpen(false)}
            onConfirm={handleSubmit}
          />
          <SubmitModal
            isLoading={isTerminateLoading}
            isSuccess={isTerminateSuccess}
            requestId={terminateRequestId}
            successTitle={t('contract.producer.terminate_modal.success_title')}
            successContent={
              <div>
                <Trans
                  i18nKey={'contract.producer.terminate_modal.success_description'}
                  values={{ name: organisationName }}
                  components={{ bold: <b /> }}
                />
              </div>
            }
            showError={false}
            showLoader={false}
            onSuccess={() => setIsSidepanelOpen(false)}
          />
        </form>
      )}
    />
  );
};

TerminateContractForm.propTypes = {
  setIsSidepanelOpen: PropTypes.func.isRequired,
  organisationName: PropTypes.string.isRequired,
  contractId: PropTypes.number.isRequired,
};

export default TerminateContractForm;
