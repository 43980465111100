import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Badge from '../Badge/index';

const generateClassName = (element, modifier) => {
  if (modifier) return `${element}--${modifier}`;

  return null;
};

const IconButton = props => {
  const {
    style,
    standalone,
    dark,
    disabled,
    action,
    isIconOnly,
    isInverse,
    onClick,
    label,
    labelPlacement,
    icon,
    className,
    size,
    badgeCount,
    color,
    padding,
    dataTestId,
  } = props;
  const backgroundColor = style.backgroundColor;
  const inlineStyle = backgroundColor !== '' ? { backgroundColor } : {};

  return (
    <div
      className={classNames(
        `icon-button ${className}`,
        generateClassName('icon-button', size),
        `icon-button--padding-${padding}`,
        {
          [`icon-button--${color}`]: color,
          'icon-button--standalone': standalone,
          'icon-button-dark': dark,
          'icon-button--disabled': disabled,
          'icon-button--action': action,
          'icon-button--icon-only': isIconOnly,
          [`icon-button--${color}-inverse`]: isInverse,
          [`icon-button--${size}`]: size,
        },
      )}
      style={inlineStyle}
      onClick={onClick}
      role="presentation"
      data-testid={dataTestId}
    >
      {label && (
        <span
          className={classNames(
            'icon-button__label',
            generateClassName('icon-button__label', labelPlacement),
          )}
        >
          {label}
        </span>
      )}
      <div className="icon-button__icon">{icon}</div>
      {badgeCount > 0 ? <Badge classNameForBadge="icon-button__badge" /> : null}
    </div>
  );
};

IconButton.defaultProps = {
  isIconOnly: false,
  label: null,
  action: false,
  standalone: false,
  size: 'small',
  dark: false,
  labelPlacement: null,
  disabled: false,
  onClick: () => {},
  className: '',
  style: {
    backgroundColor: '',
  },
  isInverse: false,
  color: 'default',
  badgeCount: 0,
  padding: 'around',
  dataTestId: null,
};

IconButton.propTypes = {
  action: PropTypes.bool,
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node.isRequired,
  isIconOnly: PropTypes.bool,
  label: PropTypes.node,
  labelPlacement: PropTypes.oneOf(['right', 'left', 'top']),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['xxs', 'xs', 'tiny', 'small', 'large', 'huge']),
  standalone: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.shape({
    backgroundColor: PropTypes.string,
  }),
  isInverse: PropTypes.bool,
  color: PropTypes.oneOf([
    'default',
    'tertiary',
    'success',
    'warning',
    'danger',
    'primary-text',
    'warning-dark',
    'success-dark',
    'danger-dark',
    'white',
  ]),
  badgeCount: PropTypes.number,
  padding: PropTypes.oneOf(['top', 'right', 'bottom', 'left', 'around', 'none']),
  dataTestId: PropTypes.string,
};

export default IconButton;
