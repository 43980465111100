import { useGetProductsQuery } from 'api/product-declarations';
import Button from 'components/base-components/Button';
import Input from 'components/base-components/Input';
import SelectBox from 'components/base-components/SelectBox';
import Textarea from 'components/base-components/Textarea';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

const ProductComplaintForm = ({ onSave, onCancel, organizationId }) => {
  const { t } = useTranslation();
  const { data: products = [] } = useGetProductsQuery({
    organizationId,
    'types[]': ['new_declaration', 'submitted', 'updated', 'complained'],
  });

  const productOptions = useMemo(() => {
    return products.map(product => ({
      label: product.name,
      value: product.id,
    }));
  }, [JSON.stringify(products)]);

  return (
    <Form
      onSubmit={onSave}
      render={({ handleSubmit, submitting, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div>
              <Field name="product">
                {({ input, meta }) => (
                  <SelectBox
                    size="tiny"
                    width="large"
                    className="organisation__form--margin-top"
                    label={t('product_declarations.attributes.product_name')}
                    required={true}
                    isClearable={false}
                    options={productOptions}
                    value={input.value}
                    selectedValue={input.label}
                    onChange={value => {
                      input.onChange(value);
                    }}
                    errorMsg={meta.submitError}
                  />
                )}
              </Field>
            </div>
            <div className="product-declarations__form--margin-top">
              <Field name="title">
                {({ input, meta }) => (
                  <Input
                    {...input}
                    size="tiny"
                    label={t('product_declarations.complaint.title.label')}
                    placeholder={t('product_declarations.complaint.title.placeholder')}
                    touched={!meta.dirtySinceLastSubmit}
                    error={meta.submitError}
                    required={true}
                  />
                )}
              </Field>
            </div>
            <div className="product-declarations__form--margin-top">
              <Field name="description">
                {({ input, meta }) => (
                  <Textarea
                    {...input}
                    size="tiny"
                    label={t('product_declarations.complaint.describe.label')}
                    placeholder={t('product_declarations.complaint.describe.placeholder')}
                    touched={!meta.dirtySinceLastSubmit}
                    error={meta.submitError}
                    required={true}
                  />
                )}
              </Field>
            </div>
            <div className="product-declarations__form--margin-top product-declarations__form-action">
              <Button
                label={t('shared.action.submit')}
                size="small"
                disabled={submitting || !values.product}
                type="success"
                submitType="submit"
              />
              <Button label={t('shared.action.cancel')} size="small" onClick={onCancel} />
            </div>
          </form>
        );
      }}
    />
  );
};

ProductComplaintForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  organizationId: PropTypes.number.isRequired,
};

export default ProductComplaintForm;
