import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import TwoFactorConfiguration from './two-factor/form.component';
import CredentialForm from './credential-form-component';

const CredentialEditComponent = () => {
  const [enableConfiguration, setEnableConfiguration] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div className="user__form--title">
        {enableConfiguration ? (
          <>
            <IconButton
              icon={<Icon name="goBack" size="small" />}
              onClick={() => setEnableConfiguration(false)}
              size="xxs"
            />
            <span className='user__form--title-two-factor'>{t('user_credential.two_factor.header')}</span>
          </>
        ) : (
          t('user_edit.credential_tab')
        )}
      </div>
      <Card className="user__credential-form--card">
        {enableConfiguration ? (
          <TwoFactorConfiguration onSave={() => setEnableConfiguration(false)} />
        ) : (
          <CredentialForm setEnableConfiguration={setEnableConfiguration} />
        )}
      </Card>
    </>
  );
};

export default CredentialEditComponent;
