import React from 'react';
import Card from 'components/base-components/Card';
import Title from 'components/base-components/Typography/components/Title';
import { useGetTasksQuery } from 'api/tasks';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useGetCurrentUserQuery } from 'api/users';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import TaskComponent from './task.component';

const TasksList = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const navigationLinks = useNavigationLinks();
  const { t } = useTranslation();

  const searchParams = {
    status: 'pending',
    q: '',
    page: 1,
    per_page: 5,
    'assigned_to[]': isBioSuisseAdmin(currentUser) ? ['me']: ['me', 'organizations'],
  };

  const { data: taskDataCollection, isSuccess: isTaskFetched } = useGetTasksQuery(searchParams);
  const taskData = (isTaskFetched && taskDataCollection.collection) || [];

  return (
    <div className="dashboard__container-item">
      <Card>
        <Title level="6" weight="boldest" className="dashboard__tasks-title">
          {t('tasks.tab.my_tasks')}
        </Title>
        <TaskComponent taskData={taskData} isFetched={isTaskFetched} />
        {isTaskFetched && taskData.length > 0 && (
          <Link to={navigationLinks.tasksListPage()}>
            <div className="dashboard__tasks-see-all">{t('dashboard.see_task')}</div>
          </Link>
        )}
      </Card>
    </div>
  );
};

export default TasksList;
