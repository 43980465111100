import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';
import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import classNames from 'classnames';

const EmptyContentPlaceholder = ({ className, text, iconName, showCard }) => {
  return (
    <div className={classNames('empty-content-placeholder empty-content-placeholder--top-gap', className)}>
      {showCard ? (
        <Card
          className="empty-content-placeholder--with-card"
          color="success"
          emptyCardImageSrc={<Icon name={iconName} />}
          emptyCardText={text}
        />
      ) : (
        <div className="empty-content-placeholder--no-card">
          <div className="empty-card">
            <div className="empty-card__icon">
              <Icon name={iconName} color="default" />
            </div>
            <div className="empty-card__text">{text}</div>
          </div>
        </div>
      )}
    </div>
  );
};

EmptyContentPlaceholder.defaultProps = {
  iconName: 'add',
  text: 'No results found',
  showCard: true,
  className: null,
};

EmptyContentPlaceholder.propTypes = {
  iconName: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  showCard: PropTypes.bool,
};

export default EmptyContentPlaceholder;
