import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Popover from 'components/base-components/Popover';
import OrganizationName from '../organization-name';

const SubOrganisation = ({ organization }) => {
  const [isSubOrganizationsVisible, setIsSubOrganizationsVisible] = useState(false);
  const { t } = useTranslation();
  const totalSubOrganizations = organization?.subOrgIds?.length;

  return (
    <Popover
      isVisible={isSubOrganizationsVisible && totalSubOrganizations > 0}
      size="medium"
      content={
        <div className="organisation__table-row--sub-organizations">
          {organization?.subOrgIds?.map(subOrgId => (
            <OrganizationName
              key={`sub-org-${subOrgId}`}
              organizationId={subOrgId}
              disableLink={true}
            />
          ))}
        </div>
      }
      direction="bottom-right"
      onOutsideClick={() => setIsSubOrganizationsVisible(false)}
    >
      <span onClick={() => setIsSubOrganizationsVisible(!isSubOrganizationsVisible)}>
        {totalSubOrganizations ? (
          <span className="organisation__table-row--sub-organizations-multiple organisation__table-row--violet-color">
            {t('organisation.sub_organizations', { count: totalSubOrganizations })}
          </span>
        ) : (
          t('shared.no_availability')
        )}
      </span>
    </Popover>
  );
};

SubOrganisation.propTypes = {
  organization: PropTypes.shape().isRequired,
};

export default SubOrganisation;
