import routes from 'utils/routes';
import {
  PRODUCT,
  PRODUCT_DECLARATION,
  PRODUCT_OVERVIEW,
  ORGANISATION_OVERVIEW,
  ORGANISATION_DECLARATION_STATUS,
  ORGANISATION,
} from 'utils/tag-types';
import { apiSlice } from '../index';

const productDeclarationApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getProductDeclarations: build.query({
      query: params => routes.productDeclarations.collection(params),
      providesTags: [PRODUCT_DECLARATION],
    }),
    getProductDeclarationsOverview: build.query({
      query: params => routes.productDeclarations.overview(params),
      providesTags: [PRODUCT_OVERVIEW],
    }),
    submitProductDeclaration: build.mutation({
      query: organizationId => ({
        url: routes.productDeclarations.submit({ organizationId }),
        method: 'PUT',
      }),
      invalidatesTags: [PRODUCT_DECLARATION, PRODUCT_OVERVIEW],
    }),
    getProducts: build.query({
      query: params => routes.products.collection(params),
      providesTags: [PRODUCT],
    }),
    getProduct: build.query({
      query: params => routes.products.entity(params),
      providesTags: result => [{ type: PRODUCT, id: result?.id }],
    }),
    getProductCategories: build.query({
      query: () => routes.products.categories(),
      providesTags: [PRODUCT],
    }),
    createProduct: build.mutation({
      query: ({ organizationId, payload }) => ({
        url: routes.products.collection({ organizationId }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (_, error) => (error ? [] : [PRODUCT_DECLARATION]),
    }),
    updateProduct: build.mutation({
      query: ({ params, payload }) => ({
        url: routes.products.entity(params),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error) =>
        error ? [] : [PRODUCT_DECLARATION, { type: PRODUCT, id: result?.id }],
    }),
    deleteProduct: build.mutation({
      query: params => ({
        url: routes.products.entity(params),
        method: 'DELETE',
      }),
      invalidatesTags: [PRODUCT_DECLARATION],
    }),
    complaintProduct: build.mutation({
      query: ({ params, payload }) => ({
        url: routes.products.complaints(params),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, id) =>
        !error ? [PRODUCT_DECLARATION, PRODUCT_OVERVIEW] : [],
    }),
    approveProductDeclarations: build.mutation({
      query: params => ({
        url: routes.productDeclarations.approve(params),
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: [
        PRODUCT_DECLARATION,
        PRODUCT_OVERVIEW,
        ORGANISATION,
        ORGANISATION_OVERVIEW,
        ORGANISATION_DECLARATION_STATUS,
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProductDeclarationsQuery,
  useGetProductQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useSubmitProductDeclarationMutation,
  useApproveProductDeclarationsMutation,
  useGetProductDeclarationsOverviewQuery,
  useGetProductsQuery,
  useComplaintProductMutation,
  useGetProductCategoriesQuery,
} = productDeclarationApi;
