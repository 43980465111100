import React from 'react';
import PropTypes from 'prop-types';
import SharedTopnavTaskComponent from './shared-topnav-task.component';

const OrganisationTopnavTask = ({ setIsSidePanelOpen }) => {
  return (
    <SharedTopnavTaskComponent taskType="organizations" setIsSidePanelOpen={setIsSidePanelOpen} />
  );
};

OrganisationTopnavTask.defaultProps = {
  setIsSidePanelOpen: () => {},
};

OrganisationTopnavTask.propTypes = {
  setIsSidePanelOpen: PropTypes.func,
};

export default OrganisationTopnavTask;
