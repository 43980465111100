import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Card from 'components/base-components/Card';
import Button from 'components/base-components/Button';
import SidePanel from 'components/base-components/SidePanel';
import GoodsFormComponent from './goods-form.component';

const BeginDeclaration = ({ changeMonth }) => {
  const { t } = useTranslation();
  const [isSidepanelOpen, setIsSidepanelOpen] = useState(false);

  return (
    <Fragment>
      <SidePanel
        isOpened={isSidepanelOpen}
        title={t('goods.select_month')}
        body={<GoodsFormComponent setIsSidepanelOpen={setIsSidepanelOpen} />}
        onClose={() => setIsSidepanelOpen(false)}
        onClick={() => setIsSidepanelOpen(false)}
      />
      {changeMonth ? (
        <Button
          label={t('goods.change_month')}
          type="default"
          size="small"
          onClick={() => setIsSidepanelOpen(true)}
        />
      ) : (
        <div className="col-12 col-bleed">
          <Card>
            <div className="col-12 col-bleed-x">
              {t('goods.begin_text.description')}
              <ul>
                <li>{t('goods.begin_text.list_item_1')}</li>
                <li>{t('goods.begin_text.list_item_2')}</li>
                <li>{t('goods.begin_text.list_item_3')}</li>
              </ul>
            </div>
            <Button
              label={t('goods.declaration_button')}
              type="success"
              size="small"
              onClick={() => setIsSidepanelOpen(true)}
            />
          </Card>
        </div>
      )}
    </Fragment>
  );
};

BeginDeclaration.defaultProps = {
  changeMonth: false,
};

BeginDeclaration.propTypes = {
  changeMonth: PropTypes.bool.isRequired,
};

export default BeginDeclaration;
