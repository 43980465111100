import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Legend.styles.scss';
import { DANGER, SUCCESS, WARNING } from 'utils/status';

const Legend = ({ label, type, size, className }) => {
  const legendType = type => {
    if (type === SUCCESS) return 'legend--success';
    if (type === DANGER) return 'legend--danger';
    if (type === WARNING) return 'legend--warning';

    return null;
  };

  const legendSize = size => {
    if (size === 'small') return 'legend--small';
    if (size === 'normal') return 'legend--normal';

    return null;
  };
  return (
    <div className={classNames(className, 'legend__wrapper')}>
      <div
        className={classNames(
          'legend',
          `legend--${type}`,
          `legend--${size}`,
          legendType(type),
          legendSize(size),
        )}
        role="presentation"
      />
      <div className="legend__label">{label}</div>
    </div>
  );
};

Legend.defaultProps = {
  label: null,
  className: null,
  type: 'success',
  size: 'small',
};

Legend.propTypes = {
  label: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOf(['success', 'warning', 'danger']),
  size: PropTypes.oneOf(['small', 'normal']),
};

export default Legend;
