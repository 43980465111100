import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useUpdateSupplierCertificateMutation } from 'api/supplier-certificates';
import SidePanel from '../../base-components/SidePanel';
import Icon from '../../base-components/Icon';
import SupplierCertificateForm from '../form/form.component';
import IconButton from '../../base-components/IconButton';

const EditForm = ({ supplierCertificate, organizationId, disabled }) => {
  const [enableSidePannel, setEnableSidePanel] = useState(false);
  const { t } = useTranslation();

  const [updateSupplierCertificate] = useUpdateSupplierCertificateMutation();

  const onUpdateSupplierCertificate = formData => {
    return updateSupplierCertificate({
      body: formData,
      queryParams: { organizationId, certificateId: supplierCertificate.id },
    }).unwrap();
  };

  return (
    <Fragment>
      <SidePanel
        title={t('supplier_certificates.edit_certificate')}
        closeIcon={<Icon name="close" />}
        body={
          <SupplierCertificateForm
            onSave={onUpdateSupplierCertificate}
            onCancel={() => {
              setEnableSidePanel(false);
            }}
            supplierCertificate={supplierCertificate}
            organizationId={organizationId}
          />
        }
        isOpened={enableSidePannel}
        onClick={() => {}}
        onClose={() => {
          setEnableSidePanel(false);
        }}
      />
      <IconButton
        icon={<Icon name="edit" size="small" color="tertiary" />}
        color="tertiary"
        onClick={() => {
          setEnableSidePanel(true);
        }}
        disabled={disabled}
      />
    </Fragment>
  );
};

EditForm.propTypes = {
  supplierCertificate: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  organizationId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default EditForm;
