import { useGetRecreationalAndGreenAreasQuery } from 'api/producers/productions';
import { t } from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import SharedGreenAreaAndPrivatePark from './shared/green-area-and-private-parks.component';

const GreenAreasComponent = ({ id }) => {
  const { data: recreationalAndGreenAreas } = useGetRecreationalAndGreenAreasQuery(
    { productionId: id },
    { skip: !id },
  );

  return (
    <SharedGreenAreaAndPrivatePark
      title={t('plant_cultivation.green_areas.title')}
      icon="green_area"
      data={recreationalAndGreenAreas}
      name="green_areas"
    />
  );
};

GreenAreasComponent.defaultProps = {
  id: null,
};

GreenAreasComponent.propTypes = {
  id: PropTypes.node,
};

export default GreenAreasComponent;
