import { useGetUserQuery } from 'api/users';
import classNames from 'classnames';
import Avatar from 'components/base-components/Avatar/Avatar';
import React from 'react';
import PropTypes from 'prop-types';

const UserAvatar = ({ userID, className }) => {
  const { data: user = {}, error, isFetching } = useGetUserQuery({ id: userID });
  if (isFetching) return null;
  if (error) return null;

  return (
    <span className={classNames('user-avatar', className)}>
      <Avatar
        src={user?.profilePictureUrl}
        firstName={user?.firstName}
        lastName={user?.lastName}
        size="small"
        bgColor={5}
      />
    </span>
  );
};

UserAvatar.defaultProps = {
  className: null,
};

UserAvatar.propTypes = {
  userID: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default UserAvatar;
