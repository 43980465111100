export const GREEN_AREA = 'greenArea';
export const PRIVATE_PARK = 'privatePark';
export const URBAN_GARDENING = 'urban_gardening';
export const PUBLICLY_ACCESSIBLE_GREEN_AREA = 'publicly_accessible_green_area';
export const GARDENING_ITEMS = 'gardening_items';
export const OTHER_FARMS = 'otherFarms';
export const DAIRY_FIRM = 'dairy_firm';
export const BEE_HIVE = 'bee_hive';
export const FISH_FARMING = 'fishFarming';
export const DAIRY_GOAT = 'dairy_goat';
export const DAIRY_EWE = 'dairy_ewe';
export const MILK_COW = 'milk_cow';
export const EGG = 'egg';
export const COW = 'cow';
export const PIG = 'pig';
export const BEE = 'bee';
export const MILK = 'milk';
export const ANIMAL = 'animal';
export const POULTRY = 'poultry';
export const EWE_GOAT = 'ewe_goat';
export const EWE_SHEEP = 'ewe_sheep';
export const MEAT = 'meat';
export const PLANT = 'plant';
export const ARABLE_CROP = 'arable_crop';
export const POTATO = 'potato';
export const POTATOES_OPEN_FIELD_IN_HECTARES = 'potatoes_open_field_in_hectares';
export const OTHER = 'other';
export const OTHER_COVERED_CULTIVATION_IN_HECTARES = 'other_covered_cultivation_in_hectares';
export const OTHER_OPEN_FIELD_IN_HECTARES = 'other_open_field_in_hectares';
export const ORNAMENTAL = 'ornamental';
export const ORNAMENTAL_COVERED_CULTIVATION_IN_HECTARES =
  'ornamental_covered_cultivation_in_hectares';
export const ORNAMENTAL_OPEN_FIELD_IN_HECTARES = 'ornamental_open_field_in_hectares';
export const VITICULTURE = 'viticulture';
export const MUSHROOM = 'mushroom';
export const MUSHROOM_OTHERS = 'mushroom_others';
export const MUSHROOM_FARMING = 'mushroom_farming';
export const MUSHROOM_COVERED_FIELD_IN_ARES = 'mushroom_covered_field_in_ares';
export const MUSHROOM_OPEN_FIELD_IN_ARES = 'mushroom_open_field_in_ares';
export const VEGETABLE = 'vegetable';
export const VEGETABLES_OPEN_FIELD_IN_HECTARES = 'vegetables_open_field_in_hectares';
export const VEGETABLES_COVERED_SOLID_LAND_IN_ARES = 'vegetables_covered_solid_land_in_ares';
export const VEGETABLES_COVERED_CULTIVATION_WITHOUT_SOLID_LAND_IN_ARES =
  'vegetables_covered_cultivation_without_solid_land_in_ares';
export const HERB = 'herb';
export const HERB_INPUT = 'herbInput';
export const HERBS_OPEN_SURFACE_AREA_IN_ARES = 'herbs_open_surface_area_in_ares';
export const HERBS_COVERED_SURFACE_AREA_IN_ARES = 'herbs_covered_surface_area_in_ares';
export const HERBS_CULTIVATION_PERIOD = 'herbs_cultivation_period';
export const FRUIT = 'fruit';
export const FRUIT_ITEM = 'fruit_item';
export const TABLE_GRAPE = 'table_grape';
export const EMPTY_PRODUCTION = 'empty_production';
export const TABLE_GRAPES_OPEN_FIELD_IN_HECTARES = 'table_grapes_open_field_in_hectares';
export const TABLE_GRAPES_COVERED_CULTIVATION_IN_HECTARES =
  'table_grapes_covered_cultivation_in_hectares';

export const PRODUCTION_DATA_ATTRIBUTES = [
  GREEN_AREA,
  PRIVATE_PARK,
  URBAN_GARDENING,
  PUBLICLY_ACCESSIBLE_GREEN_AREA,
  GARDENING_ITEMS,
  OTHER_FARMS,
  BEE_HIVE,
  FISH_FARMING,
  DAIRY_GOAT,
  DAIRY_EWE,
  MILK_COW,
  EGG,
  COW,
  PIG,
  POULTRY,
  EWE_GOAT,
  EWE_SHEEP,
  MEAT,
  PLANT,
  ARABLE_CROP,
  POTATO,
  POTATOES_OPEN_FIELD_IN_HECTARES,
  OTHER,
  OTHER_COVERED_CULTIVATION_IN_HECTARES,
  OTHER_OPEN_FIELD_IN_HECTARES,
  ORNAMENTAL,
  ORNAMENTAL_COVERED_CULTIVATION_IN_HECTARES,
  ORNAMENTAL_OPEN_FIELD_IN_HECTARES,
  VITICULTURE,
  MUSHROOM,
  MUSHROOM_OTHERS,
  MUSHROOM_COVERED_FIELD_IN_ARES,
  MUSHROOM_OPEN_FIELD_IN_ARES,
  VEGETABLE,
  VEGETABLES_OPEN_FIELD_IN_HECTARES,
  VEGETABLES_COVERED_SOLID_LAND_IN_ARES,
  VEGETABLES_COVERED_CULTIVATION_WITHOUT_SOLID_LAND_IN_ARES,
  HERB,
  HERB_INPUT,
  HERBS_OPEN_SURFACE_AREA_IN_ARES,
  HERBS_COVERED_SURFACE_AREA_IN_ARES,
  HERBS_CULTIVATION_PERIOD,
  FRUIT,
  FRUIT_ITEM,
  EMPTY_PRODUCTION,
  TABLE_GRAPE,
  TABLE_GRAPES_OPEN_FIELD_IN_HECTARES,
  TABLE_GRAPES_COVERED_CULTIVATION_IN_HECTARES,
];
