import EmphasisTag from 'components/base-components/EmphasisTag';
import React from 'react';
import PropTypes from 'prop-types';

const OrganizationStatus = ({ status }) => {
  const organisationStatusTagColor = {
    active: 'success',
    inactive: 'danger',
    draft: 'accent',
    registered: 'accent',
    undefined: 'warning',
    interested: 'warning',
  };
  return <EmphasisTag type={organisationStatusTagColor[status]} text={status} />;
};

OrganizationStatus.defaultProps = {
  status: null,
};

OrganizationStatus.propTypes = {
  status: PropTypes.node,
};

export default OrganizationStatus;
