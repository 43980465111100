import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import styles from 'components/pdf/styles/styles';
import PropTypes from 'prop-types';
import { Break } from 'components/pdf/shared/shared-components';

const PdfFooter = ({ showInFirstPageOnly }) => (
  <View fixed={true}>
    <View
      style={styles.footer}
      render={({ pageNumber }) => {
        if (showInFirstPageOnly && pageNumber !== 1) return;

        return (
          <>
            <View style={{ width: '110%', marginRight: 5 }}>
              <Text style={{ ...styles.footerText, textAlign: 'right' }}>
                Vereinigung Schweizer Biolandbau-Organisationen
                <Break />
                Association suisse des organisations d'agriculture biologique
                <Break />
                Associazione svizzera delle organizzazioni per l'agricoltura biologica
                <Break />
                Uniun svizra da las organisaziuns d'agricultura biologica
              </Text>
            </View>
            <View style={{ width: '100%', marginLeft: 5 }}>
              <Text style={styles.footerText}>
                Bio Suisse <Break />
                Peter Merian-Strasse 34 · CH-4052 Basel <Break />
                Tel. 061 204 66 66 <Break />
                www.bio-suisse.ch · bio@bio-suisse.ch <Break />
              </Text>
            </View>
          </>
        );
      }}
    />
  </View>
);

PdfFooter.defaultProps = {
  showInFirstPageOnly: false,
};

PdfFooter.propTypes = {
  showInFirstPageOnly: PropTypes.bool,
};

export default PdfFooter;
