import { groupBy } from 'utils/groupBy';
import { preparePhoneNumbers } from '../../producer/submit-form/phone-number';
import { prepareNestedAttributes } from '../../producer/submit-form/address';

export const handleContactSumbit = (organization, values, formData) => {
  const phoneNumberByType = groupBy(organization?.phoneNumbers || [], 'numberType');

  preparePhoneNumbers({ values, formData, phoneNumberByType });

  formData.append('website', values?.website || '');

  const emailAttributes = {
    id: organization?.emailAddresses[0]?.id,
    email: values.email || organization.emailAddresses[0].email,
    email_type: 'other',
    destroy: organization?.emailAddresses?.length > 0 && !values.email,
  };

  prepareNestedAttributes({ entityName: 'email_addresses', attributes: emailAttributes, formData });
};
