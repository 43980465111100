import React, { useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CheckBox from 'components/base-components/CheckBox';
import { tvdLabelOptions } from 'utils/tvd-label-options';

const TVDLabelCheckbox = ({ title, disabledLabels, tvd, onChange }) => {
  const form = useForm();
  const { values } = useFormState();
  const { t } = useTranslation();

  const isTypeDownload = values.type === 'download';

  const isLabelEnabled = label => {
    if (values.type === 'order') return false;

    return !disabledLabels.includes(label);
  };

  useEffect(() => {
    if (!values.type) return;

    isTypeDownload ? form.change('labels', []) : form.change('labels', tvd.labels);
  }, [values.type]);

  return (
    <div className="tvd-list__sidepanel--input-field">
      <div className="tvd-list__sidepanel-label">{title}</div>
      {tvdLabelOptions.map(label => (
        <div key={label}>
          <Field name={'labels'} type="checkbox" value={label}>
            {({ input }) => (
              <CheckBox
                className="tvd-list__sidepanel--checkbox"
                isChecked={input.checked}
                size="tiny"
                label={t(`tvd.order.extra_labels.${label}`)}
                onChange={event => onChange({ event, form, input, label })}
                disabled={!isLabelEnabled(label)}
              />
            )}
          </Field>
        </div>
      ))}
    </div>
  );
};

TVDLabelCheckbox.defaultProps = {
  isOrder: false,
  tvd: {},
  onChange: ({ event, input }) => input.onChange(event),
  disabledLabels: [],
};

TVDLabelCheckbox.propTypes = {
  title: PropTypes.string.isRequired,
  disabledLabels: PropTypes.array,
  tvd: PropTypes.shape(),
  onChange: PropTypes.func,
};

export default TVDLabelCheckbox;
