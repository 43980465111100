import React from 'react';
import { useCreateLocalUnitMutation } from 'api/producers/local-unit';
import Button from 'components/base-components/Button';
import Input from 'components/base-components/Input';
import AddressField from 'components/organisations/form/contact-and-address/AddressField';
import { organizationAddressToString } from 'components/organisations/organisation-address';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import TVDLabelCheckbox from 'components/tvd/shared/tvd-label-checkbox';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { removeEmptyFromObject } from 'utils/object-cleanup';
import Icon from 'components/base-components/Icon';
import Tooltip from 'components/base-components/Tooltip';
import { isNonEmptyObject } from 'utils/check-object-length';
import appendAddress from './helpers/appendAddres';
import TVDMailing from './shared/tvd-mailing';

const AddTVD = ({ organization, setIsAddSidepanelOpen }) => {
  const [showAddressForm, setShowAddressForm] = useState(false);
  const { t } = useTranslation();
  const [addTvd, status] = useCreateLocalUnitMutation();

  const handleAddressCancel = form => {
    const initialFieldValue = form.getFieldState('address')?.initial;
    form.change('address', initialFieldValue);
    setShowAddressForm(false);
  };

  const onSubmit = (values, form) => {
    let formData = new FormData();
    values.labels.forEach(value => {
      formData.append('labels[]', value);
    });
    formData.append('tvd_sticker_number', values.tvd_sticker_number || '');
    formData.append('tvd_mailing', values?.tvdMailing === 'yes');
    appendAddress(values?.stickerAddress, formData);

    return addTvd({ organisationId: organization.id, payload: formData })
      .unwrap()
      .catch(({ data: { errors } }) => {
        const stickerAddress = removeEmptyFromObject({
          zipCode: errors.stickerAddressZipCode,
          city: errors.stickerAddressCity,
          country: errors.stickerAddressCountryCode,
          line1: errors.stickerAddressLine1,
          canton: errors.stickerAddressCanton,
        });

        return removeEmptyFromObject({
          tvd_sticker_number: errors.tvdStickerNumber,
          stickerAddress: isNonEmptyObject(stickerAddress) ? stickerAddress : null,
        });
      });
  };

  const initialValues = useMemo(() => ({ labels: [] }), []);

  return (
    <div className="tvd-list__sidepanel">
      <div className="tvd-list__sidepanel-label">
        {t('organisation_view.tvd.sidepanel.organisation_name')}
      </div>
      <div>{organization?.name}</div>
      <div className="tvd-list__sidepanel-label">
        {t('organisation_view.tvd.sidepanel.main_address')}
      </div>
      <div>{organizationAddressToString(organization?.mainAddress)}</div>
      <hr className="tvd-list__sidepanel--horizontal-line" />
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, values, form, submitErrors }) => {
          return (
            <form onSubmit={handleSubmit}>
              {showAddressForm ? (
                <div className="tvd-list__sidepanel--input-field">
                  <div className="tvd-list__sidepanel-label">
                    {t('organisation_view.tvd.sidepanel.enter_tvd_sticker_address')}
                  </div>
                  <AddressField parentKey="stickerAddress" />
                  <div className="tvd-list__sidepanel--input-field">
                    <Button
                      label={t('common.add_button')}
                      type="success"
                      size="small"
                      onClick={() => setShowAddressForm(false)}
                    />
                    <Button
                      className="tvd-list__sidepanel--cancel-button"
                      label={t('common.cancel')}
                      size="small"
                      onClick={() => handleAddressCancel(form)}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="tvd-list__sidepanel--input-field">
                    <Field name="tvd_sticker_number">
                      {({ input, meta }) => (
                        <Input
                          {...input}
                          className="organisation__search"
                          placeholder={t('organisation_view.tvd.sidepanel.tvd_nr_placeholder')}
                          label={t('organisation_view.tvd.sidepanel.tvd_nr')}
                          size="tiny"
                          error={meta.submitError}
                          touched={!meta.dirtySinceLastSubmit}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="tvd-list__sidepanel--input-field">
                    <div className="tvd-list__sidepanel-label flex">
                      {t('organisation_view.tvd.sidepanel.tvd_sticker_address')}
                    </div>
                    <div className="tvd-list__sidepanel--sticker-address">
                      {values?.stickerAddress
                        ? organizationAddressToString(values?.stickerAddress)
                        : t('organisation_view.tvd.sidepanel.none')}

                      {submitErrors?.stickerAddress && (
                        <Tooltip
                          className="vertical-align-middle"
                          content={t('tvd.form.sticker_address_error')}
                          type="danger"
                          position="bottom"
                          size="tiny"
                          gap={0}
                        >
                          <Icon
                            className="margin-left-10"
                            name="invalid"
                            color="danger"
                            size="small"
                          />
                        </Tooltip>
                      )}
                    </div>
                    <Button
                      label={t('organisation_view.tvd.buttons.add_address')}
                      size="small"
                      onClick={() => setShowAddressForm(true)}
                    />
                  </div>
                  <TVDMailing />
                  <TVDLabelCheckbox title={t('organisation_view.tvd.sidepanel.labels')} />
                  <div className="tvd-list__sidepanel--input-field">
                    <Button
                      label={t('shared.action.save')}
                      type="success"
                      size="small"
                      submitType="submit"
                      disabled={submitting}
                    />
                    <Button
                      className="tvd-list__sidepanel--cancel-button"
                      label={t('common.cancel')}
                      size="small"
                      onClick={() => setIsAddSidepanelOpen(false)}
                    />
                  </div>
                </>
              )}
              <SubmitModal
                isLoading={status.isLoading}
                isSuccess={status.isSuccess}
                requestId={status.requestId}
                successTitle={t('organisation_view.tvd.modal.create.title')}
                successContent={t('organisation_view.tvd.modal.create.description')}
                errorTitle={t('organisation_view.tvd.modal.create.error_message')}
                onSuccess={() => setIsAddSidepanelOpen(false)}
                errorContent={status.error?.data?.message}
                showError={true}
              />
            </form>
          );
        }}
      />
    </div>
  );
};

AddTVD.propTypes = {
  organization: PropTypes.shape().isRequired,
  setIsAddSidepanelOpen: PropTypes.func.isRequired,
};

export default AddTVD;
