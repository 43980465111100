import React, { useState, useCallback } from 'react';
import { debounce } from 'throttle-debounce';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PillTabs } from 'components/base-components/PillTabs';
import { useGetTasksQuery } from 'api/tasks';
import { useSearchParams } from 'react-router-dom';
import TASKS_STATUS from 'utils/task-status';
import Pagination from 'components/base-components/Pagination';
import TaskTabContentComponent from './tasks-tab-content.component';

const SharedTasks = ({ taskType, currentUserId }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState();
  const [searchParams, setSearchParams] = useSearchParams({
    status: 'pending',
    assigned_to: 'me',
    q: '',
    page: 1,
    'author_ids[]': [],
  });

  const handleTaskParams = () => {
    if (taskType === 'all_task') {
      return {
        q: searchParams.get('q'),
        status: TASKS_STATUS[currentTab],
        page: currentPage,
      };
    } else if (taskType === 'reported_task') {
      return {
        q: searchParams.get('q'),
        status: TASKS_STATUS[currentTab],
        page: currentPage,
        'author_ids[]': [currentUserId],
      };
    } else {
      return {
        q: searchParams.get('q'),
        'assigned_to[]': [taskType],
        status: TASKS_STATUS[currentTab],
        page: currentPage,
        'author_ids[]': [currentUserId],
      };
    }
  };

  const currentTab = searchParams.get('status');
  const currentAssignee = searchParams.get('assigned_to');
  const currentAuthor = searchParams.get('author_ids[]');
  const currentPage = parseInt(searchParams.get('page'), 10);
  const taskParams = handleTaskParams();

  const setSearchQueryParams = useCallback(
    params => {
      Object.keys(params).forEach(key => {
        searchParams.set(key, params[key]);
      });
      setSearchParams(searchParams);
    },
    [currentTab],
  );

  const { isFetching: isFetchingTasks, data: taskData } = useGetTasksQuery(taskParams);

  const taskDataCollection = taskData?.collection || [];

  const setSearchQueryInParams = React.useRef(
    debounce(500, value =>
      setSearchQueryParams(
        {
          q: value,
          assigned_to: taskType,
          status: TASKS_STATUS[currentTab],
          'author_ids[]': [currentUserId],
          page: 1,
        },
        currentAssignee,
        currentTab,
        currentAuthor,
      ),
    ),
  ).current;

  const onSearch = value => {
    setSearchQueryInParams(value);
  };

  return (
    <>
      <PillTabs
        items={[
          {
            getContent: () => (
              <TaskTabContentComponent
                status="pending"
                key="pending"
                assigned_to={taskType}
                tasks={taskDataCollection}
                onChangeParams={setSearchQueryParams}
                isFetchingTasks={isFetchingTasks}
                handleSearch={onSearch}
              />
            ),
            key: 0,
            title: t('task.to_do'),
          },
          {
            getContent: () => (
              <TaskTabContentComponent
                tasks={taskDataCollection}
                status="done"
                key="done"
                assigned_to={taskType}
                onChangeParams={setSearchQueryParams}
                isFetchingTasks={isFetchingTasks}
                handleSearch={onSearch}
              />
            ),
            key: 1,
            title: t('task.completed'),
          },
          {
            getContent: () => (
              <TaskTabContentComponent
                tasks={taskDataCollection}
                status="cancelled"
                key="cancelled"
                assigned_to={taskType}
                onChangeParams={setSearchQueryParams}
                isFetchingTasks={isFetchingTasks}
                handleSearch={onSearch}
              />
            ),
            key: 1,
            title: t('task.cancelled'),
          },
        ]}
        onChange={value => setSelectedTab(value)}
        selectedTabKey={selectedTab}
      />
      <Pagination
        onPaginationClick={newPage => {
          setSearchQueryParams({ page: newPage });
        }}
        totalPages={taskData && parseInt(taskData.pagination.totalPages, 10)}
        currentPage={currentPage}
        firstText={t('pagination.first')}
        lastText={t('pagination.last')}
        nextText={t('pagination.next')}
        previousText={t('pagination.prev')}
      />
    </>
  );
};

SharedTasks.defaultProps = {
  taskType: null,
};

SharedTasks.propTypes = {
  taskType: PropTypes.string,
  currentUserId: PropTypes.number.isRequired,
};

export default SharedTasks;
