import React from 'react';
import { useGetUserQuery } from 'api/users';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const UserEmail = ({ userID }) => {
  const { t } = useTranslation();
  const { data: user = {}, error, isFetching } = useGetUserQuery({ id: userID });
  if (isFetching) return null;
  if (error) return null;

  return <span className="user-email">{user.email || t('common.not_applicable')}</span>;
};

UserEmail.propTypes = {
  userID: PropTypes.number.isRequired,
};

export default UserEmail;
