import { Image, Text, View } from '@react-pdf/renderer';
import ImageUpload from 'assets/image-upload.png';
import styles from 'components/pdf/styles/checklist-styles';
import { t } from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { FINAL } from 'utils/verification-status';
import truncateFilename from 'utils/truncate-filename';

const IngredientsInStock = ({ data, reportType, ingredientsLogoAttachmentPhotos, comments }) => {
  return (
    <>
      <Text style={styles.checklistMarginForty}>
        <Text style={styles.bold}>2. </Text>
        {t('control_body.survey.form.second_step')}
      </Text>
      <View>
        {data?.length > 0 ? (
          data.map(logo => (
            <>
              <View style={styles.checkListFlexSpaceBetween}>
                <View style={styles.yesNoListItemContainer}>
                  <View style={styles.yesNoBulletPoint} />
                  <Text>{logo.label}</Text>
                </View>
                <Text>
                  {logo.attached ? t('shared.action.yes') : t('control_body.survey.shared.no')}
                </Text>
              </View>
              <View style={styles.checklistHorizontalLine} />
            </>
          ))
        ) : (
          <View style={styles.checklistMarginTen}>
            <Text>{t('control_body.survey.form.no_active_products')}</Text>
          </View>
        )}
      </View>

      {reportType === FINAL && ingredientsLogoAttachmentPhotos.length > 0 && (
        <>
          <View style={styles.checklistMarginTwenty}>
            <View style={styles.checkListFlexSpaceBetween}>
              <Text style={styles.heading1}> {t('control_body.survey.form.uploaded_files')} </Text>
            </View>
          </View>
          <View style={styles.checklistMarginTen}>
            {ingredientsLogoAttachmentPhotos.map(logo => (
              <View style={{ ...styles.checkListFlex, ...styles.checklistMarginTen }}>
                <Image src={ImageUpload} style={styles.imageLogo} />
                <Text>{truncateFilename(logo?.filename) || t('common.not_applicable')}</Text>
              </View>
            ))}
          </View>
        </>
      )}

      {comments.ingredientReserves && (
        <View style={styles.baseComment}>
          <Text>
            {t('control_body.survey.form.comment_from_auditor')} {comments.ingredientReserves}
          </Text>
        </View>
      )}
    </>
  );
};

IngredientsInStock.propTypes = {
  data: PropTypes.array.isRequired,
  comments: PropTypes.shape().isRequired,
  reportType: PropTypes.string.isRequired,
  ingredientsLogoAttachmentPhotos: PropTypes.array.isRequired,
};

export default IngredientsInStock;
