import React, { useState } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Icon from 'components/base-components/Icon';
import Modal from 'components/base-components/Modal';
import { useGetOrganizationsQuery } from 'api/organizations';
import Button from 'components/base-components/Button';
import CheckboxFilter from 'components/shared-components/Filters/CheckboxFilter';
import { useSearchParams } from 'react-router-dom';
import useCustomSearchParams from 'hooks/useCustomSearchParams';

const FilterModalContent = ({ form }) => {
  const [searchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState('');
  const organizationIds = searchParams.getAll('organizationIds');
  const selectedOrganizations = organizationIds.map(id => parseInt(id));

  const organizationParams = {
    q: searchInput,
  };

  const { data } = useGetOrganizationsQuery(organizationParams);
  const organizations = data ? data.organizations : [];

  const associatedOrganisationOptions = organizations?.map(organization => ({
    label: organization.name,
    key: organization.id,
  }));

  return (
    <div>
      <span onClick={() => form.reset()} className="modal__content--reset-all">
        {t('shared.action.reset_all')}
      </span>
      <div className="modal__content__filter-set">
        <CheckboxFilter
          title={t('organisation.filter.title.organization')}
          fieldName="organizationIds"
          checkboxList={associatedOrganisationOptions}
          showBadge={true}
          form={form}
          setSearchInput={setSearchInput}
          searchInput={searchInput}
          isScrollable={true}
          selectedOptions={selectedOrganizations}
          showSearchInput={true}
        />
      </div>
    </div>
  );
};

const TVDFilterModal = props => {
  const { isFilterVisible, setIsFilterVisible } = props;

  // TODO: Eslint rule will be fixed in this story https://welltravel.atlassian.net/browse/BIOS-1334
  // eslint-disable-next-line no-unused-vars
  const [_searchParams, _setSearchParams, appendSearchParams] = useCustomSearchParams();

  const onSubmit = values => {
    appendSearchParams({ organizationIds: values.organizationIds });
    setIsFilterVisible(false);
  };

  return (
    <Modal
      className="modal__filter tvd__modal__filter"
      isVisible={isFilterVisible}
      onOutsideClick={() => setIsFilterVisible(false)}
    >
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Title className="modal__filter--title">
              <div className="modal__filter--title-name">{t('shared.action.filter')}</div>
              <div onClick={() => setIsFilterVisible(false)}>
                <Icon size="small" color="tertiary" name="close" />
              </div>
            </Modal.Title>
            <Modal.Content>
              <FilterModalContent form={form} />
            </Modal.Content>
            <Modal.Footer className="modal__filter--footer">
              <Button
                className="modal__filter--footer-button"
                type="success"
                label={t('shared.action.apply')}
                size="small"
                disabled={submitting}
                onClick={handleSubmit}
              />
              <Button
                className="modal__filter--footer-button"
                label={t('shared.action.cancel')}
                size="small"
                onClick={() => setIsFilterVisible(false)}
              />
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
};

FilterModalContent.propTypes = {
  form: PropTypes.shape().isRequired,
};

TVDFilterModal.propTypes = {
  isFilterVisible: PropTypes.bool.isRequired,
  setIsFilterVisible: PropTypes.func.isRequired,
};

export default TVDFilterModal;
