import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EmphasisTag from 'components/base-components/EmphasisTag';

const ShowStatus = ({ status, type }) => {
  const { t } = useTranslation();

  return status ? (
    <EmphasisTag type={type} size="tiny" text={status} />
  ) : (
    t('common.not_applicable')
  );
};

ShowStatus.propTypes = {
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ShowStatus;
