import React from 'react';
import { useGetOrganizationQuery, useUpdateOrganizationMutation } from 'api/organizations';
import PropTypes from 'prop-types';
import ORGANISATION_TYPE from 'utils/organisation-type';
import OrganizationForm from '../form/index.component';

const EditForm = ({ orgID }) => {
  const [updatePost, status] = useUpdateOrganizationMutation();
  const isSubmitted = status.isSuccess || status.isLoading;

  const { data: organization, isFetching } = useGetOrganizationQuery(orgID, { skip: isSubmitted });

  const { data: parentOrganization = {}, isFetching: isFetchingParentOrganization } =
    useGetOrganizationQuery(organization && organization.mainOrgId, {
      skip: isFetching || !(organization && organization.mainOrgId) || isSubmitted,
    });

  const onUpdate = params => {
    const organizationParams = { formData: params, id: organization.id };
    return updatePost(organizationParams).unwrap();
  };

  if (isFetching) {
    return null;
  }
  return (
    <OrganizationForm
      department={
        organization.type === ORGANISATION_TYPE.controlBody
          ? organization.type
          : organization.industry
      }
      industry={organization.industry}
      onSave={onUpdate}
      organization={{ ...organization, mainOrgName: parentOrganization.name }}
      isLoading={isFetching || isFetchingParentOrganization}
      status={status}
    />
  );
};

EditForm.propTypes = {
  orgID: PropTypes.node.isRequired,
};

export default EditForm;
