import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/base-components/Card';
import {
  EATING_BERRIES,
  HIGH_TRUNK_TREE,
  INDUSTRY_BERRIES,
  ORCHARD,
  TABLE_GRAPE,
  OTHER,
} from 'utils/plant-cultivation';
import { t } from 'i18next';
import { isNonEmptyObject, isNonEmptyArray } from 'utils/check-object-length';
import TitleComponent from 'components/organisations/form/producer/plant-cultivate/shared/title.component';
import { removeEmptyFromObject, toSnakecaseKeys } from 'utils/object-cleanup';
import FruitContent from './shared/fruit-content.component';

const FruitData = ({ title, icon, values }) => {
  const highTrunkTree = values?.filter(value => value?.plantType === HIGH_TRUNK_TREE);
  const orchard = values?.filter(value => value?.plantType === ORCHARD);
  const eatingBerries = values?.filter(value => value?.plantType === EATING_BERRIES);
  const industryBerries = values?.filter(value => value?.plantType === INDUSTRY_BERRIES);
  const tableGrape = values?.find(value => value?.fruitType === TABLE_GRAPE);
  const tableGrapeModifiedData = {
    openField: tableGrape?.productionDetails?.openFieldSurfaceAreaInAres,
    coveredCultivation: tableGrape?.productionDetails?.coveredFieldSurfaceAreaInAres,
  };
  const tableGrapeData = toSnakecaseKeys(removeEmptyFromObject(tableGrapeModifiedData));
  const otherFruit = values?.find(value => value?.fruitType === OTHER);

  return (
    <>
      {isNonEmptyObject(values) && (
        <Card className="operations__production--content">
          <TitleComponent title={title} icon={icon} isOutsideForm={true} />
          <hr className="organisation-checklist__checkbox-horizontal-line" />
          <Card className="operations__production__inside-card">
            {isNonEmptyArray(highTrunkTree) && (
              <div className="margin-top-5">
                <TitleComponent
                  title={t('plant_cultivation.fruits.high_trunk_trees.title')}
                  isOutsideForm={true}
                />
                <hr className="organisation-checklist__checkbox-horizontal-line" />
                {highTrunkTree?.map(fruit => (
                  <div className="flex-space-between margin-top-10">
                    <div>{t(`plant_cultivation.fruits.high_trunk_trees.${fruit.fruitType}`)}</div>
                    <div>
                      {t('operations.unit.trees', {
                        value: fruit.productionDetails.numberOfTrees,
                      })}
                    </div>
                  </div>
                ))}
              </div>
            )}
            <FruitContent
              data={orchard}
              title={t('plant_cultivation.fruits.high_trunk_trees.orchard_title')}
              name="high_trunk_trees"
            />
            <FruitContent
              data={eatingBerries}
              title={t('plant_cultivation.fruits.berry.eating_berries')}
              name="berry"
            />
            <FruitContent
              data={industryBerries}
              title={t('plant_cultivation.fruits.berry.industry_berries')}
              name="berry"
            />
            {isNonEmptyObject(tableGrapeData) && (
              <div className="operations__production__fruit-content-card margin-bottom-20">
                <TitleComponent
                  title={t('plant_cultivation.fruits.table_grapes')}
                  isOutsideForm={true}
                />
                <hr className="organisation-checklist__checkbox-horizontal-line" />
                {Object.keys(tableGrapeData)?.map(
                  key =>
                    tableGrapeData[key] !== 0 &&
                    tableGrapeData[key] !== null && (
                      <div key={key} className="flex-space-between margin-top-10">
                        <span>{t(`operations.shared.${key}`)}</span>
                        <span>
                          {t('operations.unit.ares', {
                            value: tableGrapeData[key],
                          })}
                        </span>
                      </div>
                    ),
                )}
              </div>
            )}
            {isNonEmptyObject(otherFruit) && (
              <>
                <hr className="organisation-checklist__checkbox-horizontal-line" />
                <div className="margin-top-10">
                  <TitleComponent
                    title={t('plant_cultivation.fruits.other_fruit')}
                    isOutsideForm={true}
                  />
                </div>
              </>
            )}
          </Card>
        </Card>
      )}
    </>
  );
};

FruitData.defaultProps = {
  values: {},
  isCheckboxData: false,
};

FruitData.propTypes = {
  title: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  values: PropTypes.shape(),
  isCheckboxData: PropTypes.bool,
};

export default FruitData;
