/* eslint-disable */
import { useNavigate } from 'react-router-dom';
import useNavigationLinks from './useNavigationLinks';

const useCustomNavigate = () => {
  const navigate = useNavigate();
  const links = useNavigationLinks();

  const openRootPage = () => navigate('/');
  const openEnforce2FAPage = () => navigate('/enforce-2fa');
  const openLoginPage = () => navigate('/login');
  const openRegisterPage = () => navigate('/register');
  const openCredentialPage = token => navigate(`/set-password?token=${token}`);
  const openOrganisationsPage = () => navigate(links.organisationsPage());
  const openRestaurantsPage = () => navigate(links.restaurantsPage());
  const openProducersPage = () => navigate(links.producersPage());
  const openControlBodyPage = () => navigate(links.controlBodyPage());
  const openUsersPage = () => navigate('/users');
  const organisationViewPage = (id, tab) => navigate(`/organisations/${id}/view?section=${tab}`);
  const openInvitedUsersPage = () => navigate('/users?tab=invited');
  const openOrganisationDetailsPage = id => navigate(`/organisations/${id}`);
  const openOrganisationSuperOfficeTab = id =>
    navigate(`/organisations/${id}/view?section=super_office`);
  const openOrganisationControlBodyPage = id => navigate(`/organisations/${id}?tab=control_body`);
  const openOrganisationUserPage = id =>
    navigate(`/organisations/${id}/view?section=users&tab=active&q=&page=1`);
  const openTasksListPage = () => navigate('/tasks');
  const openMessagesListPage = id => navigate('/messages/');
  const openMessageEditPage = id => navigate(`/messages/edit/${id}`);
  const organisationViewControlPage = (organisationId, surveyId) =>
    navigate(`/organisations/${organisationId}/control-body-surveys/${surveyId}/view`);
  const openTvdCreatePage = id => navigate(`/organisations/${id}/local-units/create`);
  const openTvdEditPage = (id, localUnitId) =>
    navigate(`/organisations/${id}/local-units/edit/${localUnitId}`);
  const openTvdPlaceOrderPage = id => navigate(`/organisations/${id}/local-units/place-order`);
  const openInvoiceJobPage = id => navigate(`/invoices`);
  const openInvoiceListPage = status => navigate(`/invoices/list?tab=${status}`);
  const openInvoiceShowPage = ({ organizationId, invoiceId }) =>
    navigate(`/organisations/${organizationId}/invoices/${invoiceId}`);
  const openInvoiceCreatePage = () => navigate('/invoices/create');
  const openInvoiceEditPage = id => navigate(`/invoices/${id}/edit`);
  const openOrganisationInvoiceCreatePage = id => navigate(`/organisations/${id}/invoices/create`);
  const openOrganisationInvoiceEditPage = ({ organizationId, invoiceId }) =>
    navigate(`/organisations/${organizationId}/invoices/${invoiceId}/edit`);
  const openOrganisationInvoicePage = id =>
    navigate(`/organisations/${id}/view?section=invoice&tab=active&q=&page=1`);
  const openOrganisationChangeAddressPage = (id, addressType) =>
    navigate(`/organisations/${id}/change-address?type=${addressType}`);
  const openOrganisationChangeContactPage = id => navigate(`/organisations/${id}/change-contact`);
  const openOrganisationChangeModeOfCommunicationPage = id =>
    navigate(`/organisations/${id}/preferred-language/edit`);
  const openOrganisationView = (id, tab) => navigate(`/organisations/${id}/view?section=${tab}`);
  const openLegalForm = organisationId =>
    navigate(`/organisations/${organisationId}/legal-form/edit`);

  return {
    openRootPage,
    openLoginPage,
    openRegisterPage,
    openOrganisationsPage,
    openRestaurantsPage,
    openProducersPage,
    openControlBodyPage,
    openOrganisationDetailsPage,
    openOrganisationUserPage,
    openUsersPage,
    openInvitedUsersPage,
    openTasksListPage,
    openMessagesListPage,
    openMessageEditPage,
    openCredentialPage,
    openOrganisationControlBodyPage,
    openOrganisationSuperOfficeTab,
    openTvdCreatePage,
    openTvdEditPage,
    openTvdPlaceOrderPage,
    openInvoiceJobPage,
    openInvoiceListPage,
    openInvoiceShowPage,
    openInvoiceCreatePage,
    openInvoiceEditPage,
    openOrganisationInvoiceCreatePage,
    openOrganisationInvoiceEditPage,
    openEnforce2FAPage,
    openOrganisationInvoicePage,
    openOrganisationChangeAddressPage,
    openOrganisationChangeContactPage,
    openOrganisationChangeModeOfCommunicationPage,
    organisationViewPage,
    openOrganisationView,
    openLegalForm,
    organisationViewControlPage,
  };
};

export default useCustomNavigate;
