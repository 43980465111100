export const eggValues = (values, formData) => {
  if (values.numberOfLayingHen) {
    formData.append('production_details[egg_production][sub_type]', 'poultry');
    formData.append('production_details[egg_production][laying_hens]', values.numberOfLayingHen);
  }

  if (values.numberOfFemalePullets) {
    formData.append(
      'production_details[egg_production][female_pullets]',
      values.numberOfFemalePullets,
    );
  }

  if (values.numberOfQuails) {
    formData.append('production_details[egg_production][quails]', values.numberOfQuails);
  }
};
