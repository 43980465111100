import routes from 'utils/routes';
import {
  EDUCATION_ASSIGNEE,
  ORGANISATION_OVERVIEW,
  EDUCATION_OVERVIEW,
  EDUCATION_DECLARATION,
  ORGANISATION_DECLARATION_STATUS,
  ORGANISATION,
} from 'utils/tag-types';
import { apiSlice } from '../index';

const educationsAssigneeApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getEducationAssignee: build.query({
      query: params => routes.educationAssignee.entity(params),
      providesTags: [EDUCATION_ASSIGNEE],
    }),
    setEducationAssignee: build.mutation({
      query: ({ queryParams, payload }) => {
        return {
          url: routes.educationAssignee.entity(queryParams),
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: [EDUCATION_ASSIGNEE, EDUCATION_DECLARATION, EDUCATION_OVERVIEW],
    }),
    createEducation: build.mutation({
      query: ({ queryParams, payload }) => ({
        url: routes.educationAssignee.educations.collection(queryParams),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: result => (result ? [EDUCATION_DECLARATION, EDUCATION_OVERVIEW] : []),
    }),
    getEducationDeclarations: build.query({
      query: params => routes.educationAssignee.educations.declarations.collection(params),
      transformResponse: (response, meta, arg) => {
        return {
          declarations: response.collection,
          pagination: response.pagination,
        };
      },
      providesTags: [EDUCATION_DECLARATION],
    }),
    createEducationDeclarationComplaint: build.mutation({
      query: ({ queryParams, payload }) => ({
        url: routes.educationAssignee.educations.declarations.complaints(queryParams),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, id) =>
        !error ? [EDUCATION_DECLARATION, EDUCATION_OVERVIEW] : [],
    }),
    submitEducationDeclarations: build.mutation({
      query: params => ({
        url: routes.educationAssignee.educations.declarations.submit(params),
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: [EDUCATION_DECLARATION, EDUCATION_OVERVIEW],
    }),
    approveEducationDeclarations: build.mutation({
      query: params => ({
        url: routes.educationAssignee.educations.declarations.approve(params),
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: [
        EDUCATION_DECLARATION,
        ORGANISATION,
        ORGANISATION_OVERVIEW,
        EDUCATION_OVERVIEW,
        ORGANISATION_DECLARATION_STATUS,
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEducationAssigneeQuery,
  useSetEducationAssigneeMutation,
  useCreateEducationAssigneeComplaintMutation,
  useApproveEducationAssigneeMutation,
  useCreateEducationMutation,
  useGetEducationDeclarationsQuery,
  useCreateEducationDeclarationComplaintMutation,
  useSubmitEducationDeclarationsMutation,
  useApproveEducationDeclarationsMutation,
} = educationsAssigneeApi;
