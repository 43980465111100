import {
  useCreateTaskCommentMutation,
  useDeleteTaskCommentMutation,
  useGetTaskCommentsQuery,
  useUpdateTaskCommentMutation,
} from 'api/tasks';
import classNames from 'classnames';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import Textarea from 'components/base-components/Textarea';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import dateFromNow from 'utils/date-from-now';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { useGetCurrentUserQuery } from 'api/users';
import UserInfo from 'components/users/user-info.component';
import WarningModal from 'components/shared-components/modal/warning';

const CommentsComponent = () => {
  const [comment, setComment] = useState('');
  const [editMessage, setEditMessage] = useState('');
  const [showAllComment, setShowAllComment] = useState(false);
  const [editMessageId, setEditMessageId] = useState();
  const [deleteMessageId, setDeleteMessageId] = useState();
  const [isEditButtonClicked, setIsEditButtonClicked] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const [createComment] = useCreateTaskCommentMutation();
  const [updateComment] = useUpdateTaskCommentMutation();
  const [deleteComment] = useDeleteTaskCommentMutation();
  const { data: taskCommentsCollection } = useGetTaskCommentsQuery(id);
  const { data: currentUser } = useGetCurrentUserQuery();

  const allTaskComments = taskCommentsCollection?.collection || [];

  const taskComments = showAllComment ? allTaskComments : allTaskComments.slice(0, 3);

  const handleUpdateComment = id => {
    updateComment({
      id,
      payload: {
        body: editMessage,
        comment_id: id,
      },
    }).then(() => setIsEditButtonClicked(false));
  };

  const handleSetComments = (body, id) => {
    setIsEditButtonClicked(true);
    setEditMessage(body);
    setEditMessageId(id);
  };

  const handleSubmit = () => {
    if (comment) {
      const payload = {
        task_id: id,
        body: comment,
      };

      createComment({ id, payload }).then(() => setComment(''));
    }
  };

  const handleToggleButton = () => {
    setShowAllComment(!showAllComment);
  };

  const handleDeleteComment = () => {
    deleteComment(deleteMessageId).then(() => setIsDeleteModalOpen(false));
  };

  return (
    <>
      <Card className="task__top">
        <Textarea
          size="small"
          label={t('comment.label')}
          placeholder={t('comment.placeholder')}
          value={comment}
          onChange={e => setComment(e.target.value)}
        />
        <div className="task__button">
          <Button
            label={t('common.submit')}
            disabled={!comment}
            type="success"
            size="small"
            onClick={handleSubmit}
          />
          <Button
            className="task__button--clear"
            label={t('common.clear_button')}
            size="small"
            onClick={() => setComment('')}
          />
        </div>
        {taskComments.map(taskComment => (
          <Card className="task__top">
            <div className="task__wrap">
              <div className="task__head">
                <UserInfo userData={taskComment.commenter} />
                <div className="task__head--time">{dateFromNow(taskComment.updatedAt)}</div>
              </div>
              {isEditButtonClicked && taskComment.id === editMessageId ? (
                <div className="task__icon">
                  <IconButton
                    icon={<Icon name="check" size="small" />}
                    color="tertiary"
                    onClick={() => handleUpdateComment(taskComment.id)}
                  />
                  <IconButton
                    icon={<Icon name="close" size="small" />}
                    color="tertiary"
                    onClick={() => setIsEditButtonClicked(false)}
                  />
                </div>
              ) : (
                <div className="task__icon">
                  <IconButton
                    icon={<Icon name="edit" size="small" />}
                    color="tertiary"
                    onClick={() => handleSetComments(taskComment.body, taskComment.id)}
                  />
                  {isBioSuisseAdmin(currentUser) && (
                    <IconButton
                      icon={<Icon name="delete" size="small" />}
                      color="danger"
                      onClick={() => {
                        setIsDeleteModalOpen(true);
                        setDeleteMessageId(taskComment.id);
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            {isEditButtonClicked && taskComment.id === editMessageId ? (
              <Textarea
                size="small"
                placeholder={t('comment.placeholder')}
                value={editMessage}
                onChange={e => setEditMessage(e.target.value)}
              />
            ) : (
              <div className="task__full">{taskComment.body}</div>
            )}
          </Card>
        ))}
        <Button
          label={showAllComment ? t('common.show_less') : t('common.show_more')}
          className={classNames('comment__show-button', {
            'comment__show-button--hide': allTaskComments.length <= 3,
          })}
          size="small"
          onClick={handleToggleButton}
        />
      </Card>
      <WarningModal
        onOutsideClick={() => setIsDeleteModalOpen(false)}
        isVisible={isDeleteModalOpen}
        title={t('comment.delete_modal.title')}
        content={t('comment.delete_modal.description')}
        confirmActionText={t('shared.action.delete')}
        cancelActionText={t('shared.action.cancel')}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteComment}
      />
    </>
  );
};

export default CommentsComponent;
