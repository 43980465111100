import React from 'react';
import PropTypes from 'prop-types';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { Link } from 'react-router-dom';
import ORGANISATION_TYPE from 'utils/organisation-type';

const OrganisationColumn = ({ organization }) => {
  const navigationLinks = useNavigationLinks();

  return (
    <div className="organisation__table-row--violet-color">
      <Link
        className="organisation__table-row--name"
        to={
          organization.type === ORGANISATION_TYPE.controlBody
            ? navigationLinks.organisationViewPage(organization.id)
            : navigationLinks.organisationDetailsPage(organization.id)
        }
      >
        {organization.name}
      </Link>
    </div>
  );
};

OrganisationColumn.propTypes = {
  organization: PropTypes.shape().isRequired,
};

export default OrganisationColumn;
