import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const ContentLoaderPlaceholder = ({
  className,
  numberOfLines,
  contentWidth,
  contentHeight,
  showBackground,
  isRounded,
  shape,
  diameter,
}) => {
  const isCircle = shape === 'circle';

  const showLines = () => {
    const lines = [];

    for (let i = 0; i < numberOfLines; i += 1) {
      lines.push(
        <div
          key={i}
          className={classNames('content-loader-item', className, {
            'content-loader-item--round': isRounded,
          })}
        />
      );
    }
    return <div>{lines}</div>;
  };

  return (
    <div
      className={classNames('content-loader', className, {
        [`content-loader__circle--${diameter}`]: isCircle,
      })}
      style={{ '--content-width': contentWidth }}
    >
      <div
        className={classNames('content-loader-content', className, {
          'content-loader-content__circle': isCircle,
          [`content-loader-content__circle--${diameter}`]: isCircle,

          'content-loader-content--bg': showBackground,
        })}
        style={{ '--content-height': contentHeight }}
      >
        {isCircle ? (
          <div
            className={classNames(
              'content-loader-item',
              `content-loader-item__circle--${diameter}`,
              className
            )}
          />
        ) : (
          <div className="content-loader__placeholder-content">{showLines()}</div>
        )}
      </div>
    </div>
  );
};

ContentLoaderPlaceholder.defaultProps = {
  className: null,
  contentHeight: '100%',
  contentWidth: '100%',
  showBackground: true,
  isRounded: false,
  shape: 'rectangle',
  diameter: 'normal',
};

ContentLoaderPlaceholder.propTypes = {
  className: PropTypes.string,
  numberOfLines: PropTypes.number.isRequired,
  contentHeight: PropTypes.string,
  contentWidth: PropTypes.string,
  showBackground: PropTypes.bool,
  isRounded: PropTypes.bool,
  shape: PropTypes.oneOf(['rectangle', 'circle']),
  diameter: PropTypes.oneOf(['tiny', 'normal', 'large', 'huge', 'xl', 'xxl']),
};

export default ContentLoaderPlaceholder;
