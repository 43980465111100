import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { FieldArray } from 'react-final-form-arrays';
import Input from 'components/base-components/Input';
import IconButton from 'components/base-components/IconButton';
import Icon from 'components/base-components/Icon';
import Button from 'components/base-components/Button';
import { debounce } from 'throttle-debounce';
import { useGetOrganizationRegistryQuery } from 'api/organizations';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/base-components/Spinner/Spinner';
import { Field } from 'react-final-form';

const SubOrganizationUid = ({ fieldName, onChangeUid }) => {
  const [uidSearchQuery, setUidSearchQuery] = useState('');
  const { t } = useTranslation();

  const {
    data: organizationRegistryResponse,
    error: errorResponse,
    isFetching,
  } = useGetOrganizationRegistryQuery({
    uid: uidSearchQuery,
  });

  const onSearchRegisteredOrganization = debounce(500, value => {
    setUidSearchQuery(value);
  });

  useEffect(() => {
    if (uidSearchQuery && !errorResponse) {
      onChangeUid(organizationRegistryResponse);
    }
  }, [JSON.stringify(organizationRegistryResponse)]);

  return (
    <Field name={fieldName}>
      {({ input }) => (
        <Input
          className="organisation__form--padding-left organisation__form-suborganisation--input"
          label={
            <div className="organisation__form-suborganisation--input__label">
              {t('organisation_type.uid')}
              {isFetching && <Spinner size="small" color="success" bgColor="none" />}
            </div>
          }
          placeholder={t('organisation_type.uid_placeholder')}
          size="tiny"
          width="large"
          name="uid"
          value={input.value}
          touched={true}
          error={errorResponse?.uid}
          onChange={value => {
            input.onChange(value);
            onSearchRegisteredOrganization(value);
          }}
        />
      )}
    </Field>
  );
};

SubOrganizationUid.propTypes = {
  fieldName: PropTypes.string.isRequired,
  onChangeUid: PropTypes.func.isRequired,
};

const SubOrganisationField = ({ errors }) => {
  const { t } = useTranslation();

  const addSubOrganisation = fields => {
    fields.push({
      id: uuidv4(),
      value: {
        name: '',
        uid: '',
        main_address: '',
      },
    });
  };

  return (
    <div className="organisation__form-suborganisation--wrapper">
      <FieldArray name="subOrganisations">
        {({ fields }) => {
          return (
            <Fragment>
              {fields.map((name, index) => (
                <div
                  className="organisation__form-suborganisation"
                  key={`sub-orgaisation-${index}`}
                >
                  <Field name={`${name}.name`}>
                    {({ input }) => (
                      <Input
                        className="organisation__form--padding-right organisation__form--margin-top organisation__form-suborganisation--input"
                        label={t('organisation_type.sub_organisation_name')}
                        placeholder={t('organisation_type.sub_organisation_placeholder')}
                        size="tiny"
                        width="large"
                        touched={true}
                        required={true}
                        error={errors && errors[index]?.name}
                        {...input}
                      />
                    )}
                  </Field>
                  <SubOrganizationUid
                    fieldName={`${name}.uid`}
                    onChangeUid={response => {
                      fields.update(index, {
                        ...fields.value[index],
                        name: response.organizationLegalName,
                        main_address: {
                          line1: response.addressLine1,
                          line2: response.addressLine2,
                          city: response.city,
                          canton: response.canton,
                          country_code: response.country.code,
                          zip_code: response.zipCode,
                        },
                      });
                    }}
                  />
                  <IconButton
                    className="organisation__form--padding-left organisation__form-suborganisation--icon"
                    icon={<Icon name="close" size="small" />}
                    size="tiny"
                    onClick={() => fields.remove(index)}
                  />
                </div>
              ))}
              <Button
                className="organisation__form--margin-top"
                label={t('organisation_type.add_sub_organisation')}
                size="small"
                onClick={() => addSubOrganisation(fields)}
              />
            </Fragment>
          );
        }}
      </FieldArray>
    </div>
  );
};

SubOrganisationField.defaultProps = {
  organisationType: null,
  errors: null,
  handleSubOrganisations: () => {},
};

SubOrganisationField.propTypes = {
  organisationType: PropTypes.string,
  errors: PropTypes.node,
  handleSubOrganisations: PropTypes.func,
};

export default SubOrganisationField;
