import React from 'react';
import { useGetCurrentUserQuery } from 'api/users';
import Button from 'components/base-components/Button';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ADMIN, CONTROL_BODY_USER, USER } from 'utils/users-role';
import { useGetActiveControlBodyDeclarationQuery } from 'api/control-body-declarations';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import isControlBodyMember from 'utils/is-control-body-member';
import { hasPermissionToApproveAdditonalTask } from 'utils/control-body-survey';
import { DRAFT, SUBMITTED } from 'utils/verification-status';

const AdditionalTaskSubmitButton = ({ approverType, disabled, status }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    data: currentUser = {},
    isFetching: fetchingCurrentUser,
    isSuccess: isCurrentUserFetched,
  } = useGetCurrentUserQuery();
  const {
    data: controlBodyDeclaration,
    isFetching: isFetchingControlBodyDeclaration,
    isSuccess: isControlBodyDeclarationFetched,
  } = useGetActiveControlBodyDeclarationQuery(id);

  if (fetchingCurrentUser || isFetchingControlBodyDeclaration) return null;

  const isUser = currentUser.role === USER;
  const isAdmin = isBioSuisseAdmin(currentUser);
  const isControlBodyUser = isControlBodyMember(currentUser);

  if (!isUser && approverType !== currentUser.role) return null;

  const buttonEnabled = () => {
    if (disabled) return false;
    if (isUser && status === DRAFT) return true;
    if (
      isControlBodyUser &&
      approverType === CONTROL_BODY_USER &&
      status === SUBMITTED &&
      isControlBodyDeclarationFetched &&
      isCurrentUserFetched
    ) {
      return hasPermissionToApproveAdditonalTask(
        currentUser.assignments,
        controlBodyDeclaration.controlBodyOrganizationId,
      );
    }
    if (isAdmin && approverType === ADMIN && status === SUBMITTED) {
      return true;
    }

    return false;
  };

  return (
    <Button
      className="negative-report__declare-btn"
      label={isUser ? t('control_body.survey.shared.declare') : t('shared.action.verify')}
      type="success"
      size="small"
      disabled={!buttonEnabled()}
      submitType="submit"
    />
  );
};

AdditionalTaskSubmitButton.propTypes = {
  approverType: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default AdditionalTaskSubmitButton;
