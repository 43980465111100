import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetOrganizationQuery } from 'api/organizations';
import ModeOfCommunicationForm from '../../mode-of-communication/index.component';
import IndividualForm from '../../update-single-entity.component';

const ChangeModeOfCommunication = () => {
  const { id } = useParams();
  const { data: organization } = useGetOrganizationQuery(id);
  return (
    <IndividualForm warningModalNeeded={false} type="language">
      <ModeOfCommunicationForm menuPlacement="bottom" organization={organization} />
    </IndividualForm>
  );
};

export default ChangeModeOfCommunication;
