import popover from './popoverDefaultData';

/**
 * Returns the sorted array of directions based on the provided direction.
 *
 * @param {string} direction The direction in which user wants to render popover.
 *
 * @return {array} A sorted array of directions based on the provided direction.
 */

const getSortedPositions = direction =>
  popover.positions.sort((availableDirection1, availableDirection2) => {
    const [currentDirectionPrefix] = direction.split('-');

    if (availableDirection1.split('-')[0] === currentDirectionPrefix) {
      return -1;
    }

    if (availableDirection2.split('-')[0] === currentDirectionPrefix) {
      return 1;
    }

    return 0;
  });

export default getSortedPositions;
