import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import i18next from 'i18next';

moment.locale(i18next.language);

// Example: Jul 16, 1995
const dateFormatter = date => moment(date).format('MMM D, YYYY');

// Example: 16 Jul 1995
const dateMonthYear = date => moment(date).format('D MMM YYYY');

// Example: 12.12.1996
const dateMonthYearFormatter = date => moment(date).format('DD.MM.YYYY');

const dateTimeFormatter = dateTime => moment(dateTime).format('MMM D, YYYY HH:mm:ss');

// Example: Dec 23, 2022 08:29 AM
const dateTimeFormatterWithAmPm = dateTime => moment(dateTime).format('MMM D, YYYY HH:mm A');

// Example: Dec 23, 2022 14:29
const dateTimeFormatterWith24Hour = dateTime => moment(dateTime).format('DD.MM.YYYY HH:mm');

// Example: 2023-08-23T10:00:00+06:00
const dateTimeWithZone = dateTime => moment(dateTime).format('YYYY-MM-DDTHH:mm:ssZ');

//Example: 8 Nov 2023,10:00
const dateTimeWithMonthNameFormatterWith24Hour = dateTime =>
  moment(dateTime).format('D MMM YYYY, HH:mm');

// Example: Nov 2022
const monthYearShort = date => moment(date).format('MMM YYYY');

// Example: 10:00 AM
const twelveHourTimeFormat = (date, format = 'A') => moment(date).format(`hh:mm ${format}`);

// Example: 14:00
const twentyFourHourTimeFormat = date => moment(date).format(`HH:mm`);

// Example: 14:00
const twentyFourHourTimeFormatOnlyTimeInput = time => moment(time, 'HH:mm:ss').format(`HH:mm`);

// Example: 2022
const yearFromDate = date => moment(date).format(`YYYY`);

// Example: 22 December 2023
const dateFullMonthYear = date => moment(date).format('DD MMMM YYYY');

// Example: 2024-01-16
const yearMonthDayWithHyphen = date => moment(date).format('YYYY-MM-DD');

// Example: 20/10/2022
const dateMonthYearWithSlash = date => moment(date).format('DD/MM/YYYY');

export {
  dateFormatter,
  dateMonthYear,
  monthYearShort,
  dateTimeFormatter,
  dateTimeWithZone,
  dateMonthYearFormatter,
  dateTimeFormatterWithAmPm,
  dateTimeFormatterWith24Hour,
  dateTimeWithMonthNameFormatterWith24Hour,
  twelveHourTimeFormat,
  twentyFourHourTimeFormat,
  twentyFourHourTimeFormatOnlyTimeInput,
  dateFullMonthYear,
  dateMonthYearWithSlash,
  yearFromDate,
  yearMonthDayWithHyphen,
};
