import React from 'react';
import { useGetCurrentUserQuery } from 'api/users';
import { Tabs } from 'components/base-components/RTabs';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import BiosuisseMessages from './message-lists/bio-suisse-messages.component';
import MyMessages from './message-lists/my-messages.component';
import OrganisationMessages from './message-lists/organisation-messages.component';
import SentMessages from './message-lists/sent-messages.component';
import UserMessages from './message-lists/user-messages.component';
import './message-management.styles.scss';

const MessageManagementComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams({ tab: 'organisation_message' });
  const { data: currentUser = null } = useGetCurrentUserQuery();
  const { t } = useTranslation();

  const getTabMappers = () => {
    return isBioSuisseAdmin(currentUser)
      ? {
          organisation_message: 0,
          my_message: 1,
          sent_message: 2,
          user_message: 3,
          biosuisse_message: 4,
        }
      : {
          organisation_message: 0,
          my_message: 1,
        };
  };

  const currentTab = searchParams.get('tab');
  const TAB_MAPPER = getTabMappers();

  const onChangeTab = selectedTabKey => {
    const selectedTab = Object.keys(TAB_MAPPER).find(tab => TAB_MAPPER[tab] === selectedTabKey);
    setSearchParams({ tab: selectedTab });
  };

  const userTabs = [
    {
      title: t('messages.tab.organisation_messages'),
      key: TAB_MAPPER.organisation_message,
      getContent: () => <OrganisationMessages />,
    },
    {
      title: t('messages.tab.my_messages'),
      key: TAB_MAPPER.my_message,
      getContent: () => <MyMessages />,
    },
  ];

  const adminTabs = [
    {
      title: t('messages.tab.organisation_messages'),
      key: TAB_MAPPER.organisation_message,
      getContent: () => <OrganisationMessages />,
    },
    {
      title: t('messages.tab.my_messages'),
      key: TAB_MAPPER.my_message,
      getContent: () => <MyMessages />,
    },
    {
      title: t('messages.tab.sent_messages'),
      key: TAB_MAPPER.sent_message,
      getContent: () => <SentMessages />,
    },
    {
      title: t('messages.tab.user_messages'),
      key: TAB_MAPPER.user_message,
      getContent: () => <UserMessages />,
    },
    {
      title: t('messages.tab.biosuisse_messages'),
      key: TAB_MAPPER.biosuisse_message,
      getContent: () => <BiosuisseMessages />,
    },
  ];

  const tabs = isBioSuisseAdmin(currentUser) ? adminTabs : userTabs;

  return (
    <div className="message__wrapper">
      <Tabs
        className="message__tab"
        controlled={true}
        selectedTabKey={TAB_MAPPER[currentTab]}
        items={tabs}
        onChange={onChangeTab}
      />
    </div>
  );
};

export default MessageManagementComponent;
