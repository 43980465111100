import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/base-components/Icon';
import Link from 'components/base-components/Link';
import DefaultSpinner from 'components/shared-components/default-spinner';
import { useGetCurrentUserQuery } from 'api/users';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { useTranslation } from 'react-i18next';
import { useGetChartaOverviewQuery } from 'api/chartas';
import TimePeriodComponent from './time-period.component';
import OverviewTextComponent from './overview-text.component';

const ChartaOverview = ({ onClick, organizationId, chartaEnabled }) => {
  const { t } = useTranslation();

  const { data: chartaOverview = {}, isFetching } = useGetChartaOverviewQuery({
    organizationId,
  });
  const { data: currentUser = {} } = useGetCurrentUserQuery();

  const isAdmin = isBioSuisseAdmin(currentUser);

  if (isFetching) return <DefaultSpinner className="default-spinner--overview" />;

  const handleText = status => {
    if (!chartaEnabled || status === null) {
      return t('organisation_view.overview.charta.start_legitimization');
    }
    if (status === 'wip') {
      return t('organisation_overview.admin_verify');
    } else if (status === 'active') {
      return t('organisation_overview.messages.shared.approved');
    }

    return <span>{t('organisation_overview.warning')}</span>;
  };

  const handleIconColor = status => {
    if (status === 'expired') return 'danger';
    if (chartaOverview.gracePeriodEnabled === true) return 'warning';
    if (status === 'active') return 'success';

    return null;
  };

  const handleIconName = status => {
    if (status === 'expired') return 'highPriority';
    if (status === 'active' || chartaOverview.gracePeriodEnabled === true) return 'check';

    return null;
  };

  const declarationOverview = (
    <OverviewTextComponent
      text={handleText(chartaOverview.status)}
      status={chartaOverview.status}
    />
  );

  return (
    <Fragment>
      <div className="organisation-overview__title">
        <div className="organisation-overview__title-header">
          <span className="organisation-overview__title-header-text">
            {t('organisation_overview.charta')}
          </span>
          <Icon
            className={classNames('organisation-overview__title-header--icon', {
              'organisation-overview__title-header--icon__success':
                chartaOverview.status === 'active' && chartaOverview.gracePeriodEnabled === false,
              'organisation-overview__title-header--icon__submitted':
                chartaOverview.status === 'wip',
              'organisation-overview__title-header--icon__danger':
                chartaOverview.status === 'expired',
              'organisation-overview__title-header--icon__warning':
                chartaOverview.gracePeriodEnabled === true,
            })}
            size="xs"
            name={handleIconName(chartaOverview.status)}
            color={handleIconColor(chartaOverview.status)}
            showBGColor={true}
          />
        </div>
        <TimePeriodComponent
          validFrom={chartaOverview.validFrom}
          validUntil={chartaOverview.validUntil}
          showToBeDeclaredText={false}
        />
      </div>
      <div>{declarationOverview}</div>
      <Link
        type="button"
        className="organisation-overview__details"
        size="small"
        onClick={onClick}
        modifier={chartaEnabled ? 'default' : 'success'}
        disabled={!chartaEnabled && isAdmin}
      >
        {chartaEnabled
          ? t('shared.view_details')
          : t('organisation_view.overview.start_declaration')}
      </Link>
    </Fragment>
  );
};

ChartaOverview.defaultProps = {
  onClick: () => {},
  chartaEnabled: false,
};

ChartaOverview.propTypes = {
  organizationId: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  chartaEnabled: PropTypes.bool,
};

export default ChartaOverview;
