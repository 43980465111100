import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popover from '../Popover';

const Tooltip = props => {
  const { className, children, content, title, position, showArrow, type, size } = props;
  const [isVisible, setIsVisible] = useState(false);

  const onMouseOverHandler = () => {
    setIsVisible(true);
  };

  const onMouseOutHandler = () => {
    setIsVisible(false);
  };

  const contentWithTitle = (
    <>
      {title && <div className="rcl-tooltip__title">{title}</div>}
      <div className={classNames('rcl-tooltip__content', `rcl-tooltip__content--${size}`)}>
        {content}
      </div>
    </>
  );

  return (
    <Popover
      className={classNames(
        'rcl-tooltip',
        `rcl-tooltip--${type}`,
        `rcl-tooltip--${size}`,
        className,
      )}
      isVisible={isVisible}
      content={contentWithTitle}
      direction={position}
      showTip={showArrow}
      tipSize="tiny"
    >
      <span
        onMouseOver={onMouseOverHandler}
        onMouseOut={onMouseOutHandler}
        onFocus={onMouseOverHandler}
        onBlur={onMouseOutHandler}
      >
        {children}
      </span>
    </Popover>
  );
};

Tooltip.defaultProps = {
  title: null,
  children: null,
  className: null,
  position: 'bottom',
  showArrow: true,
  type: 'dark',
  size: 'small',
};

Tooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.node.isRequired,
  position: PropTypes.oneOf([
    'left',
    'right',
    'top',
    'bottom',
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
    'left-top',
    'left-bottom',
    'right-top',
    'right-bottom',
  ]),
  showArrow: PropTypes.bool,
  type: PropTypes.oneOf([
    'dark',
    'accent',
    'important',
    'disabled',
    'inverse',
    'success',
    'warning',
    'danger',
  ]),
  size: PropTypes.oneOf(['small', 'tiny', 'medium']),
};

export default Tooltip;
