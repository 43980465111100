import { t } from 'i18next';
import React from 'react';

const EmptyProducerContent = () => {
  return (
    <div>
      <div className="product-cultivation__empty-header">{t('egg_production.produce_details')}</div>
      <div className="product-cultivation__empty-description">
        {t('egg_production.produce_details_description')}
      </div>
    </div>
  );
};

export default EmptyProducerContent;
