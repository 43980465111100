import routes from 'utils/routes';
import { MESSAGE } from 'utils/tag-types';
import { apiSlice } from '../index';

const messagesApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getMessages: build.query({
      query: searchParams => routes.messages.collection(searchParams),
      providesTags: [MESSAGE],
    }),
    getSingleMessage: build.query({
      query: id => routes.messages.entityOfMessage({ id }),
      providesTags: [MESSAGE],
    }),
    createMessage: build.mutation({
      query: payload => ({
        url: routes.messages.entity(),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [MESSAGE],
    }),
    deleteMessage: build.mutation({
      query: id => ({
        url: routes.messages.entityOfMessage({ id }),
        method: 'DELETE',
      }),
      invalidatesTags: [MESSAGE],
    }),
    updateMessage: build.mutation({
      query: ({ id, payload }) => ({
        url: routes.messages.entityOfMessage({ id }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [MESSAGE],
    }),
    updateConfirmMessage: build.mutation({
      query: ({ id, payload }) => ({
        url: routes.messages.entityOfConfirmMessage({ id }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [MESSAGE],
    }),
  }),
});

export const {
  useGetMessagesQuery,
  useGetSingleMessageQuery,
  useCreateMessageMutation,
  useUpdateMessageMutation,
  useDeleteMessageMutation,
  useUpdateConfirmMessageMutation,
} = messagesApi;
