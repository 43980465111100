import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Input from 'components/base-components/Input';
import { t } from 'i18next';

const SharedMeatField = ({ fieldName, name, label, initialValue }) => {
  return (
    <div className="product-cultivation__input-wrapper">
      <div>{label}</div>
      <Field
        name={fieldName}
        initialValue={
          initialValue?.productionDetails?.[`${fieldName}`] ||
          initialValue?.productionDetails?.[`${name}`]
        }
      >
        {({ input }) => (
          <Input
            size="tiny"
            type="number"
            placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
            {...input}
          />
        )}
      </Field>
    </div>
  );
};

SharedMeatField.defaultProps = {
  label: null,
  initialValue: {},
  fieldName: null,
  name: null,
  index: null,
};

SharedMeatField.propTypes = {
  label: PropTypes.string,
  initialValue: PropTypes.shape(),
  fieldName: PropTypes.string,
  name: PropTypes.string,
  index: PropTypes.node,
};

export default SharedMeatField;
