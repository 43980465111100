import Button from 'components/base-components/Button';
import PropTypes from 'prop-types';
import CheckBox from 'components/base-components/CheckBox';
import DatePicker from 'components/base-components/DatePicker';
import Input from 'components/base-components/Input';
import i18n from 'i18next';
import { t } from 'i18next';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import SelectBox from 'components/base-components/SelectBox';
import moment from 'moment';

const CorrectInvoiceForm = ({ onCorrect, setIsSidepanelOpen, invoice }) => {
  const [enableInvoiceDatePicker, setEnableInvoiceDatePicker] = useState(null);

  const selectBoxOptions = [
    {
      label: t('shared.action.true'),
      value: true,
    },
    {
      label: t('shared.action.false'),
      value: false,
    },
  ];

  const onSubmit = values => onCorrect(values, invoice.id);
  const generalData = invoice?.seasonData || {};

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Field name="invoiceDate" initialValue={moment().format('YYYY-MM-DD')}>
            {({ input, meta }) => (
              <DatePicker
                focused={enableInvoiceDatePicker}
                date={input.value}
                onChange={e => input.onChange(e)}
                touched={!meta.dirtySinceLastSubmit}
                error={meta.submitError}
                onFocusChange={() => setEnableInvoiceDatePicker(!enableInvoiceDatePicker)}
                displayFormat="LL"
                label={t('tasks.create_form.due_date_placeholder')}
                navNext="arrowForwardAlt"
                navPrev="arrowBackAlt"
                size="tiny"
                enablePastDates={true}
                disableFutureDates={false}
                futureYearsCount={0}
                locale={i18n.language}
              />
            )}
          </Field>
          <div className="invoice__correct--date-footer">{t('invoice.correct.date_footer')}</div>
          <Field name="changeGeneralDataCheckbox" type="checkbox">
            {({ input }) => (
              <CheckBox
                className="invoice__correct--content"
                isChecked={input.checked}
                size="tiny"
                label={t('invoice.correct.change_general_data')}
                onChange={e => input.onChange(e.target.checked)}
              />
            )}
          </Field>
          {values.changeGeneralDataCheckbox && (
            <div>
              <Field name="zone" initialValue={generalData?.zoneNr}>
                {({ input }) => (
                  <Input
                    className="invoice__correct--content"
                    size="tiny"
                    label={t('invoice.correct.zone')}
                    placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
                    {...input}
                  />
                )}
              </Field>
              <Field name="summerOperation" initialValue={generalData?.summerPastureHoldings}>
                {({ input }) => (
                  <SelectBox
                    className="invoice__correct--content"
                    size="tiny"
                    width="large"
                    label={t('invoice.correct.summer_operation')}
                    isClearable={false}
                    options={selectBoxOptions}
                    value={selectBoxOptions.find(option => option.value === input.value)}
                    selectedValue={input.label}
                    onChange={e => input.onChange(e.value)}
                  />
                )}
              </Field>
              <Field name="usableArea" initialValue={generalData?.agriculturalUsableAreaTotalInM2}>
                {({ input }) => (
                  <Input
                    size="tiny"
                    className="invoice__correct--content"
                    label={t('invoice.correct.usable_area')}
                    placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
                    {...input}
                  />
                )}
              </Field>
              <Field name="greenArea" initialValue={generalData?.grasslandInM2}>
                {({ input }) => (
                  <Input
                    size="tiny"
                    className="invoice__correct--content"
                    label={t('invoice.correct.green_area')}
                    placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
                    {...input}
                  />
                )}
              </Field>
              <Field name="livestockInDgve" initialValue={generalData?.livestockInDgve}>
                {({ input }) => (
                  <Input
                    size="tiny"
                    className="invoice__correct--content"
                    label={t('invoice.correct.population')}
                    placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
                    {...input}
                  />
                )}
              </Field>
              <Field name="openLand" initialValue={generalData?.openArableLandInM2}>
                {({ input }) => (
                  <Input
                    size="tiny"
                    className="invoice__correct--content"
                    label={t('invoice.correct.open_land')}
                    placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
                    {...input}
                  />
                )}
              </Field>
              <Field name="specialCrops" initialValue={generalData?.specialAndPermanentCropsInM2}>
                {({ input }) => (
                  <Input
                    size="tiny"
                    className="invoice__correct--content"
                    label={t('invoice.correct.special_crops')}
                    placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
                    {...input}
                  />
                )}
              </Field>
              <Field
                name="protectedCultivation"
                initialValue={generalData?.cropsInProtectedCultivationInM2}
              >
                {({ input }) => (
                  <Input
                    size="tiny"
                    label={t('invoice.correct.protected')}
                    placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
                    className="invoice__correct--content"
                    {...input}
                  />
                )}
              </Field>
              <Field name="demeter" initialValue={!!generalData?.demeter}>
                {({ input, meta }) => (
                  <SelectBox
                    size="tiny"
                    className="invoice__correct--content"
                    width="large"
                    label={t('invoice.correct.demeter')}
                    isClearable={false}
                    options={selectBoxOptions}
                    value={selectBoxOptions.find(option => option.value === input.value)}
                    selectedValue={input.label}
                    onChange={e => input.onChange(e.value)}
                  />
                )}
              </Field>
            </div>
          )}
          <div className="invoice__correct--button">
            <Button
              label={t('invoice.correct.title')}
              type="success"
              size="small"
              submitType="submit"
              disabled={submitting}
            />
            <Button
              label={t('shared.action.cancel')}
              type="default"
              size="small"
              onClick={() => setIsSidepanelOpen(false)}
            />
          </div>
        </form>
      )}
    />
  );
};

CorrectInvoiceForm.defaultProps = {
  onCorrect: () => {},
  setIsSidepanelOpen: () => {},
  organisationId: null,
};

CorrectInvoiceForm.propTypes = {
  onCorrect: PropTypes.func,
  setIsSidepanelOpen: PropTypes.func,
  organisationId: PropTypes.node,
  invoice: PropTypes.shape().isRequired,
};

export default CorrectInvoiceForm;
