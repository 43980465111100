import React from 'react';
import { Image, View } from '@react-pdf/renderer';
import BioLogo from 'assets/bio_logo_large.png';
import recognitionStyles from 'components/pdf/styles/recognition.js';

const PDFHeader = () => (
  <View fixed={true}>
    <Image style={recognitionStyles.headerLogo} src={BioLogo} />
  </View>
);

export default PDFHeader;
