import React from 'react';
import classNames from 'classnames';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BIO_CUISINE_STATUS from 'utils/bio-cuisine-status';
import { MAX_STARS } from 'utils/goods';

const BioCuisineStatus = ({ status, iconSize, disabled, displayAllStars, isDowngraded }) => {
  const { t } = useTranslation();
  if (status === BIO_CUISINE_STATUS.notLegitimised) {
    return <span>{t('organisation.not_certified')}</span>;
  }

  let numberOfStars = 0;
  switch (status) {
    case BIO_CUISINE_STATUS.oneStar:
      numberOfStars = 1;
      break;
    case BIO_CUISINE_STATUS.twoStar:
      numberOfStars = 2;
      break;
    case BIO_CUISINE_STATUS.threeStar:
      numberOfStars = 3;
      break;
    default:
      numberOfStars = 0;
  }

  const expectedStars = displayAllStars ? MAX_STARS : numberOfStars;

  return (
    <div className="organisation__bio-cuisine-status">
      {Array.from(Array(expectedStars)).map((x, index) => (
        <span key={`certification-wrapper-${index}`}>
          <IconButton
            className={classNames('organisation__bio-cuisine-status--icon', {
              'organisation__bio-cuisine-status--downgraded': isDowngraded,
            })}
            icon={<Icon name="star" color="success" size={iconSize} />}
            color="success"
            disabled={(displayAllStars ? index + 1 > numberOfStars : disabled) && !isDowngraded}
            isIconOnly={true}
          />
        </span>
      ))}
    </div>
  );
};

BioCuisineStatus.defaultProps = {
  disabled: false,
  iconSize: 'small',
  status: null,
  displayAllStars: false,
  isDowngraded: false,
};

BioCuisineStatus.propTypes = {
  disabled: PropTypes.bool,
  iconSize: PropTypes.string,
  status: PropTypes.string,
  displayAllStars: PropTypes.bool,
  isDowngraded: PropTypes.bool,
};

export default BioCuisineStatus;
