import React, { useEffect } from 'react';
import { useGetGoodsDeclarationQuery } from 'api/goods-declarations';
import DefaultSpinner from 'components/shared-components/default-spinner';
import PropTypes from 'prop-types';
import { monthYearShort } from 'utils/date-time-formatter';
import AmountInput from 'components/control-body/bio-cuisine-assessment/checklist/entities/amount-input.component';
import { t } from 'i18next';
import numberFormatter from 'utils/number-formatter';

const RandomGoodsDeclarationInfo = ({
  randomGoodsDeclaration,
  isChecklist,
  setEvaluationPeriods,
  handleVerifyInputAmount,
  status,
  isCertifier,
}) => {
  const { data, isFetching } = useGetGoodsDeclarationQuery(
    randomGoodsDeclaration.goodsDeclarationId,
  );

  const validityPeriod = `${monthYearShort(data?.periodFrom)} - ${monthYearShort(
    data?.periodUntil,
  )}`;

  useEffect(() => {
    if (data) {
      setEvaluationPeriods(prev => [...prev, validityPeriod]);
    }
  }, [data?.id]);

  const totalAmount =
    parseInt(data?.month1TotalAmount, 10) +
    parseInt(data?.month2TotalAmount, 10) +
    parseInt(data?.month3TotalAmount, 10);

  if (isFetching) return <DefaultSpinner />;

  if (isChecklist) {
    return (
      <>
        <div className="organisation-checklist__quarter-goods">
          <div>
            {t('negative_report.random_goods.header')}{' '}
            <span className="organisation-checklist__quarter-goods--bold">{validityPeriod}</span>
          </div>
          <div className="organisation-checklist__quarter-goods--bold">{`${numberFormatter(
            totalAmount,
          )} CHF`}</div>
        </div>
        <AmountInput
          amount={randomGoodsDeclaration.customInput}
          id={randomGoodsDeclaration.id}
          validFrom={monthYearShort(data?.periodFrom)}
          validUntil={monthYearShort(data?.periodUntil)}
          handleVerifyInputAmount={handleVerifyInputAmount}
          status={status}
          differenceStatus={randomGoodsDeclaration.differenceStatus}
          isCertifier={isCertifier}
        />
        <hr className="organisation-checklist__checkbox-horizontal-line" />
      </>
    );
  }

  return (
    <div className="random-goods-declarations__wrapper">
      <ul className="random-goods-declarations__list">
        <li>
          <div className="random-goods-declarations__values">
            <span>
              {t('negative_report.random_goods.header')} {validityPeriod} :
            </span>
            <span>{`${totalAmount} CHF`}</span>
          </div>
        </li>
        <li>
          <div className="random-goods-declarations__values">
            <span>
              {t('negative_report.random_goods.vat')} {validityPeriod}
            </span>
            <span>{randomGoodsDeclaration.customInput} CHF</span>
          </div>
        </li>
      </ul>
    </div>
  );
};

RandomGoodsDeclarationInfo.defaultProps = {
  isChecklist: false,
  isCertifier: false,
  status: null,
  setEvaluationPeriods: () => {},
  handleVerifyInputAmount: () => {},
};

RandomGoodsDeclarationInfo.propTypes = {
  randomGoodsDeclaration: PropTypes.shape().isRequired,
  isChecklist: PropTypes.bool,
  isCertifier: PropTypes.bool,
  status: PropTypes.string,
  setEvaluationPeriods: PropTypes.func,
  handleVerifyInputAmount: PropTypes.func,
};

export default RandomGoodsDeclarationInfo;
