import { useGetUserAssignmentsQuery } from 'api/users';
import Icon from 'components/base-components/Icon';
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Spinner from 'components/base-components/Spinner';
import OrganizationAddress from 'components/organisations/organisation-address';
import OrganizationName from 'components/organisations/organization-name';
import { t } from 'i18next';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import Pagination from 'components/base-components/Pagination';
import { dateMonthYear } from 'utils/date-time-formatter';
import Card from 'components/base-components/Card';
import EmphasisTag from 'components/base-components/EmphasisTag';
import OrganizationStatus from 'components/organisations/organization-status.component';
import IconButton from 'components/base-components/IconButton';
import { useGetOrganizationQuery } from 'api/organizations';
import UserEmail from '../user-email.component';
import '../users.styles.scss';

const AssignmentContent = ({ assignment }) => {
  const [toggleCardShowHide, setToggleCardShowHide] = useState(false);

  const { data: organisation = {} } = useGetOrganizationQuery(assignment.organizationId, {
    skip: !assignment?.organizationId,
  });

  return (
    <Card className="margin-top-20">
      <div className="flex-space-between">
        <div className="flex-with-center-align">
          <div className="font-bold font-16">
            <OrganizationName organizationId={assignment.organizationId} disableLink={true} />
          </div>
          <EmphasisTag
            type="normal"
            text={organisation.industry && t(`organisation.industry.${organisation.industry}`)}
          />
        </div>
        <div className="flex-with-center-align">
          <div>{t('user_details.assignment.assignment_status')}</div>
          <OrganizationStatus status={organisation.status} />
          <IconButton
            icon={<Icon name={toggleCardShowHide ? 'upArrow' : 'downArrow'} />}
            isIconOnly={true}
            onClick={() => setToggleCardShowHide(!toggleCardShowHide)}
          />
        </div>
      </div>
      {toggleCardShowHide && (
        <>
          <div className="flex-with-center-align margin-top-20">
            <div className="user__assignment--left-content">
              <div className="font-bold">{t('user_details.assignment.table_head.role')}</div>
              <div className="margin-top-10">{assignment?.role || t('common.not_applicable')}</div>
            </div>
            <div>
              <div className="font-bold">{t('producer.dashboard.address')}</div>
              <div className="margin-top-10">
                <OrganizationAddress organizationId={assignment.organizationId} />
              </div>
            </div>
          </div>
          <div className="flex-with-center-align margin-top-20">
            <div className="user__assignment--left-content">
              <div className="font-bold">{t('user_details.assignment.table_head.valid_from')}</div>
              <div className="margin-top-10">
                {assignment?.validFrom
                  ? dateMonthYear(assignment?.validFrom)
                  : t('common.not_applicable')}
              </div>
            </div>
            <div>
              <div className="font-bold">{t('user_details.assignment.table_head.valid_until')}</div>
              <div className="margin-top-10">
                {assignment?.validUntil
                  ? dateMonthYear(assignment?.validUntil)
                  : t('common.not_applicable')}
              </div>
            </div>
          </div>
          <div className="flex-with-center-align margin-top-20">
            <div className="user__assignment--left-content">
              <div className="font-bold">
                {t('organisation_view.overview.attributes.contact.phone')}
              </div>
              <div className="margin-top-10">{t('common.not_applicable')}</div>
            </div>
            <div>
              <div className="font-bold">
                {t('organisation_view.overview.attributes.contact.mobile')}
              </div>
              <div className="margin-top-10">{t('common.not_applicable')}</div>
            </div>
          </div>
          <div className="flex-with-center-align margin-top-20">
            <div>
              <div className="font-bold">{t('user_details.assignment.table_head.email')}</div>
              <div className="margin-top-10">
                <UserEmail userID={assignment.userId} />
              </div>
            </div>
          </div>
        </>
      )}
    </Card>
  );
};

AssignmentContent.defaultProps = {
  assignment: {},
};

AssignmentContent.propTypes = {
  assignment: PropTypes.shape(),
};

const AssignmentListComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const { id } = useParams();
  const currentPage = searchParams.get('page');
  const currentTab = searchParams.get('tab');
  const { isFetching, data, isError } = useGetUserAssignmentsQuery({
    id,
    page: currentPage,
  });
  const assignments = data ? data.assignments : [];
  const { t } = useTranslation();

  return (
    <>
      {isFetching || isError ? (
        <Spinner className="user-details__spinner" bgColor="none" color="success" size="small" />
      ) : assignments.length > 0 ? (
        <Fragment>
          {assignments?.map(assignment => (
            <AssignmentContent assignment={assignment} />
          ))}
          <div className="user-details__assignment-list">
            <Pagination
              onPaginationClick={newPage => {
                setSearchParams({ tab: currentTab, page: newPage });
              }}
              totalPages={data && parseInt(data.pagination.totalPages, 10)}
              currentPage={currentPage}
              nextText={t('pagination.next')}
              previousText={t('pagination.prev')}
              firstText={t('pagination.first')}
              lastText={t('pagination.last')}
            />
          </div>
        </Fragment>
      ) : (
        <EmptyPlaceholder
          emptyCardText={t('user_details.assignment.empty_placeholder')}
          emptyIcon={<Icon name="organization" />}
        />
      )}
    </>
  );
};

export default AssignmentListComponent;
