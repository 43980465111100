import { Text, View } from '@react-pdf/renderer';
import { Break, ListItem, Section } from 'components/pdf/shared/shared-components';
import PropTypes from 'prop-types';
import React from 'react';
import { useLanguageContext } from 'contexts/languageContext';
import { FRENCH } from 'utils/language-options';
import styles from 'components/pdf/styles/styles';
import SignatureForm from './signature-form.view';

const Content = ({ organisation, language }) => {
  const { t } = useLanguageContext();
  return (
    <>
      <Section title={t('producer_contract.point_one_title')}>
        <Text style={styles.text}>{t('producer_contract.point_one_description')}</Text>
      </Section>

      <Section title={t('producer_contract.point_two_title')}>
        <Text style={styles.text}>{t('producer_contract.point_two_description')}</Text>
      </Section>

      <Section title={t('producer_contract.point_three_title')}>
        <Text style={styles.text}>{t('producer_contract.point_three_description')}</Text>
        <ListItem prefix="a)" style={styles.listItemWithNoGap}>
          {t('producer_contract.point_three_list.a')}
        </ListItem>
        <ListItem prefix="b)" style={styles.listItemWithNoGap}>
          {t('producer_contract.point_three_list.b')}
        </ListItem>
        <ListItem prefix="c)" style={styles.listItemWithNoGap}>
          {t('producer_contract.point_three_list.c')}
          <Break count={12} />
        </ListItem>
        <ListItem prefix="d)" style={styles.listItemWithNoGap}>
          {t('producer_contract.point_three_list.d')}
        </ListItem>
        <ListItem prefix="e)" style={styles.listItemWithNoGap}>
          {t('producer_contract.point_three_list.e')}
        </ListItem>
        <ListItem prefix="f)" style={styles.listItemWithNoGap}>
          {t('producer_contract.point_three_list.f')}
        </ListItem>
        <ListItem prefix="g)" style={styles.listItemWithNoGap}>
          {t('producer_contract.point_three_list.g')}
        </ListItem>
      </Section>

      <Section title={t('producer_contract.point_four_title')}>
        <Text style={styles.text}>{t('producer_contract.point_four_description')}</Text>
      </Section>

      <Section title={t('producer_contract.point_five_title')}>
        <Text style={styles.text}>{t('producer_contract.point_five_description')}</Text>
        <ListItem prefix="1." customStyle={styles.listItemWithNoGap}>
          {t('producer_contract.point_five_list.a')}
        </ListItem>
        <ListItem prefix="2." customStyle={styles.listItemWithNoGap}>
          {t('producer_contract.point_five_list.b.before_break')}
        </ListItem>
        <Text>
          <Break />
          {t('producer_contract.point_five_list.b.after_break')}
        </Text>
      </Section>

      <Section title={t('producer_contract.point_six_title')}>
        <View>
          <Text style={styles.text}>
            {t('producer_contract.point_six_description.before_break')}
            {language !== FRENCH && <Break count={2} />}
          </Text>
        </View>
        <View>
          <Text style={styles.text}>
            {t('producer_contract.point_six_description.after_break')}
          </Text>
        </View>
      </Section>

      <Section title={t('producer_contract.point_seven_title')}>
        <Text style={styles.text}>{t('producer_contract.point_seven_description')}</Text>
      </Section>

      <SignatureForm organisation={organisation} isContractPdf={true} />
    </>
  );
};

Content.propTypes = {
  organisation: PropTypes.shape().isRequired,
  language: PropTypes.string.isRequired,
};

export default Content;
