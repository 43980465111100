import React from 'react';
import { useCreateEducationDeclarationComplaintMutation } from 'api/educations-asignee';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Complaint from '../complaint';

const CreateComplaint = ({ educationDeclarationId, isDisabled }) => {
  const { t } = useTranslation();

  const [createComplaint] = useCreateEducationDeclarationComplaintMutation();

  const onSave = values => {
    const params = {
      queryParams: {
        educationDeclarationId,
      },
      payload: {
        title: values.title,
        description: values.description,
      },
    };

    return createComplaint(params);
  };

  return <Complaint onSave={onSave} label={t('shared.action.complaint')} isDisabled={isDisabled} />;
};

CreateComplaint.propTypes = {
  educationDeclarationId: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default CreateComplaint;
