import { useGetControlBodySurveysQuery } from 'api/control-body-surveys';
import { useGetOrganizationQuery } from 'api/organizations';
import Pagination from 'components/base-components/Pagination';
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/base-components/Table';
import DefaultSpinner from 'components/shared-components/default-spinner';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import { t } from 'i18next';
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { PERMANENT } from 'utils/control-body-survey';
import { dateMonthYear } from 'utils/date-time-formatter';
import ORGANISATION_TYPE from 'utils/organisation-type';
import { FINAL } from 'utils/verification-status';
import OrganizationName from '../organisations/organization-name';
import ReportPDF from './bio-cuisine-assessment/report-pdf/index.component';

const ControlHistory = () => {
  const { id: organizationId } = useParams();
  const { data: organisation = {} } = useGetOrganizationQuery(organizationId);
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const currentPage = parseInt(searchParams.get('page'), 10);

  let queryParams = {
    organizationId,
    page: currentPage,
    status: PERMANENT,
  };

  const organizationParams =
    ORGANISATION_TYPE.sub_organisation === organisation.type
      ? {
          'sub_organization_ids[]': [organizationId],
        }
      : { 'organization_ids[]': [organizationId] };

  const { data, isFetching } = useGetControlBodySurveysQuery({
    ...queryParams,
    ...organizationParams,
  });

  if (isFetching) return <DefaultSpinner />;

  const surveys = data.collection;

  return (
    <div className="control-body__history">
      {surveys.length > 0 ? (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>{t('control_body.survey.attributes.history.table.title')}</TableHeader>
                <TableHeader>{t('control_body.survey.attributes.history.table.date')}</TableHeader>
                <TableHeader>{t('control_body.survey.attributes.history.table.file_size')}</TableHeader>
                <TableHeader>{t('control_body.survey.attributes.history.table.action')}</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {surveys.map(survey => (
                <TableRow>
                  <TableData>
                    {t('control_body.survey.attributes.history.control_at')}{' '}
                    <OrganizationName organizationId={survey.organizationId} disableLink={true} />
                  </TableData>
                  <TableData>{dateMonthYear(survey.lastSubmittedAt)}</TableData>
                  <ReportPDF survey={survey} reportType={FINAL} />
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Pagination
            onPaginationClick={newPage => {
              setSearchParams({ page: newPage, tab: searchParams.get('tab') });
            }}
            totalPages={data && parseInt(data.pagination.totalPages, 10)}
            currentPage={currentPage}
            firstText={t('pagination.first')}
            lastText={t('pagination.last')}
            nextText={t('pagination.next')}
            previousText={t('pagination.prev')}
          />
        </>
      ) : (
        <EmptyContentPlaceholder
          iconName="neutralOutline"
          text={t('shared.placeholder.no_history')}
        />
      )}
    </div>
  );
};

export default ControlHistory;
