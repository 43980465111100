import routes from 'utils/routes';
import { COMMENT, TASK } from 'utils/tag-types';
import { apiSlice } from '../index';

const tasksApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getTasks: build.query({
      query: searchParams => routes.tasks.collection(searchParams),
      providesTags: [TASK],
    }),
    getSingleTask: build.query({
      query: id => routes.tasks.entityOfTask({ id }),
      providesTags: [TASK],
    }),
    createTask: build.mutation({
      query: payload => ({
        url: routes.tasks.entity(),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [TASK],
    }),
    deleteTask: build.mutation({
      query: id => ({
        url: routes.tasks.entityOfTask({ id }),
        method: 'DELETE',
      }),
      invalidatesTags: [TASK],
    }),
    markAsDone: build.mutation({
      query: params => ({
        url: routes.tasks.markAsDone(params),
        method: 'PUT',
      }),
      invalidatesTags: [TASK],
    }),
    updateTask: build.mutation({
      query: ({ id, payload }) => ({
        url: routes.tasks.entityOfTask({ id }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [TASK],
    }),
    getTaskComments: build.query({
      query: id => routes.tasks.collectionOfComments({ id }),
      providesTags: [COMMENT],
    }),
    createTaskComment: build.mutation({
      query: ({ id, payload }) => ({
        url: routes.tasks.collectionOfComments({ id }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [COMMENT],
    }),
    updateTaskComment: build.mutation({
      query: ({ id, payload }) => ({
        url: routes.tasks.enityOfComment({ id }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [COMMENT],
    }),
    deleteTaskComment: build.mutation({
      query: id => ({
        url: routes.tasks.enityOfComment({ id }),
        method: 'DELETE',
      }),
      invalidatesTags: [COMMENT],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetSingleTaskQuery,
  useCreateTaskMutation,
  useDeleteTaskMutation,
  useUpdateTaskMutation,
  useGetTaskCommentsQuery,
  useCreateTaskCommentMutation,
  useUpdateTaskCommentMutation,
  useDeleteTaskCommentMutation,
  useMarkAsDoneMutation,
} = tasksApi;
