import { useGetCurrentUserQuery } from 'api/users';
import React from 'react';
import SharedTasks from './shared-tasks.component';

const ReportedTasks = () => {
  const { data: currentUser } = useGetCurrentUserQuery();

  return <SharedTasks currentUserId={currentUser?.id} taskType="reported_task" />;
};

export default ReportedTasks;
