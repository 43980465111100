import { useGetOrganizationQuery } from 'api/organizations';
import DefaultSpinner from 'components/shared-components/default-spinner';
import React from 'react';
import { useParams } from 'react-router-dom';
import { PRODUCER } from 'utils/organisation-industries';
import { useGetContractQuery } from 'api/contract';
import { useGetCurrentUserQuery } from 'api/users';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import ContractComponent from './bio-cuisine/contract-component';
import ContractTabComponent from './producer/contract-tab.component';
import ProducerContractComponent from './producer/producer-contract.component';

const Contract = () => {
  const { id } = useParams();
  const { data: organization, isLoading } = useGetOrganizationQuery(id);
  const { data: contractData } = useGetContractQuery(id);
  const { data: currentUser } = useGetCurrentUserQuery();
  const isAdmin = isBioSuisseAdmin(currentUser);

  if (isLoading) return <DefaultSpinner />;

  if (organization?.industry === PRODUCER) {
    return isAdmin ? (
      <ContractTabComponent contractData={contractData} />
    ) : (
      <ProducerContractComponent contractData={contractData} />
    );
  }
  return <ContractComponent contractData={contractData} />;
};

export default Contract;
