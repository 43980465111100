import { PillTabs } from 'components/base-components/PillTabs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useGetOrganizationQuery } from 'api/organizations';
import { PRODUCER } from 'utils/organisation-industries';
import DeactivationWrapper from 'components/organisations/deactivation-wrapper.component';
import ControlBodyHistoryComponent from './control-body-history.component';
import CurrentControlBodyWrapper from './current-control-body-wrapper.component';
import ControlHistory from './control-history.component';

const ControlBodyComponent = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const { id } = useParams();
  const { data: organisation = {} } = useGetOrganizationQuery(id);

  return (
    <PillTabs
      controlled={true}
      selectedTabKey={selectedTab}
      useTransitionOnChange={true}
      onChange={key => setSelectedTab(key)}
      items={[
        {
          getContent: () => (
            <DeactivationWrapper>
              <CurrentControlBodyWrapper />
            </DeactivationWrapper>
          ),
          key: 0,
          title: t('shared.tabs.currently_declared'),
        },
        {
          getContent: () => (
            <DeactivationWrapper>
              <ControlBodyHistoryComponent />
            </DeactivationWrapper>
          ),
          key: 1,
          title: t('shared.tabs.history'),
        },
        {
          getContent: () => (
            <DeactivationWrapper>
              <ControlHistory />
            </DeactivationWrapper>
          ),
          key: 2,
          title: t('control_body.survey.attributes.history.title'),
          hidden: organisation.industry === PRODUCER,
        },
      ]}
    />
  );
};

export default ControlBodyComponent;
