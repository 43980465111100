const generateQRCodeContent = ({ amount, currency, reference, creditor, debtor }) => {
  const qrData = [
    'SPC', // Swiss Payments Code
    '0200', // Version
    '1', // Coding Type UTF-8
    creditor.account, // IBAN
    'K', // Address Type
    creditor.nameArray.join(' '), // Name
    creditor.address, // Address
    `${creditor.zip} ${creditor.city}`, // Zip and city
    '', // Empty zip field
    '', // Empty city field
    creditor.country, // Country
    '', // 1x Empty
    '', // 2x Empty
    '', // 3x Empty
    '', // 4x Empty
    '', // 5x Empty
    '', // 6x Empty
    '', // 7x Empty
    amount, // Amount
    currency, // Currency
    'K', // Address Type
    debtor.name, // Name
    debtor.address, // Address
    `${debtor.zip} ${debtor.city}`, // Zip and city
    '', // Empty zip field
    '', // Empty city field
    debtor.country, // Country
    'QRR', // Reference type
    reference, // Reference
    '', // Unstructured message
    'EPD', // End of payment data
    '', // Additional information
  ];

  return qrData.join('\n');
};

export default generateQRCodeContent;
