import { useGetOrganizationQuery } from 'api/organizations';
import { useGetDatalakeSeasonByYearQuery } from 'api/producers/productions';
import Card from 'components/base-components/Card';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader/ContentLoader';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import './styles.scss';

const OthersData = ({ year }) => {
  const { id } = useParams();
  const { data: organisation = {} } = useGetOrganizationQuery(id);
  const companyId = organisation.datalakeCompanyIdentifier;
  const { data, isLoading } = useGetDatalakeSeasonByYearQuery(
    { companyId, year },
    { skip: !companyId || !organisation.certificationStatus },
  );

  const season = data ? toSnakecaseKeys(data) : {};

  return (
    <div className="others-data__container">
      <Card size="full" title={t('operations.other_data.control_body.title')}>
        {isLoading ? (
          <ContentLoaderPlaceholder numberOfLines={9} isRounded={true} showBackground={false} />
        ) : (
          <>
            {Object.keys(season).length > 0 ? (
              Object.keys(season).map(key => (
                <div className="others-data__control-body" key={key}>
                  <div>{t(`operations.other_data.control_body.items.${key}`)}</div>
                  <div className="others-data__control-body__value">{season[key] || 0}</div>
                </div>
              ))
            ) : (
              <EmptyContentPlaceholder
                iconName="plant_little"
                text={t('operations.other_data.control_body.no_data')}
                showCard={false}
              />
            )}
          </>
        )}
      </Card>
      <Card size="full" title={t('operations.other_data.maf.title')}>
        <EmptyContentPlaceholder
          iconName="plant_little"
          text={t('operations.other_data.maf.no_data')}
          showCard={false}
        />
      </Card>
    </div>
  );
};

OthersData.propTypes = {
  year: PropTypes.number.isRequired,
};

export default OthersData;
