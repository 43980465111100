import React from 'react';
import PropTypes from 'prop-types';

function SideNavActionBtn(props) {
  const { url, label } = props;

  return (
    <a className="side-nav__button" href={url}>
      <span className="side-nav__button-label">{label}</span>
    </a>
  );
}

SideNavActionBtn.defaultProps = {
  label: null,
  url: '#',
};

SideNavActionBtn.propTypes = {
  label: PropTypes.node,
  url: PropTypes.string,
};

export default SideNavActionBtn;
