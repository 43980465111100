import routes from 'utils/routes';
import { USER } from 'utils/tag-types';
import { apiSlice } from '../index';

const twoFactorAuthenticationApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    enableTwoFactorAuthentication: build.mutation({
      query: payload => ({
        url: routes.twoFactorAuthentication.enable(),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, id) => (!error ? [USER] : []),
    }),
    disableTwoFactorAuthentication: build.mutation({
      query: payload => ({
        url: routes.twoFactorAuthentication.disable(),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, id) => (!error ? [USER] : []),
    }),
    verifyTwoFactorAuthentication: build.mutation({
      query: payload => ({
        url: routes.twoFactorAuthentication.verify(),
        method: 'PUT',
        body: payload,
      }),
    }),

    getProvisioningUri: build.query({
      query: () => routes.twoFactorAuthentication.provisioningUri(),
    }),
  }),
});

export const {
  useDisableTwoFactorAuthenticationMutation,
  useEnableTwoFactorAuthenticationMutation,
  useGetProvisioningUriQuery,
  useVerifyTwoFactorAuthenticationMutation,
} = twoFactorAuthenticationApi;
