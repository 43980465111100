import routes from 'utils/routes';
import { TVD } from 'utils/tag-types';
import { apiSlice } from '../index';

const tvdAPI = apiSlice.injectEndpoints({
  endpoints: build => ({
    getTVDList: build.query({
      query: params => routes.tvdVignettes.collection(params),
      providesTags: [TVD],
    }),
    orderTvd: build.mutation({
      query: ({ id, payload }) => ({
        url: routes.tvdVignettes.order({ localUnitId: id }),
        method: 'POST',
        body: payload,
      }),
    }),
    orderTvdVignettes: build.mutation({
      query: () => ({
        url: routes.tvdVignettes.upload(),
        method: 'PUT',
      }),
    }),
  }),
});

export const { useGetTVDListQuery, useOrderTvdMutation, useOrderTvdVignettesMutation } = tvdAPI;
