import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { PillTabs } from 'components/base-components/PillTabs';
import DeactivationWrapper from '../deactivation-wrapper.component';
import ExpiredDeclaration from './expired-declaration.component';
import ActiveDeclaration from './active-declaration.component';
import './goods.styles.scss';

const GoodsComponent = () => {
  const [selectedTab, setSelectedTab] = useState();
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <>
      <div className="goods">
        <PillTabs
          controlled={true}
          size="tiny"
          selectedTabKey={selectedTab}
          items={[
            {
              title: t('goods.tab.current_tab'),
              key: 0,
              getContent: () => (
                <DeactivationWrapper>
                  <ActiveDeclaration organizationId={id} />
                </DeactivationWrapper>
              ),
            },
            {
              title: t('goods.tab.history'),
              key: 1,
              getContent: () => (
                <DeactivationWrapper>
                  <ExpiredDeclaration organizationId={id} />
                </DeactivationWrapper>
              ),
            },
          ]}
          onChange={key => setSelectedTab(key)}
        />
      </div>
    </>
  );
};

export default GoodsComponent;
