import getNodePosition from './getNodePosition';
import getNodeDimension from './getNodeDimension';
import popover from './popoverDefaultData';

/**
 * Returns an object with the distance of popover left and right from the Browser Window.
 *
 * @param {node} parentNode The DOM node of the popover-parent.
 * @param {string} direction The direction in which user wants to render popover.
 * @param {object} popoverDimension Popover height and width as an object {height, width}.
 *
 * @return {string} An object with the distance of popover left and right from the Browser Window.
 */

const popoverYPositionFromWindow = (parentNode, direction, popoverDimension, tipSize) => {
  const popoverParentDimension = getNodeDimension(parentNode);
  const popoverParentPosition = getNodePosition(parentNode);
  const [directionPrefix, directionSuffix] = direction.split('-');
  const popoverHeight = popoverDimension.height;
  const totalPopoverHeight = popoverHeight + popover.tip.size[tipSize].height + popover.tip.gap;
  let top = 0;

  if (['top', 'bottom'].includes(directionPrefix)) {
    top =
      directionPrefix === 'bottom'
        ? popoverParentPosition.y + popoverParentDimension.height
        : popoverParentPosition.y - totalPopoverHeight;
  } else if (['right', 'left'].includes(directionPrefix)) {
    if (directionSuffix === 'top') {
      top = popoverParentPosition.y;
    } else if (directionSuffix === 'bottom') {
      top = popoverParentPosition.y + popoverParentDimension.height - popoverHeight;
    } else {
      top = popoverParentPosition.y - (popoverHeight - popoverParentDimension.height) / 2;
    }
  } else if (directionPrefix === 'stretch') {
    if (directionSuffix === 'top') {
      top =
        popoverParentPosition.y -
        (popoverHeight + popover.tip.size[tipSize].height + popover.tip.gap);
    } else if (directionSuffix === 'bottom') {
      top =
        popoverParentPosition.y +
        popoverParentDimension.height +
        popover.tip.size[tipSize].height +
        popover.tip.gap;
    }
  }

  return parseFloat(top.toFixed(2));
};

const popoverXPositionFromWindow = (parentNode, direction, popoverDimension, tipSize) => {
  const popoverParentDimension = getNodeDimension(parentNode);
  const popoverParentPosition = getNodePosition(parentNode);
  const [directionPrefix, directionSuffix] = direction.split('-');
  const popoverWidth = popoverDimension.width;
  const totalPopoverwidth = popoverWidth + popover.tip.size[tipSize].height + popover.tip.gap;
  let left = 0;

  if (['left', 'right'].includes(directionPrefix)) {
    left =
      directionPrefix === 'right'
        ? popoverParentPosition.x + popoverParentDimension.width
        : popoverParentPosition.x - totalPopoverwidth;
  } else if (['bottom', 'top'].includes(directionPrefix)) {
    if (directionSuffix === 'left') {
      left = popoverParentPosition.x;
    } else if (directionSuffix === 'right') {
      left = popoverParentPosition.x + popoverParentDimension.width - popoverWidth;
    } else {
      left = popoverParentPosition.x - (popoverWidth - popoverParentDimension.width) / 2;
    }
  } else if (directionPrefix === 'stretch') {
    left = 0;
  }

  return parseFloat(left.toFixed(2));
};

export { popoverYPositionFromWindow, popoverXPositionFromWindow };
