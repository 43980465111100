import React from 'react';
import PropTypes from 'prop-types';
import EmphasisTag from 'components/base-components/EmphasisTag';
import Card from 'components/base-components/Card';
import { dateMonthYearFormatter } from 'utils/date-time-formatter';
import OrganizationName from 'components/organisations/organization-name';
import MESSAGES_STATUS from 'utils/message-status';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import { useTranslation } from 'react-i18next';
import UserInfo from 'components/users/user-info.component';
import SanitizedHTML from 'components/shared-components/sanitized-html';

const MessageComponent = ({ messageData, isFetched }) => {
  const { t } = useTranslation();
  const handleMessageType = type => {
    if (type === MESSAGES_STATUS.unconfirmed) return 'warning';
    else if (type === MESSAGES_STATUS.regular) return 'disabled';
    else return 'success';
  };

  return (
    <div>
      {isFetched && messageData.length > 0 ? (
        messageData.map(message => (
          <Card className="dashboard__messages-wrapper">
            <>
              <div className="dashboard__messages">
                <div className="dashboard__messages-avatar">
                  {message?.recipientOrganizationId ? (
                    <OrganizationName organizationId={message.recipientOrganizationId} />
                  ) : (
                    <UserInfo userData={message?.sender} />
                  )}
                  <div className="dashboard__messages-date">
                    {dateMonthYearFormatter(message?.updatedAt)}
                  </div>
                </div>
                <EmphasisTag
                  type={handleMessageType(message?.type)}
                  size="tiny"
                  text={t(`messages.type.${message?.type}`)}
                />
              </div>

              <div className="dashboard__messages-tags">
                <div className="dashboard__messages-text">
                  <SanitizedHTML html={message?.text} />
                </div>
              </div>
            </>
          </Card>
        ))
      ) : (
        <EmptyContentPlaceholder
          text={t('messages.no_message_found')}
          iconName="chat"
          showCard={false}
        />
      )}
    </div>
  );
};

MessageComponent.defaultProps = {
  messageData: [],
  isFetched: false,
};

MessageComponent.propTypes = {
  isFetched: PropTypes.bool,
  messageData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default MessageComponent;
