import React, { Fragment, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { debounce } from 'throttle-debounce';
import Textarea from 'components/base-components/Textarea';
import {
  useGetSupplierCertificatesQuery,
  useCreateSupplierCertificateComplaintMutation,
} from 'api/supplier-certificates';
import PropTypes from 'prop-types';
import Icon from '../../base-components/Icon';
import Button from '../../base-components/Button';
import SidePanel from '../../base-components/SidePanel';
import Input from '../../base-components/Input';
import SelectBox from '../../base-components/SelectBox';

const ComplaintForm = ({ onSave, onCancel, organizationId }) => {
  const [query, setQuery] = useState('');

  const { data: supplierCertificatesResponse } = useGetSupplierCertificatesQuery({
    q: query,
    organizationId: organizationId,
    status: 'complainable',
  });

  const supplierCertificates = supplierCertificatesResponse
    ? supplierCertificatesResponse.certificates
    : [];
  const { t } = useTranslation();

  const onSearchCertificates = debounce(500, value => {
    setQuery(value);
  });

  const supplierOptions = useMemo(() => {
    return supplierCertificates.map(supplierCertificate => ({
      label: supplierCertificate.name,
      value: supplierCertificate.id,
    }));
  }, [JSON.stringify(supplierCertificates)]);

  return (
    <Form
      onSubmit={onSave}
      validate={() => {}}
      render={({ handleSubmit, form, values, submitErrors }) => {
        return (
          <form onSubmit={handleSubmit} className="supplier-certificates__complain-form">
            <Field name="supplier">
              {({ input, meta }) => (
                <SelectBox
                  onInputChange={onSearchCertificates}
                  size="tiny"
                  width="large"
                  className="organisation__form--margin-top"
                  label={t('supplier_certificates.attributes.supplier_name')}
                  required={true}
                  isClearable={false}
                  options={supplierOptions}
                  value={input.value}
                  selectedValue={input.label}
                  onChange={value => {
                    input.onChange(value);
                  }}
                  errorMsg={meta.submitError}
                />
              )}
            </Field>
            <div className="supplier-certificates__form--margin-top">
              <Field name="title">
                {({ input, meta }) => (
                  <Input
                    size="tiny"
                    label={t('supplier_certificates.comment.title.label')}
                    placeholder={t('supplier_certificates.comment.title.placeholder')}
                    touched={!meta.dirtySinceLastSubmit}
                    error={meta.submitError}
                    required={true}
                    {...input}
                  />
                )}
              </Field>
            </div>
            <div className="supplier-certificates__form--margin-top">
              <Field name="description">
                {({ input, meta }) => (
                  <Textarea
                    size="tiny"
                    label={t('supplier_certificates.comment.describe.label')}
                    placeholder={t('supplier_certificates.comment.describe.placeholder')}
                    touched={!meta.dirtySinceLastSubmit}
                    error={meta.submitError}
                    required={true}
                    {...input}
                  />
                )}
              </Field>
            </div>
            <div className="supplier-certificates__form--margin-top supplier-certificates__form-action">
              <Button
                label={t('shared.action.save')}
                size="small"
                onClick={handleSubmit}
                disabled={!values.supplier}
                type="success"
              />
              <Button
                label={t('shared.action.cancel')}
                size="small"
                onClick={() => {
                  form.reset();
                  onCancel();
                }}
              />
            </div>
          </form>
        );
      }}
    />
  );
};

ComplaintForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  organizationId: PropTypes.number.isRequired,
};

const CreateComplaint = ({ organizationId, isDisabled }) => {
  const [enableSidePanel, setEnableSidePanel] = useState(false);
  const { t } = useTranslation();

  const { data: supplierCertificatesResponse } = useGetSupplierCertificatesQuery({
    q: '',
    organizationId: organizationId,
    status: 'complainable',
  });

  const [createSupplierCertificateComplaint] = useCreateSupplierCertificateComplaintMutation();

  const onSave = values => {
    const params = {
      certificateId: values.supplier && values.supplier.value,
      title: values.title,
      description: values.description,
    };
    return createSupplierCertificateComplaint(params)
      .unwrap()
      .then(() => {
        return null;
      })
      .catch(({ data: { errors } }) => {
        return errors;
      });
  };

  return (
    <Fragment>
      <SidePanel
        title={t('supplier_certificates.raise_complaint')}
        closeIcon={<Icon name="close" />}
        body={
          <ComplaintForm
            onSave={onSave}
            onCancel={() => setEnableSidePanel(false)}
            organizationId={organizationId}
          />
        }
        isOpened={enableSidePanel}
        onClick={() => {}}
        onClose={() => {
          setEnableSidePanel(false);
        }}
      />
      <Button
        label={t('supplier_certificates.raise_a_complaint')}
        size="small"
        disabled={isDisabled || supplierCertificatesResponse?.certificates.length === 0}
        onClick={() => {
          setEnableSidePanel(true);
        }}
      />
    </Fragment>
  );
};

CreateComplaint.propTypes = {
  organizationId: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default CreateComplaint;
