import { t } from 'i18next';
import React, { Fragment } from 'react';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { Link } from 'react-router-dom';
import ORGANISATION_TYPE from 'utils/organisation-type';
import PropTypes from 'prop-types';

const PopoverContent = ({ isProducer, id, organisation, isControlBodyUser, isAdmin }) => {
  const navigationLinks = useNavigationLinks();

  return (
    <div className="organisation__head-popover">
      <Link to={navigationLinks.organisationViewPage(id)}>
        <div className="organisation__head-popover--content">
          <span className="organisation__head-popover--content__text">
            {t('topnav.user.detail')}
          </span>
        </div>
      </Link>
      {isProducer && (
        <Fragment>
          <Link to={navigationLinks.organisationViewPage(id, 'operations')}>
            <div className="organisation__head-popover--content">
              <span className="organisation__head-popover--content__text">
                {t('organisation_view.tabs.operations')}
              </span>
            </div>
          </Link>
          {/* <Link to={navigationLinks.organisationViewPage(id, 'tvd')}>
            <div className="organisation__head-popover--content">
              <span className="organisation__head-popover--content__text">
                {t('organisation_view.tabs.tvd')}
              </span>
            </div>
          </Link> */}
        </Fragment>
      )}

      <Link to={navigationLinks.organisationUserPage(id)}>
        <div className="organisation__head-popover--content">
          <span className="organisation__head-popover--content__text">
            {t('organisation_view.tabs.user')}
          </span>
        </div>
      </Link>
      {organisation?.type !== ORGANISATION_TYPE.sub_organisation && !isControlBodyUser && (
        <Link to={navigationLinks.organisationContractPage(id)}>
          <div className="organisation__head-popover--content">
            <span className="organisation__head-popover--content__text">
              {t('organisation_view.tabs.contract')}
            </span>
          </div>
        </Link>
      )}
      <Link to={navigationLinks.organisationViewPage(id, 'recognition')}>
        <div className="organisation__head-popover--content">
          <span className="organisation__head-popover--content__text">
            {t('organisation_view.tabs.recognition')}
          </span>
        </div>
      </Link>
      <Link to={navigationLinks.organisationViewPage(id, 'tvd')}>
        <div className="organisation__head-popover--content">
          <span className="organisation__head-popover--content__text">
            {t('organisation_view.tabs.tvd')}
          </span>
        </div>
      </Link>
      <Link to={navigationLinks.organisationViewPage(id, 'invoices')}>
        <div className="organisation__head-popover--content">
          <span className="organisation__head-popover--content__text">
            {t('organisation_view.tabs.invoice')}
          </span>
        </div>
      </Link>
      <Link to={navigationLinks.organisationsEditPage(id)}>
        <div className="organisation__head-popover--content">
          <span className="organisation__head-popover--content__text">
            {t('organisation_view.edit_organisation_button')}
          </span>
        </div>
      </Link>
      {isProducer && (
        <Link to={navigationLinks.organisationViewPage(id, 'notes_correspondence')}>
          <div className="organisation__head-popover--content">
            <span className="organisation__head-popover--content__text">
              {t('organisation_view.tabs.notes_correspondence')}
            </span>
          </div>
        </Link>
      )}
      {isAdmin && (
        <Link to={navigationLinks.organisationReactivePage(id)}>
          <div className="organisation__head-popover--content">
            <span className="organisation__head-popover--content__text">
              {t('organisation_view.tabs.manage')}
            </span>
          </div>
        </Link>
      )}
      {isProducer && (
        <Link to={navigationLinks.organisationViewPage(id, 'super_office')}>
          <div className="organisation__head-popover--content">
            <span className="organisation__head-popover--content__text">
              {t('organisation_view.tabs.super_office')}
            </span>
          </div>
        </Link>
      )}
    </div>
  );
};

PopoverContent.defaultProps = {
  organisation: null,
  isInviteUserHeader: false,
  isProducer: false,
  isControlBodyUser: false,
  isAdmin: false,
  id: null,
};

PopoverContent.propTypes = {
  organisation: PropTypes.shape(),
  isProducer: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isControlBodyUser: PropTypes.bool,
  id: PropTypes.node,
};

export default PopoverContent;
