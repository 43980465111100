import { useGetOrganizationQuery } from 'api/organizations';
import { useGetCurrentUserQuery } from 'api/users';
import Icon from 'components/base-components/Icon';
import { Tabs } from 'components/base-components/RTabs';
import InvoiceListComponent from 'components/invoices/list/organisation/index.component';
import SuperOfficeDetails from 'components/super-office/details.component';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import isControlBodyMember from 'utils/is-control-body-member';
import { PRODUCER } from 'utils/organisation-industries';
import ORGANISATION_TYPE from 'utils/organisation-type';
import RecognitionComponent from './recognition/recognition.component';
import ContractComponent from './contract/index.component';
import ManageOrganisationComponent from './manage/manage-organisation.component';
import OrganisationViewHeader from './organisation-view-header.component';
import OverViewComponent from './overview/overview.component';
import TVDListOverview from './tvd/index.component';
import UserComponent from './user/user.component';
import OperationComponentWrapper from './operations/wrapper.component';

const OrganisationView = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { data: currentUser = null } = useGetCurrentUserQuery();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: organization = {} } = useGetOrganizationQuery(id);

  const isProducer = organization?.industry === PRODUCER;

  const TABS = {
    overview: 0,
    operations: 1,
    users: 2,
    contract: 3,
    recognition: 4,
    tvd: 5,
    invoices: 6,
    notes_correspondence: 7,
    manage: 8,
    super_office: 9,
  };

  const currentTab = searchParams.get('section') || TABS.overview;
  const isControlBodyUser = isControlBodyMember(currentUser);

  const onChangeTab = value => {
    const section = Object.keys(TABS).find(key => TABS[key] === value);
    setSearchParams(prevParams => {
      prevParams.set('section', section);
      return prevParams;
    });
  };

  return (
    <div className="organisation__rtabs">
      <OrganisationViewHeader />
      <Tabs
        controlled={true}
        selectedTabKey={TABS[currentTab]}
        items={[
          {
            title: t('organisation_view.tabs.details'),
            key: 0,
            getContent: () => <OverViewComponent isProducer={isProducer} />,
          },
          {
            title: t('organisation_view.tabs.operations'),
            key: 1,
            hidden: !isProducer,
            getContent: () => <OperationComponentWrapper />,
          },
          // {
          //   title: t('organisation_view.tabs.tvd'),   TODO: Will be uncommented when it would be necessary BIOS 1084
          //   key: 2,
          //   hidden: !isProducer,
          //   getContent: () => <TVDListComponent />,
          // },
          {
            title: t('organisation_view.tabs.user'),
            key: 2,
            getContent: () => <UserComponent />,
          },
          {
            title: t('organisation_view.tabs.contract'),
            key: 3,
            hidden:
              organization?.type === ORGANISATION_TYPE.sub_organisation ||
              organization?.type === ORGANISATION_TYPE.controlBody ||
              isControlBodyUser,
            getContent: () => <ContractComponent />,
          },
          {
            title: t('organisation_view.tabs.recognition'),
            key: 4,
            getContent: () => <RecognitionComponent organisationId={id} />,
          },
          {
            title: t('organisation_view.tabs.tvd'),
            key: 5,
            getContent: () => <TVDListOverview organisationId={id} />,
          },
          {
            title: t('organisation_view.tabs.invoice'),
            key: 6,
            getContent: () => <InvoiceListComponent organisationId={id} />,
          },
          {
            title: t('organisation_view.tabs.notes_correspondence'),
            key: 7,
            hidden: !isProducer,
            getContent: () => (
              <EmptyPlaceholder
                emptyCardText={t('common.coming_soon')}
                emptyIcon={<Icon name="add" color="tertiary" />}
              />
            ),
          },
          {
            title: t('organisation_view.tabs.manage'),
            key: 8,
            hidden: !isBioSuisseAdmin(currentUser),
            getContent: () => <ManageOrganisationComponent />,
          },
          {
            title: t('organisation_view.tabs.super_office'),
            key: 9,
            hidden: !isProducer,
            getContent: () => (
              <SuperOfficeDetails companyIdentifier={organization?.datalakeCompanyIdentifier} />
            ),
          },
        ]}
        onChange={onChangeTab}
      />
    </div>
  );
};

export default OrganisationView;
