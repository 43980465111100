import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Input from 'components/base-components/Input';
import Button from 'components/base-components/Button';
import SelectBox from 'components/base-components/SelectBox';
import Card from 'components/base-components/Card';
import Spinner from 'components/base-components/Spinner';
import useCustomNavigate from 'hooks/useCustomNavigate';
import useNavigationLinks from 'hooks/useNavigationLinks';
import {
  useGetOrganizationsQuery,
  useGetOrganisationRolesQuery,
  useGetOrganizationQuery,
} from 'api/organizations';
import Icon from 'components/base-components/Icon';
import { useGetCurrentUserQuery, useGetUserRolesQuery, useInviteUserMutation } from 'api/users';
import { useParams } from 'react-router';
import OrganisationHeadComponent from 'components/organisations/organisation-head.component';
import { USER, CONTROL_BODY_USER } from 'utils/users-role';
import ASSIGNMENT_ROLES from 'utils/assignment-roles';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import { removeEmptyFromObject, toSnakecaseKeys } from 'utils/object-cleanup';
import { debounce } from 'throttle-debounce';
import { useMemo } from 'react';
import isControlBodyMember from 'utils/is-control-body-member';
import { Link } from 'react-router-dom';
import ORGANISATION_TYPE from 'utils/organisation-type';
import ConfirmationModalWrapper from './ConfirmationModalWrapper';

const InviteUserComponent = () => {
  const [organisationQuery, setOrganisationQuery] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const organizationSearchParams = {
    q: organisationQuery,
    status: ORGANISATIONS_STATUS.active,
    'types[]':
      userRole === CONTROL_BODY_USER
        ? [ORGANISATION_TYPE.controlBody]
        : [
            ORGANISATION_TYPE.parent_organisation,
            ORGANISATION_TYPE.sub_organisation,
            ORGANISATION_TYPE.single_organisation,
          ],
  };

  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const { data: organizationsResponse, isSuccess: isOrganisationFetched } =
    useGetOrganizationsQuery(organizationSearchParams);
  const { data: userRolesResponse = [] } = useGetUserRolesQuery();
  const { data: organisationRolesResponse = [] } = useGetOrganisationRolesQuery();
  const [inviteUser, { isLoading, isError, isSuccess }] = useInviteUserMutation();

  const userRolesOptions = userRolesResponse.filter(option => option.value !== CONTROL_BODY_USER);

  const organisationData = organizationsResponse ? organizationsResponse.organizations : [];

  const onSearchOrganisation = debounce(500, value => setOrganisationQuery(value));

  const organisationOptions = organisationData.map(organizationsResponse => {
    return {
      label: organizationsResponse.name,
      value: organizationsResponse.id,
    };
  });

  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const navigationLinks = useNavigationLinks();
  const { id } = useParams();

  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: singleOrganisation } = useGetOrganizationQuery(id);

  const isControlBodyUser = isControlBodyMember(currentUser);

  const selectedOrganization = useMemo(() => {
    if (!singleOrganisation) return null;

    return {
      label: singleOrganisation.name,
      value: singleOrganisation.id,
      parent: singleOrganisation.mainOrgId,
      type: singleOrganisation.type,
    };
  }, [singleOrganisation && singleOrganisation.id]);

  let organisationRoleOptions = organisationRolesResponse;

  if (isBioSuisseAdmin(currentUser)) {
    if (selectedOrganization?.type === ORGANISATION_TYPE.controlBody) {
      organisationRoleOptions = organisationRolesResponse.filter(
        option => option.value !== ASSIGNMENT_ROLES.member,
      );
    } else {
      organisationRoleOptions = organisationRolesResponse.filter(
        option =>
          option.value === ASSIGNMENT_ROLES.owner || option.value === ASSIGNMENT_ROLES.member,
      );
    }
  }

  const onSubmit = params => {
    const values = removeEmptyFromObject(params);
    if (values.email !== values.confirmEmail) {
      return;
    }
    setInviteModalOpen(true);

    const selectedOrganizationId = values.organization && values.organization.value;

    const response = {
      ...values,
      organization_id: isControlBodyUser
        ? currentUser?.assignments[0]?.organizationId
        : selectedOrganizationId,
    };
    return inviteUser({ payload: response })
      .unwrap()
      .catch(({ data: { errors } }) => {
        let errorData = { ...errors };
        if (errors['organizationId']) {
          errorData['organization'] = errors['organizationId'];
        }
        return toSnakecaseKeys(errorData);
      });
  };

  const modalContent = () => {
    if (isLoading) {
      return (
        <div className="user__invite--modal-loading">
          <Spinner size="small" color="success" bgColor="none" />
          <div className="user__invite--modal-loading-message">
            {t('user_invite.modal.sending_invitation')}
          </div>
        </div>
      );
    }

    if (isError) {
      return (
        <div className="user__invite--modal-failure">
          <Icon className="user__invite--modal-failure-icon" name="invalid" size="xxl" />
          <div className="user__invite--modal-failure-message">
            <div>{t('user_invite.modal.failure_header')}</div>
            <div>{t('user_invite.modal.failure_description')}</div>
            <div>{t('user_invite.modal.try_again')}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="user__invite--modal-success">
        <Icon
          className="user__invite--modal-success-icon"
          name="check"
          size="large"
          showBGColor={true}
        />
        <div className="user__invite--modal-success-message">
          <div>{t('user_invite.modal.success_header')}</div>
          <div>{t('user_invite.modal.success_description')}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      {id && isOrganisationFetched && (
        <OrganisationHeadComponent
          organisation={singleOrganisation}
          isInviteUserHeader={true}
          showContract={false}
        />
      )}
      <div className="grid">
        <Card className="col-10 user__invite-card">
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, form, values, submitErrors }) => (
              <form className="grid" onSubmit={handleSubmit}>
                <div className="col-6">
                  <Field name="first_name">
                    {({ input, meta }) => (
                      <div className="user__invite--form__wrapper">
                        <Input
                          size="tiny"
                          required={true}
                          label={t('user_invite.firstname.label')}
                          placeholder={t('user_invite.firstname.placeholder')}
                          error={meta?.submitError}
                          touched={!meta.dirtySinceLastSubmit}
                          {...input}
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-6">
                  <Field name="last_name">
                    {({ input, meta }) => (
                      <div className="user__invite--form__wrapper">
                        <Input
                          size="tiny"
                          required={true}
                          label={t('user_invite.lastname.label')}
                          placeholder={t('user_invite.lastname.placeholder')}
                          error={meta?.submitError}
                          touched={!meta.dirtySinceLastSubmit}
                          {...input}
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-6">
                  <Field name="email">
                    {({ input, meta }) => (
                      <div className="user__invite--form__wrapper">
                        <Input
                          size="tiny"
                          required={true}
                          label={t('user_invite.email.label')}
                          placeholder={t('user_invite.email.placeholder')}
                          error={meta?.submitError}
                          touched={!meta.dirtySinceLastSubmit}
                          {...input}
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-6">
                  <Field name="confirmEmail">
                    {({ input, meta }) => (
                      <div className="user__invite--form__wrapper">
                        <Input
                          size="tiny"
                          required={true}
                          label={t('user_invite.confirm_email.label')}
                          placeholder={t('user_invite.confirm_email.placeholder')}
                          error={
                            values.email !== values.confirmEmail &&
                            t('user_invite.confirm_email.error')
                          }
                          touched={!meta.dirtySinceLastSubmit}
                          {...input}
                        />
                      </div>
                    )}
                  </Field>
                </div>

                {!id && !isControlBodyMember(currentUser) && (
                  <div className="col-12 user__invite-role">
                    <Field name="role" initialValue={userRolesOptions[0]?.value}>
                      {({ input, meta }) => (
                        <div className="user__invite--form__wrapper">
                          <SelectBox
                            isDisabled={!isBioSuisseAdmin(currentUser)}
                            size="tiny"
                            label={t('user_invite.role.label')}
                            placeholderText={t('user_invite.role.placeholder')}
                            isClearable={false}
                            required={true}
                            options={userRolesOptions}
                            value={userRolesOptions.find(option => option.value === input.value)}
                            selectedValue={input.label}
                            onChange={e => {
                              input.onChange(e.value);
                              setUserRole(e.value);
                              form.change('organization', undefined);
                              form.change('organization_role', undefined);
                            }}
                            errorMsg={meta?.submitError}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                )}
                {!isControlBodyMember(currentUser) && (
                  <div className="col-6">
                    <Field name="organization" type="select" initialValue={selectedOrganization}>
                      {({ input, meta }) => (
                        <div className="user__invite--form__wrapper">
                          <SelectBox
                            size="tiny"
                            width="small"
                            label={t('user_invite.organisation.label')}
                            placeholderText={t('user_invite.organisation.placeholder')}
                            required={true}
                            isClearable={false}
                            options={organisationOptions}
                            value={input.value}
                            onChange={e => input.onChange(e)}
                            onInputChange={onSearchOrganisation}
                            errorMsg={meta?.submitError}
                            isDisabled={id || values.role !== USER}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                )}

                <div className="col-6">
                  <Field name="organization_role">
                    {({ input, meta }) => (
                      <div className="user__invite--form__wrapper">
                        <SelectBox
                          size="tiny"
                          label={
                            isControlBodyMember(currentUser)
                              ? t('user_invite.role.label')
                              : t('user_invite.organisation_role.label')
                          }
                          placeholderText={
                            isControlBodyMember(currentUser)
                              ? t('user_invite.role.placeholder')
                              : t('user_invite.organisation_role.placeholder')
                          }
                          isClearable={false}
                          options={organisationRoleOptions}
                          value={organisationRoleOptions.find(
                            option => option.value === input.value,
                          )}
                          selectedValue={input.label}
                          onChange={e => input.onChange(e.value)}
                          errorMsg={meta?.submitError}
                          required={true}
                          isDisabled={
                            !id && values.role !== USER && !isControlBodyMember(currentUser)
                          }
                        />
                      </div>
                    )}
                  </Field>
                </div>

                <div className="col-12 col-bleed-y user__invite--footer">
                  <Button
                    label={t('user_invite.invite_button')}
                    type="success"
                    size="small"
                    submitType="submit"
                  />
                  <Link to={navigationLinks.usersPage()}>
                    <Button
                      className="user__invite--footer__cancel-btn"
                      size="small"
                      label={t('user_details.deactivate_user_modal.cancel_button')}
                    />
                  </Link>
                </div>
              </form>
            )}
          />
        </Card>
        <ConfirmationModalWrapper
          isVisible={inviteModalOpen}
          contentAlign="center"
          footerAlign="center"
          content={modalContent()}
          className="user__invite--modal"
          timeOut={!isLoading ? 3000 : 0}
          onTimeout={() => {
            setInviteModalOpen(false);
            if (isSuccess) {
              navigate.openInvitedUsersPage();
            }
          }}
        />
      </div>
    </>
  );
};

export default InviteUserComponent;
