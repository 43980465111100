import PropTypes from 'prop-types';
import RadioWithoutCheck from 'components/base-components/RadioWithoutCheck';
import React from 'react';
import BooleanFieldWrapper from './boolean-field-wrapper.component';

const RadioWithoutCheckContent = ({ input, value, label, onChange, disabled }) => (
  <RadioWithoutCheck
    className="organisation__form--radio__item"
    isChecked={input.checked}
    name={`item-${value}`}
    label={label}
    onChange={e => {
      input.onChange(e.target.checked ? value : null);
      onChange();
    }}
    size="medium"
    disabled={disabled}
  />
);

RadioWithoutCheckContent.defaultProps = {
  disabled: false,
};

RadioWithoutCheckContent.propTypes = {
  input: PropTypes.shape().isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const RadioboxWithIcon = ({
  label,
  icon,
  fieldName,
  initialValue,
  value,
  onChange,
  isDisabled,
}) => {
  return (
    <BooleanFieldWrapper
      name={fieldName}
      initialValue={initialValue}
      icon={icon}
      type="radio"
      value={value}
    >
      <RadioWithoutCheckContent
        value={value}
        label={label}
        onChange={onChange}
        disabled={isDisabled}
      />
    </BooleanFieldWrapper>
  );
};

RadioboxWithIcon.defaultProps = {
  icon: null,
  isChecked: false,
  isDisabled: false,
  onChange: () => {},
};

RadioboxWithIcon.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  initialValue: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default RadioboxWithIcon;
