import React, { useState } from 'react';
import { useDeleteAttachmentMutation } from 'api/contract';
import Button from 'components/base-components/Button';
import Icon from 'components/base-components/Icon';
import Spinner from 'components/base-components/Spinner';
import WarningModal from 'components/shared-components/modal/warning';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import SubmitModal from 'components/shared-components/modal/submit-modal';

const DeleteContract = ({ contractData }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteFile, { isLoading, isError, isSuccess, requestId }] = useDeleteAttachmentMutation();
  const handleDelete = () => {
    return deleteFile({
      contractId: contractData?.id,
    })
      .unwrap()
      .then(() => setIsDeleteModalOpen(false));
  };

  return (
    <div>
      <Button
        className="organisation-contract__delete-btn"
        label={
          <>
            <Icon className="choose-file__contract-delete--icon" name="delete" />
            <span className="choose-file__contract-delete--text">
              {t('contract.delete_button')}
            </span>
          </>
        }
        size="small"
        disabled={!contractData?.contract}
        onClick={() => setIsDeleteModalOpen(true)}
      />
      <WarningModal
        onOutsideClick={() => setIsDeleteModalOpen(false)}
        isVisible={isDeleteModalOpen}
        title={t('contract.delete_contract_title')}
        content={t('contract.delete_contract_description')}
        confirmActionText={t('shared.action.yes')}
        cancelActionText={t('shared.action.cancel')}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />

      <SubmitModal
        isSuccess={isSuccess}
        isLoading={isLoading}
        isError={isError}
        requestId={requestId}
        showError={true}
        showLoader={true}
        successTitle={t('contract.modal.success_header')}
        successContent={t('contract.modal.success_description')}
        errorTitle={t('contract.modal.failure_header')}
        errorTitleContent={
          <div>
            <div>{t('contract.modal.failure_description')}</div>
            <div>{t('contract.modal.try_again')}</div>
          </div>
        }
        loaderContent={
          <div className="user__invite--modal-loading">
            <Spinner size="small" color="success" bgColor="none" />
            <div className="user__invite--modal-loading-message">
              {t('user_invite.modal.sending_invitation')}
            </div>
          </div>
        }
      />
    </div>
  );
};

DeleteContract.defaultProps = {
  contractData: null,
};

DeleteContract.propTypes = {
  title: PropTypes.string.isRequired,
  contractData: PropTypes.shape(),
};

export default DeleteContract;
